import { ReactNode } from 'react';
import { Box, DefaultProps, packSx } from '@mantine/core';

type Props = {
  children: ReactNode;
  hasPageHeaderSpacings?: boolean;
  sx?: DefaultProps['sx'];
};
export const StickyContent = ({
  children,
  hasPageHeaderSpacings = false,
  sx,
}: Props) => (
  <Box
    sx={[
      theme => ({
        top: theme.other.spacing(-2),
        zIndex: 3,
        backgroundColor: theme.other.layoutBodyColor,
        ...(hasPageHeaderSpacings && theme.other.pageHeaderSpacingStyles),
        [`@media (min-width: ${theme.breakpoints.md}px)`]: {
          position: 'sticky',
        },
      }),
      ...packSx(sx),
    ]}
  >
    {children}
  </Box>
);
