import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { deleteNotifications } from '../requests';
import { DeleteNotificationsRequestType } from '../types';

export const useDeleteNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      queryParams,
    }: {
      queryParams: DeleteNotificationsRequestType['queryParams'];
    }) =>
      deleteNotifications({
        queryParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.notifications],
      });
    },
  });
};
