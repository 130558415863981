import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, Text } from '@mantine/core';
import { AxiosError } from 'axios';
import { z } from 'zod';

import { useServerErrors } from 'hooks';
import { URLS } from 'consts';
import { getZodSchemaErrorMessages } from 'helpers';

import { TextInput } from 'components/shared';

import { AuthContainerLoginHeader } from '../../AuthContainerLoginHeader';
import { SsoLoginMethods } from '../SsoLoginMethods';
import { LoginOptionsDivider } from '../LoginOptionsDivider';

type Props = {
  email: string;
  isLoading: boolean;
  setEmail: (email: string) => void;
  mutate: ({ email }: { email: string }) => void;
  error?: AxiosError | null;
};

export const ChooseLoginMethod = ({
  email,
  isLoading,
  setEmail,
  mutate,
  error = null,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const schema = z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .email({ message: t('common:formErrors.incorrectEmail') }),
  });

  const { getGeneralServerError, getServerFieldError } = useServerErrors();

  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isLoading && Boolean(error)) {
      const fieldEmailError = getServerFieldError(error, 'email');
      const generalServerError = getGeneralServerError(error);

      if (fieldEmailError) {
        setEmailFieldErrorMessage(fieldEmailError);
      } else if (generalServerError) {
        setServerErrorMessage(generalServerError);
      }

      inputRef.current?.focus();
    }
  }, [isLoading, error, getServerFieldError, getGeneralServerError]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailFieldErrorMessage('');
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = getZodSchemaErrorMessages({ schema, fields: { email } });

    if (errors) {
      setEmailFieldErrorMessage(errors.email);
      inputRef.current?.focus();
    } else {
      mutate({ email });
    }
  };

  return (
    <>
      <AuthContainerLoginHeader errorLabel={serverErrorMessage} />
      <form onSubmit={handleSubmit}>
        <TextInput
          id="email"
          label={t('common:email')}
          placeholder={t('common:emailPlaceholder')}
          autoFocus
          value={email}
          error={emailFieldErrorMessage}
          ref={inputRef}
          onChange={handleChangeEmail}
        />

        <Button
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
            fontWeight: 600,
          })}
          type="submit"
          fullWidth
          size="lg"
          loading={isLoading}
        >
          {t('common:continue')}
        </Button>
      </form>
      <Box
        sx={theme => ({
          margin: theme.other.spacing(-1, 0, 1),
          textAlign: 'center',
          visibility: isLoading ? 'hidden' : undefined,
        })}
      >
        <Text span size="md">
          {t('user:dontHaveAccount')}
        </Text>{' '}
        <Anchor component={Link} to={URLS.register}>
          {t('user:register')}
        </Anchor>
      </Box>
      <Anchor
        sx={{ display: 'flex', justifyContent: 'center' }}
        component={Link}
        to={URLS.requestPasswordReset}
        state={{ email }}
      >
        {t('user:dontRememberPassword')}
      </Anchor>
      <LoginOptionsDivider />
      <SsoLoginMethods />
    </>
  );
};
