import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Text } from '@mantine/core';

import { DYNAMIC_URLS, TRANSACTION_STATUS } from 'consts';
import {
  getHasHateoasAccess,
  getTransactionListStatusColor,
  getUserFullName,
} from 'helpers';
import { useFormatUserDate } from 'hooks';
import { Table, Status } from 'components/shared';
import { OrganisationUserListType, TransactionListItemType } from 'types';

import { SelectedOrganisationMembersType } from '../types';
import { Filters } from './Filters';

type Props = {
  transactions: TransactionListItemType[];
  hasActiveFilters: boolean;
  clearFilters: () => void;
  organisationMembers: OrganisationUserListType[];
  selectedOrganisationMembers: SelectedOrganisationMembersType;
  status: 'all' | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS];
  prevPage: string | null | undefined;
  nextPage: string | null | undefined;
  isRefetching: boolean;
  handleChangeStatus: (
    status:
      | 'all'
      | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS],
  ) => void;
  handleChangeSelectedOrganisationMembers: (
    selectedMembers: SelectedOrganisationMembersType,
  ) => void;
  areFiltersOpen: boolean;
  isOrganisationMembersError: boolean;
  isOrganisationMembersLoading: boolean;
  isFetchingOrganisationMembersNextPage: boolean;
  fetchOrganisationMembersNextPage: () => void;
  setOrganisationMembersNameAndSurname: (nameAndSurname: string) => void;
};

export const TransactionList = ({
  transactions,
  hasActiveFilters,
  clearFilters,
  organisationMembers,
  selectedOrganisationMembers,
  status,
  prevPage,
  nextPage,
  isRefetching,
  handleChangeStatus,
  handleChangeSelectedOrganisationMembers,
  areFiltersOpen,
  isOrganisationMembersError,
  isOrganisationMembersLoading,
  isFetchingOrganisationMembersNextPage,
  fetchOrganisationMembersNextPage,
  setOrganisationMembersNameAndSurname,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const formatUserDate = useFormatUserDate();

  const noDataTableProps = hasActiveFilters
    ? {
        noDataDescription: t('transactions:notFoundTransactions'),
        noDataButtonText: t('common:clearFilters'),
        onNoDataButtonClick: clearFilters,
      }
    : {
        noDataHeader: t('transactions:dontHaveTransactionsYet'),
      };

  return (
    <Filters
      organisationMembers={organisationMembers}
      selectedOrganisationMembers={selectedOrganisationMembers}
      status={status}
      prevPage={prevPage}
      nextPage={nextPage}
      handleChangeStatus={handleChangeStatus}
      handleChangeSelectedOrganisationMembers={
        handleChangeSelectedOrganisationMembers
      }
      areFiltersOpen={areFiltersOpen}
      isOrganisationMembersError={isOrganisationMembersError}
      isOrganisationMembersLoading={isOrganisationMembersLoading}
      isFetchingOrganisationMembersNextPage={
        isFetchingOrganisationMembersNextPage
      }
      fetchOrganisationMembersNextPage={fetchOrganisationMembersNextPage}
      setOrganisationMembersNameAndSurname={
        setOrganisationMembersNameAndSurname
      }
    >
      <Table
        hasData={transactions.length > 0}
        isLoading={isRefetching}
        {...noDataTableProps}
      >
        <thead>
          <tr>
            <th>{t('transactions:transactionListIdAndName')}</th>
            <th>{t('transactions:transactionListStatusLabel')}</th>
            <th>{t('transactions:transactionListModifiedDate')}</th>
            <th>{t('transactions:transactionListDataStream')}</th>
            <th>{t('transactions:transactionListTransactionOwner')}</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map(
            ({
              id,
              transactionId,
              transactionName,
              updatedAt,
              transactionDataStream,
              status: transactionItemStatus,
              owner,
              __links,
            }) => {
              const hasDetailsAccess = getHasHateoasAccess('self', __links);

              return (
                <tr key={id}>
                  <td>
                    <Text span display="block" color="gray.7">
                      {transactionName}
                    </Text>
                    {hasDetailsAccess ? (
                      <Anchor
                        sx={{ fontWeight: 600 }}
                        component={Link}
                        to={DYNAMIC_URLS.transactionDetails({
                          transactionId,
                          transactionDataStream,
                        })}
                      >
                        {transactionId}
                      </Anchor>
                    ) : (
                      transactionId
                    )}
                  </td>
                  <td>
                    <Status
                      color={getTransactionListStatusColor(
                        transactionItemStatus,
                      )}
                      label={transactionItemStatus.message}
                    />
                  </td>
                  <td>{formatUserDate(updatedAt)}</td>
                  <td>{transactionDataStream}</td>
                  <td>
                    {owner.isDeleted
                      ? t('common:accountDeleted')
                      : getUserFullName(owner.name, owner.surname)}
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    </Filters>
  );
};
