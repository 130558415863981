import { useCallback } from 'react';
import {
  useGetDocumentInvitation,
  usePatchDocumentInvitation,
} from 'api/documentInvitation/hooks';
import { useGetUserBoards } from 'api/boards';
import { getBatches } from '../api/batches/requests';

const batchStatus = {
  waiting: 'waiting',
  processing: 'processing',
  error: 'error',
  finishedWithErrors: 'finished_with_errors',
  finished: 'finished',
};

const getBatchStatus = (status: string) => {
  const isLoading =
    status === batchStatus.waiting || status === batchStatus.processing;
  const isFinishedWithErrors = status === batchStatus.finishedWithErrors;
  const isError = status === batchStatus.error || isFinishedWithErrors;
  const isFinished = status === batchStatus.finished;
  const isFulfilled = isError || isFinished;

  return {
    isLoading,
    isError,
    isFinished,
    isFinishedWithErrors,
    isFulfilled,
  };
};

export const useDocumentInvitationFlow = ({
  invitationId,
}: {
  invitationId: string;
}) => {
  const { data, isLoading: isGetDocumentInvitationLoading } =
    useGetDocumentInvitation({
      pathParams: { invitationId },
    });

  const { data: boards, isLoading: isGetBoardsLoading } = useGetUserBoards();

  const { mutate: patchDocumentInvitation } = usePatchDocumentInvitation({
    pathParams: { invitationId: invitationId || '' },
  });

  const handlePatchDocumentInvitation = useCallback(
    (boardId: string, callback: () => void) => {
      patchDocumentInvitation(
        { boardId },
        {
          // @ todo entire solution below will be rewritten
          onSuccess: async (responseData: any) => {
            const userBatchId = responseData && responseData.id;

            const checkBatchStatus = async () => {
              try {
                let isFinished = false;
                let isFinishedWithErrors = false;

                while (!isFinished && !isFinishedWithErrors) {
                  // eslint-disable-next-line no-await-in-loop
                  const batchData = await getBatches({
                    pathParams: { userBatchId },
                  });

                  const status = getBatchStatus(batchData.status);
                  isFinished = status.isFinished;
                  isFinishedWithErrors = status.isFinishedWithErrors;

                  if (isFinished) {
                    callback();
                  } else if (isFinishedWithErrors) {
                    callback();
                    // eslint-disable-next-line no-console
                    console.error(batchData);
                  } else {
                    // eslint-disable-next-line no-promise-executor-return,no-await-in-loop
                    await new Promise(resolve => setTimeout(resolve, 2000));
                  }
                }
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
              }
            };

            checkBatchStatus();
          },
        },
      );
    },
    [patchDocumentInvitation],
  );

  return {
    boards: boards?.data,
    documents: data?.documents,
    status: data?.status,
    handlePatchDocumentInvitation,
    isInitialLoading: isGetDocumentInvitationLoading || isGetBoardsLoading,
  };
};
