import { Divider } from '@mantine/core';

export const SectionDivider = () => (
  <Divider
    sx={theme => ({
      borderColor: theme.colors.gray[2],
    })}
    orientation="vertical"
  />
);
