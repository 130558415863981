import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedState, useDebouncedValue } from '@mantine/hooks';

import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { useGetTransactions } from 'api/transactions';
import { useGetCurrentOrganisationUsersInfinite } from 'api/organisations';
import { getCursorFromUrl, getInfinityQueryNextPageParam } from 'helpers';
import { useSearchParams, useTransactionsFeature } from 'hooks';
import { DEBOUNCED_INPUT_DELAY_IN_MS, TRANSACTION_STATUS } from 'consts';
import { OrganisationUserListType } from 'types';

import { SelectedOrganisationMembersType } from './types';
import { TransactionList } from './TransactionList';
import { TransactionsHeader } from './TransactionsHeader';

const defaultStatusValue = 'all';

export const Transactions = () => {
  const { t } = useTranslation('transactions');

  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();
  const { getSearchParam, deleteSearchParam, deleteAllSearchParams } =
    useSearchParams(['search', 'page']);

  const search = getSearchParam('search');
  const page = getSearchParam('page');

  const [
    organisationMembersNameAndSurname,
    setOrganisationMembersNameAndSurname,
  ] = useDebouncedState('', DEBOUNCED_INPUT_DELAY_IN_MS);

  const {
    data: currentOrganisationMembersData,
    isError: isCurrentOrganisationMembersError,
    isLoading: isCurrentOrganisationMembersLoading,
    isFetchingNextPage: isFetchingOrganisationMembersNextPage,
    fetchNextPage: fetchOrganisationMembersNextPage,
  } = useGetCurrentOrganisationUsersInfinite(
    {
      queryParams: {
        nameAndSurname: organisationMembersNameAndSurname,
        isDeleted: '0',
      },
    },
    {
      keepPreviousData: true,
      getNextPageParam: getInfinityQueryNextPageParam,
    },
  );

  const organisationMembers = useMemo(
    () =>
      currentOrganisationMembersData?.pages.map(({ data }) => data)?.flat() ||
      [],
    [currentOrganisationMembersData?.pages],
  );

  const [status, setStatus] = useState<
    | typeof defaultStatusValue
    | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS]
  >(defaultStatusValue);

  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  const [selectedOrganisationMembers, setSelectedOrganisationMembers] =
    useState<SelectedOrganisationMembersType>(['all']);
  const [debouncedSelectedOrganisationMembers] = useDebouncedValue(
    selectedOrganisationMembers,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  const transactionOwnerIds =
    debouncedSelectedOrganisationMembers[0] === 'all'
      ? []
      : (debouncedSelectedOrganisationMembers as OrganisationUserListType['id'][]);

  const {
    data: transactions,
    isError: isTransactionsError,
    isInitialLoading: isTransactionsInitialLoading,
    isRefetching: isTransactionsRefetching,
  } = useGetTransactions(
    {
      queryParams: {
        transactionIdOrName: search,
        page,
        transactionStatus: status === defaultStatusValue ? null : status,
        transactionOwnerIds,
      },
    },
    {
      enabled: !isLoadingTransactionsFeature && transactionsFeature,
      keepPreviousData: true,
    },
  );

  const prevPage = getCursorFromUrl(transactions?.links?.prev);
  const nextPage = getCursorFromUrl(transactions?.links?.next);

  const hasActiveFilters =
    Boolean(search) ||
    Boolean(page) ||
    status !== 'all' ||
    transactionOwnerIds.length > 0;

  const setSelectedOrganisationMembersInitialState = () => {
    setSelectedOrganisationMembers(['all']);
  };

  const handleChangeStatus = (
    selectedStatus:
      | 'all'
      | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS],
  ) => {
    setStatus(selectedStatus);
    deleteSearchParam('page');
  };

  const handleChangeSelectedOrganisationMembers = (
    selectedMembers: SelectedOrganisationMembersType,
  ) => {
    setSelectedOrganisationMembers(selectedMembers);

    if (selectedMembers[0] === 'all' || selectedMembers.length === 0) {
      deleteSearchParam('page');
    }
  };

  useEffect(() => {
    if (organisationMembers) {
      setSelectedOrganisationMembersInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationMembers, setSelectedOrganisationMembers]);

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={transactions}
        isError={isTransactionsError || isTransactionFeatureError}
        isLoading={isTransactionsInitialLoading || isLoadingTransactionsFeature}
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {({ data }) => (
          <>
            <Breadcrumbs
              items={[
                {
                  name: t('transactions:transactions'),
                  url: '#',
                },
                {
                  name: t('transactions:transactionList'),
                  url: '#',
                },
              ]}
            />
            <TransactionsHeader
              toggleFilters={() => {
                setAreFiltersOpen(open => !open);
              }}
            />
            <TransactionList
              transactions={data}
              hasActiveFilters={hasActiveFilters}
              clearFilters={() => {
                deleteAllSearchParams();
                setStatus(defaultStatusValue);
                setSelectedOrganisationMembersInitialState();
              }}
              prevPage={prevPage}
              nextPage={nextPage}
              organisationMembers={organisationMembers}
              selectedOrganisationMembers={selectedOrganisationMembers}
              status={status}
              isRefetching={isTransactionsRefetching}
              handleChangeStatus={handleChangeStatus}
              handleChangeSelectedOrganisationMembers={
                handleChangeSelectedOrganisationMembers
              }
              areFiltersOpen={areFiltersOpen}
              isOrganisationMembersError={isCurrentOrganisationMembersError}
              isOrganisationMembersLoading={isCurrentOrganisationMembersLoading}
              isFetchingOrganisationMembersNextPage={
                isFetchingOrganisationMembersNextPage
              }
              fetchOrganisationMembersNextPage={
                fetchOrganisationMembersNextPage
              }
              setOrganisationMembersNameAndSurname={
                setOrganisationMembersNameAndSurname
              }
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};
