import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { DescriptionField, TextInput } from 'components/shared';

import { PredefinedValues } from '../../PredefinedValues';

const forwardSlash = (
  <Text span color="gray.3">
    /
  </Text>
);

export const RegExp = ({ inputProps, error, onValueClick }) => {
  const { t } = useTranslation('templates');

  const regExpItems = [
    {
      value: '^[0-9]{11}$',
      label: t('templates:templateCreatorVariables.pesel'),
    },
    {
      value: '^[0-9]{10}$',
      label: t('templates:templateCreatorVariables.nip'),
    },
    {
      value: '^([0-9]{9}|[0-9]{14})$',
      label: t('templates:templateCreatorVariables.regon'),
    },
    {
      value: '^\\d{26}$',
      label: t('templates:templateCreatorVariables.bankAccount'),
    },
  ];

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.regExp')}
      description={t('templates:templateCreatorVariables.regExpDescription')}
      error={error}
    >
      <TextInput
        {...inputProps}
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
        })}
        placeholder={t('templates:templateCreatorVariables.regExp')}
        withErrorWrapperAlwaysEnabled={false}
        icon={forwardSlash}
        rightIcon={forwardSlash}
      />
      <PredefinedValues items={regExpItems} onValueClick={onValueClick} />
    </DescriptionField>
  );
};
