import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const FileUploadIcon = ({
  color,
  size = 62,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id={`${id}_a`}
        width={32}
        height={32}
        x={7}
        y={7}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m10.31 11.223 19.954-3.835c2.06-.394 4.054.928 4.453 2.963l3.882 19.707c.399 2.034-.949 4.003-3 4.397l-19.954 3.834c-2.06.394-4.054-.928-4.452-2.962L7.31 15.62c-.399-2.035.94-4.004 3-4.397Z"
        />
      </mask>
      <g mask={`url(#${id}_a)`}>
        <path
          fill="#E6ECF8"
          d="M38.594-.002.004 7.406l7.534 38.276 38.59-7.407L38.593-.002Z"
        />
      </g>
      <mask
        id={`${id}_b`}
        width={35}
        height={34}
        x={15}
        y={16}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m27.654 16.595 19.1 6.862c1.975.713 2.99 2.86 2.269 4.81L42.064 47.14c-.721 1.95-2.895 2.953-4.87 2.24l-19.1-6.862c-1.975-.713-2.99-2.86-2.269-4.81l6.95-18.863c.72-1.95 2.895-2.954 4.87-2.241l.009-.01Z"
        />
      </mask>
      <g mask={`url(#${id}_b)`}>
        <path
          fill={iconColor}
          d="M18.605 3.69 2.867 46.391l43.4 15.6 15.737-42.703-43.399-15.6Z"
        />
      </g>
      <mask
        id={`${id}_c`}
        width={26}
        height={17}
        x={17}
        y={31}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m18.293 36.02 5.203-3.956a1.928 1.928 0 0 1 2.667.337c.124.16.228.347.294.535l1.073 3.094a1.9 1.9 0 0 0 2.411 1.162 1.86 1.86 0 0 0 .57-.309l6.73-5.344a1.916 1.916 0 0 1 2.668.29c.18.226.314.488.37.77l1.909 8.503c.085.366.047.75-.086 1.097l-1.813 4.519a1.902 1.902 0 0 1-2.411 1.069l-19.623-7.05a1.872 1.872 0 0 1-1.139-2.4l.541-1.473c.124-.337.342-.628.627-.843h.01Z"
        />
      </mask>
      <g mask={`url(#${id}_c)`}>
        <path
          fill="#fff"
          d="M15.93 28.599 14.847 48.98l28.25 1.462 1.082-20.382-28.25-1.462Z"
        />
      </g>
      <mask
        id={`${id}_d`}
        width={7}
        height={7}
        x={25}
        y={20}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M27.52 26.514c1.5.544 3.172-.225 3.713-1.706a2.841 2.841 0 0 0-1.738-3.666c-1.5-.544-3.17.225-3.711 1.706-.551 1.481.227 3.132 1.727 3.666h.01Z"
        />
      </mask>
      <g mask={`url(#${id}_d)`}>
        <path
          fill="#fff"
          d="m25.44 17.324-3.516 9.542 9.661 3.473 3.517-9.542-9.662-3.473Z"
        />
      </g>
    </Box>
  );
};
