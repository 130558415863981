import { ReactNode } from 'react';
import { Box, DefaultProps, packSx, Text } from '@mantine/core';

import { useGetColumnWidths } from './hooks';

type Props = {
  children: ReactNode;
  label: string;
  description: ReactNode;
  sx?: DefaultProps['sx'];
  error?: string;
  leftSection?: ReactNode;
  bottomSection?: ReactNode;
  withoutBorder?: boolean;
};

export const DescriptionField = ({
  sx,
  children,
  label,
  description,
  error,
  leftSection,
  bottomSection,
  withoutBorder = false,
}: Props) => {
  const { leftColumnWidth, rightColumnWidth } = useGetColumnWidths();

  return (
    <Box
      sx={[
        theme => ({
          padding: theme.other.spacing(3, 0),
          borderBottom: withoutBorder
            ? undefined
            : `1px solid ${theme.colors.gray[2]}`,
        }),
        ...packSx(sx),
      ]}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: theme.other.spacing(3, 6),
          [`@media (min-width: ${theme.breakpoints.md}px)`]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box
          sx={{
            width: leftColumnWidth,
          }}
        >
          <Text weight={600} color={error ? 'red' : undefined}>
            {label}
          </Text>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(1),
              letterSpacing: -0.06,
            })}
            size="md"
            color="red"
          >
            {error}
          </Text>
          <Text size="md" color="gray.5">
            {description}
          </Text>
          {leftSection}
        </Box>
        <Box
          sx={{
            width: rightColumnWidth,
          }}
        >
          {children}
        </Box>
      </Box>
      {bottomSection}
    </Box>
  );
};
