import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@mantine/core';

import { useGetUser, usePatchUser } from 'api/account';
import { GuideTourContext } from 'context';
import { getUserFullName } from 'helpers';
import { FeedbackIcon } from 'icons';
import { GUIDE_TOUR_DATA } from 'consts';
import { UserType } from 'types';
import { GuideTour, Modal } from 'components/shared';

export const GuideTourOnStart = () => {
  const { t } = useTranslation(['common', 'user']);

  const [, setGuideTourState] = useContext(GuideTourContext);

  const [isPreGuideTourModalOpen, setIsPreGuideTourModalOpen] = useState(true);
  const [isGuideTourOpen, setIsGuideTourOpen] = useState(false);

  const [isSkipCheckboxChecked, setIsSkipCheckboxChecked] = useState(false);

  const { data: user } = useGetUser();
  const { name, surname } = user as UserType;

  const { mutate: updateUserData } = usePatchUser();

  const changeUserMenuOpenForGuideTourState = (isOpen: boolean) => {
    setGuideTourState(guideTourState => ({
      ...guideTourState,
      isUserMenuOpenForGuideTour: isOpen,
    }));
  };

  const checkIfShouldSkipAndUpdate = () => {
    if (isSkipCheckboxChecked) {
      updateUserData({
        tutorial: {
          showV2GuideTour: false,
        },
      });
    }
  };

  const handleClose = () => {
    changeUserMenuOpenForGuideTourState(false);
    setIsPreGuideTourModalOpen(false);
    setIsGuideTourOpen(false);
    checkIfShouldSkipAndUpdate();
  };

  const steps = [
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.changeOrganisationAndBoard.selector}"]`,
      title: t(GUIDE_TOUR_DATA.changeOrganisationAndBoard.titleTranslation),
      content: t(GUIDE_TOUR_DATA.changeOrganisationAndBoard.contentTranslation),
      backButtonCallback: () => {
        setIsGuideTourOpen(false);
        setIsPreGuideTourModalOpen(true);
      },
      nextButtonCallback: () => {
        setIsPreGuideTourModalOpen(false);
      },
    },
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.createNewDocument.selector}"]`,
      title: t(GUIDE_TOUR_DATA.createNewDocument.titleTranslation),
      content: t(GUIDE_TOUR_DATA.createNewDocument.contentTranslation),
      nextButtonCallback: () => {
        changeUserMenuOpenForGuideTourState(true);
      },
    },
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.userMenu.selector}"]`,
      title: t(GUIDE_TOUR_DATA.userMenu.titleTranslation),
      content: t(GUIDE_TOUR_DATA.userMenu.contentTranslation),
      backButtonCallback: () => {
        changeUserMenuOpenForGuideTourState(false);
      },
      nextButtonCallback: handleClose,
    },
  ].map(step => ({ disableBeacon: true, ...step }));

  return (
    <>
      <Modal
        sx={{
          textAlign: 'center',
        }}
        isOpen={isPreGuideTourModalOpen}
        onClose={() => {
          checkIfShouldSkipAndUpdate();
          handleClose();
        }}
        size="md"
        defaultButtonText={t('common:skip')}
        defaultButtonAction={() => {
          checkIfShouldSkipAndUpdate();
          handleClose();
        }}
        primaryButtonText={t('user:seeFunctionalities')}
        primaryButtonAction={() => {
          setIsGuideTourOpen(true);
          setIsPreGuideTourModalOpen(false);
        }}
      >
        <FeedbackIcon
          sx={theme => ({
            marginBottom: theme.other.spacing(0.5),
          })}
          color="primary"
        />
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(0.5),
          })}
          weight={600}
        >
          <Trans
            i18nKey="user:guideTour.greeting"
            values={{
              name: getUserFullName(name, surname),
            }}
            components={{
              br: <br />,
            }}
          />
        </Text>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          size="md"
          weight={400}
        >
          {t('user:guideTour.intro')}
        </Text>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          size="md"
          weight={400}
        >
          {t('user:guideTour.intro2')}
        </Text>
        <Checkbox
          label={t('user:dontShowAnymore')}
          checked={isSkipCheckboxChecked}
          onChange={e => {
            setIsSkipCheckboxChecked(e.target.checked);
          }}
        />
      </Modal>
      {isGuideTourOpen && <GuideTour steps={steps} onClose={handleClose} />}
    </>
  );
};
