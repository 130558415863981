import { useTranslation } from 'react-i18next';

import { decodeYamlDataType } from 'helpers';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';
import { YamlEditor } from 'components/Transactions';

type Props = {
  yaml: string;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const DataTypeViewModal = ({ isOpen, onClose, yaml }: Props) => {
  const { t } = useTranslation(['common', 'dataTypes']);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('dataTypes:previewDataType')}
      defaultButtonAction={onClose}
      defaultButtonText={t('common:close')}
    >
      <YamlEditor
        sx={theme => ({
          marginBottom: theme.other.spacing(-3),
        })}
        value={decodeYamlDataType(yaml)}
        readOnly
      />
    </Modal>
  );
};
