import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, DefaultProps } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import { DataStreamAddTemplateModal } from '../DataStreamAddTemplateModal';

type Props = {
  sx: DefaultProps['sx'];
  templates: DataStreamTemplateType[];
};

export const DataStreamAddTemplate = ({ sx, templates }: Props) => {
  const { t } = useTranslation('dataStreams');

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  return (
    <Box sx={sx}>
      <Button
        variant="light"
        color="green"
        onClick={() => {
          setIsAddModalOpen(true);
        }}
      >
        {t('dataStreams:addNewTemplate')}
      </Button>
      <DataStreamAddTemplateModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
        }}
        templates={templates}
      />
    </Box>
  );
};
