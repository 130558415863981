import { ActionIcon, Box, Menu } from '@mantine/core';

import { ContextMenuIcon } from 'icons';

import { ActionsBarPropsType } from '../types';
import { ActionItem } from '../ActionItem';

const ITEM_HEIGHT = 44;

export const MobileView = ({
  items,
  label,
  disabled: areAllActionsDisabled = false,
}: ActionsBarPropsType) => (
  <Menu>
    <Menu.Target>
      <ActionIcon
        sx={theme => ({
          width: 60,
          height: 60,
          position: 'fixed',
          right: 20,
          bottom: 80,
          zIndex: 101,
          backgroundColor: theme.black,
          borderRadius: '50%',
          boxShadow: theme.shadows.sm,
          ':hover': {
            backgroundColor: theme.black,
          },
        })}
      >
        <ContextMenuIcon
          sx={{
            transform: 'rotate(90deg)',
          }}
          color="white"
        />
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
      <ul>
        {Boolean(label) && (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              height: ITEM_HEIGHT,
              padding: theme.other.spacing(0.75, 2),
              fontSize: theme.fontSizes.lg,
              fontWeight: 600,
              minHeight: 44,
            })}
            component="li"
          >
            {label}
          </Box>
        )}
        {items.map(
          ({ name, icon, color, isLoading, disabled, ...actionOrToProp }) => (
            <li key={name}>
              <ActionItem
                {...actionOrToProp}
                sx={{ width: '100%' }}
                buttonSx={{
                  display: 'flex',
                  width: '100%',
                  height: ITEM_HEIGHT,
                  margin: 0,
                }}
                name={name}
                icon={icon}
                color={color}
                disabled={areAllActionsDisabled || disabled}
                isLoading={isLoading}
                withSeparator={false}
              />
            </li>
          ),
        )}
      </ul>
    </Menu.Dropdown>
  </Menu>
);
