import { CALENDAR_TYPES } from './calendarTypes';

export const CALENDAR_TYPE_ITEMS = [
  {
    nameTranslation: 'common:datePicker.chooseDate',
    type: CALENDAR_TYPES.single,
  },
  {
    nameTranslation: 'common:datePicker.chooseRange',
    type: CALENDAR_TYPES.range,
  },
  {
    nameTranslation: 'common:datePicker.fromDate',
    type: CALENDAR_TYPES.from,
  },
  {
    nameTranslation: 'common:datePicker.toDate',
    type: CALENDAR_TYPES.to,
  },
];
