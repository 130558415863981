import Countdown from 'react-countdown';

type Props = {
  validUntil: string;
};

export const LoginOTPCountdown = ({ validUntil }: Props) => (
  <Countdown
    date={validUntil}
    renderer={({ formatted }) => `${formatted.minutes}:${formatted.seconds}`}
  />
);
