import { apiAuthClient } from 'api/helpers';

import {
  DeleteAuthLogoutRequestType,
  DeleteAuthLogoutResponseType,
} from '../types';

export const deleteAuthLogout = async ({
  headers,
}: DeleteAuthLogoutRequestType) => {
  const url = '/api/v2/auth/logout';

  const { data } = await apiAuthClient.delete<DeleteAuthLogoutResponseType>(
    url,
    { headers },
  );

  return data;
};
