import { LinkType } from 'components/Layout/Navbar/types';

export const filterLinksByHidden = (links: LinkType[]) =>
  links.reduce((accumulator, link) => {
    const nestedLinks = (
      link.links ? filterLinksByHidden(link.links) : []
    ) as LinkType[];

    return link.hidden || (link.links && nestedLinks.length === 0)
      ? accumulator
      : [
          ...accumulator,
          {
            ...link,
            ...(link.links ? { links: nestedLinks } : false),
          } as LinkType,
        ];
  }, [] as LinkType[]);
