import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

export const Hint = ({ inputProps, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.hint')}
      description={t('templates:templateCreatorVariables.hintDescription')}
      error={error}
    >
      <TextInput
        {...inputProps}
        placeholder={t('templates:templateCreatorVariables.hint')}
        withErrorWrapperAlwaysEnabled={false}
      />
    </DescriptionField>
  );
};
