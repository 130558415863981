import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const TrashIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.8 7.2V5.6c0-.59.478-1.067 1.067-1.067h4.266c.59 0 1.067.478 1.067 1.067v1.6M4 7.733h16m-14.4 0V18.4c0 .59.478 1.067 1.067 1.067h10.666c.59 0 1.067-.478 1.067-1.067V7.733M12 11.467V16.8m-3.2-3.2v3.2m6.4-3.2v3.2"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
