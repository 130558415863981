import { Link } from 'react-router-dom';
import { Anchor, Navbar as MantineNavbar } from '@mantine/core';

import { LogoIcon } from 'icons';
import { URLS } from 'consts';

import { BoardSelect } from '../BoardSelect';
import { NavbarFull } from './NavbarFull';
import { NavbarMini } from './NavbarMini';

type Props = {
  isOpen: boolean;
};

export const Navbar = ({ isOpen }: Props) => (
  <MantineNavbar>
    <MantineNavbar.Section
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: theme.other.layoutSizes.headerHeight,
        backgroundColor: isOpen
          ? theme.colors[theme.primaryColor][9]
          : undefined,
      })}
    >
      {isOpen ? (
        <BoardSelect />
      ) : (
        <Anchor
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          component={Link}
          to={URLS.home}
        >
          <LogoIcon color="black" />
        </Anchor>
      )}
    </MantineNavbar.Section>
    {isOpen ? <NavbarFull /> : <NavbarMini />}
  </MantineNavbar>
);
