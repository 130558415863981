import { apiAuthClient } from 'api/helpers';

import { PatchUserNotificationsRequestType } from '../types';

export const patchUserNotifications = async ({
  bodyParams,
}: PatchUserNotificationsRequestType) => {
  const url = '/api/v2/auth/user/notifications';

  const { data } = await apiAuthClient.patch<{ data: '' }>(url, bodyParams);

  return data;
};
