import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

type InvitationFlowModalContextProps = {
  invitationId: string | null;
  isModalOpen: boolean;
  openModal: ({ invitationId }: { invitationId: string }) => void;
  closeModal: () => void;
};

export const InvitationFlowModalContext =
  createContext<InvitationFlowModalContextProps>({
    invitationId: null,
    isModalOpen: false,
    openModal: ({ invitationId }: { invitationId: string }) => {},
    closeModal: () => {},
  });

export const InvitationFlowModalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [invitationIdentifier, setInvitationIdentifier] = useState<
    string | null
  >(null);

  const openModal = useCallback(
    ({ invitationId }: { invitationId: string }) => {
      setInvitationIdentifier(invitationId);
      setModalOpen(true);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setInvitationIdentifier(null);
    setModalOpen(false);
  }, []);

  return (
    <InvitationFlowModalContext.Provider
      value={useMemo(
        () => ({
          invitationId: invitationIdentifier,
          isModalOpen,
          openModal,
          closeModal,
        }),
        [invitationIdentifier, isModalOpen, openModal, closeModal],
      )}
    >
      {children}
    </InvitationFlowModalContext.Provider>
  );
};
