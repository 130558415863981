import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getSso } from '../requests';
import { GetSsoRequestType } from '../types';

export const useGetSso = (
  { headers, pathParams: { ssoDriver } }: GetSsoRequestType,
  options?: {
    enabled?: boolean;
    onSuccess?: (redirectTo: string) => void;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.sso, { ssoDriver }],
    queryFn: () => getSso({ headers, pathParams: { ssoDriver } }),
    ...options,
  });
