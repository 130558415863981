import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { TemplateDescriptionField } from '../TemplateDescriptionField';
import { TemplateTypeRadio } from './TemplateTypeRadio';

type Props = {
  error: string | undefined;
  templateTypeRadioProps: UseFormRegisterReturn;
};

export const TemplateType = ({ error, templateTypeRadioProps }: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:templateType')}
      description={t('templates:templateTypeDescription')}
      error={error}
    >
      <TemplateTypeRadio templateTypeRadioProps={templateTypeRadioProps} />
    </TemplateDescriptionField>
  );
};
