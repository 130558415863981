import { MarginType } from '../types';

import { convertCmToPxInPdf } from './convertCmToPxInPdf';

export const getPdfMarginInPx = ({ top, right, bottom, left }: MarginType) => ({
  top: convertCmToPxInPdf(top),
  right: convertCmToPxInPdf(right),
  bottom: convertCmToPxInPdf(bottom),
  left: convertCmToPxInPdf(left),
});
