import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mantine/core';

import { usePatchTransaction } from 'api/transactions';
import { HTTP_HEADERS } from 'consts';
import { TransactionType } from 'types';

import { DataSettingsType, RequestDataSettingsType } from '../types';

type Props = {
  transaction: TransactionType;
  isDataEditable: boolean;
  dataSettings: DataSettingsType;
  setIsDataSaving: (saving: boolean) => void;
  toggleIsDataEditable: () => void;
};

export const DataAction = ({
  transaction,
  isDataEditable,
  dataSettings,
  setIsDataSaving,
  toggleIsDataEditable,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const { transactionDataStream, transactionId } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
  };

  const { mutateAsync, isLoading } = usePatchTransaction({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStream,
    },
    headers: {
      [HTTP_HEADERS.xTransactionEntryId]: transaction.id,
    },
  });

  const toSave = isDataEditable
    ? dataSettings.reduce(
        (accumulator, setting, index) =>
          setting.lock !== transaction.documents[index].settings.lock ||
          setting.delete !== transaction.documents[index].settings.delete
            ? [
                ...accumulator,
                {
                  id: transaction.documents[index].id,
                  settings: {
                    lock: setting.lock,
                    delete: setting.delete,
                  },
                },
              ]
            : accumulator,
        [] as RequestDataSettingsType['documents'],
      )
    : [];

  const handleClick = async () => {
    if (isDataEditable) {
      if (toSave.length > 0) {
        setIsDataSaving(true);
        await mutateAsync({ documents: toSave });
      }
      setIsDataSaving(false);
    }
    toggleIsDataEditable();
  };

  return (
    <Button
      variant={isDataEditable ? 'filled' : 'default'}
      color={isDataEditable ? 'blue' : undefined}
      loading={isLoading}
      onClick={handleClick}
    >
      {isDataEditable ? t('common:save') : t('transactions:manageData')}
    </Button>
  );
};
