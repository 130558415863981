import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Anchor, Group, Text } from '@mantine/core';

import { ErrorTwoToneIcon, WarningTwoToneIcon } from 'icons';
import { TransactionEntryType } from 'types';
import { Table } from 'components/shared';
import { DocumentInfoModal, InfoStatus } from 'components/Transactions';

import { FileInfo } from '../../../TransactionDetails/DetailPanels/DataPanel/FileInfo';

type Props = {
  documents: TransactionEntryType['documents'];
};

export const DataPanel = ({ documents }: Props) => {
  const { t } = useTranslation(['common', 'transactions']);
  const { transactionId } = useParams() as {
    transactionId: string;
  };

  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );

  const selectedDocument = selectedDocumentId
    ? documents.find(({ id }) => id === selectedDocumentId)
    : null;

  return (
    <>
      <Table hasData={documents.length > 0}>
        <>
          <thead>
            <tr>
              <th>{t('transactions:transactionDetails.documentName')}</th>
              <th>{t('transactions:transactionDetails.templateName')}</th>
            </tr>
          </thead>
          <tbody>
            {documents.map(
              ({ documentName, id, status, template, variables }) => {
                const hasDocumentInfoModalData =
                  status.errors.length > 0 ||
                  status.warnings.length > 0 ||
                  Object.keys(variables).length > 0;

                return (
                  <tr key={id}>
                    <td>
                      <Text
                        sx={{
                          display: 'block',
                        }}
                        span
                        color="gray.7"
                      >
                        {t('common:id')}: {id}
                      </Text>
                      {hasDocumentInfoModalData ? (
                        <Anchor
                          sx={{
                            maxWidth: '100%',
                          }}
                          component="button"
                          onClick={() => {
                            setSelectedDocumentId(id);
                          }}
                        >
                          {documentName}
                        </Anchor>
                      ) : (
                        documentName
                      )}
                      <Group ml="-xs" spacing="lg">
                        <InfoStatus
                          count={status.errors.length}
                          label={t('transactions:errors')}
                          icon={ErrorTwoToneIcon}
                          iconColor="error"
                        />
                        <InfoStatus
                          count={status.warnings.length}
                          label={t('transactions:warnings')}
                          icon={WarningTwoToneIcon}
                          iconColor="yellow.0"
                        />
                      </Group>
                    </td>
                    <td>
                      {template.fromFile ? (
                        <FileInfo
                          sizeInBytes={template.size}
                          extension={template.extension}
                        />
                      ) : (
                        template.name
                      )}
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </>
      </Table>
      <DocumentInfoModal
        isOpen={Boolean(selectedDocumentId)}
        onClose={() => {
          setSelectedDocumentId(null);
        }}
        transactionId={transactionId}
        errors={selectedDocument?.status?.errors || []}
        warnings={selectedDocument?.status?.warnings || []}
        variables={selectedDocument?.variables || {}}
      />
    </>
  );
};
