import { getUserFullName } from 'helpers';
import { OrganisationUserListType } from 'types';

import { SelectedOrganisationMembersType } from '../../../../../types';

export const getSelectedMembersFullNames = (
  currentOrganisationMembers: OrganisationUserListType[],
  selectedOrganisationMembers: SelectedOrganisationMembersType,
) => {
  if (selectedOrganisationMembers.length === 0) {
    return currentOrganisationMembers.map(({ name, surname }) =>
      getUserFullName(name, surname),
    );
  }

  return currentOrganisationMembers
    .filter(({ id }) =>
      (
        selectedOrganisationMembers as OrganisationUserListType['id'][]
      ).includes(id),
    )
    .map(({ name, surname }) => getUserFullName(name, surname));
};
