import { useTranslation } from 'react-i18next';
import { Accordion } from '@mantine/core';

import { TransactionNotificationAccordionItem } from 'components/Transactions/TransactionNotificationAccordionItem';

type Props = {
  errors: string[];
  warnings: string[];
};

export const ErrorsAndWarnings = ({ errors, warnings }: Props) => {
  const { t } = useTranslation('transactions');

  const hasErrors = errors.length > 0;
  const hasWarnings = warnings.length > 0;
  const accordionDefaultValue = [
    hasErrors && 'errors',
    hasWarnings && 'warnings',
  ].filter(Boolean) as string[];

  return (
    <Accordion multiple defaultValue={accordionDefaultValue}>
      {hasErrors && (
        <TransactionNotificationAccordionItem
          value="errors"
          title={t('transactions:transactionModalErrors')}
          iconType="error"
          items={errors}
        />
      )}
      {hasWarnings && (
        <TransactionNotificationAccordionItem
          value="warnings"
          title={t('transactions:transactionModalWarnings')}
          iconType="warning"
          items={warnings}
        />
      )}
    </Accordion>
  );
};
