import { apiAuthClient } from 'api/helpers';

import {
  GetOrganisationBoardsRequestType,
  GetOrganisationBoardsResponseType,
} from '../types';

export const getOrganisationBoards = async ({
  pathParams: { organisationId },
}: GetOrganisationBoardsRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards`;

  const { data } = await apiAuthClient.get<GetOrganisationBoardsResponseType>(
    url,
  );

  return data;
};
