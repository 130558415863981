import { ReactNode } from 'react';
import { Box, LoadingOverlay, useMantineTheme } from '@mantine/core';

import { useIsLgBreakpoint } from 'hooks';

import { HelpfulLinks } from './HelpfulLinks';

import { Header } from './Header';

type Props = {
  primaryContent: ReactNode;
  secondaryContent: ReactNode;
  backButtonAction?: () => void;
  backButtonActionText?: string;
  isSecondaryContentLoading?: boolean;
};

export const NonLoggedLayout = ({
  primaryContent,
  secondaryContent,
  backButtonAction,
  backButtonActionText,
  isSecondaryContentLoading = false,
}: Props) => {
  const mantineTheme = useMantineTheme();

  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <Box
      sx={theme => ({
        maxWidth: theme.other.layoutSizes.containerWidth,
        height: '100%',
        margin: 'auto',
        position: 'relative',
        padding: isLgBreakpoint ? undefined : theme.other.spacing(4),
      })}
    >
      <Header
        backButtonAction={backButtonAction}
        backButtonActionText={backButtonActionText}
      />
      <Box
        sx={{
          display: 'flex',
          height: isLgBreakpoint
            ? '100%'
            : `calc(100% - ${mantineTheme.other.layoutSizes.headerHeight}px)`,
        }}
      >
        {isLgBreakpoint && <Box sx={{ width: '50%' }}>{primaryContent}</Box>}
        <Box sx={{ width: isLgBreakpoint ? '50%' : '100%' }}>
          <Box
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: 344,
                height: isSecondaryContentLoading ? '100%' : undefined,
                margin: 'auto',
                position: 'relative',
              }}
            >
              {isSecondaryContentLoading ? (
                <LoadingOverlay visible />
              ) : (
                secondaryContent
              )}
            </Box>
            <Box
              sx={theme => ({
                marginTop: isLgBreakpoint ? undefined : theme.other.spacing(16),
                marginBottom: theme.other.spacing(2),
              })}
            >
              <HelpfulLinks />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
