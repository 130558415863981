import { ThemeComponentType } from '../types';

export const Alert: ThemeComponentType = {
  defaultProps: {
    radius: 0,
  },
  styles: theme => ({
    root: {
      padding: theme.other.spacing(3, 2),
    },
    wrapper: {
      alignItems: 'center',
    },
    message: {
      fontSize: theme.fontSizes.md,
    },
    icon: {
      marginRight: theme.other.spacing(1),
      width: 'fit-content',
      height: 'fit-content',
    },
  }),
};
