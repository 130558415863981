import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import {
  useGetCustomizationsDocument,
  useGetOrganisation,
} from 'api/organisations';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';
import { OrganisationTabs } from 'components/Organisations';

import { Branding } from './Branding';

export const OrganisationBranding = () => {
  const { t } = useTranslation(['common', 'organisations']);

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const {
    data: organisation,
    isError: isOrganisationError,
    isFetched: isOrganisationFetched,
    isInitialLoading: isOrganisationInitialLoading,
  } = useGetOrganisation({
    pathParams: {
      organisationId,
    },
  });

  const hasBrandingFeature = getHasHateoasAccess(
    'documentBranding',
    organisation?.__links,
  );

  const {
    data: brandingCustomizationConfig,
    dataUpdatedAt: brandingCustomizationUpdatedAt,
    isError: isBrandingCustomizationConfigError,
    isInitialLoading: isBrandingCustomizationConfigInitialLoading,
    isRefetching: isBrandingCustomizationConfigRefetching,
  } = useGetCustomizationsDocument({
    enabled: hasBrandingFeature,
  });

  return (
    <>
      <MetaTitle value={t('organisations:organisationManagement')} />
      <DataWrapper
        data={brandingCustomizationConfig}
        isNoAccess={isOrganisationFetched && !hasBrandingFeature}
        isError={isOrganisationError || isBrandingCustomizationConfigError}
        isLoading={
          isOrganisationInitialLoading ||
          isBrandingCustomizationConfigInitialLoading
        }
        errorMargin="xl"
      >
        {brandingCustomization => {
          const { name } = organisation as Exclude<
            typeof organisation,
            undefined
          >;
          const { headedPaperUrl, margin } = brandingCustomization;

          return (
            <>
              <Breadcrumbs
                sx={theme => ({
                  marginBottom: theme.other.spacing(4.5),
                })}
                items={[
                  { name: t('organisations:organisationManagement'), url: '#' },
                  {
                    name,
                    url: '#',
                  },
                ]}
              />
              <OrganisationTabs />
              <Text
                sx={theme => ({
                  marginBottom: theme.other.spacing(4),
                })}
                size="md"
              >
                <Trans
                  i18nKey="organisations:brandingDescription"
                  components={{ b: <b /> }}
                />
              </Text>
              <Branding
                key={brandingCustomizationUpdatedAt}
                headedPaperUrl={headedPaperUrl}
                margin={margin}
                isRefetching={isBrandingCustomizationConfigRefetching}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
