import { Dispatch, forwardRef, SetStateAction, useState } from 'react';
import { packSx, Popover } from '@mantine/core';

import { useGetUserDateInputFormat } from 'hooks';
import { CalendarIcon } from 'icons';
import { DateRangeValueType } from 'types';
import { TextInput } from 'components/shared';
import { Props as TextInputProps } from 'components/shared/Inputs/TextInput';

import { CALENDAR_TYPES } from './consts';
import { getIsParsedDateValid } from './helpers';
import { useGetIsoDateByUserFormat, useGetUserDateRegExp } from './hooks';
import { DatePicker } from './DatePicker';

type Props = {
  date: [DateRangeValueType] | [DateRangeValueType, DateRangeValueType];
  setDate: Dispatch<
    SetStateAction<
      [DateRangeValueType] | [DateRangeValueType, DateRangeValueType]
    >
  >;
} & TextInputProps;

export const DatePickerInput = forwardRef<HTMLInputElement, Props>(
  ({ sx, date, setDate, ...dateRangePickerMantineProps }, ref) => {
    const userDateInputFormat = useGetUserDateInputFormat();
    const userDateRegExp = useGetUserDateRegExp();
    const getIsoDateStringByUserFormat = useGetIsoDateByUserFormat();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [calendarType, setCalendarType] = useState<
      (typeof CALENDAR_TYPES)[keyof typeof CALENDAR_TYPES]
    >(CALENDAR_TYPES.single);
    /* TODO: add initial date here */
    const [inputValue, setInputValue] = useState('');

    const resetCalendarTypeOnPopoverOpenWhenDateIsNotSelected = (
      isPopoverOpenNextState: boolean,
    ) => {
      if (isPopoverOpenNextState && !date.some(Boolean)) {
        setCalendarType(CALENDAR_TYPES.single);
      }
    };

    return (
      <Popover
        opened={isPopoverOpen}
        onChange={open => {
          setIsPopoverOpen(open);
          resetCalendarTypeOnPopoverOpenWhenDateIsNotSelected(open);
        }}
      >
        <Popover.Target>
          <TextInput
            sx={[
              {
                minWidth: 305,
                '.ClearableInputRightSection_clearableIconBox': {
                  pointerEvents: 'initial',
                  touchAction: 'initial',
                },
              },
              ...packSx(sx),
            ]}
            styles={{
              rightSection: {
                pointerEvents: 'none',
                touchAction: 'none',
              },
            }}
            ref={ref}
            placeholder={`${userDateInputFormat.toUpperCase()} - ${userDateInputFormat.toUpperCase()}`}
            rightIcon={<CalendarIcon />}
            isClearableButtonVisible={isPopoverOpen && date.some(Boolean)}
            value={inputValue}
            onChange={e => {
              const { value } = e.target;

              setInputValue(value);

              if (userDateRegExp.singleDate.test(value)) {
                if (getIsParsedDateValid(value, userDateInputFormat)) {
                  setCalendarType(CALENDAR_TYPES.single);
                  setDate([getIsoDateStringByUserFormat(value)]);
                }
              } else if (userDateRegExp.fromDate.test(value)) {
                const dateFromValue = value.replace(' -', '');

                if (getIsParsedDateValid(dateFromValue, userDateInputFormat)) {
                  setCalendarType(CALENDAR_TYPES.from);

                  setDate([getIsoDateStringByUserFormat(dateFromValue), null]);
                }
              } else if (userDateRegExp.toDate.test(value)) {
                const dateFromValue = value.replace('- ', '');

                setCalendarType(CALENDAR_TYPES.to);
                setDate([null, getIsoDateStringByUserFormat(dateFromValue)]);
              } else if (userDateRegExp.rangeDate.test(value)) {
                const values = value.split(' - ') as [
                  DateRangeValueType,
                  DateRangeValueType,
                ];

                if (
                  values.every(val =>
                    getIsParsedDateValid(val as string, userDateInputFormat),
                  )
                ) {
                  setCalendarType(CALENDAR_TYPES.range);
                  setDate(
                    values.map(getIsoDateStringByUserFormat) as [
                      DateRangeValueType,
                      DateRangeValueType,
                    ],
                  );
                }
              } else {
                setCalendarType(CALENDAR_TYPES.single);
                setDate([null]);
              }
            }}
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
              resetCalendarTypeOnPopoverOpenWhenDateIsNotSelected(
                !isPopoverOpen,
              );
            }}
            {...dateRangePickerMantineProps}
          />
        </Popover.Target>
        <Popover.Dropdown
          sx={theme => ({
            padding: theme.other.spacing(3.5, 3, 3.5, 2),
          })}
        >
          <DatePicker
            calendarType={calendarType}
            setCalendarType={setCalendarType}
            date={date}
            setDate={setDate}
            setInputValue={setInputValue}
          />
        </Popover.Dropdown>
      </Popover>
    );
  },
);
