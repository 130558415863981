import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Button, Text, Tooltip } from '@mantine/core';

import { LetterTwoToneSendIcon } from 'icons';
import { useIsLgBreakpoint } from 'hooks';
import { URLS } from 'consts';

import { EmailSentTooltipLabel } from 'components/shared';

type Props = {
  email: string;
  onBackToForm: () => void;
};

export const EmailSendConfirmation = ({ email, onBackToForm }: Props) => {
  const { t } = useTranslation('user');

  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <div>
      <LetterTwoToneSendIcon
        sx={theme => ({
          display: 'block',
          margin: theme.other.spacing(0, 'auto', 1),
        })}
        size={180}
        color="green"
      />
      <Text mb="xl">
        <Trans
          i18nKey="user:resetPasswordSent"
          values={{ email }}
          components={{ b: <b /> }}
        />
      </Text>
      <Button
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        component={Link}
        to={URLS.home}
        fullWidth
        size="lg"
      >
        {t('user:login')}
      </Button>
      <Box
        sx={theme => ({
          marginTop: theme.other.spacing(-1),
          textAlign: 'center',
        })}
      >
        <Text span size="md">
          {isLgBreakpoint ? (
            <Tooltip label={<EmailSentTooltipLabel />}>
              <span>{t('user:emailNotArrive')}</span>
            </Tooltip>
          ) : (
            t('user:emailNotArrive')
          )}
        </Text>{' '}
        <Anchor component="button" onClick={onBackToForm}>
          {t('user:backToForm')}
        </Anchor>
      </Box>
    </div>
  );
};
