import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { Microsoft365LogoIcon, GoogleLogoIcon } from 'icons';
import { SSO_LOGIN_METHODS } from 'consts';

import { SsoLoginMethod } from './SsoLoginMethod';

const ssoData: {
  [method in keyof typeof SSO_LOGIN_METHODS]?: {
    nameTranslation: string;
    icon: ReactNode;
  };
} = {
  [SSO_LOGIN_METHODS.azure]: {
    nameTranslation: 'user:azure',
    icon: <Microsoft365LogoIcon sx={{ position: 'relative', top: 1 }} />,
  },
  [SSO_LOGIN_METHODS.google]: {
    nameTranslation: 'user:google',
    icon: <GoogleLogoIcon />,
  },
};

export const SsoLoginMethods = () => {
  const { t } = useTranslation('user');

  const ssoDataEntries = Object.entries(ssoData);

  return (
    <ul>
      {ssoDataEntries.map(([method, { nameTranslation, icon }], index) => (
        <Box
          sx={theme => ({
            marginBottom:
              index === ssoDataEntries.length - 1
                ? undefined
                : theme.other.spacing(1),
          })}
          key={method}
        >
          <SsoLoginMethod
            method={method as keyof typeof SSO_LOGIN_METHODS}
            label={t(nameTranslation)}
            icon={icon}
          />
        </Box>
      ))}
    </ul>
  );
};
