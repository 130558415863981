import { useMantineTheme } from '@mantine/core';

import { getMantineColorAndShade } from 'helpers';

export const useGetColor = (color = 'currentColor') => {
  const mantineTheme = useMantineTheme();
  const { colors } = mantineTheme;

  const [baseColor, shadeColor] = getMantineColorAndShade(color);

  const inColors = baseColor in colors;

  const primaryShade = inColors ? 0 : mantineTheme.fn.primaryShade();
  const shade = shadeColor ?? primaryShade;

  switch (true) {
    case baseColor === 'dimmed':
      return mantineTheme.colorScheme === 'dark'
        ? mantineTheme.colors.dark[2]
        : mantineTheme.colors.gray[6];

    case baseColor === 'primary':
      return mantineTheme.colors[mantineTheme.primaryColor][shade];

    case baseColor === 'white':
      return mantineTheme.white;

    case baseColor === 'black':
      return mantineTheme.black;

    case baseColor === 'error':
      return mantineTheme.colors.red[mantineTheme.fn.primaryShade()];

    case inColors:
      return colors[baseColor][shade];

    default:
      return baseColor;
  }
};
