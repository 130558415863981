import { apiAuthClient } from 'api/helpers';

import { PostDataTypeRequestType, PostDataTypeResponseType } from '../types';

export const postDataType = async ({
  pathParams: { organisationId },
  bodyParams,
}: PostDataTypeRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-types`;

  const { data } = await apiAuthClient.post<PostDataTypeResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
