import { Helmet } from 'react-helmet-async';
import { useOs } from '@mantine/hooks';

export const UserScalable = () => {
  const os = useOs();

  return (
    <Helmet>
      <meta
        name="viewport"
        content={`width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=${
          os === 'android' ? 'yes' : 'no'
        }`}
      />
    </Helmet>
  );
};
