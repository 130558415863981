import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import {
  TransactionDocumentQueueType,
  TransactionDocumentType,
  TransactionQueueParticipantType,
  TransactionQueueGroupType,
} from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';
import { ParticipantListItems } from './ParticipantList';
import {
  TransactionQueueGroupWithParticipantType,
  TransactionQueueProcessedParticipantType,
} from './types';

type Props = {
  documentName: TransactionDocumentType['documentName'];
  queue: TransactionDocumentQueueType['queue'];
  queueErrors: TransactionDocumentQueueType['errors'];
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

const mapQueueTypes = {
  acceptors: 'acceptor',
  signers: 'signer',
};

const processItems = (
  participants: TransactionQueueParticipantType[][],
  groups: TransactionQueueGroupType[],
  type: string,
): TransactionQueueProcessedParticipantType[][] => {
  if (!groups || groups.length === 0) {
    return participants;
  }

  return participants.map(current => {
    let stepData = [...current];
    const currentStep = stepData[0]?.step;
    const groupsOnGivenStep = groups.filter(
      group => group.queueRole === type && group.queueStep === currentStep,
    );
    const groupsFilledWithUsers = groupsOnGivenStep.map(group => {
      const processedUsers = group.users.map(user => {
        const matchedUser = current.find(
          queueParticipant => queueParticipant.email === user,
        );

        if (matchedUser) {
          stepData = stepData.filter(step => step.email !== user);
          return matchedUser;
        }
        return user;
      });
      return {
        ...group,
        users: processedUsers,
      } as TransactionQueueGroupWithParticipantType;
    });

    return [...stepData, ...groupsFilledWithUsers];
  });
};

export const QueueInfoModal = ({
  isOpen,
  onClose,
  documentName,
  queue,
  queueErrors,
}: Props) => {
  const { t } = useTranslation('common');
  const { acceptors, signers, groups } = queue;

  const processedAcceptors = processItems(
    acceptors,
    groups,
    mapQueueTypes.acceptors,
  );
  const processedSigners = processItems(signers, groups, mapQueueTypes.signers);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={documentName}
      defaultButtonAction={onClose}
      defaultButtonText={t('common:back')}
      size="lg"
    >
      <Box sx={{ margin: '12px 2px 0' }} component="ul">
        <ParticipantListItems
          type="acceptors"
          participants={processedAcceptors}
          queueErrors={queueErrors}
        />
        <ParticipantListItems
          type="signers"
          participants={processedSigners}
          queueErrors={queueErrors}
        />
      </Box>
    </Modal>
  );
};
