import { ThemeComponentType } from '../types';

export const Menu: ThemeComponentType = {
  defaultProps: {
    shadow: 'lg',
  },
  styles: theme => ({
    dropdown: {
      minWidth: 180,
      borderColor: theme.colors.gray[1],
      boxShadow: theme.shadows.xs,
      '&': {
        padding: theme.other.spacing(0.5, 0),
      },
    },
    item: {
      padding: theme.other.spacing(1, 2),
      borderRadius: 0,
      fontSize: theme.fontSizes.lg,
      fontWeight: 500,
      '&[data-hovered]': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
    },
    label: {
      padding: theme.other.spacing(1, 2),
      fontSize: theme.fontSizes.md,
      fontWeight: 600,
      color: theme.colors.gray[5],
    },
    itemLabel: {
      whiteSpace: 'nowrap',
    },
    itemIcon: {
      marginRight: theme.other.spacing(0.75),
    },
    divider: {
      margin: theme.other.spacing(0.5, 2),
      borderTopColor: theme.colors.gray[1],
    },
  }),
};
