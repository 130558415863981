import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EyeIcon } from 'icons';
import { Actions } from 'components/shared';

import { DataTypeViewModal } from '../DataTypeViewModal';

type Props = {
  yaml: string;
};

export const DataTypeActions = ({ yaml }: Props) => {
  const { t } = useTranslation('common');

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  return (
    <>
      <Actions
        items={[
          {
            name: t('common:preview'),
            icon: <EyeIcon />,
            colorDesktop: 'blue',
            action: () => {
              setIsViewModalOpen(true);
            },
          },
        ]}
      />
      <DataTypeViewModal
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
        }}
        yaml={yaml}
      />
    </>
  );
};
