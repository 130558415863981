import { useTranslation } from 'react-i18next';

import { ValidationErrors } from 'types';

export const useGetDataStreamTemplatesItemFormattedErrors = () => {
  const { t } = useTranslation('dataStreams');

  const getDataStreamTemplatesItemErrorKey = (errorKey: string) => {
    switch (errorKey) {
      case 'documentContent':
        return t('dataStreams:editModal.documentContent');
      case 'documentExternalId':
        return t('dataStreams:editModal.externalId');
      case 'documentName':
        return t('dataStreams:editModal.documentName');
      case 'documentReference':
        return t('dataStreams:editModal.documentReference');
      case 'isBlocked':
        return t('dataStreams:editModal.dontUpdate');
      case 'templateId':
        return t('dataStreams:editModal.templateId');
      default:
        return errorKey;
    }
  };

  return (errors: ValidationErrors) =>
    Object.entries(errors).reduce(
      (accumulator, [errorKey, errorMessage]) => ({
        ...accumulator,
        [getDataStreamTemplatesItemErrorKey(errorKey)]: (
          errorMessage as string
        ).split('\n'),
      }),
      {},
    );
};
