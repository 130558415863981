import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import {
  useGetTransactionDataStream,
  usePatchTransactionDataStream,
} from 'api/transactions';
import { useServerErrors, useShowNotification } from 'hooks';
import { DataStreamTemplateType } from 'types';
import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  name:
    | DataStreamTemplateType['templateName']
    | DataStreamTemplateType['documentContent'];
  type: 'file' | 'template';
  templateIndex: number;
};

export const DataStreamDeleteTemplateModal = ({
  isOpen,
  onClose,
  name,
  type,
  templateIndex,
}: Props) => {
  const { t } = useTranslation('dataStreams');
  const { transactionId, transactionDataStream: transactionDataStreamParam } =
    useParams() as {
      transactionDataStream: string;
      transactionId: string;
    };

  const showNotification = useShowNotification();
  const { addServerErrorActions } = useServerErrors();

  const isTemplateType = type === 'template';

  const {
    mutateAsync: updateTransactionDataStream,
    isLoading: isDataStreamDeleting,
  } = usePatchTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStreamParam,
    },
  });
  const { data: transactionDataStream } = useGetTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStreamParam,
    },
  });

  const deleteDataStream = async () => {
    try {
      const templatesToUpdate = transactionDataStream!.templates
        .map(template => ({
          templateId: template.templateId,
          documentName: template.documentName,
          documentReference: template.documentReference,
          documentExternalId: template.documentExternalId,
          documentContent: template.documentContent,
          isBlocked: template.isBlocked,
        }))
        .filter(
          (template, index) => index !== templateIndex,
        ) as DataStreamTemplateType[];

      await updateTransactionDataStream({
        templates: templatesToUpdate,
      });

      showNotification({
        message: t('dataStreams:deleteTemplateModal.deleted', {
          type: isTemplateType
            ? t('dataStreams:deleteTemplateModal.templateType2')
            : t('dataStreams:deleteTemplateModal.fileType2'),
        }),
      });

      onClose();
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isTemplateType
          ? t('dataStreams:deleteTemplateModal.templateHeader')
          : t('dataStreams:deleteTemplateModal.fileHeader')
      }
      defaultButtonAction={onClose}
      redButtonAction={deleteDataStream}
      redButtonIsLoading={isDataStreamDeleting}
    >
      <Text size="sm">
        <Trans
          i18nKey="dataStreams:deleteTemplateModal.description"
          values={{
            name,
            type: isTemplateType
              ? t('dataStreams:deleteTemplateModal.templateType')
              : t('dataStreams:deleteTemplateModal.fileType'),
          }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
