import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { deleteDataType } from '../requests';
import { DeleteDataTypeRequestType } from '../types';

export const useDeleteDataType = ({
  pathParams: { dataTypeName },
}: {
  pathParams: Omit<DeleteDataTypeRequestType['pathParams'], 'organisationId'>;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: () =>
      deleteDataType({
        pathParams: { organisationId, dataTypeName },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.dataTypes],
      });
    },
  });
};
