import { apiAuthClient } from 'api/helpers';

import { DeleteAvatarResponseType } from '../types';

export const deleteAvatar = async () => {
  const url = '/api/v2/auth/user/avatar';

  const { data } = await apiAuthClient.delete<DeleteAvatarResponseType>(url);

  return data;
};
