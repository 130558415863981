import { useMutation } from '@tanstack/react-query';

import { patchRegisterConfirm } from '../requests';
import { PatchRegisterConfirmRequestType } from '../types';

export const usePatchRegisterConfirm = (options?: {
  onError?: (error: unknown) => void;
  onSuccess?: () => void;
  onSettled?: () => void;
}) =>
  useMutation({
    mutationFn: (pathParams: PatchRegisterConfirmRequestType['pathParams']) =>
      patchRegisterConfirm({
        pathParams,
      }),
    ...options,
  });
