import {
  isMarkdown,
  toggleMarkdown,
} from 'legacy/TemplateCreatorEditor/plugins/helpers';

export const italic = {
  renderMark: ({ attributes, mark, children }, editor, next) => {
    if (mark.type === 'italic') {
      return <i {...attributes}>{children}</i>;
    }

    return next();
  },
  queries: {
    isItalic: editor => isMarkdown(editor, 'italic'),
  },
  commands: {
    toggleItalic: editor => {
      toggleMarkdown(editor, 'italic');
    },
  },
};
