import { useTranslation } from 'react-i18next';

import { TextCondition } from '../TextCondition';

const minLength = 8;

type Props = {
  hasMinLength: boolean;
  hasLowercaseLetter: boolean;
  hasUppercaseLetter: boolean;
  hasNumber: boolean;
};

export const TextConditions = ({
  hasMinLength,
  hasLowercaseLetter,
  hasUppercaseLetter,
  hasNumber,
}: Props) => {
  const { t } = useTranslation('user');

  return (
    <ul>
      <TextCondition isValid={hasMinLength}>
        {t('user:passwordConditions.minLength', { number: minLength })}
      </TextCondition>
      <TextCondition isValid={hasLowercaseLetter}>
        {t('user:passwordConditions.hasLowercaseLetter')}
      </TextCondition>
      <TextCondition isValid={hasUppercaseLetter}>
        {t('user:passwordConditions.hasUppercaseLetter')}
      </TextCondition>
      <TextCondition isValid={hasNumber}>
        {t('user:passwordConditions.hasNumber')}
      </TextCondition>
    </ul>
  );
};
