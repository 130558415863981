import { TFunction } from 'i18next';
import { z, ZodSchema } from 'zod';
import { WebhookType } from 'types';
import { EventSchema, FormErrorType, ModeType } from './types';
import { TriggerConfigType } from '../../../types/WebhookType';

type EventsUiSchemaType = {
  document: EventSchema[];
  attachment: EventSchema[];
  signQueue: EventSchema[];
};

export const EVENTS_UI_SCHEMA: EventsUiSchemaType = {
  document: [
    {
      name: 'document_status_change',
      defaultValue: false,
      values: [
        { name: 'draft', type: 'checkbox', defaultValue: false },
        { name: 'sent', type: 'checkbox', defaultValue: false },
        { name: 'in_acceptance', type: 'checkbox', defaultValue: false },
        { name: 'in_sign', type: 'checkbox', defaultValue: false },
        { name: 'on_hold', type: 'checkbox', defaultValue: false },
        { name: 'rejected', type: 'checkbox', defaultValue: false },
        { name: 'finished', type: 'checkbox', defaultValue: false },
      ],
    },
    { name: 'document_deleted', values: [], defaultValue: false },
    { name: 'document_created', values: [], defaultValue: false },
    { name: 'document_archived', values: [], defaultValue: false },
    { name: 'document_accepted', values: [], defaultValue: false },
    { name: 'document_new_version', values: [], defaultValue: false },
    { name: 'document_rejected', values: [], defaultValue: false },
    { name: 'document_signed', values: [], defaultValue: false },
    { name: 'document_unarchived', values: [], defaultValue: false },
    { name: 'document_unrejected', values: [], defaultValue: false },
    { name: 'document_viewed', values: [], defaultValue: false },
  ],
  attachment: [
    { name: 'attachment_added', values: [], defaultValue: false },
    { name: 'attachment_removed', values: [], defaultValue: false },
    { name: 'attachment_updated', values: [], defaultValue: false },
    { name: 'file_attachment_added', values: [], defaultValue: false },
    { name: 'file_attachment_removed', values: [], defaultValue: false },
  ],
  signQueue: [
    { name: 'queue_reset', values: [], defaultValue: false },
    { name: 'queue_resumed', values: [], defaultValue: false },
    {
      name: 'document_user_added',
      defaultValue: false,
      values: [
        { name: 'document_user_added_value', type: 'input', defaultValue: '' },
      ],
    },
    {
      name: 'document_user_invited',
      defaultValue: false,
      values: [
        {
          name: 'document_user_invited_value',
          type: 'input',
          defaultValue: '',
        },
      ],
    },
    {
      name: 'document_user_removed',
      defaultValue: false,
      values: [
        {
          name: 'document_user_removed_value',
          type: 'input',
          defaultValue: '',
        },
      ],
    },
  ],
};

export const EVENTS_SCHEMA: EventSchema[] = [
  ...EVENTS_UI_SCHEMA.document,
  ...EVENTS_UI_SCHEMA.attachment,
  ...EVENTS_UI_SCHEMA.signQueue,
];
export const getFormInitialValues = (
  mode: ModeType,
  webhook: WebhookType | null,
) => {
  const isEditMode = mode === 'edit' && webhook !== null;
  const defaultValues: { [key: string]: string | boolean | undefined } = {
    name: '',
    url: '',
  };

  EVENTS_SCHEMA.forEach(event => {
    defaultValues[event.name] = isEditMode
      ? Boolean(webhook.triggerConfig.find(evt => evt.trigger === event.name))
      : event.defaultValue;
    if (event.values.length !== 0) {
      event.values.forEach(value => {
        if (value.type === 'checkbox') {
          defaultValues[value.name] = isEditMode
            ? webhook.triggerConfig
                .find(evt => evt.trigger === event.name)
                ?.values?.includes(value.name)
            : value.defaultValue;
        } else if (value.type === 'input') {
          defaultValues[value.name] = isEditMode
            ? webhook.triggerConfig.find(evt => evt.trigger === event.name)
                ?.value || ''
            : value.defaultValue;
        }
      });
    }
  });

  if (isEditMode) {
    defaultValues.name = webhook.name;
    defaultValues.url = webhook.url;
  }

  return defaultValues;
};

export const parseSubmitData = (values: {
  [p: string]: string | boolean | undefined;
}) => {
  const webhookDetails: WebhookType = {
    name: values.name as string,
    url: values.url as string,
    triggerConfig: [],
  };

  EVENTS_SCHEMA.forEach(event => {
    if (values[event.name]) {
      const eventObject: TriggerConfigType = { trigger: event.name };
      if (event.values.length !== 0) {
        event.values.forEach(value => {
          if (values[value.name]) {
            if (value.type === 'checkbox') {
              if (!eventObject.values) {
                eventObject.values = [];
              }
              eventObject.values.push(value.name);
            }

            if (value.type === 'input') {
              eventObject.value = `${values[`${event.name}_value`]}`;
            }
          }
        });
      }
      webhookDetails.triggerConfig.push(eventObject);
    }
  });

  return webhookDetails;
};

export const getEventsZodSchema = (t: TFunction) => {
  const schemaObject: { [key: string]: ZodSchema } = {};

  EVENTS_SCHEMA.forEach(event => {
    schemaObject[event.name] = z.boolean().optional();
    if (event.values.length !== 0) {
      event.values.forEach(value => {
        if (value.type === 'checkbox') {
          schemaObject[value.name] = z.boolean().optional();
        } else if (value.type === 'input') {
          schemaObject[value.name] = z.union([
            z.literal(''),
            z
              .string()
              .email({ message: t('common:formErrors.incorrectEmail') }),
          ]);
        }
      });
    }
  });

  return schemaObject;
};

export const getErrorMessage = (error: FormErrorType): string => {
  if (typeof error === 'string') {
    return error;
    // eslint-disable-next-line
  } else if (error && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }
  return '';
};
