import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postCustomizationsDocumentPaper } from '../requests';
import { PostCustomizationsDocumentPaperRequestType } from '../types';

export const usePostCustomizationsDocumentPaper = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      formData,
    }: {
      formData: PostCustomizationsDocumentPaperRequestType['formData'];
    }) =>
      postCustomizationsDocumentPaper({
        pathParams: {
          organisationId,
        },
        formData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.organisationCustomizationsDocument,
          { organisationId },
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template],
      });
    },
  });
};
