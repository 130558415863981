import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionResponseType,
  GetTransactionRequestType,
} from '../types';

export const getTransaction = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
}: GetTransactionRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}`;

  const { data } = await apiAuthClient.get<GetTransactionResponseType>(url);

  return data.data;
};
