import { Box, DefaultProps, packSx } from '@mantine/core';

import { PREVIEW_DIMENSIONS } from './consts';
import { addMarginBoundary, getMarginItemDimensionInPx } from './helpers';
import { ActiveMarginType } from '../types';
import { MarginItem } from './MarginItem';

type Props = {
  activeMargin: ActiveMarginType;
  onMarginItemClick: (type: Exclude<ActiveMarginType, 'none'>) => void;
  marginTopInCm: number;
  marginRightInCm: number;
  marginBottomInCm: number;
  marginLeftInCm: number;
  sx: DefaultProps['sx'];
};

export const MarginPreview = ({
  activeMargin,
  onMarginItemClick,
  marginLeftInCm,
  marginTopInCm,
  marginBottomInCm,
  marginRightInCm,
  sx,
}: Props) => {
  const marginItems = [
    {
      type: 'top',
      margin: marginTopInCm,
    },
    {
      type: 'right',
      margin: marginRightInCm,
    },
    {
      type: 'bottom',
      margin: marginBottomInCm,
    },
    {
      type: 'left',
      margin: marginLeftInCm,
    },
  ] as const;

  return (
    <Box
      sx={[
        theme => ({
          width: PREVIEW_DIMENSIONS.WIDTH,
          height: PREVIEW_DIMENSIONS.HEIGHT,
          backgroundColor: theme.colors.orange[1],
          position: 'relative',
        }),
        ...packSx(sx),
      ]}
    >
      {marginItems.map(({ type, margin }) => (
        <MarginItem
          key={type}
          isActive={activeMargin === type}
          type={type}
          marginInPx={getMarginItemDimensionInPx(
            addMarginBoundary(margin),
            type === 'top' || type === 'bottom' ? 'vertical' : 'horizontal',
          )}
          onClick={() => {
            onMarginItemClick(type);
          }}
        />
      ))}
    </Box>
  );
};
