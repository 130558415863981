import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Tooltip, useMantineTheme } from '@mantine/core';

import { InfoIcon, TemplatesIcon } from 'icons';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { MenuItem } from '../../../MenuItem';
import { EditorActionButton } from '../../EditorActionButton';

export const Tables = () => {
  const { t } = useTranslation('templates');

  const mantineTheme = useMantineTheme();

  const editor = useContext(EditorContext);

  return (
    <Menu>
      <Menu.Target>
        <EditorActionButton>
          <TemplatesIcon />
          {t('templates:toolbar.table')}
        </EditorActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        <MenuItem
          onClick={() => {
            alert('COMING SOON');
          }}
        >
          {t('templates:standardTable')}
        </MenuItem>
        <MenuItem
          sx={theme => ({
            '.mantine-Menu-itemLabel': {
              display: 'flex',
              alignItems: 'center',
              gap: theme.other.spacing(1),
            },
          })}
          onClick={() => {
            editor.insertBlockWithOptionalEmptyContent('staticTable');
          }}
        >
          {t('templates:staticTable')}{' '}
          <Tooltip label={t('templates:staticTableDescription')}>
            <span>
              <InfoIcon backgroundColor={mantineTheme.white} />
            </span>
          </Tooltip>
        </MenuItem>
      </Menu.Dropdown>
    </Menu>
  );
};
