import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import { useGetTemplate, usePatchTemplate } from 'api/templates';
import { useServerErrors, useShowNotification } from 'hooks';
import { AttachmentTemplateType, TemplateType } from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

type Props = {
  attachmentIndex: number;
  attachmentName: AttachmentTemplateType['type'];
  onClose: () => void;
} & Pick<ModalProps, 'isOpen' | 'defaultButtonAction'>;

export const DeleteModal = ({
  isOpen,
  onClose,
  attachmentIndex,
  attachmentName,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const { templateId } = useParams() as { templateId: string };

  const showNotification = useShowNotification();
  const { addServerErrorActions } = useServerErrors();

  const { data: template } = useGetTemplate({
    pathParams: { templateId },
  });
  const { mutateAsync: updateTemplate, isLoading: isUpdatingTemplate } =
    usePatchTemplate({ pathParams: { templateId } });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('templates:deleteAttachmentHeader')}
      defaultButtonAction={onClose}
      defaultButtonDisabled={isUpdatingTemplate}
      redButtonIsLoading={isUpdatingTemplate}
      redButtonText={t('common:yesDelete')}
      redButtonAction={async () => {
        try {
          await updateTemplate({
            attachmentTemplates: (
              template as TemplateType
            ).attachmentTemplates.filter(
              (_, index) => index !== attachmentIndex,
            ),
          });

          showNotification({
            message: t('templates:attachmentTemplateDeleted'),
          });

          onClose();
        } catch (error) {
          addServerErrorActions(error);
        }
      }}
    >
      <Text size="sm">
        <Trans
          i18nKey="templates:attachmentTemplateModalDeleteDescription"
          values={{ name: attachmentName }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
