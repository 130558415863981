import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

import { HIDDEN_PASSWORD_ERROR_MESSAGE } from '../consts';

export const useGetZodSchema = ({
  passwordInstanceRef,
}: {
  passwordInstanceRef: MutableRefObject<InstanceRefObjectType>;
}) => {
  const { t } = useTranslation('common');

  return z.object({
    currentPassword: z
      .string()
      .min(1, { message: t('common:formErrors.required') }),
    password: z.string().refine(() => passwordInstanceRef.current.isValid, {
      message: HIDDEN_PASSWORD_ERROR_MESSAGE,
    }),
  });
};
