import { ReactNode } from 'react';
import { Global, MantineProvider } from '@mantine/core';

import { global, theme } from 'theme';

type Props = {
  children: ReactNode;
};

export const StylesProvider = ({ children }: Props) => (
  <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
    <Global styles={global} />
    {children}
  </MantineProvider>
);
