import { ReactNode } from 'react';
import { Box, keyframes } from '@mantine/core';

const slideLeftAnimation = keyframes({
  '0%': { opacity: 0, transform: 'translateX(100%)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

type Props = {
  children: ReactNode;
  in: boolean;
  disabled?: boolean;
};

export const SlideLeftAnimationBox = ({
  children,
  disabled = false,
  ...props
}: Props) => (
  <Box
    sx={
      disabled
        ? undefined
        : {
            display: props.in ? undefined : 'none',
            transitionProperty: 'transform, opacity',
            animation: `${slideLeftAnimation} 400ms ease`,
          }
    }
  >
    {children}
  </Box>
);
