import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, DefaultProps, UnstyledButton } from '@mantine/core';
import { Modal, Status } from 'components/shared';
import { TransactionNotificationAccordionItem } from 'components/Transactions/TransactionNotificationAccordionItem';

import { TransactionStatusType } from 'types';

type Props = {
  sx?: DefaultProps['sx'];
  warnings: TransactionStatusType['warnings'];
};

export const Warnings = ({ sx, warnings }: Props) => {
  const { t } = useTranslation('transactions');

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <UnstyledButton
        sx={sx}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Status color="orange" label={t('transactions:warnings')} />
      </UnstyledButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('transactions:warningsModalTitle')}
        defaultButtonAction={onClose}
        defaultButtonText={t('common:back')}
      >
        <Accordion defaultValue="warnings">
          <TransactionNotificationAccordionItem
            value="warnings"
            title={t('transactions:warningsModalAccordionTitle')}
            iconType="warning"
            items={warnings}
          />
        </Accordion>
      </Modal>
    </>
  );
};
