import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { deleteCustomizationsDocument } from '../requests';
import { DeleteCustomizationsDocumentRequestType } from '../types';

export const useDeleteCustomizationsDocument = ({
  pathParams: { boardId, templateId },
}: {
  pathParams: Omit<
    DeleteCustomizationsDocumentRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: () =>
      deleteCustomizationsDocument({
        pathParams: { organisationId, boardId, templateId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template, { templateId: Number(templateId) }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId, boardId }],
      });
    },
  });
};
