import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { useDeleteDataStream } from 'api/dataStreams';
import { useServerErrors, useShowNotification } from 'hooks';
import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  name: string;
};

export const DataStreamDeleteModal = ({ isOpen, onClose, id, name }: Props) => {
  const { t } = useTranslation('dataStreams');

  const { addServerErrorActions } = useServerErrors();
  const showNotification = useShowNotification();

  const {
    mutateAsync: dataStreamDeleteMutateAsync,
    isLoading: isDeletingDataStream,
  } = useDeleteDataStream({ pathParams: { dataStreamId: id } });

  const deleteDataStream = async () => {
    try {
      await dataStreamDeleteMutateAsync();

      showNotification({
        message: t('dataStreams:dataStreamDeleted'),
      });

      onClose();
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('dataStreams:deleteModal.header')}
      defaultButtonAction={onClose}
      defaultButtonDisabled={isDeletingDataStream}
      redButtonIsLoading={isDeletingDataStream}
      redButtonAction={deleteDataStream}
    >
      <Text size="sm">
        <Trans
          i18nKey="dataStreams:deleteModal.description"
          values={{ name }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
