import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Box, Radio, Text } from '@mantine/core';

const TEMPLATE_TYPES = [
  {
    value: 'base',
    labelTranslation: 'templates:baseTemplate',
    descriptionTranslation: 'templates:baseTemplateDescription',
  },
  {
    value: 'attachment',
    labelTranslation: 'templates:attachmentTemplate',
    descriptionTranslation: 'templates:attachmentTemplateDescription',
  },
  {
    value: 'offer',
    labelTranslation: 'templates:templateTypes.offer',
    descriptionTranslation: 'templates:offerDescription',
  },
];

type Props = {
  templateTypeRadioProps: UseFormRegisterReturn;
};

export const TemplateTypeRadio = ({ templateTypeRadioProps }: Props) => {
  const { t } = useTranslation('templates');

  return (
    <Box sx={theme => ({ display: 'grid', gap: theme.other.spacing(2) })}>
      {TEMPLATE_TYPES.map(
        ({ value, labelTranslation, descriptionTranslation }) => (
          <Radio
            {...templateTypeRadioProps}
            key={value}
            value={value}
            label={
              <>
                <Text sx={{ display: 'block' }} span>
                  {t(labelTranslation)}
                </Text>
                <Text span size="md" weight={400}>
                  {t(descriptionTranslation)}
                </Text>
              </>
            }
          />
        ),
      )}
    </Box>
  );
};
