import { Box } from '@mantine/core';
import { v4 as uuid } from 'uuid';

import { DataStreamTemplateType } from 'types';

import { DataStreamTemplate } from '../DataStreamTemplate';

type Props = {
  templates: DataStreamTemplateType[];
  hasDataStreamPatchAccess: boolean;
};

export const DataStreamTemplates = ({
  templates,
  hasDataStreamPatchAccess,
}: Props) => (
  <ul>
    {templates.map(
      (
        {
          templateId,
          templateName,
          documentName,
          documentExternalId,
          documentReference,
          documentContent,
          isBlocked,
          attachTo,
        },
        index,
      ) => (
        <Box
          key={uuid()} /* the list should rerender every time because there is no unique value provided by BE */
          sx={theme => ({
            marginBottom: theme.other.spacing(1),
          })}
          component="li"
        >
          <DataStreamTemplate
            templateId={templateId}
            templateName={templateName}
            templateIndex={index}
            documentName={documentName}
            documentExternalId={documentExternalId}
            documentReference={documentReference}
            documentContent={documentContent}
            isBlocked={isBlocked}
            attachTo={attachTo}
            hasDataStreamPatchAccess={hasDataStreamPatchAccess}
            templates={templates}
          />
        </Box>
      ),
    )}
  </ul>
);
