import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from '../hooks';
import { IconPropsType } from './types';

export const LetterTwoToneSendIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const mantineTheme = useMantineTheme();
  const iconColor = useGetColor(color);
  const backgroundColor = mantineTheme.fn.rgba(iconColor, 0.07);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m4.75 10.552 6.894 3.715c.222.12.49.12.712 0l6.894-3.715v7.832a.858.858 0 0 1-.85.866H5.6a.858.858 0 0 1-.85-.866v-7.832Zm6.831-5.69c.26-.15.578-.15.838 0l5.775 3.334L12 11.533 5.806 8.196l5.775-3.333-.375-.65.375.65Z"
        fill={backgroundColor}
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </Box>
  );
};
