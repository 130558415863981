import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Anchor,
  Button,
  ButtonProps,
  Center,
  DefaultProps,
  packSx,
  Text,
} from '@mantine/core';

import { WomanWithMagnifierIcon } from 'icons';

type Props = {
  sx?: DefaultProps['sx'];
  header?: string;
  description?: string;
  buttonText?: string;
  onActionButtonClick?: () => void;
  actionButtonColor?: ButtonProps['color'];
  link?: string;
  externalLink?: string;
};

export const NotFound = ({
  sx,
  header,
  description,
  buttonText,
  actionButtonColor,
  onActionButtonClick,
  link,
  externalLink,
}: Props) => {
  const { t } = useTranslation(['common', 'navigation']);

  const getButton = () => {
    if (buttonText) {
      switch (true) {
        case Boolean(onActionButtonClick):
          return (
            <Button color={actionButtonColor} onClick={onActionButtonClick}>
              {buttonText}
            </Button>
          );
        case Boolean(link): {
          return (
            <Anchor component={Link} to={link as string}>
              <Button>{buttonText}</Button>
            </Anchor>
          );
        }
        case Boolean(externalLink): {
          return (
            <Anchor href={externalLink} target="_blank">
              <Button>{buttonText}</Button>
            </Anchor>
          );
        }
      }
    }

    return null;
  };

  return (
    <Center
      sx={[
        theme => ({
          flexDirection: 'column',
          textAlign: 'center',
          padding: theme.other.spacing(0, 2),
        }),
        ...packSx(sx),
      ]}
    >
      <WomanWithMagnifierIcon
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        color="primary"
      />
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
        })}
        weight={600}
      >
        {header || t('common:notFoundResults')}
      </Text>
      {description && (
        <Text
          size="sm"
          sx={theme => ({
            marginBottom: theme.other.spacing(1.5),
          })}
        >
          {description}
        </Text>
      )}
      {getButton()}
    </Center>
  );
};
