import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, UnstyledButton, Transition } from '@mantine/core';

import { useIsLgBreakpoint } from 'hooks';
import { HelpIcon } from 'icons';
import { STATIC_URLS } from 'consts';

export const HelpfulLinks = () => {
  const { t } = useTranslation(['common', 'user']);

  const isLgBreakpoint = useIsLgBreakpoint();

  const [areLinksOpen, setAreLinksOpen] = useState(false);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 28 }}>
        <Transition mounted={isLgBreakpoint || areLinksOpen} transition="fade">
          {styles => (
            <div style={styles}>
              <Anchor href={STATIC_URLS.termsAndConditions} target="_blank">
                {t('common:regulations')}
              </Anchor>
              <Anchor
                sx={theme => ({
                  margin: theme.other.spacing(0, 2),
                })}
                component="a"
                href={STATIC_URLS.privacyPolicy}
                target="_blank"
              >
                {t('common:privacyPolicy')}
              </Anchor>
              <Anchor href={STATIC_URLS.help} target="_blank">
                {t('common:help')}
              </Anchor>
            </div>
          )}
        </Transition>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {!isLgBreakpoint && (
          <UnstyledButton
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setAreLinksOpen(open => !open);
            }}
          >
            <HelpIcon
              size={16}
              color="primary"
              sx={theme => ({
                marginRight: theme.other.spacing(0.5),
              })}
            />
            <Anchor
              sx={{
                textDecoration: areLinksOpen ? 'underline' : 'none',
              }}
            >
              {t('user:helpfulLinks')}
            </Anchor>
          </UnstyledButton>
        )}
      </Box>
    </div>
  );
};
