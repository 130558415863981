import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';

import { ListVariableButton } from '../ListVariableButton';
import { getGroupedVariables } from './helpers';

export const GroupList = ({ filteredVariables, setActiveVariable }) => {
  const { t } = useTranslation('templates');

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        rowGap: theme.other.spacing(3),
      })}
      component="ul"
    >
      {getGroupedVariables(filteredVariables).map(([type, variablesOfType]) => {
        const { labelTranslation, icon } = VARIABLES.find(
          ({ value }) => value === type,
        );

        return (
          <Box component="li" key={type}>
            <Text
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                columnGap: theme.other.spacing(1),
                marginBottom: theme.other.spacing(1),
              })}
              span
            >
              {icon}
              {t(labelTranslation)}
            </Text>
            <Box
              sx={theme => ({
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.other.spacing(1),
              })}
              component="ul"
            >
              {variablesOfType.map(({ name }) => (
                <li key={name}>
                  <ListVariableButton
                    onClick={() => {
                      setActiveVariable(name);
                    }}
                  >
                    {name}
                  </ListVariableButton>
                </li>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
