import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import {
  PaperCard,
  PercentageContainer,
  SimpleStatus,
} from 'components/shared';

import { DataStreamActions } from '../DataStreamMenu';

type Props = {
  templateId: DataStreamTemplateType['templateId'];
  templateName: DataStreamTemplateType['templateName'];
  templateIndex: number;
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentReference'];
  isBlocked: DataStreamTemplateType['isBlocked'];
  attachTo: DataStreamTemplateType['attachTo'];
  hasDataStreamPatchAccess: boolean;
  templates: DataStreamTemplateType[];
};

export const DataStreamTemplate = ({
  templateId,
  templateName,
  templateIndex,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
  attachTo,
  hasDataStreamPatchAccess,
  templates,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  return (
    <PercentageContainer component={PaperCard}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2.25),
          })}
          size="xl"
          weight={600}
        >
          {templateName || documentContent}
        </Text>
        <SimpleStatus
          labelOn={t('dataStreams:templateUpdate')}
          labelOff={t('dataStreams:templateUpdate')}
          on={documentContent ? false : !isBlocked}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Box
            sx={theme => ({
              marginBottom: theme.other.spacing(2.25),
            })}
          >
            <Text size="md" weight={600}>
              {documentName}
            </Text>
            <Text color="gray.7" size="sm">
              {t('dataStreams:templateDocumentName')}
            </Text>
          </Box>
          <div>
            <Text size="md" weight={600}>
              {documentExternalId}
            </Text>
            <Text color="gray.7" size="sm">
              {t('dataStreams:templateExternalId')}
            </Text>
          </div>
          {Boolean(documentReference) && (
            <Box
              sx={theme => ({
                marginTop: theme.other.spacing(1.75),
              })}
            >
              <Text size="md" weight={600}>
                {documentReference}
              </Text>
              <Text color="gray.7" size="sm">
                {t('dataStreams:templateDocumentReference')}
              </Text>
            </Box>
          )}
        </div>
        {hasDataStreamPatchAccess && (
          <DataStreamActions
            templateId={templateId}
            templateName={templateName}
            templateIndex={templateIndex}
            documentName={documentName}
            documentExternalId={documentExternalId}
            documentReference={documentReference}
            documentContent={documentContent}
            isBlocked={isBlocked}
            attachTo={attachTo}
            templates={templates}
          />
        )}
      </Box>
    </PercentageContainer>
  );
};
