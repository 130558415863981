import { MARGIN_RANGE } from '../../consts';

export const addMarginBoundary = (marginInCm: number) => {
  if (marginInCm < MARGIN_RANGE.min) {
    return MARGIN_RANGE.min;
  }

  if (marginInCm > MARGIN_RANGE.max) {
    return MARGIN_RANGE.max;
  }

  return marginInCm;
};
