import { useId } from 'react';
import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const Microsoft365LogoIcon = ({
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`{url(#${id})`}>
        <path fill="#F25022" d="M11.45 0H0v11.45h11.45V0Z" />
        <path fill="#7FBA02" d="M24 0H12.55v11.45H24V0Z" />
        <path fill="#01A4EF" d="M11.45 12.55H0V24h11.45V12.55Z" />
        <path fill="#FEB903" d="M24 12.55H12.55V24H24V12.55Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Box>
  );
};
