import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Popover, Select } from '@mantine/core';

import { TRANSACTION_STATUS } from 'consts';
import { OrganisationUserListType } from 'types';

import { SelectedOrganisationMembersType } from '../../../types';
import { SelectItem } from './SelectItem';
import { MembersSelectList } from './MembersSelectList';
import { TransactionOwnerButton } from './TransactionOwnerButton';

type Props = {
  organisationMembers: OrganisationUserListType[];
  selectedOrganisationMembers: SelectedOrganisationMembersType;
  status: 'all' | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS];
  handleChangeStatus: (
    status:
      | 'all'
      | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS],
  ) => void;
  handleChangeSelectedOrganisationMembers: (
    selectedMembers: SelectedOrganisationMembersType,
  ) => void;
  isOrganisationMembersError: boolean;
  isOrganisationMembersLoading: boolean;
  isFetchingOrganisationMembersNextPage: boolean;
  fetchOrganisationMembersNextPage: () => void;
  setOrganisationMembersNameAndSurname: (nameAndSurname: string) => void;
};

export const DetailedFilters = ({
  organisationMembers,
  selectedOrganisationMembers,
  status,
  handleChangeStatus,
  handleChangeSelectedOrganisationMembers,
  isOrganisationMembersError,
  isOrganisationMembersLoading,
  isFetchingOrganisationMembersNextPage,
  fetchOrganisationMembersNextPage,
  setOrganisationMembersNameAndSurname,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const [isParticipantsListOpen, setIsParticipantsListOpen] = useState(false);

  const statusOptions = [
    {
      value: 'all',
      label: t('transactions:transactionListStatus.all'),
    },
    {
      statusColor: 'red',
      value: TRANSACTION_STATUS.error,
      label: t('transactions:transactionListStatus.errors'),
    },
    {
      statusColor: 'green',
      value: TRANSACTION_STATUS.ok,
      label: t('transactions:transactionListStatus.processed'),
    },
    {
      statusColor: 'gray',
      value: TRANSACTION_STATUS.processing,
      label: t('transactions:transactionListStatus.inProgress'),
    },
  ];

  return (
    <Box sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
      <Box sx={{ display: 'flex' }}>
        <Select
          sx={theme => ({
            marginRight: theme.other.spacing(2),
            input: {
              fontSize: theme.fontSizes.md,
              fontWeight: 600,
            },
          })}
          styles={{
            wrapper: {
              marginBottom: 0,
            },
          }}
          value={status}
          onChange={value => {
            if (value !== status) {
              handleChangeStatus(value as typeof status);
            }
          }}
          data={statusOptions}
          itemComponent={SelectItem}
          error={null}
        />
        <Popover
          opened={isParticipantsListOpen}
          onChange={setIsParticipantsListOpen}
          width={370}
          position="bottom-start"
        >
          <Popover.Target>
            <TransactionOwnerButton
              isParticipantsListOpen={isParticipantsListOpen}
              setIsParticipantsListOpen={setIsParticipantsListOpen}
              organisationMembers={organisationMembers}
              selectedOrganisationMembers={selectedOrganisationMembers}
            />
          </Popover.Target>
          <Popover.Dropdown
            sx={theme => ({ padding: theme.other.spacing(2, 0, 0.5) })}
          >
            <MembersSelectList
              organisationMembers={organisationMembers}
              selectedOrganisationMembers={selectedOrganisationMembers}
              handleChangeSelectedOrganisationMembers={
                handleChangeSelectedOrganisationMembers
              }
              isOrganisationMembersError={isOrganisationMembersError}
              isOrganisationMembersLoading={isOrganisationMembersLoading}
              isFetchingOrganisationMembersNextPage={
                isFetchingOrganisationMembersNextPage
              }
              fetchOrganisationMembersNextPage={
                fetchOrganisationMembersNextPage
              }
              setOrganisationMembersNameAndSurname={
                setOrganisationMembersNameAndSurname
              }
            />
          </Popover.Dropdown>
        </Popover>
      </Box>
    </Box>
  );
};
