import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const CalendarIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M7.586 4v5.517M16.414 4v5.517M5.379 6.76h13.242c.61 0 1.103.494 1.103 1.103v11.035c0 .609-.494 1.103-1.103 1.103H5.379c-.61 0-1.103-.494-1.103-1.103V7.862c0-.61.494-1.103 1.103-1.103Z"
      />
    </Box>
  );
};
