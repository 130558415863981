import { ValidationErrors } from 'types';

export const getDataStreamTemplateErrors = (errors: ValidationErrors) =>
  Object.entries(errors).reduce(
    (accumulator, [errorKey, errorMessage]) => ({
      ...accumulator,
      [errorKey.replace(/templates\[\d]\./, '')]: errorMessage,
    }),
    {},
  );
