import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from '@mantine/hooks';

import { setRefreshToken } from 'helpers';
import { STORAGE_KEYS } from 'consts';

export const useClearLoginData = () => {
  const queryClient = useQueryClient();
  const [, setToken] = useLocalStorage({
    key: STORAGE_KEYS.token,
  });

  return useCallback(() => {
    setToken('null');
    setRefreshToken('null');
    queryClient.clear();
  }, [queryClient, setToken]);
};
