import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';

import { usePatchRegisterConfirm } from 'api/accountRegister';
import { URLS } from 'consts';
import { useShowGeneralErrorNotification, useShowNotification } from 'hooks';
import { NotFound } from 'pages';

export const RegistrationConfirmController = () => {
  const { t } = useTranslation(['common', 'user']);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const showNotification = useShowNotification();
  const showGeneralErrorNotification = useShowGeneralErrorNotification();
  const token = searchParams.get('token') ?? '';

  const { mutate: registerConfirm } = usePatchRegisterConfirm({
    onError: showGeneralErrorNotification,
    onSuccess: () => {
      showNotification({
        message: t('user:accountActivated'),
      });
    },
    onSettled: () => {
      navigate(URLS.home);
    },
  });

  useEffect(() => {
    if (token) {
      registerConfirm({
        token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token) {
    return <NotFound />;
  }

  return <LoadingOverlay visible />;
};
