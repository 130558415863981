import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const OpenIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 4H12.6667M20 4V11.3333M20 4L8 16M17.3333 12.6667C17.3333 14.414 17.3333 16.5006 17.3333 18.0005C17.3333 19.1051 16.4379 20 15.3333 20H6C4.89543 20 4 19.1046 4 18V8.66666C4 7.5621 4.89543 6.66667 6 6.66667H12.6667"
        stroke={iconColor}
        strokeWidth="1.5"
      />
    </Box>
  );
};
