import { apiAuthClient } from 'api/helpers';
import { HTTP_HEADERS } from 'consts';

import {
  PatchTransactionRequestType,
  PatchTransactionResponseType,
} from '../types';

export const patchTransaction = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
  bodyParams,
  headers,
}: PatchTransactionRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}`;

  const { data } = await apiAuthClient.patch<PatchTransactionResponseType>(
    url,
    bodyParams,
    {
      headers: {
        [HTTP_HEADERS.xTransactionEntryId]:
          headers[HTTP_HEADERS.xTransactionEntryId],
      },
    },
  );

  return data.data;
};
