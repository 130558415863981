import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { putCustomizationsDocument } from '../requests';
import { PutCustomizationsDocumentRequestType } from '../types';

export const usePutCustomizationsDocument = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      margin,
    }: PutCustomizationsDocumentRequestType['bodyParams']) =>
      putCustomizationsDocument({
        pathParams: {
          organisationId,
        },
        bodyParams: {
          margin,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.organisationCustomizationsDocument,
          { organisationId },
        ],
      });
    },
  });
};
