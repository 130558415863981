import { ReactNode } from 'react';
import { Box } from '@mantine/core';

type Props = {
  children: ReactNode;
};

export const Step = ({ children }: Props) => (
  <Box
    sx={theme => ({
      ...theme.other.getFontSizeStyles('md'),
      marginBottom: theme.other.spacing(2),
      fontWeight: 400,
    })}
    component="li"
  >
    {children}
  </Box>
);
