import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, Text } from '@mantine/core';
import { z } from 'zod';

import { usePostOtpLogin, usePostOtpQuery } from 'api/authOtp';
import { useGetAuthOtp } from 'api/invitationOtp';
import { useServerErrors, useShowNotification } from 'hooks';
import { getZodSchemaErrorMessages } from 'helpers';
import { OTP_DIGITS_NUMBER } from 'consts';
import { ValidationErrors } from 'types';

import { TextInput } from 'components/shared';

import { AuthContainerLoginHeader } from '../../AuthContainerLoginHeader';
import { LoginOTPCountdown } from './LoginOTPCountdown';
import { LoginOTPResendCode } from './LoginOTPResendCode';
import { SsoLoginMethods } from '../SsoLoginMethods';
import { LoginOptionsDivider } from '../LoginOptionsDivider';

type Props = {
  hasLoginMethodPassword: boolean;
  switchLoginMethod: () => void;
  onSuccess: (accessToken: string) => void;
  isOnDocumentInvitationRoute: boolean;
  email?: string;
  backButtonAction?: () => void;
};

export const LoginOTP = ({
  hasLoginMethodPassword,
  switchLoginMethod,
  onSuccess,
  isOnDocumentInvitationRoute,
  email = '',
  backButtonAction,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);
  const { invitationId } = useParams();

  const schema = z.object({
    code: z
      .string()
      .min(1, { message: t('common:formErrors.required') })
      .max(OTP_DIGITS_NUMBER, {
        message: t('common:formErrors.exactNumberOfSigns', {
          number: OTP_DIGITS_NUMBER,
        }),
      }),
  });

  const { getGeneralServerError, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const codeInputRef = useRef<HTMLInputElement>(null);

  const [code, setCode] = useState('');
  const [errorStatus, setErrorStatus] = useState<ValidationErrors | null>(null);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const { mutateAsync: otpLogin, isLoading: isOtpLoginLoading } =
    usePostOtpLogin();

  const handleServerErrors = (error: unknown) => {
    const validationServerErrors = getServerErrorStatus(error);

    if (validationServerErrors) {
      setErrorStatus(validationServerErrors);
    } else {
      setServerErrorMessage(getGeneralServerError(error));
    }

    codeInputRef.current?.focus();
  };

  const {
    isFetching: isEmailCodeFetching,
    refetch: emailCodeRefetch,
    data: emailCode,
  } = usePostOtpQuery(
    {
      bodyParams: {
        email,
      },
    },
    {
      enabled: !isOnDocumentInvitationRoute,
      onError: handleServerErrors,
      onSuccess: () => {
        showNotification({ message: t('user:codeSent') });
      },
    },
  );
  const {
    isFetching: isInvitationCodeFetching,
    refetch: invitationCodeRefetch,
    data: invitationCode,
  } = useGetAuthOtp(
    {
      pathParams: {
        invitationId: invitationId as string,
      },
    },
    {
      enabled: isOnDocumentInvitationRoute,
    },
  );

  const isCodeFetching = isOnDocumentInvitationRoute
    ? isInvitationCodeFetching
    : isEmailCodeFetching;
  const refetch = isOnDocumentInvitationRoute
    ? invitationCodeRefetch
    : emailCodeRefetch;
  const data = isOnDocumentInvitationRoute ? invitationCode : emailCode;

  const { id: otpId = '', order = '_', validUntil = '' } = { ...data };

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, OTP_DIGITS_NUMBER);
    setErrorStatus(status => ({
      ...status,
      code: '',
    }));
    setCode(value);
  };

  const handleResendCode = () => {
    setCode('');
    refetch();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = getZodSchemaErrorMessages({
      schema,
      fields: { code },
    });

    if (errors) {
      setErrorStatus(status => ({
        ...status,
        code: errors?.code,
      }));
    } else {
      try {
        const { accessToken } = await otpLogin({
          otpId,
          code,
        });

        onSuccess(accessToken);
      } catch (error) {
        handleServerErrors(error);
      }
    }
  };

  useEffect(() => {
    if (errorStatus?.code) {
      codeInputRef.current?.focus();
    }
  }, [errorStatus?.code]);

  return (
    <>
      <AuthContainerLoginHeader
        errorLabel={serverErrorMessage}
        backButtonAction={backButtonAction}
      />
      <form onSubmit={handleSubmit}>
        <TextInput
          id="email"
          label={t('common:email')}
          disabled
          value={email}
        />
        <Box
          sx={{
            input: {
              letterSpacing: 10,
            },
          }}
          component={TextInput}
          id="code"
          label={
            <>
              <div>
                {t('user:codeValidUntil', {
                  codeNumber: order,
                })}{' '}
                <LoginOTPCountdown key={validUntil} validUntil={validUntil} />
              </div>
              <Text
                sx={{
                  whiteSpace: 'break-spaces',
                }}
                span
                size="xs"
              >
                {t('user:otpCodeInsert', {
                  digits: OTP_DIGITS_NUMBER,
                })}
              </Text>
            </>
          }
          placeholder="000000"
          autoComplete="off"
          disabled={isOtpLoginLoading || isCodeFetching}
          value={code}
          error={errorStatus?.code}
          ref={codeInputRef}
          onChange={handleCodeChange}
        />
        <Button
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
            fontWeight: 600,
          })}
          type="submit"
          fullWidth
          size="lg"
          loading={isOtpLoginLoading}
          disabled={isCodeFetching}
        >
          {t('user:signIn')}
        </Button>
        {!isOtpLoginLoading && (
          <LoginOTPResendCode
            isLoading={isCodeFetching}
            resendCode={handleResendCode}
          />
        )}
      </form>
      {hasLoginMethodPassword && !isOtpLoginLoading && !isCodeFetching && (
        <Anchor
          component="button"
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
          onClick={switchLoginMethod}
        >
          {t('user:wishPassword')}
        </Anchor>
      )}
      <LoginOptionsDivider />
      <SsoLoginMethods />
    </>
  );
};
