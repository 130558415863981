import { useIsMdBreakpoint } from 'hooks';

import { ActionIconsItemType } from './types';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';

type Props = {
  items: ActionIconsItemType[];
  showMenuOnMobile?: boolean;
};

export const Actions = ({ items, showMenuOnMobile = true }: Props) => {
  const isMdBreakpoint = useIsMdBreakpoint();

  return isMdBreakpoint || !showMenuOnMobile ? (
    <DesktopView items={items} />
  ) : (
    <MobileView items={items} />
  );
};
