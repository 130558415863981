import { Box, DefaultProps, Skeleton } from '@mantine/core';

type Props = {
  sx?: DefaultProps['sx'];
};

export const PageSkeleton = ({ sx }: Props) => (
  <Box sx={sx}>
    <Skeleton
      sx={theme => ({
        marginBottom: theme.other.spacing(3.25),
      })}
      width="50%"
      height={16}
    />
    <Box
      sx={theme => ({
        display: 'flex',
        marginBottom: theme.other.spacing(3.25),
        height: 47,
      })}
    >
      <Skeleton
        sx={theme => ({
          marginRight: theme.other.spacing(1.5),
        })}
        width={47}
      />
      <Skeleton />
    </Box>
    <Skeleton height={296} />
  </Box>
);
