import { apiAuthClient } from 'api/helpers';

import {
  PatchRegisterConfirmRequestType,
  PatchRegisterConfirmResponseType,
} from '../types';

export const patchRegisterConfirm = async ({
  pathParams: { token },
}: PatchRegisterConfirmRequestType) => {
  const url = `/api/v2/auth/register/${token}/confirm`;

  const { data } = await apiAuthClient.patch<PatchRegisterConfirmResponseType>(
    url,
  );

  return data.data;
};
