import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { useGetUser } from 'api/account';
import { ErrorTwoToneIcon, SuccessTwoToneIcon } from 'icons';
import { MARKETING_AGREEMENTS } from 'consts';
import { UserType } from 'types';

import { getTwoToneRoundedIconProps } from '../../../helpers';

export const AgreementList = () => {
  const { t } = useTranslation('user');

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  const agreementItems = [
    {
      text: t('user:contactViaPhoneCall'),
      isEnabled: user.contactAgreements.includes(MARKETING_AGREEMENTS.phone),
    },
    {
      text: t('user:contactViaSms'),
      isEnabled: user.contactAgreements.includes(MARKETING_AGREEMENTS.sms),
    },
    {
      text: t('user:newsletterAcceptation'),
      isEnabled: user.contactAgreements.includes(
        MARKETING_AGREEMENTS.newsletter,
      ),
    },
  ];

  return (
    <ul>
      {agreementItems.map(({ text, isEnabled }) => (
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.other.spacing(2),
          })}
          key={text}
          component="li"
        >
          {isEnabled ? (
            <SuccessTwoToneIcon
              {...getTwoToneRoundedIconProps({
                color: 'green',
                backgroundColor: 'violet.1',
              })}
            />
          ) : (
            <ErrorTwoToneIcon
              {...getTwoToneRoundedIconProps({
                color: 'red',
                backgroundColor: 'violet.1',
              })}
            />
          )}
          <Text size="md" weight={400}>
            {text}
          </Text>
        </Box>
      ))}
    </ul>
  );
};
