import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { FileUploadIcon } from 'icons';

type Props = {
  isLoading: boolean;
  acceptText: string;
  additionalText: string;
};

export const Description = ({
  isLoading,
  acceptText,
  additionalText,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{ visibility: isLoading ? 'hidden' : undefined }}>
      <FileUploadIcon color="blue.6" />
      <Text sx={theme => ({ marginBottom: theme.other.spacing(0.5) })}>
        <Trans
          i18nKey="common:dragHereOrSearch"
          components={{
            b: (
              <Box
                sx={theme => ({ color: theme.fn.primaryColor() })}
                component="span"
              />
            ),
          }}
        />
      </Text>
      <Text size="md" weight={400}>
        {t('common:supportedFormatsByUs')} {acceptText.toUpperCase()}
      </Text>
      {Boolean(additionalText) && (
        <Text size="md" weight={400}>
          {additionalText}
        </Text>
      )}
    </Box>
  );
};
