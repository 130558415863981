import { Fragment, FC } from 'react';
import {
  Center,
  Divider,
  Menu,
  Navbar,
  ScrollArea,
  Stack,
  useMantineTheme,
} from '@mantine/core';

import { IconPropsType } from 'icons/types';

import {
  useGetIsMatch,
  useGetLinkRestProps,
  useGetNavbarLinks,
} from 'components/Layout/Navbar/hooks';
import { NavbarMenu } from 'components/Layout/Navbar/NavbarMenu';

export const NavbarMini = () => {
  const mantineTheme = useMantineTheme();
  const getIsMatch = useGetIsMatch();
  const getLinkRestProps = useGetLinkRestProps();
  const navbarLinks = useGetNavbarLinks();

  return (
    <Navbar.Section
      grow
      component={ScrollArea}
      sx={theme => ({
        padding: theme.other.spacing(2.5, 0.5, 2),
      })}
    >
      <Stack justify="flex-start" spacing={mantineTheme.other.spacing(0.5)}>
        {navbarLinks.map(({ sectionTranslateKey, links }, index) => (
          <Fragment key={sectionTranslateKey}>
            {index > 0 && (
              <Divider
                sx={theme => ({
                  margin: theme.other.spacing(1.5, 0),
                })}
              />
            )}
            {links
              .filter(link => Boolean(link.icon))
              .map(link => {
                const {
                  translateKey,
                  icon,
                  hrefKey,
                  toKey,
                  links: nestedLinks,
                } = link;
                const Icon = icon as FC<IconPropsType>;
                const linkRestProps = getLinkRestProps(hrefKey, toKey);
                const isMatch = getIsMatch(link);

                return (
                  <Menu
                    key={translateKey}
                    position="right-start"
                    offset={-22}
                    withinPortal
                  >
                    <Menu.Target>
                      <Center
                        {...linkRestProps}
                        sx={theme => ({
                          height: 36,
                          color: isMatch
                            ? theme.fn.primaryColor()
                            : theme.black,
                          backgroundColor: isMatch
                            ? theme.colors[theme.primaryColor][1]
                            : 'transparent',
                          borderRadius: theme.radius.sm,
                          '&:hover': {
                            backgroundColor:
                              theme.colors[theme.primaryColor][1],
                          },
                          cursor: 'pointer',
                        })}
                      >
                        <Icon />
                      </Center>
                    </Menu.Target>
                    <NavbarMenu
                      links={nestedLinks}
                      sx={theme => ({ marginTop: theme.other.spacing(3.5) })}
                    />
                  </Menu>
                );
              })}
          </Fragment>
        ))}
      </Stack>
    </Navbar.Section>
  );
};
