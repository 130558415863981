import { MantineTheme } from '@mantine/core';

import { ThemeComponentType } from '../types';

export const getInputStyles = (theme: MantineTheme) => ({
  width: '100%',
  borderColor: theme.colors.gray[3],
  padding: theme.other.spacing(1, 2),
  lineHeight: 'inherit',
  color: theme.black,
  fontSize: theme.fontSizes.md,
  fontWeight: 500,
  letterSpacing: 0.3,
  '&::placeholder': {
    color: theme.colors.gray[3],
    opacity: 1,
  },
  '&:disabled': {
    color: theme.black,
    borderColor: 'transparent',
    opacity: 1,
    '&::placeholder': {
      color: theme.colors.gray[3],
    },
  },
});

export const Input: ThemeComponentType = {
  styles: theme => ({
    input: getInputStyles(theme),
    invalid: {
      color: theme.black,
      borderColor: theme.colors.gray[3],
      '&::placeholder': {
        color: theme.colors.gray[3],
        opacity: 1,
      },
    },
    icon: {
      color: 'inherit',
      width: theme.other.spacing(3),
      height: theme.other.spacing(3),
      left: theme.other.spacing(2),
      margin: 'auto',
    },
    withIcon: {
      paddingLeft: theme.other.spacing(5.5),
    },
    rightSection: {
      width: 'initial',
      right: theme.other.spacing(2),
    },
  }),
};
