import { Box } from '@mantine/core';
import { Modal } from 'components/shared';
import { useGetWebhookLogs } from 'api/webhooks';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  webhookId: number;
};

export const WebhookLogsModal = ({ isOpen, handleClose, webhookId }: Props) => {
  const { t } = useTranslation(['common', 'api']);

  const { data: webhookLogs } = useGetWebhookLogs({
    pathParams: { webhookId },
  });

  const parsedLogs = webhookLogs?.map(({ createdAt, ...rest }) => ({
    ...rest,
  }));

  return (
    <Modal
      isOpen={isOpen}
      size={924}
      onClose={handleClose}
      title={t('api:webhooks.webhookLogsModalTitle')}
      defaultButtonText={t('common:back')}
      defaultButtonAction={handleClose}
    >
      <Box
        sx={theme => ({
          backgroundColor: '#0B0B0B',
          color: theme.colors.gray[1],
          fontFamily: 'Courier',
          minHeight: '500px',
          overflowY: 'auto',
          padding: theme.other.spacing(2),
        })}
      >
        <pre>{JSON.stringify(parsedLogs, null, 2)}</pre>
      </Box>
    </Modal>
  );
};
