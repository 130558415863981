import { useTranslation } from 'react-i18next';
import { Box, Button, Text, Tooltip } from '@mantine/core';

import { FileIcon } from 'icons';
import { URLS } from 'consts';
import { TransactionType, TransactionStatusType } from 'types';

import { Breadcrumbs, SectionHeader } from 'components/shared';
import { Status } from './Status';

type Props = {
  transactionId: string;
  transactionName: TransactionType['transactionName'];
  hasDocuments: boolean;
  updateTransaction: () => void;
  isUpdatingTransaction: boolean;
  isUpdateTransactionDisabled: boolean;
  errors: TransactionStatusType['errors'];
  warnings: TransactionStatusType['warnings'];
  updatedAt: TransactionType['updatedAt'];
};

export const Header = ({
  transactionId,
  transactionName,
  hasDocuments,
  updateTransaction,
  isUpdatingTransaction,
  isUpdateTransactionDisabled,
  errors,
  warnings,
  updatedAt,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  return (
    <div>
      <Breadcrumbs
        sx={theme => ({
          marginBottom: theme.other.spacing(1.5),
        })}
        items={[
          {
            name: t('transactions:transactions'),
            url: URLS.transactions,
          },
          {
            name: t('transactions:transactionList'),
            url: URLS.transactions,
          },
          {
            name: transactionId,
            url: '#',
          },
        ]}
      />
      <Status errors={errors} warnings={warnings} updatedAt={updatedAt} />
      <Box
        sx={theme => ({
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          ...theme.other.pageHeaderSpacingStyles,
          marginBottom: theme.other.spacing(1.5),
        })}
      >
        <div>
          <SectionHeader>{transactionId}</SectionHeader>
          {transactionName && <Text>{transactionName}</Text>}
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isUpdatingTransaction && (
            <Text
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                marginRight: theme.other.spacing(2.75),
              })}
              size="md"
              color={hasDocuments ? undefined : 'gray.7'}
            >
              <Box
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 28,
                  height: 28,
                  marginRight: theme.other.spacing(1),
                  backgroundColor: hasDocuments
                    ? theme.colors.green[1]
                    : theme.colors.gray[1],
                  borderRadius: theme.radius.sm,
                })}
                component="span"
              >
                <FileIcon color={hasDocuments ? 'green' : 'gray.7'} />
              </Box>
              {hasDocuments
                ? t('transactions:documentsCreated')
                : t('transactions:documentsNotCreated')}
            </Text>
          )}
          <Tooltip
            label={t('common:noAccessToFeature')}
            disabled={!isUpdateTransactionDisabled}
          >
            <span>
              <Button
                color={hasDocuments ? 'blue' : 'green'}
                disabled={isUpdateTransactionDisabled}
                loading={isUpdatingTransaction}
                onClick={updateTransaction}
              >
                {hasDocuments
                  ? t('transactions:updateDocuments')
                  : t('transactions:createDocuments')}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </div>
  );
};
