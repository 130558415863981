export const getUploadFileErrorMessage = (
  errors: { message: string; code: string }[],
  errorsMap: {
    'file-invalid-type': string;
    'file-too-large': string;
  },
) =>
  errors
    .reduce(
      (accumulator, { code }) =>
        `${accumulator}${errorsMap[code as keyof typeof errorsMap] || ''} `,
      '',
    )
    .trim();
