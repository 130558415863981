import { useTranslation } from 'react-i18next';
import { Button, Text } from '@mantine/core';

import {
  PercentageContainer,
  SectionHeader,
  StickyContent,
  TextInputReactRouterSearch,
} from 'components/shared';

import { ApiIcon, CreateIcon } from 'icons';

type Props = {
  isAddTechnicalUserButtonShown: boolean;
  onAddNewTechnicalUser: () => void;
};

export const Header = ({
  isAddTechnicalUserButtonShown,
  onAddNewTechnicalUser,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  return (
    <StickyContent hasPageHeaderSpacings>
      <SectionHeader icon={<ApiIcon />}>
        {t('api:technicalUsers')}
      </SectionHeader>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        size="md"
      >
        {t('api:technicalUsersManage')}
      </Text>
      <PercentageContainer
        sx={{
          display: 'flex',
        }}
      >
        {isAddTechnicalUserButtonShown && (
          <Button
            variant="light"
            color="green"
            leftIcon={<CreateIcon />}
            onClick={onAddNewTechnicalUser}
          >
            {t('common:create')}
          </Button>
        )}
        <TextInputReactRouterSearch
          sx={{
            maxWidth: 328,
            width: '100%',
            marginLeft: 'auto',
          }}
          placeholder={t('api:technicalUserSearchPlaceholder')}
        />
      </PercentageContainer>
    </StickyContent>
  );
};
