import { DOCUMENT_STAGES } from 'consts';

export const getDocumentStageTranslationKey = (
  stage: (typeof DOCUMENT_STAGES)[keyof typeof DOCUMENT_STAGES],
) => {
  switch (stage) {
    case DOCUMENT_STAGES.draft:
      return 'documents:stageStatus.draft';
    case DOCUMENT_STAGES.finished:
      return 'documents:stageStatus.finished';
    case DOCUMENT_STAGES.inAcceptance:
      return 'documents:stageStatus.inAcceptance';
    case DOCUMENT_STAGES.inSign:
      return 'documents:stageStatus.inSign';
    case DOCUMENT_STAGES.rejected:
      return 'documents:stageStatus.rejected';
    case DOCUMENT_STAGES.sent:
      return 'documents:stageStatus.sent';
    default:
      return '';
  }
};
