import { apiAuthClient } from 'api/helpers';

import {
  GetCustomizationsDocumentResponseType,
  GetCustomizationsDocumentRequestType,
} from '../types';

export const getCustomizationsDocument = async ({
  pathParams: { organisationId },
}: GetCustomizationsDocumentRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/customizations/document`;

  const { data } =
    await apiAuthClient.get<GetCustomizationsDocumentResponseType>(url);

  return data.data;
};
