import { useGetUser } from 'api/account';
import { ACCOUNT_DATE_FORMATS } from 'consts';
import { UserType } from 'types';

export const useGetUserDateInputFormat = () => {
  const { data: userData } = useGetUser();
  const { dateFormat } = userData as UserType;

  switch (dateFormat) {
    case ACCOUNT_DATE_FORMATS.variant2:
    case ACCOUNT_DATE_FORMATS.variant5:
      return 'yyyy-MM-dd';
    case ACCOUNT_DATE_FORMATS.variant3:
    case ACCOUNT_DATE_FORMATS.variant6:
      return 'MM-dd-yyyy';
    case ACCOUNT_DATE_FORMATS.variant1:
    case ACCOUNT_DATE_FORMATS.variant4:
    default:
      return 'dd-MM-yyyy';
  }
};
