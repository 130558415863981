import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { Box, MantineTheme } from '@mantine/core';

import { LayoutMainBottomPaddingSpacingContext } from 'context';
import { useIsMdBreakpoint } from 'hooks';

import { Header } from './Header';
import { Navbar } from './Navbar';
import { NavbarBottom } from './NavbarBottom';

export const Layout = () => {
  const [layoutMainBottomPaddingSpacing] = useContext(
    LayoutMainBottomPaddingSpacingContext,
  );

  const isMdBreakpoint = useIsMdBreakpoint();
  const [isNavbarOpen, { toggle: toggleNavbarOpen }] = useDisclosure(true);

  const getNavbarWidth = (theme: MantineTheme) =>
    theme.other.layoutSizes.navbarWidths[isNavbarOpen ? 1 : 0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'stretch',
          flexShrink: 0,
          position: 'sticky',
          top: 0,
          marginLeft: isMdBreakpoint ? getNavbarWidth(theme) : undefined,
          minHeight: theme.other.layoutSizes.headerHeight,
          backgroundColor: theme.colors[theme.primaryColor][9],
          borderBottomStyle: 'solid',
          borderBottomWidth: theme.other.layoutSizes.borderWidth,
          borderBottomColor: theme.colors.gray[1],
          zIndex: 100,
        })}
        component="header"
      >
        <Header toggleNavbarOpen={toggleNavbarOpen} />
      </Box>
      {isMdBreakpoint && (
        <Box
          sx={theme => ({
            flexShrink: 0,
            width: getNavbarWidth(theme),
            zIndex: 100,
          })}
        >
          <Box
            sx={theme => ({
              position: 'fixed',
              width: getNavbarWidth(theme),
              top: 0,
              height: '100%',
            })}
          >
            <Navbar isOpen={isNavbarOpen} />
          </Box>
        </Box>
      )}
      <Box
        sx={theme => ({
          marginLeft: isMdBreakpoint ? getNavbarWidth(theme) : undefined,
          height: '100%',
          padding: theme.other.spacing(2, 2, layoutMainBottomPaddingSpacing),
          backgroundColor: theme.other.layoutBodyColor,
          overflow: 'auto',
          [`@media (min-width: ${theme.breakpoints.md}px)`]: {
            borderLeft: `1px solid ${theme.colors.gray[1]}`,
          },
        })}
        component="main"
      >
        <Box
          sx={theme => ({
            maxWidth: theme.other.layoutSizes.containerWidth,
          })}
        >
          <Outlet />
        </Box>
      </Box>
      {!isMdBreakpoint && (
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'stretch',
            flexShrink: 0,
            position: 'sticky',
            bottom: 0,
            width: '100%',
            minHeight: theme.other.layoutSizes.bottomNavigationHeight,
            backgroundColor: theme.white,
            boxShadow: theme.shadows.sm,
            zIndex: 100,
          })}
          component="nav"
        >
          <NavbarBottom />
        </Box>
      )}
    </Box>
  );
};
