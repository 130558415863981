import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getHasHateoasAccess } from 'helpers';
import { useGetV1Url } from 'hooks';
import { CopyIcon, DeleteIcon, EditIcon } from 'icons';
import { ActionItemType, TemplateType } from 'types';
import { TableRowActionsBar } from 'components/shared';

import { getIsLocalTemplate } from '../../../../helpers';
import { LocalTemplateType } from '../../../../types';
import { DuplicateModal } from '../../../../DuplicateModal';
import { DeleteModal } from './DeleteModal';

type Props = {
  template: LocalTemplateType | TemplateType;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  refetchGetBoardTemplates: () => void;
};

export const TemplateActions = ({
  template,
  setIsExpanded,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const templateId = template.id;

  const getV1Url = useGetV1Url();

  const isLocalTemplate = getIsLocalTemplate(templateId);

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const hasUpdateAccess =
    isLocalTemplate ||
    getHasHateoasAccess('update', (template as TemplateType).__links);
  const hasDeleteAccess =
    isLocalTemplate ||
    getHasHateoasAccess('delete', (template as TemplateType).__links);

  const actions: (ActionItemType & { shouldRender: boolean })[] = [
    {
      shouldRender: hasUpdateAccess,
      name: t('common:edit'),
      to: isLocalTemplate
        ? getV1Url('templatesNew', {
            localTemplateId: templateId as string,
          })
        : getV1Url('template', {
            templateId: String(templateId),
          }),
      icon: <EditIcon />,
    },
    {
      shouldRender: hasDeleteAccess,
      name: t('common:delete'),
      action: () => {
        setIsDeleteModalOpen(true);
      },
      color: 'red',
      icon: <DeleteIcon />,
    },
    {
      shouldRender: true,
      name: t('common:duplicate'),
      action: () => {
        setIsDuplicateModalOpen(true);
      },
      icon: <CopyIcon />,
      dataTestId: 'TemplateActions_DuplicateTemplate',
    },
  ];

  return (
    <>
      <TableRowActionsBar
        items={actions
          .filter(({ shouldRender }) => shouldRender)
          .map(({ shouldRender: _, ...actionItems }) => actionItems)}
      />
      {isDuplicateModalOpen && (
        <DuplicateModal
          isOpen={isDuplicateModalOpen}
          onClose={() => {
            setIsDuplicateModalOpen(false);
          }}
          template={template}
          refetchGetBoardTemplates={refetchGetBoardTemplates}
        />
      )}
      {hasDeleteAccess && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onDeleteSuccess={() => {
            setIsExpanded(false);
          }}
          templateId={template.id}
        />
      )}
    </>
  );
};
