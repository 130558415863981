import { ThemeComponentType } from '../types';

export const Popover: ThemeComponentType = {
  defaultProps: {
    zIndex: 401,
  },
  styles: theme => ({
    dropdown: {
      borderColor: theme.colors.gray[1],
      boxShadow: theme.shadows.xs,
    },
  }),
};
