import { useTranslation } from 'react-i18next';

import { useGetUser } from 'api/account';
import { useGetV1Url } from 'hooks';
import { UserBoardType, UserType } from 'types';

import { NotFound } from 'components/shared';

import { Board } from '../Board';

type Props = {
  boards: UserBoardType[];
  changeBoard: (boardId: number) => Promise<void>;
};

export const Boards = ({ boards, changeBoard }: Props) => {
  const { t } = useTranslation('navigation');

  const getV1Url = useGetV1Url();

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  if (boards.length === 0) {
    return (
      <NotFound
        sx={theme => ({
          margin: theme.other.spacing(8, 0),
        })}
        description={t('navigation:organisations.notFoundDescription')}
        buttonText={t('navigation:organisations.managing')}
        externalLink={getV1Url('organisations')}
      />
    );
  }

  return (
    <ul>
      {boards.map(({ id, name, organisation }) => (
        <li key={id}>
          <Board
            organisationName={organisation.name}
            organisationLogoUrl={organisation.logoUrl}
            boardId={id}
            boardName={name}
            isActive={id === user.lastBoard.id}
            changeBoard={changeBoard}
          />
        </li>
      ))}
    </ul>
  );
};
