import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';

import { FileIcon } from 'icons';
import { TRANSACTION_DETAILS_TABS } from 'consts';
import { TransactionDetailsTabType } from 'types';

type Props = {
  activeTab: TransactionDetailsTabType;
  onTabChange: (nextTab: TransactionDetailsTabType) => void;
  hasDocuments: boolean;
};

export const DetailTabs = ({ activeTab, onTabChange, hasDocuments }: Props) => {
  const { t } = useTranslation('transactions');

  return (
    <Tabs
      styles={{
        tabLabel: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
      value={activeTab}
      onTabChange={onTabChange}
    >
      <Tabs.List>
        {hasDocuments && (
          <Tabs.Tab value={TRANSACTION_DETAILS_TABS.documents}>
            <FileIcon sx={theme => ({ marginRight: theme.other.spacing(1) })} />{' '}
            {t('transactions:documentsTab')}
          </Tabs.Tab>
        )}
        <Tabs.Tab value={TRANSACTION_DETAILS_TABS.data}>
          {t('transactions:dataTab')}
        </Tabs.Tab>
        <Tabs.Tab value={TRANSACTION_DETAILS_TABS.stream}>
          {t('transactions:streamTab')}
        </Tabs.Tab>
        <Tabs.Tab value={TRANSACTION_DETAILS_TABS.type}>
          {t('transactions:typeTab')}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};
