import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { CERTIFICATE_LANGUAGES } from 'consts';
import { FlagDeIcon, FlagPlIcon, FlagUkIcon } from 'icons';

const LANGUAGES = [
  {
    value: 'pl',
    translation: 'templates:lang.polish',
    Icon: FlagPlIcon,
  },
  {
    value: 'en',
    translation: 'templates:lang.english',
    Icon: FlagUkIcon,
  },
  {
    value: 'de',
    translation: 'templates:lang.german',
    Icon: FlagDeIcon,
  },
] as const;

type Props = {
  certificatesLanguages: (typeof CERTIFICATE_LANGUAGES)[keyof typeof CERTIFICATE_LANGUAGES][];
};

export const LanguageCertificates = ({ certificatesLanguages }: Props) => {
  const { t } = useTranslation('templates');

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.other.spacing(1.5),
      })}
      component="ul"
    >
      {certificatesLanguages.map(lang => {
        const { translation, Icon } = LANGUAGES.find(
          ({ value }) => value === lang,
        )!;

        return (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              marginTop: -3,
              fontWeight: 600,
              fontSize: theme.fontSizes.lg,
            })}
            key={lang}
            component="li"
          >
            <Icon
              sx={theme => ({
                marginRight: theme.other.spacing(0.5),
              })}
            />{' '}
            {t(translation)}
          </Box>
        );
      })}
    </Box>
  );
};
