import { Radio } from '@mantine/core';

import { TickIcon } from 'icons';
import { useGetColor } from 'hooks';

type Props = {
  color: string;
};

export const RadioColor = ({ color }: Props) => {
  const formattedColor = useGetColor(color);

  return (
    <Radio
      styles={{
        radio: {
          width: 48,
          height: 48,
          backgroundColor: formattedColor,
          borderColor: formattedColor,
          borderRadius: '50%',
          '&:checked': {
            backgroundColor: formattedColor,
            borderColor: formattedColor,
          },
        },
        icon: {
          width: 24,
          height: 24,
          inset: 0,
          margin: 'auto',
          path: {
            fill: 'none',
          },
        },
      }}
      value={formattedColor}
      icon={TickIcon}
      transitionDuration={0}
    />
  );
};
