import { Navigate, Outlet } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';

import { STORAGE_KEYS, URLS } from 'consts';
import { useGetUser } from 'api/account';
import { useLocalStorage } from '@mantine/hooks';

export const ProtectedRouteController = () => {
  const [token] = useLocalStorage({
    key: STORAGE_KEYS.token,
    serialize: value => value,
    getInitialValueInEffect: false,
  });

  const { data, isInitialLoading } = useGetUser({
    enabled: Boolean(token),
  });

  if (isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  if (data) {
    return <Navigate to={URLS.home} replace />;
  }

  return <Outlet />;
};
