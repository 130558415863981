import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDataType, usePatchDataType } from 'api/dataTypes';
import { DataTypeListItemType, ValidationErrors } from 'types';
import { PatchDataTypeRequestType } from 'api/dataTypes/types';
import {
  decodeYamlDataType,
  encodeYamlDataType,
  formatDataTypeName,
  getNestedErrorMessage,
} from 'helpers';
import {
  useServerErrors,
  useShowNotification,
  useStateWithCursorPosition,
} from 'hooks';
import { Modal, TextInput } from 'components/shared';
import { YamlEditor } from 'components/Transactions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  name: DataTypeListItemType['name'];
};

export const DataTypeEditModal = ({ isOpen, onClose, name }: Props) => {
  const { t } = useTranslation(['common', 'dataTypes']);

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const [nameField, setNameField, nameInputRef] = useStateWithCursorPosition();
  const [yamlValue, setYamlValue] = useState('');

  const [errorStatus, setErrorStatus] = useState<ValidationErrors>({});

  const {
    mutateAsync: dataTypeUpdateMutateAsync,
    isLoading: isDataTypeUpdating,
  } = usePatchDataType({
    pathParams: {
      dataTypeName: name,
    },
  });

  const {
    data: dataType,
    isLoading: isDataTypeLoading,
    remove: dataTypeRemove,
  } = useGetDataType(
    {
      pathParams: {
        dataTypeName: name,
      },
    },
    {
      enabled: isOpen,
      onError: error => {
        addServerErrorActions(error);
        onClose();
      },
      onSuccess: data => {
        setNameField({
          value: data.name,
          cursorPosition: null,
        });
        setYamlValue(decodeYamlDataType(data.yaml));
      },
    },
  );

  const updateDataType = async (
    yaml: PatchDataTypeRequestType['bodyParams']['yaml'],
  ) => {
    if (!nameField.value || !yamlValue) {
      setErrorStatus({
        ...(!nameField.value && { name: t('common:formErrors.required') }),
        ...(!yamlValue && { yaml: t('common:formErrors.required') }),
      });

      return;
    }

    try {
      await dataTypeUpdateMutateAsync({
        name: nameField.value,
        yaml: encodeYamlDataType(yaml),
      });

      showNotification({
        message: t('dataTypes:dataTypeUpdated'),
      });

      onClose();
      dataTypeRemove();
    } catch (error) {
      setErrorStatus(getServerErrorStatus(error) || {});

      addServerErrorActions(error);
    }
  };

  const closeWithResetState = () => {
    onClose();
    setErrorStatus({});

    if (dataType) {
      setNameField({
        value: name,
        cursorPosition: null,
      });
      setYamlValue(decodeYamlDataType(dataType.yaml));
    }
  };

  const { name: nameError, ...errors } = errorStatus || {};

  /* clear errorStatus when request is done after closing modal and has validation errors */
  useEffect(() => {
    if (!isOpen && Object.keys(errorStatus).length > 0) {
      setErrorStatus({});
    }
  }, [errorStatus, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeWithResetState}
      title={t('dataTypes:editDataType')}
      defaultButtonAction={closeWithResetState}
      defaultButtonDisabled={isDataTypeUpdating}
      primaryButtonAction={async () => {
        await updateDataType(yamlValue);
      }}
      primaryButtonDisabled={Object.keys(errorStatus).length > 0}
      primaryButtonIsLoading={isDataTypeUpdating}
      isFullLoading={isDataTypeLoading}
      fullLoadingText={t('dataTypes:dataTypeLoading')}
    >
      <TextInput
        ref={nameInputRef}
        label={t('dataTypes:dataTypeName')}
        value={nameField.value}
        error={nameError}
        onChange={e => {
          setErrorStatus(({ name: _, ...status }) => status);
          setNameField({
            value: formatDataTypeName(e.target.value),
            cursorPosition: e.target.selectionStart,
          });
        }}
      />
      <YamlEditor
        sx={theme => ({
          marginBottom: theme.other.spacing(-3),
        })}
        value={yamlValue}
        onChange={value => {
          setErrorStatus(status => ({
            ...(status?.name && { name: status.name }),
          }));
          setYamlValue(value);
        }}
        error={getNestedErrorMessage(errors)}
      />
    </Modal>
  );
};
