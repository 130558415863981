import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

type Item = { id: string | number };

export const useHandleCheckboxListStateChange = ({
  resetTriggerValue,
}: {
  resetTriggerValue: unknown;
}) => {
  const [lastClickedCheckboxIndex, setLastClickedCheckboxIndex] = useState(-1);

  useEffect(() => {
    setLastClickedCheckboxIndex(-1);
  }, [resetTriggerValue]);

  const handleCheckboxAllChange = <T extends Item>({
    e,
    setSelectedItems,
    pageItems,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    setSelectedItems: Dispatch<SetStateAction<T[]>>;
    pageItems: T[];
  }) => {
    setSelectedItems(selectedItems => {
      const hasSelectedItemOnCurrentPage = selectedItems.some(selectedItem =>
        pageItems.some(pageItem => pageItem.id === selectedItem.id),
      );

      if (e.target.checked && !hasSelectedItemOnCurrentPage) {
        return [
          ...new Map(
            [...selectedItems, ...pageItems].map(item => [item.id, item]),
          ).values(),
        ];
      }

      return selectedItems.filter(
        selectedItem =>
          !pageItems.some(pageItem => pageItem.id === selectedItem.id),
      );
    });

    setLastClickedCheckboxIndex(-1);
  };

  const handleCheckboxChange = <T extends Item>({
    e,
    itemId,
    itemPageIndex,
    setSelectedItems,
    pageItems,
  }: {
    e: ChangeEvent<HTMLInputElement>;
    itemId: T['id'];
    itemPageIndex: number;
    setSelectedItems: Dispatch<SetStateAction<T[]>>;
    pageItems: T[];
  }) => {
    const isShiftKeyPressed = (e.nativeEvent as PointerEvent).shiftKey;

    if (
      isShiftKeyPressed &&
      lastClickedCheckboxIndex !== -1 &&
      lastClickedCheckboxIndex !== itemPageIndex
    ) {
      const startsAt =
        lastClickedCheckboxIndex > itemPageIndex
          ? itemPageIndex
          : lastClickedCheckboxIndex;
      const endsAt =
        lastClickedCheckboxIndex > itemPageIndex
          ? lastClickedCheckboxIndex + 1
          : itemPageIndex + 1;

      if (e.target.checked) {
        setSelectedItems(selectedItems => [
          ...new Map(
            [...selectedItems, ...pageItems.slice(startsAt, endsAt)].map(
              item => [item.id, item],
            ),
          ).values(),
        ]);
      } else {
        const idsToRemove = pageItems
          .slice(startsAt, endsAt)
          .map(({ id }) => id);

        setSelectedItems(selectedItems =>
          selectedItems.filter(({ id }) => !idsToRemove.includes(id)),
        );
      }
    } else if (!e.target.checked) {
      setSelectedItems(selectedItems =>
        selectedItems.filter(({ id }) => id !== itemId),
      );
    } else {
      setSelectedItems(selectedItems => [
        ...selectedItems,
        pageItems.find(({ id }) => id === itemId) as T,
      ]);
    }

    setLastClickedCheckboxIndex(itemPageIndex);
  };

  return {
    handleCheckboxAllChange,
    handleCheckboxChange,
  };
};
