import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, DefaultProps, MantineTheme, Text } from '@mantine/core';

import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS, EMPTY_PLACEHOLDER } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { useGetLetterheadTranslation, useGetSignTypeTranslation } from 'hooks';

import { TableRowAccordion } from 'components/shared';
import { LanguageCertificates } from 'components/Templates';

import { getIsLocalTemplate } from '../../../helpers';
import { useLocalTemplates } from '../../../hooks';
import {
  BoardTemplateLocalListItemType,
  LocalTemplateType,
} from '../../../types';

import { TemplateActions } from './TemplateActions';
import { AttachmentTemplates } from './AttachmentTemplates';

type Props = {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  templateId: BoardTemplateLocalListItemType['id'];
  refetchGetBoardTemplates: () => void;
};

export const TemplateDetailsRow = ({
  templateId,
  isExpanded,
  setIsExpanded,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation('templates');

  const { localTemplates } = useLocalTemplates();

  const isLocalTemplate = getIsLocalTemplate(templateId);

  const getSignTypeTranslation = useGetSignTypeTranslation();
  const getLetterheadTranslation = useGetLetterheadTranslation();

  const {
    data: template,
    isError: isTemplateError,
    isLoading: isTemplateLoading,
  } = useGetTemplate(
    {
      pathParams: {
        templateId,
      },
    },
    {
      enabled: isExpanded && !isLocalTemplate,
    },
  );

  const localTemplate = isLocalTemplate
    ? localTemplates.find(({ id }) => id === templateId)
    : null;

  const firstColumnLabelSx: DefaultProps['sx'] = (theme: MantineTheme) => ({
    width: 200,
    marginRight: theme.other.spacing(2),
    textAlign: 'right',
  });

  return (
    <TableRowAccordion
      data={isLocalTemplate ? (localTemplate as LocalTemplateType) : template}
      isExpanded={isExpanded}
      withFirstCell
      isError={isLocalTemplate ? false : isTemplateError}
      isLoading={isLocalTemplate ? false : isTemplateLoading}
    >
      {templateData => {
        const { signType, certificatesLanguages, ...templateFields } =
          templateData;

        const hasBrandingFeature =
          '__links' in templateFields
            ? getHasHateoasAccess('branding', templateFields?.__links)
            : false;
        const attachmentTemplates =
          'attachmentTemplates' in templateFields
            ? templateFields.attachmentTemplates
            : [];
        const documentBranding =
          'documentBranding' in templateFields
            ? templateFields.documentBranding
            : null;

        return (
          <>
            <Box
              sx={theme => ({
                padding: theme.other.spacing(2.75, 0, 2.5),
                overflowX: 'auto',
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: 'fit-content',
                }}
              >
                <Box sx={{ flexShrink: 0, width: 440 }}>
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      marginBottom: theme.other.spacing(2.5),
                    })}
                  >
                    <Text sx={firstColumnLabelSx} size="md">
                      {t('templates:signType')}:
                    </Text>
                    <Text size="md" weight={600}>
                      {getSignTypeTranslation(signType)}
                    </Text>
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Text sx={firstColumnLabelSx} size="md">
                      {t('templates:certificateLangs')}:
                    </Text>
                    <LanguageCertificates
                      certificatesLanguages={certificatesLanguages}
                    />
                  </Box>
                </Box>
                <Box sx={{ flexShrink: 0, width: 300 }}>
                  <Text
                    sx={theme => ({
                      marginBottom: theme.other.spacing(2),
                    })}
                    size="md"
                  >
                    {t('templates:attachments')}
                  </Text>
                  {attachmentTemplates.length > 0 ? (
                    <AttachmentTemplates
                      key={String(isExpanded)}
                      attachmentTemplates={attachmentTemplates}
                    />
                  ) : (
                    EMPTY_PLACEHOLDER
                  )}
                </Box>
                <Box
                  sx={{
                    flexShrink: 0,
                    minWidth: 350,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Text
                      sx={theme => ({
                        marginRight: theme.other.spacing(1.5),
                      })}
                      size="md"
                    >
                      {t('templates:letterhead')}:
                    </Text>
                    <div>
                      <Text
                        sx={theme => ({
                          marginBottom: theme.other.spacing(0.25),
                        })}
                        size="md"
                        weight={600}
                      >
                        {documentBranding
                          ? getLetterheadTranslation(documentBranding.source)
                          : EMPTY_PLACEHOLDER}
                      </Text>
                      {!isLocalTemplate && (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {hasBrandingFeature && (
                            <Anchor
                              sx={{
                                fontWeight: 600,
                              }}
                              to={DYNAMIC_URLS.templateBranding({
                                templateId: String(templateId),
                              })}
                              component={Link}
                            >
                              {t('templates:letterheadDetails')}
                            </Anchor>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
            <TemplateActions
              template={templateData}
              setIsExpanded={setIsExpanded}
              refetchGetBoardTemplates={refetchGetBoardTemplates}
            />
          </>
        );
      }}
    </TableRowAccordion>
  );
};
