export const getVariableMappingInfoObject = (messages: string[]) =>
  messages.reduce((infoObject, message) => {
    const [, variableName] = message.match(/`(.+)`/) || [];

    if (variableName) {
      return {
        ...infoObject,
        [variableName]: message,
      };
    }

    return infoObject;
  }, {});
