import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePatchTransactionDataStream } from 'api/transactions';
import { useServerErrors, useShowNotification } from 'hooks';
import { DataStreamTemplateType, ValidationErrors } from 'types';
import { Modal } from 'components/shared';
import { DataStreamAddTemplateForm } from 'components/Transactions';

import { getDataStreamTemplateErrors } from '../../../helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  templates: DataStreamTemplateType[];
};

export const DataStreamAddTemplateModal = ({
  isOpen,
  onClose,
  templates,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  const formRef = useRef<HTMLFormElement>(null);

  const { transactionId, transactionDataStream } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
  };

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const [addStreamTemplateServerErrors, setAddStreamTemplateServerErrors] =
    useState<ValidationErrors | null>(null);

  const {
    mutateAsync: updateTransactionDataStream,
    isLoading: isDataStreamTemplateAdding,
  } = usePatchTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStream,
    },
  });

  const addDataStreamTemplate = async ({
    templateName: _updatedTemplateName,
    ...updatedTemplate
  }: DataStreamTemplateType) => {
    try {
      const templatesToUpdate = [
        ...templates.map(({ templateName: _templateName, ...template }) => ({
          templateId: template.templateId,
          documentName: template.documentName,
          documentReference: template.documentReference,
          documentExternalId: template.documentExternalId,
          documentContent: template.documentContent,
          isBlocked: template.isBlocked,
        })),
        updatedTemplate,
      ] as DataStreamTemplateType[];

      await updateTransactionDataStream({
        templates: templatesToUpdate,
      });

      showNotification({
        message: t('dataStreams:addTemplateModal.templateAdded'),
      });

      onClose();
    } catch (error) {
      const serverErrorStatus = getServerErrorStatus(error);

      if (serverErrorStatus) {
        setAddStreamTemplateServerErrors(
          getDataStreamTemplateErrors(serverErrorStatus),
        );
      }

      addServerErrorActions(error);
    }
  };

  const clearServerError = (errorKey: string) => {
    if (addStreamTemplateServerErrors?.[errorKey]) {
      setAddStreamTemplateServerErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('dataStreams:addTemplateModal.addNewTemplate')}
      defaultButtonAction={onClose}
      primaryButtonIsLoading={isDataStreamTemplateAdding}
      primaryButtonColor="green"
      primaryButtonText={t('common:add')}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <DataStreamAddTemplateForm
        formRef={formRef}
        addDataStreamTemplate={addDataStreamTemplate}
        errors={addStreamTemplateServerErrors}
        clearError={clearServerError}
        templates={templates}
      />
    </Modal>
  );
};
