import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { useGetUser } from 'api/account';
import {
  useGetCustomizationsEmail,
  useGetOrganisation,
  usePostCustomizationsEmailPreviewQuery,
  usePutCustomizationsEmail,
} from 'api/organisations';
import { getHasHateoasAccess, getUserFullName } from 'helpers';

import { DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import { useGetV1Url, useServerErrors, useShowNotification } from 'hooks';
import { SaveIcon } from 'icons';
import { UserType } from 'types';
import {
  ActionsBar,
  Breadcrumbs,
  DataWrapper,
  MetaTitle,
} from 'components/shared';
import {
  MailCustomizationNoAccessModal,
  OrganisationTabs,
} from 'components/Organisations';

import { SettingsType, UpdateCurrentSettingType } from './types';
import { Customization } from './Customization';

export const OrganisationMailCustomization = () => {
  const { t } = useTranslation(['common', 'organisations']);

  const { organisationId } = useParams() as {
    organisationId: string;
  };

  const { addServerErrorActions } = useServerErrors();
  const showNotification = useShowNotification();
  const getV1Url = useGetV1Url();

  const [currentSettings, setCurrentSettings] = useState<SettingsType | null>(
    null,
  );
  const [debouncedCurrentSettings] = useDebouncedValue(
    currentSettings,
    DEBOUNCED_INPUT_DELAY_IN_MS,
    { leading: true },
  );

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  const {
    data: organisation,
    isError: isOrganisationError,
    isFetched: isOrganisationFetched,
    isLoading: isOrganisationLoading,
  } = useGetOrganisation({
    pathParams: {
      organisationId,
    },
  });

  const {
    mutateAsync: updateCustomizationSettings,
    isLoading: isUpdatingOrganisationSettings,
  } = usePutCustomizationsEmail({
    pathParams: {
      organisationId,
    },
  });

  const hasEmailCustomizationFeature = getHasHateoasAccess(
    'emailCustomization',
    organisation?.__links,
  );

  const details = organisation?.details;

  const {
    data: customizationSettingsData,
    isError: isCustomizationSettingsError,
    isInitialLoading: isCustomizationSettingsInitialLoading,
  } = useGetCustomizationsEmail(
    {
      pathParams: { organisationId },
    },
    {
      enabled: hasEmailCustomizationFeature,
    },
  );
  const {
    data: previewHtmlData,
    isError: isPreviewHtmlError,
    isLoading: isPreviewHtmlLoading,
    isRefetching: isPreviewHtmlRefetching,
  } = usePostCustomizationsEmailPreviewQuery(
    {
      pathParams: { organisationId },
      bodyParams: {
        color: debouncedCurrentSettings?.color,
        withCustomFooter: debouncedCurrentSettings?.withCustomFooter,
        sender: {
          name: getUserFullName(user.name, user.surname) || undefined,
          email: user.email,
          phone: user.phone || undefined,
        },
        company: {
          name: details?.name || undefined,
          city: details?.city || undefined,
          postalCode: details?.postalCode || undefined,
          street: details?.street || undefined,
          website: details?.website || undefined,
        },
      },
    },
    {
      enabled:
        hasEmailCustomizationFeature && Boolean(debouncedCurrentSettings),
      keepPreviousData: true,
    },
  );

  const setCurrentSettingsFromCustomizationSettingsData = () => {
    if (customizationSettingsData) {
      const {
        data: { color, withCustomFooter },
        theme,
      } = customizationSettingsData;

      setCurrentSettings({
        color: (color || theme.color).toUpperCase(),
        withCustomFooter,
      });
    }
  };

  useEffect(() => {
    setCurrentSettingsFromCustomizationSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizationSettingsData]);

  return (
    <>
      <MetaTitle value={t('organisations:organisationManagement')} />
      <DataWrapper
        isError={
          isOrganisationError ||
          isCustomizationSettingsError ||
          isPreviewHtmlError
        }
        isLoading={
          isOrganisationLoading ||
          isCustomizationSettingsInitialLoading ||
          (hasEmailCustomizationFeature && !currentSettings)
        }
        errorMargin="xl"
      >
        {() => {
          if (isOrganisationFetched && !hasEmailCustomizationFeature) {
            return (
              <MailCustomizationNoAccessModal
                isOpen
                onClose={() => {
                  window.location.assign(getV1Url('contracts'));
                }}
              />
            );
          }

          const { name } = organisation as Exclude<
            typeof organisation,
            undefined
          >;
          const previewHtml = previewHtmlData as Exclude<
            typeof previewHtmlData,
            undefined
          >;

          const updateCurrentSetting: UpdateCurrentSettingType = (
            setting,
            value,
          ) => {
            setCurrentSettings(settings => ({
              ...(settings as SettingsType),
              [setting]: value,
            }));
          };

          return (
            <>
              <Breadcrumbs
                sx={theme => ({
                  marginBottom: theme.other.spacing(4.5),
                })}
                items={[
                  { name: t('organisations:organisationManagement'), url: '#' },
                  {
                    name,
                    url: '#',
                  },
                ]}
              />
              <OrganisationTabs />
              <Text
                sx={theme => ({
                  marginBottom: theme.other.spacing(3),
                })}
                size="md"
              >
                {t('organisations:mailPersonalizationInfo')}{' '}
                <Text span weight={700}>
                  {name}
                </Text>
              </Text>
              <Customization
                currentSettings={currentSettings as SettingsType}
                updateCurrentSetting={updateCurrentSetting}
                previewHtml={previewHtml}
                isPreviewHtmlLoading={
                  isPreviewHtmlLoading || isPreviewHtmlRefetching
                }
              />
              <ActionsBar
                disabled={isUpdatingOrganisationSettings}
                items={[
                  {
                    name: t('common:cancel'),
                    action: setCurrentSettingsFromCustomizationSettingsData,
                  },
                  {
                    name: t('common:save'),
                    action: async () => {
                      try {
                        await updateCustomizationSettings(
                          currentSettings as SettingsType,
                        );

                        showNotification({
                          message: t('organisations:updatedMailsAppearance'),
                        });
                      } catch (error) {
                        addServerErrorActions(error);
                      }
                    },
                    icon: <SaveIcon />,
                    color: 'blue',
                    isLoading: isUpdatingOrganisationSettings,
                  },
                ]}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
