import { Box, Text, Tooltip } from '@mantine/core';

type Props = {
  labelOn: string;
  labelOff: string;
  labelTooltip?: string;
  on?: boolean;
};

export const SimpleStatus = ({
  labelOn,
  labelOff,
  labelTooltip,
  on = false,
}: Props) => (
  <Tooltip disabled={!labelTooltip} label={labelTooltip} position="top">
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Box
        sx={({ colors, other }) => ({
          width: 9,
          height: 9,
          backgroundColor: on ? colors.green[0] : colors.gray[4],
          borderRadius: '50%',
          marginRight: other.spacing(1),
        })}
      />
      <Text
        size="sm"
        sx={({ colors, primaryColor }) => ({
          color: on ? colors[primaryColor][9] : colors.gray[7],
        })}
      >
        {on ? labelOn : labelOff}
      </Text>
    </Box>
  </Tooltip>
);
