import { ThemeComponentType } from '../types';

export const Radio: ThemeComponentType = {
  styles: theme => ({
    radioWrapper: {
      marginBottom: theme.other.spacing(2),
    },
    radio: {
      width: 30,
      height: 30,
      border: `1.5px solid ${theme.colors.gray[3]}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
      '&:checked': {
        '&:not(:disabled)': {
          backgroundColor: theme.white,
          borderColor: theme.fn.primaryColor(),
          '&:hover': {
            borderColor: theme.colors[theme.primaryColor][7],
            '+.mantine-Radio-icon': {
              path: {
                fill: theme.colors[theme.primaryColor][7],
              },
            },
          },
        },
      },
      '&:disabled': {
        backgroundColor: theme.colors.gray[1],
        '+.mantine-Radio-icon': {
          path: {
            fill: theme.colors.gray[4],
          },
        },
      },
    },
    icon: {
      width: 12,
      height: 12,
      top: 'calc(50% - 6px)',
      left: 'calc(50% - 6px)',
      path: {
        fill: theme.fn.primaryColor(),
      },
    },
    label: {
      paddingLeft: theme.other.spacing(1),
      fontWeight: 500,
      lineHeight: 'normal',
      cursor: 'pointer',
    },
    body: {
      alignItems: 'center',
    },
  }),
};
