import { ThemeComponentType } from '../types';

export const NumberInput: ThemeComponentType = {
  styles: theme => ({
    input: {
      paddingRight: theme.other.spacing(4),
    },
    control: {
      ':not(:disabled)': {
        '&:hover': {
          background: theme.colors.gray[1],
          cursor: 'pointer',
        },
      },
    },
  }),
};
