import { stringifyUrlQuery } from 'helpers';
import { GetWebhooksRequestType, GetWebhooksResponseType } from '../types';
import { apiAuthClient } from '../../helpers';

export const getWebhooks = async ({
  pathParams: { organisationId },
  queryParams: { page, perPage, sortDir } = {},
}: GetWebhooksRequestType) => {
  const query = stringifyUrlQuery({
    page,
    perPage,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/webhooks${query}`;
  const { data } = await apiAuthClient.get<GetWebhooksResponseType>(url);

  return data;
};
