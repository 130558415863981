import { useLayoutEffect, useRef, useState } from 'react';

export const useStateWithCursorPosition = () => {
  const [input, setInput] = useState<{
    value: string;
    cursorPosition: HTMLInputElement['selectionStart'];
  }>({
    value: '',
    cursorPosition: null,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (inputRef.current && input.cursorPosition) {
      inputRef.current.setSelectionRange(
        input.cursorPosition,
        input.cursorPosition,
      );
    }
  }, [input]);

  return [input, setInput, inputRef] as const;
};
