import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { ErrorTwoToneIcon, SuccessTwoToneIcon } from 'icons';

import { getTwoToneRoundedIconProps } from '../../../../helpers';

const infoItems = [
  {
    translation: 'user:deleteAccountInfo.logoutFromDevices',
    iconType: 'error',
  },
  {
    translation: 'user:deleteAccountInfo.deleteData',
    iconType: 'success',
  },
  {
    translation: 'user:deleteAccountInfo.documentsVisibility',
    iconType: 'success',
  },
];

export const DeleteAccountWarning = () => {
  const { t } = useTranslation('user');

  return (
    <div>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        size="md"
        weight={600}
      >
        {t('user:deleteAccountMeaning')}
      </Text>
      <ul>
        {infoItems.map(({ translation, iconType }) => (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.other.spacing(2),
            })}
            key={translation}
            component="li"
          >
            {iconType === 'success' ? (
              <SuccessTwoToneIcon
                {...getTwoToneRoundedIconProps({
                  color: 'green',
                  backgroundColor: 'white',
                  size: 30,
                })}
              />
            ) : (
              <ErrorTwoToneIcon
                {...getTwoToneRoundedIconProps({
                  color: 'red',
                  backgroundColor: 'white',
                  size: 30,
                })}
              />
            )}
            <Text sx={{ display: 'block' }} span>
              {t(translation)}
            </Text>
          </Box>
        ))}
      </ul>
    </div>
  );
};
