import { ReactNode } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { convertPxToPoint } from '../../helpers';

type Props = {
  children: ReactNode;
};
export const Title = ({ children }: Props) => (
  <View style={{ textAlign: 'center' }}>
    <Text
      style={{
        fontSize: convertPxToPoint(30),
        marginBottom: 24,
        fontWeight: 'bold',
      }}
    >
      {children}
    </Text>
  </View>
);
