import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getDataStreams } from '../requests';
import { GetDataStreamsRequestType } from '../types';

export const useGetDataStreams = (
  {
    queryParams: { dataType, name, page, perPage, sortDir },
  }: Omit<GetDataStreamsRequestType, 'pathParams'>,
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.dataStreams,
      { organisationId, dataType, name, page, perPage, sortDir },
    ],
    queryFn: () =>
      getDataStreams({
        pathParams: { organisationId },
        queryParams: { dataType, name, page, perPage, sortDir },
      }),
    ...options,
  });
};
