import { useMutation } from '@tanstack/react-query';

import { postAuthResetPassword } from '../requests';
import { PostAuthResetPasswordRequestType } from '../types';

export const usePostAuthResetPassword = () =>
  useMutation({
    mutationFn: (bodyParams: PostAuthResetPasswordRequestType['bodyParams']) =>
      postAuthResetPassword({
        bodyParams,
      }),
  });
