import { ReactNode } from 'react';
import { Box } from '@mantine/core';

type Props = {
  children: ReactNode;
  borderColor: string;
};

export const VariableMappingItem = ({ children, borderColor }: Props) => (
  <Box
    sx={theme => ({
      width: '100%',
      minHeight: 38,
      padding: theme.other.spacing(1.25, 1.5, 1.25, 2.5),
      border: `1px solid ${borderColor}`,
      borderRadius: theme.radius.sm,
      fontSize: theme.fontSizes.md,
      fontWeight: 400,
    })}
    component="span"
  >
    {children}
  </Box>
);
