import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GuideTourContext } from 'context';
import { GUIDE_TOUR_DATA } from 'consts';

import { GuideTour } from 'components/shared';

type Props = {
  onClose: () => void;
};
export const GuideTourOnDemand = ({ onClose }: Props) => {
  const { t } = useTranslation('user');

  const [, setGuideTourState] = useContext(GuideTourContext);

  const changeUserMenuOpenForGuideTourState = (isOpen: boolean) => {
    setGuideTourState(guideTourState => ({
      ...guideTourState,
      isUserMenuOpenForGuideTour: isOpen,
    }));
  };

  const handleClose = () => {
    changeUserMenuOpenForGuideTourState(false);
    onClose();
  };

  const steps = [
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.changeOrganisationAndBoard.selector}"]`,
      title: t(GUIDE_TOUR_DATA.changeOrganisationAndBoard.titleTranslation),
      content: t(GUIDE_TOUR_DATA.changeOrganisationAndBoard.contentTranslation),
      isBackButtonEnabled: false,
    },
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.createNewDocument.selector}"]`,
      title: t(GUIDE_TOUR_DATA.createNewDocument.titleTranslation),
      content: t(GUIDE_TOUR_DATA.createNewDocument.contentTranslation),
      nextButtonCallback: () => {
        changeUserMenuOpenForGuideTourState(true);
      },
    },
    {
      target: `[data-guide-tour-step="${GUIDE_TOUR_DATA.userMenu.selector}"]`,
      title: t(GUIDE_TOUR_DATA.userMenu.titleTranslation),
      content: t(GUIDE_TOUR_DATA.userMenu.contentTranslation),
      backButtonCallback: () => {
        changeUserMenuOpenForGuideTourState(false);
      },
      nextButtonCallback: handleClose,
    },
  ].map(step => ({ disableBeacon: true, ...step }));

  return <GuideTour steps={steps} onClose={handleClose} />;
};
