import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { OTP_DIGITS_NUMBER } from 'consts';

type Props = {
  codeNumber: number;
  validUntil: string;
};

export const OtpLabel = ({ codeNumber, validUntil }: Props) => {
  const { t } = useTranslation('user');

  return (
    <div>
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
        })}
      >
        <Countdown
          key={validUntil}
          date={validUntil}
          renderer={({ completed, formatted }) => (
            <Text color={completed ? 'red' : undefined}>
              {completed ? (
                t('user:otpCodeExpired', { codeNumber })
              ) : (
                <>
                  {t('user:codeValidUntil', {
                    codeNumber,
                  })}{' '}
                  {formatted.minutes}:{formatted.seconds}
                </>
              )}
            </Text>
          )}
        />
      </Box>
      <Text size="md" weight={400}>
        {t('user:otpCodeInsert', { digits: OTP_DIGITS_NUMBER })}
      </Text>
    </div>
  );
};
