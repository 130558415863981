export const DYNAMIC_URLS = {
  dataStreamEdit: ({ dataStreamId }: { dataStreamId: string }) =>
    `/data-streams/${dataStreamId}/edit`,
  invitation: ({ invitationId }: { invitationId: string }) =>
    `/invitation/${invitationId}`,
  templateBranding: ({ templateId }: { templateId: string }) =>
    `/templates/${templateId}/branding`,
  templateEdit: ({ templateId }: { templateId: string }) =>
    `/templates/${templateId}`,
  templateAttachmentEdit: ({
    templateId,
    attachmentName,
  }: {
    templateId: string;
    attachmentName: string;
  }) => `/templates/${templateId}/attachments/${attachmentName}`,
  templateAttachmentAdd: ({ templateId }: { templateId: string }) =>
    `/templates/${templateId}/attachments/add`,
  templateAttachmentAddFromTemplate: ({
    templateId,
    baseTemplateId,
  }: {
    templateId: string;
    baseTemplateId: string;
  }) => `/templates/${templateId}/attachments/add/${baseTemplateId}`,
  transactionDetails: ({
    transactionId,
    transactionDataStream,
    tab,
  }: {
    transactionId: string;
    transactionDataStream: string;
    tab?: string;
  }) =>
    `/transactions/${transactionId}/streams/${transactionDataStream}/details${
      tab ? `/${tab}` : ''
    }`,
  transactionEntries: ({
    transactionId,
    transactionDataStream,
  }: {
    transactionId: string;
    transactionDataStream: string;
  }) =>
    `/transactions/${transactionId}/streams/${transactionDataStream}/entries`,
  transactionEntry: ({
    transactionId,
    transactionDataStream,
    transactionEntry,
    tab,
  }: {
    transactionId: string;
    transactionDataStream: string;
    transactionEntry: string;
    tab?: string;
  }) =>
    `/transactions/${transactionId}/streams/${transactionDataStream}/entries/${transactionEntry}${
      tab ? `/${tab}` : ''
    }`,
  organisationBranding: ({ organisationId }: { organisationId: string }) =>
    `/organisations/${organisationId}/branding`,
  organisationMailCustomization: ({
    organisationId,
  }: {
    organisationId: string;
  }) => `/organisations/${organisationId}/mail-customization`,
};
