import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getDataType } from '../requests';
import { GetDataTypeRequestType, GetDataTypeResponseType } from '../types';

export const useGetDataType = (
  {
    pathParams: { dataTypeName },
  }: {
    pathParams: Omit<GetDataTypeRequestType['pathParams'], 'organisationId'>;
  },
  options?: {
    enabled?: boolean;
    onError?: (error: unknown) => void;
    onSuccess?: (data: GetDataTypeResponseType['data']) => void;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [API_QUERY_KEYS.dataStream, { organisationId, dataTypeName }],
    queryFn: () =>
      getDataType({ pathParams: { organisationId, dataTypeName } }),
    ...options,
  });
};
