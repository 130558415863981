import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const FeedbackIcon = ({
  color,
  size,
  width = 166,
  height = 129,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 166 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <mask
        id={`${id}_a`}
        width={95}
        height={68}
        x={70}
        y={28}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M73.789 77.87c2.916 1.612 4.414 3.312 4.628 4.563 0 0 63.824 20.826 80.624 10.33 13.603-8.503-.281-17.836-4.459-18.637-7.86-1.495-8.299-1.866-4.808-8.981 3.491-7.105 8.434-11.287-3.93-12.343-8.738-.752-9.166-2.99-9.605-11.6-.439-8.6-16.159-9.724-21.834-7.486-5.675 2.248 0-3.372-5.236-4.486-5.236-1.124-6.553 1.495-7.421 3.372-.878 1.866-11.79 2.619-13.974 9.352-2.184 6.734-10.922 5.981-7.86 17.21 3.052 11.22-1.305 6.733-4.808 7.857-3.49 1.124-7.42 7.486-1.306 10.848"
        />
      </mask>
      <g mask={`url(#${id}_a)`}>
        <mask
          id={`${id}_b`}
          width={95}
          height={68}
          x={70}
          y={28}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M164.874 28.966H70.591v66.758h94.283V28.966Z" />
        </mask>
        <g mask={`url(#${id}_b)`}>
          <path fill="#080717" d="M166 27.99H69.465V96.7H166V27.99Z" />
        </g>
      </g>
      <mask
        id={`${id}_c`}
        width={120}
        height={47}
        x={40}
        y={82}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m40.819 126.371 26.62-35.582c3.265-4.457 9.008-7.125 15.133-7.046l48.847-.948c6.881-.137 13.04 3.645 15.134 9.274l13.366 35.944-119.089-1.652-.011.01Z"
        />
      </mask>
      <g mask={`url(#${id}_c)`}>
        <path fill="#fff" d="M161.034 81.7H39.693V129h121.341V81.7Z" />
      </g>
      <mask
        id={`${id}_d`}
        width={120}
        height={47}
        x={40}
        y={82}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M159.278 128.023c-.271 0-.518-.147-.608-.372l-13.22-35.093c-1.971-5.239-7.882-8.806-14.357-8.679l-48.33.919c-5.844-.078-11.361 2.463-14.458 6.626l-26.338 34.742c-.191.244-.574.322-.878.156-.293-.156-.383-.488-.191-.742l26.326-34.733c3.322-4.476 9.279-7.192 15.517-7.124l48.307-.919c7.038-.136 13.445 3.743 15.584 9.431l13.22 35.094c.101.283-.079.586-.406.674a.628.628 0 0 1-.191.029"
        />
      </mask>
      <g mask={`url(#${id}_d)`}>
        <path fill="#080717" d="M161.124 81.69H39.614V129h121.51V81.69Z" />
      </g>
      <mask
        id={`${id}_e`}
        width={46}
        height={39}
        x={75}
        y={31}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M120.215 50.505c0 10.702-9.999 19.38-22.329 19.38s-22.33-8.678-22.33-19.38c0-10.7 10-19.379 22.33-19.379 12.33 0 22.329 8.678 22.329 19.38Z"
        />
      </mask>
      <g mask={`url(#${id}_e)`}>
        <path fill="#080717" d="M121.341 30.149h-46.91v40.713h46.91V30.149Z" />
      </g>
      <mask
        id={`${id}_f`}
        width={48}
        height={59}
        x={83}
        y={37}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M125.632 57.66a6.273 6.273 0 0 0-2.804.126c1.61-7.584-2.691-15.245-10.607-18.568-9.752-4.095-21.361-.186-25.651 8.63l-2.343 4.827c-3.75 7.72 1.453 16.565 10.72 18.187l1.69.293v12.597H88.19C91.04 90.72 98.9 95.724 108.156 95.724s17.127-5.014 19.964-11.972h-10.326V70.696c1.262-1.134 2.207-2.57 2.669-4.222l.079-.293a5.926 5.926 0 0 0 2.815 1.339c3.052.557 6.036-1.192 6.655-3.919.631-2.717-1.34-5.375-4.392-5.932"
        />
      </mask>
      <g mask={`url(#${id}_f)`}>
        <path fill="#fff" d="M131.78 34.136H79.352v62.565h52.428V34.136Z" />
      </g>
      <mask
        id={`${id}_g`}
        width={51}
        height={59}
        x={80}
        y={37}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M106.962 95.724c-9.515 0-17.983-4.867-21.08-12.109a.478.478 0 0 1 .08-.498.686.686 0 0 1 .529-.234h8.017V71.517l-1.216-.205c-4.718-.792-8.704-3.333-10.945-6.959-2.24-3.635-2.421-7.876-.507-11.639l2.41-4.73c2.196-4.32 6.25-7.613 11.418-9.274 5.168-1.662 10.754-1.476 15.753.537 8.04 3.235 12.623 10.545 11.396 18.002a7.675 7.675 0 0 1 2.218.058c3.468.606 5.709 3.509 4.999 6.47-.349 1.436-1.328 2.668-2.758 3.47-1.43.8-3.142 1.084-4.831.79a6.943 6.943 0 0 1-2.398-.889c-.507 1.378-1.374 2.64-2.545 3.695v12.05h9.954c.214 0 .405.087.529.234a.479.479 0 0 1 .079.498c-3.085 7.242-11.564 12.109-21.079 12.109l-.023-.01ZM87.425 83.977c3.153 6.401 10.89 10.652 19.537 10.652 8.648 0 16.384-4.25 19.537-10.652h-9.65c-.349 0-.642-.244-.642-.547V70.618c0-.147.067-.284.18-.381 1.261-1.085 2.151-2.424 2.567-3.89l.09-.293a.595.595 0 0 1 .451-.391.72.72 0 0 1 .619.137c.709.596 1.599.997 2.567 1.173 1.34.234 2.714.01 3.863-.636 1.148-.645 1.925-1.632 2.207-2.775.574-2.365-1.228-4.691-4.009-5.18a6.09 6.09 0 0 0-2.556.108.729.729 0 0 1-.619-.117.494.494 0 0 1-.203-.509c1.61-7.241-2.714-14.483-10.517-17.62a21.735 21.735 0 0 0-14.751-.498c-4.842 1.554-8.637 4.642-10.686 8.688L83 53.164c-1.757 3.46-1.588 7.358.473 10.691 2.06 3.333 5.72 5.668 10.055 6.391l1.734.294c.305.048.519.273.519.537V83.44c0 .303-.282.547-.642.547h-7.714v-.01Z"
        />
      </mask>
      <g mask={`url(#${id}_g)`}>
        <path fill="#080717" d="M131.859 36.061h-53.06v60.63h53.06v-60.63Z" />
      </g>
      <mask
        id={`${id}_h`}
        width={6}
        height={5}
        x={120}
        y={59}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M120.722 63.425s-.067 0-.101-.01c-.27-.068-.451-.371-.394-.694.202-1.182.799-2.189 1.655-2.853.856-.665 1.892-.89 2.894-.655.27.068.45.371.394.694-.056.313-.315.528-.597.46-1.531-.372-3.051.79-3.367 2.589-.045.273-.259.469-.495.469"
        />
      </mask>
      <g mask={`url(#${id}_h)`}>
        <path fill="#080717" d="M126.352 57.991h-7.308v6.411h7.308v-6.41Z" />
      </g>
      <mask
        id={`${id}_i`}
        width={11}
        height={12}
        x={90}
        y={39}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M95.071 50.505a.76.76 0 0 1-.44-.127c-.27-.185-.303-.518-.055-.733l3.31-2.922c.89-.782 1.295-1.827 1.115-2.863-.18-1.036-.912-1.964-2.027-2.531-.022-.01-.045-.02-.056-.04-.079-.048-1.97-1.192-5.518.089-.326.117-.732 0-.878-.254-.158-.264 0-.567.326-.694 4.133-1.495 6.565-.166 6.892.039 1.419.742 2.364 1.925 2.59 3.254.225 1.339-.304 2.678-1.442 3.684l-3.31 2.923a.757.757 0 0 1-.496.175"
        />
      </mask>
      <g mask={`url(#${id}_u)`}>
        <path fill="#080717" d="M101.67 37.967H89.227v13.516h12.443V37.967Z" />
      </g>
      <mask
        id={`${id}_j`}
        width={4}
        height={3}
        x={107}
        y={46}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M109.822 47.857c-.54.498-1.317.635-1.745.313-.417-.323-.327-.987.214-1.486.54-.498 1.317-.635 1.745-.312.417.322.327.987-.214 1.485Z"
        />
      </mask>
      <g mask={`url(#${id}_j)`}>
        <path fill="#080717" d="M111.579 45.072h-5.056v4.407h5.056v-4.407Z" />
      </g>
      <mask
        id={`${id}_k`}
        width={4}
        height={3}
        x={92}
        y={44}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M94.936 45.707c-.54.498-1.318.635-1.745.313-.428-.323-.327-.987.214-1.486.54-.498 1.317-.635 1.745-.312.428.322.326.987-.214 1.485Z"
        />
      </mask>
      <g mask={`url(#${id}_k)`}>
        <path fill="#080717" d="M96.681 42.912h-5.044v4.407h5.044v-4.407Z" />
      </g>
      <mask
        id={`${id}_l`}
        width={11}
        height={6}
        x={95}
        y={54}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="m95.409 54.805 9.92.04s-5.337 9.626-9.92-.04Z" />
      </mask>
      <g mask={`url(#${id}_l)`}>
        <path fill="#fff" d="M106.455 53.828H94.283v11.62h12.172v-11.62Z" />
      </g>
      <mask
        id={`${id}_m`}
        width={14}
        height={8}
        x={92}
        y={52}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M98.753 59.115h-.101c-2.173-.058-4.076-1.954-5.675-5.639a.554.554 0 0 1 .067-.557.674.674 0 0 1 .54-.263l11.103.048c.226 0 .428.108.552.274a.56.56 0 0 1 .045.577c-.112.224-2.86 5.56-6.52 5.56h-.01Zm-4.189-5.257c1.273 2.628 2.692 4.026 4.122 4.065 1.981.059 3.918-2.404 4.92-4.017l-9.042-.039v-.01Z"
        />
      </mask>
      <g mask={`url(#${id}_m)`}>
        <path fill="#080717" d="M106.5 51.678H91.772v8.415H106.5v-8.415Z" />
      </g>
      <mask
        id={`${id}_n`}
        width={11}
        height={20}
        x={112}
        y={37}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M122.501 56.965c1.126-7.906-2.725-15.744-9.695-19.38-.439 7.37 3.333 14.543 9.695 19.38Z"
        />
      </mask>
      <g mask={`url(#${id}_n)`}>
        <path
          fill="#080717"
          d="M124.753 36.609h-13.512v21.334h13.512V36.609Z"
        />
      </g>
      <mask
        id={`${id}_o`}
        width={11}
        height={8}
        x={105}
        y={41}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M114.653 48.346c-.259 0-.496-.137-.574-.362-1.701-4.593-8.13-5.062-8.198-5.062-.327-.02-.574-.274-.552-.557.023-.284.304-.499.642-.48.293.02 7.308.529 9.256 5.786.101.274-.079.567-.394.655a.577.577 0 0 1-.18.03"
        />
      </mask>
      <g mask={`url(#${id}_o)`}>
        <path fill="#080717" d="M116.454 40.889h-12.273v8.434h12.273v-8.434Z" />
      </g>
      <mask
        id={`${id}_p`}
        width={13}
        height={5}
        x={95}
        y={65}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M96.062 69.885c-.35 0-.642-.264-.653-.596-.011-.343.27-.636.63-.655 3.987-.157 10.788-2.98 10.855-3 .338-.137.721 0 .867.322a.606.606 0 0 1-.337.821c-.293.117-7.094 2.942-11.328 3.108h-.023"
        />
      </mask>
      <g mask={`url(#${id}_p)`}>
        <path fill="#080717" d="M109.034 64.51H94.272v6.342h14.762V64.51Z" />
      </g>
      <mask
        id={`${id}_q`}
        width={36}
        height={34}
        x={23}
        y={95}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m58.194 128.023-13.93-21.539 1.622-10.76h-22.43l7.78 32.299h26.957Z"
        />
      </mask>
      <g mask={`url(#${id}_q)`}>
        <path fill="#fff" d="M59.32 94.747H22.33V129h36.99V94.747Z" />
      </g>
      <mask
        id={`${id}_r`}
        width={36}
        height={36}
        x={23}
        y={93}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M57.563 128.023a.662.662 0 0 1-.563-.293l-13.411-22.233a.562.562 0 0 1-.068-.333l1.475-10.486H24.863l7.342 32.68c.067.303-.158.596-.496.655-.349.059-.675-.137-.743-.44l-7.5-33.344a.501.501 0 0 1 .136-.46.69.69 0 0 1 .484-.205h21.62c.18 0 .349.069.473.186a.5.5 0 0 1 .157.44l-1.542 10.935 13.32 22.077c.158.273.046.606-.258.752a.75.75 0 0 1-.293.069Z"
        />
      </mask>
      <g mask={`url(#${id}_r)`}>
        <path fill="#080717" d="M59.41 92.597H22.307V129H59.41V92.597Z" />
      </g>
      <mask
        id={`${id}_s`}
        width={45}
        height={50}
        x={11}
        y={48}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M13.681 48.658c6.07-.127 19.83-.234 24.672-.303 1.193-.019 2.24.665 2.612 1.672l14.627 44.71c.552 1.554-.743 3.137-2.567 3.137h-25.28c-1.204 0-2.274-.713-2.6-1.76L11.17 51.709c-.473-1.515.766-3.02 2.523-3.06"
        />
      </mask>
      <g mask={`url(#${id}_s)`}>
        <path fill="#080717" d="M57.259 47.359H9.549V98.86h47.71V47.359Z" />
      </g>
      <mask
        id={`${id}_t`}
        width={30}
        height={46}
        x={16}
        y={59}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m28.297 100.855 2.016-3.108.191-6.216 13.389-7.525c1.092-.615 1.79-1.641 1.88-2.765v-.186c.113-1.28-1.441-2.16-2.747-1.554-3.4 1.573-8.783 4.075-11.07 5.209 2.275-1.456 7.624-5.209 10.867-7.505 1.633-1.154 2.432-2.971 2.106-4.77v-.039c-.203-1.065-1.61-1.602-2.646-1.006l-15.36 8.854 13.828-11.366c1.002-.82 1.261-2.11.642-3.176l-.327-.567c-.472-.82-1.689-1.085-2.556-.557L21.913 74.742l.191-6.118 5.675-4.222c1.475-1.094 2.005-2.873 1.34-4.446-.337-.812-1.486-1.114-2.286-.606l-8.422 5.326c-1.42.899-2.264 2.326-2.264 3.85v9.763l-.123 7.261c-.046 2.522.709 5.004 2.184 7.174l7.837 11.6 2.263-3.489-.01.02Z"
        />
      </mask>
      <g mask={`url(#${id}_t)`}>
        <path fill="#fff" d="M47 57.884H14.842v47.437h32.16V57.884Z" />
      </g>
      <mask
        id={`${id}_u`}
        width={33}
        height={46}
        x={13}
        y={59}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M24.604 104.334c-.225 0-.45-.098-.563-.264L15.9 92.743c-1.588-2.209-2.399-4.73-2.354-7.28l.124-7.095v-9.52c0-1.65.98-3.214 2.612-4.182l8.75-5.199a2.626 2.626 0 0 1 1.948-.264c.675.166 1.193.567 1.43 1.104.788 1.75.146 3.734-1.577 4.936l-5.675 3.967L21 73.882l16.182-9.314c.585-.342 1.328-.45 2.015-.312.698.136 1.284.518 1.6 1.045l.337.548c.777 1.26.45 2.755-.8 3.723l-8.873 6.86 9.65-5.228a2.93 2.93 0 0 1 2.331-.166c.766.274 1.295.83 1.43 1.505.383 1.974-.54 3.948-2.41 5.19-1.373.908-3.209 2.13-5.044 3.332a620.871 620.871 0 0 1 4.538-1.974 3.072 3.072 0 0 1 2.635.088c.81.43 1.261 1.153 1.194 1.935v.175c-.113 1.261-.958 2.424-2.264 3.108L29.896 91.59l-.191 5.785a.46.46 0 0 1-.079.245l-2.106 3.039c-.18.254-.574.352-.89.205-.315-.146-.427-.469-.247-.723l2.016-2.922.191-5.932c0-.176.113-.332.293-.43l13.906-7.35c.957-.507 1.577-1.358 1.656-2.286v-.176c.045-.39-.18-.762-.586-.967a1.528 1.528 0 0 0-1.306-.04c-3.254 1.408-9.076 3.949-11.486 5.082-.304.137-.687.069-.878-.166-.192-.234-.124-.547.158-.723 2.387-1.436 8.084-5.199 11.26-7.31 1.509-.997 2.252-2.6 1.948-4.163-.068-.352-.304-.606-.664-.733a1.312 1.312 0 0 0-1.07.078l-15.956 8.64c-.281.156-.664.107-.878-.108-.214-.215-.192-.528.056-.714L39.411 68.82c.834-.645 1.047-1.641.53-2.472l-.338-.547c-.147-.245-.428-.42-.755-.489a1.367 1.367 0 0 0-.946.147l-17.25 9.929a.806.806 0 0 1-.687.039c-.214-.098-.35-.284-.35-.479l.203-5.971c0-.147.09-.293.225-.391l5.9-4.124c1.318-.919 1.802-2.433 1.206-3.763-.102-.224-.316-.39-.586-.459a1.046 1.046 0 0 0-.788.108l-8.75 5.199c-1.306.772-2.082 2.013-2.082 3.322v9.529l-.124 7.105c-.045 2.365.709 4.69 2.173 6.733l8.141 11.327c.18.254.079.576-.236.723a.747.747 0 0 1-.327.068"
        />
      </mask>
      <g mask={`url(#${id}_u)`}>
        <path fill="#080717" d="M46.978 58.05H12.364v47.261h34.614V58.05Z" />
      </g>
      <mask
        id={`${id}_v`}
        width={13}
        height={9}
        x={23}
        y={74}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M24.052 82.804a.66.66 0 0 1-.484-.215c-.192-.244-.135-.576.124-.762l11.204-7.525c.27-.176.642-.127.833.117.191.245.135.577-.124.763l-11.204 7.525a.668.668 0 0 1-.36.107"
        />
      </mask>
      <g mask={`url(#${id}_v)`}>
        <path fill="#080717" d="M37.07 73.14H22.25v10.642h14.82V73.139Z" />
      </g>
      <mask
        id={`${id}_w`}
        width={13}
        height={8}
        x={28}
        y={82}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M28.995 89.264a.625.625 0 0 1-.495-.225c-.169-.225-.079-.508.191-.645l11.25-5.521c.27-.137.63-.069.799.156.169.225.079.508-.192.645L29.3 89.196a.656.656 0 0 1-.304.068Z"
        />
      </mask>
      <g mask={`url(#${id}_w)`}>
        <path fill="#080717" d="M42.024 81.759H27.205v8.482h14.819V81.76Z" />
      </g>
      <mask
        id={`${id}_x`}
        width={8}
        height={5}
        x={13}
        y={74}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M14.177 78.495a.792.792 0 0 1-.406-.098c-.281-.147-.326-.43-.1-.616l1.44-1.192c.631-.528 1.397-.968 2.252-1.31l2.624-1.035c.304-.118.71-.05.89.156.18.205.079.479-.226.596l-2.623 1.036a7.097 7.097 0 0 0-1.903 1.104l-1.441 1.193a.791.791 0 0 1-.507.166Z"
        />
      </mask>
      <g mask={`url(#${id}_x)`}>
        <path fill="#080717" d="M22.183 73.149h-9.864v6.323h9.864v-6.323Z" />
      </g>
      <mask
        id={`${id}_y`}
        width={90}
        height={40}
        x={1}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M1.194 20.923 2.87 8.424c.575-4.3 4.775-7.515 9.73-7.447l68.36.987c5.71.078 10.079 4.457 9.415 9.421l-1.7 12.568c-.575 4.28-4.764 7.486-9.696 7.437l-49.827-.538.507 6.43c.18 2.258-3.097 3.353-4.696 1.574l-7.724-8.629-6.272.117c-5.845.108-10.45-4.329-9.774-9.401"
        />
      </mask>
      <g mask={`url(#${id}_y)`}>
        <mask
          id={`${id}_z`}
          width={90}
          height={40}
          x={1}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M90.443.977H1.126v38.759h89.317V.977Z" />
        </mask>
        <g mask={`url(#${id}_z)`}>
          <path fill={iconColor} d="M91.57 0H0v40.713h91.57V0Z" />
        </g>
      </g>
      <mask
        id={`${id}_A`}
        width={23}
        height={20}
        x={6}
        y={7}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m14.92 18.568-.248 8.248 6.633-6.596 7.116 1.651-4.91-5.98 2.208-6.392-6.87 3.088-5.404-5.15.495 7.427-7.848 3.294 8.828.41Z"
        />
      </mask>
      <g mask={`url(#${id}_A)`}>
        <path fill="#fff" d="M29.547 6.46H4.966v21.334h24.581V6.46Z" />
      </g>
      <mask
        id={`${id}_B`}
        width={21}
        height={22}
        x={33}
        y={5}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m33.376 17.298 5.18-3.255-1.442-8.756 7.77 7.007 6.328-3-3.446 7.007 5.462 4.26-7.77-.507-1.723 6.762-3.164-8.258-7.195-1.26Z"
        />
      </mask>
      <g mask={`url(#${id}_B)`}>
        <path fill="#fff" d="M54.354 4.31H32.25v23.484h22.104V4.31Z" />
      </g>
      <mask
        id={`${id}_C`}
        width={23}
        height={22}
        x={60}
        y={5}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m60.67 18.255 4.527-4.407-1.7-8.561 5.653 5.63 9.042-3.187-2.534 5.874L83 17.522l-8.479 1.955-2.826 7.34-4.234-6.851-6.79-1.71Z"
        />
      </mask>
      <g mask={`url(#${id}_C)`}>
        <path fill="#fff" d="M84.126 4.31H59.545v23.484h24.581V4.31Z" />
      </g>
      <mask
        id={`${id}_D`}
        width={6}
        height={14}
        x={73}
        y={115}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M73.643 128.023s-.102 0-.158-.02c-.304-.068-.473-.342-.394-.606l3.828-11.942c.08-.264.395-.41.699-.342.304.068.473.342.394.606l-3.829 11.942c-.067.215-.293.362-.54.362Z"
        />
      </mask>
      <g mask={`url(#${id}_D)`}>
        <path fill="#080717" d="M79.228 114.067h-7.342V129h7.342v-14.933Z" />
      </g>
      <mask
        id={`${id}_E`}
        width={3}
        height={19}
        x={130}
        y={110}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M132.208 128.023c-.203 0-.372-.215-.406-.508l-1.655-16.037c-.034-.323.124-.626.349-.675.225-.049.439.176.473.499l1.655 16.037c.034.322-.124.625-.349.674h-.056"
        />
      </mask>
      <g mask={`url(#${id}_E)`}>
        <path fill="#080717" d="M133.773 109.777h-4.786V129h4.786v-19.223Z" />
      </g>
    </Box>
  );
};
