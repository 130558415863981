import { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@mantine/hooks';

import { useGetCurrentOrganisationTemplatesInfinite } from 'api/organisations';
import { DEBOUNCED_INPUT_DELAY_IN_MS, URLS } from 'consts';
import { getInfinityQueryNextPageParam } from 'helpers';
import { DataStreamTemplateType } from 'types';
import { SelectFromDataList } from 'components/shared/SelectFromDataList';

type Props = {
  onTemplateChange: ({
    id,
    name,
  }: {
    id: DataStreamTemplateType['templateId'];
    name: DataStreamTemplateType['templateName'];
  }) => void;

  templateId?: DataStreamTemplateType['templateId'];
  templateName?: DataStreamTemplateType['templateName'];
  selectRef?: MutableRefObject<HTMLInputElement | null>;
};

export const TemplateSelect = ({
  templateId,
  templateName,
  onTemplateChange,
  selectRef,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebouncedValue(
    searchValue,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  const {
    data: organisationTemplatesData,
    isError: isOrganisationTemplatesError,
    isLoading: isOrganisationTemplatesLoading,
    isFetchingNextPage: isFetchingNextOrganisationTemplatesPage,
    fetchNextPage: fetchOrganisationTemplatesNextPage,
  } = useGetCurrentOrganisationTemplatesInfinite(
    {
      queryParams: {
        name: debouncedSearchValue,
      },
    },
    {
      getNextPageParam: getInfinityQueryNextPageParam,
    },
  );

  return (
    <SelectFromDataList
      selectProps={{
        ref: selectRef,
        value: String(templateId),
        placeholder: t('dataStreams:editModal.templatePlaceholder'),
        error: null,
        data: [{ value: String(templateId), label: templateName || '' }],
      }}
      itemsData={organisationTemplatesData}
      isError={isOrganisationTemplatesError}
      isLoading={
        isOrganisationTemplatesLoading ||
        isFetchingNextOrganisationTemplatesPage
      }
      fetchNextPage={fetchOrganisationTemplatesNextPage}
      selectedId={templateId}
      onChange={({ id, name }) => {
        onTemplateChange({ id: Number(id), name });
      }}
      searchInputProps={{
        value: searchValue,
        onChange: e => {
          setSearchValue(e.target.value);
        },
        placeholder: t('dataStreams:editModal.templateSearchPlaceholder'),
      }}
      notFoundProps={{
        buttonText: t('dataStreams:editModal.templatesManaging'),
        link: URLS.templates,
      }}
    />
  );
};
