// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DocumentInvitationStatus {
  waiting = 'waiting',
  accepted = 'accepted',
  rejected = 'rejected',
  canceled = 'canceled',
}

export type DocumentInvitationResponseStatusType =
  typeof DocumentInvitationStatus;
