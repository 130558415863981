import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, DefaultProps, packSx, Text } from '@mantine/core';

import { WomanPointingTableIcon } from 'icons';
import { STATIC_URLS } from 'consts';

type Props = {
  sx?: DefaultProps['sx'];
};

export const NoAccess = ({ sx }: Props) => {
  const { t } = useTranslation('noAccess');

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          textAlign: 'center',
        },
        ...packSx(sx),
      ]}
    >
      <WomanPointingTableIcon
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
        })}
        color="primary"
      />
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
        })}
        weight={600}
      >
        {t('noAccess:dontHaveAccessToThisFeature')}
      </Text>
      <Text
        sx={theme => ({
          maxWidth: 360,
          marginBottom: theme.other.spacing(2),
        })}
        size="sm"
      >
        {t('noAccess:description')}
      </Text>
      <Anchor href={STATIC_URLS.contact} target="_blank">
        <Button variant="default">{t('noAccess:checkHowGetAccess')}</Button>
      </Anchor>
    </Box>
  );
};
