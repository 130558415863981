import { useTranslation } from 'react-i18next';

import { useSearchParams } from 'hooks';
import { URLS } from 'consts';
import { DataStreamListItemType } from 'types';
import { DataList, PercentageContainer } from 'components/shared';
import { CursorPaginationReactRouter } from 'components/Transactions';

import { DataStreamPanel } from '../DataStreamPanel';

type Props = {
  hasDataStreamCreateAccess: boolean;
  dataStreams: DataStreamListItemType[];
  isRefetchingDataStreams: boolean;
  prevPage: string | null | undefined;
  nextPage: string | null | undefined;
};

export const DataStreamList = ({
  hasDataStreamCreateAccess,
  dataStreams,
  isRefetchingDataStreams,
  prevPage,
  nextPage,
}: Props) => {
  const { t } = useTranslation('dataStreams');
  const { getSearchParam } = useSearchParams(['search', 'page']);

  const scrollTopTriggerKey = `${getSearchParam('page')}${getSearchParam(
    'search',
  )}`;

  const noDataProps = hasDataStreamCreateAccess
    ? {
        noDataHeader: t('dataStreams:templatesList.noDataHeader'),
        noDataButtonText: t('dataStreams:addTemplate'),
        noDataLink: URLS.dataStreamAdd,
      }
    : {};

  return (
    <PercentageContainer>
      <DataList
        data={dataStreams}
        isLoading={isRefetchingDataStreams}
        scrollTopTriggerKey={scrollTopTriggerKey}
        {...noDataProps}
      >
        {({ id, name, dataType, technicalUser, createdAt }) => (
          <DataStreamPanel
            id={id}
            name={name}
            dataType={dataType}
            technicalUserName={technicalUser.name}
            createdAt={createdAt}
          />
        )}
      </DataList>
      <CursorPaginationReactRouter
        prevPage={prevPage}
        nextPage={nextPage}
        isStickyToBottom
      />
    </PercentageContainer>
  );
};
