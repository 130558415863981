import { useMutation } from '@tanstack/react-query';

import { postAuthResetPasswordUpdate } from '../requests';
import { PostAuthResetPasswordUpdateRequestType } from '../types';

export const usePostAuthResetPasswordUpdate = () =>
  useMutation({
    mutationFn: ({
      pathParams,
      bodyParams,
    }: PostAuthResetPasswordUpdateRequestType) =>
      postAuthResetPasswordUpdate({
        pathParams,
        bodyParams,
      }),
  });
