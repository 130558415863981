import { ThemeComponentType } from '../types';

export const Pagination: ThemeComponentType = {
  defaultProps: {
    color: 'violet.7',
  },
  styles: theme => ({
    item: {
      borderColor: theme.colors.gray[1],
      '&[data-active="true"]': {
        color: theme.colors[theme.primaryColor][1],
      },
    },
  }),
};
