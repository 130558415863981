import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const UndoIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M20.998 14.08a8.615 8.615 0 0 0-16.27-.609M3.001 9.919l1.257 4.12 4.12-1.256"
      />
    </Box>
  );
};
