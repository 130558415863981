import { useTranslation } from 'react-i18next';

import { TemplateDescriptionField } from '../TemplateDescriptionField';
import { AutoNumerationInput } from './AutoNumerationInput';

type Props = {
  error: string | undefined;
  isTemplateNumericEnabled: boolean;
  onTemplateNumericEnabledChange: (enabled: boolean) => void;
  templateNumeric: string;
  onTemplateNumericChange: (templateNumeric: string) => void;
};

export const AutoNumeration = ({
  error,
  isTemplateNumericEnabled,
  onTemplateNumericEnabledChange,
  templateNumeric,
  onTemplateNumericChange,
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:autoNumeration')}
      description={t('templates:autoNumerationDescription')}
      error={error}
    >
      <AutoNumerationInput
        isTemplateNumericEnabled={isTemplateNumericEnabled}
        onTemplateNumericEnabledChange={onTemplateNumericEnabledChange}
        templateNumeric={templateNumeric}
        onTemplateNumericChange={onTemplateNumericChange}
      />
    </TemplateDescriptionField>
  );
};
