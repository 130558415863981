import { MantineTheme } from '@mantine/core';

export const global = (theme: MantineTheme) => ({
  'html, body, #root': {
    height: '100%',
  },
  body: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
  p: {
    margin: 0,
  },
  'ul, ol': {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  b: {
    fontWeight: 600,
  },
  pre: {
    margin: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
  },
});
