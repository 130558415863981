import { ComponentProps } from 'react';
import { packSx } from '@mantine/core';

import { DescriptionField } from 'components/shared';

type Props = ComponentProps<typeof DescriptionField>;

export const TemplateDescriptionField = ({
  sx,
  ...descriptionFieldProps
}: Props) => (
  <DescriptionField
    sx={[
      theme => ({
        columnGap: theme.other.spacing(6.25),
        paddingRight: theme.other.spacing(4.5),
      }),
      ...packSx(sx),
    ]}
    {...descriptionFieldProps}
  />
);
