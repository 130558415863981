import { apiAuthClient } from 'api/helpers';

import { DeleteDataTypeRequestType } from '../types';

export const deleteDataType = async ({
  pathParams: { organisationId, dataTypeName },
}: DeleteDataTypeRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-types/${dataTypeName}`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
