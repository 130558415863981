import { ComponentProps, ReactNode, useRef } from 'react';
import { Box, DefaultProps, LoadingOverlay, packSx } from '@mantine/core';

import { NotFound } from 'components/shared';

type NotFoundProps = ComponentProps<typeof NotFound>;

type Props = {
  children: ReactNode;
  hasData?: boolean;
  isLoading?: boolean;
  noDataHeader?: NotFoundProps['header'];
  noDataDescription?: NotFoundProps['description'];
  noDataButtonText?: NotFoundProps['buttonText'];
  onNoDataButtonClick?: NotFoundProps['onActionButtonClick'];
  noDataButtonColor?: NotFoundProps['actionButtonColor'];
  sx?: DefaultProps['sx'];
};
export const Table = ({
  children,
  hasData = true,
  isLoading = false,
  noDataHeader,
  noDataDescription,
  noDataButtonText,
  onNoDataButtonClick,
  noDataButtonColor,
  sx,
}: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);

  if (!hasData) {
    return (
      <NotFound
        sx={theme => ({
          minHeight: 300,
          backgroundColor: theme.white,
        })}
        header={noDataHeader}
        description={noDataDescription}
        actionButtonColor={noDataButtonColor}
        buttonText={noDataButtonText}
        onActionButtonClick={onNoDataButtonClick}
      />
    );
  }

  return (
    <Box sx={[{ overflowY: 'auto' }, ...packSx(sx)]}>
      <Box
        sx={theme => ({
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: theme.white,
          border: `1px solid ${theme.colors.gray[1]}`,
          whiteSpace: 'nowrap',
          '>thead': {
            tr: { position: 'relative', zIndex: 1 },
            th: {
              position: 'sticky',
              top: theme.other.spacing(-2),
              backgroundColor: theme.colors.violet[1],
              '&:first-of-type': {
                zIndex: 2,
              },
            },
          },
          '>thead > tr > th, >tbody > tr > td': {
            padding: theme.other.spacing(2, 1),
            borderBottom: `1px solid ${theme.colors.gray[1]}`,
            minWidth: 200,
            maxWidth: 400,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            '&:first-of-type': {
              position: 'sticky',
              left: -1,
              paddingLeft: theme.other.spacing(2),
              maxWidth: 200,
              borderRight: `1px solid ${theme.colors.gray[1]}`,
              [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                maxWidth: 260,
              },
              [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                maxWidth: 320,
              },
            },
            '&:last-of-type': {
              paddingRight: theme.other.spacing(2),
            },
          },
          '>thead > tr > th': {
            textAlign: 'left',
            fontSize: theme.fontSizes.md,
            fontWeight: 500,
          },
          '>tbody > tr': {
            backgroundColor: theme.white,
          },
          '>tbody > tr > td': {
            backgroundColor: 'inherit',
          },
          '.mantine-Text-root': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        })}
        component="table"
        ref={tableRef}
      >
        {children}
        {isLoading && (
          <tbody>
            <tr>
              <Box
                sx={{
                  height: 65,
                }}
                component="td"
                colSpan={1000}
              >
                <LoadingOverlay sx={{ inset: 1 }} visible overlayOpacity={1} />
              </Box>
            </tr>
          </tbody>
        )}
      </Box>
    </Box>
  );
};
