import {
  animationsTime,
  layoutBodyColor,
  layoutSizes,
  pageHeaderSpacingStyles,
} from '../consts';
import { getFontSizeStyles, spacing } from '../helpers';

export const other = {
  animationsTime,
  getFontSizeStyles,
  layoutBodyColor,
  layoutSizes,
  pageHeaderSpacingStyles,
  spacing,
};
