import { apiAuthClient } from 'api/helpers';

import { PostUserOtpDeleteResponseType } from '../types';

export const postUserOtpDelete = async () => {
  const url = '/api/v2/auth/user/otp/delete';

  const { data } = await apiAuthClient.post<PostUserOtpDeleteResponseType>(url);

  return data.data;
};
