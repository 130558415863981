import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@mantine/hooks';

import { useGetCurrentBoard } from 'api/boards';
import { useGetBoardTemplates } from 'api/templates';
import { GetBoardTemplatesRequestType } from 'api/templates/types';
import { API_NUMBER_LIMIT, DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import {
  getAreObjectsEqualByJsonRepresentation,
  getHasHateoasAccess,
  sortArrayOfObjByDate,
} from 'helpers';
import { useSearchParams } from 'hooks';
import { DateRangeValueType, SortStateType } from 'types';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { getIsInDateRange } from './helpers';
import { useLocalTemplates } from './hooks';
import { BoardTemplateLocalListItemType } from './types';
import { Header } from './Header';
import { TemplateList } from './TemplateList';
import { TemplateMassActions } from './TemplateMassActions';

export const Templates = () => {
  const { t } = useTranslation('templates');

  const { localTemplates } = useLocalTemplates();

  const [selectedTemplates, setSelectedTemplates] = useState<
    BoardTemplateLocalListItemType[]
  >([]);

  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const { getSearchParam, deleteSearchParam, deleteAllSearchParams } =
    useSearchParams(['page', 'search']);

  const page = getSearchParam('page');
  const name = getSearchParam('search');

  const [sort, setSort] = useState<
    SortStateType<
      Exclude<GetBoardTemplatesRequestType['queryParams']['sortBy'], undefined>
    >
  >({
    sortBy: 'updated_at',
    sortDir: 'desc',
  });
  const [idFilter, setIdFilter] = useState<number | null>(null);
  const [debouncedIdFilter] = useDebouncedValue(
    idFilter,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );
  const [updatedAtRange, setUpdatedAtRange] = useState<
    [DateRangeValueType] | [DateRangeValueType, DateRangeValueType]
  >([null]);
  const [debouncedUpdatedAtRange] = useDebouncedValue(
    updatedAtRange,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const isWaitingForDebouncedQueryParams =
    idFilter !== debouncedIdFilter ||
    !getAreObjectsEqualByJsonRepresentation(
      updatedAtRange,
      debouncedUpdatedAtRange,
    );

  const {
    data: boardTemplatesData,
    isError: isBoardTemplatesError,
    isInitialLoading: isBoardTemplatesInitialLoading,
    isRefetching: isRefetchingBoardTemplates,
    refetch: refetchGetBoardTemplates,
  } = useGetBoardTemplates(
    {
      queryParams: {
        page,
        name,
        id:
          debouncedIdFilter !== null && debouncedIdFilter > API_NUMBER_LIMIT
            ? API_NUMBER_LIMIT
            : debouncedIdFilter,
        from: debouncedUpdatedAtRange[0],
        to:
          debouncedUpdatedAtRange.length === 1
            ? debouncedUpdatedAtRange[0]
            : debouncedUpdatedAtRange[1],
        ...sort,
      },
    },
    {
      enabled: !isCurrentBoardLoading && hasBoardTemplateFeature,
      keepPreviousData: true,
    },
  );

  const hasTemplatesCreateAccess = getHasHateoasAccess(
    'create',
    boardTemplatesData?.__links,
  );

  const hasUpdatedAtRangeSet = updatedAtRange.some(Boolean);

  const hasActiveFilters =
    Boolean(name) ||
    Boolean(page) ||
    Boolean(debouncedIdFilter) ||
    hasUpdatedAtRangeSet;

  useEffect(() => {
    if (boardTemplatesData?.data.length === 0 && page !== null) {
      deleteSearchParam('page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardTemplatesData?.data, page]);

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={boardTemplatesData}
        isError={isBoardTemplatesError || isCurrentBoardError}
        isLoading={isBoardTemplatesInitialLoading || isCurrentBoardLoading}
        isNoAccess={!hasBoardTemplateFeature && !isCurrentBoardLoading}
        errorMargin="xl"
      >
        {({ data: boardTemplates, meta }) => {
          const templatesPerPage = [
            ...(meta.currentPage === 1
              ? sortArrayOfObjByDate(
                  localTemplates.filter(({ type, updatedAt }) => {
                    const isInDateRange = hasUpdatedAtRangeSet
                      ? getIsInDateRange(updatedAt, updatedAtRange)
                      : true;
                    const isMatchesName =
                      type === '' ||
                      type.toLowerCase().includes((name || '').toLowerCase());

                    return isInDateRange && isMatchesName;
                  }),
                  'updatedAt',
                  sort.sortDir,
                )
              : []),
            ...boardTemplates,
          ];

          return (
            <>
              <Breadcrumbs
                items={[{ name: t('templates:templates'), url: '#' }]}
              />
              <Header hasTemplatesCreateAccess={hasTemplatesCreateAccess} />
              <TemplateList
                templates={templatesPerPage}
                meta={meta}
                isRefetching={isRefetchingBoardTemplates}
                hasActiveFilters={
                  hasActiveFilters || isWaitingForDebouncedQueryParams
                }
                clearFilters={() => {
                  deleteAllSearchParams();
                  setIdFilter(null);
                  setUpdatedAtRange([null]);
                }}
                selectedTemplates={selectedTemplates}
                setSelectedTemplates={setSelectedTemplates}
                sort={sort}
                setSort={setSort}
                idFilter={idFilter}
                setIdFilter={setIdFilter}
                updatedAtRange={updatedAtRange}
                setUpdatedAtRange={setUpdatedAtRange}
                refetchGetBoardTemplates={refetchGetBoardTemplates}
              />
              {selectedTemplates.length > 0 && (
                <TemplateMassActions
                  selectedTemplates={selectedTemplates}
                  setSelectedTemplates={setSelectedTemplates}
                  refetchGetBoardTemplates={refetchGetBoardTemplates}
                />
              )}
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
