import { apiAuthClient } from 'api/helpers';

import { DeleteDataStreamRequestType } from '../types';

export const deleteDataStream = async ({
  pathParams: { organisationId, dataStreamId },
}: DeleteDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
