import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Checkbox, Tooltip } from '@mantine/core';

import { BRANDING_SOURCE } from 'consts';
import { useFormatUserDate, useGetV1Url } from 'hooks';
import { LetterheadIcon, NoLetterheadIcon, OpenIcon } from 'icons';
import { Status } from 'components/shared';
import { TemplateStatuses } from 'components/Templates';

import { BoardTemplateLocalListItemType } from '../../types';
import { TemplateDetailsRow } from './TemplateDetailsRow';

type Props = {
  isChecked: boolean;
  isLocalTemplate: boolean;
  isBase: boolean;
  isOneWay: boolean;
  isOffer: boolean;
  hasDetailsAccess: boolean;
  letterheadSource:
    | (typeof BRANDING_SOURCE)[keyof typeof BRANDING_SOURCE]
    | null;
  id: BoardTemplateLocalListItemType['id'];
  name: string;
  updatedAt: BoardTemplateLocalListItemType['updatedAt'];
  onCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  refetchGetBoardTemplates: () => void;
};

export const TemplateRow = ({
  isChecked,
  isLocalTemplate,
  isBase,
  isOneWay,
  isOffer,
  hasDetailsAccess,
  letterheadSource,
  id,
  name,
  updatedAt,
  onCheckboxChange,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation('templates');

  const [isExpanded, setIsExpanded] = useState(false);

  const formatUserDate = useFormatUserDate();
  const getV1Url = useGetV1Url();

  return (
    <>
      <Box
        sx={theme => ({
          '&&': {
            backgroundColor: isChecked ? theme.colors.blue[1] : undefined,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.colors.blue[1],
            },
          },
        })}
        component="tr"
        onClick={() => {
          setIsExpanded(expanded => !expanded);
        }}
      >
        <td>
          <Checkbox
            sx={{ marginBottom: 0 }}
            checked={isChecked}
            onChange={onCheckboxChange}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        </td>
        <td>
          <Box sx={{ display: 'flex', alignItems: 'center' }} component="span">
            {letterheadSource === 'template' && (
              <Tooltip label={t('templates:attachedLetterhead')}>
                <span>
                  <LetterheadIcon
                    sx={theme => ({
                      marginRight: theme.other.spacing(0.5),
                    })}
                    color="primary"
                  />
                </span>
              </Tooltip>
            )}
            {letterheadSource === 'disabled' && (
              <Tooltip label={t('templates:noLetterhead')}>
                <span>
                  <NoLetterheadIcon
                    sx={theme => ({
                      marginRight: theme.other.spacing(0.5),
                    })}
                    color="orange"
                  />
                </span>
              </Tooltip>
            )}
            {hasDetailsAccess ? (
              <Anchor
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                  color: 'inherit',
                  svg: {
                    visibility: 'hidden',
                  },
                  ':hover': {
                    svg: {
                      visibility: 'visible',
                    },
                  },
                }}
                href={
                  isLocalTemplate
                    ? getV1Url('templatesNew', {
                        localTemplateId: id as string,
                      })
                    : getV1Url('template', {
                        templateId: String(id),
                      })
                }
                target="_blank"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  component="span"
                >
                  {name}
                </Box>
                <OpenIcon
                  sx={theme => ({
                    flexShrink: 0,
                    marginTop: -2,
                    marginLeft: theme.other.spacing(0.25),
                  })}
                />
              </Anchor>
            ) : (
              name
            )}
          </Box>
          {isLocalTemplate && (
            <Status
              sx={theme => ({
                marginTop: theme.other.spacing(0.5),
              })}
              label={t('templates:templateTypes.draft')}
              dotSize={0}
            />
          )}
        </td>
        <td>{isLocalTemplate ? '...' : id}</td>
        <td>{formatUserDate(updatedAt)}</td>
        <td>
          <TemplateStatuses
            canEditByNegotiator={false}
            isBase={isBase}
            isOffer={isOffer}
            isOneWay={isOneWay}
          />
        </td>
      </Box>
      <TemplateDetailsRow
        templateId={id}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        refetchGetBoardTemplates={refetchGetBoardTemplates}
      />
    </>
  );
};
