import { Link } from 'react-router-dom';

import { URLS } from 'consts';
import { useGetV1Url } from 'hooks';

import { HrefKeyType, ToKeyType } from 'components/Layout/Navbar/types';

export const useGetLinkRestProps = () => {
  const getV1Url = useGetV1Url();

  return (hrefKey?: HrefKeyType, toKey?: ToKeyType) => {
    switch (true) {
      case hrefKey !== undefined:
        return {
          component: 'a' as any,
          href: getV1Url(hrefKey as HrefKeyType),
        };
      case toKey !== undefined:
        return {
          component: Link as any,
          to: URLS[toKey as ToKeyType],
        };
      default:
        return {};
    }
  };
};
