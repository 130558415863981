import { apiAuthClient } from 'api/helpers';

import {
  PutCustomizationsEmailResponseType,
  PutCustomizationsEmailRequestType,
} from '../types';

export const putCustomizationsEmail = async ({
  pathParams: { organisationId },
  bodyParams,
}: PutCustomizationsEmailRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/customizations/email`;

  const { data } = await apiAuthClient.put<PutCustomizationsEmailResponseType>(
    url,
    bodyParams,
  );

  return data;
};
