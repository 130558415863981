import { apiAuthClient } from 'api/helpers';

import { DeleteCustomizationsDocumentRequestType } from '../types';

export const deleteCustomizationsDocument = async ({
  pathParams: { organisationId, boardId, templateId },
}: DeleteCustomizationsDocumentRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates/${templateId}/customizations/document`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
