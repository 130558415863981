import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionEntriesResponseType,
  GetTransactionEntriesRequestType,
} from '../types';

export const getTransactionEntries = async ({
  pathParams: { organisationId, dataStream, transactionId },
}: GetTransactionEntriesRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStream}/transactions/${transactionId}/entries`;

  const { data } = await apiAuthClient.get<GetTransactionEntriesResponseType>(
    url,
  );

  return data.data;
};
