import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text, Title } from '@mantine/core';

import { useGetCurrentOrganisation } from 'api/organisations';
import { useGetTemplate } from 'api/templates';
import { URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { Branding } from './Branding';
import { LetterheadManagement } from './LetterheadManagement';

export const TemplateBranding = () => {
  const { t } = useTranslation(['common', 'templates']);

  const { templateId } = useParams() as {
    templateId: string;
  };

  /* need for LetterheadManagement component */
  const { isError: isOrganisationError, isLoading: isOrganisationLoading } =
    useGetCurrentOrganisation();

  const {
    data: template,
    dataUpdatedAt: templateUpdatedAt,
    isError: isTemplateError,
    isInitialLoading: isTemplateInitialLoading,
    isRefetching: isTemplateRefetching,
  } = useGetTemplate({
    pathParams: {
      templateId,
    },
  });

  const hasBrandingFeature = getHasHateoasAccess('branding', template?.__links);

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={template}
        isNoAccess={!hasBrandingFeature}
        isError={isTemplateError || isOrganisationError}
        isLoading={isTemplateInitialLoading || isOrganisationLoading}
        errorMargin="xl"
      >
        {({ name, documentBranding, board }) => {
          const {
            headedPaperUrl,
            margin,
            source,
            disabledBranding,
            organisationBrandingExists,
          } = documentBranding;

          return (
            <>
              <Breadcrumbs
                sx={theme => ({
                  marginBottom: theme.other.spacing(4.5),
                })}
                items={[
                  { name: t('templates:templates'), url: URLS.templates },
                  {
                    name: headedPaperUrl
                      ? t('templates:editLetterhead')
                      : t('templates:attachLetterhead'),
                    url: '#',
                  },
                  {
                    name,
                    url: '#',
                  },
                ]}
              />
              <LetterheadManagement
                sx={theme => ({
                  marginBottom: theme.other.spacing(4),
                })}
                isTemplateBrandingDisabled={disabledBranding}
                isOrganisationBrandingEnabled={organisationBrandingExists}
                isRefetching={isTemplateRefetching}
              />
              {!disabledBranding && (
                <>
                  <Title order={4}>{name}</Title>
                  <Text
                    sx={theme => ({
                      marginBottom: theme.other.spacing(4),
                    })}
                    size="md"
                  >
                    <Trans
                      i18nKey="templates:brandingDescription"
                      components={{ b: <b /> }}
                    />
                  </Text>
                  <Branding
                    key={templateUpdatedAt}
                    boardId={board.id}
                    headedPaperUrl={headedPaperUrl}
                    margin={margin}
                    source={source}
                    isRefetching={isTemplateRefetching}
                  />
                </>
              )}
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
