import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Select, TextInput, Text } from '@mantine/core';

import { DescriptionField } from 'components/shared';
import { BoardType } from 'types';
import { OrganisationType } from 'types/organisations/OrganisationsType';
import { useGetIsUserAdminInCurrentOrganisation } from 'hooks';
import { QuestionIcon } from 'icons';
import { AttachmentStateType } from '../types';

type Props = {
  showDuplicateLetterhead: boolean;
  includeBranding: boolean;
  setIncludeBranding: Dispatch<SetStateAction<boolean>>;
  attachmentsState: AttachmentStateType;
  newTemplateName: string;
  templateNameError: string;
  setAttachmentsState: Dispatch<SetStateAction<AttachmentStateType>>;
  setNewTemplateName: Dispatch<SetStateAction<string>>;
  setTemplateNameError: Dispatch<SetStateAction<string>>;
  organisationId: string | null;
  setOrganisationId: Dispatch<SetStateAction<string | null>>;
  boardId: string | null;
  setBoardId: Dispatch<SetStateAction<string | null>>;
  organisations: OrganisationType[];
  boards: BoardType[];
};

export const DuplicateForm = ({
  showDuplicateLetterhead,
  includeBranding,
  setIncludeBranding,
  attachmentsState,
  newTemplateName,
  templateNameError,
  setAttachmentsState,
  setNewTemplateName,
  setTemplateNameError,
  organisationId,
  setOrganisationId,
  boardId,
  setBoardId,
  organisations,
  boards,
}: Props) => {
  const { t } = useTranslation(['organisations', 'templates']);
  const isCurrentUserAdminInCurrentOrganisation =
    useGetIsUserAdminInCurrentOrganisation();

  const hasOneOrganisation = organisations ? organisations.length === 1 : false;
  const hasOneBoard = boards.length === 1;

  const hasZeroAdminOrganisations = organisations
    ? organisations.length === 0
    : false;

  const showOrganisationAndBoardSelectors =
    isCurrentUserAdminInCurrentOrganisation && !hasZeroAdminOrganisations;

  const [duplicateWithAttachments, setDuplicateWithAttachments] =
    useState(false);

  return (
    <form>
      {showOrganisationAndBoardSelectors && (
        <>
          <DescriptionField
            label={t('templates:duplicateModal.organisation')}
            description={t('templates:duplicateModal.organisationDescription')}
            sx={theme => ({
              padding: 0,
              marginBottom: theme.other.spacing(2),
            })}
            withoutBorder
          >
            {hasOneOrganisation ? (
              <TextInput
                description={
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: theme.other.spacing(0.5),
                    })}
                  >
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: theme.other.spacing(0.5),
                      })}
                    >
                      <QuestionIcon />
                    </Box>
                    <Text
                      sx={theme => ({
                        color: theme.colors.blue[0],
                      })}
                      weight={400}
                      size={13}
                    >
                      {t('templates:duplicateModal.oneOrganisation')}
                    </Text>
                  </Box>
                }
                value={organisations[0].name}
                inputWrapperOrder={['label', 'input', 'description']}
                readOnly
                data-testid="DuplicateTemplate_DuplicateModal_OneOrganisation"
              />
            ) : (
              <Select
                data={
                  organisations
                    ? organisations.map((organisation: any) => ({
                        value: String(organisation.id),
                        label:
                          organisation.name ||
                          t('organisations:defaultOrganisationName'),
                      }))
                    : []
                }
                value={organisationId}
                onChange={setOrganisationId}
                placeholder={t(
                  'templates:duplicateModal.organisationPlaceholder',
                )}
                data-testid="DuplicateTemplate_DuplicateModal_SelectOrganisation"
              />
            )}
          </DescriptionField>
          <DescriptionField
            label={t('templates:duplicateModal.board')}
            description={t('templates:duplicateModal.boardDescription')}
            sx={theme => ({
              padding: 0,
              paddingBottom: theme.other.spacing(2),
            })}
          >
            {hasOneBoard ? (
              <TextInput
                description={
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: theme.other.spacing(0.5),
                    })}
                  >
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: theme.other.spacing(0.5),
                      })}
                    >
                      <QuestionIcon />
                    </Box>
                    <Text
                      sx={theme => ({
                        color: theme.colors.blue[0],
                      })}
                      weight={400}
                      size={13}
                    >
                      {t('templates:duplicateModal.oneBoard')}
                    </Text>
                  </Box>
                }
                value={boards[0].name}
                inputWrapperOrder={['label', 'input', 'description']}
                readOnly
                data-testid="DuplicateTemplate_DuplicateModal_OneBoard"
              />
            ) : (
              <Select
                data={boards.map(board => ({
                  value: String(board.id),
                  label: board.name,
                }))}
                value={boardId}
                onChange={setBoardId}
                placeholder={t('templates:duplicateModal.boardPlaceholder')}
                data-testid="DuplicateTemplate_DuplicateModal_SelectBoard"
              />
            )}
          </DescriptionField>
        </>
      )}
      <DescriptionField
        label={t('templates:duplicateModal.moveWithTheName')}
        description={t('templates:duplicateModal.moveWithTheNameDescription')}
        sx={theme => ({
          paddingTop: theme.other.spacing(2),
          paddingBottom: theme.other.spacing(2),
        })}
        withoutBorder={!showDuplicateLetterhead && !attachmentsState}
      >
        <TextInput
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          placeholder={t('templates:templateName')}
          value={newTemplateName}
          onChange={e => {
            setTemplateNameError('');
            setNewTemplateName(e.target.value);
          }}
          error={templateNameError}
          data-testid="DuplicateTemplate_DuplicateModal_TemplateNameTextField"
        />
      </DescriptionField>
      {showDuplicateLetterhead && (
        <DescriptionField
          label={t('templates:duplicateWithLetterhead')}
          description={t('templates:duplicateWithLetterheadDescription')}
          withoutBorder={!attachmentsState}
        >
          <Checkbox
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
            checked={includeBranding}
            onChange={e => {
              setIncludeBranding(e.target.checked);
            }}
            data-testid="DuplicateTemplate_DuplicateModal_LetterheadCheckbox"
          />
        </DescriptionField>
      )}
      {Boolean(attachmentsState) && (
        <>
          <DescriptionField
            label={t('templates:duplicateModal.duplicateWithAttachments')}
            description={t(
              'templates:duplicateModal.duplicateWithAttachmentsDescription',
            )}
            sx={theme => ({
              paddingTop: theme.other.spacing(2),
              paddingBottom: theme.other.spacing(2),
            })}
            withoutBorder
          >
            <Checkbox
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              checked={duplicateWithAttachments}
              onChange={e => {
                setAttachmentsState(state =>
                  Object.keys(state as NonNullable<typeof state>).reduce(
                    (accumulator, name) => ({
                      ...accumulator,
                      [name]: e.target.checked,
                    }),
                    {},
                  ),
                );
                setDuplicateWithAttachments(prevState => !prevState);
              }}
              data-testid="DuplicateTemplate_DuplicateModal_AllAttachmentsCheckbox"
            />
          </DescriptionField>
          {duplicateWithAttachments && (
            <DescriptionField
              label={t('templates:duplicateModal.selectAttachments')}
              description={null}
              sx={theme => ({
                paddingTop: theme.other.spacing(2),
                paddingBottom: theme.other.spacing(2),
              })}
              withoutBorder
            >
              <Box>
                {Object.entries(
                  attachmentsState as NonNullable<typeof attachmentsState>,
                ).map(([name, checked], index) => (
                  <Checkbox
                    sx={theme => ({
                      marginBottom: theme.other.spacing(2),
                    })}
                    key={name}
                    label={name}
                    checked={checked}
                    onChange={e => {
                      setAttachmentsState(state => ({
                        ...state,
                        [name]: e.target.checked,
                      }));
                    }}
                    data-testid={`DuplicateTemplate_DuplicateModal_AttachmentCheckbox${index}`}
                  />
                ))}
              </Box>
            </DescriptionField>
          )}
        </>
      )}
    </form>
  );
};
