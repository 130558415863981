import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Checkbox } from '@mantine/core';

import { FormValuesType } from './types';

type Props = {
  formRef: RefObject<HTMLFormElement>;
  defaultValues: FormValuesType;
  onSubmit: (formValues: FormValuesType) => void;
};

export const AgreementsForm = ({ formRef, defaultValues, onSubmit }: Props) => {
  const { t } = useTranslation('user');

  const { handleSubmit, register, setValue, watch } = useForm<FormValuesType>({
    defaultValues,
  });

  const phoneFields = ['phoneCallMarketing', 'phoneMessageMarketing'] as const;

  const marketingValues = watch(phoneFields);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Checkbox
        {...register('newsletter')}
        label={t('user:agreeToNewsletter')}
      />
      <Checkbox
        label={t('user:agreeToMarketingContact')}
        checked={marketingValues.every(Boolean)}
        onChange={e => {
          phoneFields.forEach(field => {
            setValue(field, e.target.checked);
          });
        }}
      />
      <Box
        sx={theme => ({
          marginLeft: theme.other.spacing(2),
        })}
      >
        <Checkbox
          {...register('phoneCallMarketing')}
          label={t('user:agreeToPhoneCallContact')}
          description={t('user:agreeToPhoneCallContactDescription')}
        />
        <Checkbox
          {...register('phoneMessageMarketing')}
          label={t('user:agreeToSmsContact')}
          description={t('user:agreeToSmsContactDescription')}
        />
      </Box>
    </form>
  );
};
