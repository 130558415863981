import { UnstyledButton } from '@mantine/core';

import { ActiveMarginType } from '../../types';

type Props = {
  isActive: boolean;
  type: Exclude<ActiveMarginType, 'none'>;
  marginInPx: number;
  onClick: () => void;
};

export const MarginItem = ({ isActive, type, marginInPx, onClick }: Props) => (
  <UnstyledButton
    sx={theme => ({
      transition: `${theme.other.animationsTime} ease`,
      position: 'absolute',
      width: type === 'top' || type === 'bottom' ? '100%' : marginInPx,
      height: type === 'right' || type === 'left' ? '100%' : marginInPx,
      top: type === 'top' ? 0 : undefined,
      right: type === 'right' ? 0 : undefined,
      bottom: type === 'bottom' ? 0 : undefined,
      left: type === 'left' ? 0 : undefined,
      zIndex: isActive ? 1 : 0,
      backgroundColor: isActive
        ? theme.colors.orange[0]
        : theme.colors.orange[2],
      '&:hover': {
        backgroundColor: theme.colors.orange[0],
        zIndex: 2,
        cursor: 'pointer',
      },
    })}
    disabled={marginInPx === 0}
    onClick={onClick}
  />
);
