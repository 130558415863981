import {
  EditorContextProvider,
  ErrorsContextProvider,
  ForceUpdateContextProvider,
} from 'legacy/TemplateCreatorEditor/context';

export const Providers = ({ children, editor, errors }) => (
  <EditorContextProvider editor={editor}>
    <ErrorsContextProvider errors={errors}>
      <ForceUpdateContextProvider>{children}</ForceUpdateContextProvider>
    </ErrorsContextProvider>
  </EditorContextProvider>
);
