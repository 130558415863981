import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Anchor, Box, Button, Text } from '@mantine/core';
import {
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { ChevronLeftIcon } from 'icons';
import { useIsLgBreakpoint } from 'hooks';
import { Agreements, PrivacyDataModal } from 'components/shared';

import {
  FormFieldErrorsType,
  RegistrationStep3ValuesType,
  RegistrationValuesType,
} from '../types';
import { SuccessView } from '../SuccessView';

type Props = {
  watch: UseFormWatch<RegistrationValuesType>;
  errors: FormFieldErrorsType;
  clearErrors: UseFormClearErrors<RegistrationValuesType>;
  setValue: UseFormSetValue<RegistrationValuesType>;
  isCreatingAccount: boolean;
  backButtonAction: () => void;
  isSuccessViewAvailable: boolean;
  serverErrorMessage?: string;
};

export const Step3 = ({
  watch,
  errors,
  clearErrors,
  setValue,
  isCreatingAccount,
  backButtonAction,
  isSuccessViewAvailable,
  serverErrorMessage = '',
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const isLgBreakpoint = useIsLgBreakpoint();

  const [isPrivacyDataModalOpen, setIsPrivacyDataModalOpen] = useState(false);

  const openPrivacyDataModal = () => {
    setIsPrivacyDataModalOpen(true);
  };

  const closePrivacyDataModal = () => {
    setIsPrivacyDataModalOpen(false);
  };

  return isSuccessViewAvailable ? (
    <SuccessView email={watch('email')} />
  ) : (
    <>
      <div>
        <Text
          size="md"
          color="red"
          sx={theme => ({
            minHeight: isLgBreakpoint ? 65 : undefined,
            marginBottom: !isLgBreakpoint ? theme.other.spacing(1) : undefined,
          })}
        >
          {serverErrorMessage}
        </Text>
        {!isLgBreakpoint && (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.other.spacing(3),
            })}
          >
            <ActionIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
                marginLeft: theme.other.spacing(-1),
              })}
              onClick={backButtonAction}
            >
              <ChevronLeftIcon color="dark" />
            </ActionIcon>
            <Text>
              {t('user:stepOfSteps', {
                step: 3,
                maxSteps: 3,
              })}
            </Text>
          </Box>
        )}
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          size="xl"
          weight={600}
        >
          {t('user:contactUs')}
        </Text>
        <Agreements
          sx={theme => ({ marginBottom: theme.other.spacing(3) })}
          agreements={{
            terms: watch('terms'),
            newsletter: watch('newsletter'),
            phoneCallMarketing: watch('phoneCallMarketing'),
            phoneMessageMarketing: watch('phoneMessageMarketing'),
          }}
          onAgreementsChange={(agreements, sourceChanged) => {
            Object.entries(agreements).forEach(([field, checked]) => {
              setValue(field as keyof RegistrationStep3ValuesType, checked);

              if (sourceChanged === 'all') {
                clearErrors(field as keyof RegistrationStep3ValuesType);
              }
            });

            if (sourceChanged !== 'all') {
              clearErrors(sourceChanged as keyof RegistrationStep3ValuesType);
            }
          }}
          errors={{
            terms: errors?.terms?.message,
            newsletter: errors?.newsletter?.message,
            phoneCallMarketing: errors?.phoneCallMarketing?.message,
            phoneMessageMarketing: errors?.phoneMessageMarketing?.message,
          }}
        />
        <Text
          size="xs"
          sx={theme => ({ marginBottom: theme.other.spacing(0.5) })}
        >
          {t('user:personalDataInfo')}{' '}
          <Anchor
            component="button"
            sx={theme => ({ fontSize: theme.fontSizes.xs })}
            onClick={openPrivacyDataModal}
          >
            {t('common:more')}
          </Anchor>
        </Text>
        <Button
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
            fontWeight: 600,
          })}
          type="submit"
          fullWidth
          size="lg"
          loading={isCreatingAccount}
        >
          {t('user:createAccount')}
        </Button>
      </div>
      <PrivacyDataModal
        isOpen={isPrivacyDataModalOpen}
        onClose={closePrivacyDataModal}
        onSubmit={closePrivacyDataModal}
      />
    </>
  );
};
