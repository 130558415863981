import { useParams } from 'react-router-dom';

import { useGetTransactionDataStream } from 'api/transactions';
import { getHasHateoasAccess } from 'helpers';
import { DataWrapper } from 'components/shared';

import { DataStreamTemplates } from './DataStreamTemplates';
import { DataStreamAddTemplate } from './DataStreamAddTemplate';

export const DataStreamPanel = () => {
  const { transactionId, transactionDataStream: transactionDataStreamParam } =
    useParams() as {
      transactionDataStream: string;
      transactionId: string;
    };

  const {
    data: transactionDataStream,
    isError: isTransactionDataStreamError,
    isLoading: isTransactionDataStreamLoading,
  } = useGetTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStreamParam,
    },
  });

  return (
    <DataWrapper
      data={transactionDataStream}
      isError={isTransactionDataStreamError}
      isLoading={isTransactionDataStreamLoading}
    >
      {({ templates, __links }) => {
        const hasDataStreamPatchAccess = getHasHateoasAccess('update', __links);

        return (
          <div>
            {hasDataStreamPatchAccess && (
              <DataStreamAddTemplate
                sx={theme => ({
                  marginBottom: theme.other.spacing(2),
                })}
                templates={templates}
              />
            )}
            <DataStreamTemplates
              templates={templates}
              hasDataStreamPatchAccess={hasDataStreamPatchAccess}
            />
          </div>
        );
      }}
    </DataWrapper>
  );
};
