const STATIC_WEBSITE_URL = 'https://pergam.in';
const SERVICE_DESK_STATIC_URL =
  'https://pergamin.atlassian.net/servicedesk/customer/portal/5/article';

export const STATIC_URLS = {
  contact: `${STATIC_WEBSITE_URL}/konsultacje`,
  help: `${STATIC_WEBSITE_URL}/help-center`,
  privacyPolicy: `${STATIC_WEBSITE_URL}/polityka-prywatnosci-aplikacji`,
  signTypes: `${SERVICE_DESK_STATIC_URL}/237043937`,
  staticWebsiteUrl: STATIC_WEBSITE_URL,
  templateCreator: `${SERVICE_DESK_STATIC_URL}/237011471`,
  termsAndConditions: `${STATIC_WEBSITE_URL}/regulamin`,
  transactionApi: `${STATIC_WEBSITE_URL}/transaction-api`,
} as const;
