import { apiAuthClient } from 'api/helpers';

import { PostAuthLoginRequestType, PostAuthLoginResponseType } from '../types';

export const postAuthLogin = async ({
  bodyParams,
}: PostAuthLoginRequestType) => {
  const url = '/api/v2/auth/login';

  const { data } = await apiAuthClient.post<PostAuthLoginResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
