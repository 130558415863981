import { PDFDocument } from 'pdf-lib';

export const getPdfValidationData = async (
  file: string | Uint8Array | ArrayBuffer,
) => {
  try {
    const pdfDocument = await PDFDocument.load(file, {
      ignoreEncryption: true,
    });

    return {
      isEncrypted: pdfDocument.isEncrypted,
      pagesLength: pdfDocument.getPageCount(),
    };
  } catch {
    return {
      isEncrypted: true,
      pagesLength: 0,
    };
  }
};
