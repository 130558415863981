import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const TabsIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M7.429 13.143v-8C7.429 4.512 7.94 4 8.57 4H12l2.286 2.286h4.571c.631 0 1.143.511 1.143 1.143v5.714c0 .631-.512 1.143-1.143 1.143H8.571M7.43 13.143c0 .631.511 1.143 1.142 1.143M7.43 13.143V9.714H5.143c-.631 0-1.143.512-1.143 1.143v8C4 19.488 4.512 20 5.143 20h10.286c.63 0 1.142-.512 1.142-1.143v-4.571h-8"
      />
    </Box>
  );
};
