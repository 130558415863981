import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { Anchor, Tabs } from '@mantine/core';

import { DYNAMIC_URLS } from 'consts';
import {
  useGetCurrentOrganisationId,
  useGetOrganisationFeatureAccess,
  useGetV1Url,
} from 'hooks';
import { NoAccessModal } from 'components/shared';
import { MailCustomizationNoAccessModal } from 'components/Organisations/MailCustomizationNoAccessModal';

const TABS = {
  branding: 'branding',
  details: 'details',
  mailCustomization: 'mailCustomization',
};

export const OrganisationTabs = () => {
  const { t } = useTranslation(['common', 'organisations']);

  const organisationId = useGetCurrentOrganisationId();
  const getV1Url = useGetV1Url();

  const [
    isMailCustomizationNoAccessModalOpen,
    setIsMailCustomizationNoAccessModalOpen,
  ] = useState(false);
  const [isNoAccessModalOpen, setIsNoAccessModalOpen] = useState(false);

  const { data: hasEmailCustomizationFeature } =
    useGetOrganisationFeatureAccess('emailCustomization');
  const { data: hasBrandingFeature } =
    useGetOrganisationFeatureAccess('documentBranding');

  const isOnBrandingRoute = Boolean(
    useMatch(
      DYNAMIC_URLS.organisationBranding({
        organisationId: ':organisationId',
      }),
    ),
  );
  const isOnMailCustomizationRoute = Boolean(
    useMatch(
      DYNAMIC_URLS.organisationMailCustomization({
        organisationId: ':organisationId',
      }),
    ),
  );

  const getCurrentTab = () => {
    if (isOnBrandingRoute) {
      return TABS.branding;
    }
    if (isOnMailCustomizationRoute) {
      return TABS.mailCustomization;
    }

    return undefined;
  };

  const anchorSx = {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  };

  return (
    <>
      <Tabs
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        value={getCurrentTab()}
      >
        <Tabs.List>
          <Anchor sx={anchorSx} href={getV1Url('organisations')}>
            <Tabs.Tab value={TABS.details}>
              {t('organisations:details')}
            </Tabs.Tab>
          </Anchor>
          <Anchor
            sx={anchorSx}
            component={Link}
            to={DYNAMIC_URLS.organisationMailCustomization({
              organisationId: String(organisationId),
            })}
          >
            <Tabs.Tab
              value={TABS.mailCustomization}
              onClick={e => {
                if (!hasEmailCustomizationFeature) {
                  e.preventDefault();
                  setIsMailCustomizationNoAccessModalOpen(true);
                }
              }}
            >
              {t('organisations:mailCustomization.mailCustomization')}
            </Tabs.Tab>
          </Anchor>
          <Anchor
            sx={anchorSx}
            component={Link}
            to={DYNAMIC_URLS.organisationBranding({
              organisationId: String(organisationId),
            })}
          >
            <Tabs.Tab
              value={TABS.branding}
              onClick={e => {
                if (!hasBrandingFeature) {
                  e.preventDefault();
                  setIsNoAccessModalOpen(true);
                }
              }}
            >
              {t('organisations:branding')}
            </Tabs.Tab>
          </Anchor>
        </Tabs.List>
      </Tabs>
      <MailCustomizationNoAccessModal
        isOpen={isMailCustomizationNoAccessModalOpen}
        onClose={() => {
          setIsMailCustomizationNoAccessModalOpen(false);
        }}
      />
      <NoAccessModal
        isOpen={isNoAccessModalOpen}
        onClose={() => {
          setIsNoAccessModalOpen(false);
        }}
      />
    </>
  );
};
