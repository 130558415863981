import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Button } from '@mantine/core';

import { useIsLgBreakpoint } from 'hooks';

import { PhoneInput, TextInput } from 'components/shared';
import { ContentHeader } from 'components/NonLoggedLayout';

import {
  FormFieldErrorsType,
  RegisterWithCustomOnChangeType,
  RegistrationValuesType,
} from '../types';

type Props = {
  onSubmit: () => void;
  customRegister: RegisterWithCustomOnChangeType;
  control: Control<RegistrationValuesType>;
  errors: FormFieldErrorsType;
  backButtonAction: () => void;
};

export const Step2 = ({
  onSubmit,
  customRegister,
  control,
  errors,
  backButtonAction,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <div>
      <ContentHeader
        preTitle={
          !isLgBreakpoint
            ? t('user:stepOfSteps', {
                step: 2,
                maxSteps: 3,
              })
            : undefined
        }
        title={t('user:yourData')}
        description={isLgBreakpoint ? t('user:yourDataDescription') : undefined}
        backButtonAction={backButtonAction}
      />
      <TextInput
        id="name"
        label={t('common:name')}
        placeholder={t('common:name')}
        autoFocus
        error={errors.name?.message}
        {...customRegister('name')}
      />
      <TextInput
        id="surname"
        label={t('common:surname')}
        placeholder={t('common:surname')}
        error={errors.surname?.message}
        {...customRegister('surname')}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <PhoneInput
            {...field}
            inputProps={ref}
            label={t('common:phoneNumber')}
            error={errors.phone?.message}
          />
        )}
      />
      <Button sx={{ fontWeight: 600 }} fullWidth size="lg" onClick={onSubmit}>
        {t('common:continue')}
      </Button>
    </div>
  );
};
