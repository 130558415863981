import { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { getZodSchemaErrorMessages } from 'helpers';
import { Modal } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

import { VariableTypeFields, Name, Type } from '../VariableTypeFields';
import { useGetVariableNameError } from './hooks';

export const VariableEditModal = ({
  isOpen,
  onClose,
  variableName,
  editVariableCallback,
  allowedTypes,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const editor = useContext(EditorContext);

  const {
    name: initialName,
    type: initialType,
    ...initialValues
  } = editor.getVariable(variableName);

  const getVariableNameError = useGetVariableNameError();

  const [name, setName] = useState(initialName);
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(initialType);

  const fieldsInstanceRef = useRef(null);

  const handleClose = () => {
    onClose();

    setName(initialName);
    setNameError('');
    setType(initialType);

    fieldsInstanceRef.current = null;
  };

  useEffect(() => {
    setName(initialName);
    setNameError('');
    setType(initialType);
  }, [initialName, initialType]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      title={t('templates:templateCreatorVariables.editVariable')}
      isRedButtonLeftAligned
      redButtonAction={() => {
        editor.deleteVariable(initialName);

        handleClose();
      }}
      defaultButtonAction={handleClose}
      primaryButtonAction={() => {
        const { getValues, schema, clearErrors, setError } =
          fieldsInstanceRef.current;
        const values = getValues();

        const variableNameError = getVariableNameError(name, variableName);

        setNameError(variableNameError);

        const errors =
          getZodSchemaErrorMessages({
            schema,
            fields: values,
          }) || {};

        clearErrors();

        Object.entries(errors).forEach(([field, message]) => {
          setError(field, { message });
        });

        if (!(variableNameError || Object.keys(errors).length > 0)) {
          const updatedVariable = {
            ...values,
            name,
            type,
          };

          editor.updateVariable(variableName, updatedVariable);

          if (editVariableCallback) {
            editVariableCallback(updatedVariable);
          }

          handleClose();
        }
      }}
    >
      <Box sx={{ overflowX: 'hidden' }} component="form">
        <Name
          value={name}
          onChange={value => {
            setNameError('');
            setName(value);
          }}
          error={nameError}
        />
        <Type value={type} onChange={setType} allowedTypes={allowedTypes} />
        <VariableTypeFields
          variableType={type}
          fieldsInstanceRef={fieldsInstanceRef}
          initialValues={initialType === type ? initialValues : undefined}
        />
      </Box>
    </Modal>
  );
};
