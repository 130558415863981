import { generatePath, Params } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getV1OriginUrl } from 'helpers';
import { V1_PATHS } from 'consts';

export const useGetV1Url = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (path: keyof typeof V1_PATHS, params?: Params, searchParams = '') =>
    `${getV1OriginUrl()}/${language}${generatePath(
      V1_PATHS[path],
      params,
    )}${searchParams}`;
};
