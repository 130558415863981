import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTransactionDataStream } from '../requests';
import { GetTransactionDataStreamRequestType } from '../types';

export const useGetTransactionDataStream = ({
  pathParams: { dataStreamId, transactionId },
}: {
  pathParams: Omit<
    GetTransactionDataStreamRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.transactionDataStream,
      { organisationId, dataStreamId, transactionId },
    ],
    queryFn: () =>
      getTransactionDataStream({
        pathParams: { organisationId, dataStreamId, transactionId },
      }),
  });
};
