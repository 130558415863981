import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const VariableIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M3.059 6.2c0-.389.316-.705.706-.705H8.47a.706.706 0 1 1 0 1.412H6.824v.53h14.588A2.588 2.588 0 0 1 24 10.023v3.765a2.588 2.588 0 0 1-2.588 2.588H6.824v.648a.717.717 0 0 1-.004.068h1.65a.706.706 0 1 1 0 1.412H3.766a.706.706 0 1 1 0-1.412h1.65a.713.713 0 0 1-.003-.069v-.647H2.588A2.588 2.588 0 0 1 0 13.79v-3.764a2.588 2.588 0 0 1 2.588-2.589h2.824v-.53H3.765a.706.706 0 0 1-.706-.705Zm2.353 2.648H2.588c-.65 0-1.176.527-1.176 1.176v3.765c0 .65.526 1.177 1.176 1.177h2.824V8.848Zm1.412 6.118h14.588c.65 0 1.176-.527 1.176-1.177v-3.764c0-.65-.527-1.177-1.176-1.177H6.824v6.118Z"
        clipRule="evenodd"
      />
    </Box>
  );
};
