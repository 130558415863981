import axios from 'axios';

import { getErrorCallback } from './getErrorCallback';
import { getRequestCallback } from './getRequestCallback';
import { getResponseCallback } from './getResponseCallback';

export const apiAuthClient = axios.create();

apiAuthClient.interceptors.request.use(getRequestCallback({ auth: true }));
apiAuthClient.interceptors.response.use(
  getResponseCallback(),
  getErrorCallback(apiAuthClient),
);
