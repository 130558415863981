import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Embla } from '@mantine/carousel';
import { ActionIcon, Box, Menu } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { ArrowLeftIcon, ArrowRightIcon } from 'icons';
import { IconPropsType } from 'icons/types';

import {
  useGetIsMatch,
  useGetLinkRestProps,
  useGetNavbarLinks,
} from '../Navbar/hooks';
import { NavbarMenu } from '../Navbar/NavbarMenu';

const CONTROL_BUTTON_WIDTH = 50;
const SLIDE_WIDTH = 120;
const CAROUSEL_VIEWPORTS_MIN_WIDTH = [360, 480, 600];

export const NavbarBottom = () => {
  const { t } = useTranslation(['navigation']);

  const getIsMatch = useGetIsMatch();
  const getLinkRestProps = useGetLinkRestProps();
  const navbarLinks = useGetNavbarLinks();

  const [embla, setEmbla] = useState<Embla | null>(null);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const { width: viewportWidth } = useViewportSize();

  const updateScrollStates = (emblaInstance: Embla | null) => {
    if (emblaInstance) {
      setCanScrollPrev(emblaInstance.canScrollPrev());
      setCanScrollNext(emblaInstance.canScrollNext());
    }
  };

  const mainLinks = navbarLinks.map(({ links }) => links).flat();

  const shouldWrapSlides = mainLinks.length * SLIDE_WIDTH > viewportWidth;

  const getSlidesToScroll = () => {
    if (viewportWidth >= CAROUSEL_VIEWPORTS_MIN_WIDTH[2]) {
      return 4;
    }

    if (viewportWidth >= CAROUSEL_VIEWPORTS_MIN_WIDTH[1]) {
      return 3;
    }

    if (viewportWidth >= CAROUSEL_VIEWPORTS_MIN_WIDTH[0]) {
      return 2;
    }

    return 1;
  };

  const actionIconSx = {
    width: CONTROL_BUTTON_WIDTH,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect(() => {
    updateScrollStates(embla);
    if (embla) {
      embla.on('select', () => {
        updateScrollStates(embla);
      });
    }
  }, [embla]);

  useEffect(() => {
    embla?.reInit();
    updateScrollStates(embla);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainLinks.length, viewportWidth]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {shouldWrapSlides && canScrollPrev && (
        <ActionIcon
          sx={actionIconSx}
          onClick={() => {
            if (canScrollPrev) {
              embla!.scrollPrev();
            }
          }}
        >
          <ArrowLeftIcon />
        </ActionIcon>
      )}
      <Carousel
        sx={{
          flexShrink: 0,
          width: '100%',
          ...(shouldWrapSlides && {
            maxWidth: 180,
            [`@media (min-width: ${CAROUSEL_VIEWPORTS_MIN_WIDTH[0]}px)`]: {
              maxWidth: 300,
            },
            [`@media (min-width: ${CAROUSEL_VIEWPORTS_MIN_WIDTH[1]}px)`]: {
              maxWidth: 420,
            },
            [`@media (min-width: ${CAROUSEL_VIEWPORTS_MIN_WIDTH[2]}px)`]: {
              maxWidth: 540,
            },
          }),
        }}
        styles={
          shouldWrapSlides
            ? undefined
            : {
                container: {
                  justifyContent: 'center',
                  transform: 'translate3d(0,0,0) !important',
                },
              }
        }
        draggable={shouldWrapSlides}
        slideSize={SLIDE_WIDTH}
        align="start"
        slidesToScroll={getSlidesToScroll()}
        getEmblaApi={setEmbla}
        withControls={false}
      >
        {mainLinks.map(link => {
          const {
            translateKey,
            icon,
            hrefKey,
            toKey,
            links: nestedLinks,
          } = link;
          const Icon = icon as FC<IconPropsType>;
          const linkRestProps = getLinkRestProps(hrefKey, toKey);
          const isMatch = getIsMatch(link);

          return (
            <Carousel.Slide key={translateKey}>
              <Menu position="top" withinPortal>
                <Menu.Target>
                  <Box
                    {...linkRestProps}
                    sx={theme => ({
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textDecoration: 'none',
                      borderRadius: theme.radius.sm,
                      padding: theme.other.spacing(1.25, 0.5),
                      backgroundColor: isMatch
                        ? theme.colors[theme.primaryColor][1]
                        : 'transparent',
                      color: isMatch ? theme.fn.primaryColor() : theme.black,
                      cursor: 'pointer',
                    })}
                  >
                    <Icon />
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        minWidth: 80,
                        fontSize: theme.fontSizes.md,
                      })}
                    >
                      {t(translateKey)}
                    </Box>
                  </Box>
                </Menu.Target>
                <NavbarMenu links={nestedLinks} />
              </Menu>
            </Carousel.Slide>
          );
        })}
      </Carousel>
      {shouldWrapSlides && canScrollNext && (
        <ActionIcon
          sx={actionIconSx}
          onClick={() => {
            if (canScrollNext) {
              embla!.scrollNext();
            }
          }}
        >
          <ArrowRightIcon />
        </ActionIcon>
      )}
    </Box>
  );
};
