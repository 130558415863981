import { MARKETING_AGREEMENTS } from 'consts';

export const getContactAgreementsArray = ({
  phoneCallMarketing,
  phoneMessageMarketing,
  newsletter,
}: {
  phoneCallMarketing: boolean;
  phoneMessageMarketing: boolean;
  newsletter: boolean;
}) => [
  ...(phoneCallMarketing ? [MARKETING_AGREEMENTS.phone] : []),
  ...(phoneMessageMarketing ? [MARKETING_AGREEMENTS.sms] : []),
  ...(newsletter ? [MARKETING_AGREEMENTS.newsletter] : []),
];
