import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { DescriptionField } from 'components/shared';
import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';

import { SelectItem } from './SelectItem';

export const Type = ({ value, onChange, allowedTypes }) => {
  const { t } = useTranslation('templates');

  const availableVariables = allowedTypes
    ? VARIABLES.filter(({ value }) => allowedTypes.includes(value))
    : VARIABLES;
  const valueIcon = availableVariables.find(
    variable => value === variable.value,
  ).icon;

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.variableType')}
      description={t(
        'templates:templateCreatorVariables.variableTypeDescription',
      )}
    >
      <Select
        error={null}
        data={availableVariables.map(({ value, labelTranslation, icon }) => ({
          value,
          icon,
          label: t(labelTranslation),
        }))}
        itemComponent={SelectItem}
        icon={valueIcon}
        value={value}
        onChange={onChange}
        readOnly={allowedTypes?.length === 1}
      />
    </DescriptionField>
  );
};
