import { forwardRef } from 'react';
import { ActionIcon, ActionIconProps } from '@mantine/core';

type Props = {
  isActive?: boolean;
} & ActionIconProps;

export const EditorActionButton = forwardRef<HTMLButtonElement, Props>(
  ({ isActive = false, ...actionIconProps }, ref) => (
    <ActionIcon
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        height: 'auto',
        gap: theme.other.spacing(0.5),
        margin: theme.other.spacing(1, 0),
        padding: theme.other.spacing(2),
        backgroundColor: isActive ? theme.colors.blue[2] : theme.white,
        border: `1px solid ${theme.colors.blue[2]}`,
        fontWeight: 500,
      })}
      ref={ref}
      onMouseDown={e => {
        e.preventDefault();
      }}
      {...actionIconProps}
    />
  ),
);
