import { apiAuthClient } from 'api/helpers';

import { PostTemplateRequestType, PostTemplateResponseType } from '../types';

export const postTemplate = async ({
  pathParams: { organisationId, boardId },
  bodyParams,
}: PostTemplateRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates`;

  const { data } = await apiAuthClient.post<PostTemplateResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
