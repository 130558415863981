import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import { PaperCard, SimpleStatus } from 'components/shared';

type Props = {
  templateName: DataStreamTemplateType['templateName'];
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentContent'];
  isBlocked: DataStreamTemplateType['isBlocked'];
};

export const DataStreamTemplate = ({
  templateName,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  return (
    <PaperCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2.25),
          })}
          size="xl"
          weight={600}
        >
          {templateName || documentContent}
        </Text>
        <SimpleStatus
          labelOn={t('dataStreams:templateUpdated')}
          labelOff={t('dataStreams:templateUpdated')}
          on={!isBlocked}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Box
            sx={theme => ({
              marginBottom: theme.other.spacing(2.25),
            })}
          >
            <Text size="md" weight={600}>
              {documentName}
            </Text>
            <Text color="gray.7" size="sm">
              {t('dataStreams:templateDocumentName')}
            </Text>
          </Box>
          <div>
            <Text size="md" weight={600}>
              {documentExternalId}
            </Text>
            <Text color="gray.7" size="sm">
              {t('dataStreams:templateExternalId')}
            </Text>
          </div>
          {Boolean(documentReference) && (
            <Box
              sx={theme => ({
                marginTop: theme.other.spacing(1.75),
              })}
            >
              <Text size="md" weight={600}>
                {documentReference}
              </Text>
              <Text color="gray.7" size="sm">
                {t('dataStreams:templateDocumentReference')}
              </Text>
            </Box>
          )}
        </div>
      </Box>
    </PaperCard>
  );
};
