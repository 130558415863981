import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';

import { Breadcrumbs, MetaTitle } from 'components/shared';

import { AccountManagement } from './AccountManagement';
import { NotificationsAndAgreements } from './NotificationsAndAgreements';

export const Account = () => {
  const { t } = useTranslation('user');

  return (
    <>
      <MetaTitle value={t('user:myAccount')} />
      <Breadcrumbs
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        items={[
          {
            name: t('user:myAccount'),
            url: '#',
          },
        ]}
      />
      <Tabs defaultValue="accountManagement">
        <Tabs.List
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
        >
          <Tabs.Tab value="accountManagement">
            {t('user:accountManagement')}
          </Tabs.Tab>
          <Tabs.Tab value="notificationsAndAgreements">
            {t('user:notificationsAndAgreements')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="accountManagement">
          <AccountManagement />
        </Tabs.Panel>
        <Tabs.Panel value="notificationsAndAgreements">
          <NotificationsAndAgreements />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
