import { useQuery } from '@tanstack/react-query';
import { AxiosResponseHeaders } from 'axios';

import { API_QUERY_KEYS } from 'consts';

import { getSsoAuth } from '../requests';
import { GetSsoAuthRequestType, GetSsoAuthResponseType } from '../types';

export const useGetSsoAuth = (
  {
    queryParams: { code, state },
    pathParams: { ssoDriver },
  }: GetSsoAuthRequestType,
  options?: {
    enabled?: boolean;
    onError?: (error: unknown) => void;
    onSuccess?: (data: {
      data: GetSsoAuthResponseType['data'];
      headers: AxiosResponseHeaders;
    }) => void;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.ssoAuth, { ssoDriver, code, state }],
    queryFn: () =>
      getSsoAuth({ queryParams: { code, state }, pathParams: { ssoDriver } }),
    ...options,
  });
