import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, Title } from '@mantine/core';
import { UseListStateHandlers } from '@mantine/hooks';

import { DeleteIcon, EditIcon } from 'icons';
import { getDataStreamTemplatesItemErrors } from 'helpers';
import { useGetV1Url } from 'hooks';
import { DataStreamTemplateType, ValidationErrors } from 'types';
import { Actions, FieldErrorMessage, Table } from 'components/shared';

import { useGetDataStreamTemplatesItemFormattedErrors } from './hooks';
import { AddTemplateModal } from './AddTemplateModal';
import { DeleteTemplateModal } from './DeleteTepmplateModal';
import { Errors } from './Errors';
import { EditTemplateModal } from './EditTemplateModal';

type Action = 'add' | 'delete' | 'edit' | null;
type SelectedTemplateLocalId = number | string | null;

type Props = {
  templates: DataStreamTemplateType[];
  templatesHandler: UseListStateHandlers<DataStreamTemplateType>;
  hasAccess: boolean;
  serverErrors: ValidationErrors | null;
  clearServerError: (errorField: string) => void;
  hasNoTemplatesError: boolean;
  clearTemplatesError: () => void;
};

export const StreamTemplates = ({
  templates,
  templatesHandler,
  hasAccess,
  serverErrors,
  clearServerError,
  hasNoTemplatesError,
  clearTemplatesError,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  const getV1Url = useGetV1Url();

  const getDataStreamTemplatesItemFormattedErrors =
    useGetDataStreamTemplatesItemFormattedErrors();

  const [modalAction, setModalAction] = useState<{
    type: Action;
    selectedTemplateLocalId: SelectedTemplateLocalId;
  }>({
    type: null,
    selectedTemplateLocalId: null,
  });

  const [addStreamTemplateErrors, setAddStreamTemplateErrors] =
    useState<ValidationErrors | null>(null);
  const [editStreamTemplateErrors, setEditStreamTemplateErrors] =
    useState<ValidationErrors | null>(null);

  const openModal = (
    type: Action,
    selectedTemplateLocalId: SelectedTemplateLocalId,
  ) => {
    setModalAction({
      type,
      selectedTemplateLocalId,
    });
  };

  const closeActiveModal = () => {
    setModalAction({
      type: null,
      selectedTemplateLocalId: null,
    });
  };

  const selectedTemplateIndex = templates.findIndex(
    ({ templateId, documentContent }) =>
      (templateId !== null &&
        templateId === modalAction.selectedTemplateLocalId) ||
      documentContent === modalAction.selectedTemplateLocalId,
  );
  const selectedTemplate = templates[selectedTemplateIndex];

  const clearAddStreamTemplateError = (errorKey: string) => {
    if (addStreamTemplateErrors?.[errorKey]) {
      setAddStreamTemplateErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }

    clearServerError(errorKey);
  };
  const clearEditStreamTemplateError = (errorKey: string) => {
    if (addStreamTemplateErrors?.[errorKey]) {
      setEditStreamTemplateErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }

    clearServerError(`templates[${selectedTemplateIndex}].${errorKey}`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title order={5}>{t('dataStreams:templatesList.templates')}</Title>
        {hasAccess && (
          <Button
            variant="light"
            color="green"
            onClick={() => {
              openModal('add', null);
            }}
          >
            {t('dataStreams:templatesList.addNew')}
          </Button>
        )}
      </Box>
      <FieldErrorMessage>
        {hasNoTemplatesError
          ? t('common:formErrors.required')
          : serverErrors?.templates}
      </FieldErrorMessage>
      <Table
        hasData={templates.length > 0}
        noDataHeader={t('dataStreams:templatesList.noDataHeader')}
      >
        <thead>
          <tr>
            <th>{t('dataStreams:templatesList.templateOrFile')}</th>
            <th>{t('dataStreams:templatesList.documentName')}</th>
            <th>{t('dataStreams:templatesList.externalId')}</th>
            <th>{t('dataStreams:templatesList.reference')}</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {templates.map(
            (
              {
                templateId,
                templateName,
                documentContent,
                documentName,
                documentExternalId,
                documentReference,
              },
              index,
            ) => {
              const localId = templateId || documentContent;
              const templateErrors = getDataStreamTemplatesItemErrors(
                serverErrors,
                index,
              );

              return (
                <tr key={localId}>
                  <td>
                    {templateId ? (
                      <Anchor
                        sx={{ fontWeight: 600 }}
                        href={getV1Url('template', {
                          templateId: String(templateId),
                        })}
                      >
                        {templateName}
                      </Anchor>
                    ) : (
                      documentContent
                    )}
                    {Object.keys(templateErrors).length > 0 && (
                      <Errors
                        errors={getDataStreamTemplatesItemFormattedErrors(
                          templateErrors,
                        )}
                      />
                    )}
                  </td>
                  <td>{documentName}</td>
                  <td>{documentExternalId}</td>
                  <td>{documentReference}</td>
                  <td>
                    {hasAccess && (
                      <Actions
                        items={[
                          {
                            name: t('common:edit'),
                            icon: <EditIcon />,
                            colorDesktop: 'blue',
                            action: () => {
                              openModal('edit', localId);
                            },
                          },
                          {
                            name: t('common:delete'),
                            icon: <DeleteIcon />,
                            colorDesktop: 'red',
                            colorMobile: 'red',
                            action: () => {
                              openModal('delete', localId);
                            },
                          },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
      {hasAccess && (
        <>
          <AddTemplateModal
            isOpen={modalAction.type === 'add'}
            onClose={() => {
              closeActiveModal();
              setAddStreamTemplateErrors(null);
            }}
            onAdd={template => {
              const handleAddTemplate = () => {
                templatesHandler.append(template);
                clearTemplatesError();
                setAddStreamTemplateErrors(null);
                closeActiveModal();
              };

              if (template.documentContent) {
                handleAddTemplate();
              } else {
                const templateWithGivenIdAlreadyExists = templates.some(
                  ({ templateId }) => templateId === template.templateId,
                );

                if (templateWithGivenIdAlreadyExists) {
                  setAddStreamTemplateErrors({
                    templateId: t('common:formErrors.duplicated'),
                  });
                } else {
                  handleAddTemplate();
                }
              }
            }}
            addStreamTemplateErrors={{
              ...serverErrors,
              ...addStreamTemplateErrors,
            }}
            clearAddStreamTemplateError={clearAddStreamTemplateError}
            templates={templates}
          />
          {selectedTemplate && (
            <>
              <DeleteTemplateModal
                isOpen={modalAction.type === 'delete'}
                onClose={closeActiveModal}
                name={
                  selectedTemplate.templateName ||
                  selectedTemplate.documentContent
                }
                type={
                  selectedTemplate.templateId === null ? 'file' : 'template'
                }
                deleteTemplate={() => {
                  templatesHandler.remove(selectedTemplateIndex);
                  closeActiveModal();
                }}
              />
              <EditTemplateModal
                isOpen={modalAction.type === 'edit'}
                onClose={() => {
                  closeActiveModal();
                  setEditStreamTemplateErrors(null);
                }}
                onEdit={updatedTemplate => {
                  const templateWithGivenIdAlreadyExists = templates.some(
                    ({ templateId }) =>
                      templateId === updatedTemplate.templateId &&
                      templateId !== selectedTemplate.templateId,
                  );

                  if (templateWithGivenIdAlreadyExists) {
                    setEditStreamTemplateErrors({
                      templateId: t('common:formErrors.duplicated'),
                    });
                  } else {
                    templatesHandler.apply((template, index) =>
                      index === selectedTemplateIndex
                        ? updatedTemplate
                        : {
                            ...template,
                            attachTo:
                              updatedTemplate.documentContent &&
                              template.attachTo ===
                                selectedTemplate.documentContent
                                ? updatedTemplate.documentContent
                                : template.attachTo,
                          },
                    );
                    closeActiveModal();
                  }
                }}
                editStreamTemplateErrors={{
                  ...getDataStreamTemplatesItemErrors(
                    serverErrors,
                    selectedTemplateIndex,
                  ),
                  ...editStreamTemplateErrors,
                }}
                clearEditStreamTemplateError={clearEditStreamTemplateError}
                templateId={selectedTemplate.templateId}
                templateName={selectedTemplate.templateName}
                documentName={selectedTemplate.documentName}
                documentExternalId={selectedTemplate.documentExternalId}
                documentReference={selectedTemplate.documentReference}
                documentContent={selectedTemplate.documentContent}
                isBlocked={selectedTemplate.isBlocked}
                attachTo={selectedTemplate.attachTo}
                templates={templates}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
