import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Checkbox, DefaultProps, Text } from '@mantine/core';

import { TemplateDescriptionField } from '../TemplateDescriptionField';

type Props = {
  error: string | undefined;
  canEditByNegotiatorCheckboxProps: UseFormRegisterReturn;
  sx?: DefaultProps['sx'];
};
export const Permissions = ({
  error,
  canEditByNegotiatorCheckboxProps,
  sx,
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      sx={sx}
      label={t('templates:permissions')}
      description={t('templates:permissionsDescription')}
      error={error}
      withoutBorder
    >
      <Checkbox
        {...canEditByNegotiatorCheckboxProps}
        sx={{
          marginBottom: 0,
        }}
        label={
          <>
            <Text>{t('templates:editableByContractors')}</Text>
            <Text size="md" weight={400}>
              {t('templates:editableByContractorsDescription')}
            </Text>
          </>
        }
      />
    </TemplateDescriptionField>
  );
};
