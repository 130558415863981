import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const GlobeIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="M8.571 9.714v-.75a.75.75 0 0 0-.75.75h.75Zm0 1.143.53.53.22-.22v-.31h-.75Zm-1.95.335-.53.53.53-.53Zm4.236 7.094h.75v-.31l-.22-.22-.53.53Zm-1.143-1.143h-.75v.31l.22.22.53-.53Zm0-1.714h.75v-.311l-.22-.22-.53.53Zm-1.143-1.143h-.75v.31l.22.22.53-.53Zm0-1.143v-.75a.75.75 0 0 0-.75.75h.75ZM13.536 4v2.286h1.5V4h-1.5Zm-.393 2.679H12v1.5h1.143v-1.5ZM9.714 8.964H8.571v1.5h1.143v-1.5Zm-1.893.75v1.143h1.5V9.714h-1.5Zm.22.613-.335.335 1.061 1.06.335-.335-1.06-1.06Zm-.89.335L5.1 8.611l-1.06 1.061 2.05 2.05 1.06-1.062Zm2.956-2.09a.393.393 0 0 1-.393.392v1.5a1.893 1.893 0 0 0 1.893-1.893h-1.5ZM12 6.678a1.893 1.893 0 0 0-1.893 1.892h1.5c0-.217.176-.392.393-.392v-1.5ZM7.706 10.66a.393.393 0 0 1-.555 0l-1.06 1.061c.738.74 1.937.74 2.676 0l-1.06-1.06Zm5.83-4.375a.393.393 0 0 1-.393.393v1.5a1.893 1.893 0 0 0 1.893-1.893h-1.5ZM11.607 20v-1.714h-1.5V20h1.5Zm-.22-2.245-1.142-1.142-1.061 1.06 1.143 1.143 1.06-1.06Zm-.923-.612v-1.714h-1.5v1.714h1.5Zm-.22-2.245-1.142-1.143-1.06 1.061 1.142 1.143 1.06-1.06Zm-.923-.612v-1.143h-1.5v1.143h1.5Zm-.75-.393h4.572v-1.5H8.57v1.5Zm4.965.393v.762h1.5v-.762h-1.5Zm2.274 3.035h3.047v-1.5H15.81v1.5Zm-1.131-1.13c0 .624.506 1.13 1.13 1.13v-1.5a.37.37 0 0 1 .37.37h-1.5Zm-.012-.012h.004l.004.003a.012.012 0 0 1 .003.004v.005h1.5c0-.835-.676-1.512-1.511-1.512v1.5Zm-1.131-1.131c0 .624.506 1.13 1.13 1.13v-1.5c.204 0 .37.166.37.37h-1.5Zm-.393-1.155c.217 0 .393.176.393.393h1.5a1.893 1.893 0 0 0-1.893-1.893v1.5ZM12 19.25A7.25 7.25 0 0 1 4.75 12h-1.5A8.75 8.75 0 0 0 12 20.75v-1.5ZM19.25 12A7.25 7.25 0 0 1 12 19.25v1.5A8.75 8.75 0 0 0 20.75 12h-1.5ZM12 4.75A7.25 7.25 0 0 1 19.25 12h1.5A8.75 8.75 0 0 0 12 3.25v1.5Zm0-1.5A8.75 8.75 0 0 0 3.25 12h1.5A7.25 7.25 0 0 1 12 4.75v-1.5Z"
      />
    </Box>
  );
};
