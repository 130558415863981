import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, Text, Title } from '@mantine/core';

import { LogoExtendedIcon, WomanWithExclamationMarkRoundedIcon } from 'icons';
import { useIsMdBreakpoint } from 'hooks';
import { EMAILS, STATIC_URLS, URLS } from 'consts';

export const Error = () => {
  const { t } = useTranslation(['error', 'notFound']);

  const isMdBreakpoint = useIsMdBreakpoint();

  return (
    <Box
      sx={theme => ({
        maxWidth: theme.other.layoutSizes.containerWidth,
        margin: 'auto',
        padding: theme.other.spacing(isMdBreakpoint ? 8 : 4),
      })}
    >
      <Anchor
        sx={theme => ({
          display: 'block',
          marginBottom: theme.other.spacing(18),
        })}
        component={Link}
        to={URLS.home}
      >
        <LogoExtendedIcon color="black" width={208} height={42} />
      </Anchor>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={theme => ({
            width: isMdBreakpoint ? 412 : undefined,
            marginRight: isMdBreakpoint
              ? theme.other.spacing(21.25)
              : undefined,
          })}
        >
          <Title
            sx={theme => ({
              marginBottom: theme.other.spacing(3.25),
            })}
            order={1}
          >
            {t('error:errorOccurred')}
          </Title>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(6.75),
              fontSize: theme.fontSizes.xl,
            })}
          >
            {t('error:description')}
          </Text>
          <Box
            sx={theme => ({
              marginBottom: theme.other.spacing(18),
            })}
          >
            <Button
              variant="default"
              component={Link}
              to={URLS.home}
              sx={theme => ({
                marginRight: theme.other.spacing(2),
                marginBottom: theme.other.spacing(2),
              })}
              size="md"
            >
              {t('error:homepage')}
            </Button>
            <Button component="a" size="md" href={STATIC_URLS.staticWebsiteUrl}>
              {t('error:goToMainSite')}
            </Button>
          </Box>
          <Text
            weight={700}
            sx={theme => ({
              fontSize: theme.fontSizes.xl,
              marginBottom: 4,
            })}
          >
            {t('notFound:needHelp')}
          </Text>
          <Anchor
            sx={{ fontWeight: 600 }}
            href={`mailto:${EMAILS.pergaminHelp}`}
          >
            {t('notFound:contactHelp')}
          </Anchor>
        </Box>
        {isMdBreakpoint && (
          <WomanWithExclamationMarkRoundedIcon color="red.6" />
        )}
      </Box>
    </Box>
  );
};
