import {
  ApiIcon,
  FileIcon,
  TemplatesIcon,
  TransactionsIcon,
  UserIcon,
} from 'icons';
import { useTransactionsFeature } from 'hooks';

import { useGetCurrentBoard } from 'api/boards';
import { LinkType } from 'components/Layout/Navbar/types';
import { filterLinksByHidden } from 'components/Layout/Navbar/helpers';

export const useGetNavbarLinks = () => {
  const { data: transactionsFeature } = useTransactionsFeature();
  const { board } = useGetCurrentBoard();

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const navbarLinks: {
    sectionTranslateKey: string;
    links: LinkType[];
  }[] = [
    {
      sectionTranslateKey: 'navigation:navbar.mainSectionLabel',
      links: [
        {
          translateKey: 'navigation:navbar.contracts',
          icon: FileIcon,
          defaultOpened: true,
          links: [
            {
              translateKey: 'navigation:navbar.myContracts',
              hrefKey: 'contracts',
            },
            {
              translateKey: 'navigation:navbar.pendingContracts',
              hrefKey: 'pendingContracts',
              showLocalContractsCount: true,
            },
            {
              translateKey: 'navigation:navbar.archivedContracts',
              hrefKey: 'archivedContracts',
            },
          ],
        },
        {
          translateKey: 'navigation:navbar.companies',
          icon: UserIcon,
          hrefKey: 'companies',
        },
        {
          translateKey: 'navigation:navbar.templates',
          icon: TemplatesIcon,
          toKey: 'templates',
          hidden: !hasBoardTemplateFeature,
        },
      ],
    },
    {
      sectionTranslateKey: 'navigation:navbar.advancedSectionLabel',
      links: [
        {
          key: 'transactions',
          translateKey: 'navigation:navbar.transactions',
          hidden: !transactionsFeature,
          icon: TransactionsIcon,
          defaultOpened: false,
          links: [
            {
              translateKey: 'navigation:navbar.transactionList',
              toKey: 'transactions',
            },
            {
              translateKey: 'navigation:navbar.dataTypes',
              toKey: 'dataTypes',
            },
            {
              translateKey: 'navigation:navbar.dataStreams',
              toKey: 'dataStreams',
            },
          ],
        },
        {
          translateKey: 'navigation:navbar.api',
          icon: ApiIcon,
          toKey: 'api',
        },
      ],
    },
  ];

  return navbarLinks.map(section => ({
    ...section,
    links: filterLinksByHidden(section.links),
  }));
};
