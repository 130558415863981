import { ReactNode } from 'react';
import { ActionIcon, ActionIconProps, packSx, Tooltip } from '@mantine/core';

type Props = {
  tooltipLabel: string;
  icon: ReactNode;
  isActive?: boolean;
} & ActionIconProps;

export const TextModifierButton = ({
  tooltipLabel,
  icon,
  isActive = false,
  sx,
  ...actionIconProps
}: Props) => (
  <Tooltip
    disabled={tooltipLabel === undefined}
    label={tooltipLabel}
    position="top"
  >
    <span>
      <ActionIcon
        sx={[
          theme => ({
            backgroundColor: isActive ? theme.colors.blue[2] : undefined,
          }),
          ...packSx(sx),
        ]}
        onMouseDown={e => {
          e.preventDefault();
        }}
        {...actionIconProps}
      >
        {icon}
      </ActionIcon>
    </span>
  </Tooltip>
);
