import { Divider as DividerBase } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const LoginOptionsDivider = () => {
  const { t } = useTranslation(['common', 'user']);

  return (
    <DividerBase
      sx={theme => ({
        margin: theme.other.spacing(4, 0, 3),
      })}
      styles={theme => ({
        label: {
          fontSize: theme.fontSizes.md,
          ':before': {
            marginRight: theme.other.spacing(3),
          },
          ':after': {
            marginLeft: theme.other.spacing(3),
          },
        },
      })}
      label={t('user:or')}
      labelPosition="center"
    />
  );
};
