import { useQuery } from '@tanstack/react-query';
import { useGetCurrentOrganisationId } from 'hooks';
import { API_QUERY_KEYS } from 'consts';
import { getWebhooks } from '../requests';
import { GetWebhooksRequestType } from '../types';

export const useGetWebhooks = ({
  queryParams: { page, perPage, sortDir } = {},
}: Omit<GetWebhooksRequestType, 'pathParams'>) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [API_QUERY_KEYS.webhooks, { page }],
    queryFn: () =>
      getWebhooks({
        pathParams: { organisationId },
        queryParams: {
          page,
          perPage,
          sortDir,
        },
      }),
  });
};
