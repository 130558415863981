import { useMutation } from '@tanstack/react-query';

import { postUserOtpUpdate } from '../requests';
import { PostUserOtpUpdateRequestType } from '../types';

export const usePostUserOtpUpdate = () =>
  useMutation({
    mutationFn: (bodyParams: PostUserOtpUpdateRequestType['bodyParams']) =>
      postUserOtpUpdate({
        bodyParams,
      }),
  });
