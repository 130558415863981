import { useState } from 'react';
import { Box, LoadingOverlay, Popover } from '@mantine/core';

import { useGetUser, usePatchUser } from 'api/account';
import { useGetUserBoards } from 'api/boards';
import { useServerErrors } from 'hooks';
import { GUIDE_TOUR_DATA } from 'consts';
import { UserBoardType, UserType } from 'types';

import { BoardSelectVariantType } from './types';
import { SelectedBoard } from './SelectedBoard';
import { BoardsPanel } from './BoardsPanel';

type Props = {
  variant?: BoardSelectVariantType;
};

export const BoardSelect = ({ variant = 'navbar' }: Props) => {
  const { addServerErrorActions } = useServerErrors();
  const { data: userData } = useGetUser();
  const user = userData as UserType;
  const { mutateAsync: changeUserBoard, isLoading } = usePatchUser();

  const [isOpen, setIsOpen] = useState(false);

  const { data: boardsData, isSuccess: isBoardsSuccess } = useGetUserBoards();
  const boards = boardsData?.data;

  const currentBoard = boards
    ? boards.find(({ id }) => id === user.lastBoard.id)
    : null;

  const changeBoard = async (boardId: number) => {
    try {
      setIsOpen(false);
      await changeUserBoard({ lastBoardId: boardId });
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Popover
        opened={isOpen}
        onChange={setIsOpen}
        width={368}
        position="bottom-start"
        withinPortal
      >
        <Popover.Target>
          <Box
            data-guide-tour-step={
              GUIDE_TOUR_DATA.changeOrganisationAndBoard.selector
            }
            sx={{ width: '100%', minHeight: 50 }}
          >
            {isBoardsSuccess && (
              <SelectedBoard
                organisationName={currentBoard?.name || ''}
                boardName={currentBoard?.organisation.name || ''}
                variant={variant}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                image={currentBoard?.organisation.logoUrl || ''}
              />
            )}
          </Box>
        </Popover.Target>
        <Popover.Dropdown
          sx={theme => ({
            padding: theme.other.spacing(1, 0, 0),
          })}
        >
          {isBoardsSuccess && (
            <BoardsPanel
              boards={boards as UserBoardType[]}
              changeBoard={changeBoard}
            />
          )}
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
