import { useId } from 'react';
import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const GoogleLogoIcon = ({ size = 24, sx, ...props }: IconPropsType) => {
  const id = useId();

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipPath={`{url(#${id})`} clipRule="evenodd">
        <path
          fill="#FBBB00"
          d="m5.32 14.5-.84 3.12-3.05.06C.52 15.99 0 14.05 0 11.99c0-2.06.48-3.87 1.34-5.52l2.72.5 1.19 2.7a7.148 7.148 0 0 0 .06 4.82"
        />
        <path
          fill="#518EF8"
          d="M23.79 9.76a12.213 12.213 0 0 1-.05 4.75c-.58 2.75-2.11 5.15-4.23 6.85l-3.42-.18-.48-3.03c1.4-.82 2.5-2.11 3.08-3.65h-6.42V9.75H23.8l-.01.01Z"
        />
        <path
          fill="#28B446"
          d="M19.51 21.36A11.973 11.973 0 0 1 12 24c-4.57 0-8.54-2.55-10.57-6.31l3.89-3.18A7.136 7.136 0 0 0 12 19.14c1.31 0 2.55-.36 3.6-.98l3.91 3.2Z"
        />
        <path
          fill="#F14336"
          d="m19.66 2.76-3.89 3.18A7.128 7.128 0 0 0 5.25 9.68l-3.91-3.2C3.34 2.63 7.36 0 12 0c2.91 0 5.58 1.04 7.66 2.76Z"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </Box>
  );
};
