import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, useMantineTheme } from '@mantine/core';

import { ValidityType } from '../types';
import { VALIDITY_OPTIONS } from './consts';

type Props = {
  validity: ValidityType;
  setValidity: Dispatch<SetStateAction<ValidityType>>;
};

export const CertificateValidity = ({ validity, setValidity }: Props) => {
  const { t } = useTranslation('api');
  const mantineTheme = useMantineTheme();

  return (
    <div>
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(2) })}
        size="md"
        weight={600}
      >
        {t('api:selectCertificateValidity')}
      </Text>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
        component="ul"
      >
        {VALIDITY_OPTIONS.map(
          ({ value, labelValue, labelSuffixTranslation }, index) => {
            const isSelected = validity === value;

            return (
              <Box
                sx={theme => ({
                  margin: theme.other.spacing(
                    0,
                    index !== VALIDITY_OPTIONS.length - 1 ? 1 : undefined,
                    0.25,
                    0,
                  ),
                })}
                key={value}
                component="li"
              >
                <Button
                  sx={theme => ({
                    color: isSelected
                      ? undefined
                      : theme.colors[theme.primaryColor][0],
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                    },
                  })}
                  color={`${mantineTheme.primaryColor}.${isSelected ? 0 : 1}`}
                  onClick={() => {
                    setValidity(value);
                  }}
                >
                  {labelValue} {t(labelSuffixTranslation)}
                </Button>
              </Box>
            );
          },
        )}
      </Box>
    </div>
  );
};
