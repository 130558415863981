import { ThemeComponentType } from '../types';

export const UnstyledButton: ThemeComponentType = {
  styles: theme => ({
    root: {
      color: 'inherit',
      letterSpacing: 'inherit',
      lineHeight: 'normal',
      '&:disabled': {
        cursor: 'default',
        color: theme.colors.gray[3],
        backgroundColor: theme.colors.gray[1],
      },
    },
  }),
};
