import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ListDeleteModal } from 'components/shared';

import { useDeleteTemplates } from 'api/templates';
import { getArrayPartitions, getHasHateoasAccess } from 'helpers';
import { useServerErrors, useShowNotification } from 'hooks';
import { TemplatesIcon } from 'icons';
import { BoardTemplateListItemType } from 'types';

import { getIsLocalTemplate } from '../../helpers';
import { useLocalTemplates } from '../../hooks';
import { BoardTemplateLocalListItemType, LocalTemplateType } from '../../types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedTemplates: BoardTemplateLocalListItemType[];
  setSelectedTemplates: Dispatch<
    SetStateAction<BoardTemplateLocalListItemType[]>
  >;
};

export const DeleteModal = ({
  isOpen,
  onClose,
  selectedTemplates,
  setSelectedTemplates,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const showNotification = useShowNotification();
  const { addServerErrorActions } = useServerErrors();

  const { localTemplates, replaceLocalTemplates } = useLocalTemplates();

  const { mutateAsync: deleteTemplates, isLoading: isDeletingTemplates } =
    useDeleteTemplates();

  const [templatesToDelete, templatesCantBeDeleted] = getArrayPartitions(
    selectedTemplates,
    ({ id, ...template }) =>
      getHasHateoasAccess(
        'delete',
        (template as BoardTemplateListItemType).__links,
      ) || getIsLocalTemplate(id),
  );

  return (
    <ListDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      isDeleting={isDeletingTemplates}
      forbiddenItems={(
        templatesCantBeDeleted as BoardTemplateListItemType[]
      ).map(({ id, name }) => ({
        id,
        name,
      }))}
      forbiddenItemIcon={<TemplatesIcon />}
      hasMultipleItemsToDelete={templatesToDelete.length > 1}
      redButtonAction={async () => {
        const [localSelectedTemplates, boardSelectedTemplates] =
          getArrayPartitions(templatesToDelete, ({ id }) =>
            getIsLocalTemplate(id),
          ) as [LocalTemplateType[], BoardTemplateListItemType[]];
        const localTemplateIds = localSelectedTemplates.map(({ id }) => id);
        const templateIds = boardSelectedTemplates.map(({ id }) => id);

        try {
          if (localTemplateIds.length > 0) {
            replaceLocalTemplates(
              localTemplates.filter(({ id }) => !localTemplateIds.includes(id)),
            );
          }

          if (templateIds.length > 0) {
            await deleteTemplates({
              queryParams: {
                templateIds,
              },
            });
          }

          showNotification({
            message:
              templatesToDelete.length > 1
                ? t('templates:templatesDeleted')
                : t('templates:templateDeleted'),
          });
        } catch (error) {
          addServerErrorActions(error);
        } finally {
          setSelectedTemplates([]);
        }
      }}
    />
  );
};
