import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { useDeleteTechnicalUser } from 'api/technicalUsers';
import { useServerErrors, useShowNotification } from 'hooks';
import { TechnicalUserType } from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

type Props = {
  technicalUserId: TechnicalUserType['id'];
  technicalUserName: TechnicalUserType['name'];
} & Pick<
  ModalProps,
  'isOpen' | 'onClose' | 'defaultButtonAction' | 'redButtonAction'
>;

export const DeleteModal = ({
  technicalUserId,
  technicalUserName,
  isOpen,
  onClose,
  defaultButtonAction,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const showNotification = useShowNotification();
  const { addServerErrorActions } = useServerErrors();

  const {
    mutateAsync: deleteTechnicalUser,
    isLoading: isDeletingTechnicalUser,
  } = useDeleteTechnicalUser({
    pathParams: {
      technicalUserId: String(technicalUserId),
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('api:technicalUserModalDeleteHeader')}
      defaultButtonAction={defaultButtonAction}
      defaultButtonDisabled={isDeletingTechnicalUser}
      redButtonIsLoading={isDeletingTechnicalUser}
      redButtonText={t('common:yesDelete')}
      redButtonAction={async () => {
        try {
          await deleteTechnicalUser();

          showNotification({
            message: t('api:technicalUserIsDeleted'),
          });
        } catch (error) {
          addServerErrorActions(error);
        }
      }}
    >
      <Text size="sm">
        <Trans
          i18nKey="api:technicalUserModalDeleteDescription"
          values={{ name: technicalUserName }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
