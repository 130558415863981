import { apiAuthClient } from 'api/helpers';

import { DeleteDataStreamRequestType } from '../types';

export const deleteTechnicalUser = async ({
  pathParams: { organisationId, technicalUserId },
}: DeleteDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/technical-users/${technicalUserId}`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
