import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Anchor, Text } from '@mantine/core';

import { SIGN_TYPES, STATIC_URLS } from 'consts';

import { TemplateDescriptionField } from '../TemplateDescriptionField';
import { SignTypeRadio } from './SignTypeRadio';

type Props = {
  error: string | undefined;
  signType: '' | (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES];
  signTypeRadioProps: UseFormRegisterReturn;
  allowedItems: (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES][] | 'all';
};

export const SignType = ({
  error,
  signType,
  signTypeRadioProps,
  allowedItems = 'all',
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:signType')}
      description={t('templates:signTypeDescription')}
      leftSection={
        <>
          <Text sx={theme => ({ marginTop: theme.other.spacing(1) })} size="md">
            {t('templates:dontYouKnowWhatSignToChoose')}
          </Text>
          <Anchor href={STATIC_URLS.signTypes} target="_blank">
            {t('templates:seeGuide')}
          </Anchor>
        </>
      }
      error={error}
    >
      <SignTypeRadio
        signType={signType}
        signTypeRadioProps={signTypeRadioProps}
        allowedItems={allowedItems}
      />
    </TemplateDescriptionField>
  );
};
