import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetOrganisationUsersResponseType,
  GetOrganisationUsersRequestType,
} from '../types';

export const getOrganisationUsers = async ({
  pathParams: { organisationId },
  queryParams: { email, isAdmin, isDeleted, nameAndSurname, page, perPage },
}: GetOrganisationUsersRequestType) => {
  const query = stringifyUrlQuery({
    email,
    isAdmin,
    isDeleted,
    nameAndSurname,
    page,
    perPage,
  });

  const url = `/api/v2/organisations/${organisationId}/users${query}`;

  const { data } = await apiAuthClient.get<GetOrganisationUsersResponseType>(
    url,
  );

  return data;
};
