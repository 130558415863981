import { MantineTheme } from '@mantine/core';

import { ThemeComponentType } from '../types';

export const NavLink: ThemeComponentType = {
  styles: (theme: MantineTheme) => ({
    root: {
      height: 44,
      padding: theme.other.spacing(0, 1),
      borderRadius: theme.radius.sm,
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
    },
    icon: {
      margin: theme.other.spacing(0, 1, 0, -0.5),
    },
    label: {
      fontSize: theme.fontSizes.md,
    },
    children: {
      position: 'relative',
      paddingLeft: theme.other.spacing(3.75),
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 15,
        height: 'calc(100% - 21px)',
        width: 1,
        backgroundColor: theme.colors.gray[1],
      },
      '& > button, a': {
        position: 'relative',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: -14,
          height: 1,
          width: 14,
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
  }),
};
