import { baseSpacing } from '../../consts';
import { getSpacingValue } from './getSpacingValue';

type SpacingFactor = number | string;

export const spacing = (
  ...factors: [SpacingFactor?, SpacingFactor?, SpacingFactor?, SpacingFactor?]
) => {
  switch (factors.length) {
    case 0:
      return baseSpacing;
    case 1:
      return getSpacingValue(factors[0] as SpacingFactor);
    default:
      return factors
        .map(factor => getSpacingValue(factor as SpacingFactor, true))
        .join(' ');
  }
};
