import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const TemplatesIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 8.571h16M8.571 4v16M5.143 4h13.714C19.488 4 20 4.512 20 5.143v13.714c0 .631-.512 1.143-1.143 1.143H5.143A1.143 1.143 0 0 1 4 18.857V5.143C4 4.512 4.512 4 5.143 4Z"
        stroke={iconColor}
        strokeWidth={1.5}
      />
    </Box>
  );
};
