import { fontFamily } from './fontFamily';

export const headings = {
  fontFamily,
  fontWeight: 600,
  sizes: {
    h1: {
      fontSize: 40,
      lineHeight: '54px',
    },
    h2: {
      fontSize: 32,
      lineHeight: '44px',
    },
    h3: {
      fontSize: 28,
      lineHeight: '38px',
    },
    h4: {
      fontSize: 24,
      lineHeight: '32px',
    },
    h5: {
      fontSize: 20,
      lineHeight: '28px',
    },
    h6: {
      fontSize: 16,
      lineHeight: '22px',
    },
  },
};
