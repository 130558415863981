import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ACCOUNT_DATE_FORMATS, LANGUAGES } from 'consts';

export const useGetZodSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    locale: z.enum(LANGUAGES as [string], {
      errorMap: () => ({
        message: t('common:formErrors.selectedValueIsInvalid'),
      }),
    }),
    dateFormat: z.enum(Object.values(ACCOUNT_DATE_FORMATS) as [string], {
      errorMap: () => ({
        message: t('common:formErrors.selectedValueIsInvalid'),
      }),
    }),
  });
};
