import { useGetUser } from 'api/account';
import { ACCOUNT_DATE_FORMATS } from 'consts';
import { UserType } from 'types';

export const useGetUserDateRegExp = () => {
  const { data: userData } = useGetUser();
  const { dateFormat } = userData as UserType;

  switch (dateFormat) {
    case ACCOUNT_DATE_FORMATS.variant2:
    case ACCOUNT_DATE_FORMATS.variant5:
      return {
        singleDate: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
        rangeDate:
          /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) - \d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
        fromDate: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) -$/,
        toDate: /^- \d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
      };
    case ACCOUNT_DATE_FORMATS.variant3:
    case ACCOUNT_DATE_FORMATS.variant6:
      return {
        singleDate: /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
        rangeDate:
          /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4} - (0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
        fromDate: /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4} -$/,
        toDate: /^- (0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
      };
    case ACCOUNT_DATE_FORMATS.variant1:
    case ACCOUNT_DATE_FORMATS.variant4:
    default:
      return {
        singleDate: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
        rangeDate:
          /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4} - (0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
        fromDate: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4} -$/,
        toDate: /^- (0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/,
      };
  }
};
