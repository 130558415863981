import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  LoadingOverlay,
  Tabs,
  Text,
  UnstyledButton,
} from '@mantine/core';

import { getNotificationsLimitCount } from 'helpers';
import { TickIcon } from 'icons';
import { NotificationListSummaryType, NotificationType } from 'types';

import { NOTIFICATION_TABS } from '../consts';
import { NotificationList } from './NotificationList';
import { UpToDateInfo } from './UpToDateInfo';

const COUNT_LIMIT = 10;

type Props = {
  notifications: NotificationType[];
  lastNotificationRef: (element: any) => void;
  notificationCount: NotificationListSummaryType;
  hasUpdateAccess: boolean;
  isLoading: boolean;
  markNotificationAsRead: (
    notificationId: NotificationType['id'],
    callback?: () => void,
  ) => void;
  markAllNotificationsAsRead: () => void;
  deleteNotification: (id: NotificationType['id']) => void;
  activeTab: (typeof NOTIFICATION_TABS)[keyof typeof NOTIFICATION_TABS];
  setActiveTab: Dispatch<
    SetStateAction<(typeof NOTIFICATION_TABS)[keyof typeof NOTIFICATION_TABS]>
  >;
  handleClosePopover: () => void;
};

export const NotificationsPanel = ({
  notifications,
  lastNotificationRef,
  notificationCount: {
    documentUnreadCount,
    organisationUnreadCount,
    unreadCount,
  },
  hasUpdateAccess,
  isLoading,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  deleteNotification,
  activeTab,
  setActiveTab,
  handleClosePopover,
}: Props) => {
  const { t } = useTranslation('notifications');

  const allNotificationsCountText =
    getNotificationsLimitCount(unreadCount) || '';

  return (
    <div>
      <LoadingOverlay visible={isLoading} />
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.other.spacing(2, 2, 3),
        })}
      >
        <Text weight={600}>
          {t('notifications:notifications')}
          {allNotificationsCountText && <> ({allNotificationsCountText})</>}
        </Text>
        {unreadCount !== 0 ? (
          <UnstyledButton
            sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}
            onClick={markAllNotificationsAsRead}
          >
            <TickIcon
              sx={theme => ({ marginRight: theme.other.spacing(0.25) })}
              size={18}
            />
            {t('notifications:markAllAsRead')}
          </UnstyledButton>
        ) : null}
      </Box>
      <Tabs
        value={activeTab}
        onTabChange={(
          tab: (typeof NOTIFICATION_TABS)[keyof typeof NOTIFICATION_TABS],
        ) => {
          setActiveTab(tab);
        }}
      >
        <Tabs.List
          sx={theme => ({
            padding: theme.other.spacing(0, 2, 2),
            borderBottom: `1px solid ${theme.colors.gray[1]}`,
          })}
        >
          <Tabs.Tab value={NOTIFICATION_TABS.all}>
            {t('notifications:all')}{' '}
            {allNotificationsCountText && (
              <>
                {' '}
                <Badge variant="filled" radius="xl" color="orange" size="xs">
                  {allNotificationsCountText}
                </Badge>
              </>
            )}
          </Tabs.Tab>
          <Tabs.Tab value={NOTIFICATION_TABS.documents}>
            {t('notifications:documents')}
            {documentUnreadCount ? (
              <>
                {' '}
                <Badge variant="filled" radius="xl" color="orange" size="xs">
                  {documentUnreadCount > COUNT_LIMIT
                    ? `${COUNT_LIMIT}+`
                    : documentUnreadCount}
                </Badge>
              </>
            ) : null}
          </Tabs.Tab>
          <Tabs.Tab value={NOTIFICATION_TABS.organisationsAndBoards}>
            {t('notifications:organisationsAndBoards')}
            {organisationUnreadCount ? (
              <>
                {' '}
                <Badge variant="filled" radius="xl" color="orange" size="xs">
                  {organisationUnreadCount > COUNT_LIMIT
                    ? `${COUNT_LIMIT}+`
                    : organisationUnreadCount}
                </Badge>
              </>
            ) : null}
          </Tabs.Tab>
        </Tabs.List>
        <Box sx={{ maxHeight: 445, overflow: 'auto' }}>
          {unreadCount === 0 && <UpToDateInfo />}
          <NotificationList
            notifications={notifications}
            lastNotificationRef={lastNotificationRef}
            hasUpdateAccess={hasUpdateAccess}
            markNotificationAsRead={markNotificationAsRead}
            deleteNotification={deleteNotification}
            handleClosePopover={handleClosePopover}
          />
        </Box>
      </Tabs>
    </div>
  );
};
