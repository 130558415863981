import { FlagPlIcon, FlagUkIcon } from 'icons';

export const LANGUAGE_LIST = [
  {
    value: 'pl',
    labelTranslation: 'common:lang.polish',
    Icon: FlagPlIcon,
  },
  {
    value: 'en',
    labelTranslation: 'common:lang.english',
    Icon: FlagUkIcon,
  },
] as const;
