import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { usePatchUser } from 'api/account';
import { setHookFormServerErrors } from 'helpers';
import { useServerErrors } from 'hooks';
import { Modal } from 'components/shared';

import { AppSettingsForm } from './AppSettingsForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AppSettingsModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();

  const { mutateAsync: updateUserData, isLoading: isUpdatingUserData } =
    usePatchUser();

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      title={t('user:appSettings')}
      defaultButtonDisabled={isUpdatingUserData}
      defaultButtonAction={onClose}
      primaryButtonIsLoading={isUpdatingUserData}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <AppSettingsForm
        formRef={formRef}
        onSubmit={async ({ locale, dateFormat }, setFormError) => {
          try {
            await updateUserData({ locale, dateFormat });

            onClose();
          } catch (error) {
            setHookFormServerErrors(getServerErrorStatus(error), setFormError);
            addServerErrorActions(error);
          }
        }}
      />
    </Modal>
  );
};
