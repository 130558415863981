import { Ref, RefObject, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetError } from 'react-hook-form';
import { Box, DefaultProps } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

import { PatchUserRequestType } from 'api/account/types';
import {
  TextInputPassword,
  TextInputPasswordWithStrength,
} from 'components/shared';
import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

import { HIDDEN_PASSWORD_ERROR_MESSAGE } from './consts';
import { useGetZodSchema } from './hooks';

type Props = {
  sx: DefaultProps['sx'];
  formRef: RefObject<HTMLFormElement>;
  formInstanceRef: Ref<{
    setFormError: UseFormSetError<{}>;
  }>;
  onSubmit: (formValues: {
    currentPassword: PatchUserRequestType['bodyParams']['currentPassword'];
    password: PatchUserRequestType['bodyParams']['password'];
  }) => void;
};

export const SetPasswordForm = ({
  sx,
  formRef,
  formInstanceRef,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const passwordInstanceRef = useRef<InstanceRefObjectType>({ isValid: false });

  const schema = useGetZodSchema({ passwordInstanceRef });

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: '',
      password: '',
    },
  });

  useImperativeHandle(formInstanceRef, () => ({
    setFormError: setError,
  }));

  return (
    <Box
      sx={sx}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInputPassword
        {...register('currentPassword')}
        label={t('user:currentPassword')}
        placeholder={t('user:insertCurrentPassword')}
        error={formErrors.currentPassword?.message}
      />
      <TextInputPasswordWithStrength
        {...register('password')}
        instanceRef={passwordInstanceRef}
        autoComplete="off"
        label={t('user:newPassword')}
        placeholder={t('user:insertNewPassword')}
        error={
          formErrors.password?.message === HIDDEN_PASSWORD_ERROR_MESSAGE
            ? undefined
            : formErrors.password?.message
        }
        value={watch('password')}
      />
    </Box>
  );
};
