import { Modal } from 'components/shared';
import { Text } from '@mantine/core';
import { WebhookType } from 'types';
import { useDeleteWebhook } from 'api/webhooks';
import { useShowNotification } from 'hooks';
import { API_QUERY_KEYS } from 'consts';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  webhook: WebhookType;
};

export const WebhookDeleteModal = ({ isOpen, handleClose, webhook }: Props) => {
  const { t } = useTranslation(['common', 'api']);
  const queryClient = useQueryClient();
  const showNotification = useShowNotification();

  const { mutate: deleteWebhook, isLoading: isDeleteWebhookLoading } =
    useDeleteWebhook({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [API_QUERY_KEYS.webhooks],
          });
          showNotification({
            message: t('api:webhooks.webhookDeleteModalSuccessSnackbar'),
          });
          handleClose();
        },
      },
    });

  const handleDeleteWebhook = () => {
    deleteWebhook({ pathParams: { webhookId: webhook.id!.toString() } });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="sm"
      onClose={handleClose}
      title={t('api:webhooks.webhookDeleteModalTitle')}
      defaultButtonText={t('common:cancel')}
      defaultButtonAction={handleClose}
      primaryButtonText={t('api:webhooks.webhookDeleteModalPrimaryButtonLabel')}
      primaryButtonColor="red"
      primaryButtonAction={handleDeleteWebhook}
      primaryButtonIsLoading={isDeleteWebhookLoading}
    >
      <Text>{t('api:webhooks.webhookDeleteModalText')}</Text>
      <Text weight={600}>
        {webhook.url} ({webhook.name})?
      </Text>
    </Modal>
  );
};
