import { Box } from '@mantine/core';

import { ListVariableButton } from '../ListVariableButton';

export const List = ({ filteredVariables, setActiveVariable }) => (
  <Box
    sx={theme => ({
      display: 'grid',
      rowGap: theme.other.spacing(2),
    })}
    component="ul"
  >
    {filteredVariables.map(({ name }) => (
      <li key={name}>
        <ListVariableButton
          onClick={() => {
            setActiveVariable(name);
          }}
        >
          {name}
        </ListVariableButton>
      </li>
    ))}
  </Box>
);
