import { useId } from 'react';
import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const WomanWithExclamationMarkIcon = ({
  color,
  size,
  width = 155,
  height = 125,
  sx,
  ...props
}: IconPropsType) => {
  const mantineTheme = useMantineTheme();

  const id = useId();
  const iconColor = useGetColor(color);
  const backgroundColor = mantineTheme.fn.rgba(iconColor, 0.1);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 155 125"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id={`${id}_a`} d="M0 0h73v63H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M133.106 15c-5.826 0-10.916 3.06-13.684 7.62-3.497-3.815-8.622-6.228-14.337-6.228C94.545 16.392 86 24.588 86 34.697 86 44.805 94.545 53 105.085 53c7.795 0 14.494-4.483 17.458-10.906a16.057 16.057 0 0 0 10.563 3.925c8.778 0 15.894-6.944 15.894-15.51C149 21.944 141.884 15 133.106 15"
          fill="#080717"
        />
        <path
          d="M124 65.662 105.76 80l-4.695-9.843-2.574-5.396c-19.447 1.288-15.94-19.638-15.94-19.638S80 44.543 80 42.02c0-2.517 3.171-2.608 3.171-2.608C82.706 29.491 89.514 26 89.514 26s2.348 1.748 4.259.647c1.911-1.095 6.76 4.459 10.076 7.95 3.314 3.484 4.652 1.222 4.652 1.222 2.425-1.222 5.93-.32 6.761 3.037.83 3.357-1.911 6.134-1.911 6.134L124 65.662Z"
          fill="#FCFDFA"
        />
        <path
          d="m99.298 64.087 7.066 15.029 17.127-13.657-10.536-20.748.305-.317c.025-.025 2.471-2.601 1.742-5.596-.39-1.6-1.377-2.363-2.136-2.723-1.112-.528-2.482-.524-3.606.002-.208.265-.686.74-1.497.836-1.165.133-2.485-.58-3.937-2.127-.356-.38-.73-.785-1.117-1.203-2.402-2.6-6.872-7.435-8.161-6.689-1.768 1.033-3.764.015-4.539-.469-1.26.79-6.067 4.365-5.679 12.756l.029.62-.603.018c-.104.003-2.57.11-2.57 2.016 0 2.008 1.985 2.51 2.07 2.53l.541.13-.09.565c-.015.095-1.51 9.6 3.346 15.123 2.603 2.961 6.587 4.283 11.846 3.93l.399-.026ZM105.934 81l-7.358-15.647c-5.45.282-9.617-1.18-12.407-4.358-4.613-5.254-3.97-13.448-3.703-15.613-1.06-.43-2.466-1.519-2.466-3.547 0-2.128 1.804-2.96 3.127-3.175-.198-9.82 6.353-13.343 6.637-13.49l.325-.17.294.222c.019.014 2.045 1.51 3.58.613 1.943-1.132 5.208 2.15 9.606 6.91.384.415.756.818 1.11 1.197 1.136 1.21 2.168 1.835 2.927 1.764.512-.05.75-.418.76-.434l.088-.14.15-.084a5.423 5.423 0 0 1 4.758-.079c1.405.666 2.396 1.92 2.789 3.533.732 3.003-1.025 5.527-1.755 6.415L125 65.797 105.934 81Z"
          fill="#000"
        />
        <path
          d="M102.765 69 100 63.168s8.495-2.476 9.59-13.168c0 0 2.921 9.798-6.825 19"
          fill="#080717"
        />
        <g transform="translate(82 62)">
          <mask id={`${id}_b`} fill="#fff">
            <use xlinkHref={`#${id}_a`} />
          </mask>
          <path
            d="m20.503 27.593.73-9.56-1.528-2.108L41.368 0l6.256 7.706-1.093 1.057C65.216 27.484 73 63 73 63L0 62.493l20.503-34.9Z"
            fill="#080717"
            mask={`url(#${id}_b)`}
          />
        </g>
        <path
          d="M129 70.646 110.372 87.6a1.523 1.523 0 0 1-2.275-.248l-4.62-6.458L102 78.802 122.95 63l6.05 7.646Z"
          fill="#FCFDFA"
        />
        <path
          d="m102.705 78.487 1.192 1.584 4.84 6.352c.17.224.422.362.708.392.288.032.562-.056.777-.24l19.088-16.309-5.605-6.65-21 14.871ZM109.551 88a2.26 2.26 0 0 1-1.8-.879L101 78.231 123.92 62l7.08 8.4-19.966 17.057c-.409.351-.94.543-1.483.543Z"
          fill="#000"
        />
        <path
          d="M9.948 60C4.454 60 0 55.545 0 50.05V9.95C0 4.456 4.454 0 9.948 0h41.103C56.546 0 61 4.455 61 9.95v40.1c0 5.495-4.454 9.95-9.949 9.95H9.948Z"
          fill={backgroundColor}
          fillRule="nonzero"
        />
        <path
          d="M104.098 38c-.403 0-.808-.05-1.214-.148-3.06-.745-5.197-4.103-5.978-5.97-1.122-2.683-4.868-5.985-4.906-6.018l.777-.864c.162.142 3.986 3.515 5.21 6.441.649 1.552 2.566 4.654 5.177 5.29 1.353.327 2.727-.074 4.086-1.196l.75.887c-1.273 1.05-2.58 1.578-3.902 1.578"
          fill="#000"
        />
        <path
          d="M89 38.5c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5.448-1.5 1-1.5 1 .672 1 1.5"
          fill="#080717"
        />
        <path
          d="M110.011 44a.605.605 0 0 1-.537-.32c-.037-.072-.909-1.772-.192-3.405.461-1.054 1.45-1.81 2.94-2.25a.607.607 0 0 1 .752.392.584.584 0 0 1-.403.732c-1.127.332-1.86.864-2.176 1.58-.498 1.123.15 2.407.156 2.42a.58.58 0 0 1-.268.788.615.615 0 0 1-.272.063"
          fill="#231F20"
        />
        <path
          d="m25 124.91 26.853-40.74S41.1 74.845 46.275 65.61c0 0-1.07-3.44 3.034-5.702 0 0-.893-2.716 2.23-4.165L40.003 42.386a2.67 2.67 0 0 1 .286-3.77 2.577 2.577 0 0 1 3.478.122l21.867 21.35s2.32-3.078 2.32-7.152c0-4.073 2.765-5.431 5.085-5.431s-.446 4.888.09 8.962c.534 4.074 1.873 24.082-.447 32.32S55.142 125 55.142 125"
          fill="#FCFDFA"
        />
        <path
          d="m55.12 125-1.074-.51c.127-.28 15.2-28.07 17.476-36.181 2.337-8.328.91-28.444.429-32.127-.246-1.871.16-3.886.487-5.505.23-1.142.517-2.563.26-2.886-.045-.055-.176-.063-.25-.063-2.167 0-4.483 1.27-4.483 4.837 0 4.236-2.338 7.398-2.438 7.53l-.403.537L42.816 38.78a1.977 1.977 0 0 0-2.68-.094 2.041 2.041 0 0 0-.714 1.41 2.053 2.053 0 0 0 .492 1.505l12.041 13.988-.723.337c-2.604 1.212-1.94 3.342-1.911 3.432l.157.48-.436.24c-3.593 1.989-2.782 4.883-2.745 5.005l.073.248-.125.229c-4.937 8.842 5.533 17.92 5.58 18.01l.162.318-27.002 41.102-.985-.67 26.616-40.516c-.814-1.765-10.128-10.142-5.512-18.637-.166-.904-.393-3.833 2.979-5.918-.172-1.115.104-2.863 1.965-4.045L39.024 42.396a3.26 3.26 0 0 1-.783-2.393c.067-.88.47-1.677 1.134-2.243a3.145 3.145 0 0 1 4.263.15l21.318 20.884c.627-1.038 1.824-3.396 1.824-6.229 0-4.46 3.053-6.044 5.668-6.044.63 0 .982.276 1.167.508.614.766.35 2.077-.016 3.89-.324 1.61-.693 3.435-.474 5.103.488 3.727 1.924 24.112-.463 32.618-2.302 8.203-17.018 35.214-17.543 36.36"
          fill="#000"
        />
        <path
          d="M51.956 79a.58.58 0 0 1-.392-.152.604.604 0 0 1-.05-.84c.026-.033.509-.625.426-1.565-.089-.994-.762-2.017-2-3.04L48 71.8l8.323 1.894a1.58 1.58 0 0 0 1.87-1.147l.434-1.625 1.39.378c1.123.305 2.27-.3 2.67-1.407.383-1.056-.067-2.252-1.044-2.78l-1.224-.663 1.078-2.3a1.994 1.994 0 0 0-.873-2.623l-10.406-5.396a.602.602 0 0 1-.257-.804.584.584 0 0 1 .792-.261l10.407 5.396a3.194 3.194 0 0 1 1.4 4.203l-.594 1.267.231.125c1.493.808 2.18 2.635 1.596 4.249-.613 1.692-2.364 2.615-4.08 2.15l-.257-.07-.126.474c-.387 1.45-1.822 2.333-3.265 2.001l-3.929-.893c.587.785.915 1.59.98 2.404.114 1.459-.687 2.39-.72 2.428a.585.585 0 0 1-.44.2"
          fill="#231F20"
        />
        <path
          d="M59.394 71a.638.638 0 0 1-.253-.052l-13.79-5.88c-.303-.128-.435-.461-.295-.742.14-.281.499-.404.802-.274l13.79 5.88c.303.129.436.462.296.743a.612.612 0 0 1-.55.325M61.402 67a.567.567 0 0 1-.267-.067l-12.806-6.748a.64.64 0 0 1-.265-.84.585.585 0 0 1 .801-.278l12.806 6.748a.64.64 0 0 1 .265.84.595.595 0 0 1-.534.345"
          fill="#231F20"
        />
        <path
          d="M90.534 49.547c-1.319 0-1.843-.118-3.018-.402-.443-.108-1.835-.772-1.835-.772"
          stroke="#000"
          strokeLinecap="round"
        />
        <path
          d="M33.45 35.168H28.5L27.25 12h7.4l-1.2 23.168ZM30.95 47c-2.3 0-3.95-1.733-3.95-3.861 0-2.13 1.65-3.812 3.95-3.812 2.35 0 4.05 1.683 4.05 3.812C35 45.267 33.3 47 30.95 47Z"
          fill={iconColor}
          fillRule="nonzero"
        />
      </g>
    </Box>
  );
};
