import { ReactNode } from 'react';
import { useMantineTheme } from '@mantine/core';
import { NotificationsProvider as NotificationsProviderMantine } from '@mantine/notifications';

type Props = {
  children: ReactNode;
};

export const NotificationsProvider = ({ children }: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <NotificationsProviderMantine
      position="top-right"
      containerWidth={382}
      style={{
        top:
          mantineTheme.other.layoutSizes.headerHeight +
          mantineTheme.other.spacing(1.25),
      }}
    >
      {children}
    </NotificationsProviderMantine>
  );
};
