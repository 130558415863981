import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Text } from '@mantine/core';

import { DescriptionField } from 'components/shared';
import { useGetColumnWidths } from 'components/shared/DescriptionField/hooks';

import { getNumberInputValue } from '../helpers';
import { InitialNumberValue } from '../InitialNumberValue';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { DescriptionForApi } from '../DescriptionForApi';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { MinMaxNumberValue } from '../MinMaxNumberValue';
import { useGetZodMoneyVariableSchema } from './hooks';
import { Currency } from './Currency';
import { LanguageSelect } from './LanguageSelect';

export const MoneyVariable = ({ fieldsInstanceRef, initialValues }) => {
  const { t } = useTranslation('templates');

  const schema = useGetZodMoneyVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? {
          ...initialValues,
          initialValue: initialValues.initialValue
            ? Number(initialValues.initialValue)
            : undefined,
          minValue: initialValues.minValue
            ? Number(initialValues.minValue)
            : undefined,
          maxValue: initialValues.maxValue
            ? Number(initialValues.maxValue)
            : undefined,
        }
      : {
          initialValue: undefined,
          placeholder: '',
          label: '',
          hint: '',
          apiDescription: '',
          currency: '',
          minValue: undefined,
          maxValue: undefined,
          translateMoneyValue: false,
          defaultMoneyTranslation: 'pl',
          required: false,
          requiredWhen: null,
        },
  });

  const { leftColumnWidth, rightColumnWidth } = useGetColumnWidths();

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { initialValue, minValue, maxValue, ...values } = getValues();

      return {
        ...values,
        initialValue: getNumberInputValue(initialValue),
        minValue: getNumberInputValue(minValue),
        maxValue: getNumberInputValue(maxValue),
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  const { initialValue, minValue, maxValue } = getValues();
  const handleNumberValueChange = (value, field) => {
    clearErrors(field);
    setValue(field, value);
  };

  return (
    <div>
      <InitialNumberValue
        inputProps={{
          defaultValue: initialValue,
          onChange: value => {
            handleNumberValueChange(value, 'initialValue');
          },
        }}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <DescriptionForApi
        inputProps={{ ...register('apiDescription') }}
        error={formErrors?.apiDescription?.message || ''}
      />
      <Currency
        inputProps={{ ...register('currency') }}
        error={formErrors?.currency?.message || ''}
        onValueClick={value => {
          setValue('currency', value);
        }}
      />
      <MinMaxNumberValue
        inputMinValueProps={{
          defaultValue: minValue,
          onChange: value => {
            handleNumberValueChange(value, 'minValue');
          },
        }}
        inputMaxValueProps={{
          defaultValue: maxValue,
          onChange: value => {
            handleNumberValueChange(value, 'maxValue');
          },
        }}
        error={
          formErrors?.minValue?.message || formErrors?.maxValue?.message || ''
        }
      />
      <DescriptionField
        label={t('templates:templateCreatorVariables.translateMoneyValue')}
        description={t(
          'templates:templateCreatorVariables.translateMoneyValueDescription',
        )}
        bottomSection={
          watch('translateMoneyValue') && (
            <Box
              sx={theme => ({
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginTop: theme.other.spacing(2),
              })}
            >
              <Text
                sx={{
                  width: leftColumnWidth,
                }}
                weight={600}
              >
                {t(
                  'templates:templateCreatorVariables.defaultTranslationLanguage',
                )}
              </Text>
              <Box
                sx={{
                  width: rightColumnWidth,
                }}
              >
                <LanguageSelect
                  language={watch('defaultMoneyTranslation')}
                  onLanguageChange={language => {
                    setValue('defaultMoneyTranslation', language);
                  }}
                />
              </Box>
            </Box>
          )
        }
      >
        <Checkbox sx={{ margin: 0 }} {...register('translateMoneyValue')} />
      </DescriptionField>
      <Required checkboxProps={{ ...register('required') }} />
    </div>
  );
};
