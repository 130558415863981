import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUser, usePatchUser } from 'api/account';
import { MARKETING_AGREEMENTS } from 'consts';
import { UserType } from 'types';

import { getContactAgreementsArray } from 'helpers';
import { useServerErrors } from 'hooks';
import { Modal } from 'components/shared';

import { AgreementsForm } from './AgreementsForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AgreementsModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const { addServerErrorActions } = useServerErrors();

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  const { mutateAsync: updateUserData, isLoading: isUpdatingUserData } =
    usePatchUser();

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      title={t('user:marketingAgreementsManagement')}
      defaultButtonDisabled={isUpdatingUserData}
      defaultButtonAction={onClose}
      primaryButtonIsLoading={isUpdatingUserData}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <AgreementsForm
        formRef={formRef}
        defaultValues={{
          newsletter: user.contactAgreements.includes(
            MARKETING_AGREEMENTS.newsletter,
          ),
          phoneCallMarketing: user.contactAgreements.includes(
            MARKETING_AGREEMENTS.phone,
          ),
          phoneMessageMarketing: user.contactAgreements.includes(
            MARKETING_AGREEMENTS.sms,
          ),
        }}
        onSubmit={async ({
          phoneMessageMarketing,
          phoneCallMarketing,
          newsletter,
        }) => {
          try {
            await updateUserData({
              contactAgreements: getContactAgreementsArray({
                phoneMessageMarketing,
                phoneCallMarketing,
                newsletter,
              }),
            });

            onClose();
          } catch (error) {
            addServerErrorActions(error, {
              422: {
                message: t('common:errorTryAgain'),
              },
            });
          }
        }}
      />
    </Modal>
  );
};
