import AceEditor, { IAceEditorProps } from 'react-ace';
import { Box, Sx, useMantineTheme } from '@mantine/core';

import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-monokai';

import { FieldErrorMessage } from 'components/shared';

type Props = {
  sx?: Sx;
  error?: string;
  structureErrors?: string;
  withErrorWrapperAlwaysEnabled?: boolean;
} & IAceEditorProps;

export const YamlEditor = ({
  sx,
  error,
  withErrorWrapperAlwaysEnabled = true,
  ...AceEditorProps
}: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <Box sx={sx}>
      <AceEditor
        width="100%"
        mode="yaml"
        theme="monokai"
        fontSize={mantineTheme.fontSizes.lg}
        showPrintMargin={false}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          tabSize: 2,
          useWorker: false,
        }}
        wrapEnabled
        minLines={27}
        maxLines={Infinity}
        {...AceEditorProps}
      />
      {(error || withErrorWrapperAlwaysEnabled) && (
        <FieldErrorMessage>{error}</FieldErrorMessage>
      )}
    </Box>
  );
};
