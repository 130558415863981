import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipProps } from '@mantine/core';

type Props = {
  children: ReactNode;
} & Omit<TooltipProps, 'label'>;

export const CertificateTooltip = ({
  children,
  ...tooltipMantineProps
}: Props) => {
  const { t } = useTranslation('api');

  return (
    <Tooltip
      sx={{ whiteSpace: 'pre-wrap' }}
      label={t('api:oneCertificateInfo')}
      width={333}
      {...tooltipMantineProps}
    >
      <div>{children}</div>
    </Tooltip>
  );
};
