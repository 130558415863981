import { apiAuthClient } from '../../helpers';
import { PostWebhookRequestType, PostWebhookResponseType } from '../types';

export const postWebhook = async ({
  pathParams: { organisationId },
  bodyParams,
}: PostWebhookRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/webhooks`;
  const { data } = await apiAuthClient.post<PostWebhookResponseType>(
    url,
    bodyParams,
  );

  return data;
};
