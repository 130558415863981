import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { useDeleteDataType } from 'api/dataTypes';
import { DataTypeListItemType } from 'types';
import { useServerErrors, useShowNotification } from 'hooks';
import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  name: DataTypeListItemType['name'];
};

export const DataTypeDeleteModal = ({ isOpen, onClose, name }: Props) => {
  const { t } = useTranslation('dataTypes');

  const { addServerErrorActions } = useServerErrors();
  const showNotification = useShowNotification();

  const {
    mutateAsync: dataTypeDeleteMutateAsync,
    isLoading: isDataTypeDeleting,
  } = useDeleteDataType({
    pathParams: {
      dataTypeName: name,
    },
  });

  const deleteDataType = async () => {
    try {
      await dataTypeDeleteMutateAsync();

      showNotification({
        message: t('dataTypes:dataTypeDeleted'),
      });

      onClose();
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('dataTypes:dataTypeDeleteModalHeader')}
      defaultButtonAction={onClose}
      defaultButtonDisabled={isDataTypeDeleting}
      redButtonIsLoading={isDataTypeDeleting}
      redButtonAction={deleteDataType}
    >
      <Text size="sm">
        <Trans
          i18nKey="dataTypes:dataTypeDeleteModalDescription"
          values={{ name }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
