import { formatISO, parse } from 'date-fns';

import { useGetUserDateInputFormat } from 'hooks';

export const useGetIsoDateByUserFormat = () => {
  const userDateInputFormat = useGetUserDateInputFormat();

  return (valueToParse: string | null) =>
    valueToParse
      ? formatISO(parse(valueToParse, userDateInputFormat, new Date()), {
          representation: 'date',
        })
      : null;
};
