import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

import { InitialTextValue } from '../InitialTextValue';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { DescriptionForApi } from '../DescriptionForApi';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { useGetZodCompanyVariableSchema } from './hooks';

export const CompanyVariable = ({ fieldsInstanceRef, initialValues }) => {
  const { t } = useTranslation('templates');

  const schema = useGetZodCompanyVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues || {
      placeholder: '',
      label: '',
      hint: '',
      apiDescription: '',
      initialValue: '',
      required: false,
      requiredWhen: null,
    },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues,
    schema,
    clearErrors,
    setError,
  }));

  return (
    <div>
      <DescriptionField
        label={t('templates:templateCreatorVariables.initialValue')}
        description={t(
          'templates:templateCreatorVariables.initialValueDescription',
        )}
      >
        <TextInput
          readOnly
          placeholder="COMING SOON"
          withErrorWrapperAlwaysEnabled={false}
        />
      </DescriptionField>
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <DescriptionForApi
        inputProps={{ ...register('apiDescription') }}
        error={formErrors?.apiDescription?.message || ''}
      />
      <Required checkboxProps={{ ...register('required') }} />
    </div>
  );
};
