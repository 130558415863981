import {
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
} from 'date-fns';

import { DateRangeValueType } from 'types';

export const getIsInDateRange = (
  dateToCheckStr: string,
  [from, to]: [DateRangeValueType] | [DateRangeValueType, DateRangeValueType],
) => {
  const dateToCheck = new Date(new Date(dateToCheckStr).setHours(0, 0, 0, 0));
  const fromDate = new Date(from || '');
  const toDate = new Date(to || '');

  const isDateToCheckValid = isValid(dateToCheck);
  const isFromDateValid = isValid(fromDate);
  const isToDateValid = isValid(toDate);

  if (isDateToCheckValid) {
    const isFromDateSameDay = isSameDay(dateToCheck, fromDate);

    if (to === undefined) {
      return isFromDateSameDay;
    }

    if (to === null && isFromDateValid) {
      return isAfter(dateToCheck, fromDate) || isFromDateSameDay;
    }

    if (from === null && isToDateValid) {
      return isBefore(dateToCheck, toDate) || isSameDay(dateToCheck, toDate);
    }

    if (isFromDateValid && isToDateValid) {
      return (
        isWithinInterval(dateToCheck, {
          start: fromDate,
          end: toDate,
        }) || isFromDateSameDay
      );
    }
  }

  return false;
};
