import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getTemplate } from '../requests';
import { GetTemplateRequestType } from '../types';

export const useGetTemplate = (
  {
    pathParams: { templateId },
  }: {
    pathParams: GetTemplateRequestType['pathParams'];
  },
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.template,
      {
        templateId: Number(templateId),
      },
    ],
    queryFn: () =>
      getTemplate({
        pathParams: { templateId },
      }),
    ...options,
  });
