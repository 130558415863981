import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const LetterheadIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1667 12.5285V12.0285H14.6667H12.5333H12.0333V12.5285V13.6V14.1H12.5333H14.6667H15.1667V13.6V12.5285ZM15.1667 10.4V9.9H14.6667H12.5333H12.0333V10.4V11.4618V11.9618H12.5333H14.6667H15.1667V11.4618V10.4ZM11.9667 10.4V9.9H11.4667H9.33332H8.83332V10.4V11.4618V11.9618H9.33332H11.4667H11.9667V11.4618V10.4ZM11.9667 12.5285V12.0285H11.4667H9.33332H8.83332V12.5285V13.6V14.1H9.33332H11.4667H11.9667V13.6V12.5285ZM15.7745 3.64645L15.628 3.5H15.4209H6.66665C5.50685 3.5 4.56665 4.4402 4.56665 5.6V18.4C4.56665 19.5598 5.50685 20.5 6.66665 20.5H17.3333C18.4931 20.5 19.4333 19.5598 19.4333 18.4V7.51242V7.30531L19.2869 7.15887L15.7745 3.64645ZM10.9667 7.76667H8.76665V7.7H10.9667V7.76667ZM8.76665 9.83333H15.2333V14.1667H8.76665V9.83333ZM15.2333 16.3H13.0333V16.2333H15.2333V16.3Z"
        fill="#E6ECFF"
        stroke={iconColor}
      />
    </Box>
  );
};
