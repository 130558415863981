import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileWithPath } from '@mantine/dropzone';

import { usePostTechnicalUserGenerateCustomCertificate } from 'api/technicalUsers';
import { TechnicalUserType } from 'types';
import { useServerErrors } from 'hooks';
import { Modal } from 'components/shared';

import {
  useGetCertificateRenderData,
  useGetTechnicalUsersData,
} from '../../hooks';
import { CertificateFileErrorsMapType } from '../../types';
import { CertificateExpiration } from '../CertificateExpiration';
import { FreshCertificate } from '../FreshCertificate';
import { GenerateNewCertificate } from '../GenerateNewCertificate';
import { CertificateServerError } from '../CertificateServerError';

const MAX_CERTIFICATE_FILE_SIZE_IN_KB = 16;

const FILE_EXTENSIONS = ['pem', 'der'];

type Props = {
  isOpen: boolean;
  onClose: () => void;
  hasCreateOwnCertificateAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  expiresAt?: string;
};

export const OwnCertificateModal = ({
  hasCreateOwnCertificateAccess,
  isOpen,
  onClose,
  technicalUserId,
  expiresAt,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);
  const getCertificateRenderData = useGetCertificateRenderData();

  const [generateNewCertificateMode, setGenerateNewCertificateMode] =
    useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [certificateKey, setCertificateKey] = useState('');

  const { getGeneralServerError, getServerFieldError } = useServerErrors();

  const { isRefetching: isTechnicalUsersRefetching } =
    useGetTechnicalUsersData();

  const {
    mutateAsync: generateCertificate,
    isLoading: isGeneratingCertificate,
  } = usePostTechnicalUserGenerateCustomCertificate({
    pathParams: {
      technicalUserId: String(technicalUserId),
    },
  });

  const handleClose = () => {
    onClose();
    setGenerateNewCertificateMode(false);
    setFile(null);
    setFileErrorMessage('');
    setServerErrorMessage('');
    setCertificateKey('');
  };

  const handleCreateOwnCertificate = async () => {
    try {
      const formData = new FormData();

      formData.append('certificate', file as FileWithPath);

      const generatedKey = await generateCertificate({ formData });

      setCertificateKey(generatedKey);
      setGenerateNewCertificateMode(false);
    } catch (error) {
      setFileErrorMessage(getServerFieldError(error, 'certificate'));
      setServerErrorMessage(getGeneralServerError(error));
    }
  };

  const errorsMap: CertificateFileErrorsMapType = {
    'file-invalid-type': t('api:certificateModal.errorInvalidTypes', {
      extension1Large: FILE_EXTENSIONS[0].toUpperCase(),
      extension1: FILE_EXTENSIONS[0],
      extension2Large: FILE_EXTENSIONS[1].toUpperCase(),
      extension2: FILE_EXTENSIONS[1],
    }),
    'file-too-large': t('common:formErrors.errorTooLarge', {
      sizeInKB: MAX_CERTIFICATE_FILE_SIZE_IN_KB,
    }),
  };

  const {
    hasExpiredCertificate,
    hasNotExpiredCertificate,
    hasFreshCertificate,
    hasCertificate,
    formattedExpirationDate,
  } = getCertificateRenderData({ certificateKey, expiresAt });

  const getModalContent = () => {
    const generateNewCertificateView = (
      <GenerateNewCertificate
        file={file}
        setFile={setFile}
        fileErrorMessage={fileErrorMessage}
        setFileErrorMessage={setFileErrorMessage}
        errorsMap={errorsMap}
        filesAccept={FILE_EXTENSIONS.map(ext => `.${ext}`)}
        filesAcceptDescription={
          <>
            {t('api:certificateModal.uploadRequiredExtensions')} .
            {FILE_EXTENSIONS.join(', ')}.
          </>
        }
      />
    );

    switch (true) {
      case Boolean(serverErrorMessage):
        return <CertificateServerError error={serverErrorMessage} />;
      case generateNewCertificateMode:
        return generateNewCertificateView;
      case hasFreshCertificate:
        return (
          <FreshCertificate
            certificateKey={certificateKey}
            certificateName={file!.name as string}
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      case hasNotExpiredCertificate:
        return (
          <CertificateExpiration
            hasExpired={false}
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      case hasExpiredCertificate:
        return (
          <CertificateExpiration
            hasExpired
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      default:
        return generateNewCertificateView;
    }
  };

  const getModalChangingProps = () => {
    if (hasCertificate && !generateNewCertificateMode) {
      return {
        title: t('api:certificateModal.headerOwnCertificate'),
        defaultButtonAction: handleClose,
        defaultButtonText: t('common:close'),
        defaultButtonDisabled: false,
        primaryButtonAction: () => {
          setGenerateNewCertificateMode(true);
          setFile(null);
          setCertificateKey('');
        },
        primaryButtonText: t('api:certificateModal.generateNew'),
      };
    }

    return {
      title: t('api:certificateModal.headerCreating'),
      defaultButtonAction: handleClose,
      primaryButtonDisabled: Boolean(!file),
      ...(hasCreateOwnCertificateAccess && {
        primaryButtonAction: handleCreateOwnCertificate,
        primaryButtonText: t('api:certificateModal.createCertificate'),
      }),
    };
  };

  return (
    <Modal
      {...getModalChangingProps()}
      isOpen={isOpen}
      size="md"
      onClose={handleClose}
      fullLoadingText={t('api:certificateModal.creatingCertificate')}
      isFullLoading={isGeneratingCertificate || isTechnicalUsersRefetching}
      primaryButtonColor="green"
    >
      {getModalContent()}
    </Modal>
  );
};
