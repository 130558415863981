import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS, HTTP_HEADERS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { patchTransaction } from '../requests';
import { PatchTransactionRequestType } from '../types';

export const usePatchTransaction = ({
  headers,
  pathParams: { dataStreamId, transactionId },
}: {
  headers: PatchTransactionRequestType['headers'];
  pathParams: Omit<PatchTransactionRequestType['pathParams'], 'organisationId'>;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PatchTransactionRequestType['bodyParams']) =>
      patchTransaction({
        pathParams: { organisationId, dataStreamId, transactionId },
        bodyParams,
        headers: {
          [HTTP_HEADERS.xTransactionEntryId]:
            headers[HTTP_HEADERS.xTransactionEntryId],
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.transaction,
          { organisationId, dataStreamId, transactionId },
        ],
      });
    },
  });
};
