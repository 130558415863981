import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getCustomizationsEmail } from '../requests';
import { GetCustomizationsEmailRequestType } from '../types';

export const useGetCustomizationsEmail = (
  { pathParams: { organisationId } }: GetCustomizationsEmailRequestType,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.organisationCustomizationsEmail,
      { organisationId },
    ],
    queryFn: () =>
      getCustomizationsEmail({
        pathParams: { organisationId },
      }),
    ...options,
  });
