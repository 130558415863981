import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

type Props = {
  name:
    | DataStreamTemplateType['templateName']
    | DataStreamTemplateType['documentContent']
    | undefined;
  type: 'file' | 'template';
  deleteTemplate: () => void;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const DeleteTemplateModal = ({
  isOpen,
  onClose,
  name,
  type,
  deleteTemplate,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const isTemplateType = type === 'template';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isTemplateType
          ? t('dataStreams:templatesList.deleteTemplateModal.templateHeader')
          : t('dataStreams:templatesList.deleteTemplateModal.fileHeader')
      }
      defaultButtonAction={onClose}
      redButtonAction={deleteTemplate}
    >
      <Text size="sm">
        <Trans
          i18nKey="dataStreams:templatesList.deleteTemplateModal.description"
          values={{
            name,
            type: isTemplateType
              ? t('dataStreams:templatesList.deleteTemplateModal.templateType')
              : t('dataStreams:templatesList.deleteTemplateModal.fileType'),
          }}
          components={{ b: <b /> }}
        />
      </Text>
    </Modal>
  );
};
