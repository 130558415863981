import axios from 'axios';

import {
  GetExternalLetterheadFileRequestType,
  GetExternalLetterheadFileResponseType,
} from '../types';

export const getExternalLetterheadFile = async ({
  url,
}: GetExternalLetterheadFileRequestType) => {
  const { data } = await axios.get<GetExternalLetterheadFileResponseType>(url, {
    responseType: 'arraybuffer',
  });

  return data;
};
