import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const BoldIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={2.5}
        d="M6 12h6.667a4 4 0 0 0 0-8H6.106A.106.106 0 0 0 6 4.106V12Zm0 0h8a4 4 0 0 1 0 8H6.119A.119.119 0 0 1 6 19.881V12Z"
      />
    </Box>
  );
};
