export enum NOTIFICATION_TYPES {
  accountActivated = 512,
  accountDeleted = 1,
  activateAccount = 1024,
  boardAccessGranted = 33554432,
  boardDeleted = 67108864,
  boardInvitation = 524288,
  certificateExpire = 65536,
  contractorJoinedDocument = 128,
  contractorRejectedDocument = 16777216,
  contractorRejectedDocumentInvitation = 131072,
  contractorRemovedFromDocument = 256,
  documentCreatedFromOffer = 262144,
  documentDeleted = 32,
  documentFinished = 16,
  documentLink = 4194304,
  documentQueueUpdated = 4,
  documentSignInvitation = 2097152,
  documentUpdated = 2,
  documentWaitingForAcceptance = 8,
  documentWaitingForSignature = 64,
  emailChanged = 4096,
  organisationDelete = 8388608,
  otpCode = 2048,
  passwordChange = 8192,
  passwordChangedConfirmation = 16384,
  userLeftOrganisation = 32768,
  userRemovedFromBoard = 1048576,
}
