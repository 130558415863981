import { apiAuthClient } from 'api/helpers';

import {
  PostAuthResetPasswordRequestType,
  PostAuthResetPasswordResponseType,
} from '../types';

export const postAuthResetPassword = async ({
  bodyParams,
}: PostAuthResetPasswordRequestType) => {
  const url = '/api/v2/auth/reset-password';

  const { data } = await apiAuthClient.post<PostAuthResetPasswordResponseType>(
    url,
    bodyParams,
  );

  return data;
};
