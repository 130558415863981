import { Box, useMantineTheme } from '@mantine/core';

import { ArrowRightIcon, ErrorTwoToneIcon } from 'icons';

import { VariableMappingItem } from '../VariableMappingItem';

type InfoObject = {
  [key: string]: string;
};

type Props = {
  errorObj: InfoObject;
  warningObj: InfoObject;
  variables: { [key: string]: unknown };
};

const getVariableValueAsString = (value: unknown) =>
  typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value);

export const VariableMapping = ({ errorObj, warningObj, variables }: Props) => {
  const { colors } = useMantineTheme();

  return (
    <ul>
      {Object.entries(variables).map(([variableKey, variableValue]) => {
        const variableError = errorObj[variableKey];
        const variableWarning = warningObj[variableKey];

        const variableMappingItemBorderColor = (() => {
          if (variableError) {
            return colors.red[0];
          }

          if (variableWarning) {
            return colors.yellow[0];
          }

          return colors.gray[3];
        })();

        return (
          <Box
            sx={{
              minHeight: 70,
            }}
            key={variableKey}
            component="li"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              component="span"
            >
              <VariableMappingItem borderColor={variableMappingItemBorderColor}>
                {variableKey}
              </VariableMappingItem>
              <Box
                sx={theme => ({
                  flexShrink: 0,
                  margin: theme.other.spacing(0, 1),
                })}
                component={ArrowRightIcon}
              />
              <VariableMappingItem borderColor={variableMappingItemBorderColor}>
                {getVariableValueAsString(variableValue)}
              </VariableMappingItem>
            </Box>
            {(Boolean(variableError) || Boolean(variableWarning)) && (
              <Box
                sx={theme => ({
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: theme.other.spacing(0.5),
                  fontSize: theme.fontSizes.md,
                })}
                component="span"
              >
                <ErrorTwoToneIcon color={variableError ? 'error' : 'yellow'} />{' '}
                {variableError || variableWarning}
              </Box>
            )}
          </Box>
        );
      })}
    </ul>
  );
};
