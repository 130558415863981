import { apiAuthClient } from 'api/helpers';
import { HTTP_HEADERS } from 'consts';

import {
  PostTransactionRequestType,
  PostTransactionResponseType,
} from '../types';

export const postTransaction = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
  headers,
}: PostTransactionRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}`;
  const { data } = await apiAuthClient.post<PostTransactionResponseType>(
    url,
    undefined,
    {
      headers: {
        [HTTP_HEADERS.xTransactionEntryId]:
          headers[HTTP_HEADERS.xTransactionEntryId],
      },
    },
  );

  return data;
};
