import { Box } from '@mantine/core';

import { ActionItemType } from 'types';

import { ActionItem } from './ActionItem';

type Props = {
  items: ActionItemType[];
  disabled?: boolean;
};

export const TableRowActionsBar = ({
  items,
  disabled: areAllActionsDisabled = false,
}: Props) => (
  <Box
    sx={theme => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 63,
      backgroundColor: theme.colors[theme.primaryColor][1],
      borderTop: `1px solid ${theme.colors.gray[1]}`,
      borderBottomRightRadius: theme.radius.sm,
      borderBottomLeftRadius: theme.radius.sm,
    })}
    component="ul"
  >
    {items.map(
      ({ name, icon, color, isLoading, disabled, ...actionOrToProp }) => (
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            ':not(:first-of-type)': {
              '&:before': {
                content: "''",
                display: 'block',
                width: 1,
                height: 20,
                position: 'absolute',
                left: 0,
                zIndex: 1,
                backgroundColor: theme.colors.gray[2],
              },
            },
          })}
          key={name}
          component="li"
        >
          <ActionItem
            {...actionOrToProp}
            name={name}
            icon={icon}
            color={color}
            disabled={areAllActionsDisabled || disabled}
            isLoading={isLoading}
          />
        </Box>
      ),
    )}
  </Box>
);
