import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionEntryResponseType,
  GetTransactionEntryRequestType,
} from '../types';

export const getTransactionEntry = async ({
  pathParams: { organisationId, dataStream, transactionId, transactionEntry },
}: GetTransactionEntryRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStream}/transactions/${transactionId}/entries/${transactionEntry}`;

  const { data } = await apiAuthClient.get<GetTransactionEntryResponseType>(
    url,
  );

  return data.data;
};
