import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { postTemplate } from '../requests';
import { PostTemplateRequestType } from '../types';

export const usePostTemplate = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: (bodyParams: PostTemplateRequestType['bodyParams']) =>
      postTemplate({
        pathParams: { organisationId, boardId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId, boardId }],
      });
    },
  });
};
