import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mantine/dates';

import { useGetUser } from 'api/account';
import { CalendarIcon } from 'icons';
import { useGetUserDateInputFormat } from 'hooks';
import { DescriptionField } from 'components/shared';

import 'dayjs/locale/pl';

export const InitialValue = ({ control, error }) => {
  const { t } = useTranslation('template');

  const {
    data: { locale },
  } = useGetUser();
  const userDateInputFormat = useGetUserDateInputFormat();

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.initialValue')}
      description={t(
        'templates:templateCreatorVariables.initialValueDescription',
      )}
      error={error}
    >
      <Controller
        name="initialValue"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            placeholder={t('templates:templateCreatorVariables.initialValue')}
            rightSection={<CalendarIcon />}
            locale={locale}
            inputFormat={userDateInputFormat.toUpperCase()}
          />
        )}
      />
    </DescriptionField>
  );
};
