import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

export const DescriptionForApi = ({
  inputProps,
  error,
  withoutBorder = false,
}) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.descriptionForApi')}
      description={t(
        'templates:templateCreatorVariables.descriptionForApiDescription',
      )}
      error={error}
      withoutBorder={withoutBorder}
    >
      <TextInput
        {...inputProps}
        placeholder={t('templates:templateCreatorVariables.descriptionForApi')}
        withErrorWrapperAlwaysEnabled={false}
      />
    </DescriptionField>
  );
};
