import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonProps,
  DefaultProps,
  packSx,
  Tooltip,
} from '@mantine/core';

import { useGetColor } from 'hooks';
import { ActionItemType } from 'types';

type Props = {
  withSeparator: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  sx?: DefaultProps['sx'];
  buttonSx?: DefaultProps['sx'];
} & ActionItemType;

export const ActionItem = ({
  name,
  icon,
  color,
  disabled = false,
  isLoading = false,
  tooltipLabel,
  withSeparator,
  sx,
  buttonSx,
  ...props
}: Props) => {
  const contentColor = useGetColor('to' in props ? 'blue' : color);

  const getButtonProps = () => {
    if ('to' in props) {
      return {
        component: Link,
        to: props.to,
      };
    }

    if ('action' in props) {
      return { onClick: props.action };
    }

    return {};
  };

  return (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          ...(withSeparator
            ? {
                '&:before': {
                  content: "''",
                  display: 'block',
                  width: 1,
                  height: 20,
                  position: 'absolute',
                  left: 0,
                  zIndex: 1,
                  backgroundColor: theme.colors.gray[2],
                },
              }
            : {}),
        }),
        ...packSx(sx),
      ]}
    >
      <Tooltip disabled={!tooltipLabel} label={tooltipLabel} position="top">
        <Box
          sx={{
            width: '100%',
          }}
          component="span"
        >
          <Button
            sx={[
              theme => ({
                margin: theme.other.spacing(0, 2),
                fontSize: theme.fontSizes.lg,
                color: contentColor,
                '&:hover': {
                  backgroundColor: theme.colors[theme.primaryColor][1],
                },
                '&[data-disabled]': {
                  backgroundColor: 'transparent',
                  fontSize: theme.fontSizes.lg,
                  color: theme.colors.gray[3],
                },
                '&[data-loading]': {
                  '.mantine-Button-leftIcon': {
                    width: 24,
                  },
                },
              }),
              ...packSx(buttonSx),
            ]}
            {...(getButtonProps() as ButtonProps)}
            variant="white"
            leftIcon={icon && icon}
            disabled={isLoading ? false : disabled}
            loading={isLoading}
          >
            {name}
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};
