import { apiAuthClient } from 'api/helpers';

import { PatchDataTypeRequestType, PatchDataTypeResponseType } from '../types';

export const patchDataType = async ({
  pathParams: { organisationId, dataTypeName },
  bodyParams,
}: PatchDataTypeRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-types/${dataTypeName}`;

  const { data } = await apiAuthClient.patch<PatchDataTypeResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
