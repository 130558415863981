import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { getBearerTokenStr, setRefreshToken } from 'helpers';
import { HTTP_HEADERS } from 'consts';

import { getRefreshedToken } from './getRefreshedToken';
import { setToken } from './setToken';

let isRefreshing = false;
let requestsToRetry: ((token: string) => void)[] = [];

export const getErrorCallback =
  (instance: AxiosInstance) =>
  async (
    error: AxiosError & {
      config: AxiosRequestConfig;
    },
  ) => {
    const { config: request, response } = error;
    if (response?.status === 401) {
      const retryRequest = new Promise(resolve => {
        requestsToRetry.push((token: string) => {
          // eslint-disable-next-line no-param-reassign
          instance.defaults.headers.common[HTTP_HEADERS.authorization] =
            getBearerTokenStr(token);

          resolve(instance(request));
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const token = await getRefreshedToken();

          setToken(token);
          setRefreshToken(token);

          requestsToRetry.forEach(requestToRetry => requestToRetry(token));

          isRefreshing = false;
          requestsToRetry = [];
        } catch {
          setToken('null');
          setRefreshToken('null');

          return Promise.reject(error);
        }
      }

      return retryRequest;
    }

    return Promise.reject(error);
  };
