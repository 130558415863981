export const QuestionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
    <path fill="#E6EDF9" d="M1 9.186a8 8 0 1 0 16 0 8 8 0 0 0-16 0Z" />
    <path
      stroke="#004FC4"
      strokeWidth={1.5}
      d="M9 10.9V9.186h.571c.947 0 1.715-.768 1.715-1.715v-.114a1.6 1.6 0 0 0-1.6-1.6H9c-.947 0-1.714.767-1.714 1.714m1.143 5.143H9.57M9 17.186a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
    />
  </svg>
);
