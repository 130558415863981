import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';

import { DownloadIcon } from 'icons';

type Props = {
  certificateKey: string;
  certificateName: string;
  formattedExpirationDate: string;
};

export const FreshCertificate = ({
  certificateKey,
  certificateName,
  formattedExpirationDate,
}: Props) => {
  const { t } = useTranslation('api');

  return (
    <div>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        size="md"
        weight={400}
      >
        <Trans
          i18nKey="api:certificateModal.expirationInfo"
          components={{ b: <b /> }}
        />
      </Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.other.spacing(0.5),
          })}
          size="md"
          weight={400}
        >
          {t('api:certificateModal.expiresAt')} <b>{formattedExpirationDate}</b>
        </Text>
        <Button
          component="a"
          href={`data:text/plain;charset=utf-8,${certificateKey}`}
          download={certificateName}
          leftIcon={<DownloadIcon color="white" />}
          color="blue"
        >
          {t('api:certificateModal.download')}
        </Button>
      </Box>
    </div>
  );
};
