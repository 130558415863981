import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Box, UnstyledButton } from '@mantine/core';

import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS } from 'consts';
import { AttachmentIcon, DeleteIcon } from 'icons';
import { DeleteModal } from './DeleteModal';

export const AttachmentTemplates = () => {
  const { templateId } = useParams();

  const { data: template } = useGetTemplate({
    pathParams: { templateId },
  });

  const [activeIndexToDelete, setActiveIndexToDelete] = useState(null);

  const { attachmentTemplates } = template;

  return (
    <>
      <Box
        sx={theme => ({
          display: 'grid',
          rowGap: theme.other.spacing(2),
          paddingBottom: theme.other.spacing(1),
          maxHeight: 550,
          overflowY: 'auto',
        })}
        component="ul"
      >
        {attachmentTemplates.map(({ type }, index) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            component="li"
            key={type}
          >
            <UnstyledButton
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                columnGap: theme.other.spacing(0.5),
                fontSize: theme.fontSizes.lg,
                ':hover': {
                  textDecoration: 'underline',
                },
              })}
              component={Link}
              to={DYNAMIC_URLS.templateAttachmentEdit({
                templateId,
                attachmentName: window.encodeURIComponent(type),
              })}
            >
              <AttachmentIcon />
              <Box
                sx={{
                  maxWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                component="span"
              >
                {type}
              </Box>
            </UnstyledButton>
            <ActionIcon
              onClick={() => {
                setActiveIndexToDelete(index);
              }}
            >
              <DeleteIcon color="red" />
            </ActionIcon>
          </Box>
        ))}
      </Box>
      {activeIndexToDelete !== null && (
        <DeleteModal
          isOpen
          onClose={() => {
            setActiveIndexToDelete(null);
          }}
          attachmentIndex={activeIndexToDelete}
          attachmentName={attachmentTemplates[activeIndexToDelete].type}
        />
      )}
    </>
  );
};
