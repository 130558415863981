import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useListState } from '@mantine/hooks';
import { z } from 'zod';

import { usePostDataStream } from 'api/dataStreams';
import {
  DataStreamTechnicalUserType,
  DataStreamTemplateType,
  DataTypeType,
  ValidationErrors,
} from 'types';
import { getZodSchemaErrorMessages } from 'helpers';
import {
  useServerErrors,
  useShowNotification,
  useStateWithCursorPosition,
  useTransactionsFeature,
} from 'hooks';
import { URLS } from 'consts';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';
import { StreamDataForm, StreamTemplates } from 'components/Transactions';

import { Header } from './Header';

export const DataStreamAdd = () => {
  const { t } = useTranslation(['common', 'transactions', 'dataStreams']);
  const navigate = useNavigate();

  const showNotification = useShowNotification();
  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();

  const { mutateAsync: createDataStream, isLoading: isCreatingDataStream } =
    usePostDataStream();

  const [dataStreamNameField, setDataStreamNameField, dataStreamNameInputRef] =
    useStateWithCursorPosition();
  const [selectedDataTypeName, setSelectedDataTypeName] =
    useState<DataTypeType['name']>('');
  const [selectedTechnicalUser, setSelectedTechnicalUser] = useState<{
    id: DataStreamTechnicalUserType['id'] | string;
    name: DataStreamTechnicalUserType['name'];
  }>({ id: '', name: '' });
  const [templates, templatesHandler] = useListState<DataStreamTemplateType>(
    [],
  );
  const [dataFormErrors, setDataFormErrors] = useState<ValidationErrors | null>(
    null,
  );
  const [serverErrors, setServerErrors] = useState<ValidationErrors | null>(
    null,
  );
  const [hasNoTemplatesError, setHasNoTemplatesError] = useState(false);

  const clearServerError = (errorKey: string) => {
    if (serverErrors?.[errorKey]) {
      setServerErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }
  };
  const clearDataFormError = (errorKey: string) => {
    if (dataFormErrors?.[errorKey]) {
      setDataFormErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }

    clearServerError(errorKey);
  };
  const clearTemplatesError = () => {
    clearServerError('templates');
    setHasNoTemplatesError(false);
  };

  const dataFormSchema = z.object({
    name: z.string().min(1, { message: t('common:formErrors.required') }),
    dataType: z.string().min(1, { message: t('common:formErrors.required') }),
    technicalUserId: z.number({
      invalid_type_error: t('common:formErrors.required'),
    }),
  });

  const handleDataStreamCreate = async () => {
    const errors = getZodSchemaErrorMessages({
      schema: dataFormSchema,
      fields: {
        name: dataStreamNameField.value,
        dataType: selectedDataTypeName,
        technicalUserId: selectedTechnicalUser.id,
      },
    });

    if (templates.length === 0) {
      setHasNoTemplatesError(true);
    }

    if (errors) {
      setDataFormErrors(errors);
    } else if (templates.length > 0) {
      try {
        await createDataStream({
          name: dataStreamNameField.value,
          dataType: selectedDataTypeName,
          technicalUserId: Number(selectedTechnicalUser.id),
          templates: templates.map(
            ({ templateName: _, ...template }) => template,
          ) as DataStreamTemplateType[],
        });

        showNotification({
          message: t('dataStreams:dataStreamAdded'),
        });
        navigate(URLS.dataStreams);
      } catch (error) {
        setServerErrors(getServerErrorStatus(error) || {});
        addServerErrorActions(error);
      }
    }
  };

  const hasCreateAccess = !isLoadingTransactionsFeature && transactionsFeature;

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={transactionsFeature}
        isError={isTransactionFeatureError}
        isLoading={isLoadingTransactionsFeature}
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {() => (
          <>
            <Breadcrumbs
              items={[
                {
                  name: t('transactions:transactions'),
                  url: URLS.transactions,
                },
                { name: t('dataStreams:dataStreams'), url: URLS.dataStreams },
                {
                  name: t('dataStreams:dataStreamAddBreadcrumb'),
                  url: '#',
                },
              ]}
            />
            <Header
              sx={theme => ({ marginBottom: theme.other.spacing(4.5) })}
              hasCreateDataStreamAccess={hasCreateAccess}
              onCreate={handleDataStreamCreate}
              isCreatingDataStream={isCreatingDataStream}
            />
            <StreamDataForm
              sx={theme => ({ marginBottom: theme.other.spacing(3.5) })}
              dataStreamNameField={dataStreamNameField}
              setDataStreamNameField={setDataStreamNameField}
              dataStreamNameInputRef={dataStreamNameInputRef}
              selectedDataType={selectedDataTypeName}
              setSelectedDataTypeName={setSelectedDataTypeName}
              selectedTechnicalUser={selectedTechnicalUser}
              setSelectedTechnicalUser={setSelectedTechnicalUser}
              hasAccess={hasCreateAccess}
              errors={{ ...serverErrors, ...dataFormErrors }}
              clearError={clearDataFormError}
            />
            <StreamTemplates
              templates={templates}
              templatesHandler={templatesHandler}
              hasAccess={hasCreateAccess}
              serverErrors={serverErrors}
              clearServerError={clearServerError}
              hasNoTemplatesError={hasNoTemplatesError}
              clearTemplatesError={clearTemplatesError}
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};
