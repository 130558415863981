import { Box, Paper, Text } from '@mantine/core';

import { ErrorTwoToneIcon } from 'icons';
import { TransactionQueueParticipantType } from 'types';

type Props = {
  participant: TransactionQueueParticipantType;
  errors: { [errorKey: string]: string[] };
};

export const Participant = ({ participant, errors }: Props) => {
  const { email, phoneNumber, signMethod } = participant;
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Paper
      sx={theme => ({
        border: hasErrors
          ? `1px solid ${theme.colors.red[theme.fn.primaryShade()]}`
          : undefined,
        margin: '16px 0',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div>
          <Text size="md" weight={600}>
            {email}
          </Text>
          <Text size="md" sx={{ marginTop: '4px' }}>
            {phoneNumber}
          </Text>
        </div>
        <div style={{ alignSelf: 'flex-start' }}>
          <Text size="md">{signMethod}</Text>
        </div>
      </Box>
      {Object.entries(errors).map(([errorField, values]) => (
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.other.spacing(1.5),
          })}
          key={errorField}
        >
          <ErrorTwoToneIcon
            sx={theme => ({
              flexShrink: 0,
              marginRight: theme.other.spacing(0.5),
            })}
            color="error"
          />
          <Text size="md">
            {errorField}: {values.join(' ')}
          </Text>
        </Box>
      ))}
    </Paper>
  );
};
