import { RefObject } from 'react';
import { FieldErrors } from 'react-hook-form';

import { CERTIFICATE_LANGUAGES } from 'consts';
import { ValidationErrors } from 'types';

import { FormValuesType } from '../types';

export const handleErrorFocus = (
  errors: FieldErrors<FormValuesType> | ValidationErrors,
  formRef: RefObject<HTMLFormElement>,
) => {
  const fieldsToCheck = [
    'name',
    'signType',
    'certificateLanguages',
    'enableNumeric',
    'templateNumeric',
    'templateType',
    'oneWay',
    'canEditByNegotiator',
  ] as const;
  const firstFieldError = fieldsToCheck.find(field => errors?.[field]);

  if (firstFieldError) {
    const fieldName =
      firstFieldError === 'certificateLanguages'
        ? `certificateLanguages-${CERTIFICATE_LANGUAGES.pl}`
        : firstFieldError;
    const fieldElement = formRef.current?.querySelector(
      `[name="${fieldName}"]`,
    );

    if (
      fieldElement?.tagName === 'INPUT' &&
      (fieldElement as HTMLInputElement).type === 'text' &&
      !(fieldElement as HTMLInputElement).disabled
    ) {
      (fieldElement as HTMLInputElement).focus();
    } else {
      fieldElement?.scrollIntoView();
    }
  }
};
