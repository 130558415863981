import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetCurrentBoard } from 'api/boards';
import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS, URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { TemplateAttachmentFormHandler } from './TemplateAttachmentFormHandler';

export const TemplateAttachmentAdd = () => {
  const { t } = useTranslation('templates');

  const { templateId } = useParams() as {
    templateId: string;
  };

  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const {
    data: templateData,
    isError: isTemplateError,
    isLoading: isTemplateLoading,
  } = useGetTemplate(
    { pathParams: { templateId } },
    { enabled: hasBoardTemplateFeature },
  );

  const hasTemplateUpdateAccess = getHasHateoasAccess(
    'update',
    templateData?.__links,
  );

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={templateData}
        isError={isCurrentBoardError || isTemplateError}
        isLoading={isCurrentBoardLoading || isTemplateLoading}
        isNoAccess={
          !hasBoardTemplateFeature &&
          hasTemplateUpdateAccess &&
          !isCurrentBoardLoading
        }
        errorMargin="xl"
      >
        {({ name, attachmentTemplates }) => (
          <>
            <MetaTitle value={`${name} - ${t('templates:templates')}`} />
            <Breadcrumbs
              sx={theme => ({
                marginBottom: theme.other.spacing(3),
              })}
              items={[
                { name: t('templates:templates'), url: URLS.templates },
                { name, url: DYNAMIC_URLS.templateEdit({ templateId }) },
                { name: t('templates:addNewAttachment'), url: '#' },
              ]}
            />
            <TemplateAttachmentFormHandler
              attachmentTemplates={attachmentTemplates}
            />
          </>
        )}
      </DataWrapper>
    </>
  );
};
