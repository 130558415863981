import { forwardRef, useState } from 'react';

import { EyeIcon } from 'icons';
import { TextInput } from 'components/shared';
import { Props as TextInputProps } from 'components/shared/Inputs/TextInput';

export const TextInputPassword = forwardRef<HTMLInputElement, TextInputProps>(
  (TextInputMantineProps, ref) => {
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    return (
      <TextInput
        ref={ref}
        {...TextInputMantineProps}
        type={isPasswordShown ? 'text' : 'password'}
        rightIcon={
          <EyeIcon color={isPasswordShown ? 'primary' : 'primary.9'} />
        }
        rightIconAction={() => {
          setIsPasswordShown(isShown => !isShown);
        }}
      />
    );
  },
);
