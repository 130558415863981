import { LOGIN_TYPE } from 'consts';
import { LoginMethodType } from 'types';

export const getLoginMethodsObject = (
  loginMethodsArray: LoginMethodType[] = [],
) =>
  Object.keys(LOGIN_TYPE).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: loginMethodsArray.includes(LOGIN_TYPE[key]),
    }),
    {} as { [method in LoginMethodType]: boolean },
  );
