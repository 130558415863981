import { useTranslation } from 'react-i18next';
import { Button, Text } from '@mantine/core';

import { WomanPointingTableIcon } from 'icons';
import { EMAILS } from 'consts';

import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;

export const NoAccessModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('noAccess');

  return (
    <Modal
      sx={{ textAlign: 'center' }}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <WomanPointingTableIcon
        sx={theme => ({ marginBottom: theme.other.spacing(2) })}
        color="primary"
      />
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(1.5) })}
        weight={600}
      >
        {t('noAccess:dontHaveAccessToThisFeature')}
      </Text>
      <Text
        sx={theme => ({
          maxWidth: 336,
          margin: `0 auto ${theme.other.spacing(2)}px`,
        })}
        size="md"
        weight={400}
      >
        {t('noAccess:contactCustomerServiceToGetFeature')}
      </Text>
      <Button component="a" href={`mailto:${EMAILS.pergaminHelp}`}>
        {t('noAccess:contactCustomerService')}
      </Button>
    </Modal>
  );
};
