import { Box } from '@mantine/core';

import { CONFIG } from 'legacy/TemplateCreatorEditor/consts';

export const title = {
  onKeyDown: (e, editor, next) => {
    if (
      e.key === 'Enter' &&
      editor.value.blocks.some(block => block.type === 'title')
    ) {
      e.preventDefault();

      editor.moveToEndOfNode(editor.value.endBlock).insertBlock('paragraph');

      return true;
    }

    return next();
  },
  renderNode: ({ attributes, node, children }, editor, next) => {
    if (node.type === 'title') {
      return (
        <Box
          {...attributes}
          sx={{
            fontSize: CONFIG.title.fontSize,
            textAlign: CONFIG.title.textAlign,
          }}
        >
          {children}
        </Box>
      );
    }

    return next();
  },
  commands: {
    insertTitle: editor => {
      editor.setBlocks({ type: 'title', data: {} });
      editor.focus();
      editor.moveToEnd();
    },
  },
};
