import { ThemeComponentType } from '../types';

export const Anchor: ThemeComponentType = {
  styles: theme => ({
    root: {
      fontWeight: 500,
      color: theme.fn.primaryColor(),
      fontSize: theme.fontSizes.md,
      '&:focus-visible': {
        textDecoration: 'underline',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:not(:hover)': {
        textDecoration: 'none',
      },
    },
  }),
};
