import { Button, packSx } from '@mantine/core';

export const VariableButton = ({
  sx,
  labelStyles,
  children,
  ...buttonProps
}) => (
  <Button
    sx={[
      theme => ({
        maxWidth: '100%',
        height: 'auto',
        padding: theme.other.spacing(0.75, 2),
        fontSize: theme.fontSizes.lg,
        fontWeight: 500,
        boxShadow: theme.shadows.xs,
      }),
      ...packSx(sx),
    ]}
    styles={{
      label: {
        display: 'inline',
        height: 'fit-content',
        overflow: 'hidden',
        whiteSpace: 'normal',
        textOverflow: 'ellipsis',
        ...labelStyles,
      },
    }}
    variant="light"
    color="blue"
    title={typeof children === 'string' ? children : undefined}
    {...buttonProps}
  >
    {children}
  </Button>
);
