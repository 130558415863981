import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';

import { STORAGE_KEYS, URLS } from 'consts';

export const useLogout = () => {
  const navigate = useNavigate();
  const [, setToken] = useLocalStorage({
    key: STORAGE_KEYS.token,
  });

  return () => {
    setToken('null');
    navigate(URLS.home);
  };
};
