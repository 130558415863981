import { DefaultMantineColor, MantineTheme } from '@mantine/core';

export const getTwoToneRoundedIconProps = ({
  color,
  backgroundColor,
  size,
}: {
  color: DefaultMantineColor;
  backgroundColor: DefaultMantineColor;
  size?: number;
}) => ({
  sx: (theme: MantineTheme) => ({
    marginRight: theme.other.spacing(1),
    flexShrink: 0,
    borderRadius: '50%',
    border: `2px solid ${theme.colors[color][theme.fn.primaryShade()]}`,
  }),
  color,
  backgroundColor,
  size,
});
