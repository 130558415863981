import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { useFormatUserDate } from 'hooks';
import { DataTypeListItemType } from 'types';
import { PaperCard } from 'components/shared';

import { DataTypeActions } from '../DataTypeActions';

type Props = {
  name: DataTypeListItemType['name'];
  updatedAt: DataTypeListItemType['updatedAt'];
};

export const DataTypePanel = ({ name, updatedAt }: Props) => {
  const { t } = useTranslation(['common', 'dataTypes']);

  const formatUserDate = useFormatUserDate();

  return (
    <PaperCard
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2.25),
          })}
          size="xl"
          weight={600}
        >
          {name}
        </Text>
        <Text size="md" weight={600}>
          {formatUserDate(updatedAt)}
        </Text>
        <Text color="gray.7" size="sm">
          {t('common:lastUpdate')}
        </Text>
      </div>
      <DataTypeActions name={name} />
    </PaperCard>
  );
};
