import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import { DeleteTemplatesRequestType } from '../types';

export const deleteTemplates = async ({
  pathParams: { organisationId, boardId },
  queryParams: { templateIds },
}: DeleteTemplatesRequestType) => {
  const query = stringifyUrlQuery({
    templateIds,
  });

  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates${query}`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
