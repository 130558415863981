import { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Button, Text } from '@mantine/core';
import { UseFormWatch } from 'react-hook-form';

import { useIsLgBreakpoint } from 'hooks';
import { URLS } from 'consts';

import { TextInput, TextInputPasswordWithStrength } from 'components/shared';
import { ContentHeader } from 'components/NonLoggedLayout';
import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

import {
  FormFieldErrorsType,
  RegisterWithCustomOnChangeType,
  RegistrationValuesType,
} from '../types';

type Props = {
  onSubmit: () => void;
  passwordInstanceRef: Ref<InstanceRefObjectType>;
  customRegister: RegisterWithCustomOnChangeType;
  watch: UseFormWatch<RegistrationValuesType>;
  errors: FormFieldErrorsType;
};

export const Step1 = ({
  onSubmit,
  passwordInstanceRef,
  customRegister,
  watch,
  errors,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <div>
      <ContentHeader
        preTitle={
          !isLgBreakpoint
            ? t('user:stepOfSteps', {
                step: 1,
                maxSteps: 3,
              })
            : undefined
        }
        title={t('user:createAccount')}
        description={
          isLgBreakpoint ? t('user:createAccountDescription') : undefined
        }
      />
      <TextInput
        id="email"
        label={t('common:email')}
        placeholder={t('common:emailPlaceholder')}
        autoFocus
        error={errors.email?.message}
        {...customRegister('email')}
      />
      <TextInputPasswordWithStrength
        sx={theme => ({ marginBottom: theme.other.spacing(3) })}
        instanceRef={passwordInstanceRef}
        id="password"
        autoComplete="off"
        label={t('user:newPassword')}
        placeholder={t('user:newPasswordPlaceholder')}
        error={errors.passwordConfirmation?.message}
        value={watch('password')}
        {...customRegister('password')}
      />
      <Button
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
          fontWeight: 600,
        })}
        fullWidth
        size="lg"
        onClick={onSubmit}
      >
        {t('common:continue')}
      </Button>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Text span size="md">
          {t('user:haveAccountAlready')}
        </Text>{' '}
        <Anchor component={Link} to={URLS.home} replace>
          {t('user:signIn')}
        </Anchor>
      </Box>
    </div>
  );
};
