import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/shared';

import { TemplateDescriptionField } from '../TemplateDescriptionField';

type Props = {
  error: string | undefined;
  nameInputProps: UseFormRegisterReturn;
};

export const Name = ({ error, nameInputProps }: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:templateName')}
      description={t('templates:templateNameDescription')}
      error={error}
    >
      <TextInput
        placeholder={t('templates:templateName')}
        withErrorWrapperAlwaysEnabled={false}
        {...nameInputProps}
      />
    </TemplateDescriptionField>
  );
};
