import { ComponentProps, ReactNode, useEffect, useRef } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';

import { NotFound } from 'components/shared/NotFound';

type NotFoundProps = ComponentProps<typeof NotFound>;

type Props<T> = {
  children: (item: T, index: number) => ReactNode;
  data: T[];
  isLoading?: boolean;
  listKey?: string | number;
  noDataHeader?: NotFoundProps['header'];
  noDataDescription?: NotFoundProps['description'];
  noDataLink?: NotFoundProps['link'];
  noDataButtonText?: NotFoundProps['buttonText'];
  onNoDataButtonClick?: NotFoundProps['onActionButtonClick'];
  noDataButtonColor?: NotFoundProps['actionButtonColor'];
  scrollTopTriggerKey?: any;
};

const PREVENT_SCROLL_TOP_VALUE = 'PREVENT_SCROLL_TOP_VALUE';

export const DataList = <T extends unknown>({
  children,
  data,
  isLoading = false,
  listKey = 'id',
  noDataHeader,
  noDataDescription,
  noDataButtonText,
  noDataLink,
  onNoDataButtonClick,
  noDataButtonColor,
  scrollTopTriggerKey = PREVENT_SCROLL_TOP_VALUE,
}: Props<T>) => {
  const listWrapperRef = useRef<HTMLDivElement>(null);

  const hasData = data.length > 0;

  useEffect(() => {
    if (
      scrollTopTriggerKey !== PREVENT_SCROLL_TOP_VALUE &&
      listWrapperRef.current
    ) {
      listWrapperRef.current.scrollTop = 0;
    }
  }, [scrollTopTriggerKey]);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        ref={listWrapperRef}
      >
        <LoadingOverlay
          sx={{
            '.mantine-Overlay-root': {
              backgroundColor: hasData ? undefined : 'transparent',
            },
          }}
          visible={isLoading}
          zIndex={1}
        />
        {hasData ? (
          <ul>
            {data.map((item, index) => {
              const getKey = () => {
                const key = listKey as keyof T;

                switch (typeof item[key]) {
                  case 'string':
                    return item[key] as string;
                  case 'number':
                    return item[key] as number;
                  default:
                    return index;
                }
              };

              return (
                <Box
                  sx={theme => ({
                    marginBottom:
                      index === data.length - 1
                        ? undefined
                        : theme.other.spacing(1),
                  })}
                  key={getKey()}
                  component="li"
                >
                  {children(item, index)}
                </Box>
              );
            })}
          </ul>
        ) : (
          <NotFound
            sx={{
              visibility: isLoading ? 'hidden' : undefined,
            }}
            header={noDataHeader}
            description={noDataDescription}
            actionButtonColor={noDataButtonColor}
            buttonText={noDataButtonText}
            link={noDataLink}
            onActionButtonClick={onNoDataButtonClick}
          />
        )}
      </Box>
    </Box>
  );
};
