import { useLocation } from 'react-router-dom';

import { LinkType } from 'components/Layout/Navbar/types';
import { getIsMatch } from 'components/Layout/Navbar/helpers';

export const useGetIsMatch = () => {
  const { pathname } = useLocation();

  return (link: LinkType) => getIsMatch(link, pathname);
};
