import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ArrowRightIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m20 12-5.12 5.12M20 12l-5.12-5.12M20 12H4"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
