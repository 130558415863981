import { useState } from 'react';
import { useToggle } from '@mantine/hooks';

import { DataSettingsType } from '../types';

export const useData = () => {
  const [isDataEditable, toggleIsDataEditable] = useToggle([false, true]);
  const [dataSettings, setDataSettings] = useState<DataSettingsType>([]);
  const [isDataSaving, setIsDataSaving] = useState(false);

  return {
    isDataEditable,
    toggleIsDataEditable,
    dataSettings,
    setDataSettings,
    isDataSaving,
    setIsDataSaving,
  };
};
