import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { getV1OriginUrl } from 'helpers';
import { useGetV1Url } from 'hooks';
import { BASENAME, DYNAMIC_URLS, URLS } from 'consts';

const REDIRECT_TO_URL_CHUNK = '?redirectTo=';

export const useRedirectToV1IfNeeded = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const matchRoute = useMatch(
    window.location.pathname.replace(`/${BASENAME}`, ''),
  );
  const matchInvitationUrl = useMatch(
    DYNAMIC_URLS.invitation({ invitationId: ':invitationId' }),
  );

  const getV1Url = useGetV1Url();

  return () => {
    if (matchInvitationUrl) {
      window.location.assign(
        getV1Url(
          'invitation',
          {
            invitationId: matchInvitationUrl.params.matchInvitationUrl,
          },
          window.location.search,
        ),
      );
    } else if (!matchRoute || matchRoute?.pathname === URLS.home) {
      const redirectToUrlSegment = window.location.search.startsWith(
        REDIRECT_TO_URL_CHUNK,
      )
        ? window.location.search.split(REDIRECT_TO_URL_CHUNK)[1]
        : null;

      window.location.assign(
        redirectToUrlSegment
          ? `${getV1OriginUrl()}/${language}/${redirectToUrlSegment}`
          : getV1Url('contracts'),
      );
    }
  };
};
