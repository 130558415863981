import { TransactionDataStreamType } from 'types';
import { Error } from 'components/shared/Error';

import { DataStreamTemplates } from './DataStreamTemplates';

type Props = {
  dataStream: TransactionDataStreamType | null;
};

export const DataStreamPanel = ({ dataStream }: Props) =>
  dataStream ? (
    <DataStreamTemplates templates={dataStream.templates} />
  ) : (
    <Error />
  );
