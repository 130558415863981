import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { useGetUserNotifications } from 'api/account';
import { getHasHateoasAccess } from 'helpers';
import { DataWrapper } from 'components/shared';

import { NotificationModal } from './NotificationModal';
import { NotificationList } from './NotificationList';

export const Notifications = () => {
  const { t } = useTranslation(['common', 'notifications', 'user']);

  const {
    data: userNotificationsData,
    isError: isUserNotificationsError,
    isLoading: isUserNotificationsLoading,
  } = useGetUserNotifications();

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const hasUpdateAccess = getHasHateoasAccess(
    'update',
    userNotificationsData?.__links,
  );

  return (
    <div>
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.other.spacing(2.5),
        })}
      >
        <Text weight={600}>{t('notifications:notifications')}</Text>
        {hasUpdateAccess && (
          <UnstyledButton
            sx={theme => ({
              fontWeight: 600,
              color: theme.fn.primaryColor(),
            })}
            onClick={() => {
              setIsNotificationsModalOpen(true);
            }}
          >
            {t('common:manage')}
          </UnstyledButton>
        )}
      </Box>
      {hasUpdateAccess && (
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          size="md"
          weight={400}
        >
          {t('notifications:notificationsManagementDescription')}
        </Text>
      )}
      <DataWrapper
        data={userNotificationsData}
        isError={isUserNotificationsError}
        isLoading={isUserNotificationsLoading}
      >
        {({ data: notifications }) => (
          <>
            <NotificationList notifications={notifications} />
            <NotificationModal
              isOpen={isNotificationsModalOpen}
              onClose={() => {
                setIsNotificationsModalOpen(false);
              }}
              notifications={notifications}
            />
          </>
        )}
      </DataWrapper>
    </div>
  );
};
