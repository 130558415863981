import { useMutation } from '@tanstack/react-query';

import { HTTP_HEADERS } from 'consts';
import { getBearerTokenStr } from 'helpers';

import { deleteAuthLogout } from '../requests';

export const useDeleteAuthLogout = () =>
  useMutation({
    mutationFn: (token: string) =>
      deleteAuthLogout({
        headers: {
          [HTTP_HEADERS.authorization]: getBearerTokenStr(token),
        },
      }),
  });
