import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangeValueType } from 'types';
import {
  DatePickerInput,
  NumberInput,
  TextInputReactRouterSearch,
} from 'components/shared';

type Props = {
  idFilter: number | null;
  setIdFilter: Dispatch<SetStateAction<number | null>>;
  updatedAtRange:
    | [DateRangeValueType]
    | [DateRangeValueType, DateRangeValueType];
  setUpdatedAtRange: Dispatch<
    SetStateAction<
      [DateRangeValueType] | [DateRangeValueType, DateRangeValueType]
    >
  >;
};

export const TemplateFiltersRow = ({
  idFilter,
  setIdFilter,
  updatedAtRange,
  setUpdatedAtRange,
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <tr>
      <td />
      <td>
        <TextInputReactRouterSearch
          placeholder={t('templates:name')}
          icon={null}
        />
      </td>
      <td>
        <NumberInput
          placeholder={t('templates:id')}
          withErrorWrapperAlwaysEnabled={false}
          value={idFilter || undefined}
          onChange={value => {
            setIdFilter(value as number);
          }}
        />
      </td>
      <td>
        <DatePickerInput
          withErrorWrapperAlwaysEnabled={false}
          date={updatedAtRange}
          setDate={setUpdatedAtRange}
        />
      </td>
      <td />
    </tr>
  );
};
