import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { postCustomizationsDocumentPaper } from '../requests';
import { PostCustomizationsDocumentPaperRequestType } from '../types';

export const usePostCustomizationsDocumentPaper = ({
  pathParams: { templateId },
}: {
  pathParams: Omit<
    PostCustomizationsDocumentPaperRequestType['pathParams'],
    'organisationId' | 'boardId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: ({
      formData,
    }: {
      formData: PostCustomizationsDocumentPaperRequestType['formData'];
    }) =>
      postCustomizationsDocumentPaper({
        pathParams: {
          organisationId,
          boardId,
          templateId,
        },
        formData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template, { templateId: Number(templateId) }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId, boardId }],
      });
    },
  });
};
