import { apiAuthClient } from 'api/helpers';

import {
  PostDataStreamRequestType,
  PostDataStreamResponseType,
} from '../types';

export const postDataStream = async ({
  pathParams: { organisationId },
  bodyParams,
}: PostDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams`;

  const { data } = await apiAuthClient.post<PostDataStreamResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
