import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const CopyIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.429 6.286H6.286c-.631 0-1.143.511-1.143 1.143v11.428c0 .631.512 1.143 1.143 1.143h9.143c.63 0 1.143-.512 1.143-1.143v-1.143m1.142 0H8.571a1.143 1.143 0 0 1-1.142-1.143V5.143C7.429 4.512 7.94 4 8.57 4h6.858l3.428 2.286V16.57c0 .632-.511 1.143-1.143 1.143Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
