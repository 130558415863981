import { useGetOrganisation } from '../api/organisations';
import { useGetCurrentOrganisationId } from './useGetCurrentOrganisationId';

export const useGetIsUserAdminInCurrentOrganisation = () => {
  const currentOrganisationId = useGetCurrentOrganisationId();
  const { data } = useGetOrganisation({
    pathParams: { organisationId: currentOrganisationId },
  });
  return data!.isAdministrator;
};
