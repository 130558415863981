import { apiAuthClient } from 'api/helpers';
import { PatchDocumentInvitationTypesRequestType } from '../types';

export const patchDocumentInvitation = async ({
  pathParams: { invitationId },
  bodyParams,
}: PatchDocumentInvitationTypesRequestType) => {
  const url = `/api/v2/document-invitations/${invitationId}`;
  const { data } = await apiAuthClient.patch(url, bodyParams);

  return data.data;
};
