import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataStreamListItemType } from 'types';
import { DeleteIcon, EditIcon } from 'icons';
import { DYNAMIC_URLS } from 'consts';
import { Actions } from 'components/shared';

import { DataStreamDeleteModal } from '../DataStreamDeleteModal';

type Props = {
  id: DataStreamListItemType['id'];
  name: DataStreamListItemType['name'];
};

export const DataStreamActions = ({ id, name }: Props) => {
  const { t } = useTranslation('common');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <Actions
        items={[
          {
            name: t('common:edit'),
            icon: <EditIcon />,
            colorDesktop: 'blue',
            to: DYNAMIC_URLS.dataStreamEdit({ dataStreamId: id }),
          },
          {
            name: t('common:delete'),
            icon: <DeleteIcon />,
            colorDesktop: 'red',
            colorMobile: 'red',
            action: () => {
              setIsDeleteModalOpen(true);
            },
          },
        ]}
      />
      <DataStreamDeleteModal
        id={id}
        name={name}
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
      />
    </>
  );
};
