import Joyride from 'react-joyride';
import { useMantineTheme } from '@mantine/core';

import { GuideTourStepType } from './types';
import { TooltipComponent } from './TooltipComponent';

type Props = {
  steps: GuideTourStepType[];
  onClose: () => void;
};

export const GuideTour = ({ steps, onClose }: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <Joyride
      styles={{
        overlay: {
          backgroundColor: mantineTheme.colors[mantineTheme.primaryColor][9],
          opacity: 0.4,
        },
      }}
      steps={steps}
      floaterProps={{ hideArrow: true }}
      tooltipComponent={TooltipComponent}
      callback={({ action }) => {
        if (action === 'skip') {
          onClose();
        }
      }}
    />
  );
};
