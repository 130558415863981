import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const CodeIcon = ({ size = 16, sx, ...props }: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#1E1C49"
      d="M2.862 6.472.195 3.805a.667.667 0 0 1 0-.943L2.862.195a.667.667 0 0 1 .943.944L1.609 3.334l2.196 2.195a.667.667 0 1 1-.943.943Zm3.333 0a.667.667 0 0 0 .943 0l2.667-2.667a.667.667 0 0 0 0-.943L7.138.195a.667.667 0 0 0-.943.944L8.39 3.334 6.195 5.529a.667.667 0 0 0 0 .943Zm8.471-5.138h-2a.667.667 0 1 0 0 1.333h2v12h-12V9.333a.667.667 0 0 0-1.333 0v5.334A1.333 1.333 0 0 0 2.667 16h12A1.333 1.333 0 0 0 16 14.667v-12a1.333 1.333 0 0 0-1.334-1.333Z"
    />
  </Box>
);
