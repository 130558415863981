import { Accordion } from './Accordion';
import { ActionIcon } from './ActionIcon';
import { Alert } from './Alert';
import { Anchor } from './Anchor';
import { Avatar } from './Avatar';
import { Badge } from './Badge';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Checkbox } from './Checkbox';
import { Divider } from './Divider';
import { Dropzone } from './Dropzone';
import { Input } from './Input';
import { InputWrapper } from './InputWrapper';
import { Menu } from './Menu';
import { Modal } from './Modal';
import { NavLink } from './NavLink';
import { Navbar } from './Navbar';
import { Notification } from './Notification';
import { NumberInput } from './NumberInput';
import { Pagination } from './Pagination';
import { Paper } from './Paper';
import { Popover } from './Popover';
import { Radio } from './Radio';
import { ScrollArea } from './ScrollArea';
import { Select } from './Select';
import { Tabs } from './Tabs';
import { Text } from './Text';
import { Textarea } from './Textarea';
import { Tooltip } from './Tooltip';
import { UnstyledButton } from './UnstyledButton';

export const components = {
  Accordion,
  ActionIcon,
  Alert,
  Anchor,
  Avatar,
  Badge,
  Button,
  Calendar,
  Checkbox,
  DatePicker: Calendar,
  Divider,
  Dropzone,
  Input,
  InputWrapper,
  Menu,
  Modal,
  NavLink,
  Navbar,
  Notification,
  NumberInput,
  Pagination,
  Paper,
  Popover,
  Radio,
  RangeCalendar: Calendar,
  ScrollArea,
  Select,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  UnstyledButton,
};
