import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileWithPath } from '@mantine/dropzone';

import { usePostTechnicalUserGenerateCertificate } from 'api/technicalUsers';
import { TechnicalUserType } from 'types';
import { useServerErrors } from 'hooks';
import { Modal } from 'components/shared';

import {
  useGetCertificateRenderData,
  useGetTechnicalUsersData,
} from '../../hooks';
import { CertificateFileErrorsMapType } from '../../types';
import { ValidityType } from './types';
import { CertificateExpiration } from '../CertificateExpiration';
import { FreshCertificate } from '../FreshCertificate';
import { GenerateNewCertificate } from '../GenerateNewCertificate';
import { CertificateServerError } from '../CertificateServerError';
import { CertificateValidity } from './CertificateValidity';

const MAX_CERTIFICATE_FILE_SIZE_IN_KB = 16;

const FILE_EXTENSION = 'csr';

const DEFAULT_VALIDITY_VALUE = 30;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  hasCreateCertificateAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  expiresAt?: string;
};

export const CertificateModal = ({
  hasCreateCertificateAccess,
  isOpen,
  onClose,
  technicalUserId,
  expiresAt,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);
  const getCertificateRenderData = useGetCertificateRenderData();

  const [generateNewCertificateMode, setGenerateNewCertificateMode] =
    useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [certificateKey, setCertificateKey] = useState('');
  const [validity, setValidity] = useState<ValidityType>(
    DEFAULT_VALIDITY_VALUE,
  );

  const { getGeneralServerError, getServerFieldError } = useServerErrors();

  const { isRefetching: isTechnicalUsersRefetching } =
    useGetTechnicalUsersData();

  const {
    mutateAsync: generateCertificate,
    isLoading: isGeneratingCertificate,
  } = usePostTechnicalUserGenerateCertificate({
    pathParams: {
      technicalUserId: String(technicalUserId),
    },
  });

  const handleClose = () => {
    onClose();
    setGenerateNewCertificateMode(false);
    setFile(null);
    setFileErrorMessage('');
    setServerErrorMessage('');
    setCertificateKey('');
    setValidity(DEFAULT_VALIDITY_VALUE);
  };

  const handleCreateCertificate = async () => {
    try {
      const formData = new FormData();

      formData.append(FILE_EXTENSION, file as FileWithPath);
      formData.append('expiration', String(validity));

      const generatedKey = await generateCertificate({ formData });

      setCertificateKey(generatedKey);
      setGenerateNewCertificateMode(false);
      setValidity(DEFAULT_VALIDITY_VALUE);
    } catch (error) {
      setFileErrorMessage(getServerFieldError(error, FILE_EXTENSION));
      setServerErrorMessage(getGeneralServerError(error));
    }
  };

  const errorsMap: CertificateFileErrorsMapType = {
    'file-invalid-type': t('api:certificateModal.errorInvalidType', {
      extensionLarge: FILE_EXTENSION.toUpperCase(),
      extension: FILE_EXTENSION,
    }),
    'file-too-large': t('common:formErrors.errorTooLarge', {
      sizeInKB: MAX_CERTIFICATE_FILE_SIZE_IN_KB,
    }),
  };

  const {
    hasExpiredCertificate,
    hasNotExpiredCertificate,
    hasFreshCertificate,
    hasCertificate,
    formattedExpirationDate,
  } = getCertificateRenderData({ certificateKey, expiresAt });

  const getModalContent = () => {
    const generateNewCertificateView = (
      <>
        <GenerateNewCertificate
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          file={file}
          setFile={setFile}
          fileErrorMessage={fileErrorMessage}
          setFileErrorMessage={setFileErrorMessage}
          errorsMap={errorsMap}
          filesAccept={[`.${FILE_EXTENSION}`]}
          filesAcceptDescription={
            <>
              {t('api:certificateModal.uploadRequiredExtension')} .
              {FILE_EXTENSION}
            </>
          }
        />
        <CertificateValidity validity={validity} setValidity={setValidity} />
      </>
    );

    switch (true) {
      case Boolean(serverErrorMessage):
        return <CertificateServerError error={serverErrorMessage} />;
      case generateNewCertificateMode:
        return generateNewCertificateView;
      case hasFreshCertificate:
        return (
          <FreshCertificate
            certificateKey={certificateKey}
            certificateName={file!.name.replace(/.csr$/, '.pem') as string}
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      case hasNotExpiredCertificate:
        return (
          <CertificateExpiration
            hasExpired={false}
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      case hasExpiredCertificate:
        return (
          <CertificateExpiration
            hasExpired
            formattedExpirationDate={formattedExpirationDate as string}
          />
        );
      default:
        return generateNewCertificateView;
    }
  };

  const getModalChangingProps = () => {
    if (hasCertificate && !generateNewCertificateMode) {
      return {
        title: t('api:certificateModal.headerCertificate'),
        defaultButtonAction: handleClose,
        defaultButtonText: t('common:close'),
        defaultButtonDisabled: false,
        primaryButtonAction: () => {
          setGenerateNewCertificateMode(true);
          setFile(null);
          setCertificateKey('');
        },
        primaryButtonText: t('api:certificateModal.generateNew'),
      };
    }

    return {
      title: t('api:certificateModal.headerCreating'),
      defaultButtonAction: handleClose,
      primaryButtonDisabled: Boolean(!file),
      ...(hasCreateCertificateAccess && {
        primaryButtonAction: handleCreateCertificate,
        primaryButtonText: t('api:certificateModal.createCertificate'),
      }),
    };
  };

  return (
    <Modal
      {...getModalChangingProps()}
      isOpen={isOpen}
      size="md"
      onClose={handleClose}
      fullLoadingText={t('api:certificateModal.creatingCertificate')}
      isFullLoading={isGeneratingCertificate || isTechnicalUsersRefetching}
      primaryButtonColor="green"
    >
      {getModalContent()}
    </Modal>
  );
};
