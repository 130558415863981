import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { I18NextProvider } from './I18NextProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { QueryClientProvider } from './QueryClientProvider';
import { StylesProvider } from './StylesProvider';
import { UserScalable } from './UserScalable';

type Props = {
  children: ReactNode;
};

export const Providers = ({ children }: Props) => (
  <QueryClientProvider>
    <StylesProvider>
      <I18NextProvider>
        <NotificationsProvider>
          <HelmetProvider>
            <UserScalable />
            {children}
          </HelmetProvider>
        </NotificationsProvider>
      </I18NextProvider>
    </StylesProvider>
  </QueryClientProvider>
);
