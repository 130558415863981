import { ReactNode } from 'react';
import { Page, Text, Font } from '@react-pdf/renderer';

import { convertPxToPoint } from '../helpers';
import { MarginType } from '../../types';

Font.registerHyphenationCallback(word => [word]);

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: 'bold',
    },
  ],
});

type Props = {
  children: ReactNode;
  padding: MarginType;
};

export const DocumentPage = ({ children, padding }: Props) => (
  <Page
    wrap
    style={{
      flexDirection: 'column',
      fontFamily: 'Roboto',
      fontSize: 10,
      lineHeight: 1.5,
      margin: 0,
      ...(padding
        ? {
            paddingTop: padding.top || 0,
            paddingRight: padding.right || 0,
            paddingBottom: padding.bottom || 0,
            paddingLeft: padding.left || 0,
          }
        : { padding: 48 }),
    }}
  >
    <Text
      render={({ pageNumber, totalPages }) => `${pageNumber} z ${totalPages}`}
      fixed
      style={{
        position: 'absolute',
        textAlign: 'right',
        bottom: 20,
        right: 24,
        left: 0,
        fontSize: convertPxToPoint(12),
      }}
    />
    {children}
  </Page>
);
