import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const SaveIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.57143 20V16.5714C8.57143 15.9402 9.0831 15.4286 9.71429 15.4286H14.2857C14.9169 15.4286 15.4286 15.9402 15.4286 16.5714V20M18.8571 20H5.14286C4.51168 20 4 19.4883 4 18.8571V5.14286C4 4.51168 4.51167 4 5.14286 4H14.9552C15.2583 4 15.549 4.12041 15.7633 4.33474L19.6653 8.23669C19.8796 8.45102 20 8.74171 20 9.04482V18.8571C20 19.4883 19.4883 20 18.8571 20Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Box>
  );
};
