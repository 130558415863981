import { useTranslation } from 'react-i18next';

import { useShowGeneralErrorNotification } from 'hooks';
import { LanguageKeyType } from 'types';

export const useChangeTranslations = () => {
  const { i18n } = useTranslation();

  const showGeneralErrorNotification = useShowGeneralErrorNotification();

  return async (language: LanguageKeyType) => {
    try {
      await i18n.changeLanguage(language);
    } catch {
      showGeneralErrorNotification();
    }
  };
};
