import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const ListIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="m1.818 20-.53-.53a.75.75 0 0 0 .53 1.28V20ZM3.273 4h.75v-.75h-.75V4Zm5.09 8.023H22.91v-1.5H8.364v1.5Zm0-5.818H22.91v-1.5H8.364v1.5Zm0 11.636H22.91v-1.5H8.364v1.5ZM5.456 19.25H1.818v1.5h3.637v-1.5Zm-3.107 1.28 2.608-2.608-1.06-1.06-2.608 2.608 1.06 1.06Zm1.35-5.644h-.425v1.5h.426v-1.5Zm-.425 0a2.205 2.205 0 0 0-2.205 2.205h1.5c0-.39.316-.705.705-.705v-1.5Zm2.204 1.779c0-.982-.796-1.779-1.778-1.779v1.5c.154 0 .278.125.278.279h1.5Zm-.52 1.258c.333-.334.52-.786.52-1.258h-1.5c0 .074-.03.145-.081.197l1.06 1.06ZM1.09 9.112h4.364v-1.5H1.09v1.5Zm2.932-.75V4h-1.5v4.364h1.5Zm-.75-5.113H1.09v1.5h2.182v-1.5Z"
      />
    </Box>
  );
};
