import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { deleteCustomizationsDocument } from '../requests';

export const useDeleteCustomizationsDocument = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: () =>
      deleteCustomizationsDocument({
        pathParams: { organisationId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.organisationCustomizationsDocument,
          { organisationId },
        ],
      });

      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template],
      });
    },
  });
};
