import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box } from '@mantine/core';

import { InfoIcon } from 'icons';

import { SectionDivider } from '../SectionDivider';
import { HistoryControlSection } from './HistoryControlSection';
import { TextBlockTypesSection } from './TextTypesSection';
import { TextSizeSection } from './TextSizeSection';
import { InterlineSection } from './InterlineSection';
import { TextDecoratorsSection } from './TextDecoratorsSection';
import { TextAlignmentSection } from './TextAlignmentSection';

import { STATIC_URLS } from 'consts';

const SECTION_COMPONENTS = [
  HistoryControlSection,
  TextBlockTypesSection,
  TextSizeSection,
  InterlineSection,
  TextDecoratorsSection,
  TextAlignmentSection,
];

export const TopSection = () => {
  const { t } = useTranslation('templates');

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: theme.other.spacing(2),
        padding: theme.other.spacing(2),
        borderBottom: `1px solid ${theme.colors.blue[2]}`,
      })}
    >
      {SECTION_COMPONENTS.map(Component => (
        <Fragment key={Component.name}>
          <Component />
          <SectionDivider />
        </Fragment>
      ))}
      <Anchor
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.other.spacing(0.5),
          fontSize: theme.fontSizes.lg,
        })}
        href={STATIC_URLS.templateCreator}
        target="_blank"
      >
        <InfoIcon color="primary" />
        {t('templates:toolbar.guide')}
      </Anchor>
    </Box>
  );
};
