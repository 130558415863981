import { FC } from 'react';
import { Box, Text } from '@mantine/core';

import { IconPropsType } from 'icons/types';

type Props = {
  count: number;
  label: string;
  icon: FC<IconPropsType>;
  iconColor: string;
};

export const InfoStatus = ({ label, count, icon: Icon, iconColor }: Props) =>
  count > 0 ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon color={iconColor} />
      <Text size="md" ml="sm">
        {label} ({count})
      </Text>
    </Box>
  ) : null;
