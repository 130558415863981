import { Box } from '@mantine/core';

import { DataStreamTemplateType } from 'types';

import { PercentageContainer } from 'components/shared';

import { DataStreamTemplate } from '../DataStreamTemplate';

type Props = {
  templates: DataStreamTemplateType[];
};

export const DataStreamTemplates = ({ templates }: Props) => (
  <PercentageContainer component="ul">
    {templates.map(
      ({
        templateId,
        templateName,
        documentName,
        documentExternalId,
        documentReference,
        documentContent,
        isBlocked,
      }) => (
        <Box
          key={templateId}
          sx={theme => ({
            marginBottom: theme.other.spacing(1),
          })}
          component="li"
        >
          <DataStreamTemplate
            templateName={templateName}
            documentName={documentName}
            documentExternalId={documentExternalId}
            documentReference={documentReference}
            documentContent={documentContent}
            isBlocked={isBlocked}
          />
        </Box>
      ),
    )}
  </PercentageContainer>
);
