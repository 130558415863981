import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Tooltip } from '@mantine/core';

import { CONFIG } from 'legacy/TemplateCreatorEditor/consts';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

export const TextSizeSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isExpanded, isFocused } = editor.value.selection;
  const resizedFontSizeData = editor.resizedFontSizeData();
  const activeBlockType = editor.activeBlockType();

  const getFontSizeSelectedValue = () => {
    if (resizedFontSizeData) {
      return resizedFontSizeData;
    }

    switch (activeBlockType) {
      case 'paragraph':
        return CONFIG.fontSize;
      case 'header': {
        return CONFIG.header.fontSize;
      }
      case 'title':
        return CONFIG.title.fontSize;
      default:
        return '';
    }
  };
  const fontSizeSelectedValue = getFontSizeSelectedValue();
  const availableFontSizesToChange = [
    ...new Set([
      fontSizeSelectedValue,
      ...[...Array(30)].map((_, index) => (index + 4) * 2),
    ]),
  ].sort((a, b) => a - b);

  const disabled = (!isFocused && !isDropdownOpen) || !isExpanded;

  return (
    <Tooltip disabled label={t('templates:selectTextToUse')}>
      <span>
        <Select
          sx={theme => ({
            maxWidth: 79,
            input: {
              fontSize: theme.fontSizes.lg,
              fontWeight: 500,
            },
          })}
          disabled={disabled}
          variant="unstyled"
          value={String(fontSizeSelectedValue)}
          error={null}
          data={[
            ...availableFontSizesToChange.map(size => ({
              value: String(size),
              label: String(size),
            })),
          ]}
          onChange={value => {
            if (isExpanded) {
              editor.toggleResize(Number(value));
            } else {
              alert('Należy zaznaczyć tekst');
            }
          }}
          onDropdownOpen={() => {
            setIsDropdownOpen(true);
          }}
          onDropdownClose={() => {
            setIsDropdownOpen(false);
          }}
        />
      </span>
    </Tooltip>
  );
};
