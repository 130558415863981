import { useTranslation } from 'react-i18next';
import { Anchor, Text, Tooltip } from '@mantine/core';

import { useIsLgBreakpoint } from 'hooks';

import { EmailSentTooltipLabel, TextLoader } from 'components/shared';

type Props = {
  isLoading: boolean;
  resendCode: () => void;
};

export const LoginOTPResendCode = ({ isLoading, resendCode }: Props) => {
  const { t } = useTranslation('user');

  const isLgBreakpoint = useIsLgBreakpoint();

  return isLoading ? (
    <TextLoader>{t('user:sendingOtpCode')}</TextLoader>
  ) : (
    <Text
      size="sm"
      align="center"
      sx={theme => ({
        margin: theme.other.spacing(-1, 0, 1),
      })}
    >
      {isLgBreakpoint ? (
        <Tooltip label={<EmailSentTooltipLabel />}>
          <Text span size="md">
            {t('user:codeNotArrive')}
          </Text>
        </Tooltip>
      ) : (
        t('user:codeNotArrive')
      )}{' '}
      <Anchor component="button" onClick={resendCode}>
        {t('user:sendAnother')}
      </Anchor>
    </Text>
  );
};
