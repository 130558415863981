import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const UserIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M15.43 7.429A3.43 3.43 0 0 1 12 10.857 3.43 3.43 0 1 1 12 4a3.43 3.43 0 0 1 3.43 3.429ZM17.718 20H6.282v-2.284a3.43 3.43 0 0 1 3.43-3.43h4.575a3.43 3.43 0 0 1 3.431 3.43V20Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="square"
      />
    </Box>
  );
};
