import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { useGetTransactionEntries } from 'api/transactionEntries';
import { useTransactionsFeature } from 'hooks';
import { DYNAMIC_URLS, URLS } from 'consts';
import {
  Breadcrumbs,
  DataWrapper,
  MetaTitle,
  SectionHeader,
} from 'components/shared';

import { TransactionEntriesList } from './TransactionEntriesList';

export const TransactionEntries = () => {
  const { t } = useTranslation('transactions');
  const { transactionId, transactionDataStream } = useParams() as {
    transactionId: string;
    transactionDataStream: string;
  };

  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();

  const {
    data: transactionEntries,
    isError: isTransactionEntriesError,
    isLoading: isTransactionEntriesLoading,
  } = useGetTransactionEntries(
    { pathParams: { transactionId, dataStream: transactionDataStream } },
    {
      enabled: !isLoadingTransactionsFeature && transactionsFeature,
    },
  );

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={transactionEntries}
        isError={isTransactionEntriesError || isTransactionFeatureError}
        isLoading={isTransactionEntriesLoading || isLoadingTransactionsFeature}
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {data => (
          <>
            <Box sx={theme => ({ marginBottom: theme.other.spacing(2.25) })}>
              <Breadcrumbs
                items={[
                  {
                    name: t('transactions:transactions'),
                    url: URLS.transactions,
                  },
                  {
                    name: t('transactions:transactionList'),
                    url: URLS.transactions,
                  },
                  {
                    name: transactionId,
                    url: DYNAMIC_URLS.transactionDetails({
                      transactionId,
                      transactionDataStream,
                    }),
                  },
                  {
                    name: t('transactions:activityHistory'),
                    url: '#',
                  },
                ]}
              />
              <SectionHeader>{t('transactions:activityHistory')}</SectionHeader>
            </Box>
            <TransactionEntriesList transactionEntries={data} />
          </>
        )}
      </DataWrapper>
    </>
  );
};
