import { MantineColor, MantineTheme } from '@mantine/core';

export const getPalette = (
  theme: MantineTheme,
  color: MantineColor = 'gray',
) => {
  const { gray } = theme.colors;

  switch (color) {
    case 'gray':
      return {
        background: gray[1],
        content: gray[7],
      };

    default:
      return {
        background: theme.colors[color][1],
        content: theme.colors[color][0],
      };
  }
};
