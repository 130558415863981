export const isValidNumberInitialValue = ({
  initialValue,
  minValue,
  maxValue,
}) => {
  if (initialValue === undefined) {
    return true;
  }

  if (minValue === undefined && maxValue === undefined) {
    return true;
  }

  const parsedInitialValue = Number(initialValue);
  const parsedMinValue = Number(minValue);
  const parsedMaxValue = Number(maxValue);

  if (minValue === undefined && maxValue !== undefined) {
    return parsedInitialValue <= parsedMaxValue;
  }

  if (minValue !== undefined && maxValue === undefined) {
    return parsedInitialValue >= minValue;
  }

  return (
    parsedMinValue <= parsedInitialValue && parsedInitialValue <= parsedMaxValue
  );
};
