import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

export const TextBlockTypesSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const { isFocused } = editor.value.selection;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const textTypes = [
    {
      value: 'paragraph',
      label: t('templates:toolbar.text'),
      action: editor.insertParagraph,
    },
    {
      value: 'title',
      label: t('templates:toolbar.title'),
      action: editor.insertTitle,
    },
    {
      value: 'header',
      label: t('templates:toolbar.paragraph'),
      action: editor.insertHeader,
    },
  ];

  return (
    <Select
      sx={theme => ({
        maxWidth: 153,
        input: {
          fontSize: theme.fontSizes.lg,
          fontWeight: 500,
        },
      })}
      disabled={!isFocused && !isDropdownOpen}
      variant="unstyled"
      value={editor.activeBlockType() || ''}
      error={null}
      data={textTypes.map(({ value, label }) => ({ value, label }))}
      onChange={newValue => {
        textTypes.find(({ value }) => value === newValue)?.action();
      }}
      onDropdownOpen={() => {
        setIsDropdownOpen(true);
      }}
      onDropdownClose={() => {
        setIsDropdownOpen(false);
      }}
    />
  );
};
