import { useEffect, useState } from 'react';
import { Box, Checkbox, Text } from '@mantine/core';
import { camelize } from 'humps';
import { ChevronDownIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { FieldErrors, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { EventSchema } from '../../types';
import { getErrorMessage } from '../../utils';

type Props = {
  event: EventSchema;
  register: UseFormRegister<{ [key: string]: string | boolean | undefined }>;
  formErrors: FieldErrors<{ [key: string]: string | boolean | undefined }>;
  watch: UseFormReturn<{
    [key: string]: string | boolean | undefined;
  }>['watch'];
  reset: UseFormReturn<{
    [key: string]: string | boolean | undefined;
  }>['reset'];
  clearGlobalErrorIfSet: () => void;
};

export const DocumentStatusChangeCheckbox = ({
  event,
  register,
  formErrors,
  watch,
  reset,
  clearGlobalErrorIfSet,
}: Props) => {
  const { t } = useTranslation(['api']);
  const [showChildren, setShowChildren] = useState(false);

  const documentStatusCheckedValue = watch('document_status_change');

  useEffect(() => {
    setShowChildren(Boolean(documentStatusCheckedValue));
  }, [documentStatusCheckedValue]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Checkbox
          label={t(`api:webhooks.events.${camelize(event.name)}`)}
          sx={theme => ({
            marginBottom: theme.other.spacing(0.5),
            lineHeight: 0,
          })}
          checked={Boolean(documentStatusCheckedValue)}
          onChange={e => {
            reset((prev: any) => ({
              ...prev,
              document_status_change: e.target.checked,
              draft: e.target.checked,
              sent: e.target.checked,
              in_acceptance: e.target.checked,
              in_sign: e.target.checked,
              on_hold: e.target.checked,
              rejected: e.target.checked,
              finished: e.target.checked,
            }));
            clearGlobalErrorIfSet();
          }}
        />
        <ChevronDownIcon
          onClick={() => setShowChildren(prev => !prev)}
          sx={theme => ({
            cursor: 'pointer',
            marginRight: theme.other.spacing(1),
            transform: showChildren ? 'rotate(180deg)' : 'none',
          })}
        />
      </Box>
      <Text
        size={13}
        color="#E63535"
        sx={theme => ({
          marginLeft: theme.other.spacing(4.75),
        })}
      >
        {getErrorMessage(formErrors[event.name])}
      </Text>
      {showChildren && (
        <Box
          sx={theme => ({
            marginLeft: theme.other.spacing(4.75),
            marginTop: theme.other.spacing(1),
          })}
        >
          {event.values.map(value => {
            if (value.type === 'checkbox') {
              return (
                <Checkbox
                  {...register(value.name)}
                  label={t(`api:webhooks.events.${camelize(value.name)}`)}
                  sx={theme => ({
                    marginBottom: theme.other.spacing(2),
                  })}
                  error={getErrorMessage(formErrors[value.name])}
                />
              );
            }
            return null;
          })}
        </Box>
      )}
      <hr
        style={{
          height: '0px',
          border: 'none',
          borderTop: '1px solid #CCC',
        }}
      />
    </Box>
  );
};
