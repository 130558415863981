import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const NewsletterModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { t } = useTranslation('user');

  return (
    <Modal
      title={t('user:newsletterModal.header')}
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonAction={onSubmit}
    >
      <Text size="sm">{t('user:newsletterModal.content')}</Text>
    </Modal>
  );
};
