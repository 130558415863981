import { CursorPaginatedListType } from 'types';

export const getInfinityQueryCursorNextPageParam = ({
  links,
}: CursorPaginatedListType<any>) => {
  if (links.next) {
    const nextPageSearchParams = new URL(links.next).searchParams;

    return nextPageSearchParams.get('cursor') ?? undefined;
  }

  return undefined;
};
