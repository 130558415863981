import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useGetZodSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .email({ message: t('common:formErrors.incorrectEmail') }),
    name: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    surname: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    phone: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
  });
};
