import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import {
  BoldIcon,
  ItalicIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from 'legacy/TemplateCreatorEditor/icons';

import { SectionWrapper } from '../../SectionWrapper';
import { TextModifierButton } from '../TextModifierButton';

export const TextDecoratorsSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const { isExpanded, isFocused } = editor.value.selection;

  const MODIFIERS = [
    {
      type: 'bold',
      tooltipLabel: t('templates:toolbar.bold'),
      icon: <BoldIcon />,
      isActive: isFocused && editor.isBold(),
      action: editor.toggleBold,
    },
    {
      type: 'italic',
      tooltipLabel: t('templates:toolbar.italic'),
      icon: <ItalicIcon />,
      isActive: isFocused && editor.isItalic(),
      action: editor.toggleItalic,
    },
    {
      type: 'underline',
      tooltipLabel: t('templates:toolbar.underline'),
      icon: <UnderlineIcon />,
      isActive: isFocused && editor.isUnderline(),
      action: editor.toggleUnderline,
    },
    {
      type: 'strikethrough',
      tooltipLabel: t('templates:toolbar.strikethrough'),
      icon: <StrikethroughIcon />,
      isActive: isFocused && editor.isStrikethrough(),
      action: editor.toggleStrikethrough,
    },
  ];

  return (
    <SectionWrapper>
      {MODIFIERS.map(({ type, tooltipLabel, icon, isActive, action }) => {
        const disabled = !isFocused || (!isExpanded && !isActive);

        return (
          <TextModifierButton
            sx={{ cursor: isExpanded ? undefined : 'default' }}
            key={type}
            tooltipLabel={
              disabled || !isExpanded
                ? /* t('templates:selectTextToUse') */ undefined
                : tooltipLabel
            }
            icon={icon}
            disabled={disabled}
            isActive={isActive}
            onClick={() => {
              if (isExpanded) {
                action();
              }
            }}
          />
        );
      })}
    </SectionWrapper>
  );
};
