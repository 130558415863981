import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Button, DefaultProps, packSx } from '@mantine/core';

import { URLS } from 'consts';
import { SectionHeader } from 'components/shared';

type Props = {
  hasCreateDataStreamAccess: boolean;
  onCreate: () => void;
  isCreatingDataStream: boolean;
  sx?: DefaultProps['sx'];
};

export const Header = ({
  hasCreateDataStreamAccess,
  onCreate,
  isCreatingDataStream,
  sx,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
        },
        ...packSx(sx),
      ]}
    >
      <SectionHeader
        sx={theme => ({
          marginRight: theme.other.spacing(1),
        })}
      >
        {t('dataStreams:dataStreamAddHeader')}
      </SectionHeader>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Anchor
          sx={theme => ({
            marginRight: theme.other.spacing(2),
          })}
          component={Link}
          to={URLS.dataStreams}
          onClick={e => {
            if (isCreatingDataStream) {
              e.preventDefault();
            }
          }}
        >
          <Button variant="default" disabled={isCreatingDataStream}>
            {t('common:cancel')}
          </Button>
        </Anchor>
        {hasCreateDataStreamAccess && (
          <Button
            color="blue"
            loading={isCreatingDataStream}
            onClick={onCreate}
          >
            {t('common:save')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
