import { useMutation } from '@tanstack/react-query';

import { postAuthLogin } from '../requests';
import { PostAuthLoginRequestType } from '../types';

export const usePostAuthLogin = () =>
  useMutation({
    mutationFn: (bodyParams: PostAuthLoginRequestType['bodyParams']) =>
      postAuthLogin({
        bodyParams,
      }),
  });
