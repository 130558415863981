import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const MoneyIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M6.667 15.2a3.2 3.2 0 0 0 3.2 3.2h4.266a3.2 3.2 0 0 0 0-6.4H9.867a3.2 3.2 0 0 1 0-6.4h4.266a3.2 3.2 0 0 1 3.2 3.2M12 4v1.6M12 20v-1.6"
      />
    </Box>
  );
};
