import { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Popover, Select, Text, UnstyledButton } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import { NotFound, TextInputSearch } from 'components/shared';

type Props = {
  selectRef?: MutableRefObject<HTMLInputElement | null>;
  isDataStreamFromFile: boolean;
  templates: DataStreamTemplateType[];
  selectedAttachmentTemplate: {
    templateId: number | null;
    templateName: string | null;
    documentContent: string | null;
  };
  onAttachmentTemplateChange: ({
    templateId,
    templateName,
    documentContent,
  }: {
    templateId: DataStreamTemplateType['templateId'];
    templateName: DataStreamTemplateType['templateName'];
    documentContent: DataStreamTemplateType['documentContent'];
  }) => void;
};

export const AttachmentTemplateSelect = ({
  selectRef,
  isDataStreamFromFile,
  templates,
  selectedAttachmentTemplate,
  onAttachmentTemplateChange,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filteredTemplates = templates.filter(
    ({ templateName, documentContent }) => {
      if (templateName) {
        return templateName.toLowerCase().includes(searchValue.toLowerCase());
      }

      if (documentContent) {
        return documentContent
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }

      return true;
    },
  );

  const selectValue = isDataStreamFromFile
    ? selectedAttachmentTemplate.documentContent || ''
    : String(selectedAttachmentTemplate.templateId || '');

  return (
    <Popover
      opened={isOpen}
      onChange={setIsOpen}
      width={370}
      position="bottom-start"
    >
      <Popover.Target>
        <Select
          ref={selectRef}
          placeholder={t('dataStreams:editModal.templatePlaceholder')}
          value={selectValue}
          error={null}
          data={[
            {
              value: selectValue,
              label:
                selectedAttachmentTemplate.templateName ||
                selectedAttachmentTemplate.documentContent ||
                '',
            },
          ]}
          sx={
            isOpen
              ? {
                  '>[role="combobox"]': {
                    '&[aria-expanded="false"]': {
                      '.rotate': {
                        transform: 'rotate(180deg)',
                      },
                    },
                  },
                }
              : undefined
          }
          readOnly
          onClick={() => {
            setIsOpen(open => !open);
          }}
          onKeyUp={e => {
            if (e.key === ' ') {
              setIsOpen(open => !open);
            }
          }}
        />
      </Popover.Target>
      <Popover.Dropdown
        sx={theme => ({ padding: theme.other.spacing(2, 0, 0.5) })}
      >
        <div>
          <TextInputSearch
            sx={theme => ({
              margin: theme.other.spacing(0, 2, 2),
            })}
            autoFocus
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
            placeholder={t('dataStreams:editModal.templateSearchPlaceholder')}
          />
          {filteredTemplates.length > 0 ? (
            <Box sx={{ maxHeight: 355, overflow: 'auto' }} component="ul">
              {filteredTemplates.map(
                ({ templateId, documentContent, templateName }) => {
                  const id = templateId || documentContent;

                  const isSelected =
                    Boolean(selectValue) &&
                    (isDataStreamFromFile
                      ? documentContent ===
                        selectedAttachmentTemplate.documentContent
                      : templateId === selectedAttachmentTemplate.templateId);

                  return (
                    <li key={id}>
                      <UnstyledButton
                        sx={theme => ({
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          minHeight: 40,
                          padding: theme.other.spacing(1, 2),
                          fontSize: theme.fontSizes.lg,
                          wordBreak: 'break-word',
                          backgroundColor: isSelected
                            ? theme.colors[theme.primaryColor][1]
                            : undefined,
                          '&:hover': {
                            backgroundColor:
                              theme.colors[theme.primaryColor][1],
                          },
                        })}
                        onClick={() => {
                          onAttachmentTemplateChange({
                            templateId,
                            templateName,
                            documentContent,
                          });
                          setIsOpen(false);
                        }}
                      >
                        <Text
                          sx={theme => ({
                            marginRight: theme.other.spacing(0.5),
                            fontWeight: isSelected ? 600 : undefined,
                          })}
                          span
                        >
                          {templateName || documentContent}
                        </Text>
                        {templateId && (
                          <Text
                            size="xs"
                            color="gray.7"
                            sx={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              flexShrink: 0,
                            }}
                          >
                            {t('common:id')} {templateId}
                          </Text>
                        )}
                      </UnstyledButton>
                    </li>
                  );
                },
              )}
            </Box>
          ) : (
            <NotFound />
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
