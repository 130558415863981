import { useTranslation } from 'react-i18next';
import { ActionIcon, Badge, Box, Text, UnstyledButton } from '@mantine/core';

import { useGetV1Url } from 'hooks';
import { SettingsIcon } from 'icons';
import { UserBoardOrganisationType, UserBoardType } from 'types';

type Props = {
  changeBoard: (boardId: number) => Promise<void>;
  organisationName: UserBoardOrganisationType['name'];
  organisationLogoUrl: UserBoardOrganisationType['logoUrl'];
  boardId: UserBoardType['id'];
  boardName?: UserBoardType['name'];
  isActive?: boolean;
};

export const Board = ({
  changeBoard,
  organisationName,
  organisationLogoUrl,
  boardId,
  boardName,
  isActive = false,
}: Props) => {
  const { t } = useTranslation(['common', 'navigation']);

  const getV1Url = useGetV1Url();

  return (
    <UnstyledButton
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: 50,
        marginBottom: theme.other.spacing(0.5),
        padding: theme.other.spacing(1, 2),
        backgroundColor: isActive
          ? theme.colors[theme.primaryColor][1]
          : undefined,
        '&:hover': {
          backgroundColor: theme.colors[theme.primaryColor][1],
        },
      })}
      onClick={async () => {
        await changeBoard(boardId);
        window.location.assign(getV1Url('contracts'));
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {organisationLogoUrl && (
          <Box
            sx={theme => ({
              maxWidth: 30,
              marginRight: theme.other.spacing(1),
              border: `1px solid ${theme.colors.gray[1]}`,
              borderRadius: theme.radius.sm,
            })}
            component="img"
            src={organisationLogoUrl}
            alt=""
          />
        )}
        <Box sx={theme => ({ marginRight: theme.other.spacing(2) })}>
          <Text size="md" weight={600}>
            {organisationName}
          </Text>
          <Text color="gray.7" size="md">
            {boardName}
          </Text>
        </Box>
      </Box>
      {isActive && (
        <Badge color="blue" size="xs" radius="lg">
          {t('navigation:organisations.active')}
        </Badge>
      )}
      <Box sx={{ flexShrink: 0 }}>
        <ActionIcon
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            '&:hover': {
              backgroundColor: theme.colors.violet[2],
            },
          })}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            await changeBoard(boardId);
            window.location.assign(getV1Url('organisations'));
          }}
        >
          <SettingsIcon color="primary.9" />
        </ActionIcon>
        <Text
          size="xs"
          color="gray.7"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          {t('common:id')} {boardId}
        </Text>
      </Box>
    </UnstyledButton>
  );
};
