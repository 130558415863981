import { useMutation } from '@tanstack/react-query';

import { postRegister } from '../requests';
import { PostRegisterRequestType } from '../types';

export const usePostRegister = () =>
  useMutation({
    mutationFn: (bodyParams: PostRegisterRequestType['bodyParams']) =>
      postRegister({
        bodyParams,
      }),
  });
