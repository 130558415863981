export const getArrayPartitions = <T>(
  array: T[],
  ...filters: ((value: T) => boolean)[]
) => {
  const partitions: T[][] = Array(filters.length + 1)
    .fill(null)
    .map(() => []);

  array.forEach(item => {
    let filtered = false;

    filters.forEach((filter, filterIndex) => {
      if (filter(item)) {
        partitions[filterIndex].push(item);

        filtered = true;
      }
    });

    if (!filtered) {
      partitions[partitions.length - 1].push(item);
    }
  });

  return partitions;
};
