import { useTranslation } from 'react-i18next';
import { Box, Checkbox } from '@mantine/core';

import { CERTIFICATE_LANGUAGES } from 'consts';
import { FlagDeIcon, FlagPlIcon, FlagUkIcon } from 'icons';

const CERTIFICATE_LANGUAGES_ITEMS = [
  {
    value: CERTIFICATE_LANGUAGES.pl,
    labelTranslation: 'templates:lang.polish',
    icon: <FlagPlIcon />,
  },
  {
    value: CERTIFICATE_LANGUAGES.en,
    labelTranslation: 'templates:lang.english',
    icon: <FlagUkIcon />,
  },
  {
    value: CERTIFICATE_LANGUAGES.de,
    labelTranslation: 'templates:lang.german',
    icon: <FlagDeIcon />,
  },
];

type CertificateLanguagesType =
  (typeof CERTIFICATE_LANGUAGES)[keyof typeof CERTIFICATE_LANGUAGES][];

type Props = {
  certificateLanguages: CertificateLanguagesType;
  onCertificateLanguagesChange: (
    certificateLanguages: CertificateLanguagesType,
  ) => void;
};

export const CertificateLanguageCheckboxes = ({
  certificateLanguages,
  onCertificateLanguagesChange,
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        rowGap: theme.other.spacing(2),
      })}
    >
      {CERTIFICATE_LANGUAGES_ITEMS.map(({ value, labelTranslation, icon }) => (
        <Checkbox
          sx={{
            margin: 0,
          }}
          key={value}
          label={
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                gap: theme.other.spacing(1),
              })}
            >
              {icon}
              {t(labelTranslation)}
            </Box>
          }
          name={`certificateLanguages-${value}`}
          checked={certificateLanguages.some(
            certificateLanguage => certificateLanguage === value,
          )}
          onChange={e => {
            onCertificateLanguagesChange(
              e.target.checked
                ? [...certificateLanguages, value]
                : certificateLanguages.filter(
                    certificateLanguage => certificateLanguage !== value,
                  ),
            );
          }}
        />
      ))}
    </Box>
  );
};
