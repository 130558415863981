import { useTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';

import { DYNAMIC_URLS } from 'consts';

import { Variables } from './Variables';
import { Documents } from './Documents';

export const Sidebar = () => {
  const { t } = useTranslation('templates');

  /* TODO: remove after requirements are established  */
  const { templateId } = useParams();
  const matchTemplateEditUrl = useMatch(
    DYNAMIC_URLS.templateEdit({ templateId: templateId }),
  );
  const isOnTemplateEditRoute = Boolean(matchTemplateEditUrl);

  return (
    <div>
      <Tabs defaultValue="variables">
        <Tabs.List
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
        >
          <Tabs.Tab value="variables">{t('templates:variables')}</Tabs.Tab>
          <Tabs.Tab value="documents" disabled={!isOnTemplateEditRoute}>
            {t('templates:documents')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="variables">
          <Variables />
        </Tabs.Panel>
        {isOnTemplateEditRoute && (
          <Tabs.Panel value="documents">
            <Documents />
          </Tabs.Panel>
        )}
      </Tabs>
    </div>
  );
};
