import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TechnicalUserType } from 'types';
import { DeleteIcon, EyeIcon } from 'icons';
import { Actions } from 'components/shared';

import { CertificateInstructionModal } from '../CertificateInstructionModal';
import { DeleteModal } from '../DeleteModal';

type Props = {
  hasDeleteTechnicalUserAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  technicalUserName: TechnicalUserType['name'];
};

export const TechnicalUserActions = ({
  hasDeleteTechnicalUserAccess,
  technicalUserId,
  technicalUserName,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const [
    isCertificateInstructionModalOpen,
    setIsCertificateInstructionModalOpen,
  ] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Actions
        items={[
          {
            name: t('api:seeInstruction'),
            icon: <EyeIcon />,
            colorDesktop: 'blue',
            action: () => {
              setIsCertificateInstructionModalOpen(true);
            },
          },
          ...(hasDeleteTechnicalUserAccess
            ? [
                {
                  name: t('common:delete'),
                  icon: <DeleteIcon />,
                  colorDesktop: 'red',
                  colorMobile: 'red',
                  action: () => {
                    setIsDeleteModalOpen(true);
                  },
                },
              ]
            : []),
        ]}
      />
      <CertificateInstructionModal
        technicalUserId={technicalUserId}
        isOpen={isCertificateInstructionModalOpen}
        onClose={() => {
          setIsCertificateInstructionModalOpen(false);
        }}
      />
      <DeleteModal
        technicalUserId={technicalUserId}
        technicalUserName={technicalUserName}
        isOpen={isDeleteModalOpen}
        onClose={handleClose}
        defaultButtonAction={handleClose}
      />
    </>
  );
};
