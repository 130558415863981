import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@mantine/core';

import { DeleteAccountModal } from './DeleteAccountModal';

export const DeletingAccount = () => {
  const { t } = useTranslation('user');

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState(false);

  return (
    <>
      <div>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2.5),
          })}
          weight={600}
        >
          {t('user:deletingAccount')}
        </Text>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          size="md"
          weight={400}
        >
          {t('user:deletingAccountDescription')}
        </Text>
        <Button
          sx={{ display: 'block', marginLeft: 'auto' }}
          color="red"
          onClick={() => {
            setIsDeleteAccountModalOpen(true);
          }}
        >
          {t('user:iWantDeleteAccount')}
        </Button>
      </div>
      <DeleteAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={() => {
          setIsDeleteAccountModalOpen(false);
        }}
      />
    </>
  );
};
