import { useTranslation } from 'react-i18next';
import { Box, DefaultProps, packSx } from '@mantine/core';

import { Status } from 'components/shared';

type Props = {
  canEditByNegotiator: boolean;
  isBase: boolean;
  isOffer: boolean;
  isOneWay: boolean;
  sx?: DefaultProps['sx'];
};

export const TemplateStatuses = ({
  canEditByNegotiator,
  isBase,
  isOffer,
  isOneWay,
  sx,
}: Props) => {
  const { t } = useTranslation('templates');

  const statuses = [
    {
      value: 'base',
      label: t('templates:templateTypes.base'),
      shouldRender: isBase,
    },
    {
      value: 'oneWay',
      label: t('templates:templateTypes.oneWay'),
      shouldRender: isOneWay,
    },
    {
      value: 'offer',
      label: t('templates:templateTypes.offer'),
      shouldRender: isOffer,
    },
    {
      value: 'canEditByNegotiator',
      label: t('templates:editableByContractors'),
      shouldRender: canEditByNegotiator,
      color: 'blue',
    },
  ].filter(({ shouldRender }) => shouldRender);

  return statuses.length > 0 ? (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          gap: theme.other.spacing(1),
        }),
        ...packSx(sx),
      ]}
    >
      {statuses.map(({ value, label, color = 'violet' }) => (
        <Status key={value} color={color} label={label} dotSize={0} />
      ))}
    </Box>
  ) : null;
};
