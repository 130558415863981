import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const BooleanIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M7.429 6.286a1.143 1.143 0 1 1 0 2.285 1.143 1.143 0 0 1 0-2.285Z"
      />
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M16.571 4H7.43a3.429 3.429 0 1 0 0 6.857h9.142a3.429 3.429 0 1 0 0-6.857ZM16.571 17.714a1.143 1.143 0 1 1 0-2.285 1.143 1.143 0 0 1 0 2.285Z"
      />
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M7.429 20h9.142a3.429 3.429 0 1 0 0-6.857H7.43a3.429 3.429 0 1 0 0 6.857Z"
      />
    </Box>
  );
};
