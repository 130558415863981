import { useGetUser } from 'api/account';
import { UserType } from 'types';
import { GuideTourOnStart } from './GuideTourOnStart';

type Props = {
  children: JSX.Element;
};

export const GuideTourController = ({ children }: Props) => {
  const { data: user } = useGetUser();
  const { tutorial } = user as UserType;

  return (
    <>
      {children}
      {tutorial.showV2GuideTour && <GuideTourOnStart />}
    </>
  );
};
