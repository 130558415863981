import {
  NavigateOptions,
  useSearchParams as useSearchParamsReactRouter,
} from 'react-router-dom';

const defaultSearchParamsOptions: NavigateOptions = {
  replace: true,
};

export const useSearchParams = (filters: string[]) => {
  const [searchParams, setSearchParams] = useSearchParamsReactRouter();

  const getSearchParam = (key: string) => searchParams.get(key);

  const changeSearchParam = (
    key: string,
    value: string | number,
    options?: NavigateOptions,
  ) => {
    setSearchParams(
      currentSearchParams => {
        currentSearchParams.set(key, String(value));

        return currentSearchParams;
      },
      {
        ...defaultSearchParamsOptions,
        ...options,
      },
    );
  };

  const deleteSearchParam = (key: string, options?: NavigateOptions) => {
    setSearchParams(
      currentSearchParams => {
        currentSearchParams.delete(key);

        return currentSearchParams;
      },
      {
        ...defaultSearchParamsOptions,
        ...options,
      },
    );
  };

  const deleteSearchParams = (keys: string[], options?: NavigateOptions) => {
    setSearchParams(
      currentSearchParams => {
        keys.forEach(key => {
          currentSearchParams.delete(key);
        });

        return currentSearchParams;
      },
      {
        ...defaultSearchParamsOptions,
        ...options,
      },
    );
  };

  const deleteAllSearchParams = (options?: NavigateOptions) => {
    setSearchParams(
      currentSearchParams => {
        filters.forEach(key => {
          currentSearchParams.delete(key);
        });

        return currentSearchParams;
      },
      {
        ...defaultSearchParamsOptions,
        ...options,
      },
    );
  };

  return {
    getSearchParam,
    changeSearchParam,
    deleteSearchParam,
    deleteSearchParams,
    deleteAllSearchParams,
  };
};
