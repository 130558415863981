import { forwardRef, useEffect, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';

import { useIsFirstRender, useSearchParams } from 'hooks';
import { DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import { TextInputSearch } from 'components/shared';
import { Props as TextInputSearchProps } from 'components/shared/Inputs/TextInputSearch';

const QUERY_PARAM_TEXT_LIMIT = 200;

type Props = {
  delay?: number;
} & Omit<TextInputSearchProps, 'value' | 'onChange'>;

export const TextInputReactRouterSearch = forwardRef<HTMLInputElement, Props>(
  ({ delay = DEBOUNCED_INPUT_DELAY_IN_MS, ...textInputSearchProps }, ref) => {
    const isFirstRender = useIsFirstRender();

    const { getSearchParam, changeSearchParam, deleteSearchParam } =
      useSearchParams(['search', 'page']);

    const searchParamSearch = getSearchParam('search');
    const pageParamSearch = getSearchParam('page');

    const getInputValueFromSearchParam = () =>
      window.decodeURIComponent(
        getSearchParam('search')?.slice(0, QUERY_PARAM_TEXT_LIMIT) || '',
      );

    const [inputValue, setInputValue] = useState(
      getInputValueFromSearchParam(),
    );
    const [debouncedValue] = useDebouncedValue(inputValue, delay);

    useEffect(() => {
      setInputValue(getInputValueFromSearchParam());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParamSearch]);

    useEffect(() => {
      if (debouncedValue) {
        changeSearchParam('search', debouncedValue, {
          replace: true,
        });
      } else {
        deleteSearchParam('search', {
          replace: true,
        });
      }

      if (!isFirstRender && pageParamSearch) {
        deleteSearchParam('page', {
          replace: true,
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
      <TextInputSearch
        ref={ref}
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value.slice(0, QUERY_PARAM_TEXT_LIMIT));
        }}
        {...textInputSearchProps}
      />
    );
  },
);
