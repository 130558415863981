import { MantineSize } from '@mantine/core';

import { getMantineColorAndShade } from 'helpers';

import { ThemeComponentType } from '../types';

export const Badge: ThemeComponentType = {
  defaultProps: {
    size: 'sm',
    radius: 'sm',
  },
  styles: (theme, { color, size, variant }) => {
    const getVariantRootColorStyles = () => {
      switch (variant) {
        case 'light': {
          const [baseColor, shadeColor] = getMantineColorAndShade(
            color || theme.primaryColor,
          );

          if (baseColor === 'gray' && shadeColor === undefined) {
            return {
              color: theme.colors.gray[4],
              backgroundColor: theme.colors.gray[1],
            };
          }

          return {
            color: theme.colors[baseColor][theme.fn.primaryShade()],
            backgroundColor: shadeColor
              ? theme.colors[baseColor][shadeColor]
              : theme.colors[baseColor][1],
          };
        }

        default:
          return {};
      }
    };

    return {
      root: {
        padding: theme.other.spacing(1.375, 1.75),
        fontSize: theme.fontSizes[size as MantineSize],
        fontWeight: 400,
        textTransform: 'none',
        ...getVariantRootColorStyles(),
      },
    };
  },
};
