import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postTechnicalUserGenerateCertificate } from '../requests';
import { PostTechnicalUserGenerateCertificateRequestType } from '../types';

export const usePostTechnicalUserGenerateCertificate = ({
  pathParams: { technicalUserId },
}: {
  pathParams: Omit<
    PostTechnicalUserGenerateCertificateRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      formData,
    }: {
      formData: PostTechnicalUserGenerateCertificateRequestType['formData'];
    }) =>
      postTechnicalUserGenerateCertificate({
        pathParams: { organisationId, technicalUserId },
        formData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.technicalUsers],
      });
    },
  });
};
