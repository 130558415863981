import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

export const InterlineSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isFocused } = editor.value.selection;
  const activeInterline = editor.activeInterline();
  const hasParagraph = editor.hasParagraph();

  return (
    <Select
      sx={theme => ({
        maxWidth: 152,
        input: {
          fontSize: theme.fontSizes.lg,
          fontWeight: 500,
        },
      })}
      disabled={(!isFocused && !isDropdownOpen) || !hasParagraph}
      variant="unstyled"
      value={activeInterline ? String(activeInterline) : 'none'}
      error={null}
      data={[1.15, 1.5, 2].map(interline => ({
        value: String(interline),
        label: `${t('templates:toolbar.interline')} ${interline}`,
      }))}
      onChange={value => {
        editor.changeParagraphInterline(Number(value));
      }}
      onDropdownOpen={() => {
        setIsDropdownOpen(true);
      }}
      onDropdownClose={() => {
        setIsDropdownOpen(false);
      }}
    />
  );
};
