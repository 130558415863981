import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetBoardTemplatesResponseType,
  GetBoardTemplatesRequestType,
} from '../types';

export const getBoardTemplates = async ({
  pathParams: { organisationId, boardId },
  queryParams: {
    base,
    from,
    id,
    name,
    page,
    perPage,
    public: publicParam,
    sortBy,
    sortDir,
    to,
  },
}: GetBoardTemplatesRequestType) => {
  const query = stringifyUrlQuery({
    base,
    from,
    id,
    name,
    page,
    perPage,
    public: publicParam,
    sortBy,
    sortDir,
    to,
  });

  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates${query}`;

  const { data } = await apiAuthClient.get<GetBoardTemplatesResponseType>(url);

  return data;
};
