import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const TourIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Z"
      />
      <path
        stroke={iconColor}
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.857 10.857 8.571 15.43l4.572-2.286 2.286-4.572-4.572 2.286Z"
      />
    </Box>
  );
};
