import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Box, Tooltip, UnstyledButton } from '@mantine/core';

import { EditIcon } from 'icons';
import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';
import { ForceUpdateContext } from 'legacy/TemplateCreatorEditor/context';
import { VariableIcon } from 'legacy/TemplateCreatorEditor/icons';
import { VariableMapping } from 'legacy/TemplateCreatorEditor/VariableMapping';

import { getMoneyVariableIcon, getNextLanguageValue } from './helpers';

const Input = ({ attributes, node, editor, isActive }) => {
  const { t } = useTranslation('templates');

  const forceUpdate = useContext(ForceUpdateContext);

  const variables = editor.getVariables();

  const nodeVariable = node.data.get('variable');

  const variable = variables.find(({ name }) => name === nodeVariable);
  const variableType = variable?.type;
  const variableIcon = variableType
    ? VARIABLES.find(({ value }) => value === variableType)?.icon
    : null;

  const getAdditionalContent = () => {
    if (variableType === 'money' && variable.translateMoneyValue) {
      const { defaultMoneyTranslation } = variable;
      const Icon = getMoneyVariableIcon(defaultMoneyTranslation);

      return (
        <span
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <Tooltip label={t('templates:clickToChangeLanguageTranslation')}>
            <ActionIcon
              sx={theme => ({
                marginLeft: theme.other.spacing(0.5),
              })}
              component="span"
              onClick={() => {
                editor.updateVariable(nodeVariable, {
                  ...variable,
                  defaultMoneyTranslation: getNextLanguageValue(
                    defaultMoneyTranslation,
                  ),
                });

                forceUpdate();
              }}
            >
              <Icon size={16} />
            </ActionIcon>
          </Tooltip>
        </span>
      );
    }
  };

  return (
    <VariableMapping {...attributes} node={node}>
      <Box
        sx={theme => ({
          position: 'relative',
          display: 'inline-block',
          marginBottom: theme.other.spacing(1),
          userSelect: 'none',
          verticalAlign: 'middle',
        })}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <UnstyledButton
          sx={theme => {
            const activeBorderColor =
              theme.focusRingStyles.inputStyles(theme).borderColor;

            const getBorderColor = () => {
              if (isActive) {
                return activeBorderColor;
              }

              return nodeVariable ? theme.colors.gray[2] : theme.colors.red[0];
            };

            return {
              display: 'inline-flex',
              alignItems: 'center',
              gap: theme.other.spacing(1),
              padding: theme.other.spacing(0.75, 2),
              fontSize: theme.fontSizes.lg,
              fontWeight: 500,
              backgroundColor: nodeVariable
                ? theme.colors.gray[1]
                : theme.colors.red[1],
              border: `1px solid ${theme.colors.gray[2]}`,
              borderColor: getBorderColor(),
              borderRadius: theme.radius.sm,
            };
          }}
        >
          {variableIcon ? variableIcon : <VariableIcon color="blue" />}
          <Box sx={{ maxWidth: 240 }} component="span">
            {nodeVariable ? nodeVariable : t('templates:chooseVariable')}
          </Box>
          {Boolean(nodeVariable) && (
            <EditIcon
              sx={theme => ({
                marginLeft: theme.other.spacing(1),
              })}
              color="primary"
            />
          )}
          {getAdditionalContent()}
        </UnstyledButton>
      </Box>
    </VariableMapping>
  );
};

export const input = {
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'input') {
      return (
        <Input
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    inlines: {
      input: {
        isVoid: true,
      },
    },
  },
};
