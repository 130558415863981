import { isBefore } from 'date-fns';

import { useFormatUserDate } from 'hooks';

export const useGetCertificateRenderData = () => {
  const formatUserDate = useFormatUserDate();

  return ({
    certificateKey,
    expiresAt,
  }: {
    certificateKey: string;
    expiresAt: string | null | undefined;
  }) => {
    const hasPreviouslyCreatedCertificate = Boolean(expiresAt);
    const hasExpiredCertificate =
      hasPreviouslyCreatedCertificate &&
      Boolean(expiresAt) &&
      isBefore(new Date(expiresAt as string), new Date());
    const hasNotExpiredCertificate =
      hasPreviouslyCreatedCertificate && !hasExpiredCertificate;
    const hasFreshCertificate = Boolean(certificateKey);
    const hasCertificate =
      hasPreviouslyCreatedCertificate || hasFreshCertificate;
    const formattedExpirationDate =
      hasCertificate && Boolean(expiresAt)
        ? formatUserDate(expiresAt as string)
        : null;

    return {
      hasExpiredCertificate,
      hasNotExpiredCertificate,
      hasFreshCertificate,
      hasCertificate,
      formattedExpirationDate,
    };
  };
};
