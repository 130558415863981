import { apiAuthClient } from 'api/helpers';

import { PostAvatarRequestType, PostAvatarResponseType } from '../types';

export const postAvatar = async ({ formData }: PostAvatarRequestType) => {
  const url = '/api/v2/auth/user/avatar';

  const { data } = await apiAuthClient.post<PostAvatarResponseType>(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data.data;
};
