import { Box } from '@mantine/core';

export const pageBreak = {
  onKeyDown: (e, editor, next) => {
    if (
      e.key === 'Enter' &&
      editor.value.blocks.some(block => block.type === 'pageBreak')
    ) {
      e.preventDefault();

      editor.insertBlock('paragraph');

      return true;
    }

    return next();
  },
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'pageBreak') {
      return (
        <Box
          {...attributes}
          sx={theme => {
            const activeBorderColor =
              theme.focusRingStyles.inputStyles(theme).borderColor;

            return {
              margin: theme.other.spacing(2, 0),
              borderColor:
                isSelected && isFocused ? activeBorderColor : undefined,
              cursor: 'pointer',
            };
          }}
          component="hr"
        />
      );
    }

    return next();
  },
  schema: {
    blocks: {
      pageBreak: {
        isVoid: true,
      },
    },
  },
};
