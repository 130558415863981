import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const SettingsIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="m10.222 4-.099.5-.376 1.827a6.308 6.308 0 0 0-1.638.942L6.27 6.654 5.777 6.5l-.256.44-1.265 2.117L4 9.5l.375.328 1.422 1.21c-.052.317-.118.63-.118.963 0 .332.066.646.118.962l-1.422 1.21-.375.329.256.44 1.265 2.117.256.442.494-.154 1.838-.615a6.307 6.307 0 0 0 1.638.942l.376 1.828.099.499h3.555l.1-.5.374-1.827a6.307 6.307 0 0 0 1.64-.942l1.837.615.494.154.257-.442 1.264-2.116L20 14.5l-.376-.328-1.422-1.21c.053-.317.118-.63.118-.963 0-.333-.065-.646-.118-.962l1.422-1.21.376-.329-.257-.44-1.264-2.117-.257-.441-.494.153-1.838.615a6.308 6.308 0 0 0-1.639-.942l-.374-1.828-.1-.499h-3.555Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M14.285 12a2.287 2.287 0 1 1-4.573-.002 2.287 2.287 0 0 1 4.573.002Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </Box>
  );
};
