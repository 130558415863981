import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { postOtp } from '../requests';
import { PostOtpRequestType, PostOtpResponseType } from '../types';

export const usePostOtpQuery = (
  { bodyParams: { email } }: PostOtpRequestType,
  options?: {
    enabled?: boolean;
    onError?: (error: unknown) => void;
    onSuccess?: (data: PostOtpResponseType['data']) => void;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.otpCode, { email }],
    queryFn: () => postOtp({ bodyParams: { email } }),
    ...options,
  });
