import { useQuery } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { getBoardInvitation } from '../requests';
import { GetBoardInvitationRequestType } from '../types';

export const useGetBoardInvitation = (
  { pathParams: { invitationId } }: GetBoardInvitationRequestType,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.boardInvitation, { invitationId }],
    queryFn: () => getBoardInvitation({ pathParams: { invitationId } }),
    ...options,
  });
