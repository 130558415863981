import { PREVIEW_DIMENSIONS } from '../consts';

const A4_SIZE_IN_CM = {
  width: 21,
  height: 29.7,
};
const CM_TO_PX_WIDTH_MULTIPLIER =
  PREVIEW_DIMENSIONS.WIDTH / A4_SIZE_IN_CM.width;
const CM_TO_PX_HEIGHT_MULTIPLIER =
  PREVIEW_DIMENSIONS.HEIGHT / A4_SIZE_IN_CM.height;

export const getMarginItemDimensionInPx = (
  valueInCm: number,
  placement: 'horizontal' | 'vertical',
) =>
  Math.ceil(
    valueInCm *
      (placement === 'horizontal'
        ? CM_TO_PX_WIDTH_MULTIPLIER
        : CM_TO_PX_HEIGHT_MULTIPLIER),
  );
