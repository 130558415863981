import { useId } from 'react';
import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const FlagDeIcon = ({ size = 24, sx, ...props }: IconPropsType) => {
  const id = useId();

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path fill="#FFCE00" d="M0 16h24v8H0v-8Z" />
        <path fill="#000" d="M0 0h24v8H0V0Z" />
        <path fill="#D00" d="M0 8h24v8H0V8Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={24} height={24} fill="#fff" rx={12} />
        </clipPath>
      </defs>
    </Box>
  );
};
