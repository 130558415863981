import { Box } from '@mantine/core';
import bytes from 'bytes';

import { FileTypePdfIcon, FileTypeWithoutExtensionIcon } from 'icons';

type Props = {
  sizeInBytes: number;
  extension: string;
};

export const FileInfo = ({ sizeInBytes, extension }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {extension === 'pdf' ? (
      <FileTypePdfIcon />
    ) : (
      <FileTypeWithoutExtensionIcon />
    )}
    <Box
      sx={theme => ({
        marginLeft: theme.other.spacing(1),
      })}
      component="span"
    >
      {bytes(sizeInBytes, {
        unitSeparator: ' ',
      })}
    </Box>
  </Box>
);
