import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { DEBOUNCED_INPUT_DELAY_IN_MS } from 'consts';
import { TextInput } from 'components/shared';

import { RadioColor } from './RadioColor';

type Props = {
  color: string;
  updateColor: (color: string) => void;
};

const isColorValid = (color: string) => /^#[A-Fa-f0-9]{6}$/.test(color);

export const MailColorTheme = ({ color, updateColor }: Props) => {
  const { t } = useTranslation(['common', 'organisations']);

  const [colorLocalState, setColorLocalState] = useState(color);
  const [debouncedColorLocalState] = useDebouncedValue(
    colorLocalState,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  useEffect(() => {
    setColorLocalState(color);
  }, [color]);

  return (
    <div>
      <Text>{t('organisations:colorOfElements')}</Text>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
        })}
        size="md"
        weight={400}
      >
        {t('organisations:colorOfElementsDescription')}
      </Text>
      <Radio.Group
        sx={theme => ({
          marginBottom: theme.other.spacing(2.25),
        })}
        value={colorLocalState}
        onChange={newColor => {
          setColorLocalState(newColor);
          updateColor(newColor);
        }}
      >
        <RadioColor color="violet" />
        <RadioColor color="red" />
        <RadioColor color="green" />
        <RadioColor color="orange" />
        <RadioColor color="yellow" />
        <RadioColor color="teal" />
      </Radio.Group>
      <TextInput
        sx={{ maxWidth: 360 }}
        icon="#"
        value={colorLocalState.replace('#', '')}
        error={
          !isColorValid(debouncedColorLocalState) &&
          t('common:formErrors.invalidFieldFormat')
        }
        onChange={e => {
          const { value } = e.target;

          if (value.length !== 7) {
            const newValue = `#${e.target.value.toUpperCase()}`;

            setColorLocalState(newValue);

            if (isColorValid(newValue)) {
              updateColor(newValue);
            }
          }
        }}
      />
    </div>
  );
};
