import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import { useGetTransactionDataType } from 'api/transactions';
import { getHasHateoasAccess } from 'helpers';
import { useFormatUserDate } from 'hooks';
import { DataWrapper, PaperCard, PercentageContainer } from 'components/shared';

import { DataTypeActions } from './DataTypeActions';

export const DataTypePanel = () => {
  const { t } = useTranslation(['common', 'dataTypes']);
  const { transactionId, transactionDataStream } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
  };

  const formatUserDate = useFormatUserDate();

  const {
    data: transactionDataType,
    isError: isTransactionDataTypeError,
    isLoading: isTransactionDataTypeLoading,
  } = useGetTransactionDataType({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStream,
    },
  });

  return (
    <DataWrapper
      data={transactionDataType}
      isError={isTransactionDataTypeError}
      isLoading={isTransactionDataTypeLoading}
    >
      {({ name, yaml, updatedAt, __links }) => {
        const hasDataTypePatchAccess = getHasHateoasAccess('update', __links);

        return (
          <PercentageContainer
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            component={PaperCard}
          >
            <div>
              <Text
                sx={theme => ({
                  marginBottom: theme.other.spacing(2.25),
                })}
                size="xl"
                weight={600}
              >
                {name}
              </Text>
              <Text size="md" weight={600}>
                {formatUserDate(updatedAt)}
              </Text>
              <Text color="gray.7" size="sm">
                {t('common:lastUpdate')}
              </Text>
            </div>
            {hasDataTypePatchAccess && <DataTypeActions yaml={yaml} />}
          </PercentageContainer>
        );
      }}
    </DataWrapper>
  );
};
