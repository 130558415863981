import { apiAuthClient } from 'api/helpers';

import { GetDataStreamResponseType, GetDataStreamRequestType } from '../types';

export const getDataStream = async ({
  pathParams: { organisationId, dataStreamId },
}: GetDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}`;

  const { data } = await apiAuthClient.get<GetDataStreamResponseType>(url);

  return data.data;
};
