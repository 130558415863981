import { FormEvent, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mantine/core';
import { z } from 'zod';

import { usePostAuthResetPassword } from 'api/accountPassword';
import { getZodSchemaErrorMessages } from 'helpers';
import { useServerErrors } from 'hooks';
import { URLS } from 'consts';
import { PromotionalCarousel } from 'components/PromotionalCarousel';
import { TextInput } from 'components/shared';
import { ContentHeader, NonLoggedLayout } from 'components/NonLoggedLayout';

import { EmailSendConfirmation } from './EmailSendConfirmation';

export const RequestPasswordReset = () => {
  const { t } = useTranslation(['common', 'user']);
  const location = useLocation();
  const navigate = useNavigate();

  const schema = z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .email({ message: t('common:formErrors.incorrectEmail') }),
  });

  const { getGeneralServerError, getServerFieldError } = useServerErrors();
  const { mutateAsync, isLoading } = usePostAuthResetPassword();

  const [email, setEmail] = useState<string>(location.state?.email || '');
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const backButtonAction = () => {
    navigate(URLS.home);
  };

  return (
    <NonLoggedLayout
      backButtonAction={backButtonAction}
      primaryContent={<PromotionalCarousel />}
      secondaryContent={
        <>
          {!isSuccess && (
            <ContentHeader
              title={t('user:passwordRecovery')}
              description={t('user:passwordRecoveryDescription')}
              errorLabel={serverErrorMessage}
              backButtonAction={backButtonAction}
            />
          )}
          {isSuccess ? (
            <Box
              sx={theme => ({
                marginTop: theme.other.spacing(8),
              })}
            >
              <EmailSendConfirmation
                email={email}
                onBackToForm={() => {
                  setIsSuccess(false);
                }}
              />
            </Box>
          ) : (
            <form
              onSubmit={async (event: FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                const errors = getZodSchemaErrorMessages({
                  schema,
                  fields: { email },
                });

                if (errors) {
                  setEmailFieldErrorMessage(errors.email);
                  inputRef.current?.focus();
                } else {
                  try {
                    await mutateAsync({ email });
                    setIsSuccess(true);
                  } catch (error) {
                    const emailFieldServerError = getServerFieldError(
                      error,
                      'email',
                    );

                    if (emailFieldServerError) {
                      setEmailFieldErrorMessage(emailFieldServerError);
                    } else {
                      setServerErrorMessage(getGeneralServerError(error));
                    }

                    inputRef.current?.focus();
                  }
                }
              }}
            >
              <TextInput
                id="email"
                label={t('common:email')}
                placeholder={t('common:emailPlaceholder')}
                autoFocus
                value={email}
                error={emailFieldErrorMessage}
                ref={inputRef}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                disabled={isLoading}
              />
              <Button
                sx={{ fontWeight: 600 }}
                type="submit"
                fullWidth
                size="lg"
                loading={isLoading}
              >
                {t('user:resetPassword')}
              </Button>
            </form>
          )}
        </>
      }
    />
  );
};
