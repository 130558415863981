import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { useGetTransaction, usePostTransaction } from 'api/transactions';
import { getHasHateoasAccess } from 'helpers';
import { useTransactionsFeature } from 'hooks';
import { DYNAMIC_URLS, HTTP_HEADERS, TRANSACTION_DETAILS_TABS } from 'consts';
import { TransactionDetailsTabType } from 'types';
import { DataWrapper, MetaTitle } from 'components/shared';

import { useData } from './hooks';
import { Header } from './Header';
import { DetailTabs } from './DetailTabs';
import { DetailPanels } from './DetailPanels';
import { DataAction } from './DataAction';

export const TransactionDetails = () => {
  const { t } = useTranslation('transactions');
  const navigate = useNavigate();

  const {
    isDataEditable,
    toggleIsDataEditable,
    dataSettings,
    setDataSettings,
    isDataSaving,
    setIsDataSaving,
  } = useData();

  const {
    transactionDataStream,
    transactionId,
    tab: activeTab,
  } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
    tab: TransactionDetailsTabType | undefined;
  };

  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();

  const {
    data: transaction,
    isError: isTransactionError,
    isInitialLoading: isTransactionInitialLoading,
  } = useGetTransaction(
    {
      pathParams: {
        transactionId,
        dataStreamId: transactionDataStream,
      },
    },
    {
      enabled: !isLoadingTransactionsFeature && transactionsFeature,
    },
  );
  const {
    mutateAsync: updateTransaction,
    isError: isUpdateTransactionError,
    isLoading: isUpdatingTransaction,
  } = usePostTransaction({
    headers: {
      [HTTP_HEADERS.xTransactionEntryId]: transaction?.id as string,
    },
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStream,
    },
  });

  const isUpdateTransactionEnabled = getHasHateoasAccess(
    'apply',
    transaction?.__links,
  );
  const hasDocuments = transaction ? transaction.documents.length > 0 : false;
  const defaultTab = hasDocuments
    ? TRANSACTION_DETAILS_TABS.documents
    : TRANSACTION_DETAILS_TABS.data;

  useEffect(() => {
    if (transaction?.documents) {
      const settings = transaction.documents.map(document => ({
        id: document.id,
        lock: document.settings.lock,
        delete: document.settings.delete,
      }));
      setDataSettings(settings);
    }
  }, [transaction, setDataSettings]);

  const hasDataStreamGetAccess = getHasHateoasAccess(
    'dataStream',
    transaction?.__links,
  );
  const hasDataTypeGetAccess = getHasHateoasAccess(
    'dataType',
    transaction?.__links,
  );

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={transaction}
        isError={
          isTransactionError ||
          isUpdateTransactionError ||
          isTransactionFeatureError
        }
        isLoading={isTransactionInitialLoading || isLoadingTransactionsFeature}
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {data => {
          const { transactionName, status, updatedAt, documents } = data;

          return (
            <>
              <MetaTitle value={transactionName ?? transactionId} />
              <Header
                transactionId={transactionId}
                transactionName={transactionName}
                hasDocuments={hasDocuments}
                updateTransaction={updateTransaction}
                isUpdatingTransaction={isUpdatingTransaction}
                isUpdateTransactionDisabled={!isUpdateTransactionEnabled}
                errors={status.errors}
                warnings={status.warnings}
                updatedAt={updatedAt}
              />
              <Box
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: 36,
                  marginBottom: theme.other.spacing(2),
                })}
              >
                <DetailTabs
                  activeTab={activeTab || defaultTab}
                  onTabChange={tab => {
                    navigate(
                      DYNAMIC_URLS.transactionDetails({
                        transactionId,
                        transactionDataStream,
                        tab,
                      }),
                    );
                  }}
                  hasDocuments={documents.length > 0}
                />
                {activeTab === TRANSACTION_DETAILS_TABS.data && (
                  <DataAction
                    transaction={data}
                    isDataEditable={isDataEditable}
                    dataSettings={dataSettings}
                    setIsDataSaving={setIsDataSaving}
                    toggleIsDataEditable={toggleIsDataEditable}
                  />
                )}
              </Box>
              <DetailPanels
                activeTab={activeTab || defaultTab}
                transaction={data}
                isDataEditable={isDataEditable}
                dataSettings={dataSettings}
                isDataSaving={isDataSaving}
                setDataSettings={setDataSettings}
                hasDataStreamGetAccess={hasDataStreamGetAccess}
                hasDataTypeGetAccess={hasDataTypeGetAccess}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
