import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import { DeleteNotificationsRequestType } from '../types';

export const deleteNotifications = async ({
  queryParams: { ids },
}: DeleteNotificationsRequestType) => {
  const query = stringifyUrlQuery({
    ids,
  });

  const url = `/api/v2/notifications${query}`;

  const { data } = await apiAuthClient.delete<{ data: '' }>(url);

  return data;
};
