import { apiAuthClient } from 'api/helpers';

import { PutNotificationsRequestType } from '../types';

export const putNotifications = async ({
  bodyParams,
}: PutNotificationsRequestType) => {
  const url = '/api/v2/notifications';

  const { data } = await apiAuthClient.put<{ data: '' }>(url, bodyParams);

  return data.data;
};
