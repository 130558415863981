import { useTranslation } from 'react-i18next';
import { Checkbox, Text } from '@mantine/core';

import { Modal } from 'components/shared';
import { AgreementsType } from '../types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  errors: {
    phoneCallMarketing: string;
    phoneMessageMarketing: string;
  };
  agreements: AgreementsType;
  changeAgreement: (checked: boolean, field: keyof AgreementsType) => void;
};

export const MarketingContactModal = ({
  isOpen,
  onClose,
  onSubmit,
  errors,
  agreements,
  changeAgreement,
}: Props) => {
  const { t } = useTranslation('user');

  return (
    <Modal
      title={t('user:marketingContactModal.header')}
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonAction={onSubmit}
    >
      <Text size="sm" sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
        {t('user:marketingContactModal.contentPrimary')}
      </Text>
      <Checkbox
        sx={theme => ({
          label: {
            fontSize: theme.fontSizes.sm,
          },
          marginBottom: theme.other.spacing(2),
        })}
        label={t('user:marketingContactModal.phoneVariant')}
        checked={agreements.phoneCallMarketing}
        onChange={e => {
          changeAgreement(e.target.checked, 'phoneCallMarketing');
        }}
        error={errors.phoneCallMarketing}
      />
      <Checkbox
        sx={theme => ({
          label: {
            fontSize: theme.fontSizes.sm,
          },
          marginBottom: theme.other.spacing(1.5),
        })}
        label={t('user:marketingContactModal.smsVariant')}
        checked={agreements.phoneMessageMarketing}
        onChange={e => {
          changeAgreement(e.target.checked, 'phoneMessageMarketing');
        }}
        error={errors.phoneMessageMarketing}
      />
      <Text size="sm">{t('user:marketingContactModal.contentSecondary')}</Text>
    </Modal>
  );
};
