import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import { GetDataTypesResponseType, GetDataTypesRequestType } from '../types';

export const getDataTypes = async ({
  pathParams: { organisationId },
  queryParams: { name, page, perPage, sortDir },
}: GetDataTypesRequestType) => {
  const query = stringifyUrlQuery({
    name,
    page,
    perPage,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/data-types${query}`;

  const { data } = await apiAuthClient.get<GetDataTypesResponseType>(url);

  return data;
};
