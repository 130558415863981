import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';
import { format } from 'date-fns';

import { useGetTemplate, usePostDuplicateTemplate } from 'api/templates';
import { BRANDING_SOURCE } from 'consts';
import {
  useGetCurrentBoardId,
  useGetCurrentOrganisationId,
  useGetV1Url,
  useServerErrors,
  useShowNotification,
} from 'hooks';
import { BoardTemplateListItemType, TemplateType, UserType } from 'types';
import { Modal } from 'components/shared';

import { useGetOrganisations } from 'api/organisations/hooks/useGetOrganisations';
import { OrganisationType } from 'types/organisations/OrganisationsType';
import { useGetCurrentOrganisationBoards } from 'api/organisations/hooks/useGetCurrentOrganisationBoards';
import { useGetUser } from 'api/account';
import { getIsLocalTemplate } from '../helpers';
import { useLocalTemplates } from '../hooks';
import { BoardTemplateLocalListItemType, LocalTemplateType } from '../types';
import { AttachmentStateType } from './types';
import { DuplicateForm } from './DuplicateForm';

const TEMPLATE_NAME_LIMIT = 255;
const LOCAL_ID_DATE_FORMAT = 'yyyy-MM-dd-HH-mm-ss';

const getTemplateNameWithSuffix = (templateName: string) =>
  `${templateName} copy`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  template: BoardTemplateLocalListItemType | TemplateType;
  refetchGetBoardTemplates: () => void;
};

export const DuplicateModal = ({
  isOpen,
  onClose,
  template,
  refetchGetBoardTemplates,
}: Props) => {
  const { id: templateId } = template;
  const isLocalTemplate = getIsLocalTemplate(templateId);
  const templateName = isLocalTemplate
    ? (template as LocalTemplateType).type
    : (template as BoardTemplateListItemType).name;

  const { t } = useTranslation(['common', 'templates']);

  const { addServerErrorActions, getServerFieldError } = useServerErrors();
  const getV1Url = useGetV1Url();
  const showNotification = useShowNotification();

  const { localTemplates, replaceLocalTemplates } = useLocalTemplates();

  const [newTemplateName, setNewTemplateName] = useState(
    getTemplateNameWithSuffix(templateName),
  );
  const [templateNameError, setTemplateNameError] = useState('');
  const [includeBranding, setIncludeBranding] = useState(false);
  const [attachmentsState, setAttachmentsState] =
    useState<AttachmentStateType>(null);

  const currentOrganisationId = useGetCurrentOrganisationId();
  const currentBoardId = useGetCurrentBoardId();

  const [organisationId, setOrganisationId] = useState<string | null>(null);
  const [boardId, setBoardId] = useState<string | null>(null);

  const changedOrganisationId = useRef<boolean | null>(null);

  useEffect(() => {
    setOrganisationId(String(currentOrganisationId));
    setBoardId(String(currentBoardId));
  }, [currentOrganisationId, currentBoardId]);

  const {
    data: templateDetails,
    isError: isLoadingTemplateDetailsError,
    isInitialLoading: isTemplateDetailsInitialLoading,
  } = useGetTemplate(
    {
      pathParams: {
        templateId,
      },
    },
    {
      enabled: isOpen && !isLocalTemplate,
    },
  );

  const { data: user } = useGetUser();
  const { email: currentUserEmail } = user as UserType;

  const { data: organisationsData, isLoading: isOrganisationsLoading } =
    useGetOrganisations();

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  const { data: boardsData, isLoading: isBoardsLoading } =
    useGetCurrentOrganisationBoards(
      {
        pathParams: { organisationId: organisationId! },
      },
      {
        enabled: Boolean(organisationId),
      },
    );

  useEffect(() => {
    if (
      boardsData?.data &&
      boardsData.data.length > 0 &&
      changedOrganisationId.current
    ) {
      setBoardId(String(boardsData.data[0].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardsData]);

  useEffect(() => {
    if (
      !changedOrganisationId.current &&
      organisationId &&
      String(currentOrganisationId) !== organisationId
    ) {
      changedOrganisationId.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    if (organisationsData) {
      setOrganisations(
        (organisationsData as unknown as OrganisationType[]).filter(
          organisation =>
            organisation.admin.some(a => a.email === currentUserEmail),
        ),
      );
    }
  }, [currentUserEmail, organisationsData]);

  const boards = boardsData?.data || [];

  const { mutateAsync: duplicateTemplate, isLoading: isDuplicatingTemplate } =
    usePostDuplicateTemplate();

  const hasTemplateBranding =
    templateDetails?.documentBranding.source === BRANDING_SOURCE.template;

  const handleClose = () => {
    onClose();
    setNewTemplateName(getTemplateNameWithSuffix(templateName));
    setTemplateNameError('');
  };

  useEffect(() => {
    setNewTemplateName(getTemplateNameWithSuffix(templateName));
  }, [templateName]);

  useEffect(() => {
    const attachmentTemplates = templateDetails?.attachmentTemplates;

    if (attachmentTemplates && attachmentTemplates.length > 0) {
      setAttachmentsState(
        attachmentTemplates.reduce(
          (accumulator, { type }) => ({
            ...accumulator,
            [type]: false,
          }),
          {},
        ),
      );
    }
  }, [templateDetails]);

  const isModalLoading =
    isTemplateDetailsInitialLoading ||
    isOrganisationsLoading ||
    isBoardsLoading;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      title={t('templates:templateDuplicationSettings')}
      isError={isLoadingTemplateDetailsError}
      isFullLoading={isModalLoading}
      defaultButtonAction={handleClose}
      defaultButtonDisabled={isDuplicatingTemplate}
      primaryButtonText={t('common:duplicate')}
      primaryButtonIsLoading={isDuplicatingTemplate}
      primaryButtonAction={async () => {
        const name = newTemplateName.trim();

        if (name.length > TEMPLATE_NAME_LIMIT) {
          setTemplateNameError(
            t('common:formErrors.maxNumberOfSigns', {
              max: TEMPLATE_NAME_LIMIT,
            }),
          );

          return;
        }

        if (isLocalTemplate) {
          const localTemplateToDuplicate = localTemplates.find(
            ({ id }) => id === templateId,
          ) as LocalTemplateType;
          const localTemplateId = format(new Date(), LOCAL_ID_DATE_FORMAT);

          replaceLocalTemplates([
            {
              ...localTemplateToDuplicate,
              type: newTemplateName,
              id: localTemplateId,
            },
            ...localTemplates,
          ]);

          window.location.assign(
            getV1Url('templatesNew', {
              localTemplateId,
            }),
          );
        } else {
          const attachmentTypes = attachmentsState
            ? Object.entries(attachmentsState).reduce(
                (accumulator, [attachmentName, checked]) => [
                  ...accumulator,
                  ...(checked ? [attachmentName] : []),
                ],
                [] as string[],
              )
            : null;

          try {
            await duplicateTemplate({
              pathParams: {
                templateId,
              },
              bodyParams: {
                name,
                attachmentTypes,
                includeBranding,
                targetId: boardId,
              },
            });

            refetchGetBoardTemplates();
            onClose();
            showNotification({
              message: t('templates:templateDuplicateSuccess'),
            });
          } catch (error) {
            setTemplateNameError(getServerFieldError(error, 'name'));
            addServerErrorActions(error);
          }
        }
      }}
      data-testid="DuplicateTemplate_DuplicateModal"
      defaultButtonDataTestId="DuplicateTemplate_DuplicateModal_CancelButton"
      primaryButtonDataTestId="DuplicateTemplate_DuplicateModal_SaveButton"
    >
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        size="md"
        weight={400}
      >
        {t('templates:templateDuplicationDescription')}
      </Text>
      <DuplicateForm
        showDuplicateLetterhead={
          hasTemplateBranding &&
          String(currentOrganisationId) === organisationId
        }
        includeBranding={includeBranding}
        setIncludeBranding={setIncludeBranding}
        attachmentsState={attachmentsState}
        newTemplateName={newTemplateName}
        templateNameError={templateNameError}
        setAttachmentsState={setAttachmentsState}
        setNewTemplateName={setNewTemplateName}
        setTemplateNameError={setTemplateNameError}
        organisationId={organisationId}
        setOrganisationId={setOrganisationId}
        boardId={boardId}
        setBoardId={setBoardId}
        organisations={organisations}
        boards={boards}
      />
    </Modal>
  );
};
