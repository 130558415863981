import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

export const Label = ({ inputProps, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.labelValue')}
      description={t(
        'templates:templateCreatorVariables.labelValueDescription',
      )}
      error={error}
    >
      <TextInput
        {...inputProps}
        placeholder={t('templates:templateCreatorVariables.labelValue')}
        withErrorWrapperAlwaysEnabled={false}
      />
    </DescriptionField>
  );
};
