import { apiAuthClient } from 'api/helpers';

import { PostRegisterRequestType, PostRegisterResponseType } from '../types';

export const postRegister = async ({ bodyParams }: PostRegisterRequestType) => {
  const url = '/api/v2/auth/register';

  const { data } = await apiAuthClient.post<PostRegisterResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
