import { FormEvent, useRef, useState } from 'react';
import { Modal, TextInput } from 'components/shared';
import { Box, Checkbox, Text } from '@mantine/core';
import { TFunction } from 'i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { camelize } from 'humps';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { usePatchWebhook, usePostWebhook } from 'api/webhooks';
import { WebhookType } from 'types';
import { useIsMdBreakpoint, useShowNotification } from 'hooks';
import { AttachmentIcon, FileIcon, LinkIcon, QueueIcon } from 'icons';
import { API_QUERY_KEYS } from 'consts';
import {
  EVENTS_UI_SCHEMA,
  getErrorMessage,
  getEventsZodSchema,
  getFormInitialValues,
  parseSubmitData,
} from '../utils';
import { DocumentStatusChangeCheckbox } from './EventComponents';

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  mode: 'create' | 'edit' | null;
  webhook: WebhookType | null;
};

const getSectionLabel = (
  sectionName: 'document' | 'attachment' | 'signQueue',
  t: TFunction,
) => {
  switch (sectionName) {
    case 'document':
      return (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: theme.other.spacing(1),
            marginBottom: theme.other.spacing(1.25),
            marginLeft: -theme.other.spacing(0.5),
          })}
        >
          <FileIcon
            sx={theme => ({
              marginRight: theme.other.spacing(0.5),
            })}
          />
          <Text size={13} weight={700}>
            {t('api:webhooks.webhooksDetailsModalSectionHeaderDocument')}
          </Text>
        </Box>
      );
    case 'attachment':
      return (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: theme.other.spacing(1.75),
            marginBottom: theme.other.spacing(1.25),
            marginLeft: -theme.other.spacing(0.5),
          })}
        >
          <AttachmentIcon
            sx={theme => ({
              marginRight: theme.other.spacing(0.5),
            })}
          />
          <Text size={13} weight={700}>
            {t('api:webhooks.webhooksDetailsModalSectionHeaderAttachment')}
          </Text>
        </Box>
      );
    case 'signQueue':
      return (
        <Box
          sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: theme.other.spacing(1.75),
            marginBottom: theme.other.spacing(1.25),
          })}
        >
          <QueueIcon
            sx={theme => ({
              marginRight: theme.other.spacing(0.5),
            })}
          />
          <Text size={13} weight={700}>
            {t('api:webhooks.webhooksDetailsModalSectionHeaderSignQueue')}
          </Text>
        </Box>
      );
    default:
      return null;
  }
};

export const WebhookDetailsModal = ({
  handleClose,
  isOpen,
  mode,
  webhook,
}: Props) => {
  const [globalError, setGlobalError] = useState<boolean>(false);
  const { t } = useTranslation(['common', 'api']);
  const modalFormRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();
  const showNotification = useShowNotification();
  const isMdBreakpoint = useIsMdBreakpoint();

  const basicSchema = z.object({
    name: z
      .string()
      .min(1, { message: t('common:formErrors.required') })
      .max(255, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: 255,
        }),
      }),
    url: z
      .string()
      .min(1, { message: t('common:formErrors.required') })
      .max(255, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: 255,
        }),
      })
      .refine(
        value =>
          /^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i.test(value),
        {
          message: t('api:webhooks.webhooksDetailsModalUrlValidation'),
        },
      ),
  });

  const combinedSchema = basicSchema
    .merge(z.object(getEventsZodSchema(t)))
    .superRefine(
      (
        {
          document_status_change,
          draft,
          sent,
          in_acceptance,
          in_sign,
          on_hold,
          rejected,
          finished,
        },
        ctx,
      ) => {
        if (
          document_status_change &&
          !draft &&
          !sent &&
          !in_acceptance &&
          !in_sign &&
          !on_hold &&
          !rejected &&
          !finished
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(
              'api:webhooks.webhooksDetailsModalDocumentStatusChangeValidationMessage',
            ),
            path: ['document_status_change'],
          });
        }
      },
    );

  const {
    formState: { errors: formErrors },
    register,
    watch,
    reset,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(combinedSchema),
    mode: 'onBlur',
    defaultValues: getFormInitialValues(mode, webhook),
  });

  const clearGlobalErrorIfSet = () => {
    if (globalError) {
      setGlobalError(false);
    }
  };

  const { mutate: postWebhook, isLoading: isPostWebhookLoading } =
    usePostWebhook({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [API_QUERY_KEYS.webhooks],
          });
          showNotification({
            message: t('api:webhooks.webhooksDetailsModalAddWebhookSnackbar'),
          });
          handleClose();
        },
      },
    });

  const { mutate: patchWebhook, isLoading: isPatchWebhookLoading } =
    usePatchWebhook({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [API_QUERY_KEYS.webhooks],
          });
          showNotification({
            message: t('api:webhooks.webhooksDetailsModalEditWebhookSnackbar'),
          });
          handleClose();
        },
      },
    });

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    handleSubmit(values => {
      const webhookDetails = parseSubmitData(values);

      if (webhookDetails.triggerConfig.length === 0) {
        setGlobalError(true);
        return;
      }

      if (mode === 'create') {
        postWebhook({
          bodyParams: webhookDetails,
        });
      }

      if (mode === 'edit' && webhook?.id) {
        patchWebhook({
          pathParams: { webhookId: webhook.id },
          bodyParams: webhookDetails,
        });
      }
    })(e);
  };

  const getModalTitle = () => {
    if (mode === 'edit') {
      return t('api:webhooks.webhooksDetailsModalEditWebhookModalTitle');
    }

    return t('api:webhooks.webhooksDetailsModalAddWebhookModalTitle');
  };

  const getPrimaryButtonLabel = () => {
    if (mode === 'edit') {
      return t(
        'api:webhooks.webhooksDetailsModalEditWebhookModalPrimaryButtonLabel',
      );
    }

    return t(
      'api:webhooks.webhooksDetailsModalAddWebhookModalPrimaryButtonLabel',
    );
  };

  const getPrimaryButtonColor = () => {
    if (mode === 'edit') {
      return 'primary';
    }

    return 'green';
  };

  const getWebhookEvents = () =>
    Object.keys(EVENTS_UI_SCHEMA).map(key => (
      <Box>
        {getSectionLabel(key as 'document' | 'attachment' | 'signQueue', t)}
        {EVENTS_UI_SCHEMA[key as 'document' | 'attachment' | 'signQueue'].map(
          (event, mainLevelIndex) => {
            if (event.name === 'document_status_change') {
              return (
                <DocumentStatusChangeCheckbox
                  event={event}
                  register={register}
                  watch={watch}
                  formErrors={formErrors}
                  reset={reset}
                  clearGlobalErrorIfSet={clearGlobalErrorIfSet}
                />
              );
            }

            return (
              <Box>
                <Checkbox
                  label={t(`api:webhooks.events.${camelize(event.name)}`)}
                  sx={theme => ({
                    marginTop: theme.other.spacing(2),
                    marginBottom: theme.other.spacing(1),
                  })}
                  {...register(event.name)}
                  onChange={e => {
                    register(event.name).onChange(e);
                    clearGlobalErrorIfSet();
                  }}
                />
                <Text
                  size={13}
                  color="#E63535"
                  sx={theme => ({
                    marginLeft: theme.other.spacing(4.75),
                  })}
                >
                  {getErrorMessage(formErrors[event.name])}
                </Text>
                {event.values.length !== 0 && Boolean(watch(event.name)) && (
                  <Box
                    sx={theme => ({
                      marginLeft: theme.other.spacing(4.75),
                      marginTop: theme.other.spacing(1),
                    })}
                  >
                    {event.values.map(value => {
                      if (value.type === 'input') {
                        return (
                          <TextInput
                            {...register(value.name)}
                            placeholder={t(
                              'api:webhooks.eventsInputPlaceholder',
                            )}
                            sx={theme => ({
                              width: isMdBreakpoint ? '50%' : '100%',
                              marginBottom: theme.other.spacing(2.5),
                            })}
                            error={getErrorMessage(formErrors[value.name])}
                            withErrorWrapperAlwaysEnabled={false}
                          />
                        );
                      }
                      return null;
                    })}
                  </Box>
                )}
                {mainLevelIndex <
                  EVENTS_UI_SCHEMA[
                    key as 'document' | 'attachment' | 'signQueue'
                  ].length -
                    1 && (
                  <hr
                    style={{
                      height: '0px',
                      border: 'none',
                      borderTop: '1px solid #CCC',
                    }}
                  />
                )}
              </Box>
            );
          },
        )}
      </Box>
    ));

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      onClose={handleClose}
      title={getModalTitle()}
      defaultButtonText={t('common:cancel')}
      defaultButtonAction={handleClose}
      primaryButtonText={getPrimaryButtonLabel()}
      primaryButtonColor={getPrimaryButtonColor()}
      primaryButtonAction={() => {
        modalFormRef.current?.requestSubmit();
      }}
      primaryButtonIsLoading={isPostWebhookLoading || isPatchWebhookLoading}
    >
      <form
        ref={modalFormRef}
        key="webhook-modal-form"
        onSubmit={handleFormSubmit}
      >
        <Box>
          <TextInput
            label={t('api:webhooks.webhooksDetailsModalNameFormField')}
            placeholder={t('api:webhooks.webhooksDetailsModalNameFormField')}
            {...register('name')}
            error={getErrorMessage(formErrors?.name)}
          />
          <TextInput
            icon={
              <LinkIcon
                color="gray.3"
                sx={theme => ({ marginLeft: -theme.other.spacing(1) })}
              />
            }
            label={t('api:webhooks.webhooksDetailsModalUrlFormField')}
            description={
              <Text
                size={13}
                weight={400}
                color="#000"
                sx={theme => ({ marginBottom: theme.other.spacing(1) })}
              >
                {t('api:webhooks.webhooksDetailsModalUrlFormFieldDescription')}
              </Text>
            }
            placeholder={t('api:webhooks.webhooksDetailsModalUrlFormField')}
            {...register('url')}
            error={getErrorMessage(formErrors?.url)}
          />
          <Text
            size={14}
            weight={600}
            sx={theme => ({
              marginBottom: theme.other.spacing(1),
            })}
          >
            {t('api:webhooks.webhooksDetailsModalEventsFormField')}
          </Text>
          {globalError && (
            <Text
              size={13}
              weight={400}
              color="#E63535"
              sx={theme => ({ marginBottom: theme.other.spacing(2) })}
            >
              {t('api:webhooks.webhooksDetailsModalGlobalError')}
            </Text>
          )}
          {getWebhookEvents()}
        </Box>
      </form>
    </Modal>
  );
};
