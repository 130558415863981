import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getUser } from '../requests';

export const useGetUser = (options?: {
  enabled?: boolean;
  onError?: (error: unknown) => void;
}) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.user],
    queryFn: getUser,
    ...options,
  });
