import { Link } from 'react-router-dom';
import { ActionIcon, Menu } from '@mantine/core';

import { ContextMenuIcon } from 'icons';

import { ActionIconsItemType } from '../types';

type Props = {
  items: ActionIconsItemType[];
};

export const MobileView = ({ items }: Props) => (
  <Menu>
    <Menu.Target>
      <ActionIcon>
        <ContextMenuIcon color="gray.4" />
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
      {items.map(({ name, colorMobile, ...actionItem }) => {
        const sharedProps = {
          color: colorMobile,
          key: name,
          children: name,
        };

        return 'action' in actionItem ? (
          <Menu.Item {...sharedProps} onClick={actionItem.action} />
        ) : (
          <Menu.Item {...sharedProps} component={Link} to={actionItem.to} />
        );
      })}
    </Menu.Dropdown>
  </Menu>
);
