import { ChangeEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { OTP_DIGITS_NUMBER } from 'consts';
import { TextInput } from 'components/shared';
import { Props as TextInputProps } from 'components/shared/Inputs/TextInput';

import { OtpLabel } from './OtpLabel';
import { ResendOtpDataButton } from './ResendOtpDataButton';

type Props = {
  codeNumber: number;
  validUntil: string;
  isGettingOtpData: boolean;
  resendCode: () => void;
  textInputProps: TextInputProps & {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    ref: RefObject<HTMLInputElement>;
  };
};

export const OtpFlow = ({
  codeNumber,
  validUntil,
  isGettingOtpData,
  resendCode,
  textInputProps: { onChange, ...textInputProps },
}: Props) => {
  const { t } = useTranslation('user');

  return (
    <div>
      <TextInput
        sx={{
          input: {
            letterSpacing: 10,
          },
        }}
        label={<OtpLabel codeNumber={codeNumber} validUntil={validUntil} />}
        placeholder="000000"
        autoComplete="off"
        onChange={e => {
          onChange({
            ...e,
            target: {
              ...e.target,
              value: e.target.value
                .replace(/\D/g, '')
                .slice(0, OTP_DIGITS_NUMBER),
            },
          });
        }}
        {...textInputProps}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Text
          sx={theme => ({
            marginRight: theme.other.spacing(0.5),
          })}
          size="lg"
        >
          {t('user:codeNotArrive')}
        </Text>
        <ResendOtpDataButton
          isGettingOtpData={isGettingOtpData}
          onClick={resendCode}
        />
      </Box>
    </div>
  );
};
