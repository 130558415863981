import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getExternalLetterheadFile } from '../requests';
import { GetExternalLetterheadFileRequestType } from '../types';

export const useGetExternalLetterheadFile = (
  { url }: GetExternalLetterheadFileRequestType,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.externalLetterheadFile,
      {
        url,
      },
    ],
    queryFn: () =>
      getExternalLetterheadFile({
        url,
      }),
    ...options,
  });
