import { forwardRef } from 'react';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { ChevronDownIcon } from 'icons';
import { BoardSelectVariantType } from 'components/Layout/BoardSelect/types';

const HORIZONTAL_PADDING_SPACING = 2;
const CHEVRON_SIZE = 24;

type Props = {
  organisationName?: string;
  boardName?: string;
  image?: string;
  variant: BoardSelectVariantType;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const SelectedBoard = forwardRef<HTMLButtonElement, Props>(
  (
    {
      organisationName,
      boardName,
      image,
      variant,
      isOpen,
      setIsOpen,
      ...popoverMantineTargetProps
    },
    ref,
  ) => (
    <UnstyledButton
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: theme.other.spacing(1.5, HORIZONTAL_PADDING_SPACING),
        backgroundColor: theme.colors[theme.primaryColor][9],
        color: theme.colors[theme.primaryColor][1],
      })}
      ref={ref}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      {...popoverMantineTargetProps}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          [`@media (min-width: ${theme.breakpoints.md}px)`]: {
            maxWidth: `calc(${
              theme.other.layoutSizes.navbarWidths[1]
            }px - ${theme.other.spacing(
              HORIZONTAL_PADDING_SPACING * 2,
            )}px - ${CHEVRON_SIZE}px)`,
          },
        })}
      >
        {image && (
          <Box
            sx={theme => ({
              maxWidth: 30,
              maxHeight: 30,
              marginRight: theme.other.spacing(1),
              borderRadius: theme.radius.sm,
            })}
            component="img"
            src={image}
            alt={boardName}
          />
        )}
        <Box
          sx={theme => ({
            marginRight: theme.other.spacing(2),
            overflow: 'hidden',
            width: 90,
            '@media (min-width: 400px)': {
              width: 170,
            },
            [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
              width: 340,
            },
            [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
              width: 540,
            },
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              width: 'initial',
            },
          })}
        >
          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            size="sm"
          >
            {organisationName}
          </Text>
          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            size="sm"
          >
            {boardName}
          </Text>
        </Box>
      </Box>
      {variant === 'navbar' && (
        <ChevronDownIcon
          sx={theme => ({
            display: 'flex',
            transition: `transform ${theme.other.animationsTime} ease`,
            transform: `rotate(${isOpen ? '180' : '0'}deg)`,
          })}
          size={CHEVRON_SIZE}
        />
      )}
    </UnstyledButton>
  ),
);
