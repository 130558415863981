import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const EditIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5 15-.53-.53-.22.22V15H5ZM15 5l.53-.53a.75.75 0 0 0-1.06 0L15 5Zm4 4 .53.53a.75.75 0 0 0 0-1.06L19 9ZM9 19v.75h.31l.22-.22L9 19Zm-4 0h-.75c0 .414.336.75.75.75V19Zm.53-3.47 10-10-1.06-1.06-10 10 1.06 1.06Zm8.94-10 4 4 1.06-1.06-4-4-1.06 1.06Zm4 2.94-10 10 1.06 1.06 10-10-1.06-1.06ZM9 18.25H5v1.5h4v-1.5ZM5.75 19v-4h-1.5v4h1.5Z"
        fill={iconColor}
      />
    </Box>
  );
};
