import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';

import { useChangeTranslations } from 'hooks';
import { LANGUAGE_LIST } from 'consts';
import { LanguageKeyType } from 'types';

import { SelectItem } from './SelectItem';

export const LanguageSelect = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  const changeTranslations = useChangeTranslations();

  const LanguageIcon = LANGUAGE_LIST.find(({ value }) => value === language)
    ?.Icon;

  return (
    <Select
      sx={{
        width: 180,
      }}
      styles={{
        wrapper: {
          marginBottom: 0,
        },
        icon: {
          width: 18,
          height: 18,
          transform: 'translateX(1px)',
        },
        withIcon: {
          paddingLeft: 38,
        },
      }}
      value={language}
      onChange={(value: LanguageKeyType) => {
        changeTranslations(value);
      }}
      icon={LanguageIcon && <LanguageIcon />}
      data={LANGUAGE_LIST.map(({ labelTranslation, ...languageItem }) => ({
        ...languageItem,
        label: t(labelTranslation),
      }))}
      itemComponent={SelectItem}
      error=""
    />
  );
};
