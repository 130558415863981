import { ReactNode } from 'react';
import {
  DefaultProps,
  MantineColor,
  packSx,
  Text,
  UnstyledButton,
} from '@mantine/core';

import { Status } from 'components/shared';

type Props = {
  children: ReactNode;
  statusColor: MantineColor;
  isBold: boolean;
  sx?: DefaultProps['sx'];
  onClick?: () => void;
};

export const AccessButton = ({
  children,
  statusColor,
  isBold,
  sx,
  onClick,
}: Props) => (
  <UnstyledButton
    sx={[
      {
        display: 'flex',
        alignItems: 'center',
      },
      ...packSx(sx),
    ]}
    onClick={onClick}
  >
    <Status
      sx={theme => ({ marginRight: theme.other.spacing(1) })}
      color={statusColor}
    />
    <Text span size="md" weight={isBold ? 600 : 400}>
      {children}
    </Text>
  </UnstyledButton>
);
