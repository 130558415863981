import { Text } from '@react-pdf/renderer';

import { Title } from './Title';

export const SampleDocumentContent = () => (
  <>
    <Title>Lorem ipsum dolor sit amet, consectetur adipiscing</Title>
    <Text style={{ textAlign: 'justify' }}>
      Vestibulum neque massa, scelerisque sit amet ligula eu, congue molestie
      mi. Praesent utvarius sem. Nullam at porttitor arcu, nec lacinia nisi. Ut
      ac dolor vitae odio interdumcondimentum.{' '}
      <Text style={{ fontWeight: 'bold' }}>
        Vivamus dapibus sodales ex, vitae malesuada ipsum cursusconvallis.
        Maecenas sed egestas nulla, ac condimentum orci.
      </Text>{' '}
      Mauris diam felis,vulputate ac suscipit et, iaculis non est. Curabitur
      semper arcu ac ligula semper, nec luctusnisl blandit. Integer lacinia ante
      ac libero lobortis imperdiet. Nullam mollis convallis ipsum, ac accumsan
      nunc vehicula vitae. Nulla eget justo in felis tristique fringilla. Morbi
      sit amettortor quis risus auctor condimentum. Morbi in ullamcorper elit.
      Nulla iaculis tellus sit ametmauris tempus fringilla.
      {'\n'}
      Maecenas mauris lectus, lobortis et purus mattis, blandit dictum tellus.
      {'\n'}• Maecenas non lorem quis tellus placerat varius.
      {'\n'}• Nulla facilisi.
      {'\n'}• Aenean congue fringilla justo ut aliquam.
      {'\n'}• Mauris id ex erat.
      {'\n'}• Nunc vulputate neque vitae justo facilisis, non condimentum
      antesagittis.
      {'\n'}• Morbi viverra semper lorem nec molestie.
      {'\n'}• Maecenas tincidunt est efficitur ligula euismod, sit amet ornare
      est vulputate.
      {'\n'}
      Proin eget purus scelerisque, cursus elit nec, elementum nisl. Suspendisse
      efficitur suscipit orci a dignissim. Proin quis dignissim lorem. Vivamus
      viverra lorem quis tortor auctor egestas. Integer neque magna, vestibulum
      non varius sit amet, laoreet sit amet ante. Donec lobortis nunc sit amet
      libero aliquet, feugiat accumsan velit auctor. Duis vehicula nec lacus et
      volutpat. Integer porta laoreet est, vel gravida eros egestas sit amet.
      Nam ac turpis vitae quam scelerisque imperdiet et a leo. Nunc imperdiet
      hendrerit risus, in pretium lectus tincidunt non. Vestibulum ut massa
      erat. Proin non libero iaculis erat laoreet dictum. Donec a dui sed sapien
      egestas pharetra. Suspendisse nec justo ligula. Nullam quis sem sem.
      Aenean posuere mattis tortor, eget feugiat turpis mattis at. Phasellus
      luctus accumsan nibh, vitae aliquam magna scelerisque in. Vivamus
      hendrerit, nunc eget euismod interdum, libero nunc tempus mauris, a
      bibendum nulla arcu a urna. Cras dapibus, turpis id dapibus tristique,
      ante neque lacinia ligula, eleifend hendrerit odio dolor sit amet tellus.
      Mauris sed suscipit dolor, et malesuada ex. In sed elementum ex. Nulla nec
      purus ac orci venenatis condimentum sed et quam. Maecenas scelerisque
      dolor ut lectus gravida viverra.{' '}
      <Text style={{ fontWeight: 'bold' }}>
        Sed consequat massa ultricies ante facilisis porttitor.
      </Text>{' '}
      Duis gravida ornare consequat. Etiam id dapibus massa Cras fermentum ipsum
      ut augue convallis, quis imperdiet mauris rutrum. Fusce bibendum
      condimentum purus sit amet convallis. Ut non lacus ut orci placerat
      consequat a sit amet sem. Donec non orci mauris. Sed pretium mattis massa,
      pharetra pulvinar neque sodales vel. Donec eleifend mollis magna. Quisque
      aliquam nunc sed odio congue, nec molestie quam fermentum. Vestibulum eget
      porttitor lacus. Curabitur vulputate sodales elit, sed mattis elit
      vestibulum sit amet. Vestibulum auctor nunc at laoreet blandit. Quisque
      eleifend, nunc nec euismod convallis, erat massa rutrum mauris, id
      pulvinar diam quam blandit lacus. Donec a enim in dolor vestibulum lacinia
      non a odio. Maecenas non ultrices nisi. Aliquam id nulla et quam congue
      iaculis quis vel leo. Pellentesque accumsan eros venenatis, egestas nunc
      vel, dignissim tortor. Ut varius quis ex in tristique. Etiam ac tellus
      nisi. Nullam non facilisis mauris, sit amet sollicitudin arcu. Etiam non
      velit nec mi efficitur interdum. Etiam placerat dui vitae fringilla
      auctor. Fusce rutrum mauris vitae libero dapibus, sed mollis enim semper.
      Duis velit orci, tempus quis tempus non, bibendum nec velit pellentesque.
      Cras euismod enim sed fermentum fringilla. Aliquam eu sem massa. Vivamus
      et sagittis erat. Proin a ultricies dui, id congue lorem. Sed viverra odio
      id luctus rutrum. In vulputate facilisis massa a hendrerit. Nullam euismod
      dolor nec venenatis aliquet. Maecenas varius blandit sodales. Cras
      consequat egestas lorem, vitae lobortis enim tempor porta. Sed in euismod
      eros, placerat viverra risus. Proin nibh libero, efficitur vel lobortis
      auctor, dictum id arcu. Maecenas tempus nulla et nisl interdum, in gravida
      urna hendrerit. Class aptent taciti sociosqu ad litora torquent per
      conubia nostra, per inceptos himenaeos. Mauris orci sem, egestas eu
      commodo vitae, condimentum et justo. Vivamus posuere ex vitae commodo
      volutpat. Orci varius natoque penatibus et magnis dis parturient montes,
      nascetur ridiculus mus. In hac habitasse platea dictumst. Maecenas
      ultricies pretium ex eget laoreet. Nulla facilisi. Nunc quis erat sed erat
      accumsan ultrices sit amet at dolor. Quisque auctor efficitur erat, at
      scelerisque risus pharetra nec. Etiam auctor libero at lorem ornare,
      ultrices accumsan velit hendrerit. Duis pretium orci sit amet dapibus
      dapibus. Mauris et sem et elit posuere aliquam ut placerat quam.{' '}
      <Text style={{ fontWeight: 'bold' }}>
        Suspendisse tempus nulla sed convallis imperdiet.
      </Text>{' '}
      Proin mollis sagittis tincidunt. Donec pretium erat lectus, id
      pellentesque velit iaculis ut. Donec ac bibendum tortor. Ut vulputate
      velit eget risus convallis, ullamcorper elementum felis venenatis. Aliquam
      ut mollis nisl. Proin hendrerit magna et erat euismod vestibulum.
      Suspendisse ultricies tellus quis felis eleifend rhoncus. Ut scelerisque,
      dolor nec porta volutpat, dui dui mollis metus, vitae varius tellus augue
      vel nisi. Vivamus consectetur eros non est rutrum, at posuere ipsum
      porttitor. Aenean at feugiat diam, ut vestibulum ante. Duis rhoncus libero
      tellus, eu cursus velit cursus ut. Maecenas hendrerit augue non velit
      interdum congue. Proin pretium lacus ac diam tristique pharetra. Sed
      venenatis elementum blandit. Mauris pulvinar accumsan sem vitae porta.
      Donec ultricies ac urna ac pretium. Vestibulum id tellus luctus, facilisis
      est eu, molestie magna.{' '}
      <Text style={{ fontWeight: 'bold' }}>Sed tempor varius porta.</Text> Donec
      accumsan diam vitae metus vestibulum, ut dignissim est ultrices. Class
      aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
      himenaeos. Suspendisse non diam nulla. Ut sapien sapien, consequat et
      ipsum egestas, mattis sagittis tortor. Ut at augue velit. Fusce congue
      nibh vitae euismod elementum. Morbi vestibulum eu nibh non sodales. Etiam
      dignissim faucibus nisl ac luctus. Vestibulum blandit a nulla in dapibus.
      Nunc faucibus nulla quis nisl laoreet aliquam. Mauris efficitur lacinia mi
      bibendum imperdiet. Maecenas vestibulum massa ac erat pellentesque, vel
      consequat nisl vulputate.
    </Text>
  </>
);
