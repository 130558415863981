import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, ScrollArea, Text } from '@mantine/core';

import { LogoExtendedIcon } from 'icons';

import { useGetNavbarLinks, useLocalDocumentsCount } from '../hooks';
import { Links } from './Links';

export const NavbarFull = () => {
  const { t } = useTranslation('navigation');
  const navbarLinks = useGetNavbarLinks();
  const localContractsCount = useLocalDocumentsCount();

  return (
    <>
      <Navbar.Section
        sx={theme => ({
          margin: theme.other.spacing(3, 'auto', 0),
        })}
      >
        <LogoExtendedIcon color="black" />
      </Navbar.Section>
      <Navbar.Section
        grow
        component={ScrollArea}
        styles={theme => ({
          viewport: {
            paddingRight: theme.other.spacing(0.5),
            paddingLeft:
              theme.other.spacing(0.5) +
              1 /* to make outline visible during focus */,
          },
        })}
      >
        {navbarLinks.map(({ sectionTranslateKey, links }) => (
          <Fragment key={sectionTranslateKey}>
            <Text
              size="sm"
              color="gray.7"
              sx={theme => ({
                padding: theme.other.spacing(4, 1, 1),
              })}
            >
              {t(sectionTranslateKey)}
            </Text>
            <Links links={links} localContractsCount={localContractsCount} />
          </Fragment>
        ))}
      </Navbar.Section>
    </>
  );
};
