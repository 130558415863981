import { useLocalStorage } from '@mantine/hooks';

import { STORAGE_KEYS } from 'consts';

import { LocalTemplateType } from '../types';

export const useLocalTemplates = () => {
  const [localTemplatesData, setLocalTemplatesData] = useLocalStorage<{
    data: string;
    _persist: string;
  }>({
    key: STORAGE_KEYS.localTemplates,
    getInitialValueInEffect: false,
  });

  return {
    localTemplates: (localTemplatesData
      ? JSON.parse(localTemplatesData.data)
      : []) as LocalTemplateType[],
    replaceLocalTemplates: (newLocalTemplates: LocalTemplateType[]) => {
      setLocalTemplatesData(data => ({
        ...data,
        data: JSON.stringify(newLocalTemplates),
      }));
    },
  };
};
