import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Select, Text } from '@mantine/core';
import { useDocumentInvitationFlow } from 'hooks/useDocumentInvitationFlow';
import { InvitationFlowModalContext } from 'context/InvitationFlowModal';
import { FileIconBig } from 'icons';
import { useGetV1Url } from 'hooks';
import { DocumentInvitationStatus } from 'types/documentInvitation';
import { useIsXsBreakpoint } from 'hooks/useIsXsBreakpoint';
import { Modal } from '../Modal';

export const InvitationFlowModal = () => {
  const { t } = useTranslation(['common', 'documents']);
  const isXsBreakpoint = useIsXsBreakpoint();
  const [selectedBoardId, setSelectedBoardId] = useState<string | null>('');
  const [showBoardSelectionModal, setShowBoardSelectionModal] = useState(false);
  const { invitationId, isModalOpen, closeModal } = useContext(
    InvitationFlowModalContext,
  );

  const getV1Url = useGetV1Url();

  const {
    boards,
    documents,
    isInitialLoading,
    status,
    handlePatchDocumentInvitation,
  } = useDocumentInvitationFlow({
    invitationId: invitationId || '',
  });

  const handleCancel = () => {
    closeModal();
  };

  const handleSelectAndGoBack = () => {
    handlePatchDocumentInvitation(selectedBoardId || '', () => {
      closeModal();
    });
  };

  const handleSelectAndShow = () => {
    handlePatchDocumentInvitation(selectedBoardId || '', () => {
      window.location.assign(
        getV1Url('contract', {
          documentId: documents[0].id,
        }),
      );
    });
  };

  useEffect(() => {
    if (status === DocumentInvitationStatus.accepted) {
      window.location.assign(
        getV1Url('contract', {
          documentId: documents[0].id,
        }),
      );
    }
    if (
      [
        DocumentInvitationStatus.waiting,
        DocumentInvitationStatus.rejected,
      ].includes(status)
    ) {
      if (boards?.length) {
        if (boards?.length === 1) {
          handlePatchDocumentInvitation(`${boards[0].id}`, () => {
            window.location.assign(
              getV1Url('contract', {
                documentId: documents[0].id,
              }),
            );
          });
        }
        if (boards?.length > 1 && !showBoardSelectionModal) {
          setShowBoardSelectionModal(true);
        }
      }
    }
  }, [
    boards,
    documents,
    getV1Url,
    handlePatchDocumentInvitation,
    showBoardSelectionModal,
    status,
  ]);

  if (!showBoardSelectionModal) {
    return null;
  }

  return (
    <Modal
      onClose={closeModal}
      isOpen={isModalOpen}
      title={t('documents:invitationModal.title')}
      size={isXsBreakpoint ? 'sm' : 'md'}
      isFullLoading={isInitialLoading}
    >
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(2) })}
        align="left"
        weight={400}
        size={13}
      >
        {t('documents:invitationModal.description')}
      </Text>
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        component="ul"
      >
        {Array.isArray(documents) &&
          documents.map(({ id, name }) => (
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                marginBottom: theme.other.spacing(1),
                fontSize: theme.fontSizes.lg,
              })}
              component="li"
              key={id}
            >
              <FileIconBig
                sx={theme => ({
                  flexShrink: 0,
                  marginRight: theme.other.spacing(0.5),
                })}
              />
              <Text align="left" weight={600}>
                {name}
              </Text>
            </Box>
          ))}
      </Box>
      <Select
        placeholder={t('documents:invitationModal.selectBoardPlaceholder')}
        value={selectedBoardId}
        error={null}
        data={
          boards
            ? boards.map(board => ({
                value: `${board.id}`,
                label: `${board.organisation.name} | ${board.name}`,
              }))
            : []
        }
        onChange={setSelectedBoardId}
      />
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: isXsBreakpoint ? 'column' : 'row',
          justifyContent: isXsBreakpoint ? 'center' : 'flex-end',
          marginTop: theme.other.spacing(3),
          gap: theme.other.spacing(1),
          alignItems: 'center',
        })}
      >
        <Button variant="default" onClick={handleCancel}>
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSelectAndGoBack} disabled={!selectedBoardId}>
          {t('documents:invitationModal.selectAndGoBackButtonLabel')}
        </Button>
        <Button onClick={handleSelectAndShow} disabled={!selectedBoardId}>
          {t('documents:invitationModal.selectAndShowButtonLabel')}
        </Button>
      </Box>
    </Modal>
  );
};
