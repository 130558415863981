import { matchPath } from 'react-router-dom';

import { URLS } from 'consts';

import { LinkType } from 'components/Layout/Navbar/types';

export const getIsMatch = (
  { toKey, links }: LinkType,
  pathname: string,
): boolean => {
  if (toKey) {
    const path = URLS[toKey];

    return Boolean(matchPath(path, pathname));
  }

  if (links) {
    return links.some(link => getIsMatch(link, pathname));
  }

  return false;
};
