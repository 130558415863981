import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Checkbox, Group, Text, Tooltip } from '@mantine/core';

import { ErrorTwoToneIcon, HelpIcon, WarningTwoToneIcon } from 'icons';
import {
  SimpleStatus,
  StickyContent,
  Table,
  TextInputSearch,
} from 'components/shared';
import { DocumentInfoModal, InfoStatus } from 'components/Transactions';
import { SigningQueueButton } from 'pages/TransactionDetails/DetailPanels/DataPanel/SigningQueueButton';
import { QueueInfoModal } from 'pages/TransactionDetails/DetailPanels/DataPanel/QueueInfoModal';

import { TransactionType } from 'types';

import { DataSettingsType } from '../../types';
import { FileInfo } from './FileInfo';

type Action = 'showDocumentInfo' | 'showSigningQueueInfo' | null;
type SelectedDocumentId = string | null;

type Props = {
  transaction: TransactionType;
  isDataEditable: boolean;
  dataSettings: DataSettingsType;
  isDataSaving: boolean;
  setDataSettings: (settings: DataSettingsType) => void;
};

export const DataPanel = ({
  transaction,
  isDataEditable,
  dataSettings,
  isDataSaving,
  setDataSettings,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const [modalAction, setModalAction] = useState<{
    type: 'showDocumentInfo' | 'showSigningQueueInfo' | null;
    selectedDocumentId: SelectedDocumentId;
  }>({
    type: null,
    selectedDocumentId: null,
  });
  const [documentNameSearchValue, setDocumentNameSearchValue] = useState('');

  const openModal = (type: Action, selectedDocumentId: SelectedDocumentId) => {
    setModalAction({
      type,
      selectedDocumentId,
    });
  };

  const closeActiveModal = () => {
    setModalAction({
      type: null,
      selectedDocumentId: null,
    });
  };

  const { documents } = transaction;
  const filteredDocuments = documents.filter(({ documentName }) =>
    documentName
      ? documentName
          .toLowerCase()
          .includes(documentNameSearchValue.toLowerCase())
      : false,
  );

  const selectedDocument = modalAction.selectedDocumentId
    ? filteredDocuments.find(({ id }) => id === modalAction.selectedDocumentId)
    : null;

  return (
    <>
      <StickyContent>
        <TextInputSearch
          sx={theme => ({
            maxWidth: 328,
            width: '100%',
            marginLeft: 'auto',
            paddingBottom: theme.other.spacing(2),
          })}
          placeholder={t('transactions:transactionDocuments.searchDocument')}
          value={documentNameSearchValue}
          onChange={e => {
            setDocumentNameSearchValue(e.target.value);
          }}
        />
      </StickyContent>
      <Table hasData={filteredDocuments.length > 0} isLoading={isDataSaving}>
        <thead>
          <tr>
            <th>{t('transactions:transactionDetails.documentName')}</th>
            <th>{t('transactions:transactionDetails.templateName')}</th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          {filteredDocuments.map((document, index) => {
            const hasDocumentInfoModalData =
              document.status.errors.length > 0 ||
              document.status.warnings.length > 0 ||
              Object.keys(document.variables).length > 0;

            const { queue, errors: queueErrors } = document.queue;
            const hasSigningQueue =
              queue.acceptors?.length > 0 || queue.signers?.length > 0;

            return (
              <Fragment key={document.id}>
                <Box
                  sx={
                    document.template.fromFile
                      ? {
                          '&>td': {
                            borderBottom: 'none',
                          },
                        }
                      : undefined
                  }
                  component="tr"
                >
                  <td>
                    <Text
                      sx={theme => ({
                        display: 'block',
                        marginBottom: theme.other.spacing(0.5),
                      })}
                      span
                      size="md"
                      color="gray.9"
                    >
                      {t('common:id')} {document.id}
                    </Text>
                    {hasDocumentInfoModalData ? (
                      <Anchor
                        sx={{
                          maxWidth: '100%',
                          fontWeight: 600,
                        }}
                        component="button"
                        onClick={() => {
                          openModal('showDocumentInfo', document.id);
                        }}
                      >
                        {document.documentName ||
                          t('transactions:documentWithoutName')}
                      </Anchor>
                    ) : (
                      document.documentName
                    )}
                    <Group
                      sx={theme => ({
                        marginTop:
                          hasSigningQueue ||
                          document.status.errors.length > 0 ||
                          document.status.warnings.length > 0
                            ? theme.other.spacing(2.5)
                            : undefined,
                      })}
                      spacing="lg"
                    >
                      {hasSigningQueue && (
                        <SigningQueueButton
                          hasQueueErrors={Object.keys(queueErrors).length > 0}
                          onClick={() => {
                            openModal('showSigningQueueInfo', document.id);
                          }}
                        />
                      )}
                      <InfoStatus
                        count={document.status.errors.length}
                        label={t('transactions:errors')}
                        icon={ErrorTwoToneIcon}
                        iconColor="error"
                      />
                      <InfoStatus
                        count={document.status.warnings.length}
                        label={t('transactions:warnings')}
                        icon={WarningTwoToneIcon}
                        iconColor="yellow.0"
                      />
                    </Group>
                  </td>
                  <td>
                    {document.template.fromFile ? (
                      <FileInfo
                        sizeInBytes={document.template.size}
                        extension={document.template.extension}
                      />
                    ) : (
                      document.template.name
                    )}
                  </td>
                  <Box
                    sx={{
                      textAlign: 'right',
                    }}
                    component="td"
                  >
                    {isDataEditable ? (
                      <Tooltip
                        sx={{
                          maxWidth: 370,
                          textAlign: 'left',
                          whiteSpace: 'initial',
                        }}
                        disabled={document.template.fromFile}
                        label={t(
                          'transactions:transactionDetails.updateTooltip',
                        )}
                      >
                        <Box
                          sx={{
                            display: 'inline-block',
                            width: 'fit-content',
                          }}
                          component="span"
                        >
                          <Checkbox
                            checked={!dataSettings[index]?.lock || false}
                            onChange={e => {
                              const newDataSettings = dataSettings.map(
                                (item, itemIndex) => {
                                  if (index === itemIndex) {
                                    return {
                                      ...item,
                                      lock: !e.currentTarget.checked,
                                    };
                                  }

                                  return item;
                                },
                              );

                              setDataSettings(newDataSettings);
                            }}
                            label={t('transactions:transactionDetails.update')}
                            disabled={document.template.fromFile}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {!document.template.fromFile && (
                          <SimpleStatus
                            labelOn={t(
                              'transactions:transactionDetails.update',
                            )}
                            labelOff={t(
                              'transactions:transactionDetails.update',
                            )}
                            labelTooltip={t(
                              'transactions:transactionDetails.statusTooltip',
                            )}
                            on={!dataSettings[index]?.lock || false}
                          />
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'right',
                    }}
                    component="td"
                  >
                    {isDataEditable ? (
                      <Tooltip
                        label={t(
                          'transactions:transactionDetails.excludeTooltip',
                        )}
                      >
                        <Box
                          sx={{
                            display: 'inline-block',
                            width: 'fit-content',
                          }}
                          component="span"
                        >
                          <Checkbox
                            checked={dataSettings[index]?.delete || false}
                            onChange={e => {
                              const newDataSettings = dataSettings.map(
                                (item, itemIndex) => {
                                  if (index === itemIndex) {
                                    return {
                                      ...item,
                                      delete: e.currentTarget.checked,
                                    };
                                  }

                                  return item;
                                },
                              );

                              setDataSettings(newDataSettings);
                            }}
                            label={t('transactions:transactionDetails.exclude')}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <SimpleStatus
                        labelOn={t('transactions:transactionDetails.exclude')}
                        labelOff={t('transactions:transactionDetails.exclude')}
                        labelTooltip={t(
                          'transactions:transactionDetails.statusTooltip',
                        )}
                        on={dataSettings[index]?.delete || false}
                      />
                    )}
                  </Box>
                </Box>
                {document.template.fromFile && (
                  <Box
                    sx={{
                      '&>td': {
                        paddingTop: 0,
                      },
                    }}
                    component="tr"
                  >
                    <td />
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <td />
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <td />
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <td>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                        component="span"
                      >
                        <HelpIcon
                          sx={theme => ({
                            marginRight: theme.other.spacing(1),
                          })}
                        />
                        <Text span size="md">
                          {t(
                            'transactions:transactionDetails.documentContentIsNotUpdated',
                          )}
                        </Text>
                      </Box>
                    </td>
                  </Box>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      {selectedDocument && (
        <>
          <DocumentInfoModal
            isOpen={modalAction.type === 'showDocumentInfo'}
            onClose={closeActiveModal}
            transactionId={transaction?.transactionId}
            errors={selectedDocument.status.errors}
            warnings={selectedDocument.status.warnings}
            variables={selectedDocument.variables}
          />
          <QueueInfoModal
            isOpen={modalAction.type === 'showSigningQueueInfo'}
            onClose={closeActiveModal}
            documentName={selectedDocument.documentName}
            queue={selectedDocument.queue.queue}
            queueErrors={selectedDocument.queue.errors}
          />
        </>
      )}
    </>
  );
};
