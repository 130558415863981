import { useTranslation } from 'react-i18next';
import { Badge } from '@mantine/core';

import { DOCUMENT_STAGES } from 'consts';

import {
  getDocumentStageTranslationKey,
  getDocumentStageBadgeStyleProps,
} from '../../../helpers';

type Props = {
  stage: (typeof DOCUMENT_STAGES)[keyof typeof DOCUMENT_STAGES];
};

export const DocumentStageBadge = ({ stage }: Props) => {
  const { t } = useTranslation('documents');

  const { color, variant } = getDocumentStageBadgeStyleProps(stage);

  return color && variant ? (
    <Badge color={color} variant={variant}>
      {t(getDocumentStageTranslationKey(stage))}
    </Badge>
  ) : null;
};
