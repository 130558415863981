import { Box, Loader, Text, Sx } from '@mantine/core';

type Props = {
  children: string;
  sx?: Sx;
};

export const TextLoader = ({ children, sx }: Props) => (
  <Box sx={[{ textAlign: 'center' }, sx]}>
    <Loader sx={theme => ({ marginBottom: theme.other.spacing(0.25) })} />
    <Text>{children}</Text>
  </Box>
);
