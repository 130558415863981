import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteAuthLogout } from 'api/auth';
import { useClearLoginData, useShowNotification } from 'hooks';

export const useLogout = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('user');
  const showNotification = useShowNotification();
  const { mutateAsync } = useDeleteAuthLogout();
  const clearLoginData = useClearLoginData();

  return useCallback(
    async (token: string) => {
      try {
        await mutateAsync(token);
        showNotification({
          message: t('user:signedOutSuccessfully'),
        });
      } finally {
        clearLoginData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearLoginData, mutateAsync, language],
  );
};
