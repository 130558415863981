import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const HelpIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13.714V12h.571c.947 0 1.715-.768 1.715-1.714v-.115a1.6 1.6 0 0 0-1.6-1.6H12c-.947 0-1.714.768-1.714 1.715m1.143 5.143h1.142M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z"
        stroke={iconColor}
        strokeWidth={1.5}
      />
    </Box>
  );
};
