import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@mantine/core';

import {
  PercentageContainer,
  SectionHeader,
  StickyContent,
  TextInputReactRouterSearch,
} from 'components/shared';

import { DataTypeAddModal } from '../DataTypeAddModal';

type Props = {
  hasDataTypeCreateAccess: boolean;
};

export const Header = ({ hasDataTypeCreateAccess }: Props) => {
  const { t } = useTranslation('dataTypes');

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  return (
    <>
      <StickyContent hasPageHeaderSpacings>
        <SectionHeader>{t('dataTypes:dataTypes')}</SectionHeader>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(4),
          })}
          size="md"
        >
          {t('dataTypes:pageDescription')}
        </Text>
        <PercentageContainer
          sx={{
            display: 'flex',
          }}
        >
          {hasDataTypeCreateAccess && (
            <Button
              variant="light"
              color="green"
              onClick={() => {
                setIsAddModalOpen(true);
              }}
            >
              {t('dataTypes:addNew')}
            </Button>
          )}
          <TextInputReactRouterSearch
            sx={{
              maxWidth: 328,
              width: '100%',
              marginLeft: 'auto',
            }}
            placeholder={t('dataTypes:dataTypeSearchPlaceholder')}
          />
        </PercentageContainer>
      </StickyContent>
      {hasDataTypeCreateAccess && (
        <DataTypeAddModal
          isOpen={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
          }}
        />
      )}
    </>
  );
};
