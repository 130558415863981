import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, DefaultProps, packSx } from '@mantine/core';

type Props = {
  goToPrevPage?: () => void;
  goToNextPage?: () => void;
  isStickyToBottom?: boolean;
  sx?: DefaultProps['sx'];
};

export const CursorPagination = ({
  goToPrevPage,
  goToNextPage,
  isStickyToBottom,
  sx,
}: Props) => {
  const { t } = useTranslation('transactions');

  return goToPrevPage || goToNextPage ? (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          ...(isStickyToBottom && {
            bottom: theme.other.spacing(-2),
            margin: theme.other.spacing(0, -1, -1),
            padding: theme.other.spacing(0, 1, 1),
            backgroundColor: theme.other.layoutBodyColor,
            zIndex: 2,
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              position: 'sticky',
            },
          }),
        }),
        ...packSx(sx),
      ]}
    >
      {goToPrevPage && (
        <Anchor
          sx={theme => ({
            marginRight: goToNextPage ? theme.other.spacing(1.5) : undefined,
          })}
        >
          <Button variant="default" onClick={goToPrevPage}>
            {t('transactions:transactionListPrevPage')}
          </Button>
        </Anchor>
      )}
      {goToNextPage && (
        <Anchor>
          <Button variant="default" onClick={goToNextPage}>
            {t('transactions:transactionListNextPage')}
          </Button>
        </Anchor>
      )}
    </Box>
  ) : null;
};
