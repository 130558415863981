import { MantineTheme } from '@mantine/core';

import { ThemeComponentType } from '../types';
import { colors, primaryColor } from '../consts';

export const Tooltip: ThemeComponentType = {
  defaultProps: {
    color: (colors as MantineTheme['colors'])[primaryColor][9],
    position: 'bottom',
    withArrow: true,
    arrowSize: 6,
  },
  styles: theme => ({
    tooltip: {
      padding: theme.other.spacing(2),
      fontSize: theme.fontSizes.sm,
      lineHeight: '13px',
      color: theme.colors[theme.primaryColor][1],
    },
  }),
};
