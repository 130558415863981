import { ComponentProps, MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Loader,
  MantineColor,
  Modal as ModalMantine,
  useMantineTheme,
} from '@mantine/core';

import { Error, TextLoader } from 'components/shared';

export type Props = {
  isOpen: boolean;
  children: ReactNode;
  onClose?: () => void;
  defaultButtonAction?: (e: MouseEvent<HTMLButtonElement>) => void;
  defaultButtonDisabled?: boolean;
  defaultButtonText?: string;
  primaryButtonAction?: (e: MouseEvent<HTMLButtonElement>) => void;
  primaryButtonColor?: MantineColor;
  primaryButtonDisabled?: boolean;
  primaryButtonIsLoading?: boolean;
  primaryButtonRightIcon?: ReactNode;
  primaryButtonText?: string;
  isRedButtonLeftAligned?: boolean;
  redButtonAction?: (e: MouseEvent<HTMLButtonElement>) => void;
  redButtonIsLoading?: boolean;
  redButtonText?: string;
  fullLoadingText?: string;
  isError?: boolean;
  isFullLoading?: boolean;
  defaultButtonDataTestId?: string;
  primaryButtonDataTestId?: string;
} & Omit<ComponentProps<typeof ModalMantine>, 'opened' | 'onClose'>;

export const Modal = ({
  isOpen,
  children,
  onClose = () => {},
  defaultButtonAction,
  defaultButtonDisabled = false,
  defaultButtonText,
  primaryButtonAction,
  primaryButtonColor,
  primaryButtonDisabled = false,
  primaryButtonIsLoading = false,
  primaryButtonRightIcon,
  primaryButtonText,
  isRedButtonLeftAligned = false,
  redButtonAction,
  redButtonIsLoading = false,
  redButtonText,
  fullLoadingText,
  isError = false,
  isFullLoading = false,
  defaultButtonDataTestId,
  primaryButtonDataTestId,
  ...modalMantineProps
}: Props) => {
  const { t } = useTranslation('common');
  const mantineTheme = useMantineTheme();

  const hasDefaultButton = Boolean(defaultButtonAction);
  const hasRedButton = Boolean(redButtonAction);
  const hasPrimaryButton = Boolean(primaryButtonAction);

  const getContent = () => {
    if (isError) {
      return <Error />;
    }

    if (isFullLoading) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 340,
            paddingBottom: 35,
          }}
        >
          {fullLoadingText ? (
            <TextLoader>{fullLoadingText}</TextLoader>
          ) : (
            <Loader />
          )}
        </Box>
      );
    }

    return (
      <>
        <Box
          sx={theme => ({
            maxHeight: 'calc(100vh - 245px)',
            overflowY: 'auto',
            marginBottom: theme.other.spacing(1),
          })}
        >
          <Box sx={{ overflowY: 'hidden' }}>{children}</Box>
        </Box>
        {(hasDefaultButton || hasRedButton || hasPrimaryButton) && (
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.other.spacing(3),
              gap: theme.other.spacing(2.25),
            })}
          >
            {hasDefaultButton && (
              <Button
                variant="default"
                disabled={defaultButtonDisabled}
                onClick={defaultButtonAction}
                data-testid={defaultButtonDataTestId}
              >
                {defaultButtonText || t('common:cancel')}
              </Button>
            )}
            {hasRedButton && (
              <Button
                sx={
                  isRedButtonLeftAligned
                    ? {
                        order: -1,
                        marginRight: 'auto',
                      }
                    : undefined
                }
                color="red"
                loading={redButtonIsLoading}
                onClick={redButtonAction}
              >
                {redButtonText || t('common:delete')}
              </Button>
            )}
            {hasPrimaryButton && (
              <Button
                disabled={primaryButtonDisabled}
                loading={primaryButtonIsLoading}
                onClick={primaryButtonAction}
                rightIcon={primaryButtonRightIcon}
                color={primaryButtonColor}
                data-testid={primaryButtonDataTestId}
              >
                {primaryButtonText || t('common:save')}
              </Button>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <ModalMantine
      opened={isOpen}
      onClose={onClose}
      centered
      padding={mantineTheme.other.spacing(2)}
      shadow="xs"
      withFocusReturn={false}
      overlayColor={mantineTheme.colors[mantineTheme.primaryColor][9]}
      overlayOpacity={0.5}
      {...modalMantineProps}
    >
      {getContent()}
    </ModalMantine>
  );
};
