import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const AttachmentIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m19.429 12-6.58 6.58a4.848 4.848 0 1 1-6.857-6.857l6.776-6.776a3.232 3.232 0 1 1 4.57 4.571l-6.579 6.58a1.616 1.616 0 0 1-2.286-2.286l6.384-6.383"
      />
    </Box>
  );
};
