import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';

import { TRANSACTION_ENTRIES_TABS } from 'consts';
import { TransactionEntriesTabType } from 'types';

type Props = {
  activeTab: TransactionEntriesTabType;
  onTabChange: (nextTab: TransactionEntriesTabType) => void;
};

export const DetailTabs = ({ activeTab, onTabChange }: Props) => {
  const { t } = useTranslation('transactions');

  return (
    <Tabs
      styles={{
        tabLabel: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
      value={activeTab}
      onTabChange={onTabChange}
    >
      <Tabs.List>
        <Tabs.Tab value={TRANSACTION_ENTRIES_TABS.data}>
          {t('transactions:dataTab')}
        </Tabs.Tab>
        <Tabs.Tab value={TRANSACTION_ENTRIES_TABS.stream}>
          {t('transactions:streamTab')}
        </Tabs.Tab>
        <Tabs.Tab value={TRANSACTION_ENTRIES_TABS.type}>
          {t('transactions:typeTab')}
        </Tabs.Tab>{' '}
        <Tabs.Tab value={TRANSACTION_ENTRIES_TABS.details}>
          {t('transactions:detailsTab')}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};
