import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

import { usePostAuthResetPasswordUpdate } from 'api/accountPassword';
import { useServerErrors, useShowNotification } from 'hooks';
import { URLS } from 'consts';
import { ValidationErrors } from 'types';
import { PromotionalCarousel } from 'components/PromotionalCarousel';
import { TextInputPasswordWithStrength } from 'components/shared';
import { ContentHeader, NonLoggedLayout } from 'components/NonLoggedLayout';
import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

export const ResetPassword = () => {
  const { t } = useTranslation(['common', 'user']);
  const navigate = useNavigate();
  const { token } = useParams() as { token: string };

  const { getGeneralServerError, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();
  const { mutateAsync, isLoading } = usePostAuthResetPasswordUpdate();

  const [password, setPassword] = useState('');
  const [errorStatus, setErrorStatus] = useState<ValidationErrors | null>(null);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordInstanceRef = useRef<InstanceRefObjectType>({ isValid: false });

  const backButtonAction = () => {
    navigate(URLS.home);
  };

  return (
    <NonLoggedLayout
      backButtonAction={backButtonAction}
      primaryContent={<PromotionalCarousel />}
      secondaryContent={
        <>
          <ContentHeader
            title={t('user:createNewPassword')}
            description={t('user:createNewPasswordDescription')}
            errorLabel={serverErrorMessage || errorStatus?.token}
            backButtonAction={backButtonAction}
          />
          <form
            onSubmit={async (event: FormEvent<HTMLFormElement>) => {
              event.preventDefault();

              if (passwordInstanceRef.current.isValid) {
                try {
                  await mutateAsync({
                    pathParams: {
                      token,
                    },
                    bodyParams: {
                      password,
                      passwordConfirmation: password,
                    },
                  });
                  navigate(URLS.home);
                  showNotification({
                    message: t('user:passwordUpdated'),
                  });
                } catch (error) {
                  const validationServerErrors = getServerErrorStatus(error);

                  if (validationServerErrors) {
                    setErrorStatus(validationServerErrors);
                  } else {
                    setServerErrorMessage(getGeneralServerError(error));
                  }
                }
              }
            }}
          >
            <TextInputPasswordWithStrength
              sx={theme => ({ marginBottom: theme.other.spacing(3) })}
              id="password"
              type="password"
              label={t('user:newPassword')}
              placeholder={t('user:newPasswordPlaceholder')}
              autoFocus
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              error={errorStatus?.password || errorStatus?.passwordConfirmation}
              disabled={isLoading}
              ref={passwordRef}
              instanceRef={passwordInstanceRef}
            />
            <Button
              sx={{ fontWeight: 600 }}
              type="submit"
              fullWidth
              size="lg"
              loading={isLoading}
            >
              {t('user:createNewPassword')}
            </Button>
          </form>
        </>
      }
    />
  );
};
