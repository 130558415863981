import { apiAuthClient } from 'api/helpers';

import {
  PostTechnicalUserGenerateCertificateRequestType,
  PostTechnicalUserGenerateCertificateResponseType,
} from '../types';

export const postTechnicalUserGenerateCertificate = async ({
  pathParams: { organisationId, technicalUserId },
  formData,
}: PostTechnicalUserGenerateCertificateRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/technical-users/${technicalUserId}/certificates`;

  const { data } =
    await apiAuthClient.post<PostTechnicalUserGenerateCertificateResponseType>(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

  return data;
};
