import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { getNumberInputValue } from '../helpers';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { DescriptionForApi } from '../DescriptionForApi';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { MinMaxNumberValue } from '../MinMaxNumberValue';
import { useGetZodDateVariableSchema } from './hooks';
import { InitialValue } from './InitialValue';

export const DateVariable = ({ fieldsInstanceRef, initialValues }) => {
  const schema = useGetZodDateVariableSchema();

  const {
    control,
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? {
          ...initialValues,
          initialValue: new Date(initialValues.initialValue),
          minYearDelta: initialValues.minYearDelta
            ? Number(initialValues.minYearDelta)
            : undefined,
          maxYearDelta: initialValues.maxDelta
            ? Number(initialValues.maxYearDelta)
            : undefined,
        }
      : {
          initialValue: undefined,
          placeholder: '',
          label: '',
          hint: '',
          apiDescription: '',
          minYearDelta: undefined,
          maxYearDelta: undefined,
          required: false,
          requiredWhen: null,
        },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { initialValue, minYearDelta, maxYearDelta, ...values } =
        getValues();

      return {
        ...values,
        initialValue: initialValue ? initialValue.toISOString() : undefined,
        minYearDelta: getNumberInputValue(minYearDelta),
        maxYearDelta: getNumberInputValue(maxYearDelta),
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  const { minYearDelta, maxYearDelta } = getValues();

  const handleNumberValueChange = (value, field) => {
    clearErrors(field);
    setValue(field, value);
  };

  return (
    <div>
      <InitialValue
        control={control}
        error={formErrors?.initialValue?.message}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <DescriptionForApi
        inputProps={{ ...register('apiDescription') }}
        error={formErrors?.apiDescription?.message || ''}
      />
      <MinMaxNumberValue
        inputMinValueProps={{
          defaultValue: minYearDelta,
          onChange: value => {
            handleNumberValueChange(value, 'minYearDelta');
          },
        }}
        inputMaxValueProps={{
          defaultValue: maxYearDelta,
          onChange: value => {
            handleNumberValueChange(value, 'maxYearDelta');
          },
        }}
        error={
          formErrors?.minYearDelta?.message ||
          formErrors?.maxYearDelta?.message ||
          ''
        }
      />
      <Required checkboxProps={{ ...register('required') }} />
    </div>
  );
};
