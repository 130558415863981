import { apiAuthClient } from '../../helpers';
import { DeleteWebhookRequestType } from '../types';

export const deleteWebhook = async ({
  pathParams: { organisationId, webhookId },
}: DeleteWebhookRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/webhooks/${webhookId}`;
  const { data } = await apiAuthClient.delete(url);

  return data;
};
