import { useGetTechnicalUsers } from 'api/technicalUsers';
import { useSearchParams, useTechnicalUsersFeature } from 'hooks';

export const useGetTechnicalUsersData = () => {
  const { getSearchParam } = useSearchParams(['search', 'page']);
  const { data: hasTechnicalUsersFeature } = useTechnicalUsersFeature();

  return useGetTechnicalUsers(
    {
      queryParams: {
        page: getSearchParam('page'),
        name: getSearchParam('search'),
      },
    },
    {
      enabled: hasTechnicalUsersFeature,
      keepPreviousData: true,
    },
  );
};
