import { apiAuthClient } from 'api/helpers';

import {
  PostCustomizationsEmailPreviewResponseType,
  PostCustomizationsEmailPreviewRequestType,
} from '../types';

export const postCustomizationsEmailPreview = async ({
  pathParams: { organisationId },
  bodyParams: { color, company, sender, withCustomFooter },
}: PostCustomizationsEmailPreviewRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/customizations/email/previews`;

  const { data } =
    await apiAuthClient.post<PostCustomizationsEmailPreviewResponseType>(url, {
      color,
      company,
      sender,
      withCustomFooter,
    });

  return data;
};
