import { ThemeComponentType } from '../types';

export const Dropzone: ThemeComponentType = {
  styles: theme => ({
    root: {
      borderColor: theme.colors.gray[1],
      '&:hover': {
        backgroundColor: theme.white,
      },
      '&[data-reject]': {
        borderColor: theme.fn.primaryColor(),
        backgroundColor: theme.white,
        '&:hover': {
          backgroundColor: theme.white,
        },
      },
    },
  }),
};
