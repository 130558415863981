import { AvatarProps, Avatar, Box } from '@mantine/core';

import { getUserInitials } from 'helpers';

type Props = {
  name: string | null | undefined;
  surname: string | null | undefined;
  avatar: string | null | undefined;
  isDeleted?: boolean;
  size?: number;
  imageSize?: number;
} & AvatarProps;

export const UserAvatar = ({
  name,
  surname,
  avatar,
  isDeleted = false,
  size = 38,
  imageSize,
  ...avatarProps
}: Props) => {
  const getAvatarContent = () => {
    if (isDeleted) {
      return null;
    }

    if (avatar) {
      return (
        <Box
          sx={{
            width: imageSize ?? size,
            height: imageSize ?? size,
            borderRadius: '50%',
          }}
          component="img"
          src={avatar}
          alt=""
        />
      );
    }

    if (name && surname) {
      return <>{getUserInitials(name, surname)}</>;
    }

    return null;
  };

  return (
    <Avatar size={size} {...avatarProps}>
      {getAvatarContent()}
    </Avatar>
  );
};
