import { useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';

import { usePostTechnicalUser } from 'api/technicalUsers';
import { PostTechnicalUserRequestType } from 'api/technicalUsers/types';
import { useServerErrors, useShowNotification } from 'hooks';

import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../../types';
import { ConfirmationInput } from '../ConfirmationInput';

type Props = {
  setIsNewTechnicalUserInputShown: Dispatch<SetStateAction<boolean>>;
  setBearerTokensForNewlyCreatedTechnicalUsers: Dispatch<
    SetStateAction<BearerTokensForNewlyCreatedTechnicalUsersType>
  >;
};

export const NewTechnicalUserInput = ({
  setIsNewTechnicalUserInputShown,
  setBearerTokensForNewlyCreatedTechnicalUsers,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const showNotification = useShowNotification();
  const { addServerErrorActions, getServerFieldError } = useServerErrors();

  const [newTechnicalUserName, setNewTechnicalUserName] = useState('');
  const [technicalUserNameError, setTechnicalUserNameError] = useState('');
  const [isNameInputFocused, setIsNameInputFocused] = useState(true);

  const onCancelNewTechnicalUserCreation = () => {
    if (!isCreatingTechnicalUser) {
      setIsNewTechnicalUserInputShown(false);
      setTechnicalUserNameError('');
    }
  };

  const wrapperRef = useClickOutside(onCancelNewTechnicalUserCreation);

  const {
    mutateAsync: createTechnicalUser,
    isLoading: isCreatingTechnicalUser,
  } = usePostTechnicalUser();

  return (
    <Paper
      sx={theme => ({
        marginBottom: theme.other.spacing(1),
        padding: theme.other.spacing(2, 4, 0, 2),
      })}
      ref={wrapperRef}
    >
      <ConfirmationInput
        value={newTechnicalUserName}
        onConfirm={async (
          technicalUserName: PostTechnicalUserRequestType['bodyParams']['name'],
        ) => {
          setNewTechnicalUserName(technicalUserName);

          if (technicalUserName) {
            setTechnicalUserNameError('');

            try {
              const { id, bearerToken } = await createTechnicalUser({
                name: technicalUserName,
              });
              setBearerTokensForNewlyCreatedTechnicalUsers(
                newlyCreatedTechnicalUsers => ({
                  ...newlyCreatedTechnicalUsers,
                  [id]: bearerToken,
                }),
              );
              setIsNewTechnicalUserInputShown(false);
              showNotification({
                message: t('api:technicalUserIsCreated'),
              });
            } catch (error) {
              setTechnicalUserNameError(getServerFieldError(error, 'name'));
              addServerErrorActions(error);
            } finally {
              setNewTechnicalUserName('');
            }
          } else {
            setTechnicalUserNameError(t('common:formErrors.required'));
          }
        }}
        onReject={onCancelNewTechnicalUserCreation}
        isFocused={isNameInputFocused}
        setIsFocused={setIsNameInputFocused}
        error={technicalUserNameError}
        setError={setTechnicalUserNameError}
        isLoading={isCreatingTechnicalUser}
      />
    </Paper>
  );
};
