import { Box, Text } from '@mantine/core';

type Props = {
  label: string;
  value: string | null;
};

export const InfoItem = ({ label, value }: Props) => (
  <Box
    sx={{
      display: 'flex',
    }}
  >
    <Text
      sx={theme => ({
        width: 200,
        marginRight: theme.other.spacing(2),
        textAlign: 'right',
      })}
      size="md"
    >
      {label}:
    </Text>
    {Boolean(value) && (
      <Text
        sx={{
          flexShrink: 0,
          maxWidth: 275,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        size="md"
        weight={600}
      >
        {value}
      </Text>
    )}
  </Box>
);
