import { useTranslation } from 'react-i18next';

import { EMPTY_PLACEHOLDER, SIGN_TYPES } from 'consts';

export const useGetSignTypeTranslation = () => {
  const { t } = useTranslation('common');

  return (signType: (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES] | '') => {
    switch (signType) {
      case 'SMS':
        return t('common:signTypes.sms');
      case 'ANY':
        return t('common:signTypes.any');
      case 'QUALIFIED':
        return t('common:signTypes.qualified');
      case 'HYBRID':
        return t('common:signTypes.hybrid');
      default:
        return EMPTY_PLACEHOLDER;
    }
  };
};
