import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button, Text } from '@mantine/core';

import { CreateIcon } from 'icons';
import { STATIC_URLS } from 'consts';

type Props = {
  onCreateNewTechnicalUser: () => void;
};

export const NewTechnicalUserPanel = ({ onCreateNewTechnicalUser }: Props) => {
  const { t } = useTranslation(['api', 'common']);

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.other.spacing(1.25, 2, 2, 2),
        backgroundColor: theme.colors.green[1],
        borderRadius: theme.radius.sm,
        boxShadow: theme.shadows.xs,
      })}
    >
      <Box sx={{ maxWidth: 550 }}>
        <Text weight={600} mb="xs">
          {t('api:technicalUserCreating')}
        </Text>
        <Text size="md">
          {t('api:technicalUserCreatingDescription')}{' '}
          <Anchor href={STATIC_URLS.transactionApi} target="_blank">
            {t('api:technicalUserCreatingFindOutMore')}
          </Anchor>
        </Text>
      </Box>
      <Button
        leftIcon={<CreateIcon />}
        color="green"
        onClick={onCreateNewTechnicalUser}
      >
        {t('common:create')}
      </Button>
    </Box>
  );
};
