import { apiAuthClient } from 'api/helpers';

import { PatchTemplateRequestType, PatchTemplateResponseType } from '../types';

export const patchTemplate = async ({
  pathParams: { organisationId, boardId, templateId },
  bodyParams,
}: PatchTemplateRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates/${templateId}`;

  const { data } = await apiAuthClient.patch<PatchTemplateResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
