import { useInfiniteQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { getInfinityQueryNextPageParam } from 'helpers';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTechnicalUsers } from '../requests';
import { GetTechnicalUsersRequestType } from '../types';

export const useGetTechnicalUsersInfinite = (
  {
    queryParams: { name, page, perPage, sortBy, sortDir },
  }: Omit<GetTechnicalUsersRequestType, 'pathParams'>,
  options?: {
    enabled?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useInfiniteQuery({
    queryKey: [
      API_QUERY_KEYS.technicalUsers,
      { organisationId, name, page, perPage, sortBy, sortDir },
    ],
    queryFn: ({ pageParam }) =>
      getTechnicalUsers({
        pathParams: { organisationId },
        queryParams: { name, page: pageParam, perPage, sortBy, sortDir },
      }),
    getNextPageParam: getInfinityQueryNextPageParam,
    ...options,
  });
};
