import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postDataType } from '../requests';
import { PostDataTypeRequestType } from '../types';

export const usePostDataType = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PostDataTypeRequestType['bodyParams']) =>
      postDataType({
        pathParams: { organisationId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.dataTypes],
      });
    },
  });
};
