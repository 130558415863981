import { apiAuthClient } from 'api/helpers';

import {
  PatchTechnicalUserRequestType,
  PatchTechnicalUserResponseType,
} from '../types';

export const patchTechnicalUser = async ({
  pathParams: { organisationId, technicalUserId },
  bodyParams,
}: PatchTechnicalUserRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/technical-users/${technicalUserId}`;

  const { data } = await apiAuthClient.patch<PatchTechnicalUserResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
