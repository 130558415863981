import { useTranslation } from 'react-i18next';

import { BRANDING_SOURCE, EMPTY_PLACEHOLDER } from 'consts';

export const useGetLetterheadTranslation = () => {
  const { t } = useTranslation('templates');

  return (
    brandingSource: (typeof BRANDING_SOURCE)[keyof typeof BRANDING_SOURCE],
  ) => {
    switch (brandingSource) {
      case 'organisation':
        return t('templates:attachedForOrganisation');
      case 'template':
        return t('templates:attachedToTemplate');
      default:
        return EMPTY_PLACEHOLDER;
    }
  };
};
