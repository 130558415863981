import {
  isMarkdown,
  toggleMarkdown,
} from 'legacy/TemplateCreatorEditor/plugins/helpers';

export const underline = {
  renderMark: ({ mark, attributes, children }, editor, next) => {
    if (mark.type === 'underline') {
      return <u {...attributes}>{children}</u>;
    }

    return next();
  },
  queries: {
    isUnderline: editor => isMarkdown(editor, 'underline'),
  },
  commands: {
    toggleUnderline: editor => {
      toggleMarkdown(editor, 'underline');
    },
  },
};
