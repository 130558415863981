export const GroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#1E1C49"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M17.143 20H20v-1.714a2.857 2.857 0 0 0-2.857-2.857m-4-8.003a3.427 3.427 0 0 1-3.429 3.427 3.427 3.427 0 1 1 0-6.853 3.427 3.427 0 0 1 3.429 3.426Zm2.286 12.564H4v-2.283a3.428 3.428 0 0 1 3.429-3.428H12a3.428 3.428 0 0 1 3.429 3.428v2.283Zm1.142-6.847a2.286 2.286 0 1 1 0-4.572 2.286 2.286 0 0 1 0 4.572Z"
    />
  </svg>
);
