import { useEffect } from 'react';
import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DataWrapper, MetaTitle, SectionHeader } from 'components/shared';
import { WebhookIcon } from 'icons';
import { useGetWebhooks } from 'api/webhooks';
import { useSearchParams } from 'hooks';
import { WebhookList } from './components';

export const Webhooks = () => {
  const { t } = useTranslation('api');
  const { getSearchParam, deleteSearchParam } = useSearchParams([
    'webhookPage',
  ]);

  const page = getSearchParam('webhookPage');

  const {
    data: webhooksData,
    isInitialLoading,
    isError,
  } = useGetWebhooks({
    queryParams: {
      page,
      perPage: 5,
    },
  });

  useEffect(() => {
    if (webhooksData?.data.length === 0 && page !== null) {
      deleteSearchParam('webhookPage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhooksData?.data, page]);

  return (
    <Box
      sx={theme => ({
        marginTop: theme.other.spacing(4),
      })}
    >
      <MetaTitle value={t('api:webhooks.featureName')} />
      <Box>
        <Box>
          <SectionHeader icon={<WebhookIcon />}>
            {t('api:webhooks.featureName')}
          </SectionHeader>
        </Box>
        <DataWrapper
          isError={isError}
          isLoading={isInitialLoading}
          data={webhooksData}
        >
          {({ data: webhooks, meta }) => (
            <WebhookList webhooks={webhooks} meta={meta} />
          )}
        </DataWrapper>
      </Box>
    </Box>
  );
};
