import { Box, Text } from '@mantine/core';
import {
  ErrorTwoToneIcon,
  MailIcon,
  NotificationIcon,
  SuccessTwoToneIcon,
} from 'icons';
import { getTwoToneRoundedIconProps } from '../../../../helpers';

type Props = {
  text: string;
  hasSuccessIcon: boolean;
  hasPushIcon: boolean;
  hasMailIcon: boolean;
};

export const NotificationListItem = ({
  text,
  hasSuccessIcon,
  hasPushIcon,
  hasMailIcon,
}: Props) => (
  <Box
    sx={theme => ({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.other.spacing(2),
    })}
    component="li"
  >
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.other.spacing(1),
      })}
    >
      {hasSuccessIcon ? (
        <SuccessTwoToneIcon
          {...getTwoToneRoundedIconProps({
            color: 'green',
            backgroundColor: 'violet.1',
          })}
        />
      ) : (
        <ErrorTwoToneIcon
          {...getTwoToneRoundedIconProps({
            color: 'red',
            backgroundColor: 'violet.1',
          })}
        />
      )}
      <Text size="md" weight={400}>
        {text}
      </Text>
    </Box>
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {hasPushIcon && (
        <NotificationIcon
          sx={theme => ({
            display: 'flex',
            marginRight: hasMailIcon ? theme.other.spacing(0.5) : undefined,
          })}
          color="blue"
          backgroundColor="blue.1"
        />
      )}
      {hasMailIcon && <MailIcon color="blue" backgroundColor="blue.1" />}
    </Box>
  </Box>
);
