import { apiAuthClient } from 'api/helpers';

import {
  PostAuthLoginMethodsRequestType,
  PostAuthLoginMethodsResponseType,
} from '../types';

export const postAuthLoginMethods = async ({
  bodyParams,
}: PostAuthLoginMethodsRequestType) => {
  const url = '/api/v2/auth/login-methods';

  const { data } = await apiAuthClient.post<PostAuthLoginMethodsResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
