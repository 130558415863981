import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DataStreamTemplateType, ValidationErrors } from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';
import { DataStreamAddTemplateForm } from 'components/Transactions';

type Props = {
  onAdd: (template: DataStreamTemplateType) => void;
  addStreamTemplateErrors: ValidationErrors | null;
  clearAddStreamTemplateError: (errorKey: string) => void;
  templates: DataStreamTemplateType[];
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const AddTemplateModal = ({
  isOpen,
  onClose,
  onAdd,
  addStreamTemplateErrors,
  clearAddStreamTemplateError,
  templates,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('dataStreams:addTemplateModal.addNewTemplate')}
      defaultButtonAction={onClose}
      primaryButtonColor="green"
      primaryButtonText={t('common:add')}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <DataStreamAddTemplateForm
        formRef={formRef}
        addDataStreamTemplate={onAdd}
        errors={addStreamTemplateErrors}
        clearError={clearAddStreamTemplateError}
        templates={templates}
      />
    </Modal>
  );
};
