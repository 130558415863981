import { Link } from 'react-router-dom';
import { ActionIcon, Box, MantineTheme } from '@mantine/core';

import { ActionIconsItemType } from '../types';

type Props = {
  items: ActionIconsItemType[];
};

export const DesktopView = ({ items }: Props) => (
  <Box display="flex">
    {items.map(({ icon, name, colorDesktop, ...actionItem }, index) => {
      const sharedProps = {
        sx: (theme: MantineTheme) => ({
          width: 36,
          height: 36,
          marginRight:
            index < items.length - 1 ? theme.other.spacing(2) : undefined,
        }),
        key: name,
        variant: 'filled' as const,
        color: colorDesktop,
        children: icon,
        title: name,
      };

      return 'action' in actionItem ? (
        <ActionIcon {...sharedProps} onClick={actionItem.action} />
      ) : (
        <ActionIcon {...sharedProps} component={Link} to={actionItem.to} />
      );
    })}
  </Box>
);
