import { MarginType } from '../../types';
import { MARGIN_RANGE } from '../consts';

const getSingleMarginValue = (value: number) => {
  if (value < MARGIN_RANGE.min) {
    return MARGIN_RANGE.min;
  }

  if (value > MARGIN_RANGE.max) {
    return MARGIN_RANGE.max;
  }

  return value;
};

export const getMarginRangeValues = (margin: MarginType) =>
  Object.entries(margin).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: getSingleMarginValue(value),
    }),
    {} as MarginType,
  );
