import { Block } from 'slate';

import { CONFIG } from 'legacy/TemplateCreatorEditor/consts';

import { Root } from './Root_';

/* header - named toolbar in legacy app */

export const root = {
  renderEditor: ({ errors }, editor, next) => {
    return <Root errors={errors} editor={editor} textEditor={next()} />;
  },
  queries: {
    activeAlignment: editor => {
      let activeAlignment;

      for (const block of editor.value.blocks) {
        const blockDataAlign = block.data.get('align');
        const getBlockAlignment = () => {
          if (blockDataAlign) {
            return blockDataAlign;
          }

          switch (block.type) {
            case 'title':
              return CONFIG.title.textAlign;
            case 'header':
              return CONFIG.header.textAlign;
            default:
              return CONFIG.textAlign;
          }
        };

        const blockAlignment = getBlockAlignment();

        if (activeAlignment && activeAlignment !== blockAlignment) {
          activeAlignment = 'notMatchForAllBlocks';
          break;
        } else {
          activeAlignment = blockAlignment;
        }
      }

      if (activeAlignment === 'notMatchForAllBlocks') {
        return undefined;
      }

      if (activeAlignment === undefined) {
        return CONFIG.textAlign;
      }

      return activeAlignment;
    },
    activeInterline: editor => {
      let activeInterline;

      for (const block of editor.value.blocks) {
        const blockInterline =
          block.data.get('lineHeight') || CONFIG.lineHeight;

        if (activeInterline && activeInterline !== blockInterline) {
          activeInterline = 'notMatchForAllBlocks';
          break;
        } else {
          activeInterline = blockInterline;
        }
      }

      if (activeInterline === 'notMatchForAllBlocks') {
        return undefined;
      }

      if (activeInterline === undefined) {
        return CONFIG.lineHeight;
      }

      return activeInterline;
    },
    activeBlockType: editor => {
      let activeBlockType;

      for (const block of editor.value.blocks) {
        if (activeBlockType && activeBlockType !== block.type) {
          activeBlockType = undefined;
          break;
        } else {
          activeBlockType = block.type;
        }
      }

      return activeBlockType;
    },
    getVariables: editor => {
      return editor.value.document.data.get('variables') || [];
    },
    getVariable: (editor, variableName) => {
      return (
        editor.getVariables().find(({ name }) => name === variableName) || {}
      );
    },
    hasVariable: (editor, variableName) => {
      return editor.getVariables().some(({ name }) => name === variableName);
    },
  },
  commands: {
    insertInlineWithEmptyContent: (editor, inline) => {
      editor
        .insertText(' ')
        .insertInline(inline)
        .moveToStartOfNextText()
        .insertText(' ');
    },
    insertBlockWithOptionalEmptyContent: (editor, block) => {
      const { value } = editor;
      const currentNode = value.startBlock;

      const lastNode = value.document.nodes.last();

      editor.insertBlock(block);

      if (currentNode === lastNode) {
        editor.insertBlock('paragraph');
      }
    },
    wrapBlockWithOptionalEmptyContent: (editor, block) => {
      const { value } = editor;
      const currentNode = value.startBlock;
      const firstNode = value.document.nodes.first();
      const lastNode = value.document.nodes.last();

      editor.wrapBlock(block);

      if (currentNode === lastNode) {
        editor.insertNodeByKey(
          value.document.key,
          value.document.nodes.size,
          Block.create({
            type: 'paragraph',
          }),
        );
      }

      if (currentNode === firstNode) {
        editor.insertNodeByKey(
          value.document.key,
          0,
          Block.create({
            type: 'paragraph',
          }),
        );
      }
    },
    updateNodeData: (editor, node, newValuesObj) => {
      editor.setNodeByKey(node.key, {
        data: {
          ...node.data.toJS(),
          ...newValuesObj,
        },
      });
    },
    addVariable: (editor, variable) => {
      editor.updateNodeData(editor.value.document, {
        variables: [...editor.getVariables(), variable],
      });
    },
    updateVariable: (editor, variableName, variable) => {
      const variablesToUpdate = [...editor.getVariables()];
      const variableToUpdateIndex = variablesToUpdate.findIndex(
        ({ name }) => name === variableName,
      );

      variablesToUpdate.splice(variableToUpdateIndex, 1, variable);

      editor.updateNodeData(editor.value.document, {
        variables: variablesToUpdate,
      });

      if (variableName !== variable.name) {
        const updateVariableInNodes = (
          nodes,
          prevVariableName,
          newVariableName,
        ) => {
          nodes.forEach(node => {
            const nodeVariable = node.data && node.data.get('variable');

            if (nodeVariable === prevVariableName) {
              editor.updateNodeData(node, { variable: newVariableName });
            }

            if (node.nodes) {
              updateVariableInNodes(
                node.nodes,
                prevVariableName,
                newVariableName,
              );
            }
          });
        };

        updateVariableInNodes(editor.value, variableName, variable.name);
      }
    },
    deleteVariable: (editor, variable) => {
      editor.updateNodeData(editor.value.document, {
        variables: editor
          .getVariables()
          .filter(({ name }) => name !== variable),
      });

      const deleteVariableInNodes = nodes => {
        nodes.forEach(node => {
          const nodeVariable = node.data && node.data.get('variable');

          if (nodeVariable === variable) {
            const { variable: _variable, ...data } = node.data.toJS();

            editor.setNodeByKey(node.key, {
              data,
            });
          }

          if (node.nodes) {
            deleteVariableInNodes(node.nodes);
          }
        });
      };

      deleteVariableInNodes(editor.value);
    },
  },
};
