import { stringifyUrlQuery } from 'helpers';
import {
  GetWebhookLogsRequestType,
  GetWebhookLogsResponseType,
} from '../types';
import { apiAuthClient } from '../../helpers';

export const getWebhookLogs = async ({
  pathParams: { organisationId, webhookId },
  queryParams: { page, perPage, sortDir } = {},
}: GetWebhookLogsRequestType) => {
  const query = stringifyUrlQuery({
    page,
    perPage,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/webhooks/${webhookId}/logs${query}`;
  const { data } = await apiAuthClient.get<GetWebhookLogsResponseType>(url);

  return data.data;
};
