import { apiAuthClient } from 'api/helpers';

import {
  PostUserOtpUpdateRequestType,
  PostUserOtpUpdateResponseType,
} from '../types';

export const postUserOtpUpdate = async ({
  bodyParams: { email, setupPassword },
}: PostUserOtpUpdateRequestType) => {
  const url = '/api/v2/auth/user/otp/update';

  const { data } = await apiAuthClient.post<PostUserOtpUpdateResponseType>(
    url,
    {
      email,
      setupPassword,
    },
  );

  return data.data;
};
