import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { LockIcon } from 'icons';
import { SetPasswordModal } from './SetPasswordModal';

export const Password = () => {
  const { t } = useTranslation('user');

  const [isSetPasswordModalOpen, setIsSetPasswordModalOpen] = useState(false);

  return (
    <>
      <div>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.other.spacing(2.5),
          })}
        >
          <Text weight={600}>{t('user:password')}</Text>
          <UnstyledButton
            sx={theme => ({
              fontWeight: 600,
              color: theme.fn.primaryColor(),
            })}
            onClick={() => {
              setIsSetPasswordModalOpen(true);
            }}
          >
            {t('user:changePassword')}
          </UnstyledButton>
        </Box>
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.colors[theme.primaryColor][1],
            borderRadius: theme.radius.sm,
            height: 38,
            padding: theme.other.spacing(0.5, 2),
          })}
        >
          <LockIcon sx={theme => ({ marginRight: theme.other.spacing(0.5) })} />
          <Text size="sm" weight={400}>
            •••••••••••••••••••••
          </Text>
        </Box>
      </div>
      <SetPasswordModal
        isOpen={isSetPasswordModalOpen}
        onClose={() => {
          setIsSetPasswordModalOpen(false);
        }}
      />
    </>
  );
};
