import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Calendar, RangeCalendar } from '@mantine/dates';
import { CalendarSharedProps } from '@mantine/dates/lib/components/CalendarBase/CalendarBase';
import { isAfter, isBefore, isToday } from 'date-fns';

import { useGetUser } from 'api/account';
import { useGetUserDateInputFormat } from 'hooks';
import { getDayStyles } from 'theme/components/Calendar';
import { DateRangeValueType, UserType } from 'types';

import 'dayjs/locale/pl';

import { CALENDAR_TYPE_ITEMS, CALENDAR_TYPES } from '../consts';
import { useGetIsoDateByUserFormat } from '../hooks';
import { DATE_RANGE } from './consts';
import { dateObjToRangeValue, rangeValueToDateObj } from './helpers';

type Props = {
  calendarType: (typeof CALENDAR_TYPES)[keyof typeof CALENDAR_TYPES];
  setCalendarType: Dispatch<
    SetStateAction<(typeof CALENDAR_TYPES)[keyof typeof CALENDAR_TYPES]>
  >;
  date: [DateRangeValueType] | [DateRangeValueType, DateRangeValueType];
  setDate: Dispatch<
    SetStateAction<
      [DateRangeValueType] | [DateRangeValueType, DateRangeValueType]
    >
  >;
  setInputValue: Dispatch<SetStateAction<string>>;
};

export const DatePicker = ({
  calendarType,
  setCalendarType,
  date,
  setDate,
  setInputValue,
}: Props) => {
  const { t } = useTranslation('common');

  const mantineTheme = useMantineTheme();

  const { data: userData } = useGetUser();
  const { locale } = userData as UserType;

  const userDateInputFormat = useGetUserDateInputFormat();
  const getIsoDateStringByUserFormat = useGetIsoDateByUserFormat();

  const firstRangeDateObj = rangeValueToDateObj(date[0]);

  const [month, setMonth] = useState(firstRangeDateObj || new Date());

  const calendarSharedProps: CalendarSharedProps = {
    sx: {
      minHeight: 300,
    },
    locale,
    minDate: DATE_RANGE.min,
    maxDate: DATE_RANGE.max,
    month,
    onMonthChange: dateObj => {
      setMonth(dateObj);
    },
    dayStyle: dateObj => ({
      border: `1px solid ${
        isToday(dateObj)
          ? mantineTheme.colors[mantineTheme.primaryColor][0]
          : 'transparent'
      }`,
    }),
  };

  const dateObjToRangeValueByUserFormat = (dateObj: Date | null) =>
    dateObjToRangeValue(dateObj, userDateInputFormat);

  useEffect(() => {
    setMonth(rangeValueToDateObj(date[0]) || new Date());
  }, [date]);

  const getCalendar = () => {
    switch (calendarType) {
      case CALENDAR_TYPES.single:
        return (
          <Calendar
            {...calendarSharedProps}
            value={firstRangeDateObj}
            onChange={dateObj => {
              const dateRange = dateObjToRangeValueByUserFormat(dateObj);

              setDate([getIsoDateStringByUserFormat(dateRange)]);
              setInputValue(dateRange || '');
            }}
          />
        );
      case CALENDAR_TYPES.range:
        return (
          <RangeCalendar
            {...calendarSharedProps}
            value={[
              firstRangeDateObj,
              rangeValueToDateObj(date[1] as DateRangeValueType),
            ]}
            onChange={dateObjects => {
              const dateRanges = dateObjects.map(dateObj =>
                dateObjToRangeValueByUserFormat(dateObj),
              ) as [DateRangeValueType, DateRangeValueType];

              setDate(
                dateRanges.map(dateRange =>
                  getIsoDateStringByUserFormat(dateRange),
                ) as [DateRangeValueType, DateRangeValueType],
              );

              setInputValue(
                dateRanges.every(Boolean)
                  ? dateRanges.join(' - ')
                  : `${dateRanges[0]} -`,
              );
            }}
          />
        );
      case CALENDAR_TYPES.from: {
        const dayStyles = getDayStyles(mantineTheme);

        return (
          <Calendar
            {...calendarSharedProps}
            dayStyle={(dateObj, modifiers) => {
              const { selected, disabled } = modifiers;

              const sharedDayStyles = (
                calendarSharedProps.dayStyle as NonNullable<
                  typeof calendarSharedProps.dayStyle
                >
              )(dateObj, modifiers);

              if (firstRangeDateObj === null || disabled) {
                return sharedDayStyles;
              }

              if (selected) {
                return {
                  ...dayStyles['&[data-first-in-range="true"]'],
                  ...sharedDayStyles,
                };
              }

              if (isAfter(dateObj, firstRangeDateObj)) {
                return {
                  ...dayStyles['&[data-in-range="true"]'],
                  ...sharedDayStyles,
                };
              }

              return sharedDayStyles;
            }}
            renderDay={dateObj => (
              <Tooltip label={t('common:datePicker.fromDateToInfinity')}>
                <Box sx={{ display: 'block' }} component="span">
                  {dateObj.getDate()}
                </Box>
              </Tooltip>
            )}
            value={firstRangeDateObj}
            onChange={dateObj => {
              const rangeDate = dateObjToRangeValueByUserFormat(dateObj);

              setDate([getIsoDateStringByUserFormat(rangeDate), null]);
              setInputValue(`${rangeDate} -` || '');
            }}
          />
        );
      }
      case CALENDAR_TYPES.to: {
        const value = rangeValueToDateObj(date[1] as string);
        const dayStyles = getDayStyles(mantineTheme);

        return (
          <Calendar
            {...calendarSharedProps}
            dayStyle={(dateObj, modifiers) => {
              const { selected, disabled } = modifiers;

              const sharedDayStyles = (
                calendarSharedProps.dayStyle as NonNullable<
                  typeof calendarSharedProps.dayStyle
                >
              )(dateObj, modifiers);

              if (value === null || disabled) {
                return sharedDayStyles;
              }

              if (selected) {
                return {
                  ...dayStyles['&[data-last-in-range="true"]'],
                  ...sharedDayStyles,
                };
              }

              if (isBefore(dateObj, value)) {
                return {
                  ...dayStyles['&[data-in-range="true"]'],
                  ...sharedDayStyles,
                };
              }

              return sharedDayStyles;
            }}
            renderDay={dateObj => (
              <Tooltip label={t('common:datePicker.toDateToInfinity')}>
                <Box sx={{ display: 'block' }} component="span">
                  {dateObj.getDate()}
                </Box>
              </Tooltip>
            )}
            value={value}
            onChange={dateObj => {
              const dateRange = dateObjToRangeValueByUserFormat(dateObj);

              setDate([null, getIsoDateStringByUserFormat(dateRange)]);
              setInputValue(`- ${dateRange}` || '');
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={theme => ({
          marginRight: theme.other.spacing(6.25),
        })}
        component="ul"
      >
        {CALENDAR_TYPE_ITEMS.map(({ type, nameTranslation }) => (
          <li key={type}>
            <UnstyledButton
              sx={theme => {
                const isActive = calendarType === type;

                return {
                  width: '100%',
                  marginBottom: theme.other.spacing(0.5),
                  padding: theme.other.spacing(1),
                  fontWeight: 500,
                  backgroundColor: isActive
                    ? theme.colors[theme.primaryColor][1]
                    : undefined,
                  color: isActive
                    ? theme.colors[theme.primaryColor][0]
                    : undefined,
                  '&:hover': {
                    backgroundColor: theme.colors[theme.primaryColor][1],
                    color: theme.colors[theme.primaryColor][0],
                  },
                };
              }}
              onClick={() => {
                if (calendarType !== type) {
                  setCalendarType(type);
                  setDate(
                    type === CALENDAR_TYPES.single ? [null] : [null, null],
                  );
                  setInputValue('');
                }
              }}
            >
              {t(nameTranslation)}
            </UnstyledButton>
          </li>
        ))}
      </Box>
      {getCalendar()}
    </Box>
  );
};
