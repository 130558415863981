import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIcon } from 'icons';
import { Actions } from 'components/shared';

import { DataTypeEditModal } from '../DataTypeEditModal';

type Props = {
  yaml: string;
};

export const DataTypeActions = ({ yaml }: Props) => {
  const { t } = useTranslation('common');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <>
      <Actions
        items={[
          {
            name: t('common:edit'),
            icon: <EditIcon />,
            colorDesktop: 'blue',
            action: () => {
              setIsEditModalOpen(true);
            },
          },
        ]}
      />
      <DataTypeEditModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        yaml={yaml}
      />
    </>
  );
};
