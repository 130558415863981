import { ThemeComponentType } from '../types';

export const ScrollArea: ThemeComponentType = {
  styles: {
    root: {
      '.mantine-ScrollArea-scrollbar': {
        backgroundColor: 'inherit',
      },
    },
  },
};
