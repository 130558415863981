import { apiAuthClient } from 'api/helpers';

import {
  PutCustomizationsDocumentResponseType,
  PutCustomizationsDocumentRequestType,
} from '../types';

export const putCustomizationsDocument = async ({
  pathParams: { organisationId, boardId, templateId },
  bodyParams,
}: PutCustomizationsDocumentRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates/${templateId}/customizations/document`;

  const { data } =
    await apiAuthClient.put<PutCustomizationsDocumentResponseType>(
      url,
      bodyParams,
    );

  return data;
};
