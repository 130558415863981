import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, DefaultProps } from '@mantine/core';

import { Breadcrumbs, SectionHeader } from 'components/shared';
import { DYNAMIC_URLS, URLS } from 'consts';

type Props = {
  sx?: DefaultProps['sx'];
};

export const Header = ({ sx }: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const { transactionId, transactionEntry, transactionDataStream } =
    useParams() as {
      transactionId: string;
      transactionEntry: string;
      transactionDataStream: string;
    };

  return (
    <Box sx={sx}>
      <Breadcrumbs
        items={[
          {
            name: t('transactions:transactions'),
            url: URLS.transactions,
          },
          {
            name: t('transactions:transactionList'),
            url: URLS.transactions,
          },
          {
            name: transactionId,
            url: DYNAMIC_URLS.transactionDetails({
              transactionId,
              transactionDataStream,
            }),
          },
          {
            name: t('transactions:activityHistory'),
            url: DYNAMIC_URLS.transactionEntries({
              transactionId,
              transactionDataStream,
            }),
          },
          {
            name: transactionEntry,
            url: '#',
          },
        ]}
      />
      <SectionHeader>{transactionEntry}</SectionHeader>
    </Box>
  );
};
