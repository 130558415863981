import { Menu as MenuMantine } from '@mantine/core';

export const MenuItem = ({ isActive, ...menuItemMantineProps }) => (
  <MenuMantine.Item
    sx={theme => ({
      backgroundColor: isActive
        ? theme.colors[theme.primaryColor][1]
        : undefined,
    })}
    {...menuItemMantineProps}
  />
);
