import { FIELDS_PER_STEP } from '../consts';
import { RegistrationValuesType, ValidStepsType } from '../types';

export const getStepsValidationFromServer = (serverErrors: {
  [key: string]: string;
}) =>
  Object.entries(FIELDS_PER_STEP).reduce(
    (accumulator, [step, fields]) => ({
      ...accumulator,
      [step]: !(
        Object.keys(serverErrors) as (keyof RegistrationValuesType)[]
      ).some(fieldFromServer =>
        (fields as (keyof RegistrationValuesType)[]).includes(fieldFromServer),
      ),
    }),
    {} as ValidStepsType,
  );
