import { apiAuthClient } from '../../helpers';
import {
  GetBoardInvitationRequestType,
  GetBoardInvitationResponseType,
} from '../types';

export const getBoardInvitation = async ({
  pathParams: { invitationId },
}: GetBoardInvitationRequestType) => {
  const url = `/api/v2/board-invitations/${invitationId}`;

  const { data } = await apiAuthClient.get<GetBoardInvitationResponseType>(url);

  return data.data;
};
