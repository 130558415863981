import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Anchor,
  Box,
  Checkbox,
  DefaultProps,
  Loader,
  packSx,
  Paper,
  Text,
} from '@mantine/core';

import { useGetCurrentOrganisation } from 'api/organisations';
import { usePutCustomizationsDocument } from 'api/templates';
import { useServerErrors, useShowNotification } from 'hooks';
import { InfoIcon } from 'icons';
import { DYNAMIC_URLS } from 'consts';
import { TemplateBrandingConfigType } from 'types';

type Props = {
  isTemplateBrandingDisabled: TemplateBrandingConfigType['disabledBranding'];
  isOrganisationBrandingEnabled: TemplateBrandingConfigType['organisationBrandingExists'];
  isRefetching: boolean;
  sx: DefaultProps['sx'];
};

export const LetterheadManagement = ({
  isTemplateBrandingDisabled,
  isOrganisationBrandingEnabled,
  isRefetching,
  sx,
}: Props) => {
  const { t } = useTranslation(['templates', 'organisations']);

  const { templateId } = useParams() as {
    templateId: string;
  };

  const { addServerErrorActions } = useServerErrors();
  const showNotification = useShowNotification();

  const {
    mutateAsync: updateBrandingConfig,
    isLoading: isUpdatingBrandingConfig,
  } = usePutCustomizationsDocument({
    pathParams: {
      templateId,
    },
  });

  const { data: organisation } = useGetCurrentOrganisation();

  if (!organisation) {
    return null;
  }

  const isLoading = isUpdatingBrandingConfig || isRefetching;

  return (
    <Paper
      sx={[
        theme => ({
          maxWidth: 752,
          border: `1px solid ${theme.colors.gray[1]}`,
        }),
        ...packSx(sx),
      ]}
    >
      <Box sx={{ display: 'flex' }}>
        <InfoIcon
          sx={theme => ({
            marginRight: theme.other.spacing(1),
            flexShrink: 0,
          })}
          color="blue"
        />
        <div>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(0.25),
            })}
          >
            {isOrganisationBrandingEnabled ? (
              <Trans
                i18nKey="templates:letterheadAddedForOrganisation"
                values={{
                  organisation: organisation.name,
                }}
                components={{
                  b: <b />,
                }}
              />
            ) : (
              <Trans
                i18nKey="templates:letterheadNotAddedForOrganisation"
                values={{
                  organisation: organisation.name,
                }}
                components={{
                  b: <b />,
                  u: <u />,
                  a: (
                    <Anchor
                      sx={{
                        fontSize: 'inherit',
                      }}
                      component={Link}
                      to={DYNAMIC_URLS.organisationBranding({
                        organisationId: String(organisation.id),
                      })}
                    />
                  ),
                }}
              />
            )}
          </Text>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
          >
            {t('templates:youCanAddLetterheadToThisTemplate')}
          </Text>
          <Box sx={{ position: 'relative' }}>
            <Checkbox
              sx={{
                visibility: isLoading ? 'hidden' : undefined,
              }}
              label={t('templates:dontWantUseLetterheadForThisTemplate')}
              defaultChecked={isTemplateBrandingDisabled}
              onChange={async e => {
                try {
                  await updateBrandingConfig({
                    disableBranding: e.target.checked,
                  });

                  showNotification({
                    message: t('organisations:updatedBrandingConfig'),
                  });
                } catch (error) {
                  addServerErrorActions(error);
                }
              }}
            />
            {isLoading && (
              <Loader
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  margin: 'auto',
                }}
              />
            )}
          </Box>
        </div>
      </Box>
    </Paper>
  );
};
