import { Link } from 'react-router-dom';
import { Box, Button, ButtonProps, Tooltip } from '@mantine/core';

import { useGetColor } from 'hooks';
import { ActionItemType } from 'types';

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
} & ActionItemType;

export const ActionItem = ({
  name,
  icon,
  color,
  disabled = false,
  isLoading = false,
  tooltipLabel,
  dataTestId,
  ...props
}: Props) => {
  const contentColor = useGetColor('to' in props ? 'blue' : color);

  const getButtonProps = () => {
    if ('to' in props) {
      return {
        component: Link,
        to: props.to,
      };
    }

    if ('action' in props) {
      return { onClick: props.action };
    }

    return {};
  };

  return (
    <Tooltip disabled={!tooltipLabel} label={tooltipLabel} position="top">
      <Box
        sx={{
          width: '100%',
        }}
        component="span"
      >
        <Button
          sx={theme => ({
            margin: theme.other.spacing(0, 2),
            fontSize: theme.fontSizes.lg,
            color: contentColor,
            '&:hover': {
              textDecoration: 'underline',
            },
            '&[data-disabled]': {
              backgroundColor: 'transparent',
              fontSize: theme.fontSizes.lg,
              color: theme.colors.gray[3],
            },
            '&[data-loading]': {
              '.mantine-Button-leftIcon': {
                width: 24,
              },
            },
          })}
          {...(getButtonProps() as ButtonProps)}
          variant="subtle"
          leftIcon={icon && icon}
          disabled={isLoading ? false : disabled}
          loading={isLoading}
          data-testid={dataTestId}
        >
          {name}
        </Button>
      </Box>
    </Tooltip>
  );
};
