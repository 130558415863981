import { createContext, useCallback, useState } from 'react';

export const ForceUpdateContext = createContext(null);

export const ForceUpdateContextProvider = ({ children }) => {
  const [bool, setBool] = useState(false);

  const toggle = useCallback(() => {
    setBool(value => !value);
  }, [bool]);

  return (
    <ForceUpdateContext.Provider value={toggle}>
      {children}
    </ForceUpdateContext.Provider>
  );
};
