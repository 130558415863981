import { useTranslation } from 'react-i18next';
import { useShowNotification } from './useShowNotification';

export const useShowGeneralErrorNotification = () => {
  const { t } = useTranslation('common');

  const showNotification = useShowNotification();

  return () => {
    showNotification({
      variant: 'error',
      message: t('common:errorTryAgain'),
    });
  };
};
