import { Text, TextProps } from '@mantine/core';

export const FieldErrorMessage = (props: TextProps) => (
  <Text
    sx={theme => ({
      ...((theme.components.InputWrapper as any).styles(theme).error || {}),
    })}
    color="red"
    {...props}
  />
);
