import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionEntryDebugResponseType,
  GetTransactionEntryDebugRequestType,
} from '../types';

export const getTransactionEntryDebug = async ({
  pathParams: { organisationId, dataStream, transactionId, transactionEntry },
}: GetTransactionEntryDebugRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStream}/transactions/${transactionId}/entries/${transactionEntry}/debug`;

  const { data } =
    await apiAuthClient.get<GetTransactionEntryDebugResponseType>(url);

  return data.data;
};
