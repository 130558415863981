import { apiAuthClient } from 'api/helpers';

import { GetDataTypeResponseType, GetDataTypeRequestType } from '../types';

export const getDataType = async ({
  pathParams: { organisationId, dataTypeName },
}: GetDataTypeRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-types/${dataTypeName}`;

  const { data } = await apiAuthClient.get<GetDataTypeResponseType>(url);

  return data.data;
};
