import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const WomanWithMagnifierIcon = ({
  color,
  size,
  width = 129,
  height = 109,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 129 109"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id={`${id}_a`} d="M0 0h101.692v57.928H0z" />
        <path id={`${id}_b`} d="M0 0h53.47v53.596H0z" />
      </defs>
      <g transform="translate(.797)" fill="none" fillRule="evenodd">
        <path
          d="m75.42 56.752 3.429 1.166 2.72-6.626-3.662-1.013-2.486 6.473Zm3.897 2.198-4.974-1.692 3.063-7.975 5.28 1.46-3.37 8.207Z"
          fill="#0B0817"
        />
        <path
          fill="#FFF"
          d="m77.895 50.242 3.672 1.069-2.722 6.635-3.45-1.178z"
        />
        <g transform="translate(0 51.072)">
          <mask id={`${id}_c`} fill="#fff">
            <use xlinkHref={`#${id}_a`} />
          </mask>
          <path
            d="M34.355 2.938s-3.83 1.106-3.052 5.14c0 0-15.386-1.106-21.748 12.17-6.362 13.274-12.4 20.237-8.115 28.696 4.285 8.46 11.232 9.827 17.074 8.59 5.843-1.236 15.581-4.425 15.581-4.425l67.597-.085-.375-22.062-14.257-1.755-2.376 16.922s-3.376-17.173-4.627-24.945c-1.25-7.772-6.253-20.808-23.636-17.55 0 0-1.48-3.427-4.268-3.634L34.355 2.938Z"
            fill="#0B0817"
            mask={`url(#${id}_c)`}
          />
        </g>
        <path
          d="m71.553 53.447 12.297 4.167-10.515 29.829a6.83 6.83 0 0 1-8.54 4.238c-3.72-1.202-5.679-5.28-4.296-8.944l11.054-29.29Z"
          fill={iconColor}
          fillRule="nonzero"
        />
        <path
          d="M60.85 67.211s5.38-9.093 5.66-11.708c.281-2.615 4.054-.644 3.894 2.575-.16 3.219-1.164 6.035-1.164 6.035l-8.39 3.098Z"
          fill="#FFF"
        />
        <path
          d="M67.606 54.704c-.218 0-.604.084-.686.843-.234 2.175-3.723 8.423-5.135 10.878l7.127-2.631c.217-.665.948-3.076 1.08-5.737.095-1.897-1.315-3.272-2.289-3.349-.025-.002-.059-.004-.097-.004ZM59.904 68l.592-1c.054-.09 5.336-9.046 5.605-11.542.134-1.248.948-1.635 1.667-1.575 1.5.119 3.16 1.986 3.048 4.215-.162 3.242-1.146 6.035-1.188 6.153l-.065.182-9.659 3.567Z"
          fill="#000"
        />
        <path
          d="m34.803 81.093 21.274-6.108s1.606-8.39 6.473-10.098c3.69-1.295 13.37-3.964 17.833-5.178 1.45-.395 2.819.85 2.567 2.334-.164.965-.64 1.953-1.76 2.6-2.871 1.659-7.826 3.651-7.826 3.651s7.384-1.561 9.074-.873c2.214.902 2.354 4.49-.761 5.515 0 0 2.774 4.342-.876 5.562 0 0 2.217 1.191.96 3.443-.595 1.065-1.692 1.752-2.9 1.9l-9.74 1.194s-1.753 3.707-7.252 6.049c-5.5 2.341-27.926 12.678-27.926 12.678s-6.788-6.455-5.21-12.85c1.576-6.396 6.07-9.82 6.07-9.82"
          fill="#FFF"
        />
        <path
          d="M34.995 81.467c-.522.425-4.437 3.763-5.862 9.544-.78 3.157.632 6.36 1.953 8.49a22.927 22.927 0 0 0 2.945 3.765c2.524-1.162 22.554-10.382 27.677-12.563 5.272-2.245 7.023-5.81 7.04-5.846l.098-.205.224-.028 9.74-1.194c1.107-.135 2.076-.767 2.59-1.69.32-.574.41-1.112.268-1.6-.238-.816-1.054-1.274-1.062-1.278l-.843-.453.907-.303c.783-.262 1.267-.682 1.48-1.284.533-1.509-.807-3.642-.82-3.664l-.286-.448.504-.167c1.275-.42 2.06-1.363 2.1-2.524.035-.994-.501-1.863-1.365-2.215-1.155-.47-5.915.277-8.834.894l-.239-.788c.05-.02 4.961-2 7.774-3.625.859-.496 1.383-1.273 1.56-2.312a1.642 1.642 0 0 0-.517-1.495 1.634 1.634 0 0 0-1.536-.37c-4.114 1.119-14.084 3.864-17.805 5.169-4.584 1.608-6.188 9.705-6.204 9.786l-.048.249-.243.07-21.196 6.085Zm-1.133 22.787-.203-.192c-.284-.27-6.943-6.696-5.327-13.25 1.593-6.462 6.033-9.905 6.221-10.048l.062-.047.074-.022 21.036-6.039c.139-.63.561-2.358 1.386-4.246 1.388-3.176 3.172-5.166 5.303-5.914 3.746-1.313 13.739-4.065 17.861-5.186a2.452 2.452 0 0 1 2.307.555c.627.57.916 1.41.774 2.247-.22 1.292-.879 2.264-1.96 2.89-1.289.743-3.003 1.559-4.504 2.232 2.19-.336 4.724-.593 5.701-.196 1.188.484 1.926 1.665 1.88 3.01-.037 1.074-.643 2.401-2.2 3.1.417.793 1.16 2.514.655 3.948-.226.641-.666 1.143-1.312 1.498.319.295.674.733.844 1.314.206.71.092 1.462-.34 2.235-.643 1.152-1.843 1.94-3.21 2.108l-9.525 1.168c-.463.835-2.455 3.959-7.355 6.045-5.431 2.312-27.69 12.57-27.915 12.673l-.253.117Z"
          fill="#000"
        />
        <path
          d="m87.857 80.367 12.747 1.59 1.061-4.97 24.45-12.057a.95.95 0 0 0 .534-.814.95.95 0 0 0-.45-.864 2.667 2.667 0 0 0-2.365-.227l-6.674 2.572 4.486-6.68a1.13 1.13 0 0 0 .038-1.222 1.123 1.123 0 0 0-1.081-.566 2.981 2.981 0 0 0-2.063 1.083l-5.937 7.28 1.972-6.062c.152-.47.029-.95-.33-1.288a1.206 1.206 0 0 0-1.304-.243 3.061 3.061 0 0 0-1.467 1.25l-4.29 7.102-8.331-2.94 5.521-8.509a1.856 1.856 0 0 0-.463-2.512 1.84 1.84 0 0 0-2.391.186c-1.197 1.191-2.956 2.99-4.533 4.788-.816.93-1.877 2.019-3 3.171-2.553 2.62-5.447 5.591-6.084 7.366-.818 2.278-.19 10.774-.046 12.566Zm13.396 2.503-14.16-1.766-.03-.333c-.037-.429-.909-10.541.064-13.25.698-1.944 3.53-4.851 6.27-7.663 1.115-1.145 2.168-2.226 2.97-3.14 1.593-1.816 3.366-3.628 4.572-4.829a2.66 2.66 0 0 1 3.456-.267 2.683 2.683 0 0 1 .67 3.631l-4.95 7.627 6.71 2.369 3.944-6.53a3.897 3.897 0 0 1 1.864-1.587 2.037 2.037 0 0 1 2.175.407c.59.553.802 1.376.551 2.147l-.606 1.863 3.148-3.86a3.807 3.807 0 0 1 2.636-1.384c.76-.06 1.474.312 1.86.973.385.661.36 1.466-.067 2.102l-3.026 4.505 4.234-1.632a3.497 3.497 0 0 1 3.098.298c.557.35.87.95.836 1.607a1.766 1.766 0 0 1-.994 1.513l-24.09 11.88-1.135 5.32ZM67.542 69.59l-.137-.815 8.83-1.489.138.815zM67.72 74.864l-.09-.822 14.29-1.569.089.822zM68.042 80.054l-.093-.821 12.363-1.408.093.82z"
          fill="#0B0817"
        />
        <path
          d="M9.62 87.475a.413.413 0 0 1-.083-.818l26.083-5.421a.413.413 0 0 1 .168.81l-26.083 5.42a.42.42 0 0 1-.085.01"
          fill="#FFF"
        />
        <path
          d="M34.876 10.78s-15.3 3.98-11.826 18.984l-.413 2.57s-7.128-2.326-9.805 4.98c-2.642 7.21 3.933 10.852 8.564 10.521 0 0 2.15 7.295 12.157 2.32l19.104-2.569s9.676-.829 7.939-10.776c0 0 4.135-3.813 2.48-8.373-1.653-4.559-5.623-5.72-5.623-5.72l-3.748-1.878S52.69 4.046 34.876 10.781"
          fill="#0B0817"
        />
        <path
          fill="#FFF"
          d="m30.46 39.629 5.669 17.618 16.216-2.95-2.02-17.01z"
        />
        <path
          d="m31.006 39.98 5.404 16.795 15.48-2.816-1.926-16.214-18.958 2.236Zm4.841 17.738-5.934-18.44 20.773-2.45L52.8 54.634l-16.953 3.084Z"
          fill="#000"
        />
        <path
          d="M36.428 16.31s5.719 5.781 13.552 4.192c0 0 .289 1.782 1.827 1.445 1.537-.338 3.7-1.156 4.71 1.3 1.008 2.457-2.355 4.432-3.653 4.384.602.022.036 7.034-.099 7.735-1.651 8.534-12.654 13.522-19.872 8.33-2.102-1.513-3.611-3.726-4.508-6.14-.086-.23-1.19-3.6-.896-3.711 0 0-5.234 1.973-5.815-2.36-.48-3.59 4.518-4.673 5.815-3.42 0 0 8.555-5.275 8.94-11.754"
          fill="#FFF"
        />
        <path
          d="M27.853 33.989c-.045.422.758 2.99.918 3.422.933 2.51 2.441 4.567 4.363 5.95 3.123 2.245 7.332 2.652 11.26 1.087 4.14-1.65 7.192-5.16 7.966-9.161.16-.826.575-6.712.242-7.375.02.041.107.127.247.132l.03-.826c.668.026 2.2-.676 2.973-1.789.465-.669.56-1.35.283-2.024-.719-1.75-2.01-1.588-3.756-1.168-.167.04-.329.08-.484.114-.534.116-1.006.038-1.404-.233-.448-.306-.687-.793-.808-1.14-6.3 1.101-11.254-2.424-12.932-3.826-.986 6.227-8.704 11.054-9.045 11.265l-.273.167-.23-.222c-.52-.502-2.1-.558-3.432.118-.729.37-1.916 1.245-1.688 2.95.13.965.523 1.651 1.17 2.04 1.543.926 4.067-.002 4.092-.012l.29.774a.338.338 0 0 0 .218-.243m11.752 12.226c-2.521 0-4.943-.738-6.952-2.183-2.055-1.478-3.665-3.668-4.655-6.332a40.052 40.052 0 0 1-.6-1.932c-.166-.59-.276-1.029-.33-1.358-.937.256-2.838.608-4.236-.23-.875-.524-1.402-1.412-1.566-2.64-.217-1.615.56-3 2.132-3.797 1.425-.723 3.171-.782 4.112-.183a28.683 28.683 0 0 0 4.029-3.25c1.938-1.889 4.287-4.814 4.477-8.024l.055-.921.65.654c.049.05 4.39 4.342 10.544 4.343.846 0 1.725-.081 2.633-.265l.42-.086.07.424c0 .006.122.699.57 1.001.205.14.454.174.76.107.15-.033.307-.07.468-.11 1.46-.351 3.668-.883 4.711 1.657.386.938.258 1.91-.369 2.811-.724 1.041-2.053 1.84-3.065 2.074.083.504.129 1.495.028 3.541-.087 1.765-.252 3.572-.321 3.928-.827 4.275-4.073 8.019-8.47 9.772a13.78 13.78 0 0 1-5.095.999"
          fill="#000"
        />
        <path
          d="M45.922 24.309c0 .327-1.088.592-2.43.592s-2.43-.265-2.43-.592c0-.328 1.088-.593 2.43-.593s2.43.265 2.43.593M36.292 25.033c.148.292-.702 1.022-1.899 1.63-1.196.61-2.286.866-2.434.575-.148-.292.702-1.023 1.899-1.631 1.196-.61 2.286-.866 2.434-.574M41.063 33.807a.43.43 0 0 1-.066-.006c-1.297-.208-2.118-.659-2.508-1.378-.588-1.082.002-2.423.475-3.5.164-.37.317-.72.39-.99.111-.411.11-.73-.004-.875a.33.33 0 0 0-.225-.11.413.413 0 0 1-.025-.826c.055.001.56-.004.897.424.29.367.341.907.153 1.604-.089.329-.255.706-.43 1.106-.402.912-.9 2.047-.507 2.772.256.472.9.794 1.915.957a.414.414 0 0 1-.065.822M32.719 30.762a.411.411 0 0 1-.388-.554c.02-.055.507-1.365 1.744-1.76.855-.272 1.822-.038 2.875.697a.414.414 0 0 1-.47.678c-.83-.578-1.553-.775-2.15-.588-.85.267-1.22 1.247-1.224 1.257a.414.414 0 0 1-.387.27M45.155 27.572c.038.769-.374 1.415-.923 1.442-.548.028-1.023-.573-1.062-1.342-.039-.768.374-1.414.922-1.442.549-.028 1.024.573 1.063 1.342M41.958 37.524a.413.413 0 0 1-.103-.814c.024-.006 1.752-.475 2.38-1.816.366-.783.291-1.736-.221-2.831a.412.412 0 1 1 .747-.35c.622 1.33.695 2.52.217 3.538-.797 1.696-2.831 2.238-2.917 2.26a.412.412 0 0 1-.103.013"
          fill="#0B0817"
        />
        <path
          d="M32.72 27.045a.414.414 0 0 1-.224-.76c.016-.011 1.18-.781 2.22-2.338 1.39-2.082 1.914-4.514 1.558-7.227a.413.413 0 1 1 .818-.108c.479 3.654-.667 6.274-1.714 7.828-1.137 1.689-2.383 2.505-2.436 2.54a.41.41 0 0 1-.223.065M26.69 32.684a.413.413 0 0 1-.411-.445c.058-.764-.096-1.335-.459-1.699-.542-.543-1.407-.483-1.416-.482a.412.412 0 0 1-.069-.824c.052-.003 1.245-.096 2.061.715.546.542.783 1.334.705 2.354a.413.413 0 0 1-.41.381"
          fill="#0B0817"
        />
        <path
          d="M61.165 26.798C61.165 12.226 72.95.413 87.488.413c14.537 0 26.322 11.813 26.322 26.385 0 14.572-11.785 26.385-26.322 26.385-14.538 0-26.323-11.813-26.323-26.385"
          fill="#FFF"
        />
        <g transform="translate(60.753)">
          <mask id={`${id}_d`} fill="#fff">
            <use xlinkHref={`#${id}_c`} />
          </mask>
          <path
            d="M26.735.827C12.448.827.825 12.477.825 26.798S12.448 52.77 26.735 52.77s25.91-11.651 25.91-25.972c0-14.32-11.623-25.971-25.91-25.971m0 52.77C11.993 53.596 0 41.574 0 26.797 0 12.022 11.993 0 26.735 0 41.477 0 53.47 12.022 53.47 26.798c0 14.777-11.993 26.798-26.735 26.798"
            fill="#000"
            mask={`url(#${id}_d)`}
          />
        </g>
        <path
          d="M65.185 26.798c0-12.346 9.986-22.355 22.303-22.355s22.302 10.009 22.302 22.355c0 12.347-9.985 22.355-22.302 22.355S65.185 39.145 65.185 26.798"
          fill="#FFF"
        />
        <path
          d="M87.488 4.857c-12.07 0-21.89 9.842-21.89 21.941 0 12.099 9.82 21.942 21.89 21.942s21.89-9.843 21.89-21.942-9.82-21.941-21.89-21.941M64.773 26.798c0-12.554 10.19-22.768 22.715-22.768s22.714 10.214 22.714 22.768c0 12.555-10.19 22.769-22.714 22.769-12.525 0-22.715-10.214-22.715-22.769Z"
          fill="#000"
        />
        <circle
          fill={iconColor}
          fillRule="nonzero"
          cx={87.754}
          cy={26.788}
          r={19.398}
        />
      </g>
    </Box>
  );
};
