import { Box } from '@mantine/core';
import { IconPropsType } from './types';
import { useGetColor } from '../hooks';

export const LinkIcon = ({ color, size = 18, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);
  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        stroke={color ? iconColor : '#1E1C49'}
        strokeWidth={1.5}
        d="m5.571 7.857-3.624 3.625a3.232 3.232 0 1 0 4.571 4.571l3.625-3.624m2.286-2.286 3.624-3.625a3.233 3.233 0 1 0-4.571-4.571L7.857 5.57M5.571 12.43 12.43 5.57"
      />
    </Box>
  );
};
