import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const StrikethroughIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M6.667 15.333A4.667 4.667 0 0 0 11.333 20h1.983a4.018 4.018 0 0 0 4.017-4.018c0-1.607-1.19-3.349-2.666-3.982m-4.56-1.764A3.476 3.476 0 0 1 8 7.041 3.041 3.041 0 0 1 11.041 4h1.626A3.333 3.333 0 0 1 16 7.333M4.667 12h14.666"
      />
    </Box>
  );
};
