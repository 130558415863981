import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getOrganisation } from '../requests';
import { GetOrganisationRequestType } from '../types';

export const useGetOrganisation = ({
  pathParams: { organisationId },
}: GetOrganisationRequestType) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.organisation,
      { organisationId: Number(organisationId) },
    ],
    queryFn: () =>
      getOrganisation({
        pathParams: { organisationId },
      }),
  });
