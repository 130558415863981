import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Carousel } from '@mantine/carousel';
import { Box, Button, Text } from '@mantine/core';

import { TextInput } from 'components/shared';

const AUTO_NUMERATION_VARIABLES = [
  {
    value: '%i',
    nameTranslation: 'templates:autoNumerationDocumentNumber',
  },
  {
    value: '%d',
    nameTranslation: 'templates:autoNumerationDay',
  },
  {
    value: '%m',
    nameTranslation: 'templates:autoNumerationMonth',
  },
  {
    value: '%y',
    nameTranslation: 'templates:autoNumerationYear',
  },
];

type Props = {
  isTemplateNumericEnabled: boolean;
  onTemplateNumericEnabledChange: (enabled: boolean) => void;
  templateNumeric: string;
  onTemplateNumericChange: (templateNumeric: string) => void;
};

export const AutoNumerationInput = ({
  isTemplateNumericEnabled,
  onTemplateNumericEnabledChange,
  templateNumeric,
  onTemplateNumericChange,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const [lastSelectionEnd, setLastSelectionEnd] = useState(
    templateNumeric.length,
  );

  const inputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = lastSelectionEnd;
      inputRef.current.selectionEnd = lastSelectionEnd;
    }
  }, [lastSelectionEnd]);

  return (
    <div>
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.other.spacing(0.5),
        })}
      >
        <Text>
          <Trans
            i18nKey={
              isTemplateNumericEnabled
                ? 'templates:autoNumerationEnabled'
                : 'templates:autoNumerationDisabled'
            }
            components={{
              u: <u />,
            }}
          />
        </Text>
        <Button
          sx={theme => ({
            height: 'initial',
            padding: 0,
            fontSize: theme.fontSizes.md,
            fontWeight: 400,
          })}
          variant="subtle"
          onClick={() => {
            onTemplateNumericEnabledChange(!isTemplateNumericEnabled);
          }}
        >
          {isTemplateNumericEnabled ? t('common:disable') : t('common:enable')}
        </Button>
      </Box>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
        })}
        size="md"
        weight={400}
      >
        {t('templates:variableIsRequired', {
          variable: t('templates:autoNumerationDocumentNumber'),
        })}
      </Text>
      <TextInput
        sx={theme => ({
          marginBottom: isTemplateNumericEnabled
            ? theme.other.spacing(1)
            : undefined,
        })}
        ref={inputRef}
        name="templateNumeric"
        placeholder={AUTO_NUMERATION_VARIABLES.reduce(
          (accumulator, { value }) => `${accumulator}${value}`,
          '',
        )}
        disabled={!isTemplateNumericEnabled}
        withErrorWrapperAlwaysEnabled={false}
        value={templateNumeric}
        onChange={e => {
          onTemplateNumericChange(e.target.value);
        }}
        onBlur={e => {
          setLastSelectionEnd(
            e.relatedTarget?.tagName === 'BUTTON' && e.target.selectionEnd
              ? e.target.selectionEnd
              : templateNumeric.length,
          );
        }}
      />
      {isTemplateNumericEnabled && (
        <Carousel
          slideSize={0}
          skipSnaps
          align="start"
          slideGap="xs"
          withControls={false}
        >
          {AUTO_NUMERATION_VARIABLES.map(({ value, nameTranslation }) => (
            <Carousel.Slide key={value}>
              <Button
                sx={theme => ({
                  fontWeight: 400,
                  borderColor: theme.colors[theme.primaryColor][2],
                  ':hover': {
                    borderColor: theme.colors[theme.primaryColor][4],
                  },
                })}
                variant="light"
                onClick={() => {
                  onTemplateNumericChange(
                    `${templateNumeric.slice(
                      0,
                      lastSelectionEnd,
                    )}${value}${templateNumeric.slice(lastSelectionEnd)}`,
                  );
                  setLastSelectionEnd(
                    lastSelection => lastSelection + value.length,
                  );

                  inputRef.current?.focus();
                }}
              >
                {t(nameTranslation)} ({value})
              </Button>
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
    </div>
  );
};
