import { apiAuthClient } from 'api/helpers';

import {
  PatchDataStreamRequestType,
  PatchDataStreamResponseType,
} from '../types';

export const patchDataStream = async ({
  pathParams: { organisationId, dataStreamId },
  bodyParams,
}: PatchDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}`;

  const { data } = await apiAuthClient.patch<PatchDataStreamResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
