import { decamelize } from 'humps';

export const stringifyUrlQuery = (params: {
  [key: string]: string | number | (string | number)[] | null | undefined;
}) => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(valueItem => {
        searchParams.append(
          decamelize(`${key}[]`),
          window.encodeURIComponent(String(valueItem)),
        );
      });
    } else if (value !== null && value !== undefined && value !== '') {
      searchParams.append(decamelize(key), String(value));
    }
  });

  return [...searchParams].length > 0 ? `?${searchParams.toString()}` : '';
};
