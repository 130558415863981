import { Text } from '@mantine/core';

type Props = {
  error: string;
};

export const CertificateServerError = ({ error }: Props) => (
  <Text color="red" mt="lg">
    {error}
  </Text>
);
