import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getDocuments } from '../requests';
import { GetDocumentsRequestType } from '../types';

export const useGetDocuments = (
  {
    queryParams: {
      boardId,
      page,
      perPage,
      search,
      transactionDataStream,
      transactionId,
    },
  }: GetDocumentsRequestType,
  options?: {
    keepPreviousData?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.documents,
      {
        boardId,
        page,
        perPage,
        search,
        transactionDataStream,
        transactionId,
      },
    ],
    queryFn: () =>
      getDocuments({
        queryParams: {
          boardId,
          page,
          perPage,
          search,
          transactionDataStream,
          transactionId,
        },
      }),
    ...options,
  });
