import { apiAuthClient } from 'api/helpers';

import {
  PatchTransactionDataStreamRequestType,
  PatchTransactionDataStreamResponseType,
} from '../types';

export const patchTransactionDataStream = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
  bodyParams,
}: PatchTransactionDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}/data-stream`;

  const { data } =
    await apiAuthClient.patch<PatchTransactionDataStreamResponseType>(
      url,
      bodyParams,
    );

  return data.data;
};
