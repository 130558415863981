import { UseFormSetError } from 'react-hook-form';

import { ValidationErrors } from 'types';

export const setHookFormServerErrors = (
  errors: ValidationErrors,
  setFormError: UseFormSetError<any>,
) => {
  Object.entries(errors).forEach(([field, message]) => {
    setFormError(
      field,
      {
        type: 'custom',
        message,
      },
      {
        shouldFocus: true,
      },
    );
  });
};
