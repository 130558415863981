import { ReactNode } from 'react';
import { NotificationProps, showNotification } from '@mantine/notifications';

import {
  ErrorTwoToneIcon,
  SuccessTwoToneIcon,
  WarningTwoToneIcon,
} from 'icons';

type IconVariants = 'success' | 'error' | 'warning';

type Props = {
  message: ReactNode;
  variant?: IconVariants;
} & NotificationProps;

export const useShowNotification =
  () =>
  ({ message, variant = 'success', ...showNotificationProps }: Props) => {
    const getIcon = (iconVariant: IconVariants) => {
      switch (iconVariant) {
        case 'error': {
          return <ErrorTwoToneIcon color="error" />;
        }

        case 'warning': {
          return <WarningTwoToneIcon color="yellow" />;
        }

        default: {
          return <SuccessTwoToneIcon color="green" />;
        }
      }
    };

    showNotification({
      message,
      icon: getIcon(variant),
      styles: {
        description: {
          fontWeight: variant === 'error' ? 600 : undefined,
        },
      },
      ...showNotificationProps,
    });
  };
