import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const FileIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.571 11.995h6.858M8.57 15.429h6.858M8.57 8.569h6.858M17.714 20H6.286a1.143 1.143 0 0 1-1.143-1.143V5.143C5.143 4.512 5.655 4 6.286 4h9.143l3.428 3.429v11.428c0 .631-.511 1.143-1.143 1.143Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
