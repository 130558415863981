import { useTranslation } from 'react-i18next';

import { DescriptionField, TextInput } from 'components/shared';

import { PredefinedValues } from '../../PredefinedValues';

export const Currency = ({ inputProps, error, onValueClick }) => {
  const { t } = useTranslation('templates');

  const currencyItems = [
    { value: 'zł', label: t('templates:polishZloty') },
    { value: '€', label: t('templates:euro') },
    { value: '$', label: t('templates:dollar') },
  ];

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.currency')}
      description={t('templates:templateCreatorVariables.currencyDescription')}
      error={error}
    >
      <TextInput
        {...inputProps}
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
        })}
        placeholder={t('templates:templateCreatorVariables.currency')}
        withErrorWrapperAlwaysEnabled={false}
      />
      <PredefinedValues items={currencyItems} onValueClick={onValueClick} />
    </DescriptionField>
  );
};
