import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const CheckIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m7 11.808 3.461 3.461L17 8.731"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <rect
        x={0.75}
        y={0.75}
        width={22.5}
        height={22.5}
        rx={11.25}
        stroke={iconColor}
        strokeWidth={1.5}
      />
    </Box>
  );
};
