import { useMutation } from '@tanstack/react-query';

import { postOtpLogin } from '../requests';
import { PostOtpLoginRequestType } from '../types';

export const usePostOtpLogin = () =>
  useMutation({
    mutationFn: (bodyParams: PostOtpLoginRequestType['bodyParams']) =>
      postOtpLogin({
        bodyParams,
      }),
  });
