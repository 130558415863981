import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { postCustomizationsEmailPreview } from '../requests';
import { PostCustomizationsEmailPreviewRequestType } from '../types';

export const usePostCustomizationsEmailPreviewQuery = (
  {
    pathParams: { organisationId },
    bodyParams: { color, company, sender, withCustomFooter },
  }: PostCustomizationsEmailPreviewRequestType,
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  },
) =>
  useQuery({
    queryKey: [
      API_QUERY_KEYS.organisationCustomizationsEmailPreview,
      { organisationId, color, company, sender, withCustomFooter },
    ],
    queryFn: () =>
      postCustomizationsEmailPreview({
        pathParams: { organisationId },
        bodyParams: {
          color,
          company,
          sender,
          withCustomFooter,
        },
      }),
    ...options,
  });
