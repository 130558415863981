import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { getHasHateoasAccess } from 'helpers';
import { useSearchParams, useTechnicalUsersFeature } from 'hooks';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { useGetTechnicalUsersData } from './hooks';
import { Details } from './Details';
import { TechnicalUsers } from './TechnicalUsers';
import { Webhooks } from './Webhooks';

export const Api = () => {
  const { t } = useTranslation('api');

  const { getSearchParam } = useSearchParams(['search', 'page']);

  const {
    data: hasTechnicalUsersFeature,
    isError: isHasTechnicalUsersFeatureError,
    isLoading: isLoadingHasTechnicalUsersFeature,
  } = useTechnicalUsersFeature();

  const page = getSearchParam('page');
  const name = getSearchParam('search');

  const {
    data: technicalUsersData,
    isInitialLoading: isTechnicalUsersInitialLoading,
    isError: isTechnicalUsersError,
    isRefetching: isRefetchingTechnicalUsers,
  } = useGetTechnicalUsersData();

  const hasActiveFilters = Boolean(page ?? name);

  return (
    <>
      <MetaTitle value={t('api:api')} />
      <DataWrapper
        data={technicalUsersData}
        isError={isTechnicalUsersError || isHasTechnicalUsersFeatureError}
        isLoading={
          isTechnicalUsersInitialLoading || isLoadingHasTechnicalUsersFeature
        }
        errorMargin="xl"
      >
        {data => {
          const { data: technicalUsers, meta, __links } = data || {};

          return (
            <>
              <Breadcrumbs items={[{ name: t('api:api'), url: '#' }]} />
              <Box sx={theme => theme.other.pageHeaderSpacingStyles}>
                <Details hasTechnicalUsersFeature={hasTechnicalUsersFeature} />
              </Box>
              {hasTechnicalUsersFeature && (
                <TechnicalUsers
                  hasCreateTechnicalUserAccess={getHasHateoasAccess(
                    'create',
                    __links,
                  )}
                  technicalUsers={technicalUsers}
                  isRefetchingTechnicalUsers={isRefetchingTechnicalUsers}
                  meta={meta}
                  hasActiveFilters={hasActiveFilters}
                />
              )}
              <Webhooks />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
