import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { putNotifications } from '../requests';
import { PutNotificationsRequestType } from '../types';

export const usePutNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (bodyParams: PutNotificationsRequestType['bodyParams']) =>
      putNotifications({
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.notifications],
      });
    },
  });
};
