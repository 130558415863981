import { useTranslation } from 'react-i18next';
import { Anchor, Avatar, Box, Text, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { sanitize } from 'dompurify';
import { env } from 'env';
import { useFormatDateDistanceFromNow } from 'hooks';
import { NOTIFICATION_TYPES } from 'consts';
import { UserAvatar } from 'components/shared';
import {
  ChevronRightIcon,
  ClockIcon,
  DeleteIcon,
  LogoIcon,
  EyeIcon,
} from 'icons';

import { NotificationType } from 'types';
import { ActionBlock } from './ActionBlock';

type Props = Pick<
  NotificationType,
  'message' | 'createdAt' | 'readAt' | 'url' | 'sender' | 'type'
> & {
  hasUpdateAccess: boolean;
  markAsRead: (callback?: () => void) => void;
  deleteNotification: () => void;
  handleOpen?: () => void;
};

const ACTION_BLOCK_WIDTH = 120;
export const NotificationListItem = ({
  message,
  createdAt,
  readAt,
  url,
  hasUpdateAccess,
  markAsRead,
  deleteNotification,
  sender,
  type,
  handleOpen,
}: Props) => {
  const { t } = useTranslation(['common', 'notifications']);
  const mantineTheme = useMantineTheme();
  const { hovered: isListItemHovered, ref: listItemRef } = useHover();
  const { hovered: isActionsWrapperHovered, ref: actionsWrapperRef } =
    useHover();

  const formatDateDistanceFromNow = useFormatDateDistanceFromNow();

  const isRead = readAt !== null;
  const sanitizedMessage = sanitize(message, { ALLOWED_TAGS: ['b'] });
  const actionsWidth = isRead ? ACTION_BLOCK_WIDTH : ACTION_BLOCK_WIDTH * 2;
  const isUserTypeNotification = sender.type === 'user';
  const isBoardInvitationType = type === NOTIFICATION_TYPES.boardInvitation;
  const isDocumentSignInvitationType =
    type === NOTIFICATION_TYPES.documentSignInvitation;
  const coverTransitionXWidth =
    hasUpdateAccess && isListItemHovered ? mantineTheme.other.spacing(1.25) : 0;
  const convertedUrl =
    url && env.REACT_APP_V1_URL_LOCAL
      ? url.replace(env.REACT_APP_V1_URL, env.REACT_APP_V1_URL_LOCAL)
      : url;

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        minHeight: 80,
        color: 'inherit',
        '&:hover': {
          textDecoration: 'none',
        },
        '&& > *': {
          transform: isActionsWrapperHovered ? 'translateX(0)' : undefined,
        },
      }}
      ref={listItemRef}
      // @ts-ignore
      component={convertedUrl ? Anchor : undefined}
      {...(convertedUrl && { href: convertedUrl })}
      onClick={
        convertedUrl
          ? async e => {
              e.preventDefault();

              const redirectToNotificationSource = () => {
                window.location.assign(convertedUrl);
              };

              if (isBoardInvitationType) {
                redirectToNotificationSource();
              } else if (isDocumentSignInvitationType) {
                if (handleOpen) {
                  await markAsRead(handleOpen);
                }
              } else {
                await markAsRead(redirectToNotificationSource);
              }
            }
          : undefined
      }
    >
      {hasUpdateAccess && (
        <Box
          sx={theme => ({
            display: 'flex',
            transform: isListItemHovered
              ? `translateX(-${actionsWidth - coverTransitionXWidth}px)`
              : `translateX(-${actionsWidth}px)`,
            transition: `300ms ${theme.transitionTimingFunction}`,
            zIndex: 1,
          })}
          ref={actionsWrapperRef}
        >
          <ActionBlock
            color="red.0"
            colorHover="red.6"
            width={ACTION_BLOCK_WIDTH}
            Icon={DeleteIcon}
            text={t('common:delete')}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              deleteNotification();
            }}
          />
          {!isRead && (
            <ActionBlock
              color="violet.9"
              colorHover="gray"
              width={ACTION_BLOCK_WIDTH}
              Icon={EyeIcon}
              text={t('notifications:read')}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                markAsRead();
              }}
            />
          )}
        </Box>
      )}
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: 0,
          padding: theme.other.spacing(2.25),
          borderBottom: `1px solid ${theme.colors.gray[1]}`,
          backgroundColor: isRead ? undefined : theme.colors.orange[1],
          width: '100%',
          transform: hasUpdateAccess
            ? `translateX(-${actionsWidth - coverTransitionXWidth}px)`
            : undefined,
          transition: `300ms ${theme.transitionTimingFunction}`,
          color: isRead ? theme.colors.gray[4] : undefined,
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            marginRight: theme.other.spacing(3),
          })}
        >
          {isUserTypeNotification ? (
            <UserAvatar
              sx={theme => ({
                marginRight: theme.other.spacing(1.25),
              })}
              isDeleted={sender.isDeleted}
              name={sender.firstName}
              surname={sender.lastName}
              avatar={sender.avatar}
            />
          ) : (
            <Avatar
              sx={theme => ({
                marginRight: theme.other.spacing(1.25),
              })}
              size={38}
            >
              <LogoIcon color="black" />
            </Avatar>
          )}
          <div>
            <Text
              sx={theme => ({
                marginBottom: theme.other.spacing(0.25),
                b: {
                  fontWeight: 700,
                },
              })}
              dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
            />
            <Box
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                left: theme.other.spacing(-0.5),
              })}
            >
              <ClockIcon
                sx={theme => ({ marginRight: theme.other.spacing(0.25) })}
              />
              <Text size="md">{formatDateDistanceFromNow(createdAt)}</Text>
            </Box>
          </div>
        </Box>
        {convertedUrl && <ChevronRightIcon sx={{ flexShrink: 0 }} />}
      </Box>
    </Box>
  );
};
