import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postDataStream } from '../requests';
import { PostDataStreamRequestType } from '../types';

export const usePostDataStream = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PostDataStreamRequestType['bodyParams']) =>
      postDataStream({
        pathParams: { organisationId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.dataStreams],
      });
    },
  });
};
