import { ReactNode } from 'react';
import { Box, Title, ThemeIcon, DefaultProps, packSx } from '@mantine/core';

import { useGetColor } from 'hooks';

type Props = {
  children: ReactNode;
  sx?: DefaultProps['sx'];
  icon?: ReactNode;
};

export const SectionHeader = ({ children, sx, icon }: Props) => {
  const primaryColor = useGetColor('primary.9');

  return (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.other.spacing(0.5),
        }),
        ...packSx(sx),
      ]}
    >
      {icon && (
        <ThemeIcon
          size="md"
          color={primaryColor}
          sx={theme => ({
            marginRight: theme.other.spacing(1),
          })}
        >
          {icon}
        </ThemeIcon>
      )}
      <Title order={4} sx={{ marginBottom: 0 }}>
        {children}
      </Title>
    </Box>
  );
};
