import { useInfiniteQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getOrganisationTemplates } from '../requests';
import {
  GetOrganisationTemplatesRequestType,
  GetOrganisationTemplatesResponseType,
} from '../types';

export const useGetCurrentOrganisationTemplatesInfinite = (
  {
    queryParams: { name, perPage, page },
  }: Pick<GetOrganisationTemplatesRequestType, 'queryParams'>,
  options?: {
    getNextPageParam: (
      data: GetOrganisationTemplatesResponseType,
    ) => string | undefined;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useInfiniteQuery({
    queryKey: [
      API_QUERY_KEYS.organisationTemplates,
      { organisationId, name, perPage, page },
    ],
    queryFn: ({ pageParam }) =>
      getOrganisationTemplates({
        pathParams: { organisationId },
        queryParams: { name, perPage, page: pageParam },
      }),
    ...options,
  });
};
