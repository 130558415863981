import { useTranslation } from 'react-i18next';

import { ListDeleteModal } from 'components/shared';

import { useDeleteTemplates } from 'api/templates';
import { useServerErrors, useShowNotification } from 'hooks';
import { TemplateType } from 'types';

import { getIsLocalTemplate } from '../../../../../helpers';
import { useLocalTemplates } from '../../../../../hooks';
import { LocalTemplateType } from '../../../../../types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
  templateId: LocalTemplateType['id'] | TemplateType['id'];
};

export const DeleteModal = ({
  isOpen,
  onClose,
  onDeleteSuccess,
  templateId,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const showNotification = useShowNotification();
  const { addServerErrorActions } = useServerErrors();

  const { localTemplates, replaceLocalTemplates } = useLocalTemplates();

  const { mutateAsync: deleteTemplates, isLoading: isDeletingTemplates } =
    useDeleteTemplates();

  const isLocalTemplate = getIsLocalTemplate(templateId);

  return (
    <ListDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      isDeleting={isDeletingTemplates}
      redButtonAction={async () => {
        try {
          if (isLocalTemplate) {
            replaceLocalTemplates(
              localTemplates.filter(({ id }) => id !== templateId),
            );
          } else {
            await deleteTemplates({
              queryParams: {
                templateIds: [templateId as number],
              },
            });
          }

          showNotification({
            message: t('templates:templateDeleted'),
          });

          onDeleteSuccess();
        } catch (error) {
          addServerErrorActions(error);
        } finally {
          onClose();
        }
      }}
    />
  );
};
