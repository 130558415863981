import { useTranslation } from 'react-i18next';
import { ActionIcon, Box } from '@mantine/core';

import { TextInputReactRouterSearch } from 'components/shared';
import { FilterIcon } from 'icons';

type Props = {
  toggleFilters: () => void;
};

export const Filters = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('transactions');

  return (
    <Box sx={{ display: 'flex' }}>
      <TextInputReactRouterSearch
        sx={theme => ({
          maxWidth: 328,
          width: '100%',
          marginRight: theme.other.spacing(1),
        })}
        placeholder={t('transactions:transactionListSearch')}
      />
      <ActionIcon variant="default" size={36} onClick={toggleFilters}>
        <FilterIcon />
      </ActionIcon>
    </Box>
  );
};
