import { ThemeComponentType } from '../types';

export const ActionIcon: ThemeComponentType = {
  defaultProps: {
    variant: 'subtle',
  },
  styles: (theme, { variant, color }) => {
    const getVariantRootStyles = () => {
      switch (variant) {
        case 'default':
          return {
            borderColor: theme.colors.gray[3],
            '&:hover,&:focus': {
              boxShadow: theme.shadows.xs,
            },
            '&:hover': {
              backgroundColor: theme.white,
            },
          };
        case 'filled': {
          return {
            '&:hover': {
              backgroundColor: theme.colors[color][4],
            },
          };
        }
        case 'transparent': {
          return {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          };
        }
        default:
          return {};
      }
    };

    return {
      root: {
        '&:hover': {
          backgroundColor: theme.colors[theme.primaryColor][1],
        },
        '&[data-loading="true"]': {
          'svg > g > g': {
            stroke: theme.fn.primaryColor(),
          },
        },
        ...getVariantRootStyles(),
      },
    };
  },
};
