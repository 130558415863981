import { MantineTheme } from '@mantine/core';

import { DocumentListItemParticipantType } from 'types';

export const getDocumentRoleStyles = (
  role: DocumentListItemParticipantType['role'],
  theme: MantineTheme,
) => {
  const primaryShade = theme.fn.primaryShade();

  switch (role) {
    case 'acceptor':
      return {
        fontWeight: 500,
        color: theme.colors.orange[primaryShade],
      };
    case 'signer':
      return {
        fontWeight: 500,
        color: theme.colors.green[primaryShade],
      };
    case 'owner':
      return {
        fontWeight: 600,
      };
    default:
      return {};
  }
};
