import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTransactions } from '../requests';
import { GetTransactionsRequestType } from '../types';

export const useGetTransactions = (
  {
    queryParams: {
      transactionIdOrName,
      transactionStatus,
      transactionOwnerIds,
      page,
      perPage,
      sortDir,
    },
  }: {
    queryParams: GetTransactionsRequestType['queryParams'];
  },
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.transactions,
      {
        organisationId,
        transactionIdOrName,
        transactionStatus,
        transactionOwnerIds,
        page,
        perPage,
        sortDir,
      },
    ],
    queryFn: () =>
      getTransactions({
        pathParams: { organisationId },
        queryParams: {
          transactionIdOrName,
          transactionStatus,
          transactionOwnerIds,
          page,
          perPage,
          sortDir,
        },
      }),
    ...options,
  });
};
