export const readFile = <T extends 'arrayBuffer' | 'dataUrl'>(
  file: File,
  type: T,
): Promise<T extends 'arrayBuffer' ? ArrayBuffer : string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result !== null) {
        resolve(
          reader.result as T extends 'arrayBuffer' ? ArrayBuffer : string,
        );
      } else {
        reject();
      }
    };

    reader.onerror = reject;

    if (type === 'arrayBuffer') {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsDataURL(file);
    }
  });
