import axios from 'axios';

import { getBearerTokenStr } from 'helpers';
import { HTTP_HEADERS } from 'consts';
import { env } from 'env';

import { getRefreshToken } from './getRefreshToken';

type ResponseData = {
  access_token: string;
  access_type: 'Bearer';
  expire_at: string;
  ttl: number;
};

export const getRefreshedToken = async () => {
  const baseURL = env.REACT_APP_API_URL;
  const refreshToken = getRefreshToken();
  const { data } = await axios.post<{ data: ResponseData }>(
    `${baseURL}/api/v2/auth/refresh`,
    undefined,
    {
      headers: {
        [HTTP_HEADERS.authorization]: getBearerTokenStr(refreshToken),
      },
    },
  );

  const { access_token: accessToken } = data.data;

  return accessToken;
};
