import { compareAsc, compareDesc } from 'date-fns';

import { SortDirType } from 'types';

export const sortArrayOfObjByDate = <T extends Record<string, any>>(
  array: T[],
  sortBy: keyof T,
  sortDir: SortDirType | null,
) => {
  if (sortDir === null) {
    return array;
  }

  const compareFn = sortDir === 'asc' ? compareAsc : compareDesc;

  return array.sort((a, b) =>
    compareFn(new Date(a[sortBy]), new Date(b[sortBy])),
  );
};
