import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postTechnicalUserGenerateCustomCertificate } from '../requests';
import { PostTechnicalUserGenerateCustomCertificateRequestType } from '../types';

export const usePostTechnicalUserGenerateCustomCertificate = ({
  pathParams: { technicalUserId },
}: {
  pathParams: Omit<
    PostTechnicalUserGenerateCustomCertificateRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      formData,
    }: {
      formData: PostTechnicalUserGenerateCustomCertificateRequestType['formData'];
    }) =>
      postTechnicalUserGenerateCustomCertificate({
        pathParams: { organisationId, technicalUserId },
        formData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.technicalUsers],
      });
    },
  });
};
