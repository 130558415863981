import { useMutation } from '@tanstack/react-query';

import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { postDuplicateTemplate } from '../requests';
import { PostDuplicateTemplateRequestType } from '../types';

export const usePostDuplicateTemplate = () => {
  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: ({
      pathParams: { templateId },
      bodyParams,
    }: {
      pathParams: {
        templateId: PostDuplicateTemplateRequestType['pathParams']['templateId'];
      };
      bodyParams: PostDuplicateTemplateRequestType['bodyParams'];
    }) =>
      postDuplicateTemplate({
        pathParams: { organisationId, boardId, templateId },
        bodyParams,
      }),
  });
};
