import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const WebhookIcon = ({ size = 24, sx, ...props }: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={24} height={24} y={0.5} fill="#1E1C49" rx={5} />
    <path
      fill="#F7F8FF"
      d="M15.583 16.785h-4.774a3.427 3.427 0 0 1-4.733 2.579 3.43 3.43 0 0 1-1.39-5.209.572.572 0 0 1 .915.687 2.286 2.286 0 1 0 4.114 1.371.572.572 0 0 1 .571-.571h5.297a1.143 1.143 0 1 1 0 1.143Zm-8.154.571a1.144 1.144 0 0 0 1.006-1.686l2.555-4.153a.571.571 0 0 0-.188-.786 2.285 2.285 0 1 1 3.293-2.861.572.572 0 0 0 1.048-.457 3.43 3.43 0 1 0-5.401 3.952L7.46 15.07h-.03a1.143 1.143 0 0 0 0 2.286Zm9.143-4.571c-.441 0-.877.084-1.286.249l-2.28-3.706a1.143 1.143 0 1 0-1.006.6h.032l2.555 4.153a.571.571 0 0 0 .786.187 2.287 2.287 0 1 1 1.199 4.23.572.572 0 0 0 0 1.144 3.428 3.428 0 1 0 0-6.857Z"
    />
  </Box>
);
