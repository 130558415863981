import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { NotificationCountContext } from 'context';
import { getNotificationsLimitCount } from 'helpers';

type Props = {
  value?: string;
};

const APP_NAME = 'Pergamin';
const SEPARATOR = ' - ';

export const MetaTitle = ({ value }: Props) => {
  const [{ unreadCount }] = useContext(NotificationCountContext);

  const unreadNotificationsCount = unreadCount
    ? `(${getNotificationsLimitCount(unreadCount)}) `
    : '';

  return (
    <Helmet>
      <title>
        {unreadNotificationsCount}
        {value ? `${value}${SEPARATOR}` : ''}
        {APP_NAME}
      </title>
    </Helmet>
  );
};
