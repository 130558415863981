import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Text } from '@mantine/core';

import { URLS } from 'consts';
import {
  PercentageContainer,
  SectionHeader,
  StickyContent,
  TextInputReactRouterSearch,
} from 'components/shared';

type Props = {
  hasDataStreamCreateAccess: boolean;
};

export const Header = ({ hasDataStreamCreateAccess }: Props) => {
  const { t } = useTranslation('dataStreams');

  return (
    <StickyContent hasPageHeaderSpacings>
      <SectionHeader>{t('dataStreams:dataStreams')}</SectionHeader>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        size="md"
      >
        {t('dataStreams:pageDescription')}
      </Text>
      <PercentageContainer
        sx={{
          display: 'flex',
        }}
      >
        {hasDataStreamCreateAccess && (
          <Button
            variant="light"
            color="green"
            component={Link}
            to={URLS.dataStreamAdd}
          >
            {t('dataStreams:addNew')}
          </Button>
        )}
        <TextInputReactRouterSearch
          sx={{
            maxWidth: 328,
            width: '100%',
            marginLeft: 'auto',
          }}
          placeholder={t('dataStreams:dataStreamSearchPlaceholder')}
        />
      </PercentageContainer>
    </StickyContent>
  );
};
