import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionDataStreamResponseType,
  GetTransactionDataStreamRequestType,
} from '../types';

export const getTransactionDataStream = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
}: GetTransactionDataStreamRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}/data-stream`;

  const { data } =
    await apiAuthClient.get<GetTransactionDataStreamResponseType>(url);

  return data.data;
};
