import { Box, Text } from '@mantine/core';
import { WebhookType } from 'types';
import { PaperCard, PercentageContainer } from 'components/shared';
import { CodeIcon, EditIcon, LinkIcon, TrashIcon } from 'icons';

type Props = {
  webhook: WebhookType;
  handleDelete: () => void;
  handleShowLogs: () => void;
  handleEdit: () => void;
};
export const SingleWebhook = ({
  webhook,
  handleDelete,
  handleShowLogs,
  handleEdit,
}: Props) => (
  <PercentageContainer
    sx={theme => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.other.spacing(2),
      marginBottom: theme.other.spacing(1),
    })}
    component={PaperCard}
  >
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.other.spacing(2),
        width: '75%',
        [`@media (min-width: ${theme.breakpoints.xs}px)`]: {
          width: '75%',
        },
        [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
          width: '80%',
        },
        [`@media (min-width: ${theme.breakpoints.md}px)`]: {
          width: '75%',
        },
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
          width: '80%',
        },
      })}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'row',
          marginBottom: theme.other.spacing(2),
        })}
      >
        <LinkIcon
          sx={theme => ({
            marginRight: theme.other.spacing(1),
            flexShrink: 0,
          })}
        />
        <Text
          weight={400}
          size={13}
          sx={{
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            overflowWrap: 'break-word',
          }}
        >
          {webhook.url}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Text
          color="gray.7"
          size={13}
          weight={600}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {webhook.name}
        </Text>
      </Box>
    </Box>
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.other.spacing(1),
      })}
    >
      <TrashIcon
        color="#E63535"
        sx={{ cursor: 'pointer' }}
        onClick={handleDelete}
      />
      <CodeIcon sx={{ cursor: 'pointer' }} onClick={handleShowLogs} />
      <EditIcon sx={{ cursor: 'pointer' }} onClick={handleEdit} />
    </Box>
  </PercentageContainer>
);
