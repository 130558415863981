import { AxiosResponse } from 'axios';

import { camelizeKeys } from 'humps';

import { camelizeKey } from 'helpers';

export const getResponseCallback =
  ({ camelize = true } = {}) =>
  (response: AxiosResponse) => {
    if (
      camelize &&
      response.data &&
      response.headers['content-type']?.includes('application/json')
    ) {
      response.data = camelizeKeys(response.data, (key, convert) =>
        camelizeKey(key, convert),
      );
    }

    return response;
  };
