import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { patchTemplate } from '../requests';
import { PatchTemplateRequestType } from '../types';

export const usePatchTemplate = ({
  pathParams: { templateId },
}: {
  pathParams: {
    templateId: PatchTemplateRequestType['pathParams']['templateId'];
  };
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: (bodyParams: PatchTemplateRequestType['bodyParams']) =>
      patchTemplate({
        pathParams: { organisationId, boardId, templateId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template, { templateId: Number(templateId) }],
      });
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId, boardId }],
      });
    },
  });
};
