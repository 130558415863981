import { format, isValid } from 'date-fns';

import { useGetUser } from 'api/account';

import { UserType } from 'types';

export const useFormatUserDate = () => {
  const { data: userData } = useGetUser();
  const { dateFormat } = userData as UserType;

  return (date: string | undefined | null) => {
    const isValidDate =
      typeof date === 'string' ? isValid(new Date(date)) : false;

    return isValidDate ? format(new Date(date as string), dateFormat) : date;
  };
};
