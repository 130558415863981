import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Anchor, DefaultProps, packSx, Text } from '@mantine/core';

import { ChevronRightIcon } from 'icons';

type Props = {
  items: { name: string; url: string }[];
  sx?: DefaultProps['sx'];
};

export const Breadcrumbs = ({ items, sx }: Props) => (
  <Text
    sx={[
      { display: 'flex', flexWrap: 'wrap', alignItems: 'center' },
      ...packSx(sx),
    ]}
    size="md"
  >
    {items.map(({ name, url }, index) => {
      const isLastBreadcrumb = index === items.length - 1;
      const isLinkingToItself = url === '#';

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {isLinkingToItself ? (
            name
          ) : (
            <Anchor
              sx={theme => ({
                color: theme.black,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              })}
              component={Link}
              to={url}
            >
              {name}
            </Anchor>
          )}

          {!isLastBreadcrumb && (
            <ChevronRightIcon
              sx={theme => ({ margin: theme.other.spacing(0, 0.5) })}
              size={16}
            />
          )}
        </Fragment>
      );
    })}
  </Text>
);
