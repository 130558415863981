import AceEditor from 'react-ace';
import { useMantineTheme } from '@mantine/core';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

type Props = {
  value: any;
};

export const JsonViewer = ({ value }: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <AceEditor
      width="800px"
      height="800px"
      mode="json"
      theme="monokai"
      fontSize={mantineTheme.fontSizes.lg}
      showPrintMargin={false}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        tabSize: 2,
        useWorker: false,
      }}
      readOnly
      wrapEnabled
      value={JSON.stringify(value, null, 2)}
    />
  );
};
