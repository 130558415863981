import { useTranslation } from 'react-i18next';
import { UnstyledButton } from '@mantine/core';

import { QueueIcon } from 'icons';

type Props = {
  hasQueueErrors: boolean;
  onClick: () => void;
};

export const SigningQueueButton = ({ hasQueueErrors, onClick }: Props) => {
  const { t } = useTranslation('transactions');

  return (
    <UnstyledButton
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
      }}
      // @ts-ignore
      onClick={onClick}
    >
      <QueueIcon
        sx={theme => ({
          marginRight: theme.other.spacing(0.5),
        })}
        color={hasQueueErrors ? 'error' : undefined}
      />
      {t('transactions:signingQueue')}
    </UnstyledButton>
  );
};
