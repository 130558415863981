import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const InfoIcon = ({
  color,
  size = 24,
  sx,
  backgroundColor: backgroundColorProp,
  ...props
}: IconPropsType & { backgroundColor?: string }) => {
  const mantineTheme = useMantineTheme();

  const iconColor = useGetColor(color);
  const backgroundColor =
    backgroundColorProp || mantineTheme.fn.rgba(iconColor, 0.07);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={backgroundColor}
        stroke={iconColor}
        strokeWidth={1.5}
        d="M12,3.2c-4.8,0-8.8,3.9-8.8,8.8c0,4.8,3.9,8.8,8.8,8.8c4.8,0,8.8-3.9,8.8-8.8C20.8,7.2,16.8,3.2,12,3.2z"
      />
      <g>
        <path
          stroke={iconColor}
          strokeWidth={1.5}
          d="M11.7,13.7V12h0.6c0.9,0,1.7-0.8,1.7-1.7v-0.1c0-0.9-0.7-1.6-1.6-1.6h-0.7c-0.9,0-1.7,0.8-1.7,1.7"
        />
        <line
          stroke={iconColor}
          strokeWidth={1.5}
          x1="11.4"
          y1="15.4"
          x2="12.6"
          y2="15.4"
        />
      </g>
    </Box>
  );
};
