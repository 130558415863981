import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { getZodSchemaErrorMessages } from 'helpers';
import { OTP_DIGITS_NUMBER } from 'consts';

export const useGetOtpCodeValidationError = () => {
  const { t } = useTranslation('common');

  const schema = z.object({
    code: z
      .string()
      .min(1, { message: t('common:formErrors.required') })
      .length(OTP_DIGITS_NUMBER, {
        message: t('common:formErrors.exactNumberOfSigns', {
          number: OTP_DIGITS_NUMBER,
        }),
      }),
  });

  return (code: string) =>
    getZodSchemaErrorMessages({ schema, fields: { code } })?.code;
};
