export const V1_PATHS = {
  accountSettings: '/your-account',
  archivedContracts: '/contracts/archived',
  companies: '/companies',
  contracts: '/contracts',
  contract: '/contracts/:documentId',
  createDocument: '/create-contract',
  invitation: '/invitation/:invitationId',
  organisations: '/organisations',
  pendingContracts: '/contracts/pending',
  template: '/templates/:templateId',
  templatesAdd: '/templates/add',
  templatesNew: '/templates/new/:localTemplateId',
};
