import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, TextInput } from 'components/shared';
import { YamlEditor } from 'components/Transactions';

import { usePostDataType } from 'api/dataTypes';
import { PostDataTypeRequestType } from 'api/dataTypes/types';
import {
  encodeYamlDataType,
  formatDataTypeName,
  getNestedErrorMessage,
} from 'helpers';
import {
  useServerErrors,
  useShowNotification,
  useStateWithCursorPosition,
} from 'hooks';
import { ValidationErrors } from 'types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SAMPLE_YAML_VALUE = `data_type:
  header:
    type: object
    fields:
      TransactionId:
        type: string
      TransactionOwner:
        type: string`;

export const DataTypeAddModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'dataTypes']);

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const [nameField, setNameField, nameInputRef] = useStateWithCursorPosition();
  const [yamlValue, setYamlValue] = useState(SAMPLE_YAML_VALUE);
  const [errorStatus, setErrorStatus] = useState<ValidationErrors>({});

  const { mutateAsync: dataTypeAddMutateAsync, isLoading: isDataTypeAdding } =
    usePostDataType();

  const closeWithResetState = () => {
    onClose();
    setErrorStatus({});
    setNameField({
      value: '',
      cursorPosition: null,
    });
    setYamlValue(SAMPLE_YAML_VALUE);
  };

  const addDataType = async (
    yaml: PostDataTypeRequestType['bodyParams']['yaml'],
  ) => {
    if (!nameField.value || !yamlValue) {
      setErrorStatus({
        ...(!nameField.value && { name: t('common:formErrors.required') }),
        ...(!yamlValue && { yaml: t('common:formErrors.required') }),
      });

      return;
    }

    try {
      await dataTypeAddMutateAsync({
        name: nameField.value,
        yaml: encodeYamlDataType(yaml),
      });

      showNotification({
        message: t('dataTypes:dataTypeAdded'),
      });

      closeWithResetState();
    } catch (error) {
      setErrorStatus(getServerErrorStatus(error) || {});

      addServerErrorActions(error);
    }
  };

  const { name: nameError, ...errors } = errorStatus || {};

  /* clear errorStatus when request is done after closing modal and has validation errors */
  useEffect(() => {
    if (!isOpen && Object.keys(errorStatus).length > 0) {
      setErrorStatus({});
    }
  }, [errorStatus, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeWithResetState}
      title={t('dataTypes:addNew')}
      defaultButtonAction={closeWithResetState}
      defaultButtonDisabled={isDataTypeAdding}
      primaryButtonAction={async () => {
        await addDataType(yamlValue);
      }}
      primaryButtonDisabled={Object.keys(errorStatus).length > 0}
      primaryButtonIsLoading={isDataTypeAdding}
      primaryButtonColor="green"
    >
      <TextInput
        ref={nameInputRef}
        label={t('dataTypes:dataTypeName')}
        value={nameField.value}
        error={nameError}
        onChange={e => {
          setErrorStatus(({ name: _, ...status }) => status);
          setNameField({
            value: formatDataTypeName(e.target.value),
            cursorPosition: e.target.selectionStart,
          });
        }}
      />
      <YamlEditor
        sx={theme => ({
          marginBottom: theme.other.spacing(-3),
        })}
        value={yamlValue}
        onChange={value => {
          setErrorStatus(status => ({
            ...(status?.name && { name: status.name }),
          }));
          setYamlValue(value);
        }}
        error={getNestedErrorMessage(errors)}
      />
    </Modal>
  );
};
