import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { patchTechnicalUser } from '../requests';
import { PatchTechnicalUserRequestType } from '../types';

export const usePatchTechnicalUser = ({
  pathParams: { technicalUserId },
}: {
  pathParams: Omit<
    PatchTechnicalUserRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PatchTechnicalUserRequestType['bodyParams']) =>
      patchTechnicalUser({
        pathParams: { organisationId, technicalUserId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.technicalUsers],
      });
    },
  });
};
