import { apiAuthClient } from 'api/helpers';

import { PostOtpRequestType, PostOtpResponseType } from '../types';

export const postOtp = async ({ bodyParams }: PostOtpRequestType) => {
  const url = '/api/v2/auth/otp';

  const { data } = await apiAuthClient.post<PostOtpResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
