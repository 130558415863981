import { DefaultProps, packSx, Paper, PaperProps } from '@mantine/core';

type Props = {
  sx?: DefaultProps['sx'];
} & PaperProps;

export const PaperCard = ({ sx, ...paperProps }: Props) => (
  <Paper
    sx={[
      theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        boxShadow: 'none',
      }),
      ...packSx(sx),
    ]}
    {...paperProps}
  />
);
