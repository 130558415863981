import { Ref, RefObject, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetError } from 'react-hook-form';
import { Box, DefaultProps } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';

import { PatchUserRequestType } from 'api/account/types';
import { TextInputPasswordWithStrength } from 'components/shared';
import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

type Props = {
  sx: DefaultProps['sx'];
  formRef: RefObject<HTMLFormElement>;
  formInstanceRef: Ref<{
    setFormError: UseFormSetError<{}>;
  }>;
  onSubmit: (formValues: {
    password: PatchUserRequestType['bodyParams']['password'];
  }) => void;
};

export const SetNewPasswordForm = ({
  sx,
  formRef,
  formInstanceRef,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('user');

  const schema = z.object({
    password: z.string().refine(() => passwordInstanceRef.current.isValid),
  });

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    setError,
    watch,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      password: '',
    },
  });

  useImperativeHandle(formInstanceRef, () => ({
    setFormError: setError,
  }));

  const passwordInstanceRef = useRef<InstanceRefObjectType>({ isValid: false });

  return (
    <Box
      sx={sx}
      component="form"
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInputPasswordWithStrength
        {...register('password')}
        instanceRef={passwordInstanceRef}
        autoComplete="off"
        label={t('user:newPassword')}
        placeholder={t('user:insertNewPassword')}
        error={formErrors.password?.message}
        value={watch('password')}
      />
    </Box>
  );
};
