import { apiAuthClient } from 'api/helpers';

import { PatchUserRequestType, PatchUserResponseType } from '../types';

export const patchUser = async ({ bodyParams }: PatchUserRequestType) => {
  const url = '/api/v2/auth/user';

  const { data } = await apiAuthClient.patch<PatchUserResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
