import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mantine/core';

import { GetBoardTemplatesRequestType } from 'api/templates/types';
import { getHasHateoasAccess } from 'helpers';
import { useHandleCheckboxListStateChange } from 'hooks';
import {
  BoardTemplateListItemType,
  DateRangeValueType,
  MetaType,
  SortStateType,
} from 'types';
import {
  NotFound,
  PaginationReactRouter,
  Table,
  TableSortButton,
} from 'components/shared';

import { getIsLocalTemplate } from '../helpers';
import { BoardTemplateLocalListItemType, LocalTemplateType } from '../types';
import { TemplateFiltersRow } from './TemplateFiltersRow';
import { TemplateRow } from './TemplateRow';

type Props = {
  meta: MetaType;
  templates: BoardTemplateLocalListItemType[];
  isRefetching: boolean;
  hasActiveFilters: boolean;
  clearFilters: () => void;
  selectedTemplates: BoardTemplateLocalListItemType[];
  setSelectedTemplates: Dispatch<
    SetStateAction<BoardTemplateLocalListItemType[]>
  >;
  sort: SortStateType<
    Exclude<GetBoardTemplatesRequestType['queryParams']['sortBy'], undefined>
  >;
  setSort: Dispatch<
    SetStateAction<
      SortStateType<
        Exclude<
          GetBoardTemplatesRequestType['queryParams']['sortBy'],
          undefined
        >
      >
    >
  >;
  idFilter: number | null;
  setIdFilter: Dispatch<SetStateAction<number | null>>;
  updatedAtRange:
    | [DateRangeValueType]
    | [DateRangeValueType, DateRangeValueType];
  setUpdatedAtRange: Dispatch<
    SetStateAction<
      [DateRangeValueType] | [DateRangeValueType, DateRangeValueType]
    >
  >;
  refetchGetBoardTemplates: () => void;
};

export const TemplateList = ({
  templates,
  meta,
  isRefetching,
  hasActiveFilters,
  clearFilters,
  selectedTemplates,
  setSelectedTemplates,
  sort,
  setSort,
  idFilter,
  setIdFilter,
  updatedAtRange,
  setUpdatedAtRange,
  refetchGetBoardTemplates,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const { handleCheckboxAllChange, handleCheckboxChange } =
    useHandleCheckboxListStateChange({ resetTriggerValue: meta });

  const noDataProps = hasActiveFilters
    ? {
        buttonText: t('common:clearFilters'),
        onActionButtonClick: clearFilters,
      }
    : {
        header: t('templates:dontHaveTemplatesYet'),
      };

  const areAllTemplatesSelected = templates.every(template =>
    selectedTemplates.some(
      selectedTemplate => selectedTemplate.id === template.id,
    ),
  );

  const hasData = templates.length > 0;

  return (
    <div>
      <Table
        sx={theme => ({
          '&&': {
            '>table': {
              '>thead > tr > th, >tbody > tr > td': {
                '&:first-of-type': {
                  width: 48,
                  minWidth: 48,
                  paddingLeft: theme.other.spacing(1),
                },
              },
            },
          },
        })}
        isLoading={isRefetching}
      >
        <thead>
          <tr>
            <th>
              <Checkbox
                sx={{ marginBottom: 0 }}
                checked={areAllTemplatesSelected}
                onChange={e => {
                  handleCheckboxAllChange({
                    e,
                    setSelectedItems: setSelectedTemplates,
                    pageItems: templates,
                  });
                }}
              />
            </th>
            <th>{t('templates:name')}</th>
            <th>{t('templates:id')}</th>
            <th>
              <TableSortButton
                text={t('common:lastUpdate')}
                value="updated_at"
                sort={sort}
                setSort={setSort}
              />
            </th>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
          </tr>
        </thead>
        <tbody>
          <TemplateFiltersRow
            idFilter={idFilter}
            setIdFilter={setIdFilter}
            updatedAtRange={updatedAtRange}
            setUpdatedAtRange={setUpdatedAtRange}
          />
          {templates.map(({ id, updatedAt, ...template }, index) => {
            const isLocalTemplate = getIsLocalTemplate(id);
            const name = isLocalTemplate
              ? (template as LocalTemplateType).type
              : (template as BoardTemplateListItemType).name;
            const isChecked = selectedTemplates.some(
              selectedTemplate => selectedTemplate.id === id,
            );
            const isBase = 'base' in template && template.base;
            const isOneWay = 'oneWay' in template && template.oneWay;
            const isOffer = 'isOffer' in template && template.isOffer;
            const letterheadSource =
              'documentBrandingSource' in template
                ? template.documentBrandingSource
                : null;
            const hasDetailsAccess =
              isLocalTemplate ||
              getHasHateoasAccess(
                'self',
                (template as BoardTemplateListItemType).__links,
              );

            return (
              <TemplateRow
                key={id}
                isChecked={isChecked}
                isLocalTemplate={isLocalTemplate}
                isBase={isBase}
                isOneWay={isOneWay}
                isOffer={isOffer}
                hasDetailsAccess={hasDetailsAccess}
                letterheadSource={letterheadSource}
                id={id}
                name={name}
                updatedAt={updatedAt}
                onCheckboxChange={e => {
                  handleCheckboxChange({
                    e,
                    itemId: id,
                    itemPageIndex: index,
                    setSelectedItems: setSelectedTemplates,
                    pageItems: templates,
                  });
                }}
                refetchGetBoardTemplates={refetchGetBoardTemplates}
              />
            );
          })}
        </tbody>
      </Table>
      {!hasData && (
        <NotFound
          sx={theme => ({
            minHeight: 300,
            backgroundColor: theme.white,
            border: `1px solid ${theme.colors.gray[1]}`,
            marginTop: -1,
          })}
          {...noDataProps}
        />
      )}
      <PaginationReactRouter meta={meta} isStickyToBottom />
    </div>
  );
};
