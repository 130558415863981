import { MantineTheme } from '@mantine/core';

import { ThemeComponentType } from '../types';

export const getDescriptionStyles = (theme: MantineTheme) => ({
  marginBottom: theme.other.spacing(0.5),
  color: theme.black,
  fontSize: theme.fontSizes.md,
  fontWeight: 400,
});

export const getLabelStyles = (theme: MantineTheme) => ({
  marginBottom: theme.other.spacing(0.5),
  display: 'block',
  color: theme.black,
  width: '100%',
  fontSize: theme.fontSizes.lg,
  lineHeight: '24px',
  fontWeight: 500,
});

export const InputWrapper: ThemeComponentType = {
  styles: theme => ({
    error: {
      marginTop: theme.other.spacing(0.5),
      fontWeight: 500,
      fontSize: theme.fontSizes.md,
      lineHeight: '15px',
      minHeight: 24,
      whiteSpace: 'pre-wrap',
    },
    description: getDescriptionStyles(theme),
    label: getLabelStyles(theme),
  }),
};
