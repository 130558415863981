import { DefaultProps, packSx } from '@mantine/core';

import { Error, NoAccess, PageSkeleton } from 'components/shared';

const errorMargins = {
  md: 8,
  xl: 23,
} as const;

type Props<T = unknown | undefined> = {
  data?: T;
  children: (data: Exclude<T, undefined>) => JSX.Element;
  isError: boolean;
  isLoading: boolean;
  isNoAccess?: boolean;
  errorMargin?: keyof typeof errorMargins;
  sx?: DefaultProps['sx'];
};

export const DataWrapper = <T extends unknown>({
  data,
  children,
  isError,
  isLoading,
  isNoAccess,
  errorMargin = 'md',
  sx,
}: Props<T>) => {
  if (isError) {
    return (
      <Error
        sx={[
          theme => ({
            marginTop: theme.other.spacing(errorMargins[errorMargin]),
          }),
          ...packSx(sx),
        ]}
      />
    );
  }

  if (isLoading) {
    return <PageSkeleton sx={sx} />;
  }

  if (isNoAccess) {
    return (
      <NoAccess
        sx={[
          theme => ({
            marginTop: theme.other.spacing(errorMargins[errorMargin]),
          }),
          ...packSx(sx),
        ]}
      />
    );
  }

  return children(data as Exclude<T, undefined>);
};
