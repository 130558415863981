import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { getHotkeyHandler } from '@mantine/hooks';

import { usePostRegister } from 'api/accountRegister';
import { getEmailFromSearchParam, getContactAgreementsArray } from 'helpers';
import { useServerErrors, useShowNotification } from 'hooks';
import { URLS } from 'consts';
import { NonLoggedLayout } from 'components/NonLoggedLayout';
import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

import {
  RegisterWithCustomOnChangeType,
  RegistrationStepType,
  RegistrationValuesType,
  ValidStepsType,
} from './types';
import { Steps } from './Steps';
import { RegistrationForm } from './RegistrationForm';
import { FIELDS_PER_STEP } from './consts';
import {
  getFirstServerErrorStep,
  getStepsValidationFromServer,
} from './helpers';

export const Registration = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('user');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const showNotification = useShowNotification();

  const emailSearchParam = searchParams.get('email');
  const tokenSearchParam = searchParams.get('token');

  const passwordInstanceRef = useRef<InstanceRefObjectType>({ isValid: false });

  const schema = z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .email({ message: t('common:formErrors.incorrectEmail') }),
    password: z.string().refine(() => passwordInstanceRef.current.isValid),
    name: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    surname: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    phone: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    terms: z
      .boolean()
      .refine(Boolean, { message: t('common:formErrors.required') }),
    newsletter: z.boolean(),
    phoneCallMarketing: z.boolean(),
    phoneMessageMarketing: z.boolean(),
  });

  const { mutateAsync: createAccount, isLoading: isCreatingAccount } =
    usePostRegister();

  const { getGeneralServerError, getServerErrorStatus } = useServerErrors();

  const [currentStep, setCurrentStep] = useState<RegistrationStepType>(1);
  const [availableSteps, setAvailableSteps] = useState<RegistrationStepType>(1);
  const [validSteps, setValidSteps] = useState<ValidStepsType>({
    1: true,
    2: true,
    3: true,
  });
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [isSuccessViewAvailable, setIsSuccessViewAvailable] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm<RegistrationValuesType>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: emailSearchParam ? getEmailFromSearchParam(emailSearchParam) : '',
      password: '',
      name: '',
      surname: '',
      phone: '',
      terms: false,
      newsletter: false,
      phoneCallMarketing: false,
      phoneMessageMarketing: false,
    },
  });

  const updateStepValidation = (
    step: RegistrationStepType,
    isValid: boolean,
  ) => {
    setValidSteps(steps => ({ ...steps, [step]: isValid }));
  };

  const getIsCurrentStepValid = () => trigger(FIELDS_PER_STEP[currentStep]);

  const goToStep = async (step: RegistrationStepType) => {
    if (step !== currentStep) {
      if (step < currentStep) {
        setCurrentStep(step);
        setIsSuccessViewAvailable(false);
      } else {
        const isCurrentStepValid = await getIsCurrentStepValid();

        updateStepValidation(currentStep, isCurrentStepValid);

        if (isCurrentStepValid) {
          setCurrentStep(step);
        }
      }
    }
  };

  const goToPreviousStep = () => {
    setCurrentStep(step =>
      step > 1 ? ((step - 1) as RegistrationStepType) : step,
    );
    setIsSuccessViewAvailable(false);
  };

  const nextStepSubmit = async () => {
    const isCurrentStepValid = await getIsCurrentStepValid();

    updateStepValidation(currentStep, isCurrentStepValid);

    if (isCurrentStepValid) {
      const nextStep = (currentStep + 1) as RegistrationStepType;

      setCurrentStep(nextStep);

      if (nextStep > availableSteps) {
        setAvailableSteps(nextStep);
      }
    }
  };

  const getBackButtonLayoutProps = () =>
    currentStep > 1 && !isSuccessViewAvailable
      ? {
          backButtonAction: () => {
            goToPreviousStep();
          },
          backButtonActionText: t('user:backToStep', {
            step: currentStep - 1,
          }),
        }
      : {};

  const onFormValidationSuccess = async (values: RegistrationValuesType) => {
    const valuesToSend = {
      email: values.email.toLowerCase(),
      password: values.password,
      passwordConfirmation: values.password,
      name: values.name,
      surname: values.surname,
      phone: `+${values.phone}`,
      terms: values.terms,
      privacy: values.terms,
      contactAgreements: getContactAgreementsArray({
        phoneMessageMarketing: values.phoneMessageMarketing,
        phoneCallMarketing: values.phoneCallMarketing,
        newsletter: values.newsletter,
      }),
      locale: language as 'en' | 'pl',
      ...(tokenSearchParam && {
        organisationInvitationToken: tokenSearchParam,
      }),
    };

    try {
      await createAccount(valuesToSend);

      if (tokenSearchParam) {
        showNotification({
          message: t('user:yourAccountHasBeenActivated'),
        });
        navigate(URLS.home);
      } else {
        setIsSuccessViewAvailable(true);
      }
    } catch (error) {
      const validationServerErrors = getServerErrorStatus(error);

      if (validationServerErrors) {
        const stepsValidationFromServer = getStepsValidationFromServer(
          validationServerErrors,
        );
        const firstServerErrorStep = getFirstServerErrorStep(
          stepsValidationFromServer,
        );

        Object.entries(validationServerErrors).forEach(([field, message]) => {
          setError(field as keyof RegistrationValuesType, {
            message,
          });
        });

        const passwordError =
          validationServerErrors.password ||
          validationServerErrors.passwordConfirmation;

        if (passwordError) {
          setError('passwordConfirmation' as keyof RegistrationValuesType, {
            message: passwordError,
          });
        }

        setValidSteps(stepsValidationFromServer);
        setCurrentStep(firstServerErrorStep);
      } else {
        setServerErrorMessage(getGeneralServerError(error));
      }
    }
  };

  const onFormValidationError = () => {
    updateStepValidation(3, false);
  };

  const getFormSubmit = () =>
    handleSubmit(onFormValidationSuccess, onFormValidationError);

  useEffect(() => {
    const enterHotkeyHandler = getHotkeyHandler([
      [
        'Enter',
        e => {
          if (e.target) {
            const targetClassList = (e.target as HTMLElement).classList;
            const isPhoneInputDropdownFocused =
              targetClassList.contains('selected-flag') &&
              targetClassList.contains('open');
            const isPhoneInputCountryListFocused =
              targetClassList.contains('country-list');

            if (isPhoneInputDropdownFocused || isPhoneInputCountryListFocused) {
              return;
            }
          }
          if (currentStep === 3) {
            getFormSubmit()();
          } else {
            nextStepSubmit();
          }
        },
      ],
    ]);

    document.body.addEventListener('keydown', enterHotkeyHandler);

    return () => {
      document.body.removeEventListener('keydown', enterHotkeyHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const setValueWitErrorClear = (
    ...setValueParams: Parameters<UseFormSetValue<RegistrationValuesType>>
  ) => {
    const [fieldName] = setValueParams;

    setValue(...setValueParams);
    clearErrors(fieldName);
  };

  const customRegister: RegisterWithCustomOnChangeType = (
    ...registerParams
  ) => ({
    ...register(...registerParams),
    onChange: async e => {
      setValueWitErrorClear(
        e.target.name,
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      );
    },
  });

  return (
    <NonLoggedLayout
      {...getBackButtonLayoutProps()}
      primaryContent={
        <Steps
          availableSteps={availableSteps}
          validSteps={validSteps}
          onChangeStep={goToStep}
          isFinished={isSuccessViewAvailable}
        />
      }
      secondaryContent={
        <RegistrationForm
          availableSteps={availableSteps}
          control={control}
          currentStep={currentStep}
          errors={errors}
          clearErrors={clearErrors}
          getFormSubmit={getFormSubmit}
          goToPreviousStep={goToPreviousStep}
          isCreatingAccount={isCreatingAccount}
          isSuccessViewAvailable={isSuccessViewAvailable}
          nextStepSubmit={nextStepSubmit}
          passwordInstanceRef={passwordInstanceRef}
          customRegister={customRegister}
          serverErrorMessage={serverErrorMessage}
          setValue={setValue}
          watch={watch}
        />
      }
    />
  );
};
