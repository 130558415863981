import { useTranslation } from 'react-i18next';
import { camelize, decamelize } from 'humps';

import { useShowNotification, useShowGeneralErrorNotification } from 'hooks';

type ErrorActionsObject = {
  [key: number]: { message?: string; callback?: () => void } | null;
};

const replaceIndexSurroundingDotsWithBraces = (field: string) =>
  field.replace(/\.(\d+)\./, '[$1].');

const transformFieldErrorsArrayToString = (fieldErrors: string[]) =>
  fieldErrors.reduce(
    (accumulator, errorMessage) => `${accumulator}\n${errorMessage}`,
  );

export const getServerErrorStatus = (
  error: any,
  options?: { camelizeKeys: boolean },
) => {
  const { camelizeKeys } = options || { camelizeKeys: true };
  const status: { [key: string]: string } = {};
  const serverErrors = error?.response?.data?.errors;

  if (serverErrors) {
    Object.entries(serverErrors).forEach(([field, errors]) => {
      const fieldName = replaceIndexSurroundingDotsWithBraces(field);
      status[camelizeKeys ? camelize(fieldName) : fieldName] =
        transformFieldErrorsArrayToString(errors as string[]);
    });
  }

  return Object.keys(status).length > 0 ? status : {};
};

const getServerFieldError = (error: any, field: string) => {
  const errors = error.response?.data?.errors;
  const fieldErrors = errors && errors[decamelize(field)];

  return fieldErrors ? transformFieldErrorsArrayToString(fieldErrors) : '';
};

export const useServerErrors = () => {
  const { t } = useTranslation('common');

  const showNotification = useShowNotification();
  const showGeneralErrorNotification = useShowGeneralErrorNotification();

  const defaultErrorMessages: ErrorActionsObject = {
    401: null,
    422: null,
    429: {
      message: t('common:formErrors.requestLimitExceeded'),
    },
  };

  const addServerErrorActions = (
    error: any,
    errorsData?: ErrorActionsObject,
  ) => {
    const status = error.response?.status;
    const errorData = { ...defaultErrorMessages, ...(errorsData || {}) }[
      status
    ];

    if (errorData) {
      if (errorData.message) {
        showNotification({
          variant: 'error',
          message: errorData.message,
        });
      }

      if (errorData.callback) {
        errorData.callback();
      }
    } else if (errorData !== null) {
      showGeneralErrorNotification();
    }
  };

  const getGeneralServerError = (error: any) => {
    const status = error?.response?.status;

    if (!status) {
      return '';
    }

    return status in defaultErrorMessages
      ? defaultErrorMessages[status]?.message || ''
      : t('common:errorTryAgain');
  };

  return {
    addServerErrorActions,
    getServerFieldError,
    getServerErrorStatus,
    getGeneralServerError,
  };
};
