import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Box } from '@mantine/core';

import { LanguagesDataKeyType } from 'types';

type Props = { label: string } & Omit<
  LanguagesDataKeyType,
  'labelTranslation'
> &
  ComponentPropsWithoutRef<'div'>;

export const SelectItem = forwardRef<HTMLDivElement, Props>(
  ({ label, Icon, ...props }: Props, ref) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }} ref={ref} {...props}>
      <Box
        sx={theme => ({
          marginRight: theme.other.spacing(0.5),
        })}
        component={Icon}
      />
      {label}
    </Box>
  ),
);
