import { apiAuthClient } from 'api/helpers';

import {
  GetTransactionDataTypeResponseType,
  GetTransactionDataTypeRequestType,
} from '../types';

export const getTransactionDataType = async ({
  pathParams: { organisationId, dataStreamId, transactionId },
}: GetTransactionDataTypeRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/data-streams/${dataStreamId}/transactions/${transactionId}/data-type`;

  const { data } = await apiAuthClient.get<GetTransactionDataTypeResponseType>(
    url,
  );

  return data.data;
};
