import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';

import { useGetV1Url } from 'hooks';

export const Invitation = () => {
  const getV1Url = useGetV1Url();
  const { invitationId } = useParams() as {
    invitationId: string;
  };

  useEffect(() => {
    window.location.assign(
      getV1Url('invitation', { invitationId }, window.location.search),
    );
  }, [getV1Url, invitationId]);

  return <LoadingOverlay visible />;
};
