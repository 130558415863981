import { MantineThemeOverride } from '@mantine/core';

export const colors: MantineThemeOverride['colors'] = {
  blue: [
    '#004FC4', // 0
    '#E6EDF9', // 1
    '#B3CAED', // 2
    '#80A7E2', // 3
    '#4D84D6', // 4
    '#1961CA', // 5
    '#0047B0', // 6
    '#023789', // 7
    '#002862', // 8
    '#00173B', // 9
  ],
  gray: [
    '#191919', // 0
    '#E6E6E6', // 1
    '#CCCCCC', // 2
    '#B3B3B3', // 3
    '#999999', // 4
    '#808080', // 5
    '#666666', // 6
    '#4D4D4D', // 7
    '#333333', // 8
    '#000000', // 9
  ],
  green: [
    '#05A660', // 0
    '#E6F7EF', // 1
    '#B4E4CF', // 2
    '#82D3B0', // 3
    '#50C190', // 4
    '#1EAF70', // 5
    '#039556', // 6
    '#047443', // 7
    '#025330', // 8
    '#02321D', // 9
  ],
  orange: [
    '#E67A00', // 0
    '#FDF2E6', // 1
    '#F9D7B3', // 2
    '#F3BD80', // 3
    '#EEA24E', // 4
    '#E9871A', // 5
    '#CF6E01', // 6
    '#A15501', // 7
    '#733D01', // 8
    '#452500', // 9
  ],
  red: [
    '#E63535', // 0
    '#FDEBEB', // 1
    '#F9C2C2', // 2
    '#F39A9A', // 3
    '#EE7272', // 4
    '#E9494A', // 5
    '#CF3030', // 6
    '#A12525', // 7
    '#731B1B', // 8
    '#451010', // 9
  ],
  teal: [
    '#00B7C4', // 0
    '#E6F8F9', // 1
    '#B3E9ED', // 2
    '#80DBE2', // 3
    '#4DCDD6', // 4
    '#1ABECA', // 5
    '#01A4B0', // 6
    '#008089', // 7
    '#005D62', // 8
    '#02373B', // 9
  ],
  violet: [
    '#685EF4', // 0
    '#F7F8FF', // 1
    '#D1CFFC', // 2
    '#B4AFFA', // 3
    '#958EF7', // 4
    '#776EF5', // 5
    '#5F55DC', // 6
    '#4942AB', // 7
    '#342F7A', // 8
    '#1E1C49', // 9
  ],
  yellow: [
    '#E6B800', // 0
    '#FDF8E6', // 1
    '#F9E9B3', // 2
    '#F3DC80', // 3
    '#EFCD4E', // 4
    '#E9BF1A', // 5
    '#CEA600', // 6
    '#A18101', // 7
    '#735C01', // 8
    '#453700', // 9
  ],
};
