import { ThemeComponentType } from '../types';

export const Tabs: ThemeComponentType = {
  defaultProps: {
    variant: 'pills',
  },
  styles: theme => ({
    tab: {
      padding: theme.other.spacing(0.5, 1.5),
      borderWidth: 0,
      fontWeight: 500,
      '&[data-active]': {
        color: theme.colors[theme.primaryColor][1],
        backgroundColor: theme.colors[theme.primaryColor][9],
        '&:hover': {
          backgroundColor: theme.colors[theme.primaryColor][9],
        },
      },
      '&:hover': {
        color: theme.colors[theme.primaryColor][1],
        backgroundColor: theme.colors[theme.primaryColor][9],
      },
      '&:disabled': {
        '&:hover': {
          color: theme.colors.gray[3],
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
    tabLabel: {
      fontSize: theme.fontSizes.md,
      lineHeight: '24px',
    },
  }),
};
