import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DefaultProps,
  Loader,
  MantineTheme,
  Text,
} from '@mantine/core';

import { useGetLetterheadTranslation, useGetSignTypeTranslation } from 'hooks';
import { TemplateType } from 'types';
import { PaperCard } from 'components/shared';

import { LanguageCertificates, TemplateStatuses } from 'components/Templates';
import { InfoItem } from './InfoItem';

type Props = {
  template: TemplateType;
  isLoading: boolean;
  onEdit: (() => void) | undefined;
};

export const Summary = ({
  template: {
    base,
    documentBranding,
    canEditByNegotiator,
    certificatesLanguages,
    enableNumeric,
    isOffer,
    name,
    oneWay,
    signType,
    templateNumeric,
  },
  isLoading,
  onEdit,
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const getSignTypeTranslation = useGetSignTypeTranslation();
  const getLetterheadTranslation = useGetLetterheadTranslation();

  const firstColumnLabelSx: DefaultProps['sx'] = (theme: MantineTheme) => ({
    width: 200,
    marginRight: theme.other.spacing(2),
    textAlign: 'right',
  });

  return (
    <PaperCard
      sx={theme => ({
        maxWidth: 836,
        position: 'relative',
        padding: theme.other.spacing(3, 2, 3, 4),
        overflow: 'auto',
      })}
    >
      {isLoading && (
        <Loader sx={{ position: 'absolute', inset: 0, margin: 'auto' }} />
      )}
      <Box sx={{ visibility: isLoading ? 'hidden' : undefined }}>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.other.spacing(4),
          })}
        >
          <Text size="xl" weight={700}>
            {name}
          </Text>
          {onEdit && (
            <Button
              sx={theme => ({
                fontSize: theme.fontSizes.md,
                fontWeight: 600,
              })}
              variant="subtle"
              onClick={onEdit}
            >
              {t('common:edit')}
            </Button>
          )}
        </Box>
        <Box
          sx={theme => ({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.other.spacing(2),
            overflow: 'auto',
          })}
        >
          <Box
            sx={theme => ({
              flexShrink: 0,
              display: 'grid',
              rowGap: theme.other.spacing(1.5),
            })}
          >
            <InfoItem
              label={t('templates:signType')}
              value={getSignTypeTranslation(signType)}
            />
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Text sx={firstColumnLabelSx} size="md">
                {t('templates:certificateLangs')}:
              </Text>
              <LanguageCertificates
                certificatesLanguages={certificatesLanguages}
              />
            </Box>
            <InfoItem
              label={t('templates:autoNumeration')}
              value={
                enableNumeric
                  ? templateNumeric
                  : t('templates:autoNumerationDisabled2')
              }
            />
            <InfoItem
              label={t('templates:letterhead')}
              value={getLetterheadTranslation(documentBranding.source)}
            />
          </Box>
          <TemplateStatuses
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
            canEditByNegotiator={canEditByNegotiator}
            isBase={base}
            isOffer={isOffer}
            isOneWay={oneWay}
          />
        </Box>
      </Box>
    </PaperCard>
  );
};
