import { useId } from 'react';
import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const FlagPlIcon = ({ size = 24, sx, ...props }: IconPropsType) => {
  const id = useId();

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipPath={`url(#${id})`} clipRule="evenodd">
        <path fill="#fff" d="M24 24H0V0h24v24Z" />
        <path fill="#DC143C" d="M24 24H0V12h24v12Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={24} height={24} fill="#fff" rx={12} />
        </clipPath>
      </defs>
    </Box>
  );
};
