import { ChevronDownIcon } from 'icons';

import { ThemeComponentType } from '../types';

export const Select: ThemeComponentType = {
  defaultProps: {
    rightSection: <ChevronDownIcon className="rotate" />,
    error: ' ',
  },
  styles: (theme, { variant }) => ({
    root: {
      '>[role="combobox"]': {
        '&[aria-expanded="false"]': {
          '.rotate': {
            transition: `transform ${theme.other.animationTime} ease`,
            transform: 'rotate(0)',
          },
        },
        '&[aria-expanded="true"]': {
          '.rotate': {
            transition: `transform ${theme.other.animationsTime} ease 0s`,
            transform: 'rotate(180deg)',
          },
        },
      },
    },
    input: {
      paddingRight: theme.other.spacing(4),
      backgroundColor: variant === 'unstyled' ? undefined : theme.white,
      borderColor: theme.colors.gray[3],
      fontSize: theme.fontSizes.md,
      fontWeight: 600,
      '&::placeholder': {
        fontWeight: 500,
      },
      '&:disabled': {
        backgroundColor:
          variant === 'unstyled' ? theme.colors.gray[1] : undefined,
        '&+.mantine-Select-rightSection': {
          color: theme.colors.gray[4],
        },
      },
      '&&:read-only': {
        cursor: 'default',
      },
    },
    label: {
      ...((theme.components.InputWrapper as any).styles(theme).label || {}),
    },
    error: {
      ...((theme.components.InputWrapper as any).styles(theme).error || {}),
    },
    dropdown: {
      borderColor: theme.colors.gray[1],
      boxShadow: theme.shadows.xs,
      transform: `translateY(${theme.other.spacing(-0.5)}px)`,
      '.mantine-ScrollArea-viewport > div > div': {
        padding: '0 !important',
      },
    },
    item: {
      padding: theme.other.spacing(1, 2),
      fontSize: theme.fontSizes.lg,
      '&[data-selected]': {
        fontWeight: 600,
        backgroundColor: theme.colors[theme.primaryColor][1],
        color: 'inherit',
      },
      '&[data-hovered]': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
    },
    rightSection: {
      pointerEvents: 'none',
      touchAction: 'none',
    },
  }),
};
