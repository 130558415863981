import { useTranslation } from 'react-i18next';
import { Center, Group } from '@mantine/core';

type Props = {
  translateKey: string;
  showLocalContractsCount?: boolean;
  localContractsCount?: number;
};

export const LinkLabel = ({
  translateKey,
  showLocalContractsCount = false,
  localContractsCount = 0,
}: Props) => {
  const { t } = useTranslation(['navigation']);

  return (
    <Group spacing="xs">
      <span>{t(translateKey)}</span>
      {showLocalContractsCount && localContractsCount > 0 && (
        <Center
          component="span"
          sx={theme => ({
            height: 15,
            minWidth: 15,
            padding: theme.other.spacing(0.5),
            borderRadius: 7.5,
            fontSize: 8,
            fontWeight: 500,
            color: theme.white,
            backgroundColor: theme.colors.orange[0],
          })}
        >
          {' '}
          {localContractsCount}
        </Center>
      )}
    </Group>
  );
};
