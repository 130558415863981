import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

type Props = {
  hasExpired: boolean;
  formattedExpirationDate: string;
};

export const CertificateExpiration = ({
  hasExpired,
  formattedExpirationDate,
}: Props) => {
  const { t } = useTranslation('api');

  return (
    <Text size="sm">
      {hasExpired
        ? t('api:certificateModal.expiredAt')
        : t('api:certificateModal.expiresAt')}{' '}
      <b>{formattedExpirationDate}</b>
    </Text>
  );
};
