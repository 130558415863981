import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import { UnstyledButton } from '@mantine/core';

const DELAY_IN_SECONDS = 30;

type Props = {
  isGettingOtpData: boolean;
  onClick: () => void;
};

const getTimestamp = () => Date.now() + DELAY_IN_SECONDS * 1000;

export const ResendOtpDataButton = ({ isGettingOtpData, onClick }: Props) => {
  const { t } = useTranslation('common');

  const [timestamp, setTimestamp] = useState(getTimestamp());

  return (
    <Countdown
      key={timestamp}
      date={timestamp}
      renderer={({ completed, seconds }) => (
        <UnstyledButton
          sx={theme => ({
            fontSize: theme.fontSizes.lg,
            color: completed ? theme.fn.primaryColor() : theme.colors.gray[4],
            cursor: completed ? undefined : 'default',
            ':disabled': {
              backgroundColor: 'initial',
            },
          })}
          disabled={!completed}
          onClick={() => {
            setTimestamp(getTimestamp());
            onClick();
          }}
        >
          {t('common:sendNext')}
          {!completed && !isGettingOtpData ? ` (${seconds})` : ''}
        </UnstyledButton>
      )}
    />
  );
};
