import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

type GuideTourContextType = {
  isUserMenuOpenForGuideTour: boolean;
};

export const GuideTourContext = createContext(
  [] as unknown as [
    GuideTourContextType,
    Dispatch<SetStateAction<GuideTourContextType>>,
  ],
);

type Props = {
  children: ReactNode;
};

export const GuideTourContextProvider = ({ children }: Props) => {
  const guideTourState = useState<GuideTourContextType>({
    isUserMenuOpenForGuideTour: false,
  });

  return (
    <GuideTourContext.Provider value={guideTourState}>
      {children}
    </GuideTourContext.Provider>
  );
};
