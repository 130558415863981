import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { deleteTechnicalUser } from '../requests';
import { DeleteDataStreamRequestType } from '../types';

export const useDeleteTechnicalUser = ({
  pathParams: { technicalUserId },
}: {
  pathParams: Omit<DeleteDataStreamRequestType['pathParams'], 'organisationId'>;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: () =>
      deleteTechnicalUser({
        pathParams: { organisationId, technicalUserId },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.technicalUsers],
      });
    },
  });
};
