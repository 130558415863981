import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';

import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

import { getVariableMappingInfoObject } from './helpers';

import { ErrorsAndWarnings } from './ErrorsAndWarnings';
import { VariableMapping } from './VariableMapping';

const ERRORS_AND_WARNINGS_TAB = 'errorsAndWarnings';
const VARIABLE_MAPPING_TAB = 'variableMapping';

type Props = {
  transactionId: string;
  errors: string[];
  warnings: string[];
  variables: { [key: string]: unknown };
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const DocumentInfoModal = ({
  isOpen,
  onClose,
  transactionId,
  errors,
  warnings,
  variables,
}: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const hasErrorsOrWarnings = errors.length > 0 || warnings.length > 0;
  const hasVariables = Object.keys(variables).length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('transactions:transactionWithId', {
        id: transactionId,
      })}
      defaultButtonAction={onClose}
      defaultButtonText={t('common:back')}
    >
      <Tabs
        defaultValue={
          hasErrorsOrWarnings ? ERRORS_AND_WARNINGS_TAB : VARIABLE_MAPPING_TAB
        }
      >
        <Tabs.List sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
          {hasErrorsOrWarnings && (
            <Tabs.Tab value={ERRORS_AND_WARNINGS_TAB}>
              {t('transactions:errorsAndWarnings')}
            </Tabs.Tab>
          )}
          {hasVariables && (
            <Tabs.Tab value={VARIABLE_MAPPING_TAB}>
              {t('transactions:variableMapping')}
            </Tabs.Tab>
          )}
        </Tabs.List>
        {hasErrorsOrWarnings && (
          <Tabs.Panel value={ERRORS_AND_WARNINGS_TAB}>
            <ErrorsAndWarnings errors={errors} warnings={warnings} />
          </Tabs.Panel>
        )}
        {hasVariables && (
          <Tabs.Panel value={VARIABLE_MAPPING_TAB}>
            <VariableMapping
              errorObj={getVariableMappingInfoObject(errors)}
              warningObj={getVariableMappingInfoObject(warnings)}
              variables={variables}
            />
          </Tabs.Panel>
        )}
      </Tabs>
    </Modal>
  );
};
