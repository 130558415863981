import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { usePatchTechnicalUser } from 'api/technicalUsers';
import { useServerErrors } from 'hooks';
import { TechnicalUserType } from 'types';
import { PaperCard } from 'components/shared';

import { ConfirmationInput } from '../ConfirmationInput';
import { TechnicalUserActions } from '../TechnicalUserActions';
import { AccessActions } from '../AccessActions';
import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../../types';

type Props = {
  hasCreateCertificateAccess: boolean;
  hasCreateOwnCertificateAccess: boolean;
  hasDeleteTechnicalUserAccess: boolean;
  hasUpdateTechnicalUserAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  technicalUserName: TechnicalUserType['name'];
  visibleBearerToken: TechnicalUserType['bearerToken'];
  setBearerTokensForNewlyCreatedTechnicalUsers: Dispatch<
    SetStateAction<BearerTokensForNewlyCreatedTechnicalUsersType>
  >;
} & Pick<TechnicalUserType, 'certificate' | 'bearerToken'>;

export const TechnicalUserPanel = ({
  hasCreateCertificateAccess,
  hasCreateOwnCertificateAccess,
  hasDeleteTechnicalUserAccess,
  hasUpdateTechnicalUserAccess,
  technicalUserId,
  technicalUserName,
  certificate,
  bearerToken,
  visibleBearerToken,
  setBearerTokensForNewlyCreatedTechnicalUsers,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const { addServerErrorActions, getServerFieldError } = useServerErrors();

  const [isNameInputFocused, setIsNameInputFocused] = useState(false);
  const [technicalUserNameError, setTechnicalUserNameError] = useState('');

  const {
    mutateAsync: updateTechnicalUser,
    isLoading: isUpdatingTechnicalUser,
  } = usePatchTechnicalUser({
    pathParams: {
      technicalUserId: String(technicalUserId),
    },
  });

  const updateTechnicalUserName = async (name: string) => {
    try {
      await updateTechnicalUser({
        name,
      });

      setIsNameInputFocused(false);
    } catch (error) {
      setTechnicalUserNameError(getServerFieldError(error, 'name'));
      addServerErrorActions(error);
    }
  };

  return (
    <PaperCard>
      <Text color="gray.7" mb="xs" pl="xs">
        {t('common:id')}: {technicalUserId}
      </Text>
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.other.spacing(1.5),
        })}
      >
        <ConfirmationInput
          value={technicalUserName}
          onConfirm={async value => {
            if (value) {
              await updateTechnicalUserName(value);
            } else {
              setTechnicalUserNameError(t('common:formErrors.required'));
            }
          }}
          onBlur={() => {
            setTechnicalUserNameError('');
          }}
          isFocused={isNameInputFocused}
          isLoading={isUpdatingTechnicalUser}
          setIsFocused={setIsNameInputFocused}
          isEditMode
          error={technicalUserNameError}
          setError={setTechnicalUserNameError}
        />
        {!isNameInputFocused && (
          <TechnicalUserActions
            hasDeleteTechnicalUserAccess={hasDeleteTechnicalUserAccess}
            technicalUserId={technicalUserId}
            technicalUserName={technicalUserName}
          />
        )}
      </Box>
      <AccessActions
        hasCreateCertificateAccess={hasCreateCertificateAccess}
        hasCreateOwnCertificateAccess={hasCreateOwnCertificateAccess}
        hasUpdateTechnicalUserAccess={hasUpdateTechnicalUserAccess}
        technicalUserId={technicalUserId}
        bearerToken={bearerToken}
        certificate={certificate}
        visibleBearerToken={visibleBearerToken}
        setBearerTokensForNewlyCreatedTechnicalUsers={
          setBearerTokensForNewlyCreatedTechnicalUsers
        }
      />
    </PaperCard>
  );
};
