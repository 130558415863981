import { Trans, useTranslation } from 'react-i18next';
import { Box, Anchor, Text } from '@mantine/core';

import { CheckIcon, CustomizationIcon } from 'icons';
import { EMAILS } from 'consts';
import { Modal } from 'components/shared';

const CUSTOMIZATION_BENEFITS_TRANSLATIONS = [
  'organisations:mailCustomization.theme',
  'organisations:mailCustomization.footer',
  'organisations:mailCustomization.branding',
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const MailCustomizationNoAccessModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'noAccess', 'organisations']);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      primaryButtonText={t('common:understand')}
      primaryButtonAction={onClose}
    >
      <CustomizationIcon
        sx={theme => ({
          display: 'block',
          margin: theme.other.spacing(0, 'auto', 2),
        })}
        color="primary"
      />
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(4) })}
        align="center"
        weight={600}
      >
        {t('noAccess:dontHaveAccessToThisFeature')}
      </Text>
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(2) })}
        weight={600}
      >
        {t('organisations:mailCustomization.whatYouGain')}
      </Text>
      <Box
        sx={theme => ({
          marginBottom: theme.other.spacing(2.5),
        })}
        component="ul"
      >
        {CUSTOMIZATION_BENEFITS_TRANSLATIONS.map(translation => (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              marginBottom: theme.other.spacing(2),
              fontSize: theme.fontSizes.lg,
            })}
            component="li"
            key={translation}
          >
            <CheckIcon
              sx={theme => ({
                flexShrink: 0,
                marginRight: theme.other.spacing(1),
              })}
              color="green"
            />
            {t(translation)}
          </Box>
        ))}
      </Box>
      <Text>
        <Trans
          i18nKey="organisations:mailCustomization.contact"
          components={{
            a: (
              <Anchor
                sx={{
                  fontSize: 'inherit',
                }}
                href={`mailto:${EMAILS.pergaminHelp}`}
                target="_blank"
              />
            ),
          }}
        />
      </Text>
    </Modal>
  );
};
