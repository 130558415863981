import axios from 'axios';

import {
  GetExternalTransactionEntryDebugRequestType,
  GetExternalTransactionEntryDebugResponseType,
} from '../types';

export const getExternalTransactionEntryDebug = async ({
  url,
}: GetExternalTransactionEntryDebugRequestType) => {
  const { data } =
    await axios.get<GetExternalTransactionEntryDebugResponseType>(url);

  return data;
};
