import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Paper, Text } from '@mantine/core';

import { DYNAMIC_URLS } from 'consts';
import { EyeIcon, SaveIcon } from 'icons';
import { ActionsBar, DescriptionField, TextInput } from 'components/shared';
import { TemplateCreatorEditor } from 'legacy/TemplateCreatorEditor/TemplateCreatorEditor';

const NAME_LIMIT = 200;

export const TemplateAttachmentForm = ({
  attachmentDefaultName,
  attachmentDefaultValue,
  onSubmit,
  isSaving,
  nameError,
  setNameError,
  editorRef: editorRefProp,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const { templateId } = useParams();

  const [name, setName] = useState(attachmentDefaultName || '');

  const formRef = useRef(null);
  const editorRef = useRef(null);

  useImperativeHandle(editorRefProp, () => ({
    ...editorRef.current,
  }));

  useEffect(() => {
    if (attachmentDefaultName) {
      setName(attachmentDefaultName);
    }
  }, [attachmentDefaultName]);

  return (
    <>
      <form
        ref={formRef}
        onSubmit={e => {
          e.preventDefault();

          const getNameError = () => {
            if (!name) {
              return t('common:formErrors.required');
            }

            if (name.length > NAME_LIMIT) {
              return t('common:formErrors.maxNumberOfSigns', {
                max: NAME_LIMIT,
              });
            }

            return nameError;
          };

          const hasContractTemplateErrors =
            (editorRef.current?.triggerValidationAndGetErrors() || []).length >
            0;
          const currentNameError = getNameError();

          if (currentNameError) {
            setNameError(currentNameError);
          }

          if (!hasContractTemplateErrors && !currentNameError) {
            onSubmit({
              name,
              attachmentData: editorRef.current?.editorValueJson,
            });
          }
        }}
      >
        <Paper
          sx={theme => ({
            maxWidth: 836,
            marginBottom: theme.other.spacing(3),
            '&': {
              padding: theme.other.spacing(3, 4),
            },
          })}
        >
          <Text size="xl" weight={700}>
            {t('templates:attachmentDetails')}
          </Text>
          <DescriptionField
            label={t('templates:attachmentName')}
            description={t('templates:attachmentNameDescription')}
            error={nameError}
            withoutBorder
          >
            <TextInput
              withErrorWrapperAlwaysEnabled={false}
              disabled={isSaving}
              value={name}
              onChange={e => {
                setNameError('');
                setName(e.target.value);
              }}
            />
          </DescriptionField>
        </Paper>
        <TemplateCreatorEditor
          editorRef={editorRef}
          defaultValue={attachmentDefaultValue}
        />
      </form>
      <ActionsBar
        items={[
          {
            name: t('common:cancel'),
            to: DYNAMIC_URLS.templateEdit({ templateId }),
            disabled: isSaving,
          },
          {
            name: t('templates:documentPreview'),
            action: () => {
              // eslint-disable-next-line no-alert
              alert('COMING SOON');
            },
            icon: <EyeIcon />,
          },
          {
            name: t('common:save'),
            action: () => {
              formRef.current?.requestSubmit();
            },
            icon: <SaveIcon />,
            color: 'blue',
            isLoading: isSaving,
          },
        ]}
      />
    </>
  );
};
