import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { patchUserNotifications } from '../requests';
import { PatchUserNotificationsRequestType } from '../types';

export const usePatchUserNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (bodyParams: PatchUserNotificationsRequestType['bodyParams']) =>
      patchUserNotifications({
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.userNotifications],
      });
    },
  });
};
