import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getCustomizationsDocument } from '../requests';

export const useGetCustomizationsDocument = (options?: {
  enabled?: boolean;
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.organisationCustomizationsDocument,
      { organisationId },
    ],
    queryFn: () =>
      getCustomizationsDocument({
        pathParams: {
          organisationId,
        },
      }),
    ...options,
  });
};
