import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postTechnicalUser } from '../requests';
import { PostTechnicalUserRequestType } from '../types';

export const usePostTechnicalUser = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PostTechnicalUserRequestType['bodyParams']) =>
      postTechnicalUser({
        pathParams: { organisationId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.technicalUsers],
      });
    },
  });
};
