import { apiAuthClient } from 'api/helpers';

import { GetTemplateResponseType, GetTemplateRequestType } from '../types';

export const getTemplate = async ({
  pathParams: { templateId },
}: GetTemplateRequestType) => {
  const url = `/api/v2/templates/${templateId}`;

  const { data } = await apiAuthClient.get<GetTemplateResponseType>(url);

  return data.data;
};
