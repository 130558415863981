import { useQuery } from '@tanstack/react-query';
import { API_QUERY_KEYS } from 'consts';
import { getDocumentInvitation } from '../requests';
import { GetDocumentInvitationRequestType } from '../types';

export const useGetDocumentInvitation = ({
  pathParams: { invitationId },
}: GetDocumentInvitationRequestType) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.documentInvitation, { invitationId }],
    queryFn: () => getDocumentInvitation({ pathParams: { invitationId } }),
  });
