import { apiAuthClient } from 'api/helpers';

import { DeleteUserRequestType } from '../types';

export const deleteUser = async ({ bodyParams }: DeleteUserRequestType) => {
  const url = '/api/v2/auth/user';

  const { data } = await apiAuthClient.delete<{ data: '' }>(url, {
    data: bodyParams,
  });

  return data;
};
