import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodDateVariableSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    initialValue: z
      .string()
      .optional()
      .refine(data => data !== undefined, {
        message: t('common:formErrors.required'),
      }),
    placeholder: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    label: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    hint: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    apiDescription: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    minYearDelta: z.string().optional(),
    maxYearDelta: z.string().optional(),
    required: z.boolean(),
    requiredWhen: z.null(),
  });
};
