import { RefObject, useEffect, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { Box } from '@mantine/core';

import 'cropperjs/dist/cropper.css';

import { IMAGE_SQUARE_SIZE } from '../consts';
import { getZoomRange } from './helpers';
import { ZoomControl } from './ZoomControl';

type Props = {
  avatarError: string;
  cropperRef: RefObject<ReactCropperElement>;
  avatarDataUrl: string;
};

export const AvatarCropper = ({
  avatarError,
  cropperRef,
  avatarDataUrl,
}: Props) => {
  const [zoom, setZoom] = useState(1);
  const [zoomRange, setZoomRange] = useState<number[]>([]);

  useEffect(() => {
    if (cropperRef.current) {
      const { cropper } = cropperRef.current;
      const { canvasData, containerData } = cropper as unknown as {
        canvasData?: {
          width: number;
          height: number;
          naturalWidth: number;
        };
        containerData?: {
          width: number;
          height: number;
        };
      };

      if (canvasData && containerData) {
        cropper.setCanvasData({
          left: (containerData.width - canvasData.width) / 2,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, zoomRange]);

  return (
    <div>
      <Box
        sx={theme => ({
          width: 392,
          margin: theme.other.spacing(0, 'auto', 2),
          '& .cropper-crop-box': {
            '& .cropper-view-box, & .cropper-face': {
              borderRadius: '50%',
            },
          },
        })}
        component={Cropper}
        ref={cropperRef}
        src={avatarDataUrl}
        viewMode={1}
        aspectRatio={1}
        zoomTo={zoomRange[zoom - 1]}
        dragMode="none"
        cropBoxResizable={false}
        scalable
        guides={false}
        minContainerHeight={400}
        zoomOnWheel={false}
        ready={() => {
          if (cropperRef.current) {
            const { cropper } = cropperRef.current;
            const { canvasData, containerData } = cropper as unknown as {
              canvasData: {
                width: number;
                height: number;
                naturalWidth: number;
              };
              containerData: {
                width: number;
                height: number;
              };
            };

            cropper.setCropBoxData({
              width: IMAGE_SQUARE_SIZE,
              height: IMAGE_SQUARE_SIZE,
              top: (containerData.height - IMAGE_SQUARE_SIZE) / 2,
              left: (containerData.width - IMAGE_SQUARE_SIZE) / 2,
            });

            setZoomRange(
              getZoomRange({
                canvasDataWidth: canvasData.width,
                canvasDataNaturalWidth: canvasData.naturalWidth,
                canvasDataHeight: canvasData.height,
              }),
            );
          }
        }}
      />
      <ZoomControl zoom={zoom} setZoom={setZoom} avatarError={avatarError} />
    </div>
  );
};
