import { useTranslation } from 'react-i18next';
import { Box, Paper, UnstyledButton } from '@mantine/core';

import { TransactionsIcon } from 'icons';

const LoopWrapper = ({ attributes, node, editor, children, isActive }) => {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <Paper
      {...attributes}
      sx={theme => {
        const activeBorderColor =
          theme.focusRingStyles.inputStyles(theme).borderColor;

        return {
          marginTop: theme.other.spacing(1),
          border: '1px solid',
          borderColor: isActive ? activeBorderColor : undefined,
          userSelect: 'none',
        };
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          columnGap: theme.other.spacing(1),
        })}
        contentEditable={false}
      >
        <TransactionsIcon />
        <span>{t('templates:loop')}</span>
        <UnstyledButton
          sx={theme => ({
            fontWeight: 500,
            color: theme.colors[theme.primaryColor][theme.primaryShade],
          })}
          onClick={() => {
            editor.unwrapBlockByKey(node.key, 'loopWrapper');
          }}
        >
          {t('common:disable')}
        </UnstyledButton>
      </Box>
      {children}
    </Paper>
  );
};

export const loopWrapper = {
  renderNode: (
    { attributes, node, children, isSelected, isFocused },
    editor,
    next,
  ) => {
    if (node.type === 'loopWrapper') {
      return (
        <LoopWrapper
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        >
          {children}
        </LoopWrapper>
      );
    }

    return next();
  },
  commands: {
    wrapInLoopWrapper: editor => {
      /* TODO: update when table and list are ready */

      editor.wrapBlockWithOptionalEmptyContent('loopWrapper');
    },
  },
};
