import { useMutation } from '@tanstack/react-query';
import { useGetCurrentOrganisationId } from 'hooks';
import { postWebhook } from '../requests';
import { PostWebhookRequestType } from '../types';

export const usePostWebhook = ({
  options,
}: {
  options?: {
    onSuccess?: () => void;
  };
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      bodyParams,
    }: {
      bodyParams: PostWebhookRequestType['bodyParams'];
    }) =>
      postWebhook({
        pathParams: {
          organisationId,
        },
        bodyParams,
      }),
    ...options,
  });
};
