import { FlagDeIcon, FlagPlIcon, FlagUkIcon } from 'icons';

export const getMoneyVariableIcon = defaultMoneyTranslation => {
  switch (defaultMoneyTranslation) {
    case 'pl': {
      return FlagPlIcon;
    }
    case 'en': {
      return FlagUkIcon;
    }
    case 'de': {
      return FlagDeIcon;
    }
    default:
      return null;
  }
};
