import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { getZodSchemaErrorMessages } from 'helpers';
import { Modal } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { VARIABLE_TYPES } from 'legacy/TemplateCreatorEditor/consts';

import { VariableTypeFields, Name, Type } from '../VariableTypeFields';
import { useGetVariableNameError } from './hooks';

export const VariableAddModal = ({
  isOpen,
  onClose,
  addVariableCallback,
  defaultType: defaultTypeProp,
  allowedTypes,
}) => {
  const { t } = useTranslation(['common', 'templates']);

  const editor = useContext(EditorContext);

  const getVariableNameError = useGetVariableNameError();

  const defaultType = defaultTypeProp || VARIABLE_TYPES.text;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(defaultType);

  const fieldsInstanceRef = useRef(null);

  const handleClose = () => {
    onClose();
    setName('');
    setNameError('');
    setType(defaultType);

    fieldsInstanceRef.current = null;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      title={t('templates:templateCreatorVariables.newVariable')}
      defaultButtonAction={handleClose}
      primaryButtonColor="green"
      primaryButtonText={t('common:create')}
      primaryButtonAction={() => {
        if (type === VARIABLE_TYPES.company) {
          alert('Typ zmiennej Strona umowy - COMING SOON');
          return;
        }

        const { getValues, schema, clearErrors, setError } =
          fieldsInstanceRef.current;
        const values = getValues();

        const variableNameError = getVariableNameError(name);

        setNameError(variableNameError);

        const errors =
          getZodSchemaErrorMessages({
            schema,
            fields: values,
          }) || {};

        clearErrors();

        Object.entries(errors).forEach(([field, message]) => {
          setError(field, { message });
        });

        if (!(variableNameError || Object.keys(errors).length > 0)) {
          const newVariable = {
            ...values,
            name,
            type,
          };

          editor.addVariable(newVariable);

          if (addVariableCallback) {
            addVariableCallback(newVariable);
          }

          handleClose();
        }
      }}
    >
      <Box sx={{ overflowX: 'hidden' }} component="form">
        <Name
          value={name}
          onChange={value => {
            setNameError('');
            setName(value);
          }}
          error={nameError}
        />
        <Type value={type} onChange={setType} allowedTypes={allowedTypes} />
        <VariableTypeFields
          variableType={type}
          fieldsInstanceRef={fieldsInstanceRef}
        />
      </Box>
    </Modal>
  );
};
