import { UserBoardType } from 'types';

export const filterOrganisationsAndBoardsByNames = (
  boards: UserBoardType[],
  searchValue: string,
) =>
  boards.filter(({ name, organisation }) => {
    const searchValueLowerCased = searchValue.toLowerCase();

    return (
      name.toLowerCase().includes(searchValueLowerCased) ||
      organisation.name.toLowerCase().includes(searchValueLowerCased)
    );
  });
