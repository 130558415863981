import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Anchor } from '@mantine/core';

import { TransactionEntryListItemType } from 'types';
import { getTransactionListStatusColor } from 'helpers';
import { useFormatUserDate } from 'hooks';
import { DYNAMIC_URLS } from 'consts';
import { Table, Status } from 'components/shared';

type Props = {
  transactionEntries: TransactionEntryListItemType[];
};

export const TransactionEntriesList = ({ transactionEntries }: Props) => {
  const { t } = useTranslation('transactions');
  const { transactionId, transactionDataStream } = useParams() as {
    transactionId: string;
    transactionDataStream: string;
  };

  const formatUserDate = useFormatUserDate();

  return (
    <Table hasData={transactionEntries.length > 0}>
      <thead>
        <tr>
          <th>{t('transactions:transactionEntries.uuid')}</th>
          <th>{t('transactions:transactionEntries.date')}</th>
          <th>{t('transactions:transactionEntries.status')}</th>
        </tr>
      </thead>
      <tbody>
        {transactionEntries.map(({ id, createdAt, status }) => (
          <tr key={id}>
            <td>
              <Anchor
                sx={{ fontWeight: 600 }}
                component={Link}
                to={DYNAMIC_URLS.transactionEntry({
                  transactionId,
                  transactionDataStream,
                  transactionEntry: id,
                })}
              >
                {id}
              </Anchor>
            </td>
            <td>{formatUserDate(createdAt)}</td>
            <td>
              <Status
                color={getTransactionListStatusColor(status)}
                label={status.message}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
