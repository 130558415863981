import { useContext, useEffect } from 'react';

import { DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE } from 'consts';
import { LayoutMainBottomPaddingSpacingContext } from 'context';
import { useIsMdBreakpoint } from 'hooks';

import { ActionsBarPropsType } from './types';
import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';

export const ActionsBar = (props: ActionsBarPropsType) => {
  const [, setLayoutMainBottomPaddingSpacing] = useContext(
    LayoutMainBottomPaddingSpacingContext,
  );

  const isMdBreakpoint = useIsMdBreakpoint();

  useEffect(() => {
    setLayoutMainBottomPaddingSpacing(isMdBreakpoint ? 10 : 0);

    return () => {
      setLayoutMainBottomPaddingSpacing(DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE);
    };
  }, [isMdBreakpoint, setLayoutMainBottomPaddingSpacing]);

  return isMdBreakpoint ? (
    <DesktopView {...props} />
  ) : (
    <MobileView {...props} />
  );
};
