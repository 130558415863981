import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES } from 'consts';
import { MappedUserNotificationListItemType } from 'types';
import { EXPOSED_NOTIFICATIONS } from '../consts';
import { NotificationListItem } from './NotificationListItem';

type Props = {
  notifications: MappedUserNotificationListItemType;
};

export const NotificationList = ({ notifications }: Props) => {
  const { t } = useTranslation(['notifications', 'user']);

  const hasAllPushNotificationsEnabled = EXPOSED_NOTIFICATIONS.every(
    ({ type }) =>
      notifications[
        NOTIFICATION_TYPES[type] as keyof typeof NOTIFICATION_TYPES
      ]!.push,
  );
  const hasAllMailNotificationsEnabled = EXPOSED_NOTIFICATIONS.every(
    ({ type }) =>
      notifications[
        NOTIFICATION_TYPES[type] as keyof typeof NOTIFICATION_TYPES
      ]!.mail,
  );
  const hasAllNotificationsEnabled =
    hasAllPushNotificationsEnabled && hasAllMailNotificationsEnabled;

  return (
    <ul>
      {hasAllPushNotificationsEnabled && (
        <NotificationListItem
          text={t('user:iWantAllAppNotifications')}
          hasSuccessIcon
          hasPushIcon={false}
          hasMailIcon={false}
        />
      )}
      {hasAllMailNotificationsEnabled && (
        <NotificationListItem
          text={t('user:iWantAllMailNotifications')}
          hasSuccessIcon
          hasPushIcon={false}
          hasMailIcon={false}
        />
      )}
      {!hasAllNotificationsEnabled &&
        EXPOSED_NOTIFICATIONS.map(({ type, translations }) => {
          const _channels =
            notifications[
              NOTIFICATION_TYPES[type] as keyof typeof NOTIFICATION_TYPES
            ];
          const channels = _channels as NonNullable<typeof _channels>;

          return (
            <NotificationListItem
              key={type}
              text={t(translations.type)}
              hasSuccessIcon={channels.push || channels.mail}
              hasPushIcon={channels.push}
              hasMailIcon={channels.mail}
            />
          );
        })}
    </ul>
  );
};
