import { useTranslation } from 'react-i18next';
import { Avatar, Box, Menu } from '@mantine/core';

import { getUserFullName } from 'helpers';
import { DocumentListItemParticipantType } from 'types';
import { UserAvatar } from 'components/shared';

import {
  getDocumentRoleStyles,
  getDocumentRoleTranslationKey,
  getParticipantRoleBorderColor,
} from '../../../helpers';

const PARTICIPANTS_DISPLAY_LIMIT = 3;

type Props = {
  participants: DocumentListItemParticipantType[];
};

export const Participants = ({ participants }: Props) => {
  const { t } = useTranslation(['common', 'documents']);

  return (
    <Menu
      styles={{ itemLabel: { display: 'flex', alignItems: 'center' } }}
      withinPortal
      position="top-start"
      trigger="hover"
    >
      <Menu.Target>
        <Avatar.Group>
          {participants
            .slice(0, PARTICIPANTS_DISPLAY_LIMIT)
            .map(({ id, role, user: { name, surname, isDeleted, avatar } }) => (
              <UserAvatar
                styles={theme => ({
                  placeholder: {
                    borderColor: getParticipantRoleBorderColor(role, theme),
                  },
                })}
                key={id}
                name={name}
                surname={surname}
                isDeleted={isDeleted}
                avatar={avatar}
                imageSize={32}
              />
            ))}
          {participants.length > PARTICIPANTS_DISPLAY_LIMIT && (
            <Avatar
              styles={theme => ({
                placeholder: {
                  backgroundColor: theme.colors.gray[1],
                  color: theme.colors.gray[3],
                  fontSize: theme.fontSizes.sm,
                  fontWeight: 500,
                },
              })}
              size={38}
            >
              +{participants.length - PARTICIPANTS_DISPLAY_LIMIT}
            </Avatar>
          )}
        </Avatar.Group>
      </Menu.Target>
      <Menu.Dropdown>
        {participants.map(
          ({ id, role, user: { name, surname, isDeleted, avatar } }) => (
            <Menu.Item
              sx={theme => ({
                '&:disabled': {
                  color: theme.black,
                  userSelect: 'initial',
                },
              })}
              key={id}
              disabled
            >
              <UserAvatar
                sx={theme => ({ marginRight: theme.other.spacing(1) })}
                name={name}
                surname={surname}
                isDeleted={isDeleted}
                avatar={avatar}
              />
              <span>
                <Box
                  sx={theme => ({
                    display: 'block',
                    fontSize: theme.fontSizes.lg,
                  })}
                  component="span"
                >
                  {isDeleted
                    ? t('common:accountDeleted')
                    : getUserFullName(name, surname)}
                </Box>
                <Box
                  sx={theme => ({
                    fontSize: theme.fontSizes.sm,
                    ...getDocumentRoleStyles(role, theme),
                  })}
                  component="span"
                >
                  {!isDeleted && t(getDocumentRoleTranslationKey(role))}
                </Box>
              </span>
            </Menu.Item>
          ),
        )}
      </Menu.Dropdown>
    </Menu>
  );
};
