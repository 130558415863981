import { useState } from 'react';
import { MetaType, TechnicalUserType } from 'types';

import { PercentageContainer } from 'components/shared';

import { NewTechnicalUserPanel } from './NewTechnicalUserPanel';
import { Header } from './Header';
import { TechnicalUsersList } from './TechnicalUsersList';
import { NewTechnicalUserInput } from './NewTechnicalUserInput';
import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../types';

type Props = {
  hasCreateTechnicalUserAccess: boolean;
  technicalUsers: TechnicalUserType[];
  isRefetchingTechnicalUsers: boolean;
  meta: MetaType;
  hasActiveFilters: boolean;
};

export const TechnicalUsers = ({
  hasCreateTechnicalUserAccess,
  technicalUsers,
  isRefetchingTechnicalUsers,
  meta,
  hasActiveFilters,
}: Props) => {
  const [isNewTechnicalUserInputShown, setIsNewTechnicalUserInputShown] =
    useState(false);

  const [
    bearerTokensForNewlyCreatedTechnicalUsers,
    setBearerTokensForNewlyCreatedTechnicalUsers,
  ] = useState<BearerTokensForNewlyCreatedTechnicalUsersType>({});

  const hasTechnicalUsers = technicalUsers.length > 0;

  const openNewTechnicalUserPanel = () => {
    if (!isNewTechnicalUserInputShown) {
      setIsNewTechnicalUserInputShown(true);
    }
  };

  return (
    <>
      <Header
        isAddTechnicalUserButtonShown={
          hasCreateTechnicalUserAccess &&
          (hasTechnicalUsers || isNewTechnicalUserInputShown)
        }
        onAddNewTechnicalUser={openNewTechnicalUserPanel}
      />
      <PercentageContainer>
        {isNewTechnicalUserInputShown && (
          <NewTechnicalUserInput
            setIsNewTechnicalUserInputShown={setIsNewTechnicalUserInputShown}
            setBearerTokensForNewlyCreatedTechnicalUsers={
              setBearerTokensForNewlyCreatedTechnicalUsers
            }
          />
        )}
        {(hasTechnicalUsers || hasActiveFilters) && (
          <TechnicalUsersList
            technicalUsers={technicalUsers}
            isRefetchingTechnicalUsers={isRefetchingTechnicalUsers}
            meta={meta}
            bearerTokensForNewlyCreatedTechnicalUsers={
              bearerTokensForNewlyCreatedTechnicalUsers
            }
            setBearerTokensForNewlyCreatedTechnicalUsers={
              setBearerTokensForNewlyCreatedTechnicalUsers
            }
          />
        )}
        {hasCreateTechnicalUserAccess &&
          !isRefetchingTechnicalUsers &&
          !hasTechnicalUsers &&
          !hasActiveFilters &&
          !isNewTechnicalUserInputShown && (
            <NewTechnicalUserPanel
              onCreateNewTechnicalUser={openNewTechnicalUserPanel}
            />
          )}
      </PercentageContainer>
    </>
  );
};
