import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import { TechnicalUserType } from 'types';

import {
  getBearerTokenColorStatus,
  getCertificateColorStatus,
} from '../../helpers';
import { AccessButton } from '../AccessButton';
import { BearerTokenModal } from '../BearerTokenModal';
import { CertificateModal } from '../CertificateModal';
import { CertificateTooltip } from '../CertificateTooltip';
import { OwnCertificateModal } from '../OwnCertificateModal';
import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../../types';

type Props = {
  hasCreateCertificateAccess: boolean;
  hasCreateOwnCertificateAccess: boolean;
  hasUpdateTechnicalUserAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  visibleBearerToken: TechnicalUserType['bearerToken'];
  setBearerTokensForNewlyCreatedTechnicalUsers: Dispatch<
    SetStateAction<BearerTokensForNewlyCreatedTechnicalUsersType>
  >;
} & Pick<TechnicalUserType, 'certificate' | 'bearerToken'>;

export const AccessActions = ({
  hasCreateCertificateAccess,
  hasCreateOwnCertificateAccess,
  hasUpdateTechnicalUserAccess,
  technicalUserId,
  bearerToken,
  certificate,
  visibleBearerToken,
  setBearerTokensForNewlyCreatedTechnicalUsers,
}: Props) => {
  const { t } = useTranslation('api');

  const [isBearerTokenModalOpen, setIsBearerTokenModalOpen] = useState(false);
  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isCustomCertificateModalOpen, setIsCustomCertificateModalOpen] =
    useState(false);

  const hasPergaminCertificate = certificate.type === 'pergamin';
  const hasCustomCertificate = certificate.type === 'custom';
  const hasAnyCertificate = hasPergaminCertificate || hasCustomCertificate;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AccessButton
          sx={theme => ({ marginRight: theme.other.spacing(2.5) })}
          statusColor={getBearerTokenColorStatus(bearerToken)}
          isBold={bearerToken.token !== null}
          onClick={() => {
            setIsBearerTokenModalOpen(true);
          }}
        >
          {t('api:bearerToken')}
        </AccessButton>
        <CertificateTooltip
          disabled={hasPergaminCertificate || !hasAnyCertificate}
        >
          <AccessButton
            sx={theme => ({ marginRight: theme.other.spacing(2.5) })}
            statusColor={getCertificateColorStatus('pergamin', certificate)}
            isBold={hasPergaminCertificate}
            onClick={() => {
              setIsCertificateModalOpen(true);
            }}
          >
            {t('api:pergaminCertificate')}
          </AccessButton>
        </CertificateTooltip>
        <CertificateTooltip
          disabled={hasCustomCertificate || !hasAnyCertificate}
        >
          <AccessButton
            statusColor={getCertificateColorStatus('custom', certificate)}
            isBold={hasCustomCertificate}
            onClick={() => {
              setIsCustomCertificateModalOpen(true);
            }}
          >
            {t('api:ownCertificate')}
          </AccessButton>
        </CertificateTooltip>
      </Box>
      <BearerTokenModal
        hasUpdateTechnicalUserAccess={hasUpdateTechnicalUserAccess}
        isOpen={isBearerTokenModalOpen}
        onClose={() => {
          setIsBearerTokenModalOpen(false);
        }}
        technicalUserId={technicalUserId}
        bearerToken={bearerToken}
        visibleBearerToken={visibleBearerToken}
        setBearerTokensForNewlyCreatedTechnicalUsers={
          setBearerTokensForNewlyCreatedTechnicalUsers
        }
      />
      <CertificateModal
        hasCreateCertificateAccess={hasCreateCertificateAccess}
        isOpen={isCertificateModalOpen}
        onClose={() => {
          setIsCertificateModalOpen(false);
        }}
        technicalUserId={technicalUserId}
        expiresAt={
          hasPergaminCertificate ? (certificate.expiresAt as string) : undefined
        }
      />
      <OwnCertificateModal
        hasCreateOwnCertificateAccess={hasCreateOwnCertificateAccess}
        isOpen={isCustomCertificateModalOpen}
        onClose={() => {
          setIsCustomCertificateModalOpen(false);
        }}
        technicalUserId={technicalUserId}
        expiresAt={
          hasCustomCertificate ? (certificate.expiresAt as string) : undefined
        }
      />
    </>
  );
};
