import { MetaType } from 'types';

import { useSearchParams } from 'hooks';
import { Pagination } from 'components/shared';

type Props = {
  meta: MetaType;
  isStickyToBottom?: boolean;
  pageName?: string;
};
export const PaginationReactRouter = ({
  meta,
  isStickyToBottom,
  pageName = 'page',
}: Props) => {
  const { getSearchParam, changeSearchParam, deleteSearchParam } =
    useSearchParams(['search', pageName]);

  const isPaginationShown = meta.lastPage > 1;

  return isPaginationShown ? (
    <Pagination
      sx={theme => ({
        paddingTop: theme.other.spacing(2),
        justifyContent: 'flex-end',
      })}
      meta={meta}
      currentPage={Number(getSearchParam(pageName)) || 1}
      changePage={page => {
        if (page === 1) {
          deleteSearchParam(pageName);
        } else {
          changeSearchParam(pageName, page);
        }
      }}
      isStickyToBottom={isStickyToBottom}
    />
  ) : null;
};
