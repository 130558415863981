import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { getHasHateoasAccess } from 'helpers';
import { useSearchParams } from 'hooks';
import { DataList, PaginationReactRouter } from 'components/shared';
import { MetaType, TechnicalUserType } from 'types';

import { TechnicalUserPanel } from '../TechnicalUserPanel';
import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../../types';

type Props = {
  technicalUsers: TechnicalUserType[];
  isRefetchingTechnicalUsers: boolean;
  meta: MetaType;
  bearerTokensForNewlyCreatedTechnicalUsers: BearerTokensForNewlyCreatedTechnicalUsersType;
  setBearerTokensForNewlyCreatedTechnicalUsers: Dispatch<
    SetStateAction<BearerTokensForNewlyCreatedTechnicalUsersType>
  >;
};

export const TechnicalUsersList = ({
  technicalUsers,
  isRefetchingTechnicalUsers,
  meta,
  bearerTokensForNewlyCreatedTechnicalUsers,
  setBearerTokensForNewlyCreatedTechnicalUsers,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const { deleteAllSearchParams, getSearchParam } = useSearchParams([
    'search',
    'page',
  ]);

  const scrollTopTriggerKey = `${getSearchParam('page')}${getSearchParam(
    'search',
  )}`;

  return (
    <>
      <DataList
        data={technicalUsers}
        isLoading={isRefetchingTechnicalUsers}
        noDataButtonText={t('common:clearFilters')}
        onNoDataButtonClick={deleteAllSearchParams}
        scrollTopTriggerKey={scrollTopTriggerKey}
      >
        {({ id, name, certificate, bearerToken, __links }) => (
          <TechnicalUserPanel
            hasCreateCertificateAccess={getHasHateoasAccess(
              'createCertificate',
              __links,
            )}
            hasCreateOwnCertificateAccess={getHasHateoasAccess(
              'createCertificate',
              __links,
            )}
            hasDeleteTechnicalUserAccess={getHasHateoasAccess(
              'delete',
              __links,
            )}
            hasUpdateTechnicalUserAccess={getHasHateoasAccess(
              'update',
              __links,
            )}
            technicalUserId={id}
            technicalUserName={name}
            certificate={certificate}
            bearerToken={bearerToken}
            visibleBearerToken={bearerTokensForNewlyCreatedTechnicalUsers[id]}
            setBearerTokensForNewlyCreatedTechnicalUsers={
              setBearerTokensForNewlyCreatedTechnicalUsers
            }
          />
        )}
      </DataList>
      <PaginationReactRouter meta={meta} isStickyToBottom />
    </>
  );
};
