import { ReactNode } from 'react';
import { Box } from '@mantine/core';

import { CursorPaginationReactRouter } from 'components/Transactions';
import { TRANSACTION_STATUS } from 'consts';
import { OrganisationUserListType } from 'types';

import { SelectedOrganisationMembersType } from '../../types';
import { DetailedFilters } from './DetailedFilters';

type Props = {
  children: ReactNode;
  organisationMembers: OrganisationUserListType[];
  selectedOrganisationMembers: SelectedOrganisationMembersType;
  status: 'all' | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS];
  prevPage: string | null | undefined;
  nextPage: string | null | undefined;
  handleChangeStatus: (
    status:
      | 'all'
      | (typeof TRANSACTION_STATUS)[keyof typeof TRANSACTION_STATUS],
  ) => void;
  handleChangeSelectedOrganisationMembers: (
    selectedMembers: SelectedOrganisationMembersType,
  ) => void;
  areFiltersOpen: boolean;
  isOrganisationMembersError: boolean;
  isOrganisationMembersLoading: boolean;
  isFetchingOrganisationMembersNextPage: boolean;
  fetchOrganisationMembersNextPage: () => void;
  setOrganisationMembersNameAndSurname: (nameAndSurname: string) => void;
};

export const Filters = ({
  children,
  organisationMembers,
  selectedOrganisationMembers,
  status,
  prevPage,
  nextPage,
  handleChangeStatus,
  handleChangeSelectedOrganisationMembers,
  areFiltersOpen,
  isOrganisationMembersError,
  isOrganisationMembersLoading,
  isFetchingOrganisationMembersNextPage,
  fetchOrganisationMembersNextPage,
  setOrganisationMembersNameAndSurname,
}: Props) => (
  <div>
    {areFiltersOpen && (
      <DetailedFilters
        organisationMembers={organisationMembers}
        selectedOrganisationMembers={selectedOrganisationMembers}
        status={status}
        handleChangeStatus={handleChangeStatus}
        handleChangeSelectedOrganisationMembers={
          handleChangeSelectedOrganisationMembers
        }
        isOrganisationMembersError={isOrganisationMembersError}
        isOrganisationMembersLoading={isOrganisationMembersLoading}
        isFetchingOrganisationMembersNextPage={
          isFetchingOrganisationMembersNextPage
        }
        fetchOrganisationMembersNextPage={fetchOrganisationMembersNextPage}
        setOrganisationMembersNameAndSurname={
          setOrganisationMembersNameAndSurname
        }
      />
    )}
    <Box sx={theme => ({ marginBottom: theme.other.spacing(1.75) })}>
      {children}
    </Box>
    <CursorPaginationReactRouter
      prevPage={prevPage}
      nextPage={nextPage}
      isStickyToBottom
    />
  </div>
);
