import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { AgreementsModal } from './AgreementsModal';
import { AgreementList } from './AgreementList';

export const Agreements = () => {
  const { t } = useTranslation(['common', 'user']);

  const [isAgreementsModalOpen, setIsAgreementsModalOpen] = useState(false);

  return (
    <>
      <div>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.other.spacing(2.5),
          })}
        >
          <Text weight={600}>{t('user:marketingAgreements')}</Text>
          <UnstyledButton
            sx={theme => ({
              fontWeight: 600,
              color: theme.fn.primaryColor(),
            })}
            onClick={() => {
              setIsAgreementsModalOpen(true);
            }}
          >
            {t('common:manage')}
          </UnstyledButton>
        </Box>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          size="md"
          weight={400}
        >
          {t('user:marketingAgreementsManagementDescription')}
        </Text>
        <AgreementList />
      </div>
      <AgreementsModal
        isOpen={isAgreementsModalOpen}
        onClose={() => {
          setIsAgreementsModalOpen(false);
        }}
      />
    </>
  );
};
