import { useId } from 'react';
import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const WomanWithExclamationMarkRoundedIcon = ({
  color,
  size,
  width = 327,
  height = 313,
  sx,
  ...props
}: IconPropsType) => {
  const mantineTheme = useMantineTheme();

  const id = useId();
  const iconColor = useGetColor(color);
  const backgroundColor = mantineTheme.fn.rgba(iconColor, 0.1);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 327 313"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <circle id={`${id}_a`} cx={156.5} cy={156.5} r={156.5} />
        <path id={`${id}_b`} d="M0 0h141v122H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M98.756 187c10.628 0 19.244-8.613 19.244-19.238V90.238C118 79.613 109.384 71 98.756 71h-79.51C8.615 71 0 79.613 0 90.238v77.524C0 178.387 8.616 187 19.245 187h79.51Z"
            fill={backgroundColor}
          />
          <path
            d="M65.094 139.013h-9.281L53.468 94h13.875l-2.25 45.013ZM60.406 162c-4.312 0-7.406-3.366-7.406-7.502 0-4.136 3.094-7.406 7.406-7.406 4.407 0 7.594 3.27 7.594 7.406 0 4.136-3.188 7.502-7.594 7.502Z"
            fill={iconColor}
          />
        </g>
        <g transform="translate(14)">
          <mask id={`${id}_c`} fill="#fff">
            <use xlinkHref={`#${id}_a`} />
          </mask>
          <g mask={`url(#${id}_c)`}>
            <path
              d="M243.221 100c-11.281 0-21.14 5.96-26.498 14.84-6.773-7.431-16.697-12.128-27.764-12.128-20.411 0-36.959 15.959-36.959 35.644C152 158.042 168.548 174 188.96 174c15.094 0 28.066-8.73 33.805-21.237 5.438 4.751 12.602 7.642 20.456 7.642 17 0 30.779-13.522 30.779-30.203 0-16.68-13.78-30.202-30.779-30.202"
              fill="#080717"
            />
            <path
              d="M226 198.12 190.763 226l-9.069-19.139-4.973-10.493c-37.567 2.506-30.794-38.184-30.794-38.184S141 157.054 141 152.15c0-4.893 6.127-5.07 6.127-5.07-.9-19.292 12.253-26.079 12.253-26.079s4.534 3.4 8.226 1.259c3.693-2.13 13.061 8.67 19.465 15.457 6.404 6.775 8.988 2.376 8.988 2.376 4.684-2.376 11.457-.623 13.061 5.905 1.604 6.529-3.692 11.928-3.692 11.928L226 198.121Z"
              fill="#FCFDFA"
            />
            <path
              d="m178.309 195.382 13.662 28.984 33.111-26.338-20.37-40.014.591-.61c.047-.05 4.778-5.018 3.367-10.794-.753-3.084-2.66-4.557-4.13-5.251-2.15-1.018-4.798-1.01-6.972.005a4.298 4.298 0 0 1-2.892 1.612c-2.252.256-4.806-1.118-7.612-4.103-.69-.734-1.412-1.515-2.159-2.32-4.644-5.014-13.287-14.34-15.78-12.9-3.417 1.993-7.275.03-8.774-.904-2.436 1.522-11.73 8.418-10.979 24.6l.055 1.196-1.166.034c-.2.007-4.97.213-4.97 3.888 0 3.874 3.84 4.84 4.003 4.879l1.047.25-.174 1.09c-.03.184-2.918 18.514 6.468 29.168 5.032 5.71 12.735 8.26 22.903 7.58l.77-.052ZM191.139 228l-14.225-30.177c-10.538.544-18.594-2.276-23.987-8.404-8.919-10.133-7.675-25.936-7.16-30.111-2.049-.83-4.767-2.929-4.767-6.841 0-4.103 3.488-5.71 6.046-6.123-.383-18.938 12.281-25.733 12.831-26.018l.628-.326.568.427c.037.027 3.955 2.912 6.923 1.183 3.756-2.182 10.069 4.148 18.571 13.326.743.802 1.462 1.58 2.147 2.308 2.195 2.336 4.19 3.54 5.658 3.403.989-.097 1.448-.806 1.469-.837l.17-.269.292-.162c2.868-1.46 6.307-1.518 9.196-.153 2.718 1.284 4.633 3.704 5.393 6.814 1.416 5.79-1.981 10.659-3.394 12.372L228 198.682 191.138 228Z"
              fill="#000"
            />
            <path
              d="M185.253 205 180 193.644s16.14-4.823 18.22-25.644c0 0 5.551 19.08-12.967 37"
              fill="#080717"
            />
            <g transform="translate(145 191)">
              <mask id={`${id}_d`} fill="#fff">
                <use xlinkHref={`#${id}_b`} />
              </mask>
              <path
                d="m39.6 53.433 1.412-18.512-2.95-4.082L79.901 0l12.084 14.923-2.111 2.046C125.965 53.223 141 122 141 122l-141-.982 39.6-67.585Z"
                fill="#080717"
                mask={`url(#${id}_d)`}
              />
            </g>
            <path
              d="m236 207.681-35.876 32.551a2.939 2.939 0 0 1-4.381-.476l-8.897-12.4-2.846-4.015L224.347 193 236 207.681Z"
              fill="#FCFDFA"
            />
            <path
              d="m185.296 222.706 2.305 3.046 9.359 12.215c.329.43.814.697 1.367.754a1.957 1.957 0 0 0 1.502-.46l36.904-31.365-10.837-12.789-40.6 28.6ZM198.531 241a4.38 4.38 0 0 1-.455-.024 4.374 4.374 0 0 1-3.026-1.666L182 222.212 226.312 191 240 207.153l-38.6 32.804A4.43 4.43 0 0 1 198.53 241ZM187.44 144a9.98 9.98 0 0 1-2.352-.284c-5.929-1.432-10.07-7.891-11.582-11.482-2.174-5.159-9.432-11.509-9.506-11.572l1.506-1.662c.314.273 7.722 6.759 10.094 12.387 1.257 2.984 4.971 8.95 10.03 10.171 2.62.631 5.284-.14 7.916-2.298l1.454 1.706c-2.466 2.019-5 3.034-7.56 3.034"
              fill="#000"
            />
            <path
              d="M158 146c0 1.657-.895 3-2 3s-2-1.343-2-3 .895-3 2-3 2 1.343 2 3"
              fill="#080717"
            />
            <path
              d="M199.023 156c-.44 0-.862-.234-1.076-.64-.073-.143-1.816-3.544-.384-6.81.924-2.108 2.901-3.621 5.88-4.5.638-.187 1.312.164 1.505.784a1.168 1.168 0 0 1-.805 1.464c-2.254.665-3.72 1.727-4.352 3.158-.996 2.249.298 4.817.311 4.843a1.16 1.16 0 0 1-.536 1.574 1.23 1.23 0 0 1-.543.127"
              fill="#231F20"
            />
            <path
              d="m34 312.826 52.061-78.67s-20.845-18.007-10.813-35.839c0 0-2.075-6.642 5.88-11.013 0 0-1.73-5.244 4.325-8.041L63.087 153.47c-1.869-2.155-1.618-5.44.555-7.28a5.01 5.01 0 0 1 6.744.236l42.394 41.227s4.498-5.944 4.498-13.81c0-7.867 5.361-10.49 9.86-10.49 4.496 0-.866 9.44.172 17.307 1.038 7.868 3.632 46.503-.866 62.412C121.948 258.98 92.44 313 92.44 313"
              fill="#FCFDFA"
            />
            <path
              d="m92.372 313-2.083-.986c.247-.54 29.49-54.225 33.904-69.895 4.534-16.088 1.767-54.948.831-62.063-.476-3.615.313-7.507.946-10.635.447-2.205 1.003-4.95.502-5.574-.085-.106-.339-.123-.482-.123-4.206 0-8.698 2.456-8.698 9.345 0 8.184-4.536 14.29-4.729 14.547l-.783 1.037-43.276-42.216a3.848 3.848 0 0 0-5.2-.182 3.937 3.937 0 0 0-1.385 2.725 3.955 3.955 0 0 0 .954 2.907l23.36 27.022-1.403.65c-5.051 2.341-3.764 6.456-3.708 6.63l.306.926-.847.466c-6.97 3.841-5.396 9.432-5.325 9.669l.143.479-.243.441c-9.578 17.081 10.732 34.619 10.823 34.793l.315.614L33.91 312.98 32 311.684l51.636-78.27c-1.58-3.41-19.65-19.592-10.695-36.002-.322-1.746-.762-7.405 5.78-11.433-.334-2.155.201-5.53 3.813-7.813l-21.388-24.743a6.282 6.282 0 0 1-1.518-4.623c.13-1.7.91-3.238 2.2-4.33a6.12 6.12 0 0 1 8.27.288l41.357 40.344c1.215-2.006 3.538-6.56 3.538-12.033 0-8.618 5.923-11.675 10.997-11.675 1.22 0 1.904.532 2.264.98 1.19 1.48.678 4.013-.032 7.516-.63 3.11-1.344 6.634-.92 9.857.947 7.2 3.733 46.58-.899 63.012-4.465 15.847-33.014 68.027-34.031 70.241"
              fill="#000"
            />
            <path
              d="M86.664 224c-.27 0-.542-.096-.759-.291a1.15 1.15 0 0 1-.098-1.611c.053-.063.987-1.198.826-3-.171-1.904-1.475-3.864-3.875-5.826L79 210.2l16.125 3.63a3.064 3.064 0 0 0 3.624-2.198l.84-3.116 2.694.725a4.297 4.297 0 0 0 5.174-2.697c.74-2.023-.13-4.315-2.024-5.328l-2.372-1.27 2.09-4.408a3.801 3.801 0 0 0-1.693-5.027l-20.16-10.343a1.149 1.149 0 0 1-.498-1.542 1.137 1.137 0 0 1 1.534-.5l20.164 10.343a6.09 6.09 0 0 1 2.711 8.055l-1.15 2.428.448.24c2.893 1.549 4.223 5.05 3.091 8.144-1.186 3.243-4.58 5.013-7.904 4.12l-.498-.134-.244.91c-.75 2.78-3.53 4.47-6.326 3.835l-7.612-1.713c1.137 1.506 1.773 3.048 1.897 4.61.223 2.795-1.329 4.579-1.395 4.653a1.139 1.139 0 0 1-.852.383"
              fill="#231F20"
            />
            <path
              d="M100.829 209c-.164 0-.331-.032-.49-.103l-26.66-11.76c-.585-.258-.842-.923-.571-1.485.27-.562.965-.808 1.551-.549l26.66 11.76c.586.26.844.924.574 1.486-.198.41-.622.651-1.064.651M104.847 200c-.174 0-.35-.04-.516-.126l-24.696-12.652a1.184 1.184 0 0 1-.512-1.576 1.143 1.143 0 0 1 1.546-.52l24.696 12.651c.568.291.798.997.512 1.575a1.15 1.15 0 0 1-1.03.648"
              fill="#231F20"
            />
            <path
              d="M161 167c-2.446 0-3.419-.201-5.597-.685C154.58 166.132 152 165 152 165"
              stroke="#000"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </Box>
  );
};
