import { ReactNode } from 'react';
import { Box, DefaultProps, packSx, Text } from '@mantine/core';

import { EMPTY_PLACEHOLDER } from 'consts';

type Props = {
  icon: ReactNode;
  label: string;
  value: string | null;
  sx?: DefaultProps['sx'];
};

export const DataItem = ({
  sx,
  icon,
  label,
  value = EMPTY_PLACEHOLDER,
}: Props) => (
  <Box sx={[{ display: 'flex' }, ...packSx(sx)]}>
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        marginRight: theme.other.spacing(1),
        backgroundColor: theme.colors[theme.primaryColor][1],
        borderRadius: theme.radius.sm,
      })}
    >
      {icon}
    </Box>
    <div>
      <Text size="md" weight={400}>
        {label}
      </Text>
      <Text size="md" weight={600}>
        {value}
      </Text>
    </div>
  </Box>
);
