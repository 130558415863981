import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const SignatureAnyIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={iconColor}
        d="M6.282 4v-.75h-.75V4h.75Zm11.436 0h.75v-.75h-.75V4ZM9.24 8.91a.75.75 0 1 0-1.342-.671l1.342.671Zm-2.958 4.233-.67-.336-.22.44.314.376.576-.48ZM12 20l-.576.48a.75.75 0 0 0 1.152 0L12 20Zm5.718-6.857.576.48.315-.377-.22-.439-.67.336Zm-1.617-4.904a.75.75 0 1 0-1.341.671L16.1 8.24Zm-3.351 6.047a.75.75 0 0 0-1.5 0h1.5ZM12 8.964A4.965 4.965 0 0 1 7.032 4h-1.5A6.465 6.465 0 0 0 12 10.464v-1.5ZM6.282 4.75h11.436v-1.5H6.282v1.5ZM16.968 4A4.965 4.965 0 0 1 12 8.964v1.5A6.465 6.465 0 0 0 18.468 4h-1.5Zm-9.07 4.239-2.286 4.568 1.34.671L9.24 8.91 7.898 8.24Zm-2.192 5.384 5.718 6.857 1.152-.96-5.718-6.857-1.152.96Zm6.87 6.857 5.718-6.857-1.152-.96-5.718 6.857 1.152.96Zm5.813-7.673L16.1 8.239l-1.341.671 2.288 4.569 1.34-.672ZM12.75 20v-5.714h-1.5V20h1.5Z"
      />
    </Box>
  );
};
