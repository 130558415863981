import { forwardRef } from 'react';
import { Button, ButtonProps, packSx } from '@mantine/core';

import { CreateIcon } from 'icons';

export const AddButtonSubtle = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ sx, ...buttonProps }, ref) => (
    <Button
      ref={ref}
      sx={[
        theme => ({
          fontSize: theme.fontSizes.md,
        }),
        ...packSx(sx),
      ]}
      leftIcon={<CreateIcon />}
      variant="subtle"
      color="green"
      {...buttonProps}
    />
  ),
);
