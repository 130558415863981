import { VARIABLES } from 'legacy/TemplateCreatorEditor/consts';

export const getGroupedVariables = variables => {
  const groupedVariables = VARIABLES.reduce(
    (accumulator, { value }) => ({
      ...accumulator,
      [value]: [],
    }),
    {},
  );

  variables.forEach(variable => {
    groupedVariables[variable.type].push(variable);
  });

  return Object.entries(groupedVariables).filter(
    ([_type, variables]) => variables.length > 0,
  );
};
