import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const CustomizationIcon = ({
  color,
  size,
  width = 212,
  height = 130,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 212 130"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          fill="#fff"
          d="M195.265 91.793 38.811 91.67 37.863.186 194.317.35l.947 91.442.001.001Z"
        />
        <path
          fill="#1E1C49"
          d="M195.264 91.98 38.81 91.854a.248.248 0 0 1-.206-.206L37.657.166A.207.207 0 0 1 37.739 0h.124l156.454.166a.2.2 0 0 1 .145.06.2.2 0 0 1 .061.147l.948 91.443a.198.198 0 0 1-.023.092.195.195 0 0 1-.06.072h-.124v-.002Zm-156.249-.537 156.042.124-.947-91.03L38.069.373l.947 91.07Z"
        />
        <path
          fill="#E67A00"
          d="M101.095 51.233c-.495-.33-2.471 3.427-2.471 3.427l-1.731-1.074 2.348-3.55c-2.512.164-3.748 1.486-4.325 2.435a2.403 2.403 0 0 0-.782-.33c-.536 0-.412 1.445-.865 2.56s-5.891 4.912-11.205 7.679c-5.314 2.765-4.778 4.376-4.778 4.376s0 2.022 2.513 1.898c2.513-.123 14.665-10.857 15.736-11.435 1.07-.578 4.573-.206 4.573-.206 3.254-1.527 1.483-5.45.988-5.78h-.001ZM80.457 67.498a1.109 1.109 0 0 1-1.524-.494 1.166 1.166 0 0 1-.08-.888 1.155 1.155 0 0 1 .574-.682 1.154 1.154 0 0 1 1.557 1.395 1.156 1.156 0 0 1-.528.67Z"
        />
        <path
          fill="#1E1C49"
          d="M79.551 130h-.082c-1.524-.454-1.689-2.311-1.565-3.261 0-.083.042-.124.124-.166l19.073-8.958a.244.244 0 0 1 .158-.007.242.242 0 0 1 .13.09l1.194 2.105c.042.083.042.124 0 .165-.04.042-.04.124-.082.124l-18.867 9.866-.082.042Zm-1.235-3.096c-.042.454-.124 2.229 1.194 2.683l18.619-9.743-1.03-1.775-18.785 8.835h.002Z"
        />
        <path
          fill="#1E1C49"
          d="m98.541 112.785-3.172 1.445 3.296 7.101 3.419 2.973-.288-4.542-3.255-6.977Z"
        />
        <path
          fill="#fff"
          d="m72.177 116.584-19.649-6.482-2.925-.33 2.925 2.023 19.65 5.697v-.908Z"
        />
        <path
          fill="#1E1C49"
          d="M72.177 117.699h-.082l-19.608-5.656h-.082l-2.925-2.023c-.082-.041-.082-.165-.082-.248a.248.248 0 0 1 .247-.123l2.883.33h.042l19.649 6.399a.284.284 0 0 1 .165.206v.908l-.082.166-.124.041Zm-19.567-6.11 19.362 5.615v-.496l-19.485-6.399-2.1-.247 2.223 1.527Z"
        />
        <path
          fill="#E63535"
          d="M62.62 112.869s15.243 1.816 16.313 4.334c1.07 2.518-1.36 3.096-3.007 2.766-1.647-.33-13.553-4.129-14.129-5.078-1.07-1.65.824-2.022.824-2.022Z"
        />
        <path
          fill="#1E1C49"
          d="M127.747 125.583h-.082l-14.706-8.752c-.083-.083-.123-.166-.083-.248.042-.082 3.831-11.27 14.995-8.505a18.036 18.036 0 0 1 3.543-4.293c3.049-2.642 6.385-3.385 9.681-2.105l16.6 7.1c.04.017.073.046.095.084.023.037.032.08.029.123a.203.203 0 0 1-.074.12.203.203 0 0 1-.132.045 33.052 33.052 0 0 0-6.673 1.156c-3.131.949-7.044 2.808-7.95 6.399 0 .082-.083.124-.166.124s-14.624.371-14.871 8.545a.33.33 0 0 1-.082.207h-.124Zm-14.418-9.042 14.254 8.505c.577-7.803 13.47-8.545 15.077-8.629.783-2.889 3.625-5.16 8.156-6.48a28.033 28.033 0 0 1 5.933-1.115l-15.82-6.77c-7.58-2.89-12.729 6.233-12.769 6.356a.166.166 0 0 1-.207.084c-10.339-2.643-14.129 6.646-14.624 8.049Z"
        />
        <path
          fill="#1E1C49"
          d="M142.784 116.831h-.083l-14.83-8.339a.207.207 0 0 1-.041-.29.164.164 0 0 1 .103-.101.161.161 0 0 1 .144.019l14.829 8.339a.245.245 0 0 1 .091.13.258.258 0 0 1-.007.16l-.206.082ZM126.43 123.188h-.082a.248.248 0 0 1-.115-.122.251.251 0 0 1-.009-.167c0-.082 2.389-7.967 12.152-7.266a.208.208 0 0 1 .206.207.208.208 0 0 1-.206.207c-9.433-.661-11.658 6.688-11.741 6.976a.291.291 0 0 1-.205.165Z"
        />
        <path
          fill="#1E1C49"
          d="M124.411 121.084h-.082a.169.169 0 0 1-.077-.029.155.155 0 0 1-.052-.061.17.17 0 0 1 .005-.158c.041-.082 2.719-7.595 10.875-6.894.124 0 .206.123.165.207a.207.207 0 0 1-.206.206c-7.827-.66-10.422 6.523-10.464 6.605-.04.083-.082.124-.164.124Z"
        />
        <path
          fill="#E67A00"
          d="m115.142 116.048 4.861 2.517s.577-4.128 4.655-4.994l-4.737-2.23s-2.76.041-4.779 4.707Z"
        />
        <path
          fill="#1E1C49"
          d="M141.96 114.065h-.082c-.084-.04-.166-.164-.124-.247a7.416 7.416 0 0 1 6.838-4.706c.054 0 .106.021.146.06a.207.207 0 0 1-.146.352c-5.026.207-6.427 4.377-6.427 4.418a.202.202 0 0 1-.083.095.194.194 0 0 1-.122.028Z"
        />
        <path
          fill="#1E1C49"
          d="M139.612 112.993h-.042c-.124-.042-.206-.166-.164-.248.04-.083 2.059-7.183 10.422-6.441a.206.206 0 0 1 .205.207.243.243 0 0 1-.247.206c-3.378-.33-6.055.62-8.032 2.808a8.453 8.453 0 0 0-1.936 3.302.293.293 0 0 1-.206.166ZM130.343 108.493h-.083a.164.164 0 0 1-.084-.04.173.173 0 0 1-.05-.079.173.173 0 0 1 .001-.093.177.177 0 0 1 .051-.078c.082-.206 2.719-5.284 7.25-5.408a.21.21 0 0 1 .146.061.21.21 0 0 1 0 .292.21.21 0 0 1-.146.061c-4.284.123-6.88 5.118-6.88 5.201l-.205.083Z"
        />
        <path
          fill="#E63535"
          d="m132.403 108.988 5.025 2.766s1.072-4.253 4.532-4.707l-4.779-2.187s-2.801.578-4.778 4.128Z"
        />
        <path
          fill="#1E1C49"
          d="M194.358 7.679H37.863a.207.207 0 0 1 0-.415h156.495c.055 0 .107.022.146.061.038.04.061.09.061.146a.208.208 0 0 1-.207.207ZM160.58 18.28h-28.259a.206.206 0 0 1-.206-.206V11.84a.21.21 0 0 1 .059-.147.208.208 0 0 1 .147-.06h28.259a.206.206 0 0 1 .205.207v6.234a.19.19 0 0 1-.016.078.196.196 0 0 1-.044.067.208.208 0 0 1-.145.06Zm-28.054-.414h27.848v-5.82h-27.848v5.82ZM160.58 37.413h-28.259a.206.206 0 0 1-.206-.206v-6.234c0-.055.021-.108.059-.147a.208.208 0 0 1 .147-.06h28.259a.206.206 0 0 1 .205.207v6.233a.19.19 0 0 1-.016.078.196.196 0 0 1-.044.068.208.208 0 0 1-.145.06Zm-28.054-.414h27.848v-5.82h-27.848v5.82ZM160.58 46.438h-28.259a.212.212 0 0 1-.147-.061.207.207 0 0 1-.059-.145v-6.234a.21.21 0 0 1 .059-.147.208.208 0 0 1 .147-.06h28.259c.054 0 .106.022.145.06a.21.21 0 0 1 .06.147v6.234a.191.191 0 0 1-.016.078.198.198 0 0 1-.11.112.21.21 0 0 1-.079.016Zm-28.054-.414h27.848v-5.82h-27.848v5.82ZM192.097 12.757a.867.867 0 1 0-.001-1.734.867.867 0 0 0 .001 1.734ZM182.29 49.17a.866.866 0 1 0-.001-1.732.866.866 0 0 0 .001 1.731Z"
        />
        <path fill="#004FC4" d="M151.558 70.223h-6.839v6.841h6.839v-6.84Z" />
        <path
          fill="#1E1C49"
          d="M149.869 78.85h-6.839a.206.206 0 0 1-.206-.205v-6.811a.206.206 0 0 1 .206-.206h6.839c.055 0 .107.02.145.06.039.038.061.09.061.146v6.81a.205.205 0 0 1-.206.207Zm-6.632-.412h6.425V72.04h-6.425v6.398ZM132.67 52.645a.208.208 0 0 1-.145-.353.207.207 0 0 1 .145-.06l20.309-.207c.055 0 .107.023.145.061a.2.2 0 0 1 .061.147.198.198 0 0 1-.061.145.204.204 0 0 1-.145.06l-20.309.207ZM145.77 56.113h-13.1a.206.206 0 0 1-.205-.207.208.208 0 0 1 .205-.206h13.1a.204.204 0 0 1 .206.206.209.209 0 0 1-.059.147.211.211 0 0 1-.147.06ZM79.597 29.188a.719.719 0 0 1-.305-.06c-.08-.04-.128-.092-.128-.146 0-.055.047-.108.128-.147a.735.735 0 0 1 .305-.06l42.716-.207c.114 0 .224.023.305.061.083.039.128.091.128.146 0 .056-.045.107-.128.146a.737.737 0 0 1-.305.06l-42.716.207ZM79.553 36.37a.808.808 0 0 1-.286-.069c-.08-.036-.128-.084-.146-.136 0-.055.044-.107.128-.146.08-.04.19-.06.305-.06l42.715-.207h.085a.74.74 0 0 1 .307.06c.081.04.128.092.128.146 0 .055-.047.107-.128.147a.738.738 0 0 1-.307.06l-42.8.206ZM107.261 32.656H79.708a.736.736 0 0 1-.305-.06c-.08-.039-.128-.092-.128-.147 0-.054.047-.107.128-.145a.717.717 0 0 1 .305-.061h27.553c.114 0 .224.021.307.061.084.04.126.09.126.145 0 .055-.045.108-.126.147a.754.754 0 0 1-.307.06Z"
        />
        <path
          fill="#004FC4"
          d="M85.771 24.013a3.588 3.588 0 0 0 3.585-3.592 3.588 3.588 0 0 0-3.585-3.591 3.587 3.587 0 0 0-3.584 3.591 3.588 3.588 0 0 0 3.584 3.592Z"
        />
        <path
          fill="#1E1C49"
          d="M83.505 22.65a3.834 3.834 0 0 1-3.568-2.341 3.848 3.848 0 0 1 .804-4.198 3.829 3.829 0 0 1 4.18-.85 3.842 3.842 0 0 1 1.267 6.251 3.823 3.823 0 0 1-2.683 1.138Zm0-7.224a3.417 3.417 0 0 0-3.191 2.095 3.433 3.433 0 0 0 .733 3.753 3.404 3.404 0 0 0 3.745.734 3.425 3.425 0 0 0 2.091-3.2 3.377 3.377 0 0 0-3.378-3.385v.003Z"
        />
        <path
          fill="#E6B800"
          d="m69.777 13.653 1.36-4.128 2.842 2.766-4.202 1.362Z"
        />
        <path
          fill="#1E1C49"
          d="m68.467 13.707-.166-.083a.287.287 0 0 1-.041-.206l1.4-4.128a.21.21 0 0 1 .125-.124h.205l2.843 2.767a.372.372 0 0 1 .042.206c0 .082-.082.124-.124.165l-4.202 1.32-.082.082Zm1.483-3.964-1.154 3.427 3.502-1.114-2.348-2.313ZM185.419 62.834h-.123l-1.937-1.198a.212.212 0 0 1 .206-.372l1.937 1.24c.123.04.123.206.082.288l-.165.042Z"
        />
        <path
          fill="#E67A00"
          d="M183.483 39.798a2.68 2.68 0 0 0 2.677-2.684 2.68 2.68 0 0 0-2.677-2.684 2.68 2.68 0 0 0-2.677 2.684 2.681 2.681 0 0 0 2.677 2.684Z"
        />
        <path
          fill="#1E1C49"
          d="M181.465 39.053a2.88 2.88 0 0 1-2.697-1.764 2.891 2.891 0 0 1 2.103-3.961 2.881 2.881 0 0 1 2.967 1.256c.313.481.477 1.045.469 1.62a2.854 2.854 0 0 1-.833 2.014 2.824 2.824 0 0 1-2.009.835Zm0-5.326a2.473 2.473 0 0 0-2.472 2.477 2.48 2.48 0 0 0 2.451 2.159 2.473 2.473 0 0 0 2.451-2.158 2.468 2.468 0 0 0-.71-1.737 2.469 2.469 0 0 0-1.72-.74ZM77.038 102.382H.706a.208.208 0 0 1-.207-.207.242.242 0 0 1 .07-.136.244.244 0 0 1 .136-.07h76.332a.207.207 0 0 1 .206.206.202.202 0 0 1-.06.146.204.204 0 0 1-.146.061h.001Z"
        />
        <path
          fill="#E63535"
          d="m170.013 109.193.617-5.779 4.779 4.17-5.396 1.609Z"
        />
        <path
          fill="#1E1C49"
          d="M172.072 109.648h-.123a.17.17 0 0 1-.067-.071.171.171 0 0 1-.016-.095l.618-5.78a.337.337 0 0 1 .165-.164c.041-.042.123 0 .205.041l4.738 4.128c.083.041.083.124.083.207 0 .082-.083.124-.165.124l-5.397 1.61h-.041Zm.782-5.532-.535 5.036 4.738-1.404-4.203-3.632Z"
        />
        <path
          fill="#fff"
          d="m56.112 22.334-2.801 5.532-8.115-4.747 6.673-5.285s.042-10.114 7.786-5.614a24.055 24.055 0 0 1 2.1 5.532c.66 3.137.042 6.152-5.643 4.582Z"
        />
        <path
          fill="#1E1C49"
          d="M53.31 28.073h-.123l-8.074-4.747c-.082-.042-.123-.082-.123-.166a.208.208 0 0 1 .082-.164l6.591-5.244c0-.702.248-4.995 2.72-6.398 1.359-.785 3.172-.537 5.355.742.022 0 .043.009.059.025a.08.08 0 0 1 .024.059 22.791 22.791 0 0 1 2.143 5.573c.453 2.105.205 3.592-.701 4.458-.906.867-2.637 1.033-5.025.413l-2.76 5.366c0 .042-.042.083-.125.083a.048.048 0 0 1-.02.006.044.044 0 0 1-.02-.006h-.002Zm-7.743-4.995 7.662 4.5 2.719-5.326a.16.16 0 0 1 .029-.076.176.176 0 0 1 .061-.053.17.17 0 0 1 .157.005c2.307.62 3.913.536 4.779-.289.865-.826.989-2.105.577-4.045a22.423 22.423 0 0 0-2.06-5.408c-2.019-1.197-3.666-1.404-4.902-.702-2.471 1.403-2.513 6.11-2.513 6.15a.329.329 0 0 1-.082.166l-6.426 5.078h-.001Z"
        />
        <path
          fill="#1E1C49"
          d="M54.175 16.884s-.205 1.363-1.112 1.363c0 0-1.277 3.303-4.284.372-3.007-2.931-.453-4.046-.453-4.046s-2.595-3.963 2.018-4.5c0 0 1.236-4.004 5.355-1.32 0 0 3.09-1.693 4.655 1.692 0 0 1.524 1.115.825 3.386-.7 2.27-3.46-.703-3.46-.703s.411 3.055-1.566 2.85c-1.977-.207-1.977.908-1.977.908v-.002Z"
        />
        <path
          fill="#fff"
          d="M55.411 17.546a1.61 1.61 0 0 0 1.607-1.61 1.608 1.608 0 1 0-3.214 0c0 .89.72 1.61 1.607 1.61Z"
        />
        <path
          fill="#1E1C49"
          d="M60.149 17.215a.453.453 0 1 0 0-.907.453.453 0 0 0 0 .907Z"
        />
        <path
          fill="#fff"
          d="M61.303 17.587s1.235-1.239 1.317-.207c.083 1.033-.988 1.9-.988 1.9l-.33-1.694Z"
        />
        <path
          fill="#1E1C49"
          d="m61.632 19.485-.164-.082a.246.246 0 0 1 .04-.288s.829-.372.705-1.28c0-.125-.042-.207-.082-.207s-.376-.206-.663.082a.208.208 0 0 1-.33-.15.21.21 0 0 1 .04-.14c.166-.205.623-.371 1.077-.205a.616.616 0 0 1 .37.578c.124 1.156-.828 1.61-.868 1.652l-.124.041h-.001ZM61.55 21.426a2.925 2.925 0 0 1-2.884-2.105.195.195 0 0 1-.002-.083.221.221 0 0 1 .031-.076.19.19 0 0 1 .06-.058.219.219 0 0 1 .076-.031c.123-.042.206.041.247.165a2.477 2.477 0 0 0 2.595 1.775.207.207 0 1 1 0 .415h-.123v-.002ZM54.34 20.56a11.02 11.02 0 0 0 1.896 1.568l-.989 1.94-.906-3.509Z"
        />
        <path
          fill={iconColor}
          d="M26.535 43.265S8.368 53.71 20.933 69.645l9.639 3.963-5.85 17.752s39.134 17.463 46.796 8.05c7.662-9.412 3.666-22.292-8.444-32.944-12.111-10.65-36.54-23.2-36.54-23.2Z"
        />
        <path
          fill="#fff"
          d="M71.436 57.384s12.893-4.5 13.923-3.013c1.03 1.485-2.39 3.467-2.39 3.467s11.7 1.941 13.265 8.01c1.565 6.068-8.856-3.18-8.856-3.18s2.677 1.817 1.236 5.244c0 0 .494 3.508-2.843 2.89 0 0 3.336 3.673-1.194 3.92-2.843.166-2.513-1.485-2.513-1.485l-13.388-3.344 2.759-12.509h.001ZM19.861 48.59s7.415-11.476 11.947-17.628c8.65-11.683 18.62-9.784 23.026-.454 4.408 9.33 0 32.861 0 32.861s-14.623.909-23.233-6.852c-2.554-2.313-7.25-8.96-11.74-7.927Z"
        />
        <path
          fill="#1E1C49"
          d="M53.517 63.576c-3.79 0-14.995-.537-22.08-6.937-.536-.494-1.195-1.196-1.937-1.98-2.595-2.808-6.179-6.647-9.598-5.904a.164.164 0 0 1-.207-.083.163.163 0 0 1 0-.206c.082-.124 7.456-11.518 11.946-17.628 4.49-6.11 9.145-8.587 13.8-8.133 3.954.372 7.456 3.22 9.556 7.72 2.101 4.5 2.39 10.65 1.648 20.022a127.033 127.033 0 0 1-1.648 12.963.163.163 0 0 1-.164.166h-1.318.002ZM20.809 48.26c3.296 0 6.55 3.509 8.98 6.11.741.784 1.401 1.485 1.936 1.981 7.992 7.183 21.298 6.894 22.904 6.812.765-4.22 1.302-8.479 1.606-12.756.742-9.166.166-16.018-1.606-19.817-1.771-3.798-5.397-7.1-9.228-7.471-4.531-.455-9.269 2.352-13.43 7.967C27.812 36.7 21.633 46.237 20.274 48.3l.535-.04Z"
        />
        <path
          fill="#fff"
          d="M52.115 34.306c.783 3.303 1.154 8.34.577 15.894l19.98 6.317L68.388 70.8s-12.235-1.404-21.462-5.573a17.358 17.358 0 0 1-7.296-6.276 17.4 17.4 0 0 1-2.838-9.205c-.082-4.417.124-10.197 1.525-14.739l13.8-.702h-.002Z"
        />
        <path
          fill="#1E1C49"
          d="M68.388 71.007c-.124-.041-12.358-1.444-21.545-5.573a17.593 17.593 0 0 1-7.384-6.362 17.65 17.65 0 0 1-2.874-9.325c-.082-4.17.124-10.155 1.525-14.78.042-.123.164-.206.248-.165a.287.287 0 0 1 .164.289c-1.442 4.583-1.607 10.487-1.524 14.656a17.165 17.165 0 0 0 2.82 9.095 17.103 17.103 0 0 0 7.23 6.18c8.487 3.88 19.691 5.367 21.216 5.531l4.16-13.912-19.814-6.233a.208.208 0 0 1-.124-.206c.536-6.689.33-12.014-.576-15.854-.042-.124.041-.206.164-.248.124-.04.206.042.247.166.907 3.84 1.112 9.123.577 15.77l19.814 6.274a.215.215 0 0 1 .135.089.213.213 0 0 1 .03.16l-4.284 14.284a.291.291 0 0 1-.205.164ZM85.771 71.007h-.082l-2.513-1.114a.244.244 0 0 1-.09-.13.244.244 0 0 1 .008-.159.205.205 0 0 1 .108-.085.2.2 0 0 1 .139.002l2.554 1.115a.25.25 0 0 1 .082.29.207.207 0 0 1-.206.081ZM88.614 68.117a.207.207 0 0 1-.207-.206c-.165-2.023-2.636-2.643-2.678-2.643a.246.246 0 0 1-.123-.288c0-.084.123-.166.247-.124.124.04 2.76.7 2.966 3.055a.208.208 0 0 1-.207.206h.002Z"
        />
        <path
          fill="#fff"
          d="m30.572 73.607-11.494-4.375a3.615 3.615 0 0 0-4.016 1c-.323.37-.57.802-.72 1.27L8.367 89.337a9.513 9.513 0 0 0 1.294 8.558 9.477 9.477 0 0 0 7.687 3.95h4.284a.91.91 0 0 0 .866-1.074c-.247-1.156-1.194-2.889-4.572-3.467l6.797-5.945 5.85-17.752h-.002Z"
        />
        <path
          fill="#1E1C49"
          d="M21.632 102.053h-4.284a9.634 9.634 0 0 1-7.872-4.007 9.676 9.676 0 0 1-1.315-8.75l5.973-17.834a3.832 3.832 0 0 1 5.026-2.436l11.493 4.376a.246.246 0 0 1 .123.289.245.245 0 0 1-.287.083l-11.452-4.377a3.453 3.453 0 0 0-3.83.976c-.311.353-.55.766-.702 1.213L8.532 89.42a9.298 9.298 0 0 0 4.587 11.207 9.258 9.258 0 0 0 4.229 1.013h4.284a.666.666 0 0 0 .537-.248.696.696 0 0 0 .123-.578c-.247-1.198-1.194-2.766-4.408-3.303a.33.33 0 0 1-.165-.164c0-.084 0-.166.082-.208l6.798-5.944a.247.247 0 0 1 .288.042.206.206 0 0 1 0 .288l-6.508 5.656c3.254.66 4.119 2.519 4.326 3.551a1.038 1.038 0 0 1-.206.908 1.024 1.024 0 0 1-.865.413h-.002Z"
        />
        <path
          fill="#1E1C49"
          d="M23.65 92.516c-.082 0-.165-.04-.165-.123l-1.936-4.047a.215.215 0 0 1-.005-.173.21.21 0 0 1 .128-.115.164.164 0 0 1 .156-.006.165.165 0 0 1 .09.13l1.937 4.045a.243.243 0 0 1-.01.167.252.252 0 0 1-.113.122h-.083.002ZM21.88 94.043a.208.208 0 0 1-.166-.124l-2.1-4.418a.281.281 0 0 1 .082-.288.205.205 0 0 1 .244.046c.02.022.035.049.044.078l2.1 4.416a.29.29 0 0 1-.082.29h-.124.002ZM56.36 16.514h-.124l-1.484-1.197a.204.204 0 0 1-.075-.216.191.191 0 0 1 .035-.073.247.247 0 0 1 .289-.04l1.483 1.197c.124.041.124.165.041.29a.212.212 0 0 1-.164.04l-.002-.001Z"
        />
        <path
          fill="#1E1C49"
          d="M55.082 16.762h-.124a.205.205 0 0 1-.082-.248l.453-.785a.219.219 0 0 1 .127-.101.214.214 0 0 1 .224.072.213.213 0 0 1 .02.235l-.454.743c-.042.042-.082.082-.165.082v.002ZM63.156 70.181h-.042a.197.197 0 0 1-.077-.043.206.206 0 0 1-.046-.245L67.4 54.866a.293.293 0 0 1 .119-.129.28.28 0 0 1 .17-.036.25.25 0 0 1 .124.289l-4.448 15.027a.283.283 0 0 1-.084.107.282.282 0 0 1-.122.057h-.002ZM82.805 55.568a1.808 1.808 0 0 1-1.277-.372 1.03 1.03 0 0 1-.289-.785.288.288 0 0 1 .246-.165c.124.042.206.125.166.249a.45.45 0 0 0 .165.412c.206.165.865.455 2.677 0 .083-.041.206.042.248.124.041.083-.042.248-.164.248-.574.18-1.17.278-1.772.289ZM82.97 58.045h-.042c-1.565-.33-4.901-.372-4.943-.372a.209.209 0 0 1-.146-.06.211.211 0 0 1-.06-.146c0-.055.02-.107.06-.146.038-.04.09-.06.146-.06.165 0 3.42.041 5.025.371.124.04.206.165.165.247-.042.083-.082.166-.206.166ZM84.289 73.815h-.042l-5.149-1.073a.256.256 0 0 1-.123-.096.255.255 0 0 1-.042-.152c.042-.124.124-.206.247-.166l5.15 1.074c.082 0 .165.124.165.248a.29.29 0 0 1-.206.165ZM57.719 83.68h-.042L30.49 73.774a.208.208 0 0 1-.124-.248.208.208 0 0 1 .204-.145c.03 0 .058.007.085.021l27.147 9.908a.159.159 0 0 1 .076.029c.022.016.04.036.053.06a.172.172 0 0 1-.005.159.205.205 0 0 1-.206.124h-.001ZM17.925 97.511h-.042l-5.808-1.073a.175.175 0 0 1-.09-.012.167.167 0 0 1-.075-.235c0-.083.124-.166.206-.166l5.85 1.074a.166.166 0 0 1 .16.071.171.171 0 0 1 .027.088.175.175 0 0 1-.022.089.208.208 0 0 1-.207.165ZM95.164 67.168h-.124c-1.194-1.032-1.689-1.94-1.524-2.683.164-.744 1.318-1.239 1.36-1.239a.207.207 0 0 1 .247.124c.042.124-.041.248-.124.248-.082 0-.947.289-1.112.95-.165.66.371 1.362 1.401 2.27a.206.206 0 0 1 .04.288l-.164.042Z"
        />
        <path
          fill="#E63535"
          d="M113.082 84.012a3.63 3.63 0 0 0 3.625-3.633 3.63 3.63 0 0 0-3.625-3.633 3.628 3.628 0 0 0-3.624 3.633 3.629 3.629 0 0 0 3.624 3.633ZM133.926 23.89a1.814 1.814 0 0 0 1.813-1.817 1.813 1.813 0 1 0-3.624 0c0 1.004.811 1.817 1.811 1.817Z"
        />
        <path
          fill="#E67A00"
          d="M139.364 23.89a1.814 1.814 0 0 0 1.813-1.817 1.813 1.813 0 1 0-3.624 0c0 1.004.811 1.817 1.811 1.817Z"
        />
        <path
          fill="#004FC4"
          d="M144.801 23.89a1.815 1.815 0 0 0 1.813-1.817 1.815 1.815 0 0 0-1.813-1.816 1.813 1.813 0 0 0-1.811 1.816c0 1.004.811 1.817 1.811 1.817Z"
        />
        <path
          fill="#1E1C49"
          d="M150.117 23.89a1.814 1.814 0 0 0 1.812-1.817 1.814 1.814 0 0 0-1.812-1.816 1.813 1.813 0 0 0-1.812 1.816c0 1.004.811 1.817 1.812 1.817ZM127.696 79.117H75.165V11.539h52.531v67.578Zm-51.998-.535h51.465V12.075H75.698v66.508Z"
        />
        <path
          fill="#1E1C49"
          d="M84.165 74.93c-.948 0-1.607-.207-1.977-.661a1.196 1.196 0 0 1-.33-.867L68.636 70.1c-.124-.04-.206-.164-.164-.248.041-.082.124-.206.247-.164l13.388 3.385c.124 0 .166.124.166.206a.913.913 0 0 0 .205.743c.371.372 1.07.537 2.06.496.989-.042 1.937-.372 2.142-.909.206-.536-1.03-2.683-1.03-2.683a.209.209 0 0 1-.063-.116.209.209 0 0 1 .022-.132.163.163 0 0 1 .206-.082c.906.165 1.565.04 2.018-.372.453-.412.577-2.27.577-2.27v-.124c.988-2.312.041-3.84-.66-4.54l-.534-.455c-.082-.083-.082-.166 0-.29.082-.123.165-.082.247-.04.192.117.36.272.495.454 2.719 2.311 6.88 5.325 7.95 4.664.411-.248.289-1.073.124-1.733-1.524-5.862-12.977-7.803-13.059-7.844a.277.277 0 0 1-.123-.058.302.302 0 0 1-.083-.107.21.21 0 0 1 .124-.207s2.142-1.32 2.43-2.518a.788.788 0 0 0-.165-.66c-.33-.538-4.367-.124-13.677 3.096a.164.164 0 0 1-.248-.124.208.208 0 0 1 .124-.248c2.184-.785 13.1-4.5 14.17-2.972a1.205 1.205 0 0 1 .165.99c-.206 1.033-1.483 2.023-2.142 2.52 1.897.372 3.757.91 5.561 1.608 4.12 1.652 6.674 3.881 7.333 6.44.289 1.157.206 1.859-.33 2.147-1.277.826-5.025-1.94-7.25-3.755a4.536 4.536 0 0 1-.042 3.755c.042.29.124 1.735-.7 2.56a2.309 2.309 0 0 1-1.853.537c.453.619 1.153 1.775.782 2.725-.37.949-1.112 1.073-2.471 1.155h-.412Z"
        />
        <path
          fill="#E63535"
          d="M204.868 112.667a5.022 5.022 0 0 1-3.131-1.032c-.082-.042-.082-.207-.041-.289a.25.25 0 0 1 .289-.041c1.318.991 3.089 1.197 4.861.579 1.771-.62 3.583-2.271 4.037-4.17.906-3.551-1.401-7.637-5.191-9.082a20.167 20.167 0 0 0-4.737-.99 21.414 21.414 0 0 1-2.843-.413c-3.213-.66-5.478-2.353-6.097-4.582a2.937 2.937 0 0 1-.123-1.156c-2.059-.826-4.078-2.353-5.932-4.458l-1.318-1.61c-1.483-1.817-3.048-3.716-5.191-4.748-2.142-1.032-5.725-.908-8.239-.66-.123.041-.205-.042-.247-.165 0-.059.02-.116.058-.162a.24.24 0 0 1 .149-.086c2.554-.248 5.766-.536 8.485.702 2.719 1.239 3.79 3.013 5.314 4.872.412.536.865 1.073 1.318 1.568a16.035 16.035 0 0 0 5.686 4.335.18.18 0 0 0 .033-.057c.007-.022.009-.044.007-.066.619-1.487 2.801-1.404 3.502-1.404.7 0 1.812.412 2.142 1.032a.905.905 0 0 1 0 .908 1.674 1.674 0 0 1-1.194.619 9.127 9.127 0 0 1-4.161-.496c-.037.309.005.62.124.908.577 2.065 2.719 3.674 5.766 4.294.925.197 1.86.336 2.802.412 1.606.206 3.295.413 4.86 1.032 3.955 1.528 6.386 5.821 5.438 9.578-.495 1.981-2.183 3.715-4.326 4.458a6.48 6.48 0 0 1-2.1.372v-.002Zm-12.482-21.468c1.284.5 2.671.671 4.037.496a1.157 1.157 0 0 0 .865-.412.463.463 0 0 0 .095-.242.46.46 0 0 0-.054-.254c-.247-.454-1.153-.785-1.812-.826-1.73-.042-2.76.33-3.089 1.156l-.042.082Z"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M.5 0h211v130H.5z" />
        </clipPath>
      </defs>
    </Box>
  );
};
