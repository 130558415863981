import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

export const SelectItem = forwardRef(
  ({ value, label, icon, ...props }, ref) => {
    const { t } = useTranslation('templates');

    return (
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.other.spacing(0.5),
        })}
        ref={ref}
        value={value}
        {...props}
      >
        {icon} {t(label)}
      </Box>
    );
  },
);
