import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS, HTTP_HEADERS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { postTransaction } from '../requests';
import { PostTransactionRequestType } from '../types';

export const usePostTransaction = ({
  headers,
  pathParams: { dataStreamId, transactionId },
}: {
  headers: PostTransactionRequestType['headers'];
  pathParams: Omit<PostTransactionRequestType['pathParams'], 'organisationId'>;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: () =>
      postTransaction({
        pathParams: { organisationId, dataStreamId, transactionId },
        headers: {
          [HTTP_HEADERS.xTransactionEntryId]:
            headers[HTTP_HEADERS.xTransactionEntryId],
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.transactions],
      });
    },
  });
};
