import { useTranslation } from 'react-i18next';
import { ActionIcon, Box, CopyButton, Sx, Tooltip } from '@mantine/core';

import { CopyIcon } from 'icons';

type Props = {
  code: string;
  withCopy?: boolean;
  sx?: Sx;
};

export const CodeBlock = ({ code, withCopy = true, sx }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.other.spacing(1),
          padding: theme.other.spacing(0.75, 2),
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.sm,
          backgroundColor: theme.colors.gray[1],
        }),
        sx,
      ]}
    >
      <Box
        sx={theme => ({
          whiteSpace: 'pre-wrap',
          fontSize: theme.fontSizes.md,
          lineHeight: '16px',
          color: theme.colors.gray[6],
        })}
        component="pre"
      >
        {code}
      </Box>
      {withCopy && (
        <CopyButton value={code}>
          {({ copied, copy }) => (
            <Tooltip
              opened={copied}
              label={t('common:copied')}
              withArrow={false}
            >
              <ActionIcon sx={{ flexShrink: 0 }} onClick={copy}>
                <CopyIcon color="black" />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      )}
    </Box>
  );
};
