import { useGetCurrentOrganisationId } from 'hooks';

import { useGetOrganisation } from './useGetOrganisation';

export const useGetCurrentOrganisation = () => {
  const organisationId = useGetCurrentOrganisationId();

  return useGetOrganisation({
    pathParams: {
      organisationId,
    },
  });
};
