export const toggleMarkdown = (editor, markType) => {
  if (editor.value.selection.isExpanded) {
    editor.toggleMark(markType);

    /* TODO: check if it should be moved above the isExpanded condition */
    editor.value.inlines
      .filter(inline => inline.type === 'variable' || inline.type === 'input')
      .forEach(inline => {
        editor.updateNodeData(inline, {
          [markType]: !inline.data.get(markType),
        });
      });
  }
};
