import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';

import { usePatchTechnicalUser } from 'api/technicalUsers';
import { TechnicalUserType } from 'types';
import { DeleteIcon } from 'icons';
import { useFormatUserDate, useServerErrors, useShowNotification } from 'hooks';

import { Modal } from 'components/shared';

import { BearerTokensForNewlyCreatedTechnicalUsersType } from '../../types';
import { CodeBlock } from '../CodeBlock';

type Props = {
  bearerToken: TechnicalUserType['bearerToken'];
  isOpen: boolean;
  onClose: () => void;
  hasUpdateTechnicalUserAccess: boolean;
  technicalUserId: TechnicalUserType['id'];
  visibleBearerToken: TechnicalUserType['bearerToken'];
  setBearerTokensForNewlyCreatedTechnicalUsers: Dispatch<
    SetStateAction<BearerTokensForNewlyCreatedTechnicalUsersType>
  >;
};

export const BearerTokenModal = ({
  hasUpdateTechnicalUserAccess,
  isOpen,
  onClose,
  technicalUserId,
  bearerToken,
  visibleBearerToken,
  setBearerTokensForNewlyCreatedTechnicalUsers,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const { addServerErrorActions } = useServerErrors();
  const showNotification = useShowNotification();
  const formatUserDate = useFormatUserDate();
  const [bearerTokenData, setBearerTokenData] = useState<
    TechnicalUserType['bearerToken'] | null
  >(null);

  const {
    mutateAsync: updateTechnicalUser,
    isLoading: isUpdatingTechnicalUser,
  } = usePatchTechnicalUser({
    pathParams: {
      technicalUserId: String(technicalUserId),
    },
  });

  const handleClose = () => {
    onClose();
    setBearerTokenData(null);

    if (visibleBearerToken) {
      setBearerTokensForNewlyCreatedTechnicalUsers(
        newlyCreatedTechnicalUsers => {
          const newlyCreatedTechnicalUsersToUpdate = {
            ...newlyCreatedTechnicalUsers,
          };

          delete newlyCreatedTechnicalUsersToUpdate[technicalUserId];

          return newlyCreatedTechnicalUsersToUpdate;
        },
      );
    }
  };

  const generateNewBearerToken = async () => {
    try {
      const data = await updateTechnicalUser({
        bearerToken: 'rotate',
      });

      setBearerTokenData(data.bearerToken);
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  const deleteBearerToken = async () => {
    try {
      await updateTechnicalUser({
        bearerToken: 'delete',
      });
      handleClose();
      showNotification({
        message: t('api:bearerTokenModal.deleted'),
      });
    } catch (error) {
      addServerErrorActions(error);
    }
  };

  const hasToken = Boolean(bearerToken?.token && bearerToken?.expiresAt);
  const hasVisibleBearerToken = Boolean(bearerTokenData);

  useEffect(() => {
    if (visibleBearerToken) {
      setBearerTokenData(visibleBearerToken);
    }
  }, [bearerToken, technicalUserId, visibleBearerToken]);

  // eslint-disable-next-line consistent-return
  const getDefaultButtonText = () => {
    if (hasUpdateTechnicalUserAccess) {
      return hasToken
        ? t('api:bearerTokenModal.generateNew')
        : t('api:bearerTokenModal.generateToken');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={hasToken ? 'lg' : 'md'}
      title={t('api:bearerTokenModal.header')}
      defaultButtonText={t('common:close')}
      defaultButtonAction={handleClose}
      primaryButtonText={getDefaultButtonText()}
      primaryButtonAction={
        hasUpdateTechnicalUserAccess ? generateNewBearerToken : undefined
      }
      primaryButtonColor="green"
      isFullLoading={isUpdatingTechnicalUser}
      fullLoadingText={t('api:bearerTokenModal.generatingBearerToken')}
    >
      {hasToken ? (
        <>
          {hasVisibleBearerToken && (
            <Text size="md" weight={400}>
              <Trans
                i18nKey="api:bearerTokenModal.expirationInfo"
                components={{ b: <b /> }}
              />
            </Text>
          )}
          <CodeBlock
            sx={theme => ({
              ...theme.other.getFontSizeStyles('sm'),
              marginBottom: theme.other.spacing(2),
            })}
            code={bearerTokenData?.token || (bearerToken.token as string)}
            withCopy={hasVisibleBearerToken}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Text
              sx={theme => ({
                marginRight: theme.other.spacing(1.5),
              })}
              size="md"
              weight={400}
            >
              {t('api:bearerTokenModal.expiresAt')}{' '}
              <b>
                {formatUserDate(
                  bearerTokenData?.expiresAt ??
                    (bearerToken.expiresAt as string),
                )}
              </b>
            </Text>
            <Button
              color="red"
              leftIcon={<DeleteIcon color="white" />}
              onClick={deleteBearerToken}
              loading={isUpdatingTechnicalUser}
            >
              {t('common:delete')}
            </Button>
          </Box>
        </>
      ) : (
        <Text size="md" weight={400}>
          <Trans
            i18nKey="api:bearerTokenModal.generateInstruction"
            values={{
              buttonName: t('api:bearerTokenModal.generateToken'),
            }}
            components={{ b: <b /> }}
          />
        </Text>
      )}
    </Modal>
  );
};
