import { ValidityType } from '../../types';

export const VALIDITY_OPTIONS: {
  value: ValidityType;
  labelValue: number;
  labelSuffixTranslation: string;
}[] = [
  {
    value: 30,
    labelValue: 30,
    labelSuffixTranslation: 'api:certificateValidityDays',
  },
  {
    value: 60,
    labelValue: 60,
    labelSuffixTranslation: 'api:certificateValidityDays',
  },
  {
    value: 90,
    labelValue: 90,
    labelSuffixTranslation: 'api:certificateValidityDays',
  },
  {
    value: 180,
    labelValue: 180,
    labelSuffixTranslation: 'api:certificateValidityDays',
  },
  {
    value: 365,
    labelValue: 1,
    labelSuffixTranslation: 'api:certificateValidityYear',
  },
];
