import {
  Box,
  BoxProps,
  createPolymorphicComponent,
  packSx,
} from '@mantine/core';

export const PercentageContainer = createPolymorphicComponent<'div', BoxProps>(
  ({ sx, ...boxProps }: BoxProps) => (
    <Box
      sx={[
        theme => ({
          [`@media (min-width: ${theme.breakpoints.md}px)`]: {
            width: '75%',
          },
          [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            width: '50%',
          },
        }),
        ...packSx(sx),
      ]}
      {...boxProps}
    />
  ),
);
