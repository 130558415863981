import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Checkbox, Text } from '@mantine/core';

import { useGetUser } from 'api/account';
import { MailIcon, PhoneIcon } from 'icons';
import { URLS } from 'consts';
import { UserType } from 'types';
import { TextInput } from 'components/shared';

type Props = {
  withCustomFooter: boolean;
  updateWithCustomFooter: (withCustomFooter: boolean) => void;
};

export const MailFooter = ({
  withCustomFooter,
  updateWithCustomFooter,
}: Props) => {
  const { t } = useTranslation(['common', 'organisations']);

  const { data: user } = useGetUser();
  const { name, surname, phone, email } = user as UserType;

  return (
    <div>
      <Checkbox
        label={t('organisations:wantToAddFooterToEmail')}
        checked={withCustomFooter}
        onChange={e => {
          updateWithCustomFooter(e.target.checked);
        }}
      />
      {withCustomFooter && (
        <>
          <Text
            sx={theme => ({
              marginTop: theme.other.spacing(2),
            })}
            weight={600}
          >
            {t('organisations:yourData')}
          </Text>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
            size="md"
            weight={400}
          >
            <Trans
              i18nKey="organisations:yourDataDescription"
              components={{
                a: <Anchor component={Link} to={URLS.account} />,
              }}
            />
          </Text>
          <Box sx={{ maxWidth: 360 }}>
            <TextInput
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              label={t('common:name')}
              value={name}
              disabled
              withErrorWrapperAlwaysEnabled={false}
            />
            <TextInput
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              label={t('common:surname')}
              value={surname}
              disabled
              withErrorWrapperAlwaysEnabled={false}
            />
            <TextInput
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              label={t('common:phoneNumber')}
              value={phone || ''}
              disabled
              withErrorWrapperAlwaysEnabled={false}
              rightIcon={<PhoneIcon />}
            />
            <TextInput
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              label={t('common:email2')}
              value={email}
              disabled
              withErrorWrapperAlwaysEnabled={false}
              rightIcon={<MailIcon />}
            />
          </Box>
        </>
      )}
    </div>
  );
};
