import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Radio, Text } from '@mantine/core';

const reasons = [
  {
    value: 'dontNeed',
    translation: 'user:deleteAccountReasons.dontNeed',
  },
  {
    value: 'electronicSignatureIsEnough',
    translation: 'user:deleteAccountReasons.electronicSignatureIsEnough',
  },
  {
    value: 'dontWorkWithOrganisation',
    translation: 'user:deleteAccountReasons.dontWorkWithOrganisation',
  },
  {
    value: 'tooExpensive',
    translation: 'user:deleteAccountReasons.tooExpensive',
  },
  {
    value: 'anotherTool',
    translation: 'user:deleteAccountReasons.anotherTool',
  },
  {
    value: 'other',
    translation: 'user:deleteAccountReasons.other',
  },
];

type Props = {
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
};

export const ChooseReason = ({ reason, setReason }: Props) => {
  const { t } = useTranslation('user');

  return (
    <div>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        size="md"
        weight={600}
      >
        {t('user:deleteAccountReasonQuestion')}
      </Text>
      {reasons.map(({ value, translation }) => (
        <Radio
          key={value}
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          label={t(translation)}
          checked={reason === value}
          onChange={() => {
            setReason(value);
          }}
        />
      ))}
    </div>
  );
};
