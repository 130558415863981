import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';
import { useGetV1Url } from 'hooks';
import { TemplatesIcon } from 'icons';
import {
  SectionHeader,
  StickyContent,
  TextInputReactRouterSearch,
} from 'components/shared';

type Props = {
  hasTemplatesCreateAccess: boolean;
};

export const Header = ({ hasTemplatesCreateAccess }: Props) => {
  const { t } = useTranslation('templates');

  const getV1Url = useGetV1Url();

  return (
    <StickyContent hasPageHeaderSpacings>
      <SectionHeader icon={<TemplatesIcon />}>
        {t('templates:templates')}
      </SectionHeader>
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(4),
        })}
        size="md"
      >
        {t('templates:pageDescription')}
      </Text>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {hasTemplatesCreateAccess && (
          <Button color="green" component="a" href={getV1Url('templatesAdd')}>
            {t('templates:createTemplate')}
          </Button>
        )}
        <TextInputReactRouterSearch
          sx={{
            maxWidth: 328,
            width: '100%',
            marginLeft: 'auto',
          }}
          placeholder={t('templates:searchTemplate')}
        />
      </Box>
    </StickyContent>
  );
};
