import { useMutation } from '@tanstack/react-query';

import { postAuthLoginMethods } from '../requests';
import { PostAuthLoginMethodsRequestType } from '../types';

// Hook uses a POST request but in fact it gets auth login methods
export const useGetAuthLoginMethods = () =>
  useMutation({
    mutationFn: (bodyParams: PostAuthLoginMethodsRequestType['bodyParams']) =>
      postAuthLoginMethods({
        bodyParams,
      }),
  });
