import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { TransactionsIcon } from 'icons';
import { SectionHeader, StickyContent } from 'components/shared';

import { Filters } from './Filters';

type Props = {
  toggleFilters: () => void;
};

export const TransactionsHeader = ({ toggleFilters }: Props) => {
  const { t } = useTranslation('transactions');

  return (
    <StickyContent
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      hasPageHeaderSpacings
    >
      <Box sx={theme => ({ marginRight: theme.other.spacing(2) })}>
        <SectionHeader icon={<TransactionsIcon />}>
          {t('transactions:transactionList')}
        </SectionHeader>
        <Text size="md">{t('transactions:transactionListDescription')}</Text>
      </Box>
      <Filters toggleFilters={toggleFilters} />
    </StickyContent>
  );
};
