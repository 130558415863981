import { ComponentProps, ReactNode, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import ReactPhoneInput from 'react-phone-input-2';

import { getInputStyles } from 'theme/components/Input';
import {
  getDescriptionStyles,
  getLabelStyles,
} from 'theme/components/InputWrapper';
import { FieldErrorMessage } from 'components/shared';

import pl from 'react-phone-input-2/lang/pl.json';

import { ChevronDownIcon } from './assets';

const SELECTED_FLAG_WIDTH = 46;

type Props = {
  label?: ReactNode;
  description?: ReactNode;
  error?: ReactNode;
  withErrorWrapperAlwaysEnabled?: boolean;
  inputProps?: object;
} & ComponentProps<typeof ReactPhoneInput>;

export const PhoneInput = ({
  label,
  description,
  error,
  withErrorWrapperAlwaysEnabled = true,
  inputProps = {},
  ...reactPhoneInputProps
}: Props) => {
  const {
    i18n: { language },
  } = useTranslation();

  const inputId = useId();

  return (
    <div>
      {label && (
        <Box
          sx={theme => getLabelStyles(theme)}
          component="label"
          htmlFor={inputId}
        >
          {label}
        </Box>
      )}
      <Box
        sx={theme => {
          const inputFocusRingStyles = theme.focusRingStyles.inputStyles(theme);
          const inputStyles = getInputStyles(theme);

          return {
            '&&&': {
              fontFamily: 'inherit',
            },
            '&&& .form-control': {
              ...{
                fontFamily: 'inherit',
                ...inputStyles,
              },
              width: 'calc(100% - 1px)',
              left: 1,
              paddingLeft: theme.other.spacing(7),
              borderRadius: theme.radius.sm,
              '&: focus-visible': {
                ...inputFocusRingStyles,
                '+ .flag-dropdown': {
                  borderRight: `1px solid ${inputFocusRingStyles.borderColor}`,
                },
              },
            },
            '&&& .flag-dropdown': {
              borderColor: inputStyles.borderColor,
              borderRadius: `${theme.radius.sm}px 0 0 ${theme.radius.sm}px`,
              backgroundColor: theme.white,
            },
            '&&& .selected-flag': {
              width: SELECTED_FLAG_WIDTH,
              '&: focus-visible': {
                height: 'calc(100% + 2px)',
                top: -1,
                border: `1px solid ${inputFocusRingStyles.borderColor}`,
              },
            },
            '&&& .arrow': {
              width: 16,
              height: 16,
              margin: 'auto',
              top: -2,
              bottom: 0,
              background: `url(${ChevronDownIcon}) no-repeat center`,
              border: 'none',
              transition: `transform ${theme.other.animationsTime} ease`,
              transform: 'rotate(0)',
              '&.up': {
                border: 'none',
                transition: `transform ${theme.other.animationsTime} ease 0s`,
                transform: 'rotate(180deg)',
              },
            },
            '.country': {
              fontSize: theme.fontSizes.sm,
            },
            '.flag': {
              position: 'relative',
              top: 2,
            },
          };
        }}
        component={ReactPhoneInput}
        country="pl"
        localization={language === 'pl' ? pl : undefined}
        placeholder="+48 123 456 789"
        inputProps={{ id: inputId, ...inputProps }}
        {...reactPhoneInputProps}
      />
      {description && (
        <Box
          sx={theme => ({
            ...getDescriptionStyles(theme),
            margin: theme.other.spacing(0.5, 0, 0),
          })}
          component="p"
        >
          {description}
        </Box>
      )}
      {(error || withErrorWrapperAlwaysEnabled) && (
        <FieldErrorMessage>{error}</FieldErrorMessage>
      )}
    </div>
  );
};
