import { useTranslation } from 'react-i18next';
import { Paper } from '@mantine/core';

const StaticTable = ({ attributes, isActive }) => {
  const { t } = useTranslation('templates');

  return (
    <Paper
      {...attributes}
      sx={theme => {
        const activeBorderColor =
          theme.focusRingStyles.inputStyles(theme).borderColor;

        return {
          margin: theme.other.spacing(2, 0),
          cursor: 'pointer',
          border: `1px solid ${isActive ? activeBorderColor : 'transparent'}`,
          boxSizing: 'border-box',
          userSelect: 'none',
        };
      }}
    >
      {t('templates:hereYouCanPasteTable')}
    </Paper>
  );
};

export const staticTable = {
  onKeyDown: (e, editor, next) => {
    if (
      e.key === 'Enter' &&
      editor.value.blocks.some(block => block.type === 'staticTable')
    ) {
      e.preventDefault();

      editor.insertBlock('paragraph');

      return true;
    }

    return next();
  },
  renderNode: ({ attributes, node, isSelected, isFocused }, editor, next) => {
    if (node.type === 'staticTable') {
      return (
        <StaticTable
          attributes={attributes}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    blocks: {
      staticTable: {
        isVoid: true,
      },
    },
  },
};
