import { Trans, useTranslation } from 'react-i18next';
import { Anchor, Text } from '@mantine/core';

import { ApiIcon } from 'icons';
import { STATIC_URLS } from 'consts';

import { SectionHeader } from 'components/shared';

export const DetailsHeader = () => {
  const { t } = useTranslation('api');

  return (
    <div>
      <SectionHeader icon={<ApiIcon />}>{t('api:details')}</SectionHeader>
      <Text size="md" sx={{ lineHeight: '24px' }}>
        <Trans
          i18nKey="api:integrationProblems"
          components={{
            a: <Anchor href={STATIC_URLS.contact} target="_blank" />,
          }}
        />
      </Text>
    </div>
  );
};
