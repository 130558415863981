import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Text } from '@mantine/core';

import {
  useGetExternalTransactionEntryDebug,
  useGetTransactionEntryDebug,
} from 'api/transactionEntries';
import { DataWrapper } from 'components/shared';

import { JsonViewer } from '../../JsonViewer';

export const DetailsPanel = () => {
  const { t } = useTranslation('transactions');

  const { transactionDataStream, transactionId, transactionEntry } =
    useParams() as {
      transactionDataStream: string;
      transactionId: string;
      transactionEntry: string;
    };

  const {
    data: entry,
    isError: isEntryError,
    isLoading: isEntryLoading,
  } = useGetTransactionEntryDebug({
    pathParams: {
      transactionId,
      dataStream: transactionDataStream,
      transactionEntry,
    },
  });

  const {
    data: externalEntry,
    isError: isExternalEntryError,
    isInitialLoading: isExternalEntryInitialLoading,
  } = useGetExternalTransactionEntryDebug(
    {
      url: entry?.payload || '',
    },
    {
      enabled: Boolean(entry?.payload),
    },
  );

  return (
    <DataWrapper
      data={entry}
      isError={isEntryError || isExternalEntryError}
      isLoading={isEntryLoading || isExternalEntryInitialLoading}
    >
      {() => (
        <div>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
            size="sm"
          >
            {t('transactions:transactionEntries.expiration', {
              hours: 48,
            })}
          </Text>
          {externalEntry && <JsonViewer value={externalEntry} />}
        </div>
      )}
    </DataWrapper>
  );
};
