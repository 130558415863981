import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import { GetDocumentsRequestType, GetDocumentsResponseType } from '../types';

export const getDocuments = async ({
  queryParams: {
    boardId,
    page,
    search,
    perPage,
    transactionDataStream,
    transactionId,
  },
}: GetDocumentsRequestType) => {
  const query = stringifyUrlQuery({
    boardId,
    'transaction[id]': transactionId,
    'transaction[data_stream]': transactionDataStream,
    page,
    perPage,
    name: search,
  });
  const url = `/api/v2/documents/${query}`;
  const { data } = await apiAuthClient.get<GetDocumentsResponseType>(url);

  return data;
};
