import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS, NOTIFICATION_TYPES } from 'consts';
import { MappedUserNotificationListItemType } from 'types';

import { getUserNotifications } from '../requests';
import { GetUserNotificationsResponseType } from '../types';

export const useGetUserNotifications = () =>
  useQuery({
    queryKey: [API_QUERY_KEYS.userNotifications],
    queryFn: getUserNotifications,
    select: ({ data, ...rest }: GetUserNotificationsResponseType) => ({
      data: data.reduce(
        (accumulator, { type, channel, enabled }) => {
          const notificationType = NOTIFICATION_TYPES[type];

          if (notificationType) {
            if (!accumulator[notificationType]) {
              accumulator[notificationType] = {} as {
                mail: boolean;
                push: boolean;
              };
            }

            accumulator[notificationType][channel] = enabled;
          }

          return accumulator;
        },
        {} as { [key: string]: { mail: boolean; push: boolean } },
      ) as MappedUserNotificationListItemType,
      ...rest,
    }),
  });
