import { ReactNode } from 'react';
import { Box } from '@mantine/core';

type Props = {
  children: ReactNode;
  className?: string;
};

export const IconBox = ({ children, className }: Props) => (
  <Box
    className={className}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24,
    }}
  >
    {children}
  </Box>
);
