import { useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { useLocalStorage, usePrevious } from '@mantine/hooks';

import { useGetUser } from 'api/account';
import { useClearLoginData, useServerErrors } from 'hooks';
import { STORAGE_KEYS } from 'consts';
import { Auth } from 'components/Auth';

import { useLogout } from './hooks';

type Props = {
  children: JSX.Element;
};

export const AuthController = ({ children }: Props) => {
  const [token] = useLocalStorage({
    key: STORAGE_KEYS.token,
    serialize: value => value,
    getInitialValueInEffect: false,
  });

  const previousToken = usePrevious(token);
  const clearLoginData = useClearLoginData();
  const logout = useLogout();

  const { addServerErrorActions } = useServerErrors();
  const { isError, isLoading, refetch } = useGetUser({
    enabled: false,
    onError: addServerErrorActions,
  });

  useEffect(() => {
    if (token && token !== 'null') {
      refetch();
    }
  }, [token, refetch]);

  useEffect(() => {
    if ((!token || token === 'null') && previousToken !== undefined) {
      if (previousToken && previousToken !== 'null') {
        logout(previousToken);
      } else {
        clearLoginData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, logout, clearLoginData]);

  if (!token || token === 'null' || isError) {
    return <Auth />;
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};
