import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, Paper } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE } from 'consts';
import { LayoutMainBottomPaddingSpacingContext } from 'context';
import { useIsMdBreakpoint } from 'hooks';

import { ActionsBarPropsType } from '../types';
import { ActionItem } from '../ActionItem';

const DesktopViewInner = ({
  items,
  label,
  disabled: areAllActionsDisabled = false,
}: ActionsBarPropsType) => {
  const { t } = useTranslation('common');

  const [, setLayoutMainBottomPaddingSpacing] = useContext(
    LayoutMainBottomPaddingSpacingContext,
  );

  const isMdBreakpoint = useIsMdBreakpoint();

  const paperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const actionItemMoreWrapperRef = useRef<HTMLDivElement>(null);

  const [numberOfMainItemsToShow, setNumberOfMainItemsToShow] = useState<
    number | undefined
  >(undefined);

  const mainItems = items.slice(0, numberOfMainItemsToShow);
  const additionalItems =
    numberOfMainItemsToShow !== undefined && items.length > mainItems.length
      ? items.slice(numberOfMainItemsToShow)
      : [];

  const hasLabel = Boolean(label);
  const hasOnlyActionItemMoreVisible =
    numberOfMainItemsToShow === 0 && !hasLabel;

  useLayoutEffect(() => {
    const paper = paperRef.current;
    const list = listRef.current;
    const actionItemMoreWrapper = actionItemMoreWrapperRef.current;

    if (paper && list && actionItemMoreWrapper) {
      const availableSpace =
        paper.offsetWidth -
        parseFloat(getComputedStyle(paper).paddingLeft) -
        parseFloat(getComputedStyle(paper).paddingRight);
      const listHtmlItems = [...list.children];
      const actionsItemMoreWrapperWidth = actionItemMoreWrapper.offsetWidth;
      const hasSomeMainItemsToShow =
        (listHtmlItems[0] as HTMLLIElement).offsetWidth +
          actionsItemMoreWrapperWidth <
        availableSpace;

      if (hasSomeMainItemsToShow) {
        const {
          indexOfLastItemThatFitAvailableSpace,
          indexOfLastItemThatFitWithMoreButton,
        } = listHtmlItems.reduce(
          (accumulator, li, index) => {
            const currentWidthSum =
              accumulator.totalWidth + (li as HTMLLIElement).offsetWidth;
            const isFitWithMoreButton =
              currentWidthSum + actionsItemMoreWrapperWidth < availableSpace;

            return currentWidthSum < availableSpace
              ? {
                  indexOfLastItemThatFitAvailableSpace: index,
                  totalWidth: currentWidthSum,
                  indexOfLastItemThatFitWithMoreButton: isFitWithMoreButton
                    ? index
                    : accumulator.indexOfLastItemThatFitWithMoreButton,
                }
              : accumulator;
          },
          {
            indexOfLastItemThatFitAvailableSpace: 0,
            indexOfLastItemThatFitWithMoreButton: 0,
            totalWidth: 0,
          },
        );

        const lastIndex =
          indexOfLastItemThatFitWithMoreButton <
          indexOfLastItemThatFitAvailableSpace
            ? indexOfLastItemThatFitWithMoreButton
            : indexOfLastItemThatFitAvailableSpace;

        setNumberOfMainItemsToShow(lastIndex + (hasLabel ? 0 : 1));
      } else {
        setNumberOfMainItemsToShow(0);
      }
    }
  }, [hasLabel]);

  useEffect(() => {
    setLayoutMainBottomPaddingSpacing(isMdBreakpoint ? 10 : 0);

    return () => {
      setLayoutMainBottomPaddingSpacing(DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE);
    };
  }, [isMdBreakpoint, setLayoutMainBottomPaddingSpacing]);

  return (
    <Paper
      ref={paperRef}
      sx={theme => ({
        display: 'flex',
        flexWrap: numberOfMainItemsToShow === 0 ? 'wrap' : undefined,
        position: 'fixed',
        right: 0,
        bottom: 37,
        left: 0,
        margin: 'auto',
        width: 'fit-content',
        maxWidth: `calc(100% - ${theme.other.spacing(4)}px)`,
        zIndex: 101,
        borderRadius: theme.radius.md,
        '&': {
          paddingRight: 0,
          paddingLeft: hasOnlyActionItemMoreVisible
            ? 0
            : theme.other.spacing(3),
        },
      })}
    >
      <Box
        ref={listRef}
        sx={{
          display: 'flex',
        }}
        component="ul"
      >
        {Boolean(label) && (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
              marginRight: theme.other.spacing(2),
              padding: theme.other.spacing(0.75, 2),
              fontSize: theme.fontSizes.lg,
              fontWeight: 600,
            })}
            component="li"
          >
            {label}
          </Box>
        )}
        {mainItems.map(
          (
            { name, icon, color, isLoading, disabled, ...actionOrToProp },
            index,
          ) => (
            <li key={name}>
              <ActionItem
                {...actionOrToProp}
                name={name}
                icon={icon}
                color={color}
                disabled={areAllActionsDisabled || disabled}
                isLoading={isLoading}
                withSeparator={!(index === 0 && !label)}
              />
            </li>
          ),
        )}
      </Box>
      {(additionalItems.length > 0 ||
        numberOfMainItemsToShow === undefined) && (
        <Menu>
          <Menu.Target>
            <div ref={actionItemMoreWrapperRef}>
              <ActionItem
                withSeparator={!hasOnlyActionItemMoreVisible}
                name={t('common:more')}
              />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <ul>
              {additionalItems.map(
                ({
                  name,
                  icon,
                  color,
                  isLoading,
                  disabled,
                  ...actionOrToProp
                }) => (
                  <li key={name}>
                    <ActionItem
                      {...actionOrToProp}
                      sx={{ width: '100%' }}
                      buttonSx={{
                        display: 'flex',
                        width: '100%',
                        height: 44,
                        margin: 0,
                      }}
                      name={name}
                      icon={icon}
                      color={color}
                      disabled={areAllActionsDisabled || disabled}
                      isLoading={isLoading}
                      withSeparator={false}
                    />
                  </li>
                ),
              )}
            </ul>
          </Menu.Dropdown>
        </Menu>
      )}
    </Paper>
  );
};

export const DesktopView = (props: ActionsBarPropsType) => {
  const { width, height } = useViewportSize();

  return <DesktopViewInner key={`${width} ${height}`} {...props} />;
};
