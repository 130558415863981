import { useId } from 'react';
import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const NoLetterheadIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();

  const mantineTheme = useMantineTheme();

  const iconColor = useGetColor(color);
  const backgroundColor = mantineTheme.fn.rgba(iconColor, 0.07);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke={iconColor} clipPath={`url(#${id})`}>
        <path
          fill={backgroundColor}
          d="m15.806 3.582-.151-.152H6.62c-1.17 0-2.117.948-2.117 2.118v12.904c0 1.17.948 2.118 2.117 2.118H17.38c1.17 0 2.117-.948 2.117-2.118V7.272l-.151-.152-3.54-3.538Z"
        />
        <path strokeWidth={1.5} d="m5.18 4.005 13.288 15.647" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M4 2.926h16v18.147H4z" />
        </clipPath>
      </defs>
    </Box>
  );
};
