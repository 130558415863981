import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

const ErrorBoldText = ({ children }) => (
  <Text component="span" color="red">
    {children}
  </Text>
);

export const useGetErrors = () => {
  const { t } = useTranslation('templates');

  return useCallback(
    editorValueJson => {
      const variables = editorValueJson.document.data?.variables;

      const newErrors = [];

      let variablesToAssign = variables
        ? [...variables].map(({ name }) => name)
        : [];

      let notFilledSignaturesCount = 0;
      let notAssignedInputNodeCount = 0;
      let notFilledTooltipsCount = 0;

      const checkErrors = nodes => {
        nodes.forEach(node => {
          const nodeData = node.data;
          const nodeVariable = nodeData?.variable;

          if (nodeVariable && variablesToAssign.includes(nodeVariable)) {
            variablesToAssign = variablesToAssign.filter(
              variableToAssign => variableToAssign !== nodeVariable,
            );
          }

          switch (node.type) {
            case 'signatures': {
              if (!nodeData?.firstPerson || !nodeData?.secondPerson) {
                notFilledSignaturesCount += 1;
              }
              break;
            }
            case 'tooltip': {
              if (!nodeData.text) {
                notFilledTooltipsCount += 1;
              }
              break;
            }
            case 'input': {
              if (!nodeVariable) {
                notAssignedInputNodeCount += 1;
              }
              break;
            }
          }

          if (node.nodes) {
            checkErrors(node.nodes);
          }
        });
      };

      checkErrors(editorValueJson.document.nodes);

      const errorsMap = [
        {
          isPresent: notFilledSignaturesCount > 0,
          error: (
            <Trans
              i18nKey="templates:templateCreatorErrors.signatures"
              values={{ count: notFilledSignaturesCount }}
              components={{
                b: <ErrorBoldText />,
              }}
            />
          ),
        },
        {
          isPresent: notAssignedInputNodeCount > 0,
          error: (
            <Trans
              i18nKey="templates:templateCreatorErrors.notMappedFields"
              values={{ count: notAssignedInputNodeCount }}
              components={{
                b: <ErrorBoldText />,
              }}
            />
          ),
        },
        {
          isPresent: notFilledTooltipsCount > 0,
          error: (
            <Trans
              i18nKey="templates:templateCreatorErrors.tooltips"
              values={{ count: notFilledTooltipsCount }}
              components={{
                b: <ErrorBoldText />,
              }}
            />
          ),
        },
        {
          isPresent: variablesToAssign.length > 0,
          error: (
            <Trans
              i18nKey="templates:templateCreatorErrors.notMappedVariables"
              values={{ variables: variablesToAssign.join(', ') }}
              components={{
                b: <ErrorBoldText />,
              }}
            />
          ),
        },
      ];

      errorsMap.forEach(({ isPresent, error }) => {
        if (isPresent) {
          newErrors.push(error);
        }
      });

      return newErrors;
    },
    [t],
  );
};
