import { Dispatch, forwardRef, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@mantine/core';
import { ChevronDownIcon } from 'icons';
import { OrganisationUserListType } from 'types';

import { SelectedOrganisationMembersType } from '../../../../types';
import { getSelectedMembersFullNames } from './helpers';

const SELECTED_MEMBERS_TO_SHOW = 2;

type Props = {
  isParticipantsListOpen: boolean;
  setIsParticipantsListOpen: Dispatch<SetStateAction<boolean>>;
  organisationMembers: OrganisationUserListType[];
  selectedOrganisationMembers: SelectedOrganisationMembersType;
};

export const TransactionOwnerButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      isParticipantsListOpen,
      setIsParticipantsListOpen,
      organisationMembers,
      selectedOrganisationMembers,
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation('transactions');

    const selectedMembers = getSelectedMembersFullNames(
      organisationMembers,
      selectedOrganisationMembers,
    );

    return (
      <div>
        <Button
          sx={theme => ({
            borderColor: theme.colors.gray[3],
            ':hover': {
              boxShadow: 'none',
            },
          })}
          ref={ref}
          variant="default"
          rightIcon={
            <ChevronDownIcon
              sx={theme => ({
                transition: `transform ${theme.other.animationsTime} ease`,
                transform: `rotate(${isParticipantsListOpen ? '180deg' : 0})`,
              })}
            />
          }
          onClick={() => {
            setIsParticipantsListOpen(open => !open);
          }}
        >
          <Box
            sx={theme => ({
              marginRight: theme.other.spacing(0.5),
              fontWeight: 600,
            })}
            component="span"
          >
            {t('transactions:transactionListOwner')}
          </Box>
          <Text span size="sm">
            {selectedOrganisationMembers[0] === 'all' ||
            selectedOrganisationMembers.length === 0 ? (
              t('transactions:transactionListAllOrganisation')
            ) : (
              <>
                {selectedMembers.length > 0 &&
                  selectedMembers.slice(0, SELECTED_MEMBERS_TO_SHOW).join(', ')}
                {selectedMembers.length > SELECTED_MEMBERS_TO_SHOW &&
                  ` +${selectedMembers.length - SELECTED_MEMBERS_TO_SHOW}`}
              </>
            )}
          </Text>
        </Button>
      </div>
    );
  },
);
