import { ThemeComponentType } from '../types';

export const Divider: ThemeComponentType = {
  styles: (theme, { color }) => ({
    root: {
      '&': {
        borderTopColor: color || theme.colors.gray[2],
      },
    },
    label: {
      '&': {
        marginTop: 0,
      },
      ':before, :after': {
        borderTopColor: color || theme.colors.gray[2],
      },
    },
  }),
};
