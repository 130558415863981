import {
  isMarkdown,
  toggleMarkdown,
} from 'legacy/TemplateCreatorEditor/plugins/helpers';

export const strikethrough = {
  renderMark: ({ attributes, mark, children }, editor, next) => {
    if (mark.type === 'strikethrough') {
      return <s {...attributes}>{children}</s>;
    }

    return next();
  },
  queries: {
    isStrikethrough: editor => isMarkdown(editor, 'strikethrough'),
  },
  commands: {
    toggleStrikethrough: editor => {
      toggleMarkdown(editor, 'strikethrough');
    },
  },
};
