import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { useGetCurrentOrganisationId } from 'hooks';
import { ApiIcon } from 'icons';
import {
  PercentageContainer,
  NoAccessModal,
  PaperCard,
} from 'components/shared';

type Props = {
  hasTechnicalUsersFeature: boolean;
};

export const DetailsContent = ({ hasTechnicalUsersFeature }: Props) => {
  const { t } = useTranslation('api');

  const organisationId = useGetCurrentOrganisationId();

  const [isNoAccessToApiModalOpen, setIsNoAccessToApiModalOpen] =
    useState(false);

  return (
    <>
      <PercentageContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        component={PaperCard}
      >
        <div>
          <Text weight={600} mb="xs">
            {t('api:organisationId')}
          </Text>
          <Text color="gray.7">{organisationId}</Text>
        </div>

        <UnstyledButton
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            fontSize: theme.fontSizes.md,
            fontWeight: 500,
            color: hasTechnicalUsersFeature ? undefined : theme.colors.gray[7],
            '&:disabled': {
              cursor: 'default',
              color: 'inherit',
              backgroundColor: 'transparent',
            },
          })}
          disabled={hasTechnicalUsersFeature}
          onClick={() => {
            setIsNoAccessToApiModalOpen(true);
          }}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 30,
              height: 30,
              marginRight: theme.other.spacing(1),
              padding: theme.other.spacing(0, 0.5),
              backgroundColor: hasTechnicalUsersFeature
                ? theme.colors.green[1]
                : theme.colors.gray[1],
              borderRadius: theme.radius.sm,
              color: hasTechnicalUsersFeature
                ? theme.colors.green[0]
                : theme.colors.gray[4],
              fontSize: 11,
              fontWeight: 600,
            })}
          >
            <ApiIcon />
          </Box>
          {hasTechnicalUsersFeature
            ? t('api:accessToApi')
            : t('api:noAccessToApi')}
        </UnstyledButton>
      </PercentageContainer>
      <NoAccessModal
        isOpen={isNoAccessToApiModalOpen}
        onClose={() => {
          setIsNoAccessToApiModalOpen(false);
        }}
      />
    </>
  );
};
