import { useGetCurrentOrganisation } from 'api/organisations';
import { getHasHateoasAccess } from 'helpers';

export const useGetOrganisationFeatureAccess = (featuresKey: string) => {
  const {
    data: organisation,
    isError,
    isLoading,
  } = useGetCurrentOrganisation();

  return {
    data: getHasHateoasAccess(featuresKey, organisation?.__links),
    isError,
    isLoading,
  };
};
