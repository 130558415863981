import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getOrganisationBoards } from '../requests';
import { GetOrganisationBoardsRequestType } from '../types';

export const useGetCurrentOrganisationBoards = (
  {
    pathParams: { organisationId },
  }: Pick<GetOrganisationBoardsRequestType, 'pathParams'>,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.organisationBoards, { organisationId }],
    queryFn: () =>
      getOrganisationBoards({
        pathParams: { organisationId },
      }),
    ...options,
  });
