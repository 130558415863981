import { apiAuthClient } from 'api/helpers';

import { GetUserResponseType } from '../types';

export const getUser = async () => {
  const url = '/api/v2/auth/user';

  const { data } = await apiAuthClient.get<GetUserResponseType>(url);

  return data.data;
};
