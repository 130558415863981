import { Box } from '@mantine/core';

export const SectionWrapper = ({ children }) => (
  <Box
    sx={theme => ({
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.other.spacing(2),
    })}
  >
    {children}
  </Box>
);
