import { useTranslation } from 'react-i18next';
import { formatDistanceToNow, differenceInDays, isValid } from 'date-fns';
import { pl } from 'date-fns/locale';

import { useFormatUserDate } from './useFormatUserDate';

const DIFFERENCE_IN_DAYS_TO_SHOW_FULL_DATE = 6;

export const useFormatDateDistanceFromNow = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const formatUserDate = useFormatUserDate();

  return (date: string | null | undefined) => {
    if (date && isValid(new Date(date))) {
      const differenceInDaysFromNow = differenceInDays(
        Date.now(),
        new Date(date),
      );

      return differenceInDaysFromNow >= DIFFERENCE_IN_DAYS_TO_SHOW_FULL_DATE
        ? formatUserDate(date)
        : formatDistanceToNow(new Date(date), {
            addSuffix: true,
            locale: language === 'pl' ? pl : undefined,
          });
    }

    return '';
  };
};
