import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Box, MantineColor, Text } from '@mantine/core';

import { Status } from 'components/shared';

type Props = {
  label: string;
  statusColor?: MantineColor;
} & ComponentPropsWithoutRef<'div'>;

export const SelectItem = forwardRef<HTMLDivElement, Props>(
  ({ label, statusColor, ...props }: Props, ref) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }} ref={ref} {...props}>
      {statusColor && (
        <Status
          sx={theme => ({
            marginRight: theme.other.spacing(1),
          })}
          color={statusColor}
          dotSize={16}
        />
      )}
      <Text>{label}</Text>
    </Box>
  ),
);
