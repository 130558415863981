import { decamelize, decamelizeKeys } from 'humps';

export const parseRequestData = (data: FormData | object) => {
  if (data instanceof FormData) {
    const newData = new FormData();

    for (const [key, value] of data.entries()) {
      newData.append(decamelize(key), value);
    }

    return newData;
  }

  return decamelizeKeys(data);
};
