import { Box, UnstyledButton } from '@mantine/core';

import { EditIcon } from 'icons';
import {
  CONFIG,
  VARIABLE_TYPES,
  VARIABLES,
} from 'legacy/TemplateCreatorEditor/consts';
import { VariableIcon } from 'legacy/TemplateCreatorEditor/icons';
import { VariableMapping } from 'legacy/TemplateCreatorEditor/VariableMapping';

const LEGAL_PARAGRAPH_WIDTH = 80;

const Header = ({ attributes, node, editor, children }) => {
  const variables = editor.getVariables();
  const nodeVariable = node.data.get('variable');
  const variableType = variables.find(
    ({ name }) => name === nodeVariable,
  )?.type;
  const variableIcon = variableType
    ? VARIABLES.find(({ value }) => value === variableType)?.icon
    : null;

  return (
    <Box
      {...attributes}
      sx={theme => ({
        counterIncrement: 'legal-paragraph',
        position: 'relative',
        margin: theme.other.spacing(9, 0, 4),
      })}
    >
      <VariableMapping
        node={node}
        defaultType="boolean"
        allowedTypes={[VARIABLE_TYPES.boolean]}
      >
        <UnstyledButton
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: 'auto',
            gap: theme.other.spacing(1),
            marginBottom: theme.other.spacing(1),
            padding: theme.other.spacing(0.75, 2),
            fontSize: theme.fontSizes.lg,
            fontWeight: 500,
            backgroundColor: theme.colors.gray[1],
            border: `1px solid ${theme.colors.gray[2]}`,
            borderRadius: theme.radius.sm,
            cursor: 'pointer',
            '&:before': {
              content: "'§ ' counter(legal-paragraph)",
              width: LEGAL_PARAGRAPH_WIDTH,
              position: 'absolute',
              top: 5,
              left: -(LEGAL_PARAGRAPH_WIDTH + theme.other.spacing(2)),
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'right',
              pointerEvents: 'none',
            },
          })}
          contentEditable={false}
        >
          {nodeVariable ? variableIcon : <VariableIcon color="blue" />}
          {Boolean(nodeVariable) && (
            <>
              <Box sx={{ maxWidth: 240, userSelect: 'none' }} component="span">
                {nodeVariable}
              </Box>
              <EditIcon
                sx={theme => ({
                  marginLeft: theme.other.spacing(1),
                })}
                color="primary"
              />
            </>
          )}
        </UnstyledButton>
      </VariableMapping>
      <Box
        sx={{
          margin: 0,
          fontSize: CONFIG.header.fontSize,
          textAlign: CONFIG.header.textAlign,
        }}
        component="h2"
      >
        {children}
      </Box>
    </Box>
  );
};

/* header - named paragraph in UI editor */

export const header = {
  onKeyDown: (e, editor, next) => {
    if (
      e.key === 'Enter' &&
      editor.value.blocks.some(block => block.type === 'header')
    ) {
      e.preventDefault();

      editor.moveToEndOfNode(editor.value.endBlock).insertBlock('paragraph');

      return true;
    }

    return next();
  },
  renderNode: ({ attributes, node, children }, editor, next) => {
    if (node.type === 'header') {
      return (
        <Header attributes={attributes} node={node} editor={editor}>
          {children}
        </Header>
      );
    }

    return next();
  },
  commands: {
    insertHeader: editor => {
      editor.setBlocks({ type: 'header', data: {} });
      editor.focus();
      editor.moveToEnd();
    },
  },
};
