import { Box, Button, Text } from '@mantine/core';
import { PaperCard, PercentageContainer } from 'components/shared';
import { CreateIcon } from 'icons';
import { useTranslation } from 'react-i18next';

type Props = {
  handleOpenDetailsModal: () => void;
};

export const WebhookCreateBox = ({ handleOpenDetailsModal }: Props) => {
  const { t } = useTranslation(['common', 'api']);
  return (
    <PercentageContainer
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.colors.green[1],
        padding: theme.other.spacing(2),
      })}
      component={PaperCard}
    >
      <Box>
        <Text weight={600} mb="xs">
          {t('api:webhooks.webhookCreateBoxHeader')}
        </Text>
        <Text
          color="gray.7"
          size={13}
          sx={theme => ({ paddingRight: theme.other.spacing(2) })}
        >
          {t('api:webhooks.webhookCreateBoxDescription')}
        </Text>
      </Box>
      <Box>
        <Button
          component="a"
          leftIcon={<CreateIcon />}
          color="green"
          onClick={() => handleOpenDetailsModal()}
        >
          <Text size={14}>{t('common:create')}</Text>
        </Button>
      </Box>
    </PercentageContainer>
  );
};
