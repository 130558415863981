export const getUserInitials = (
  name: string | null | undefined,
  surname: string | null | undefined,
) => {
  const firstLetterOfName = (typeof name === 'string' ? name[0] : '') ?? '';
  const firstLetterOfSurname =
    (typeof surname === 'string' ? surname[0] : '') ?? '';

  return `${firstLetterOfName.toUpperCase()}${firstLetterOfSurname.toUpperCase()}`;
};
