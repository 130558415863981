import { Box, Button } from '@mantine/core';

export const PredefinedValues = ({ items, onValueClick }) => (
  <Box
    sx={theme => ({
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.other.spacing(0.5),
    })}
    component="ul"
  >
    {items.map(({ value, label }) => (
      <li key={value}>
        <Button
          sx={theme => ({
            fontWeight: 400,
            borderColor: theme.colors[theme.primaryColor][2],
            ':hover': {
              borderColor: theme.colors[theme.primaryColor][4],
            },
          })}
          variant="light"
          onClick={() => {
            onValueClick(value);
          }}
        >
          {label}
        </Button>
      </li>
    ))}
  </Box>
);
