import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mantine/core';

import { useGetTransactionEntry } from 'api/transactionEntries';
import { useTransactionsFeature } from 'hooks';
import { DYNAMIC_URLS, TRANSACTION_DETAILS_TABS } from 'consts';
import { TransactionEntriesTabType } from 'types';
import { DataWrapper, MetaTitle } from 'components/shared';

import { Header } from './Header';
import { DetailTabs } from './DetailTabs';
import { DetailPanels } from './DetailPanels';
import { JsonViewer } from './JsonViewer';

export const TransactionEntry = () => {
  const { t } = useTranslation('transactions');
  const navigate = useNavigate();

  const {
    transactionDataStream,
    transactionId,
    transactionEntry,
    tab: activeTab,
  } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
    transactionEntry: string;
    tab: TransactionEntriesTabType;
  };

  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();

  const {
    data: entry,
    isError: isEntryError,
    isLoading: isEntryLoading,
  } = useGetTransactionEntry(
    {
      pathParams: {
        transactionId,
        dataStream: transactionDataStream,
        transactionEntry,
      },
    },
    {
      enabled: !isLoadingTransactionsFeature && transactionsFeature,
    },
  );

  const defaultTab = TRANSACTION_DETAILS_TABS.data;

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={entry}
        isError={isEntryError || isTransactionFeatureError}
        isLoading={isEntryLoading || isLoadingTransactionsFeature}
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {({ status, documents, dataStream, dataType }) => (
          <>
            <Header sx={theme => ({ marginBottom: theme.other.spacing(5) })} />
            {status.invalid ? (
              <JsonViewer value={status} />
            ) : (
              <>
                <Box
                  sx={theme => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: 36,
                    marginBottom: theme.other.spacing(2),
                  })}
                >
                  <DetailTabs
                    activeTab={activeTab || defaultTab}
                    onTabChange={(tab: TransactionEntriesTabType) => {
                      navigate(
                        DYNAMIC_URLS.transactionEntry({
                          transactionId,
                          transactionDataStream,
                          transactionEntry,
                          tab,
                        }),
                      );
                    }}
                  />
                </Box>
                <DetailPanels
                  activeTab={activeTab || defaultTab}
                  documents={documents}
                  dataStream={dataStream}
                  dataType={dataType}
                />
              </>
            )}
          </>
        )}
      </DataWrapper>
    </>
  );
};
