import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Select } from '@mantine/core';

import { DescriptionField } from 'components/shared';

export const InitialListValue = ({ control, data, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.initialValue')}
      description={t(
        'templates:templateCreatorVariables.initialValueDescription',
      )}
      error={error}
    >
      <Controller
        name="_initialValueId"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            defaultValue={null}
            placeholder={t('templates:templateCreatorVariables.initialValue')}
            error={null}
            data={[
              {
                value: null,
                label: t('templates:templateCreatorVariables.none'),
              },
              ...data,
            ]}
          />
        )}
      />
    </DescriptionField>
  );
};
