import { NOTIFICATION_TYPES } from 'consts';

export const EXPOSED_NOTIFICATIONS = [
  {
    type: NOTIFICATION_TYPES.contractorJoinedDocument,
    translations: {
      type: 'notifications:types.contractorJoinedDocument.type',
      description: 'notifications:types.contractorJoinedDocument.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.contractorRejectedDocumentInvitation,
    translations: {
      type: 'notifications:types.contractorRejectedDocumentInvitation.type',
      description:
        'notifications:types.contractorRejectedDocumentInvitation.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.contractorRemovedFromDocument,
    translations: {
      type: 'notifications:types.contractorRemovedFromDocument.type',
      description:
        'notifications:types.contractorRemovedFromDocument.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.documentCreatedFromOffer,
    translations: {
      type: 'notifications:types.documentCreatedFromOffer.type',
      description: 'notifications:types.documentCreatedFromOffer.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.documentDeleted,
    translations: {
      type: 'notifications:types.documentDeleted.type',
      description: 'notifications:types.documentDeleted.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.documentFinished,
    translations: {
      type: 'notifications:types.documentFinished.type',
      description: 'notifications:types.documentFinished.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.documentQueueUpdated,
    translations: {
      type: 'notifications:types.documentQueueUpdated.type',
      description: 'notifications:types.documentQueueUpdated.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.documentUpdated,
    translations: {
      type: 'notifications:types.documentUpdated.type',
      description: 'notifications:types.documentUpdated.description',
    },
  },
  {
    type: NOTIFICATION_TYPES.userLeftOrganisation,
    translations: {
      type: 'notifications:types.userLeftOrganisation.type',
      description: 'notifications:types.userLeftOrganisation.description',
    },
  },
] as const;
