import { BadgeVariant } from '@mantine/core';

import { DOCUMENT_STAGES } from 'consts';

export const getDocumentStageBadgeStyleProps = (
  stage: (typeof DOCUMENT_STAGES)[keyof typeof DOCUMENT_STAGES],
) => {
  switch (stage) {
    case DOCUMENT_STAGES.draft:
      return {
        color: 'gray',
        variant: 'light' as BadgeVariant,
      };
    case DOCUMENT_STAGES.finished:
      return {
        color: 'green',
        variant: 'light' as BadgeVariant,
      };
    case DOCUMENT_STAGES.inAcceptance:
      return {
        color: 'orange',
        variant: 'outline' as BadgeVariant,
      };
    case DOCUMENT_STAGES.inSign:
      return {
        color: 'green',
        variant: 'outline' as BadgeVariant,
      };
    case DOCUMENT_STAGES.rejected:
      return {
        color: 'red',
        variant: 'light' as BadgeVariant,
      };
    case DOCUMENT_STAGES.sent:
      return {
        color: 'blue',
        variant: 'light' as BadgeVariant,
      };
    default:
      return {
        color: undefined,
        variant: undefined,
      };
  }
};
