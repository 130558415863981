import { Box } from '@mantine/core';

export const paragraph = {
  renderNode: ({ attributes, node, children }, editor, next) => {
    if (node.type === 'paragraph') {
      return (
        <Box
          {...attributes}
          sx={{
            position: 'relative',
            lineHeight: node.data.get('lineHeight') || undefined,
            textAlign: node.data.get('align') || undefined,
          }}
        >
          {children}
        </Box>
      );
    }

    return next();
  },
  queries: {
    hasParagraph: editor => {
      return editor.value.blocks.some(block => block.type === 'paragraph');
    },
  },
  commands: {
    insertParagraph: editor => {
      editor.setBlocks({ type: 'paragraph', data: {} });
      editor.focus();
      editor.moveToEnd();
    },
    setParagraphAlignment: (editor, align) => {
      editor.value.blocks
        .filter(block => block.type === 'paragraph')
        .forEach(block => {
          editor.updateNodeData(block, { align });
        });
    },
    changeParagraphInterline: (editor, lineHeight) => {
      editor.value.blocks
        .filter(block => block.type === 'paragraph')
        .forEach(block => {
          editor.updateNodeData(block, { lineHeight });
        });
    },
  },
};
