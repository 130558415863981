export const URLS = {
  account: '/account',
  api: '/api',
  dataStreamAdd: '/data-streams/add',
  dataStreams: '/data-streams',
  dataTypes: '/data-types',
  home: '/',
  oauthAzure: '/oauth/azure',
  oauthGoogle: '/oauth/google',
  register: '/register',
  requestPasswordReset: '/reset-password',
  resetPassword: '/reset-password/:token',
  templateAdd: '/templates/add',
  templates: '/templates',
  transactions: '/transactions',
  verifyEmail: '/verify-email',
} as const;
