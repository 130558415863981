import { ThemeComponentType } from '../types';

export const Notification: ThemeComponentType = {
  styles: theme => ({
    root: {
      padding: theme.other.spacing(1),
      minHeight: 50,
      border: 'none',
      boxShadow: theme.shadows.xs,
      '&.mantine-Notification-root': {
        paddingLeft: theme.other.spacing(1),
      },
    },
    icon: {
      '&.__mantine-ref-icon.mantine-Notification-icon': {
        marginRight: theme.other.spacing(1),
        backgroundColor: 'transparent',
      },
    },
    description: {
      fontSize: theme.fontSizes.md,
      fontWeight: 500,
      paddingRight: theme.other.spacing(3),
    },
    closeButton: {
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
    },
  }),
};
