import { useTranslation } from 'react-i18next';

import { DescriptionField, NumberInput } from 'components/shared';

export const InitialNumberValue = ({ inputProps, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.initialValue')}
      description={t(
        'templates:templateCreatorVariables.initialValueDescription',
      )}
      error={error}
    >
      <NumberInput
        placeholder={t('templates:templateCreatorVariables.initialValue')}
        withErrorWrapperAlwaysEnabled={false}
        hideControls={false}
        {...inputProps}
      />
    </DescriptionField>
  );
};
