import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { putCustomizationsDocument } from '../requests';
import { PutCustomizationsDocumentRequestType } from '../types';

export const usePutCustomizationsDocument = ({
  pathParams: { templateId },
}: {
  pathParams: Omit<
    PutCustomizationsDocumentRequestType['pathParams'],
    'organisationId' | 'boardId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: ({
      margin,
      disableBranding,
    }: PutCustomizationsDocumentRequestType['bodyParams']) =>
      putCustomizationsDocument({
        pathParams: {
          organisationId,
          boardId,
          templateId,
        },
        bodyParams: {
          margin,
          disableBranding,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.template, { templateId: Number(templateId) }],
      });
    },
  });
};
