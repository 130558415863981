import { useState } from 'react';
import { Box, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { MetaType, WebhookType } from 'types';
import { CreateIcon } from 'icons';
import { PaginationReactRouter, PercentageContainer } from 'components/shared';
import { WebhookCreateBox } from './WebhookCreateBox';
import { SingleWebhook } from './SingleWebhook';
import { WebhookDeleteModal } from './WebhookDeleteModal';
import { WebhookLogsModal } from './WebhookLogsModal';
import { WebhookDetailsModal } from './WebhookDetailsModal';

type Props = {
  meta: MetaType;
  webhooks: WebhookType[];
};

export const WebhookList = ({ meta, webhooks }: Props) => {
  const { t } = useTranslation(['common', 'api']);
  const [webhookToEditId, setWebhookToEditId] = useState<number | null>(null);
  const [webhookToDeleteId, setWebhookToDeleteId] = useState<number | null>(
    null,
  );
  const [webhookToShowLogsId, setWebhookToShowLogsId] = useState<number | null>(
    null,
  );
  const [webhookDetailsModalMode, setWebhookDetailsModalMode] = useState<
    'create' | 'edit' | null
  >(null);

  const handleOpenWebhookDeleteModal = (index: number) => {
    setWebhookToDeleteId(index);
  };

  const handleCloseWebhookDeleteModal = () => {
    setWebhookToDeleteId(null);
  };

  const handleOpenWebhookDetailsModalInCreateMode = () => {
    setWebhookDetailsModalMode('create');
  };

  const handleOpenWebhookDetailsModalInEditMode = (index: number) => {
    setWebhookToEditId(index);
    setWebhookDetailsModalMode('edit');
  };
  const handleCloseWebhookDetailsModal = () => {
    setWebhookToEditId(null);
    setWebhookDetailsModalMode(null);
  };

  const handleOpenWebhookLogsModal = (index: number) => {
    setWebhookToShowLogsId(index);
  };
  const handleCloseWebhookLogsModal = () => {
    setWebhookToShowLogsId(null);
  };

  const showWebhookDeleteModal = webhookToDeleteId !== null;
  const showWebhookLogsModal = webhookToShowLogsId !== null;
  const showWebhookDetailsModal = webhookDetailsModalMode !== null;

  const showCreateWebhookBox = webhooks.length === 0;
  const showWebhooks = webhooks.length > 0;

  return (
    <Box
      sx={theme => ({
        marginTop: theme.other.spacing(2),
      })}
    >
      {showWebhookDetailsModal && (
        <WebhookDetailsModal
          isOpen={showWebhookDetailsModal}
          handleClose={() => handleCloseWebhookDetailsModal()}
          mode={webhookDetailsModalMode}
          webhook={webhookToEditId !== null ? webhooks[webhookToEditId] : null}
        />
      )}
      {showWebhookLogsModal && (
        <WebhookLogsModal
          isOpen={showWebhookLogsModal}
          handleClose={handleCloseWebhookLogsModal}
          webhookId={webhooks[webhookToShowLogsId].id!}
        />
      )}
      {showWebhookDeleteModal && (
        <WebhookDeleteModal
          isOpen={showWebhookDeleteModal}
          handleClose={() => handleCloseWebhookDeleteModal()}
          webhook={webhooks[webhookToDeleteId]}
        />
      )}
      {showCreateWebhookBox && (
        <WebhookCreateBox
          handleOpenDetailsModal={handleOpenWebhookDetailsModalInCreateMode}
        />
      )}
      {showWebhooks && (
        <Box>
          <Button
            variant="light"
            color="green"
            leftIcon={<CreateIcon />}
            onClick={handleOpenWebhookDetailsModalInCreateMode}
          >
            {t('common:create')}
          </Button>
          <Box
            sx={theme => ({
              marginTop: theme.other.spacing(2),
            })}
          >
            {webhooks.map((webhook, index) => (
              <SingleWebhook
                webhook={webhook}
                handleDelete={() => handleOpenWebhookDeleteModal(index)}
                handleEdit={() =>
                  handleOpenWebhookDetailsModalInEditMode(index)
                }
                handleShowLogs={() => handleOpenWebhookLogsModal(index)}
              />
            ))}
          </Box>
          <PercentageContainer
            sx={theme => ({
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingTop: theme.other.spacing(2),
              marginBottom: theme.other.spacing(1),
            })}
          >
            <PaginationReactRouter
              meta={meta}
              isStickyToBottom={false}
              pageName="webhookPage"
            />
          </PercentageContainer>
        </Box>
      )}
    </Box>
  );
};
