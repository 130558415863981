import { TickIcon } from 'icons';

import { ThemeComponentType } from '../types';

export const Checkbox: ThemeComponentType = {
  defaultProps: {
    icon: TickIcon,
  },
  styles: theme => ({
    root: {
      marginBottom: theme.other.spacing(1.5),
    },
    inner: {
      width: 30,
      height: 30,
      flexShrink: 0,
    },
    input: {
      width: '100%',
      height: '100%',
      border: `1.5px solid ${theme.colors.gray[3]}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][1],
      },
      '&:checked': {
        '&:not(:disabled)': {
          backgroundColor: theme.fn.primaryColor(),
          borderColor: theme.fn.primaryColor(),
          '&:hover': {
            backgroundColor: theme.colors[theme.primaryColor][7],
            borderColor: 'transparent',
          },
        },
      },
      '&:disabled': {
        backgroundColor: theme.colors.gray[1],
        borderColor: 'transparent',
        '+.mantine-Checkbox-icon': {
          color: theme.colors.gray[4],
        },
      },
    },
    icon: {
      width: 18,
    },
    label: {
      paddingLeft: theme.other.spacing(1),
      fontSize: theme.fontSizes.lg,
      lineHeight: 'normal',
      cursor: 'pointer',
    },
    error: {
      ...((theme.components.InputWrapper as any).styles(theme).error || {}),
    },
    body: {
      alignItems: 'center',
    },
    description: {
      marginTop: 0,
      fontSize: theme.fontSizes.md,
      fontWeight: 400,
    },
  }),
};
