import { TRANSACTION_DETAILS_TABS } from 'consts';
import { TransactionDetailsTabType, TransactionType } from 'types';
import { NoAccess } from 'components/shared';

import { DataSettingsType } from '../types';
import { DataPanel } from './DataPanel';
import { DataTypePanel } from './DataTypePanel';
import { DocumentsPanel } from './DocumentsPanel';
import { DataStreamPanel } from './DataStreamPanel';

type Props = {
  activeTab: TransactionDetailsTabType;
  transaction: TransactionType;
  isDataEditable: boolean;
  dataSettings: DataSettingsType;
  isDataSaving: boolean;
  setDataSettings: (settings: DataSettingsType) => void;
  hasDataStreamGetAccess: boolean;
  hasDataTypeGetAccess: boolean;
};

export const DetailPanels = ({
  activeTab,
  transaction,
  isDataEditable,
  dataSettings,
  isDataSaving,
  setDataSettings,
  hasDataStreamGetAccess,
  hasDataTypeGetAccess,
}: Props) => {
  switch (activeTab) {
    case TRANSACTION_DETAILS_TABS.documents:
      return <DocumentsPanel />;
    case TRANSACTION_DETAILS_TABS.data:
      return (
        <DataPanel
          transaction={transaction}
          isDataEditable={isDataEditable}
          dataSettings={dataSettings}
          isDataSaving={isDataSaving}
          setDataSettings={setDataSettings}
        />
      );
    case TRANSACTION_DETAILS_TABS.stream:
      return hasDataStreamGetAccess ? <DataStreamPanel /> : <NoAccess />;
    case TRANSACTION_DETAILS_TABS.type:
      return hasDataTypeGetAccess ? <DataTypePanel /> : <NoAccess />;
    default:
      return null;
  }
};
