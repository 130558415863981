import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Anchor, Box, Text } from '@mantine/core';

import { useGetDocuments } from 'api/documents';
import {
  useFormatUserDate,
  useGetCurrentBoardId,
  useGetV1Url,
  useSearchParams,
} from 'hooks';
import { AttachmentIcon } from 'icons';
import {
  DataWrapper,
  Table,
  PaginationReactRouter,
  TextInputReactRouterSearch,
  StickyContent,
} from 'components/shared';

import { DocumentStageBadge } from './DocumentStageBadge';
import { Participants } from './Participants';

export const DocumentsPanel = () => {
  const { t } = useTranslation(['common', 'transactions']);
  const { transactionId, transactionDataStream } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
  };

  const formatUserDate = useFormatUserDate();
  const getV1Url = useGetV1Url();
  const { getSearchParam, deleteAllSearchParams } = useSearchParams([
    'search',
    'page',
  ]);
  const boardId = useGetCurrentBoardId();

  const page = getSearchParam('page');
  const search = getSearchParam('search');

  const {
    data: documentsData,
    isError: isDocumentsError,
    isLoading: isDocumentsLoading,
    isRefetching: isDocumentsRefetching,
  } = useGetDocuments(
    {
      queryParams: {
        boardId,
        transactionId,
        transactionDataStream,
        page,
        search,
      },
    },
    {
      keepPreviousData: true,
    },
  );

  const hasActiveFilters = Boolean(page ?? search);

  return (
    <DataWrapper
      data={documentsData}
      isError={isDocumentsError}
      isLoading={isDocumentsLoading}
    >
      {({ data: documents, meta }) => (
        <>
          <StickyContent>
            <TextInputReactRouterSearch
              sx={theme => ({
                maxWidth: 328,
                width: '100%',
                marginLeft: 'auto',
                paddingBottom: theme.other.spacing(2),
              })}
              placeholder={t(
                'transactions:transactionDocuments.searchDocument',
              )}
            />
          </StickyContent>
          <Table
            hasData={documents.length > 0}
            isLoading={isDocumentsRefetching}
            noDataButtonText={
              hasActiveFilters ? t('common:clearFilters') : undefined
            }
            onNoDataButtonClick={
              hasActiveFilters ? deleteAllSearchParams : undefined
            }
          >
            <thead>
              <tr>
                <th>{t('transactions:transactionDocuments.documentName')}</th>
                <th>{t('transactions:transactionDocuments.template')}</th>
                <th>{t('transactions:transactionDocuments.modified')}</th>
                <th>{t('transactions:transactionDocuments.reference')}</th>
                <th>{t('transactions:transactionDocuments.participants')}</th>
              </tr>
            </thead>
            <tbody>
              {documents.map(
                ({
                  id,
                  name,
                  stage,
                  type,
                  updatedAt,
                  contractorName,
                  participants,
                }) => (
                  <tr key={id}>
                    <td>
                      <Anchor
                        sx={theme => ({
                          display: 'block',
                          marginBottom: theme.other.spacing(4),
                          fontWeight: 600,
                        })}
                        href={getV1Url('contract', {
                          documentId: String(id),
                        })}
                      >
                        {name}
                      </Anchor>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DocumentStageBadge stage={stage} />
                        {type === 'FILE' && (
                          <AttachmentIcon
                            sx={theme => ({
                              marginLeft: theme.other.spacing(1),
                            })}
                          />
                        )}
                      </Box>
                    </td>
                    <Text component="td" size="sm">
                      {type}
                    </Text>
                    <Text component="td" size="sm">
                      {formatUserDate(updatedAt)}
                    </Text>
                    <td>{contractorName}</td>
                    <td>
                      <Participants participants={participants} />
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
          <PaginationReactRouter meta={meta} isStickyToBottom />
        </>
      )}
    </DataWrapper>
  );
};
