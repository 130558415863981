import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ApiIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m16 16 4-4-4-4m-8 8-4-4 4-4m5.333-4-2.666 16"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Box>
  );
};
