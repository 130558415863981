import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetTransactionsResponseType,
  GetTransactionsRequestType,
} from '../types';

export const getTransactions = async ({
  pathParams: { organisationId },
  queryParams: {
    transactionIdOrName,
    transactionStatus,
    transactionOwnerIds,
    page,
    perPage,
    sortDir,
  },
}: GetTransactionsRequestType) => {
  const query = stringifyUrlQuery({
    transactionIdOrName,
    transactionStatus,
    transactionOwnerIds,
    page,
    perPage,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/transactions${query}`;

  const { data } = await apiAuthClient.get<GetTransactionsResponseType>(url);

  return data;
};
