import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    signType: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') }),
    certificateLanguages: z
      .tuple(
        [
          z
            .string()
            .trim()
            .min(1, { message: t('common:formErrors.required') }),
        ],
        {
          errorMap: () => ({
            message: t('common:formErrors.required'),
          }),
        },
      )
      .rest(
        z
          .string()
          .trim()
          .min(1, { message: t('common:formErrors.required') }),
      ),
    enableNumeric: z.boolean(),
    templateNumeric: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      })
      .refine(
        value =>
          value.length > 0
            ? z.string().regex(/%i/).safeParse(value).success
            : true,
        t('common:formErrors.mustInclude', {
          value: '%i',
        }),
      ),
    templateType: z.string(),
    oneWay: z.boolean(),
    canEditByNegotiator: z.boolean(),
  });
};
