import { apiAuthClient } from '../../helpers';
import { PatchWebhookRequestType, PatchWebhookResponseType } from '../types';

export const patchWebhook = async ({
  pathParams: { organisationId, webhookId },
  bodyParams,
}: PatchWebhookRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/webhooks/${webhookId}`;
  const { data } = await apiAuthClient.patch<PatchWebhookResponseType>(
    url,
    bodyParams,
  );

  return data;
};
