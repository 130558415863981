import { TransactionDocumentQueueType } from 'types';

export const getParticipantErrors = ({
  type,
  queueErrors,
  stepIndex,
  participantIndex,
}: {
  type: 'acceptors' | 'signers';
  queueErrors: TransactionDocumentQueueType['errors'];
  stepIndex: number;
  participantIndex: number;
}) => {
  const errorPointer = `${type}.${stepIndex}.${participantIndex}.`;

  const participantErrorKeys = Object.keys(queueErrors).filter(errorKey =>
    errorKey.startsWith(errorPointer),
  );

  return participantErrorKeys.reduce(
    (accumulator, errorKey) => ({
      ...accumulator,
      [errorKey.replace(errorPointer, '')]: queueErrors[errorKey],
    }),
    {},
  );
};
