import { Menu, Sx } from '@mantine/core';

import { LinkType } from 'components/Layout/Navbar/types';
import {
  useGetIsMatch,
  useGetLinkRestProps,
  useLocalDocumentsCount,
} from 'components/Layout/Navbar/hooks';
import { LinkLabel } from 'components/Layout/Navbar/LinkLabel';

type Props = {
  links?: LinkType[];
  sx?: Sx;
};

export const NavbarMenu = ({ links, sx }: Props) => {
  const getIsMatch = useGetIsMatch();
  const getLinkRestProps = useGetLinkRestProps();
  const localContractsCount = useLocalDocumentsCount();

  if (!links) {
    return null;
  }

  return (
    <Menu.Dropdown sx={sx}>
      {links.map(link => {
        const {
          translateKey,
          hrefKey,
          toKey,
          showLocalContractsCount = false,
        } = link;

        const linkRestProps = getLinkRestProps(hrefKey, toKey);
        const isMatch = getIsMatch(link);

        return (
          <Menu.Item
            key={translateKey}
            {...linkRestProps}
            sx={theme => ({
              padding: theme.other.spacing(0.75, 2),
              fontSize: theme.fontSizes.md,
              fontWeight: isMatch ? 600 : 400,
              color: isMatch ? theme.fn.primaryColor() : undefined,
            })}
          >
            <LinkLabel
              translateKey={translateKey}
              showLocalContractsCount={showLocalContractsCount}
              localContractsCount={localContractsCount}
            />
          </Menu.Item>
        );
      })}
    </Menu.Dropdown>
  );
};
