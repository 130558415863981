import { CalendarIcon } from 'icons';
import {
  BooleanIcon,
  ContractorIcon,
  MoneyIcon,
  NumberIcon,
  SelectionListIcon,
  TabsIcon,
  TextIcon,
} from 'legacy/TemplateCreatorEditor/icons';

import { VARIABLE_TYPES } from './variableTypes';

export const VARIABLES = [
  {
    value: VARIABLE_TYPES.text,
    labelTranslation: 'templates:templateCreatorVariables.text',
    icon: <TextIcon />,
  },
  {
    value: VARIABLE_TYPES.textarea,
    labelTranslation: 'templates:templateCreatorVariables.longText',
    icon: <TextIcon />,
  },
  {
    value: VARIABLE_TYPES.integer,
    labelTranslation: 'templates:templateCreatorVariables.integer',
    icon: <NumberIcon />,
  },
  {
    value: VARIABLE_TYPES.boolean,
    labelTranslation: 'templates:templateCreatorVariables.boolean',
    icon: <BooleanIcon />,
  },
  {
    value: VARIABLE_TYPES.select,
    labelTranslation: 'templates:templateCreatorVariables.selectionList',
    icon: <SelectionListIcon />,
  },
  {
    value: VARIABLE_TYPES.tabs,
    labelTranslation: 'templates:templateCreatorVariables.tabs',
    icon: <TabsIcon />,
  },
  {
    value: VARIABLE_TYPES.money,
    labelTranslation: 'templates:templateCreatorVariables.money',
    icon: <MoneyIcon />,
  },
  {
    value: VARIABLE_TYPES.date,
    labelTranslation: 'templates:templateCreatorVariables.date',
    icon: <CalendarIcon />,
  },
  {
    value: VARIABLE_TYPES.company,
    labelTranslation: 'templates:templateCreatorVariables.contractor',
    icon: <ContractorIcon />,
  },
];
