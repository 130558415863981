import { ThemeComponentType } from '../types';

export const Textarea: ThemeComponentType = {
  styles: theme => ({
    input: {
      '&': {
        padding: theme.other.spacing(1, 2),
      },
    },
  }),
};
