import { useMantineTheme } from '@mantine/core';

const COLUMN_GAP_VALUE = 6;

export const useGetColumnWidths = () => {
  const mantineTheme = useMantineTheme();

  const columnHalfSpacingSize = Math.floor(
    mantineTheme.other.spacing(COLUMN_GAP_VALUE) / 2,
  );

  return {
    leftColumnWidth: `calc(40% - ${columnHalfSpacingSize}px)`,
    rightColumnWidth: `calc(60% - ${columnHalfSpacingSize}px)`,
  };
};
