import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { useFormatUserDate } from 'hooks';
import { DataStreamListItemType, DataStreamTechnicalUserType } from 'types';
import { PaperCard } from 'components/shared';

import { DataStreamActions } from '../DataStreamActions';

type Props = {
  id: DataStreamListItemType['id'];
  name: DataStreamListItemType['name'];
  dataType: DataStreamListItemType['dataType'];
  technicalUserName: DataStreamTechnicalUserType['name'];
  createdAt: DataStreamListItemType['createdAt'];
};

export const DataStreamPanel = ({
  id,
  name,
  dataType,
  technicalUserName,
  createdAt,
}: Props) => {
  const { t } = useTranslation('dataTypes');

  const formatUserDate = useFormatUserDate();

  return (
    <PaperCard
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          size="xl"
          weight={600}
        >
          {name}
        </Text>
        <Box
          sx={theme => ({
            marginBottom: theme.other.spacing(1.75),
          })}
        >
          <Text size="md" weight={600}>
            {technicalUserName}
          </Text>
          <Text color="gray.7" size="sm">
            {t('dataStreams:listTechnicalUserName')}
          </Text>
        </Box>
        <Box
          sx={theme => ({
            marginBottom: theme.other.spacing(1.75),
          })}
        >
          <Text size="md" weight={600}>
            {dataType}
          </Text>
          <Text color="gray.7" size="sm">
            {t('dataStreams:listDataType')}
          </Text>
        </Box>
        <div>
          <Text size="md" weight={600}>
            {formatUserDate(createdAt)}
          </Text>
          <Text color="gray.7" size="sm">
            {t('dataStreams:listCreatedAt')}
          </Text>
        </div>
      </div>
      <DataStreamActions id={id} name={name} />
    </PaperCard>
  );
};
