import { apiAuthClient } from 'api/helpers';

import {
  GetOrganisationResponseType,
  GetOrganisationRequestType,
} from '../types';

export const getOrganisation = async ({
  pathParams: { organisationId },
}: GetOrganisationRequestType) => {
  const url = `/api/v2/organisations/${organisationId}`;

  const { data } = await apiAuthClient.get<GetOrganisationResponseType>(url);

  return data.data;
};
