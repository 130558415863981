import { TransactionListStatusType } from 'types';

export const getTransactionListStatusColor = (
  status: TransactionListStatusType,
) => {
  if (status.applied) {
    return 'green';
  }
  if (status.invalid) {
    return 'red';
  }

  return 'gray';
};
