import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Text } from '@mantine/core';

import { useFormatUserDate } from 'hooks';
import { DYNAMIC_URLS } from 'consts';
import { TransactionStatusType } from 'types';

import { Errors } from './Errors';
import { Warnings } from './Warnings';

type Props = {
  errors: TransactionStatusType['errors'];
  warnings: TransactionStatusType['warnings'];
  updatedAt: string;
};

export const Status = ({ errors, warnings, updatedAt }: Props) => {
  const { t } = useTranslation(['common', 'transactions']);

  const formatUserDate = useFormatUserDate();

  const { transactionId, transactionDataStream } = useParams() as {
    transactionId: string;
    transactionDataStream: string;
  };

  const hasErrors = Object.keys(errors).length > 0;
  const hasWarnings = warnings.length > 0;

  return (
    <div>
      <Text
        sx={theme => ({ marginBottom: theme.other.spacing(0.5) })}
        size="md"
        weight={600}
      >
        {t('common:lastUpdate')}: {formatUserDate(updatedAt)}
      </Text>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {(hasErrors || hasWarnings) && (
          <>
            <Text size="md">{t('transactions:status')}:</Text>{' '}
            <Box
              sx={theme => ({
                display: 'flex',
                margin: theme.other.spacing(0, 2, 0, 1),
              })}
            >
              {hasErrors && (
                <Errors
                  sx={theme => ({
                    marginRight: theme.other.spacing(1),
                  })}
                  errors={errors}
                />
              )}
              {hasWarnings && (
                <Warnings
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  warnings={warnings}
                />
              )}
            </Box>
          </>
        )}
        <Anchor
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          component={Link}
          to={DYNAMIC_URLS.transactionEntries({
            transactionId,
            transactionDataStream,
          })}
        >
          <Text size="md">{t('transactions:goToActivityHistory')}</Text>
        </Anchor>
      </Box>
    </div>
  );
};
