import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { SuccessBucketIcon } from 'icons';

export const UpToDateInfo = () => {
  const { t } = useTranslation('notifications');

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.other.spacing(2, 0),
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
      })}
    >
      <SuccessBucketIcon color="primary" size={36} />
      <Text
        sx={theme => ({
          margin: theme.other.spacing(0.75, 0, 0, 1),
        })}
        size="md"
      >
        {t('notifications:youAreUpToDate')}
      </Text>
    </Box>
  );
};
