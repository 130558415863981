import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DefaultProps, Loader, UnstyledButton } from '@mantine/core';

import { useGetSso } from 'api/singleSignOn';
import { HTTP_HEADERS, SSO_LOGIN_METHODS } from 'consts';

type Props = {
  method: keyof typeof SSO_LOGIN_METHODS;
  label: string;
  icon: ReactNode;
  sx?: DefaultProps['sx'];
};

export const SsoLoginMethod = ({ method, label, icon, sx }: Props) => {
  const { t } = useTranslation('user');

  const { refetch: login, isRefetching: isLogging } = useGetSso(
    {
      headers: {
        [HTTP_HEADERS.xRedirectUrl]: window.location.href,
      },
      pathParams: {
        ssoDriver: method,
      },
    },
    {
      enabled: false,
      onSuccess: redirectTo => {
        window.location.assign(redirectTo);
      },
    },
  );

  return (
    <Box sx={sx}>
      <UnstyledButton
        sx={theme => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          border: `1px solid ${theme.colors.gray[1]}`,
          padding: theme.other.spacing(0.75),
          borderRadius: theme.radius.sm,
          fontSize: theme.fontSizes.lg,
          fontWeight: 500,
        })}
        onClick={() => {
          login();
        }}
      >
        {isLogging ? (
          <Loader size={27} />
        ) : (
          <>
            <Box
              sx={theme => ({ marginRight: theme.other.spacing(1) })}
              component="span"
            >
              {icon}
            </Box>
            {t('user:continueWith')} {label}
          </>
        )}
      </UnstyledButton>
    </Box>
  );
};
