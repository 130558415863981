import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, UnstyledButton, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { useGetUser } from 'api/account';
import { getUserInitials } from 'helpers';
import { EditIcon, MailIcon, PhoneIcon } from 'icons';
import { UserType } from 'types';

import { DataItem } from '../DataItem';
import { EditAvatarModal } from './EditAvatarModal';
import { EditPersonalInformationModal } from './EditPersonalInformationModal';

export const PersonalInformation = () => {
  const { t } = useTranslation(['common', 'user']);
  const { hovered: isAvatarHovered, ref: avatarRef } = useHover();

  const [isEditAvatarModalOpen, setIsEditAvatarModalOpen] = useState(false);
  const [
    isEditPersonalInformationModalOpen,
    setIsEditPersonalInformationModalOpen,
  ] = useState(false);

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  const avatarContent = user.avatar ? (
    <Box
      sx={{ width: 50, height: 50 }}
      component="img"
      src={user.avatar}
      alt=""
    />
  ) : (
    <span>{getUserInitials(user.name, user.surname)}</span>
  );

  return (
    <>
      <div>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.other.spacing(2.5),
          })}
        >
          <Text weight={600}>{t('user:personalInformation')}</Text>
          <UnstyledButton
            sx={theme => ({
              fontWeight: 600,
              color: theme.fn.primaryColor(),
            })}
            onClick={() => {
              setIsEditPersonalInformationModalOpen(true);
            }}
          >
            {t('common:edit')}
          </UnstyledButton>
        </Box>
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.other.spacing(3),
          })}
        >
          <Avatar
            sx={theme => ({
              marginRight: theme.other.spacing(1),
            })}
            ref={avatarRef}
            size={50}
          >
            <UnstyledButton
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                fontWeight: 'inherit',
              }}
              onClick={() => {
                setIsEditAvatarModalOpen(true);
              }}
            >
              {isAvatarHovered && (
                <Box
                  sx={theme => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    inset: 0,
                    margin: 'auto',
                    backgroundColor: theme.fn.rgba(theme.white, 0.6),
                  })}
                  component="span"
                >
                  <EditIcon color="black" />
                </Box>
              )}
              {avatarContent}
            </UnstyledButton>
          </Avatar>
          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            weight={600}
          >
            {user.name} {user.surname}
          </Text>
        </Box>
        <DataItem
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          icon={<MailIcon />}
          label={t('common:email2')}
          value={user.email}
        />
        <DataItem
          icon={<PhoneIcon />}
          label={t('common:phoneNumber')}
          value={user.phone}
        />
      </div>
      <EditAvatarModal
        isOpen={isEditAvatarModalOpen}
        onClose={() => {
          setIsEditAvatarModalOpen(false);
        }}
      />
      <EditPersonalInformationModal
        isOpen={isEditPersonalInformationModalOpen}
        onClose={() => {
          setIsEditPersonalInformationModalOpen(false);
        }}
      />
    </>
  );
};
