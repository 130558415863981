import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const NotificationIcon = ({
  color,
  size = 24,
  sx,
  backgroundColor: backgroundColorProp,
  ...props
}: IconPropsType & { backgroundColor?: string }) => {
  const iconColor = useGetColor(color);
  const backgroundColor = useGetColor(backgroundColorProp || 'transparent');

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={backgroundColor}
        d="M17.714 15.429H6.286V9.714a5.714 5.714 0 1 1 11.428 0v5.715Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M4.571 15.429H19.43m-13.143 0V9.714a5.714 5.714 0 1 1 11.428 0v5.715m-8 1.714v.571a2.286 2.286 0 1 0 4.572 0v-.571"
      />
    </Box>
  );
};
