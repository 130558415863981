import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useGetTransactionDataStream,
  usePatchTransactionDataStream,
} from 'api/transactions';
import { useServerErrors, useShowNotification } from 'hooks';
import { DataStreamTemplateType, ValidationErrors } from 'types';

import { Modal } from 'components/shared';
import { DataStreamEditTemplateForm } from 'components/Transactions';

import { getDataStreamTemplateErrors } from '../../../helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  templateId: DataStreamTemplateType['templateId'];
  templateName: DataStreamTemplateType['templateName'];
  templateIndex: number;
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentContent'];
  isBlocked: DataStreamTemplateType['isBlocked'];
  attachTo: DataStreamTemplateType['attachTo'];
  templates: DataStreamTemplateType[];
};

export const DataStreamEditTemplateModal = ({
  isOpen,
  onClose,
  templateId,
  templateName,
  templateIndex,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
  attachTo,
  templates,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const formRef = useRef<HTMLFormElement>(null);

  const { transactionId, transactionDataStream: transactionDataStreamParam } =
    useParams() as {
      transactionDataStream: string;
      transactionId: string;
    };

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const [editStreamServerErrors, setEditStreamServerErrors] =
    useState<ValidationErrors | null>(null);

  const { data: transactionDataStream } = useGetTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStreamParam,
    },
  });
  const {
    mutateAsync: updateTransactionDataStream,
    isLoading: isDataStreamUpdating,
  } = usePatchTransactionDataStream({
    pathParams: {
      transactionId,
      dataStreamId: transactionDataStreamParam,
    },
  });

  const editDataStreamTemplates = async ({
    templateId: updatedTemplateId,
    documentName: updatedDocumentName,
    documentReference: updatedDocumentReference,
    documentExternalId: updatedDocumentExternalId,
    documentContent: updatedDocumentContent,
    isBlocked: updatedIsBlocked,
    attachTo: updatedAttachTo,
  }: Pick<
    DataStreamTemplateType,
    | 'documentName'
    | 'documentExternalId'
    | 'documentReference'
    | 'documentContent'
    | 'isBlocked'
    | 'attachTo'
  > & { templateId: number | null; templateName: string }) => {
    try {
      const templatesToUpdate = transactionDataStream!.templates.map(
        ({ templateName: _, ...template }, index) => {
          if (index === templateIndex) {
            return {
              templateId: updatedTemplateId,
              documentName: updatedDocumentName,
              documentReference: updatedDocumentReference,
              documentExternalId: updatedDocumentExternalId,
              documentContent: updatedDocumentContent,
              isBlocked: updatedIsBlocked,
              attachTo: updatedAttachTo,
            };
          }

          return {
            templateId: template.templateId,
            documentName: template.documentName,
            documentReference: template.documentReference,
            documentExternalId: template.documentExternalId,
            documentContent: template.documentContent,
            isBlocked: template.isBlocked,
            attachTo:
              updatedDocumentContent && template.attachTo === documentContent
                ? updatedDocumentContent
                : template.attachTo,
          };
        },
      ) as DataStreamTemplateType[];

      await updateTransactionDataStream({
        templates: templatesToUpdate,
      });

      showNotification({
        message: t('dataStreams:editModal.dataStreamUpdated'),
      });

      onClose();
    } catch (error) {
      const serverErrorStatus = getServerErrorStatus(error);

      if (serverErrorStatus) {
        setEditStreamServerErrors(
          getDataStreamTemplateErrors(serverErrorStatus),
        );
      }

      addServerErrorActions(error);
    }
  };

  const clearServerError = (errorKey: string) => {
    if (editStreamServerErrors?.[errorKey]) {
      setEditStreamServerErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('dataStreams:editModal.editDataStream')}
      defaultButtonAction={onClose}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
      primaryButtonIsLoading={isDataStreamUpdating}
    >
      <DataStreamEditTemplateForm
        templateId={templateId}
        templateName={templateName}
        documentName={documentName}
        documentExternalId={documentExternalId}
        documentReference={documentReference}
        documentContent={documentContent}
        isBlocked={isBlocked}
        attachTo={attachTo}
        formRef={formRef}
        editDataStreamTemplates={editDataStreamTemplates}
        errors={editStreamServerErrors}
        clearError={clearServerError}
        templates={templates}
      />
    </Modal>
  );
};
