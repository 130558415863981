import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const SuccessBucketIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <path id={`${id}_a`} d="M0 0h26.571v27.22H0z" />
        <path
          d="M0 .878c0 .486.385.878.857.878a.868.868 0 0 0 .857-.878A.868.868 0 0 0 .857 0 .868.868 0 0 0 0 .878"
          id={`${id}_c`}
        />
        <linearGradient
          x1=".03%"
          y1="50.01%"
          x2="99.98%"
          y2="50.01%"
          id={`${id}_d`}
        >
          <stop stopColor="#00B7C4" offset="0%" />
          <stop stopColor="#00B7C4" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M30 .878A.868.868 0 0 0 29.143 0a.868.868 0 0 0-.857.878c0 .485.384.878.857.878A.868.868 0 0 0 30 .878"
          fill="#00B7C4"
          fillRule="nonzero"
        />
        <g transform="translate(0 8.78)">
          <mask id={`${id}_b`} fill="#fff">
            <use xlinkHref={`#${id}_a`} />
          </mask>
          <path
            d="M25.63 16.522C1.74 27.939 3.512 27.21 2.672 27.21c-1.961 0-3.242-2.09-2.42-3.892L10.438.965A1.612 1.612 0 0 1 13.071.49l13.022 13.336c.8.82.563 2.206-.464 2.696"
            fill="#D1CFFC"
            mask={`url(#${id}_b)`}
          />
        </g>
        <path
          fill={iconColor}
          fillRule="nonzero"
          d="M24 20.429v5.702l-7.714 3.65v-17.05zM12 31.89l-7.714 3.601v-12.03L10.49 9.762A1.642 1.642 0 0 1 12 8.78v23.11Z"
        />
        <path
          d="M27.12 9.659c-.672 0-.755-1.01-.09-1.121l.588-.1c1.308-.224 2.258-1.373 2.258-2.73 0-1.913 1.337-3.53 3.179-3.843l.589-.101a.55.55 0 0 1 .634.463.563.563 0 0 1-.452.65l-.59.101c-1.308.223-2.258 1.371-2.258 2.73 0 1.912-1.337 3.528-3.179 3.843-.533.09-.61.108-.68.108"
          fill="#82D3B0"
          fillRule="nonzero"
        />
        <path
          d="M18.428 12.293c-.376 0-.606-.55-.382-.952l.296-.53c.657-1.178.554-2.776-.246-3.8-1.126-1.441-1.271-3.692-.346-5.35l.296-.53c.15-.271.444-.334.656-.14.211.193.26.568.11.839l-.296.53c-.658 1.178-.555 2.776.245 3.8 1.126 1.442 1.271 3.692.346 5.35-.311.558-.387.783-.679.783"
          fill="#1EAF70"
          fillRule="nonzero"
        />
        <path
          d="M32.021 19.317c-1.042 0-2.077-.33-2.865-.976-1-.82-2.56-.925-3.71-.252l-.517.303c-.264.154-.631.104-.82-.112-.188-.217-.127-.517.137-.672l.517-.303c1.62-.948 3.817-.799 5.224.355 1 .819 2.56.925 3.71.251l.517-.303c.264-.154.63-.105.82.112.188.217.127.517-.137.672-.803.47-1.531.925-2.876.925"
          fill="#004FC4"
          fillRule="nonzero"
        />
        <g transform="translate(26.571 14.049)">
          <mask id={`${id}_e`} fill="#fff">
            <use xlinkHref={`#${id}_c`} />
          </mask>
          <path
            d="M0 .878c0 .486.385.878.857.878a.868.868 0 0 0 .857-.878A.868.868 0 0 0 .857 0 .868.868 0 0 0 0 .878"
            fill={`url(#${id}_d)`}
            mask={`url(#${id}_e)`}
          />
        </g>
        <path
          d="M23.143 14.927a.868.868 0 0 0-.857-.878.868.868 0 0 0-.857.878c0 .485.384.878.857.878a.868.868 0 0 0 .857-.878"
          fill="#E6B800"
          fillRule="nonzero"
        />
        <path
          d="M24 10.537a.868.868 0 0 0-.857-.878.868.868 0 0 0-.857.878c0 .484.384.878.857.878a.868.868 0 0 0 .857-.878M24 4.83l-1.285-1.318-1.286 1.317 1.286 1.317z"
          fill="#004FC4"
          fillRule="nonzero"
        />
        <path
          fill="#00B7C4"
          fillRule="nonzero"
          d="m14.571 3.951-1.285-1.317L12 3.951l1.286 1.317z"
        />
        <path
          fill="#E6B800"
          fillRule="nonzero"
          d="m36 9.22-1.285-1.318-1.286 1.318 1.286 1.317z"
        />
        <path
          fill="#00B7C4"
          fillRule="nonzero"
          d="m33.429 23.268-1.286-1.317-1.286 1.317 1.286 1.317z"
        />
        <path fill="#B359D8" d="m30.857 13.445 1.714-1.152" />
        <path
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m30.857 13.445 1.714-1.152"
        />
        <path
          d="M26.286 5.268c-.387 0-.666-.35-.542-.693l.571-1.58c.1-.276.423-.426.723-.333.299.092.461.39.361.666l-.571 1.58a.57.57 0 0 1-.542.36"
          fill="#10821D"
        />
      </g>
    </Box>
  );
};
