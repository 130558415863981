import { apiAuthClient } from 'api/helpers';

import {
  PostDuplicateTemplateRequestType,
  PostDuplicateTemplateResponseType,
} from '../types';

export const postDuplicateTemplate = async ({
  pathParams: { organisationId, boardId, templateId },
  bodyParams,
}: PostDuplicateTemplateRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates/${templateId}/duplicates`;

  const { data } = await apiAuthClient.post<PostDuplicateTemplateResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
