import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { deleteTemplates } from '../requests';
import { DeleteTemplatesRequestType } from '../types';

export const useDeleteTemplates = () => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useMutation({
    mutationFn: ({
      queryParams: { templateIds },
    }: {
      queryParams: DeleteTemplatesRequestType['queryParams'];
    }) =>
      deleteTemplates({
        pathParams: {
          organisationId,
          boardId,
        },
        queryParams: {
          templateIds,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.boardTemplates, { organisationId, boardId }],
      });
    },
  });
};
