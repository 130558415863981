import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDataStreams } from 'api/dataStreams';
import { getCursorFromUrl, getHasHateoasAccess } from 'helpers';
import { useGetOrganisationFeatureAccess, useSearchParams } from 'hooks';
import { URLS } from 'consts';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { Header } from './Header';
import { DataStreamList } from './DataStreamList';

export const DataStreams = () => {
  const { t } = useTranslation(['transactions', 'dataStreams']);

  const {
    data: hasDataStreamsFeature,
    isError: isDataStreamsFeatureError,
    isLoading: isLoadingDataStreamsFeature,
  } = useGetOrganisationFeatureAccess('dataStreams');

  const { getSearchParam, deleteSearchParam } = useSearchParams([
    'page',
    'search',
  ]);

  const page = getSearchParam('page');
  const name = getSearchParam('search');

  const {
    data: dataStreamsData,
    isError: isDataStreamsError,
    isInitialLoading: isDataStreamsInitialLoading,
    isRefetching: isRefetchingDataStreams,
  } = useGetDataStreams(
    { queryParams: { page, name } },
    {
      enabled: !isLoadingDataStreamsFeature && hasDataStreamsFeature,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (dataStreamsData?.data.length === 0 && page !== null) {
      deleteSearchParam('page');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStreamsData?.data, page]);

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={dataStreamsData}
        isError={isDataStreamsError || isDataStreamsFeatureError}
        isLoading={isDataStreamsInitialLoading || isLoadingDataStreamsFeature}
        isNoAccess={!hasDataStreamsFeature && !isLoadingDataStreamsFeature}
        errorMargin="xl"
      >
        {({ data: dataStreams, links, __links }) => {
          const hasDataStreamCreateAccess = getHasHateoasAccess(
            'create',
            __links,
          );

          const prevPage = getCursorFromUrl(links.prev);
          const nextPage = getCursorFromUrl(links.next);

          return (
            <>
              <Breadcrumbs
                items={[
                  {
                    name: t('transactions:transactions'),
                    url: URLS.transactions,
                  },
                  { name: t('dataStreams:dataStreams'), url: '#' },
                ]}
              />
              <Header hasDataStreamCreateAccess={hasDataStreamCreateAccess} />
              <DataStreamList
                hasDataStreamCreateAccess={hasDataStreamCreateAccess}
                dataStreams={dataStreams}
                isRefetchingDataStreams={isRefetchingDataStreams}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
