import {
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import i18 from 'i18next';
import { decamelizeKeys } from 'humps';

import { getBearerTokenStr } from 'helpers';
import { HTTP_HEADERS } from 'consts';
import { env } from 'env';

import { getToken } from './getToken';
import { parseRequestData } from './parseRequestData';

export const getRequestCallback =
  (options = { auth: false }) =>
  (config: AxiosRequestConfig) => {
    const { headers, params, data } = config;
    const { auth } = options;
    const token = getToken();
    const baseURL = env.REACT_APP_API_URL;

    const requestHeaders: RawAxiosRequestHeaders = {
      ...headers,
      ...(!auth && {
        [HTTP_HEADERS.xAppLocale]: i18.language,
      }),
      ...(auth &&
        Boolean(token) && {
          [HTTP_HEADERS.authorization]: getBearerTokenStr(token),
        }),
    };

    return {
      ...config,
      baseURL,
      headers: requestHeaders,
      ...(Boolean(params) && { params: decamelizeKeys(params) }),
      ...(Boolean(data) && { data: parseRequestData(data) }),
    } as InternalAxiosRequestConfig<any>;
  };
