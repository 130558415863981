import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDF_MIME_TYPE } from '@mantine/dropzone';

import {
  useDeleteCustomizationsDocument,
  usePostCustomizationsDocumentPaper,
  usePutCustomizationsDocument,
} from 'api/organisations';
import { URLS } from 'consts';
import { getPdfValidationData, readFile } from 'helpers';
import { useServerErrors, useShowNotification } from 'hooks';
import { OrganisationBrandingConfigType } from 'types';
import { BrandingPreview, FileDropzone } from 'components/shared';

const MAX_PDF_PAGES = 1;

type Props = {
  headedPaperUrl: OrganisationBrandingConfigType['headedPaperUrl'];
  margin: OrganisationBrandingConfigType['margin'];
  isRefetching: boolean;
};

export const Branding = ({ headedPaperUrl, margin, isRefetching }: Props) => {
  const { t } = useTranslation(['common', 'organisations']);

  const { addServerErrorActions, getServerFieldError } = useServerErrors();
  const showNotification = useShowNotification();

  const [paperError, setPaperError] = useState('');

  const { mutateAsync: uploadPaper, isLoading: isUploadingPaper } =
    usePostCustomizationsDocumentPaper();
  const {
    mutateAsync: deleteLetterheadCustomization,
    isLoading: isDeletingLetterheadCustomization,
    isSuccess: isDeleteLetterheadCustomizationSuccess,
    reset,
  } = useDeleteCustomizationsDocument();
  const {
    mutateAsync: updateBrandingConfig,
    isLoading: isUpdatingBrandingConfig,
  } = usePutCustomizationsDocument();

  return headedPaperUrl && !isDeleteLetterheadCustomizationSuccess ? (
    <BrandingPreview
      headedPaperUrl={headedPaperUrl}
      margin={margin}
      urlToRedirectAfterCancel={URLS.home}
      deleteLetterhead={async () => {
        try {
          await deleteLetterheadCustomization();

          showNotification({
            message: t('organisations:letterheadDeleted'),
          });
        } catch (error) {
          addServerErrorActions(error);
        }
      }}
      updateBrandingConfig={async updatedMargin => {
        try {
          await updateBrandingConfig({
            margin: updatedMargin,
          });

          showNotification({
            message: t('organisations:updatedBrandingConfig'),
          });
        } catch (error) {
          addServerErrorActions(error);
        }
      }}
      isDeletingLetterhead={isDeletingLetterheadCustomization}
      isUpdatingBrandingConfig={isUpdatingBrandingConfig}
    />
  ) : (
    <FileDropzone
      sx={{ maxWidth: 523, margin: 'auto' }}
      error={paperError}
      setError={setPaperError}
      onFileDrop={async file => {
        setPaperError('');

        const { isEncrypted, pagesLength } = await getPdfValidationData(
          await readFile(file, 'arrayBuffer'),
        );

        if (isEncrypted) {
          setPaperError(t('common:formErrors.fileIsEncrypted'));
          return;
        }

        if (pagesLength > MAX_PDF_PAGES) {
          setPaperError(
            t('common:formErrors.pdfPagesLength', { number: MAX_PDF_PAGES }),
          );
          return;
        }

        const formData = new FormData();

        formData.append('paper', file);

        reset();

        try {
          await uploadPaper({ formData });

          showNotification({
            message: t('organisations:letterheadAdded'),
          });
        } catch (error) {
          setPaperError(getServerFieldError(error, 'paper'));
          addServerErrorActions(error);
        }
      }}
      isLoading={
        isUploadingPaper ||
        (isRefetching && !isDeleteLetterheadCustomizationSuccess)
      }
      accept={PDF_MIME_TYPE}
      acceptText="PDF"
      additionalText={t('organisations:brandingLetterheadSize')}
      maxSizeInKB={5000}
    />
  );
};
