import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, UnstyledButton } from '@mantine/core';

import { getInputVariableValue } from 'helpers';
import { AddButtonSubtle, NotFound, TextInputSearch } from 'components/shared';
import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { GroupIcon, List2Icon } from 'legacy/TemplateCreatorEditor/icons';
import { VariableAddModal } from 'legacy/TemplateCreatorEditor/VariableAddModal';
import { VariableEditModal } from 'legacy/TemplateCreatorEditor/VariableEditModal';

import { GroupList } from './GroupList';
import { List } from './List';

export const Variables = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const variables = editor.getVariables();

  const [isGroupViewEnabled, setIsGroupViewEnabled] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const [isVariableAddModalOpen, setIsVariableAddModalOpen] = useState(false);
  const [activeVariable, setActiveVariable] = useState(null);

  const filteredVariables = variables
    .filter(({ name }) =>
      name.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .sort((prev, next) => prev.name.localeCompare(next.name));

  const buttonSx = (theme, isActive) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 38,
    height: 38,
    backgroundColor: isActive ? theme.colors[theme.primaryColor][1] : undefined,
  });

  const getVariablesView = () => {
    if (variables.length === 0) {
      return null;
    }

    if (filteredVariables.length === 0) {
      return (
        <NotFound
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
        />
      );
    }

    return isGroupViewEnabled ? (
      <GroupList
        filteredVariables={filteredVariables}
        setActiveVariable={setActiveVariable}
      />
    ) : (
      <List
        filteredVariables={filteredVariables}
        setActiveVariable={setActiveVariable}
      />
    );
  };

  return (
    <>
      <div>
        <Box
          sx={theme => ({
            display: 'flex',
            columnGap: theme.other.spacing(1),
            marginBottom: theme.other.spacing(3),
          })}
        >
          <TextInputSearch
            styles={{
              wrapper: {
                height: '100%',
              },
              input: {
                height: '100%',
              },
            }}
            placeholder={t('templates:templateCreatorVariables.search')}
            value={searchValue}
            onChange={e => {
              setSearchValue(getInputVariableValue(e.target.value));
            }}
          />
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <UnstyledButton
              sx={theme => ({
                ...buttonSx(theme, isGroupViewEnabled),
                border: `1px solid ${theme.colors.gray[1]}`,
                borderRight: 'none',
                borderTopLeftRadius: theme.radius.sm,
                borderBottomLeftRadius: theme.radius.sm,
              })}
              onClick={() => {
                setIsGroupViewEnabled(true);
              }}
            >
              <GroupIcon color={isGroupViewEnabled ? 'primary' : undefined} />
            </UnstyledButton>
            <UnstyledButton
              sx={theme => ({
                ...buttonSx(theme, !isGroupViewEnabled),
                border: `1px solid ${theme.colors.gray[1]}`,
                borderTopRightRadius: theme.radius.sm,
                borderBottomRightRadius: theme.radius.sm,
              })}
              onClick={() => {
                setIsGroupViewEnabled(false);
              }}
            >
              <List2Icon color={isGroupViewEnabled ? undefined : 'primary'} />
            </UnstyledButton>
          </Box>
        </Box>
        <AddButtonSubtle
          sx={theme => ({
            marginBottom: theme.other.spacing(1),
            paddingRight: theme.other.spacing(1),
            paddingLeft: theme.other.spacing(1),
            fontWeight: 600,
          })}
          onClick={() => {
            setIsVariableAddModalOpen(true);
          }}
        >
          {t('templates:addNewVariable')}
        </AddButtonSubtle>
        <Box
          sx={theme => ({
            maxHeight: 550,
            overflowY: 'auto',
            marginLeft: theme.other.spacing(-1),
            padding: theme.other.spacing(1, 0, 1.25, 1),
          })}
        >
          {getVariablesView()}
        </Box>
      </div>
      <VariableAddModal
        isOpen={isVariableAddModalOpen}
        onClose={() => {
          setIsVariableAddModalOpen(false);
        }}
      />
      {Boolean(activeVariable) && (
        <VariableEditModal
          isOpen
          onClose={() => {
            setActiveVariable(null);
          }}
          variableName={activeVariable}
        />
      )}
    </>
  );
};
