import { Box, DefaultProps, keyframes, packSx } from '@mantine/core';

type Props = {
  sx?: DefaultProps['sx'];
};

export const PulsatingDot = ({ sx }: Props) => (
  <Box
    sx={[
      theme => {
        const orangePrimaryColor = theme.colors.orange[theme.fn.primaryShade()];

        const pulseAnimation = keyframes({
          '0%': {
            transform: 'scale(0.95)',
            boxShadow: `0 0 0 0 ${theme.fn.rgba(orangePrimaryColor, 0.7)}`,
          },
          '70%': {
            transform: 'scale(1)',
            boxShadow: `0 0 0 5px ${theme.fn.rgba(orangePrimaryColor, 0)}`,
          },

          '100%': {
            transform: 'scale(0.95)',
            boxShadow: `0 0 0 0 ${theme.fn.rgba(orangePrimaryColor, 0)}`,
          },
        });

        return {
          borderRadius: '50%',
          height: 10,
          width: 10,
          transform: 'scale(1)',
          background: 'rgba(255, 121, 63, 1)',
          boxShadow: `0 0 0 0 ${theme.fn.rgba(orangePrimaryColor, 1)}`,
          animation: `${pulseAnimation} 2s infinite`,
        };
      },
      ...packSx(sx),
    ]}
  />
);
