import { isBefore } from 'date-fns';

import { TechnicalUserType } from 'types';

export const getBearerTokenColorStatus = (
  bearerToken: TechnicalUserType['bearerToken'],
) => {
  const hasToken = Boolean(bearerToken?.token && bearerToken?.expiresAt);
  const hasExpired =
    hasToken && isBefore(new Date(bearerToken.expiresAt as string), new Date());

  if (hasExpired) {
    return 'orange';
  }

  return hasToken ? 'green' : 'red';
};
