import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { DYNAMIC_URLS, URLS } from 'consts';

import {
  Account,
  Api,
  DataStreamAdd,
  DataStreamEdit,
  DataStreams,
  DataTypes,
  Error,
  Home,
  Invitation,
  NotFound,
  OauthAzure,
  OauthGoogle,
  OrganisationBranding,
  OrganisationMailCustomization,
  Registration,
  RequestPasswordReset,
  ResetPassword,
  TemplateAdd,
  TemplateAttachmentAdd,
  TemplateAttachmentAddFromTemplate,
  TemplateAttachmentEdit,
  TemplateBranding,
  TemplateEdit,
  Templates,
  TransactionDetails,
  TransactionEntries,
  TransactionEntry,
  Transactions,
} from 'pages';
import { Layout } from 'components/Layout';
import { LoggedUserProviders } from 'components/LoggedUserProviders';
import { MetaTitle } from 'components/shared';

import {
  AuthController,
  GuideTourController,
  ProtectedRouteController,
  RegistrationConfirmController,
  /* TODO: remove when PERG-558 is closed*/
  // RequiredUserAgreementsController,
} from './controllers';

const router = createBrowserRouter(
  [
    {
      errorElement: <Error />,
      children: [
        { path: URLS.oauthAzure, element: <OauthAzure /> },
        { path: URLS.oauthGoogle, element: <OauthGoogle /> },
        { path: URLS.verifyEmail, element: <RegistrationConfirmController /> },
        {
          element: (
            <AuthController>
              <LoggedUserProviders>
                <MetaTitle />
                <GuideTourController>
                  <Layout />
                </GuideTourController>
              </LoggedUserProviders>
            </AuthController>
          ),
          children: [
            { path: URLS.account, element: <Account /> },
            { path: URLS.api, element: <Api /> },
            { path: URLS.dataStreams, element: <DataStreams /> },
            { path: URLS.dataStreamAdd, element: <DataStreamAdd /> },
            { path: URLS.templateAdd, element: <TemplateAdd /> },
            { path: URLS.templates, element: <Templates /> },
            {
              path: DYNAMIC_URLS.dataStreamEdit({
                dataStreamId: ':dataStreamId',
              }),
              element: <DataStreamEdit />,
            },
            { path: URLS.dataTypes, element: <DataTypes /> },
            {
              path: DYNAMIC_URLS.invitation({ invitationId: ':invitationId' }),
              element: <Invitation />,
            },
            {
              path: URLS.home,
              element: <Home />,
            },
            {
              path: DYNAMIC_URLS.templateAttachmentAdd({
                templateId: ':templateId',
              }),
              element: <TemplateAttachmentAdd />,
            },
            {
              path: DYNAMIC_URLS.templateAttachmentAddFromTemplate({
                templateId: ':templateId',
                baseTemplateId: ':baseTemplateId',
              }),
              element: <TemplateAttachmentAddFromTemplate />,
            },
            {
              path: DYNAMIC_URLS.templateAttachmentEdit({
                templateId: ':templateId',
                attachmentName: ':attachmentName',
              }),
              element: <TemplateAttachmentEdit />,
            },
            {
              path: DYNAMIC_URLS.templateBranding({
                templateId: ':templateId',
              }),
              element: <TemplateBranding />,
            },
            {
              path: DYNAMIC_URLS.templateEdit({
                templateId: ':templateId',
              }),
              element: <TemplateEdit />,
            },
            {
              path: DYNAMIC_URLS.transactionDetails({
                transactionId: ':transactionId',
                transactionDataStream: ':transactionDataStream',
              }),
              element: <TransactionDetails />,
            },
            {
              path: DYNAMIC_URLS.transactionDetails({
                transactionId: ':transactionId',
                transactionDataStream: ':transactionDataStream',
                tab: ':tab',
              }),
              element: <TransactionDetails />,
            },
            {
              path: DYNAMIC_URLS.transactionEntries({
                transactionId: ':transactionId',
                transactionDataStream: ':transactionDataStream',
              }),
              element: <TransactionEntries />,
            },
            {
              path: DYNAMIC_URLS.transactionEntry({
                transactionId: ':transactionId',
                transactionDataStream: ':transactionDataStream',
                transactionEntry: ':transactionEntry',
              }),
              element: <TransactionEntry />,
            },
            {
              path: DYNAMIC_URLS.transactionEntry({
                transactionId: ':transactionId',
                transactionDataStream: ':transactionDataStream',
                transactionEntry: ':transactionEntry',
                tab: ':tab',
              }),
              element: <TransactionEntry />,
            },
            { path: URLS.transactions, element: <Transactions /> },
            {
              path: DYNAMIC_URLS.organisationBranding({
                organisationId: ':organisationId',
              }),
              element: <OrganisationBranding />,
            },
            {
              path: DYNAMIC_URLS.organisationMailCustomization({
                organisationId: ':organisationId',
              }),
              element: <OrganisationMailCustomization />,
            },
          ],
        },
        {
          element: <ProtectedRouteController />,
          children: [
            {
              path: URLS.register,
              element: <Registration />,
            },
            {
              path: URLS.requestPasswordReset,
              element: <RequestPasswordReset />,
            },
            {
              path: URLS.resetPassword,
              element: <ResetPassword />,
            },
          ],
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ],
  { basename: '/v2' },
);

export const Router = () => <RouterProvider router={router} />;
