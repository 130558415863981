import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Text } from '@mantine/core';
import { AddButtonSubtle, DescriptionField } from 'components/shared';
import { useGetColumnWidths } from 'components/shared/DescriptionField/hooks';

export const Required = ({ checkboxProps }) => {
  const { t } = useTranslation('templates');

  const { leftColumnWidth, rightColumnWidth } = useGetColumnWidths();

  const [isRequired, setIsRequired] = useState(false);

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.isRequired')}
      description={t(
        'templates:templateCreatorVariables.isRequiredDescription',
      )}
      withoutBorder
      bottomSection={
        isRequired ? (
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginTop: theme.other.spacing(2),
              [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                flexDirection: 'row',
              },
            })}
          >
            <Text
              sx={{
                width: leftColumnWidth,
              }}
              size="md"
              weight={600}
            >
              {t('templates:templateCreatorVariables.requiredWhen')}
            </Text>
            <Box sx={{ width: rightColumnWidth }}>
              <AddButtonSubtle
                sx={theme => ({
                  fontSize: theme.fontSizes.lg,
                  paddingRight: theme.other.spacing(1),
                  paddingLeft: theme.other.spacing(1),
                })}
                onClick={() => {
                  alert('COMING SOON');
                }}
              >
                {t('templates:templateCreatorVariables.addCondition')}
              </AddButtonSubtle>
            </Box>
          </Box>
        ) : undefined
      }
    >
      <Checkbox
        {...checkboxProps}
        sx={{ margin: 0 }}
        checked={isRequired}
        onChange={e => {
          setIsRequired(e.target.checked);
        }}
      />
    </DescriptionField>
  );
};
