import {
  isMarkdown,
  toggleMarkdown,
} from 'legacy/TemplateCreatorEditor/plugins/helpers';

export const bold = {
  renderMark: ({ attributes, mark, children }, editor, next) => {
    if (mark.type === 'bold') {
      return <b {...attributes}>{children}</b>;
    }

    return next();
  },
  queries: {
    isBold: editor => isMarkdown(editor, 'bold'),
  },
  commands: {
    toggleBold: editor => {
      toggleMarkdown(editor, 'bold');
    },
  },
};
