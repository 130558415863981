import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetOrganisationTemplatesResponseType,
  GetOrganisationTemplatesRequestType,
} from '../types';

export const getOrganisationTemplates = async ({
  pathParams: { organisationId },
  queryParams: { name, page, perPage },
}: GetOrganisationTemplatesRequestType) => {
  const query = stringifyUrlQuery({
    name,
    page,
    perPage,
  });

  const url = `/api/v2/organisations/${organisationId}/templates${query}`;

  const { data } =
    await apiAuthClient.get<GetOrganisationTemplatesResponseType>(url);

  return data;
};
