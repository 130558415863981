import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Accordion, Box, Button, Text, Tooltip } from '@mantine/core';

import {
  SignatureAnyIcon,
  SignatureQualifiedIcon,
  SignatureSmsIcon,
  StarIcon,
} from 'icons';
import { SIGN_TYPES } from 'consts';
import { useIsMdBreakpoint } from 'hooks';

const SIGN_TYPE_ITEMS = [
  {
    value: SIGN_TYPES.sms,
    Icon: SignatureSmsIcon,
    titleTranslation: 'common:signTypes.sms',
    descriptionTranslation: 'common:signTypeDescriptions.sms',
  },
  {
    value: SIGN_TYPES.qualified,
    Icon: SignatureQualifiedIcon,
    titleTranslation: 'common:signTypes.qualified',
    descriptionTranslation: 'common:signTypeDescriptions.qualified',
  },
  {
    value: SIGN_TYPES.any,
    Icon: SignatureAnyIcon,
    titleTranslation: 'common:signTypes.any',
    descriptionTranslation: 'common:signTypeDescriptions.any',
  },
  {
    value: SIGN_TYPES.hybrid,
    Icon: StarIcon,
    titleTranslation: 'common:signTypes.hybrid',
    descriptionTranslation: 'common:signTypeDescriptions.hybrid',
  },
];

const ICON_WRAPPER_SIZE = 34;
const ICON_GAP_VALUE = 2;

type Props = {
  signType: '' | (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES];
  signTypeRadioProps: UseFormRegisterReturn;
  allowedItems: (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES][] | 'all';
};

export const SignTypeRadio = ({
  signType,
  signTypeRadioProps,
  allowedItems = 'all',
}: Props) => {
  const { t } = useTranslation(['common', 'templates']);

  const isMdBreakpoint = useIsMdBreakpoint();

  const [accordionValues, setAccordionValues] = useState<
    (typeof SIGN_TYPES)[keyof typeof SIGN_TYPES][]
  >([]);

  return (
    <Accordion
      sx={theme => ({
        display: 'grid',
        rowGap: theme.other.spacing(2),
      })}
      styles={theme => ({
        content: {
          padding: theme.other.spacing(0, 2),
        },
      })}
      variant="contained"
      multiple
      value={accordionValues}
    >
      {SIGN_TYPE_ITEMS.map(
        ({ value, Icon, titleTranslation, descriptionTranslation }) => {
          const disabled =
            allowedItems === 'all'
              ? false
              : !allowedItems.some(allowedItem => allowedItem === value);

          return (
            <Box
              sx={{
                position: 'relative',
              }}
              component="label"
              key={value}
            >
              <Box
                sx={theme => ({
                  width: 15,
                  height: 15,
                  position: 'absolute',
                  visibility: 'hidden',
                  ':disabled': {
                    '& +.mantine-Accordion-item': {
                      backgroundColor: theme.colors.gray[1],
                      borderColor: theme.colors.gray[2],
                    },
                  },
                })}
                component="input"
                type="radio"
                disabled={disabled}
                value={value}
                {...signTypeRadioProps}
              />

              <Accordion.Item
                sx={theme => {
                  const itemBackgroundColor =
                    signType === value ? theme.colors.violet[2] : undefined;

                  return {
                    padding: theme.other.spacing(1.75, 0),
                    borderRadius: theme.radius.sm,
                    cursor: disabled ? undefined : 'pointer',
                    '&&': {
                      border: `1px solid ${theme.colors.violet[2]}`,
                      ':hover': {
                        boxShadow: disabled ? undefined : theme.shadows.xs,
                      },
                    },
                    background: itemBackgroundColor,
                    '&[data-active]': {
                      backgroundColor: itemBackgroundColor,
                    },
                  };
                }}
                value={value}
                tabIndex={0}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();

                    (e.target as HTMLDivElement).click();
                  }
                }}
              >
                <Tooltip
                  disabled={!disabled}
                  label={t('templates:optionIsNotAvailableForTemplateType')}
                >
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: theme.other.spacing(0, 2),
                      gap: theme.other.spacing(1),
                    })}
                  >
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.other.spacing(ICON_GAP_VALUE),
                        overflow: 'hidden',
                      })}
                    >
                      <Box
                        sx={theme => ({
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexShrink: 0,
                          backgroundColor: disabled
                            ? theme.colors.gray[2]
                            : theme.colors.violet[1],
                          borderRadius: theme.radius.sm,
                          width: ICON_WRAPPER_SIZE,
                          height: ICON_WRAPPER_SIZE,
                        })}
                      >
                        <Icon color={disabled ? 'gray.4' : 'primary'} />
                      </Box>
                      <Text
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        weight={600}
                        color={disabled ? 'gray.3' : undefined}
                      >
                        {t(titleTranslation)}
                      </Text>
                    </Box>
                    {!disabled && (
                      <Button
                        sx={theme => ({
                          height: 'initial',
                          padding: 0,
                          fontSize: theme.fontSizes.lg,
                          fontWeight: 600,
                        })}
                        variant="subtle"
                        onClick={e => {
                          e.stopPropagation();

                          setAccordionValues(currentAccordionValue =>
                            currentAccordionValue.includes(value)
                              ? currentAccordionValue.filter(
                                  accordionValue => accordionValue !== value,
                                )
                              : [...currentAccordionValue, value],
                          );
                        }}
                      >
                        {isMdBreakpoint ? (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {accordionValues.includes(value)
                              ? t('common:collapse')
                              : t('common:expand')}
                          </>
                        ) : (
                          <Accordion.Control />
                        )}
                      </Button>
                    )}
                  </Box>
                </Tooltip>
                <Accordion.Panel>
                  <Text
                    sx={theme => ({
                      marginLeft:
                        ICON_WRAPPER_SIZE + theme.other.spacing(ICON_GAP_VALUE),
                      letterSpacing: -0.07,
                    })}
                  >
                    {t(descriptionTranslation)}
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            </Box>
          );
        },
      )}
    </Accordion>
  );
};
