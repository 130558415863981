import { useInfiniteQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { getInfinityQueryCursorNextPageParam } from 'helpers';

import { getNotifications } from '../requests';
import {
  GetNotificationsRequestType,
  GetNotificationsResponseType,
} from '../types';

export const useGetNotificationsInfinite = (
  {
    queryParams: {
      category,
      isRead,
      page,
      perPage,
      priority,
      sortBy,
      sortDir,
      type,
    },
  }: GetNotificationsRequestType,
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
    refetchInterval?: number;
    onSuccess?: ({
      pageParams,
      pages,
    }: {
      pageParams: unknown[];
      pages: GetNotificationsResponseType[];
    }) => void;
  },
) =>
  useInfiniteQuery({
    queryKey: [
      API_QUERY_KEYS.notifications,
      {
        category,
        isRead,
        page,
        perPage,
        priority,
        sortBy,
        sortDir,
        type,
      },
    ],
    queryFn: ({ pageParam }) =>
      getNotifications({
        queryParams: {
          category,
          isRead,
          page: pageParam,
          perPage,
          priority,
          sortBy,
          sortDir,
          type,
        },
      }),
    getNextPageParam: getInfinityQueryCursorNextPageParam,
    ...options,
  });
