import { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';
import { Editor } from 'slate-react';
import { Value } from 'slate';

import { DEFAULT_CONTRACT_TEMPLATE } from 'consts';
import { getAreObjectsEqualByJsonRepresentation } from 'helpers';
import { FieldErrorMessage } from 'components/shared';
import { CONFIG } from 'legacy/TemplateCreatorEditor/consts';
import {
  bold,
  header,
  input,
  italic,
  loopWrapper,
  pageBreak,
  paragraph,
  resize,
  root,
  signatures,
  staticTable,
  strikethrough,
  title,
  tooltip,
  underline,
} from './plugins';
import { useGetErrors } from 'legacy/TemplateCreatorEditor/hooks';

const plugins = [
  bold,
  header,
  input,
  italic,
  loopWrapper,
  pageBreak,
  paragraph,
  resize,
  root,
  signatures,
  staticTable,
  strikethrough,
  title,
  tooltip,
  underline,
];

export const TemplateCreatorEditor = ({ editorRef, defaultValue, sx }) => {
  const { t } = useTranslation('templates');

  const getErrors = useGetErrors();

  const [value, setValue] = useState(
    Value.fromJSON(defaultValue || DEFAULT_CONTRACT_TEMPLATE),
  );
  const [generalError, setGeneralError] = useState('');
  const [errors, setErrors] = useState([]);

  const editorValueJson = value.toJSON();

  const isEditorEmpty = getAreObjectsEqualByJsonRepresentation(
    editorValueJson.document.nodes,
    DEFAULT_CONTRACT_TEMPLATE.document.nodes,
  );

  const triggerValidationAndGetErrors = () => {
    const newErrors = getErrors(editorValueJson);

    setErrors(newErrors);

    return newErrors;
  };

  useImperativeHandle(editorRef, () => ({
    editorValueJson,
    setGeneralError,
    triggerValidationAndGetErrors,
  }));

  useEffect(() => {
    if (defaultValue) {
      setValue(Value.fromJSON(defaultValue));
    }
  }, [defaultValue]);

  return (
    <Box sx={sx}>
      <Editor
        style={{
          minHeight: 600,
          fontSize: CONFIG.fontSize,
          lineHeight: CONFIG.lineHeight,
          textAlign: CONFIG.textAlign,
        }}
        value={value}
        onChange={({ value }) => {
          setGeneralError('');
          setValue(value);
        }}
        plugins={plugins}
        placeholder={
          isEditorEmpty ? (
            <Text
              sx={theme => ({
                position: 'absolute',
                top: 127,
                left: 64,
                fontSize: theme.fontSizes.xl * 1.5,
                letterSpacing: -0.1,
              })}
              weight={600}
              color="gray.7"
            >
              {t('templates:startHere')}
            </Text>
          ) : undefined
        }
        // custom props
        errors={errors}
      />
      <FieldErrorMessage>{generalError}</FieldErrorMessage>
    </Box>
  );
};
