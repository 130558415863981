import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodSelectVariableSchema = () => {
  const { t } = useTranslation(['common', 'templates']);

  return z
    .object({
      label: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      hint: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      apiDescription: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        }),
      options: z.array(
        z.object({
          value: z
            .string()
            .trim()
            .max(TEXT_LIMIT, {
              message: t('common:formErrors.maxNumberOfSigns', {
                max: TEXT_LIMIT,
              }),
            }),
        }),
      ),
      initialValue: z
        .string()
        .trim()
        .max(TEXT_LIMIT, {
          message: t('common:formErrors.maxNumberOfSigns', {
            max: TEXT_LIMIT,
          }),
        })
        .optional(),
      required: z.boolean(),
      requiredWhen: z.null(),
    })
    .refine(({ options }) => options.every(({ value }) => value), {
      message: t('templates:templateCreatorErrors.allFieldsNeedToBeFilled'),
      path: ['options'],
    });
};
