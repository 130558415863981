import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Button, DefaultProps, packSx } from '@mantine/core';

import { URLS } from 'consts';
import { SectionHeader } from 'components/shared';

type Props = {
  hasUpdateDataStreamAccess: boolean;
  onUpdate: () => void;
  isUpdatingDataStream: boolean;
  sx?: DefaultProps['sx'];
};

export const Header = ({
  hasUpdateDataStreamAccess,
  onUpdate,
  isUpdatingDataStream,
  sx,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
        },
        ...packSx(sx),
      ]}
    >
      <SectionHeader
        sx={theme => ({
          marginRight: theme.other.spacing(1),
        })}
      >
        {t('dataStreams:dataStreamEditHeader')}
      </SectionHeader>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Anchor
          sx={theme => ({
            marginRight: theme.other.spacing(2),
          })}
          component={Link}
          to={URLS.dataStreams}
          onClick={e => {
            if (isUpdatingDataStream) {
              e.preventDefault();
            }
          }}
        >
          <Button variant="default" disabled={isUpdatingDataStream}>
            {t('common:cancel')}
          </Button>
        </Anchor>
        {hasUpdateDataStreamAccess && (
          <Button
            color="blue"
            loading={isUpdatingDataStream}
            onClick={onUpdate}
          >
            {t('common:save')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
