import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Menu } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';

import {
  useGetBoardTemplatesInfinite,
  useGetTemplate,
} from 'api/templates/hooks';
import { DYNAMIC_URLS } from 'consts';
import { TemplatesIcon } from 'icons';
import { AddButtonSubtle, Error } from 'components/shared';

import { AttachmentTemplates } from './AttachmentTemplates';

const MENU_DROPDOWN_HEIGHT = 500;
const LOADER_SIZE = 36;

export const Documents = () => {
  const { t } = useTranslation('templates');

  const { templateId } = useParams();

  const { data: template } = useGetTemplate({
    pathParams: { templateId },
  });

  const listRef = useRef(null);

  const { ref: lastNotificationRef, entry: lastNotificationEntry } =
    useIntersection({
      root: listRef.current,
      threshold: 1,
    });

  const {
    data: boardTemplatesData,
    isError: isBoardTemplatesError,
    isLoading: isBoardTemplatesLoading,
    isFetchingNextPage: isFetchingBoardTemplatesNextPage,
    fetchNextPage: fetchBoardTemplatesNextPage,
  } = useGetBoardTemplatesInfinite(
    {
      queryParams: {
        public: 0,
        base: 0,
      },
    },
    { enabled: Boolean(template) },
  );

  useEffect(() => {
    if (lastNotificationEntry?.isIntersecting) {
      fetchBoardTemplatesNextPage();
    }
  }, [fetchBoardTemplatesNextPage, lastNotificationEntry?.isIntersecting]);

  const { attachmentTemplates } = template;
  const boardTemplates =
    boardTemplatesData?.pages.map(({ data }) => data)?.flat() || [];

  return (
    <div>
      <Menu
        styles={theme => ({
          itemLabel: {
            display: 'flex',
            alignItems: 'center',
            columnGap: theme.other.spacing(1),
            minWidth: 240,
          },
        })}
        withinPortal
      >
        <Menu.Target>
          <AddButtonSubtle
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
              paddingRight: theme.other.spacing(1),
              paddingLeft: theme.other.spacing(1),
              fontWeight: 600,
            })}
          >
            {t('templates:addNewDocument')}
          </AddButtonSubtle>
        </Menu.Target>
        <Menu.Dropdown
          sx={{
            maxHeight: MENU_DROPDOWN_HEIGHT,
            overflow: 'auto',
            '[data-menu-dropdown="true"]': {
              position: 'relative',
            },
          }}
        >
          <LoadingOverlay
            loaderProps={{
              sx: {
                position: 'absolute',
                bottom: (MENU_DROPDOWN_HEIGHT - LOADER_SIZE) / 2,
              },
              size: LOADER_SIZE,
            }}
            visible={
              isBoardTemplatesLoading || isFetchingBoardTemplatesNextPage
            }
          />
          <AddButtonSubtle
            sx={theme => ({
              padding: theme.other.spacing(0, 2, 0, 3),
              width: '100%',
              minHeight: 40,
              fontSize: theme.fontSizes.lg,
              ':hover': {
                backgroundColor: theme.colors[theme.primaryColor][1],
              },
            })}
            styles={{
              inner: {
                justifyContent: 'flex-start',
              },
            }}
            component={Link}
            to={DYNAMIC_URLS.templateAttachmentAdd({
              templateId,
            })}
          >
            {t('templates:createNewDocument')}
          </AddButtonSubtle>
          {boardTemplates.length > 0 && (
            <Menu.Label
              sx={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
            >
              {t('templates:useTemplate')}
            </Menu.Label>
          )}
          {isBoardTemplatesError ? (
            <Error sx={theme => ({ padding: theme.other.spacing(1) })} />
          ) : (
            boardTemplates.map(({ id, name }, index) => (
              <Menu.Item
                key={id}
                component={Link}
                ref={
                  index === boardTemplates.length - 1
                    ? lastNotificationRef
                    : undefined
                }
                to={DYNAMIC_URLS.templateAttachmentAddFromTemplate({
                  templateId,
                  baseTemplateId: id,
                })}
                title={name}
              >
                <TemplatesIcon />
                <Box
                  sx={{
                    maxWidth: 280,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  component="span"
                >
                  {name}
                </Box>
              </Menu.Item>
            ))
          )}
        </Menu.Dropdown>
      </Menu>
      {attachmentTemplates.length > 0 && <AttachmentTemplates />}
    </div>
  );
};
