import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Anchor, Box, Text } from '@mantine/core';

import { useGetCurrentOrganisation } from 'api/organisations';
import { BRANDING_SOURCE, DYNAMIC_URLS } from 'consts';
import { useGetV1Url } from 'hooks';
import { LetterheadIcon, NoLetterheadIcon } from 'icons';

type Props = {
  source: (typeof BRANDING_SOURCE)[keyof typeof BRANDING_SOURCE];
};
export const LetterheadInfo = ({ source }: Props) => {
  const { templateId } = useParams() as {
    templateId: string;
  };

  const getV1Url = useGetV1Url();

  const { data: organisation } = useGetCurrentOrganisation();

  if (!organisation) {
    return null;
  }

  const letterheadIconSx = {
    flexShrink: 0,
  };

  const organisationsV1Url = getV1Url('organisations');
  const templateBrandingUrl = DYNAMIC_URLS.templateBranding({ templateId });

  const getTranslation = () => {
    switch (source) {
      case 'organisation':
        return (
          <Trans
            i18nKey="templates:letterheadAttachedForOrganisation"
            values={{
              organisation: organisation.name,
            }}
            components={{
              a: <Anchor href={organisationsV1Url} />,
              a2: <Anchor component={Link} to={templateBrandingUrl} />,
            }}
          />
        );
      case 'template':
        return (
          <Trans
            i18nKey="templates:letterheadAttachedInTemplate"
            components={{
              a: <Anchor component={Link} to={templateBrandingUrl} />,
              a2: <Anchor href={organisationsV1Url} />,
            }}
          />
        );
      case 'disabled':
        return (
          <Trans
            i18nKey="templates:letterheadNotAttachedForTemplate"
            components={{
              a: <Anchor href={organisationsV1Url} />,
              a2: <Anchor component={Link} to={templateBrandingUrl} />,
            }}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.other.spacing(1),
      })}
    >
      {source === 'disabled' ? (
        <NoLetterheadIcon sx={letterheadIconSx} color="orange" />
      ) : (
        <LetterheadIcon sx={letterheadIconSx} color="primary" />
      )}
      <Text size="md">{getTranslation()}</Text>
    </Box>
  );
};
