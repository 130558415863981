import { NavLink } from '@mantine/core';

import { LinkType } from 'components/Layout/Navbar/types';
import {
  useGetIsMatch,
  useGetLinkRestProps,
} from 'components/Layout/Navbar/hooks';
import { LinkLabel } from 'components/Layout/Navbar/LinkLabel';

type Props = {
  links: LinkType[];
  localContractsCount: number;
};

export const Links = ({ links, localContractsCount }: Props) => {
  const getIsMatch = useGetIsMatch();
  const getLinkRestProps = useGetLinkRestProps();

  return (
    <>
      {links.map(link => {
        const {
          translateKey,
          icon: Icon,
          hrefKey,
          toKey,
          links: nestedLinks,
          defaultOpened = false,
          showLocalContractsCount = false,
        } = link;
        const linkRestProps = getLinkRestProps(hrefKey, toKey);
        const isMatch = getIsMatch(link);

        return (
          <NavLink
            key={translateKey}
            label={
              <LinkLabel
                translateKey={translateKey}
                showLocalContractsCount={showLocalContractsCount}
                localContractsCount={localContractsCount}
              />
            }
            icon={Icon ? <Icon /> : undefined}
            defaultOpened={defaultOpened}
            sx={theme => ({
              ...(isMatch && {
                color: theme.fn.primaryColor(),
                fontWeight: 600,
              }),
            })}
            {...linkRestProps}
          >
            {nestedLinks ? (
              <Links
                links={nestedLinks}
                localContractsCount={localContractsCount}
              />
            ) : undefined}
          </NavLink>
        );
      })}
    </>
  );
};
