import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, Divider, Text } from '@mantine/core';

import { FileIcon, MailIcon, NotificationIcon } from 'icons';
import { NOTIFICATION_TYPES } from 'consts';
import { MappedUserNotificationListItemType } from 'types';

import { EXPOSED_NOTIFICATIONS } from '../../consts';

type Props = {
  formRef: RefObject<HTMLFormElement>;
  onSubmit: (formValues: {
    [key: string]: {
      mail: boolean;
      push: boolean;
    };
  }) => void;
  notifications: MappedUserNotificationListItemType;
};

const MAIL_NOTIFICATION_COLUMN_WIDTH = 60;
const APP_NOTIFICATION_COLUMN_WIDTH = 120;

export const NotificationForm = ({
  formRef,
  onSubmit,
  notifications,
}: Props) => {
  const { t } = useTranslation('user');

  const { formState, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: EXPOSED_NOTIFICATIONS.reduce(
      (accumulator, { type }) => {
        const mappedType = NOTIFICATION_TYPES[
          type
        ] as keyof typeof NOTIFICATION_TYPES;

        return {
          ...accumulator,
          [mappedType]: {
            mail: notifications[mappedType]?.mail || false,
            push: notifications[mappedType]?.push || false,
          },
        };
      },
      {} as {
        [key: string]: {
          mail: boolean;
          push: boolean;
        };
      },
    ),
  });

  const formFields = Object.keys(formState.defaultValues || {});

  const notificationValues = watch(formFields);
  const areAllMailNotificationsChecked = notificationValues.every(
    ({ mail }) => mail,
  );
  const areAllPushNotificationsChecked = notificationValues.every(
    ({ push }) => push,
  );

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Checkbox
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        label={t('user:iWantAllMailNotifications')}
        checked={areAllMailNotificationsChecked}
        onChange={e => {
          formFields.forEach(field => {
            setValue(`${field}.mail`, e.target.checked);
          });
        }}
      />
      <Checkbox
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
        label={t('user:iWantAllAppNotifications')}
        checked={areAllPushNotificationsChecked}
        onChange={e => {
          formFields.forEach(field => {
            setValue(`${field}.push`, e.target.checked);
          });
        }}
      />
      <Divider />
      <Box
        sx={theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.other.spacing(2.5, 0),
        })}
      >
        <Text size="md">{t('user:whereWillYouGetNotification')}</Text>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: MAIL_NOTIFICATION_COLUMN_WIDTH,
            }}
          >
            <MailIcon
              sx={theme => ({
                marginRight: theme.other.spacing(0.5),
              })}
              color="blue"
              backgroundColor="blue.1"
            />
            <Text size="md" weight={600}>
              {t('user:mailNotification')}
            </Text>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: APP_NOTIFICATION_COLUMN_WIDTH,
            }}
          >
            <NotificationIcon
              sx={theme => ({
                marginRight: theme.other.spacing(0.5),
              })}
              color="blue"
              backgroundColor="blue.1"
            />
            <Text size="md" weight={600}>
              {t('user:appNotification')}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          marginBottom: theme.other.spacing(2),
          padding: theme.other.spacing(0.5, 0),
          backgroundColor: theme.colors[theme.primaryColor][1],
        })}
      >
        <FileIcon sx={theme => ({ marginRight: theme.other.spacing(0.5) })} />
        <Text size="md">{t('user:documentsAndBoardsChanges')}</Text>
      </Box>
      {EXPOSED_NOTIFICATIONS.map(({ type, translations }) => (
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.other.spacing(2),
          })}
          key={type}
        >
          <Box
            sx={theme => ({
              maxWidth: 400,
              marginRight: theme.other.spacing(2),
            })}
          >
            <Text size="md" weight={600}>
              {t(translations.type)}
            </Text>
            <Text size="md">{t(translations.description)}</Text>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Checkbox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: MAIL_NOTIFICATION_COLUMN_WIDTH,
              }}
              {...register(`${NOTIFICATION_TYPES[type]}.mail`)}
            />
            <Checkbox
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: APP_NOTIFICATION_COLUMN_WIDTH,
              }}
              {...register(`${NOTIFICATION_TYPES[type]}.push`)}
            />
          </Box>
        </Box>
      ))}
    </form>
  );
};
