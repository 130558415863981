import { apiAuthClient } from 'api/helpers';

import { GetUserBoardsResponseType } from '../types';

export const getUserBoards = async () => {
  const url = '/api/v2/auth/user/boards';

  const { data } = await apiAuthClient.get<GetUserBoardsResponseType>(url);

  return data;
};
