import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, LoadingOverlay, Text } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';

import { useGetCurrentOrganisation } from 'api/organisations';
import { getUserFullName } from 'helpers';
import { useGetV1Url } from 'hooks';
import { OrganisationUserListType } from 'types';
import {
  Error,
  NotFound,
  TextInputSearch,
  UserAvatar,
} from 'components/shared';

import { SelectedOrganisationMembersType } from '../../../../types';

type Props = {
  organisationMembers: OrganisationUserListType[];
  selectedOrganisationMembers: SelectedOrganisationMembersType;
  handleChangeSelectedOrganisationMembers: (
    selectedMembers: SelectedOrganisationMembersType,
  ) => void;
  isOrganisationMembersError: boolean;
  isOrganisationMembersLoading: boolean;
  isFetchingOrganisationMembersNextPage: boolean;
  fetchOrganisationMembersNextPage: () => void;
  setOrganisationMembersNameAndSurname: (nameAndSurname: string) => void;
};

export const MembersSelectList = ({
  organisationMembers,
  selectedOrganisationMembers,
  handleChangeSelectedOrganisationMembers,
  isOrganisationMembersError,
  isOrganisationMembersLoading,
  isFetchingOrganisationMembersNextPage,
  fetchOrganisationMembersNextPage,
  setOrganisationMembersNameAndSurname,
}: Props) => {
  const { t } = useTranslation('transactions');

  const getV1Url = useGetV1Url();

  const { data: organisation } = useGetCurrentOrganisation();

  const [searchValue, setSearchValue] = useState('');

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { ref: lastItemRef, entry: lastItemEntry } = useIntersection({
    root: wrapperRef.current,
    threshold: 1,
  });

  useEffect(() => {
    if (lastItemEntry?.isIntersecting) {
      fetchOrganisationMembersNextPage();
    }
  }, [fetchOrganisationMembersNextPage, lastItemEntry?.isIntersecting]);

  const areAllMembersSelected = selectedOrganisationMembers[0] === 'all';

  return (
    <div>
      <LoadingOverlay
        visible={
          isOrganisationMembersLoading || isFetchingOrganisationMembersNextPage
        }
      />
      <TextInputSearch
        sx={theme => ({
          margin: theme.other.spacing(0, 2, 3.25),
        })}
        placeholder={t('transactions:transactionListSearchOrganisationMember')}
        value={searchValue}
        onChange={e => {
          setSearchValue(e.target.value);
          setOrganisationMembersNameAndSurname(e.target.value);
        }}
        autoFocus
      />
      {isOrganisationMembersError && <Error />}
      {!isOrganisationMembersError && (
        <Box sx={{ maxHeight: 355, overflow: 'auto' }} ref={wrapperRef}>
          {organisationMembers.length === 0 ? (
            <NotFound
              sx={theme => ({
                margin: theme.other.spacing(8, 0),
              })}
              buttonText={t(
                'transactions:transactionListOrganisationsManaging',
              )}
              externalLink={getV1Url('organisations')}
            />
          ) : (
            <div>
              <Box
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  minHeight: 40,
                  marginBottom: theme.other.spacing(2),
                  padding: theme.other.spacing(0, 2),
                  '&:hover': {
                    backgroundColor: theme.fn.rgba(
                      theme.fn.primaryColor(),
                      0.05,
                    ),
                  },
                })}
                component="label"
              >
                <Text span size="md" weight={600}>
                  {t('transactions:transactionListAllOrganisation')}{' '}
                  {organisation!.name}
                </Text>
                <Checkbox
                  sx={{
                    lineHeight: 0,
                    marginBottom: 0,
                  }}
                  checked={areAllMembersSelected}
                  onChange={e => {
                    handleChangeSelectedOrganisationMembers(
                      e.target.checked ? ['all'] : [],
                    );
                  }}
                />
              </Box>

              <ul>
                {organisationMembers.map(({ id, name, surname }, index) => (
                  <Box
                    sx={theme => ({
                      marginBottom: theme.other.spacing(1),
                    })}
                    key={id}
                    ref={
                      index === organisationMembers.length - 1
                        ? lastItemRef
                        : undefined
                    }
                    component="li"
                  >
                    <Box
                      sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minHeight: 40,
                        padding: theme.other.spacing(0, 2),
                        '&:hover': {
                          backgroundColor: theme.fn.rgba(
                            theme.fn.primaryColor(),
                            0.05,
                          ),
                        },
                      })}
                      component="label"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        component="span"
                      >
                        <UserAvatar
                          sx={theme => ({
                            marginRight: theme.other.spacing(1.25),
                          })}
                          name={name}
                          surname={surname}
                          avatar={null}
                        />
                        <Text span size="md" weight={600}>
                          {getUserFullName(name, surname)}
                        </Text>
                      </Box>
                      <Checkbox
                        sx={{ marginBottom: 0, lineHeight: 0 }}
                        name={String(id)}
                        checked={
                          areAllMembersSelected ||
                          selectedOrganisationMembers.some(
                            selectedMember => selectedMember === id,
                          )
                        }
                        onChange={e => {
                          if (e.target.checked) {
                            handleChangeSelectedOrganisationMembers([
                              ...(selectedOrganisationMembers as OrganisationUserListType['id'][]),
                              id,
                            ]);
                          } else if (areAllMembersSelected) {
                            handleChangeSelectedOrganisationMembers([id]);
                          } else {
                            handleChangeSelectedOrganisationMembers(
                              (
                                selectedOrganisationMembers as OrganisationUserListType['id'][]
                              ).filter(selectedMember => selectedMember !== id),
                            );
                          }
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </ul>
            </div>
          )}
        </Box>
      )}
    </div>
  );
};
