import { FormEventHandler, Ref } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  UseFormClearErrors,
} from 'react-hook-form';

import { InstanceRefObjectType } from 'components/shared/Inputs/TextInputPasswordWithStrength';

import {
  FormFieldErrorsType,
  RegisterWithCustomOnChangeType,
  RegistrationStepType,
  RegistrationValuesType,
} from '../types';
import { SlideLeftAnimationBox } from '../SlideLeftAnimationBox';
import { Step1 } from '../Step1';
import { Step2 } from '../Step2';
import { Step3 } from '../Step3';

type Props = {
  availableSteps: RegistrationStepType;
  control: Control<RegistrationValuesType>;
  currentStep: RegistrationStepType;
  errors: FormFieldErrorsType;
  clearErrors: UseFormClearErrors<RegistrationValuesType>;
  getFormSubmit: () => FormEventHandler<HTMLFormElement> | undefined;
  goToPreviousStep: () => void;
  isCreatingAccount: boolean;
  isSuccessViewAvailable: boolean;
  nextStepSubmit: () => Promise<void>;
  passwordInstanceRef: Ref<InstanceRefObjectType>;
  customRegister: RegisterWithCustomOnChangeType;
  serverErrorMessage: string;
  setValue: UseFormSetValue<RegistrationValuesType>;
  watch: UseFormWatch<RegistrationValuesType>;
};

export const RegistrationForm = ({
  availableSteps,
  control,
  currentStep,
  errors,
  clearErrors,
  getFormSubmit,
  goToPreviousStep,
  isCreatingAccount,
  isSuccessViewAvailable,
  nextStepSubmit,
  passwordInstanceRef,
  customRegister,
  serverErrorMessage,
  setValue,
  watch,
}: Props) => (
  <form onSubmit={getFormSubmit()}>
    <SlideLeftAnimationBox
      in={currentStep === 1}
      disabled={availableSteps === 1}
    >
      <Step1
        passwordInstanceRef={passwordInstanceRef}
        customRegister={customRegister}
        errors={errors}
        watch={watch}
        onSubmit={nextStepSubmit}
      />
    </SlideLeftAnimationBox>
    <SlideLeftAnimationBox in={currentStep === 2}>
      <Step2
        customRegister={customRegister}
        control={control}
        errors={errors}
        onSubmit={nextStepSubmit}
        backButtonAction={goToPreviousStep}
      />
    </SlideLeftAnimationBox>
    <SlideLeftAnimationBox in={currentStep === 3}>
      <Step3
        errors={errors}
        clearErrors={clearErrors}
        watch={watch}
        setValue={setValue}
        isCreatingAccount={isCreatingAccount}
        serverErrorMessage={serverErrorMessage}
        backButtonAction={goToPreviousStep}
        isSuccessViewAvailable={isSuccessViewAvailable}
      />
    </SlideLeftAnimationBox>
  </form>
);
