import { API_QUERY_KEYS } from 'consts';
import { useQuery } from '@tanstack/react-query';
import { useGetCurrentOrganisationId } from 'hooks';
import { getWebhookLogs } from '../requests';
import { GetWebhookLogsRequestType } from '../types';

export const useGetWebhookLogs = ({
  pathParams: { webhookId },
  queryParams: { page, perPage, sortDir } = {},
}: {
  pathParams: Omit<GetWebhookLogsRequestType['pathParams'], 'organisationId'>;
  queryParams?: GetWebhookLogsRequestType['queryParams'];
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [API_QUERY_KEYS.webhooksLogs, { webhookId }],
    queryFn: () =>
      getWebhookLogs({
        pathParams: { organisationId, webhookId },
        queryParams: {
          page,
          perPage,
          sortDir,
        },
      }),
  });
};
