import { ThemeComponentType } from '../types';

export const Modal: ThemeComponentType = {
  defaultProps: {
    transitionDuration: 0,
    size: 'xl',
  },
  styles: theme => ({
    header: {
      marginBottom: theme.other.spacing(1.5),
    },
    title: {
      ...theme.other.getFontSizeStyles('lg'),
      fontWeight: 600,
    },
    close: {
      color: '#808199',
    },
  }),
};
