import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentHeader } from 'components/NonLoggedLayout';

type Props = {
  errorLabel?: ReactNode;
  backButtonAction?: () => void;
};

export const AuthContainerLoginHeader = ({
  errorLabel,
  backButtonAction,
}: Props) => {
  const { t } = useTranslation('user');

  return (
    <ContentHeader
      title={t('user:login')}
      description={t('user:loginDescription')}
      errorLabel={errorLabel}
      backButtonAction={backButtonAction}
    />
  );
};
