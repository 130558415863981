import { useTranslation } from 'react-i18next';
import { camelize } from 'humps';

import { getRidOfDiacritics } from 'helpers';
import { DescriptionField, TextInput } from 'components/shared';

export const Name = ({ value, onChange, error }) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.variableName')}
      description={t(
        'templates:templateCreatorVariables.variableNameDescription',
      )}
      error={error}
    >
      <TextInput
        placeholder={t('templates:templateCreatorVariables.variableName')}
        withErrorWrapperAlwaysEnabled={false}
        value={value}
        onChange={e => {
          onChange(camelize(getRidOfDiacritics(e.target.value)));
        }}
      />
    </DescriptionField>
  );
};
