import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const MailIcon = ({
  color,
  size = 24,
  sx,
  backgroundColor: backgroundColorProp,
  ...props
}: IconPropsType & { backgroundColor?: string }) => {
  const iconColor = useGetColor(color);
  const backgroundColor = useGetColor(backgroundColorProp || 'transparent');

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={backgroundColor}
        d="M18.857 5.143H5.143C4.512 5.143 4 5.655 4 6.286v11.428c0 .632.512 1.143 1.143 1.143h13.714c.631 0 1.143-.511 1.143-1.143V6.286c0-.631-.512-1.143-1.143-1.143Z"
      />
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m4 8.571 8 4.572 8-4.572M5.143 5.143h13.714c.631 0 1.143.512 1.143 1.143v11.428c0 .632-.512 1.143-1.143 1.143H5.143A1.143 1.143 0 0 1 4 17.714V6.286c0-.631.512-1.143 1.143-1.143Z"
      />
    </Box>
  );
};
