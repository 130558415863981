import { useGetUserBoards } from 'api/boards';
import { useGetCurrentBoardId } from 'hooks';

export const useGetCurrentBoard = () => {
  const currentBoardId = useGetCurrentBoardId();

  const {
    data: boardsData,
    isError: isBoardsDataError,
    isLoading: isBoardsDataLoading,
  } = useGetUserBoards();

  return {
    board: boardsData?.data.find(({ id }) => id === currentBoardId),
    isCurrentBoardError: isBoardsDataError,
    isCurrentBoardLoading: isBoardsDataLoading,
  };
};
