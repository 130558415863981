import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Checkbox, Tooltip } from '@mantine/core';

import { DataStreamTemplateType } from 'types';
import {
  AttachmentTemplateSelect,
  TemplateSelect,
} from 'components/Transactions';
import { DescriptionField, TextInput } from 'components/shared';

const FIELD_LIMIT = 255;

const DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT = {
  templateId: null,
  documentContent: null,
  templateName: null,
};

const getDefaultSelectedTemplateAttachment = (
  attachTo: DataStreamTemplateType['attachTo'],
  templates: DataStreamTemplateType[],
) => {
  const isAttachedToTemplateId = typeof attachTo === 'number';

  return {
    templateId: isAttachedToTemplateId ? attachTo : null,
    documentContent: isAttachedToTemplateId ? null : attachTo,
    templateName: attachTo
      ? templates.find(
          ({ templateId, documentContent: documentContentItem }) =>
            isAttachedToTemplateId
              ? templateId === attachTo
              : documentContentItem === attachTo,
        )?.templateName || null
      : null,
  };
};

type FormValues = Pick<
  DataStreamTemplateType,
  | 'documentName'
  | 'documentExternalId'
  | 'documentReference'
  | 'documentContent'
  | 'isBlocked'
>;

type Props = {
  formRef: MutableRefObject<HTMLFormElement | null>;
  editDataStreamTemplates: (
    values: FormValues & {
      templateId: number | null;
      templateName: string;
      attachTo: number | string | null;
    },
  ) => void;
  errors: { [error: string]: string } | null;
  clearError: (errorKey: string) => void;
  templateId: DataStreamTemplateType['templateId'];
  templateName: DataStreamTemplateType['templateName'];
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentContent'];
  isBlocked: DataStreamTemplateType['isBlocked'];
  attachTo: DataStreamTemplateType['attachTo'];
  templates: DataStreamTemplateType[];
};

export const DataStreamEditTemplateForm = ({
  formRef,
  editDataStreamTemplates,
  errors,
  clearError,
  templateId: defaultTemplateId,
  templateName: defaultTemplateName,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
  attachTo,
  templates,
}: Props) => {
  const { t } = useTranslation(['common', 'dataStreams']);

  const [selectedTemplate, setSelectedTemplate] = useState({
    id: defaultTemplateId,
    name: defaultTemplateName,
  });
  const [selectedTemplateError, setSelectedTemplateError] = useState('');

  const [isAttachment, setIsAttachment] = useState(Boolean(attachTo));
  const [selectedAttachmentTemplate, setSelectedAttachmentTemplate] = useState<{
    templateId: number | null;
    templateName: string | null;
    documentContent: string | null;
  }>(getDefaultSelectedTemplateAttachment(attachTo, templates));

  const selectTemplateRef = useRef<HTMLInputElement>(null);
  const selectAttachmentTemplateRef = useRef<HTMLInputElement>(null);

  const schema = z.object({
    documentName: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .max(FIELD_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: FIELD_LIMIT,
        }),
      }),
    documentReference: z.string(),
    documentExternalId: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .max(FIELD_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: FIELD_LIMIT,
        }),
      }),
    documentContent: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .max(FIELD_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: FIELD_LIMIT,
        }),
      })
      .nullable(),
    isBlocked: z.boolean(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: formErrors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      documentName,
      documentExternalId,
      documentContent,
      documentReference: documentReference ?? '',
      isBlocked,
    },
  });

  const documentNameValue = watch('documentName');
  const documentExternalIdValue = watch('documentExternalId');
  const documentReferenceValue = watch('documentReference');
  const documentContentValue = watch('documentContent') || '';
  const isBlockedValue = watch('isBlocked');

  const isDataStreamFromFile = selectedTemplate.id === null;

  const focusTemplateSelect = () => {
    setTimeout(() => {
      selectTemplateRef.current?.focus();
    }, 10);
  };

  const focusAttachmentTemplateSelect = () => {
    setTimeout(() => {
      selectAttachmentTemplateRef.current?.focus();
    }, 10);
  };

  const setSelectedTemplateErrorWithFocus = () => {
    setSelectedTemplateError(t('common:formErrors.required'));
    focusTemplateSelect();
  };

  const onSubmit = (values: FormValues) => {
    if (
      selectedTemplate.id ||
      (isDataStreamFromFile && values.documentContent)
    ) {
      editDataStreamTemplates({
        ...values,
        templateId: selectedTemplate.id,
        templateName: selectedTemplate.name as string,
        isBlocked: isDataStreamFromFile ? false : values.isBlocked,
        attachTo:
          selectedAttachmentTemplate.templateId ||
          selectedAttachmentTemplate.documentContent,
      });
    }
  };

  const onError = () => {
    if (!selectedTemplate.id) {
      setSelectedTemplateErrorWithFocus();
    }
  };

  const customRegister = (fieldName: keyof FormValues) => ({
    ...register(fieldName),
    onChange: async (e: { target: any; type?: any }) => {
      setValue(
        e.target.name,
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      );
      clearError(fieldName);
    },
  });

  useEffect(() => {
    setSelectedTemplate({
      id: defaultTemplateId,
      name: defaultTemplateName,
    });
  }, [defaultTemplateId, defaultTemplateName]);

  useEffect(() => {
    if (errors?.templateId) {
      focusTemplateSelect();
    } else if (errors?.attachTo) {
      focusAttachmentTemplateSelect();
    }
  }, [errors]);

  useEffect(() => {
    setIsAttachment(Boolean(attachTo));
    setSelectedAttachmentTemplate(
      getDefaultSelectedTemplateAttachment(attachTo, templates),
    );
  }, [attachTo, templates]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
      {isDataStreamFromFile ? (
        <DescriptionField
          label={t('dataStreams:editModal.fileField')}
          description={t('dataStreams:editModal.fileFieldDescription')}
          error={
            formErrors?.documentContent?.message || errors?.documentContent
          }
        >
          <TextInput
            {...customRegister('documentContent')}
            onChange={e => {
              customRegister('documentContent').onChange(e);
              setSelectedAttachmentTemplate(
                DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
              );
            }}
            value={documentContentValue || ''}
            placeholder={t('dataStreams:editModal.documentContentPlaceholder')}
            inputLengthLimit={FIELD_LIMIT}
          />
        </DescriptionField>
      ) : (
        <DescriptionField
          label={t('dataStreams:editModal.template')}
          description={t('dataStreams:editModal.templateDescription')}
          error={selectedTemplateError || errors?.templateId}
        >
          <TemplateSelect
            selectRef={selectTemplateRef}
            templateId={selectedTemplate.id}
            templateName={selectedTemplate.name}
            onTemplateChange={({ id, name }) => {
              setSelectedTemplate({ id, name });
              setSelectedTemplateError('');
              clearError('templateId');
              setSelectedAttachmentTemplate(
                DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
              );
            }}
          />
        </DescriptionField>
      )}
      <DescriptionField
        label={t('dataStreams:editModal.documentName')}
        description={t('dataStreams:editModal.documentNameDescription')}
        error={formErrors?.documentName?.message || errors?.documentName}
      >
        <TextInput
          {...customRegister('documentName')}
          value={documentNameValue}
          withErrorWrapperAlwaysEnabled={false}
          placeholder={t('dataStreams:editModal.documentNamePlaceholder')}
          inputLengthLimit={FIELD_LIMIT}
        />
      </DescriptionField>
      <DescriptionField
        label={t('dataStreams:editModal.externalId')}
        description={t('dataStreams:editModal.externalIdDescription')}
        error={
          formErrors?.documentExternalId?.message || errors?.documentExternalId
        }
      >
        <TextInput
          {...customRegister('documentExternalId')}
          value={documentExternalIdValue}
          withErrorWrapperAlwaysEnabled={false}
          placeholder={t('dataStreams:editModal.externalIdPlaceholder')}
          inputLengthLimit={FIELD_LIMIT}
        />
      </DescriptionField>
      <DescriptionField
        label={t('dataStreams:editModal.documentReference')}
        description={t('dataStreams:editModal.documentReferenceDescription')}
        error={
          formErrors?.documentReference?.message || errors?.documentReference
        }
      >
        <TextInput
          {...customRegister('documentReference')}
          value={documentReferenceValue || ''}
          withErrorWrapperAlwaysEnabled={false}
          placeholder={t('dataStreams:editModal.documentReferencePlaceholder')}
          inputLengthLimit={FIELD_LIMIT}
        />
      </DescriptionField>
      <DescriptionField
        label={t('dataStreams:editModal.dontUpdate')}
        description={t('dataStreams:editModal.dontUpdateDescription')}
        error={formErrors?.isBlocked?.message || errors?.isBlocked}
      >
        {isDataStreamFromFile ? (
          <Tooltip
            label={t(
              'dataStreams:editModal.cantChangeValueForDataStreamFromFile',
            )}
          >
            <Box sx={{ width: 'fit-content' }}>
              <Checkbox checked disabled />
            </Box>
          </Tooltip>
        ) : (
          <Checkbox
            defaultChecked={isBlockedValue}
            {...customRegister('isBlocked')}
          />
        )}
      </DescriptionField>
      <DescriptionField
        sx={{ borderBottom: isAttachment ? 'none' : undefined }}
        label={t('dataStreams:editModal.attachment')}
        description={t('dataStreams:editModal.attachmentDescription')}
        withoutBorder={!isAttachment}
      >
        <Checkbox
          disabled={templates.some(
            template =>
              template.attachTo ===
              (isDataStreamFromFile ? documentContent : selectedTemplate.id),
          )}
          checked={isAttachment}
          onChange={e => {
            const isChecked = e.target.checked;

            setIsAttachment(isChecked);

            if (!isChecked) {
              setSelectedAttachmentTemplate(
                DEFAULT_EMPTY_SELECTED_TEMPLATE_ATTACHMENT,
              );
            }
          }}
        />
      </DescriptionField>
      {isAttachment && (
        <DescriptionField
          sx={{ paddingTop: 0 }}
          label={t('dataStreams:editModal.baseTemplate')}
          description={t('dataStreams:editModal.baseTemplateDescription')}
          error={errors?.attachTo}
          withoutBorder
        >
          <AttachmentTemplateSelect
            selectRef={selectAttachmentTemplateRef}
            isDataStreamFromFile={isDataStreamFromFile}
            templates={templates.filter(template => {
              if (template.attachTo !== null) {
                return false;
              }

              return isDataStreamFromFile
                ? template.documentContent !== documentContent
                : template.templateId !== selectedTemplate.id;
            })}
            selectedAttachmentTemplate={selectedAttachmentTemplate}
            onAttachmentTemplateChange={({
              templateId,
              templateName,
              documentContent: templateDocumentContent,
            }) => {
              setSelectedAttachmentTemplate({
                templateId,
                templateName,
                documentContent: templateDocumentContent,
              });
              clearError('attachTo');
            }}
          />
        </DescriptionField>
      )}
    </form>
  );
};
