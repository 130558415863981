import { TRANSACTION_ENTRIES_TABS } from 'consts';
import {
  TransactionDataStreamType,
  TransactionEntriesTabType,
  TransactionEntryType,
} from 'types';

import { DataPanel } from './DataPanel';
import { DataTypePanel } from './DataTypePanel';
import { DataStreamPanel } from './DataStreamPanel';
import { DetailsPanel } from './DetailsPanel';

type Props = {
  activeTab: TransactionEntriesTabType;
  documents: TransactionEntryType['documents'];
  dataStream: TransactionDataStreamType | null;
  dataType: TransactionEntryType['dataType'];
};

export const DetailPanels = ({
  activeTab,
  documents,
  dataStream,
  dataType,
}: Props) => {
  switch (activeTab) {
    case TRANSACTION_ENTRIES_TABS.data:
      return <DataPanel documents={documents} />;
    case TRANSACTION_ENTRIES_TABS.stream:
      return <DataStreamPanel dataStream={dataStream} />;
    case TRANSACTION_ENTRIES_TABS.type:
      return <DataTypePanel dataType={dataType} />;
    case TRANSACTION_ENTRIES_TABS.details:
      return <DetailsPanel />;
    default:
      return null;
  }
};
