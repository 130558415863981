import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const FileTypePdfIcon = ({ sx, ...props }: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={24}
    height={28}
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6617 1.46967L17.442 1.25H17.1313H3.99998C2.26028 1.25 0.849976 2.6603 0.849976 4.4V23.6C0.849976 25.3397 2.26028 26.75 3.99998 26.75H20C21.7397 26.75 23.15 25.3397 23.15 23.6V7.26863V6.95797L22.9303 6.7383L17.6617 1.46967ZM4.04998 16.4V18.85H3.94998V12.35H5.59998C6.51125 12.35 7.24998 13.0887 7.24998 14C7.24998 14.9113 6.51125 15.65 5.59998 15.65H4.79998H4.04998V16.4ZM4.04998 14.8V15.55H4.79998H5.59998C6.45602 15.55 7.14998 14.856 7.14998 14C7.14998 13.144 6.45602 12.45 5.59998 12.45H4.79998H4.04998V13.2V14.8ZM11.2 12.45H10.45V13.2V18V18.75H11.2H12C12.856 18.75 13.55 18.056 13.55 17.2V14C13.55 13.144 12.856 12.45 12 12.45H11.2ZM17.6 12.45H16.85V13.2V14.8V15.55H17.6H18.45V15.65H17.6H16.85V16.4V18.85H16.75V12.35H20.05V12.45H17.6ZM10.35 12.35H12C12.9112 12.35 13.65 13.0887 13.65 14V17.2C13.65 18.1113 12.9112 18.85 12 18.85H10.35V12.35Z"
      fill="#E6EDF9"
      stroke="#004FC4"
      strokeWidth="1.5"
    />
  </Box>
);
