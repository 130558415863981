import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const PhoneIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M8.822 4H6.286A2.286 2.286 0 0 0 4 6.286V8.57C4 14.883 9.117 20 15.429 20h2.285A2.286 2.286 0 0 0 20 17.714v-1.58c0-.432-.245-.828-.632-1.021l-2.76-1.38a1.143 1.143 0 0 0-1.596.66l-.34 1.02a1.382 1.382 0 0 1-1.583.92 6.91 6.91 0 0 1-5.421-5.422 1.382 1.382 0 0 1 .918-1.583l1.267-.422c.567-.189.892-.782.748-1.361l-.67-2.68A1.143 1.143 0 0 0 8.822 4Z"
      />
    </Box>
  );
};
