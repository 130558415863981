import { useTranslation } from 'react-i18next';

import { DescriptionField, NumberInput } from 'components/shared';
import { Box } from '@mantine/core';

export const MinMaxNumberValue = ({
  inputMinValueProps,
  inputMaxValueProps,
  error,
}) => {
  const { t } = useTranslation('templates');

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.minAndMaxValue')}
      description={t('templateCreatorVariables.minAndMaxValueDescription')}
      error={error}
    >
      <Box sx={theme => ({ display: 'flex', gap: theme.other.spacing(6) })}>
        <NumberInput
          placeholder={t('templates:templateCreatorVariables.min')}
          withErrorWrapperAlwaysEnabled={false}
          hideControls={false}
          {...inputMinValueProps}
        />
        <NumberInput
          placeholder={t('templates:templateCreatorVariables.max')}
          withErrorWrapperAlwaysEnabled={false}
          hideControls={false}
          {...inputMaxValueProps}
        />
      </Box>
    </DescriptionField>
  );
};
