import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DataStreamTemplateType, ValidationErrors } from 'types';
import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';
import { DataStreamEditTemplateForm } from 'components/Transactions';

type Props = {
  onEdit: (template: DataStreamTemplateType) => void;
  editStreamTemplateErrors: ValidationErrors | null;
  clearEditStreamTemplateError: (errorKey: string) => void;
  templateId: DataStreamTemplateType['templateId'];
  templateName: DataStreamTemplateType['templateName'];
  documentName: DataStreamTemplateType['documentName'];
  documentExternalId: DataStreamTemplateType['documentExternalId'];
  documentReference: DataStreamTemplateType['documentReference'];
  documentContent: DataStreamTemplateType['documentContent'];
  isBlocked: DataStreamTemplateType['isBlocked'];
  attachTo: DataStreamTemplateType['attachTo'];
  templates: DataStreamTemplateType[];
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const EditTemplateModal = ({
  isOpen,
  onClose,
  onEdit,
  editStreamTemplateErrors,
  clearEditStreamTemplateError,
  templateId,
  templateName,
  documentName,
  documentExternalId,
  documentReference,
  documentContent,
  isBlocked,
  attachTo,
  templates,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('dataStreams:editModal.editDataStream')}
      defaultButtonAction={onClose}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <DataStreamEditTemplateForm
        templateId={templateId}
        templateName={templateName}
        documentName={documentName}
        documentExternalId={documentExternalId}
        documentReference={documentReference}
        documentContent={documentContent}
        isBlocked={isBlocked}
        attachTo={attachTo}
        formRef={formRef}
        editDataStreamTemplates={template => {
          onEdit(template as DataStreamTemplateType);
        }}
        errors={editStreamTemplateErrors}
        clearError={clearEditStreamTemplateError}
        templates={templates}
      />
    </Modal>
  );
};
