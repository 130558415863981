import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Box,
  MantineTheme,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { getHotkeyHandler, useHover } from '@mantine/hooks';

import { TickIcon, CancelIcon, EditIcon } from 'icons';

type Props = {
  value: string;
  onConfirm: (value: string) => void;
  isFocused: boolean;
  setIsFocused: (focused: boolean) => void;
  error: string;
  setError: (error: string) => void;
  onReject?: () => void;
  onBlur?: () => void;
  isEditMode?: boolean;
  isLoading?: boolean;
};

export const ConfirmationInput = ({
  value: initialValue,
  onConfirm,
  isFocused,
  setIsFocused,
  error,
  setError,
  onReject,
  onBlur,
  isEditMode = false,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const [value, setValue] = useState(initialValue);

  const { hovered: isInputWrapperHovered, ref: inputWrapperRef } = useHover();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleConfirm = async () => {
    await onConfirm(value);
  };

  useEffect(() => {
    if (error || isFocused) {
      inputRef.current?.focus();
    }
  }, [error, isFocused]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const enterHotkeyHandler = getHotkeyHandler([
      [
        'Enter',
        async () => {
          if (isFocused) {
            await handleConfirm();

            if (value) {
              inputRef.current?.blur();
            }
          }
        },
      ],
    ]);

    document.body.addEventListener('keydown', enterHotkeyHandler);

    return () => {
      document.body.removeEventListener('keydown', enterHotkeyHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const actionIconSx = (theme: MantineTheme) => ({
    backgroundColor: theme.white,
    boxShadow: theme.shadows.sm,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
      }}
      ref={inputWrapperRef}
    >
      <TextInput
        sx={theme => ({
          width: '100%',
          marginRight: theme.other.spacing(2),
          input: {
            borderColor: isEditMode ? theme.white : undefined,
            transition: 'none',
            paddingRight: theme.other.spacing(isFocused ? 9.5 : 6.5),
            ...(isEditMode && {
              '&:hover:not(:focus)': {
                backgroundColor: theme.colors.violet[1],
              },
              '&:not(:focus):not(:placeholder-shown)': {
                ...theme.other.getFontSizeStyles('xl'),
                paddingLeft: theme.other.spacing(0.5),
                fontWeight: 600,
              },
            }),
          },
        })}
        styles={{
          wrapper: {
            marginBottom: 0,
          },
          rightSection: {
            pointerEvents: 'none',
            touchAction: 'none',
          },
        }}
        ref={inputRef}
        placeholder={t('api:technicalUserName')}
        rightSection={
          isFocused ? (
            <Text
              size="xs"
              color="gray.8"
              sx={theme => ({
                backgroundColor: theme.fn.lighten(theme.colors.gray[1], 0.8),
                borderRadius: theme.radius.sm,
                border: `1px solid ${theme.colors.gray[1]}`,
                padding: '3px 9px',
              })}
            >
              {t('api:enter')}
            </Text>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{isInputWrapperHovered && <EditIcon />}</>
          )
        }
        value={value}
        disabled={isLoading}
        error={error || (isEditMode ? undefined : ' ')}
        onChange={e => {
          setError('');
          setValue(e.target.value);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);

          if (isEditMode) {
            setValue(initialValue);
          }

          if (onBlur) {
            onBlur();
          }
        }}
      />
      {(!isEditMode || isFocused) && (
        <Box sx={{ display: 'flex', marginTop: 4 }}>
          <Tooltip label={t('common:add')} position="top">
            <ActionIcon
              sx={[
                actionIconSx,
                theme => ({
                  marginRight: theme.other.spacing(1),
                }),
              ]}
              loading={isLoading}
              onMouseDown={(e: unknown) => {
                (e as MouseEvent)?.stopPropagation();

                handleConfirm();
              }}
            >
              <TickIcon color={isLoading ? 'gray.2' : 'gray'} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('common:cancel')} position="top">
            <ActionIcon
              sx={actionIconSx}
              disabled={isLoading}
              onMouseDown={() => {
                if (isEditMode) {
                  setValue(initialValue);
                }

                if (onReject) {
                  onReject();
                }
              }}
            >
              <CancelIcon size={18} color={isLoading ? 'gray.2' : 'gray'} />
            </ActionIcon>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};
