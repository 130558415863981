import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpIcon } from 'icons';
import { TextInput } from 'components/shared';

const Tooltip = ({ attributes, node, editor, isActive }) => {
  const { t } = useTranslation('templates');

  const [value, setValue] = useState(node.data.toJS().text || '');

  const hasError = value.length === 0;

  return (
    <TextInput
      {...attributes}
      sx={theme => {
        const activeBorderColor =
          theme.focusRingStyles.inputStyles(theme).borderColor;

        const getBorderColor = () => {
          if (isActive) {
            return activeBorderColor;
          }

          return hasError ? theme.colors.red[0] : undefined;
        };

        return {
          display: 'inline-block',
          verticalAlign: 'middle',
          input: {
            backgroundColor: hasError ? theme.colors.red[1] : undefined,
            borderColor: getBorderColor(),
          },
        };
      }}
      placeholder={t('templates:hint')}
      icon={<HelpIcon />}
      withErrorWrapperAlwaysEnabled={false}
      value={value}
      onClick={e => {
        e.stopPropagation();
      }}
      onChange={e => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        editor.updateNodeData(node, { text: value });
      }}
    />
  );
};

export const tooltip = {
  renderNode: (
    { attributes, node, children, isSelected, isFocused },
    editor,
    next,
  ) => {
    if (node.type === 'tooltip') {
      return (
        <Tooltip
          attributes={attributes}
          node={node}
          editor={editor}
          isActive={isSelected && isFocused}
        />
      );
    }

    return next();
  },
  schema: {
    inlines: {
      tooltip: {
        isVoid: true,
      },
    },
  },
};
