import { apiAuthClient } from 'api/helpers';

import {
  PostAuthResetPasswordUpdateRequestType,
  PostAuthResetPasswordUpdateResponseType,
} from '../types';

export const postAuthResetPasswordUpdate = async ({
  pathParams: { token },
  bodyParams,
}: PostAuthResetPasswordUpdateRequestType) => {
  const url = `/api/v2/auth/reset-password/${token}`;

  const { data } =
    await apiAuthClient.post<PostAuthResetPasswordUpdateResponseType>(
      url,
      bodyParams,
    );

  return data;
};
