import { useEffect, useImperativeHandle } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DescriptionForApi } from '../DescriptionForApi';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { Label } from '../Label';
import { InitialListValue } from '../InitialListValue';
import { useGetZodTabsVariableSchema } from './hooks';
import { Options } from './Options';

export const TabsVariable = ({ fieldsInstanceRef, initialValues }) => {
  const { t } = useTranslation('templates');

  const schema = useGetZodTabsVariableSchema();

  const {
    control,
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? { ...initialValues, _initialValueId: null }
      : {
          label: '',
          hint: '',
          apiDescription: '',
          options: [{ value: '' }],
          initialValue: '',
          _initialValueId: '',
          required: false,
          requiredWhen: null,
        },
  });
  const optionsFieldArray = useFieldArray({
    control,
    name: 'options',
  });

  const optionsFields = optionsFieldArray.fields;
  const options = watch('options');

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { _initialValueId, ...values } = getValues();

      return {
        ...values,
        initialValue: _initialValueId
          ? options[optionsFields.findIndex(({ id }) => id === _initialValueId)]
              .value
          : undefined,
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  useEffect(() => {
    const { _initialValueId, initialValue } = getValues();

    if (_initialValueId === null) {
      setValue(
        '_initialValueId',
        initialValue
          ? optionsFields.find(({ value }) => value === initialValue).id
          : '',
      );
    }
  }, []);

  useEffect(() => {
    /* clear _initialValueId after delete option */
    if (!optionsFields.some(({ id }) => id === getValues()._initialValueId)) {
      setValue('_initialValueId', '');
    }
  }, [options]);

  return (
    <div>
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <DescriptionForApi
        inputProps={{ ...register('apiDescription') }}
        error={formErrors?.apiDescription?.message || ''}
      />
      <Options
        optionsFieldArray={optionsFieldArray}
        register={register}
        error={formErrors?.options?.message || ''}
        clearOptionsError={() => {
          clearErrors('options');
        }}
      />
      <InitialListValue
        control={control}
        data={options.map(({ value }, index) => ({
          value: optionsFields[index].id,
          label:
            value ||
            `${t('templates:templateCreatorVariables.tab')} ${index + 1}`,
        }))}
        error={formErrors?.initialValue?.message || ''}
      />
      <Required checkboxProps={{ ...register('required') }} />
    </div>
  );
};
