import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import { RedoIcon, UndoIcon } from 'legacy/TemplateCreatorEditor/icons';

import { SectionWrapper } from '../../SectionWrapper';
import { TextModifierButton } from '../TextModifierButton';

export const HistoryControlSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  return (
    <SectionWrapper>
      <TextModifierButton
        tooltipLabel={t('templates:toolbar.undo')}
        icon={<UndoIcon />}
        disabled={
          !(
            editor.value.data.get('undos') &&
            editor.value.data.get('undos').size > 0
          )
        }
        onClick={editor.undo}
      />
      <TextModifierButton
        tooltipLabel={t('templates:toolbar.redo')}
        icon={<RedoIcon />}
        disabled={
          !(
            editor.value.data.get('redos') &&
            editor.value.data.get('redos').size > 0
          )
        }
        onClick={editor.redo}
      />
    </SectionWrapper>
  );
};
