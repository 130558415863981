import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, ActionIcon, Box, Button } from '@mantine/core';

import { GUIDE_TOUR_DATA, URLS } from 'consts';
import { useGetV1Url, useIsMdBreakpoint } from 'hooks';
import { CreateIcon, HamburgerIcon, LogoIcon } from 'icons';

import { BoardSelect } from '../BoardSelect';
import { Notifications } from './Notifications';
import { UserMenu } from './UserMenu';

type Props = {
  toggleNavbarOpen: () => void;
};

export const Header = ({ toggleNavbarOpen }: Props) => {
  const { t } = useTranslation(['navigation']);
  const isMdBreakpoint = useIsMdBreakpoint();
  const getV1Url = useGetV1Url();

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: theme.other.spacing(0, 2),
        minHeight: theme.other.layoutSizes.headerHeight,
      })}
    >
      {isMdBreakpoint ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ActionIcon
            variant="transparent"
            sx={theme => ({
              marginRight: theme.other.spacing(1),
            })}
            onClick={toggleNavbarOpen}
          >
            <HamburgerIcon color="primary.1" />
          </ActionIcon>
          <Button
            data-guide-tour-step={GUIDE_TOUR_DATA.createNewDocument.selector}
            component="a"
            href={getV1Url('createDocument')}
            leftIcon={<CreateIcon />}
            color="green"
          >
            {t('navigation:header.createDocument')}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Anchor component={Link} to={URLS.home}>
            <LogoIcon color="primary.1" />
          </Anchor>
          <BoardSelect variant="header" />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Notifications sx={{ marginRight: isMdBreakpoint ? 28 : 12 }} />
        <UserMenu />
      </Box>
    </Box>
  );
};
