import { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, DefaultProps, packSx } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import {
  DataStreamTechnicalUserType,
  DataStreamType,
  DataTypeType,
  ValidationErrors,
} from 'types';
import { useGetTechnicalUsersInfinite } from 'api/technicalUsers';
import { useGetDataTypesInfinite } from 'api/dataTypes';
import { DEBOUNCED_INPUT_DELAY_IN_MS, URLS } from 'consts';
import { getInputVariableValue } from 'helpers';
import {
  PaperCard,
  PercentageContainer,
  SelectFromDataList,
  TextInput,
} from 'components/shared';

type Props = {
  hasAccess: boolean;
  dataStreamNameField: {
    value: DataStreamType['name'];
    cursorPosition: HTMLInputElement['selectionStart'];
  };
  setDataStreamNameField: Dispatch<
    SetStateAction<{
      value: DataStreamType['name'];
      cursorPosition: HTMLInputElement['selectionStart'];
    }>
  >;
  dataStreamNameInputRef: RefObject<HTMLInputElement>;
  selectedDataType: DataTypeType['name'];
  setSelectedDataTypeName: Dispatch<SetStateAction<DataTypeType['name']>>;
  selectedTechnicalUser: {
    id: DataStreamTechnicalUserType['id'] | string;
    name: DataStreamTechnicalUserType['name'];
  };
  setSelectedTechnicalUser: Dispatch<
    SetStateAction<{
      id: DataStreamTechnicalUserType['id'] | string;
      name: DataStreamTechnicalUserType['name'];
    }>
  >;
  errors: ValidationErrors | null;
  clearError: (errorField: string) => void;
  sx?: DefaultProps['sx'];
};

export const StreamDataForm = ({
  hasAccess,
  dataStreamNameField,
  setDataStreamNameField,
  dataStreamNameInputRef,
  selectedDataType,
  setSelectedDataTypeName,
  selectedTechnicalUser,
  setSelectedTechnicalUser,
  errors,
  clearError,
  sx,
}: Props) => {
  const { t } = useTranslation('dataStreams');

  const [dataTypeSearch, setDataTypeSearch] = useState('');
  const [technicalUserSearch, setTechnicalUserSearch] = useState('');

  const [debouncedDataTypeSearch] = useDebouncedValue(
    dataTypeSearch,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );
  const [debouncedTechnicalUserSearch] = useDebouncedValue(
    technicalUserSearch,
    DEBOUNCED_INPUT_DELAY_IN_MS,
  );

  const selectDataTypeRef = useRef<HTMLInputElement>(null);
  const selectTechnicalUserIdRef = useRef<HTMLInputElement>(null);

  const {
    data: dataTypesData,
    isError: isDataTypesError,
    isLoading: isDataTypesLoading,
    isFetchingNextPage: isFetchingNextDataTypesPage,
    fetchNextPage: fetchDataTypesNextPage,
  } = useGetDataTypesInfinite(
    {
      queryParams: {
        name: debouncedDataTypeSearch,
      },
    },
    {
      enabled: hasAccess,
    },
  );

  const {
    data: technicalUsersData,
    isError: isTechnicalUsersError,
    isLoading: isTechnicalUsersLoading,
    isFetchingNextPage: isFetchingNextTechnicalUsersPage,
    fetchNextPage: fetchTechnicalUsersNextPage,
  } = useGetTechnicalUsersInfinite(
    {
      queryParams: {
        name: debouncedTechnicalUserSearch,
      },
    },
    {
      enabled: hasAccess,
    },
  );

  return (
    <PercentageContainer
      sx={[
        theme => ({
          padding: theme.other.spacing(1, 2, 2),
        }),
        ...packSx(sx),
      ]}
      component={PaperCard}
    >
      <Box sx={{ maxWidth: 327 }}>
        <TextInput
          ref={dataStreamNameInputRef}
          value={dataStreamNameField.value}
          onChange={e => {
            clearError('name');
            setDataStreamNameField({
              value: getInputVariableValue(e.target.value).toUpperCase(),
              cursorPosition: e.target.selectionStart,
            });
          }}
          label={t('dataStreams:dataForm.dataStreamNameLabel')}
          placeholder={t('dataStreams:dataForm.dataStreamNamePlaceholder')}
          disabled={!hasAccess}
          error={errors?.name}
        />
        <SelectFromDataList
          selectProps={{
            ref: selectDataTypeRef,
            value: selectedDataType,
            label: t('dataStreams:dataForm.dataTypeLabel'),
            placeholder: t('dataStreams:dataForm.dataTypePlaceholder'),
            disabled: !hasAccess,
            data: [{ value: selectedDataType, label: selectedDataType }],
            error: errors?.dataType,
          }}
          itemsData={dataTypesData}
          isError={isDataTypesError}
          isLoading={isDataTypesLoading || isFetchingNextDataTypesPage}
          fetchNextPage={fetchDataTypesNextPage}
          selectedId={selectedDataType}
          onChange={({ name }) => {
            clearError('dataType');
            setSelectedDataTypeName(name);
          }}
          searchInputProps={{
            value: dataTypeSearch,
            onChange: e => {
              setDataTypeSearch(e.target.value);
            },
            placeholder: t('dataStreams:dataForm.dataTypeSearchPlaceholder'),
          }}
          notFoundProps={{
            buttonText: t('dataStreams:dataForm.dataTypesManaging'),
            link: URLS.dataTypes,
          }}
          uniqueKey="name"
        />
        <SelectFromDataList
          selectProps={{
            ref: selectTechnicalUserIdRef,
            value: String(selectedTechnicalUser.id),
            label: t('dataStreams:dataForm.technicalUserLabel'),
            placeholder: t('dataStreams:dataForm.technicalUserPlaceholder'),
            disabled: !hasAccess,
            data: [
              {
                value: String(selectedTechnicalUser.id),
                label: selectedTechnicalUser.name || undefined,
              },
            ],
            error: errors?.technicalUserId,
          }}
          itemsData={technicalUsersData}
          isError={isTechnicalUsersError}
          isLoading={
            isTechnicalUsersLoading || isFetchingNextTechnicalUsersPage
          }
          fetchNextPage={fetchTechnicalUsersNextPage}
          selectedId={selectedTechnicalUser.id}
          onChange={({ id, name }) => {
            clearError('technicalUserId');
            setSelectedTechnicalUser({ id, name } as {
              id: number;
              name: string;
            });
          }}
          searchInputProps={{
            value: technicalUserSearch,
            onChange: e => {
              setTechnicalUserSearch(e.target.value);
            },
            placeholder: t(
              'dataStreams:dataForm.technicalUserSearchPlaceholder',
            ),
          }}
          notFoundProps={{
            buttonText: t('dataStreams:dataForm.technicalUsersManaging'),
            link: URLS.api,
          }}
        />
      </Box>
    </PercentageContainer>
  );
};
