import { apiAuthClient } from 'api/helpers';

import {
  GetCustomizationsEmailResponseType,
  GetCustomizationsEmailRequestType,
} from '../types';

export const getCustomizationsEmail = async ({
  pathParams: { organisationId },
}: GetCustomizationsEmailRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/customizations/email`;

  const { data } = await apiAuthClient.get<GetCustomizationsEmailResponseType>(
    url,
  );

  return data;
};
