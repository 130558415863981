import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const QueueIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.25a.75.75 0 0 0 0 1.5h3.333a3.418 3.418 0 0 0 6.668 0H14.999a3.418 3.418 0 0 0 6.668 0H25a.75.75 0 1 0 0-1.5h-3.333a3.418 3.418 0 0 0-6.668 0h-3.998a3.418 3.418 0 0 0-6.668 0H1Zm6.667 2.667a1.917 1.917 0 1 1 0-3.834 1.917 1.917 0 0 1 0 3.834Zm10.666 0a1.917 1.917 0 1 1 0-3.834 1.917 1.917 0 0 1 0 3.834Z"
        fill={iconColor}
      />
    </Box>
  );
};
