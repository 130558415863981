import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const WomanPointingTableIcon = ({
  color,
  size,
  width = 167,
  height = 160,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 167 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <ellipse id={`${id}_a`} cx={79.925} cy={80} rx={79.925} ry={80} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M94.215 103.354H3.948c-2.18 0-3.948-1.751-3.948-3.912v-57c0-2.161 1.767-3.913 3.948-3.913h90.267c2.18 0 3.948 1.752 3.948 3.912v57.001c0 2.16-1.768 3.912-3.948 3.912"
          fill="#FFF"
        />
        <path
          d="M5.58 37.453c-2.45 0-4.443 2.031-4.443 4.528v55.686c0 2.497 1.993 4.529 4.443 4.529h87.002c2.45 0 4.444-2.032 4.444-4.53V41.982c0-2.497-1.994-4.528-4.444-4.528H5.58Zm87.002 65.901H5.58c-3.077 0-5.58-2.551-5.58-5.687V41.98c0-3.136 2.503-5.687 5.58-5.687h87.002c3.077 0 5.58 2.551 5.58 5.687v55.686c0 3.136-2.503 5.687-5.58 5.687Z"
          fill="#080717"
        />
        <path
          d="M6.693 41.832a1.168 1.168 0 0 0 0 2.336 1.168 1.168 0 0 0 0-2.336m0 3.403a2.236 2.236 0 0 1 0-4.47 2.236 2.236 0 0 1 0 4.47M13.386 41.832a1.168 1.168 0 0 0 0 2.336 1.168 1.168 0 0 0 0-2.336m0 3.403a2.236 2.236 0 0 1 0-4.47 2.236 2.236 0 0 1 0 4.47M20.079 41.832a1.168 1.168 0 0 0 0 2.336 1.168 1.168 0 0 0 0-2.336m0 3.403a2.236 2.236 0 0 1 0-4.47 2.236 2.236 0 0 1 0 4.47"
          fill="#080717"
        />
        <path
          d="M11.342 57.446c-.717 0-1.3.539-1.3 1.201 0 .663.583 1.202 1.3 1.202h8.55c.716 0 1.3-.539 1.3-1.202 0-.662-.584-1.201-1.3-1.201h-8.55Zm8.55 3.437h-8.55c-1.333 0-2.418-1.003-2.418-2.236 0-1.232 1.085-2.235 2.418-2.235h8.55c1.333 0 2.418 1.003 2.418 2.235 0 1.233-1.085 2.236-2.418 2.236ZM26.772 57.446c-.64 0-1.158.5-1.158 1.115v.173c0 .615.519 1.115 1.158 1.115.638 0 1.157-.5 1.157-1.115v-.173c0-.615-.52-1.115-1.157-1.115m0 3.437c-1.23 0-2.231-.964-2.231-2.15v-.172c0-1.185 1-2.149 2.23-2.149s2.232.964 2.232 2.149v.173c0 1.185-1.001 2.149-2.231 2.149"
          fill={iconColor}
          fillRule="nonzero"
        />
        <path d="M29.003 72.06h53.543-53.543Z" fill="#FFF" />
        <path
          d="M29.588 72.06c-.323 0-.585-.501-.585-1.119 0-.617.262-1.117.585-1.117h54.603c.323 0 .586.5.586 1.117 0 .618-.263 1.118-.586 1.118H29.588Z"
          fill={iconColor}
          fillRule="nonzero"
        />
        <path d="M29.003 76.53h53.543-53.543Z" fill="#FFF" />
        <path
          d="M29.588 78.765c-.323 0-.585-.5-.585-1.118 0-.617.262-1.117.585-1.117h54.603c.323 0 .586.5.586 1.117 0 .618-.263 1.118-.586 1.118H29.588ZM48.516 85.471H29.568c-.312 0-.565-.5-.565-1.118 0-.617.253-1.117.565-1.117h18.948c.312 0 .565.5.565 1.117 0 .618-.253 1.118-.565 1.118M15.212 68.498c-2.538 0-4.603 2.14-4.603 4.768v1.96c-.825.303-1.416 1.116-1.416 2.074v5.867c0 1.215.95 2.2 2.124 2.2h7.79c1.173 0 2.124-.985 2.124-2.2V77.3c0-.958-.592-1.77-1.417-2.073v-1.961c0-2.629-2.064-4.768-4.602-4.768Zm0 1.467c1.757 0 3.186 1.481 3.186 3.3V75.1h-6.373v-1.833c0-1.82 1.43-3.3 3.187-3.3Zm-3.895 6.601h7.79a.72.72 0 0 1 .707.734v5.867a.72.72 0 0 1-.708.734h-7.789a.72.72 0 0 1-.708-.734V77.3a.72.72 0 0 1 .708-.734Z"
          fill={iconColor}
          fillRule="nonzero"
        />
        <g transform="translate(7.15)">
          <mask id={`${id}_b`} fill="#fff">
            <use xlinkHref={`#${id}_a`} />
          </mask>
          <g mask={`url(#${id}_b)`}>
            <path
              d="m56.858 94.873.916-13.993a21.996 21.996 0 0 0-3.276-13.032L42.35 48.24c-.502-.812-1.752-.724-2.128.148a4.71 4.71 0 0 0 .226 4.185l3.89 6.915-5.496-3.377-5.601 5.877a1.329 1.329 0 0 0 .258 2.052l-1.433 1.47a.96.96 0 0 0 .212 1.508 4.952 4.952 0 0 0 3.593.553l.681-.154 1.29 5.523c.19.815 1.161 1.203 1.895.757l.053-.032a3.755 3.755 0 0 0 1.71-2.3l.396-1.599 5.343 5.433-7.84 31.442c.794 7.073 7.819 11.9 15.034 10.332l40.301-8.762-8.143-15.346-31.621 2.679 1.887-.67Z"
              fill="#FFF"
            />
            <path
              d="M40.36 105.959a11.533 11.533 0 0 0 4.754 8.045 11.567 11.567 0 0 0 9.258 1.865l38.713-8.738-7.466-14.604-30.55 2.686-.098-1.113 1.609-.141-.272-.017.896-14.207a22.345 22.345 0 0 0-3.127-12.91l-11.88-19.908a.583.583 0 0 0-.565-.294.596.596 0 0 0-.517.372 4.368 4.368 0 0 0 .195 3.77l4.833 8.919-4.614-2.943a3.086 3.086 0 0 0-3.94.515l-3.407 3.711a.82.82 0 0 0 .15 1.241l.55.366-1.853 1.973a.43.43 0 0 0-.115.358c.01.077.051.22.206.315.932.568 2.03.744 3.094.494l1.216-.285 1.384 6.157a.66.66 0 0 0 .397.471.663.663 0 0 0 .613-.052l.054-.034a3.225 3.225 0 0 0 1.425-1.991l.626-2.628 6.145 6.486-7.714 32.12Zm4.104 8.954a12.647 12.647 0 0 1-5.227-8.93l-.01-.096.022-.094 7.596-31.627-4.307-4.545-.147.617a4.34 4.34 0 0 1-1.917 2.679l-.054.033a1.79 1.79 0 0 1-2.697-1.12l-1.137-5.056-.116.027a5.21 5.21 0 0 1-3.933-.629 1.548 1.548 0 0 1-.731-1.117c-.064-.466.085-.93.407-1.272l1.024-1.092a1.939 1.939 0 0 1 .12-2.479l3.408-3.71a4.204 4.204 0 0 1 5.366-.703l.971.62-2.776-5.123a5.48 5.48 0 0 1-.245-4.728 1.718 1.718 0 0 1 1.47-1.06 1.719 1.719 0 0 1 1.608.837l11.88 19.91a23.455 23.455 0 0 1 3.281 13.55l-.887 14.079 28.84-2.536 8.462 16.555-40.116 9.055a12.688 12.688 0 0 1-10.155-2.046Z"
              fill="#080717"
            />
            <path
              d="m108.53 143.365 24.903-42.064.371-3.165c-4.4-5.612-11.815-9.83-18.763-9.044l-14.571 1.652c-2.973.336-5.697 1.64-7.746 3.707l-4.745 4.782c-2.556 2.578-1.83 34.858-1.83 34.858l22.381 9.274Z"
              fill="#FFF"
            />
            <path
              d="m87.229 135.534 21.105 8.769 24.035-40.706.322-2.759c-3.848-4.815-10.765-9.265-17.706-8.478l-14.238 1.618c-2.767.314-5.34 1.548-7.244 3.474l-4.636 4.686c-1.685 1.703-1.898 21.23-1.638 33.396m21.332 10.006a.539.539 0 0 1-.207-.042l-21.87-9.087a.549.549 0 0 1-.337-.494c-.002-.08-.177-8.012-.043-16.151.273-16.666 1.52-17.927 1.99-18.402l4.636-4.686c2.08-2.102 4.882-3.448 7.894-3.79l14.239-1.618c7.445-.847 14.819 3.944 18.825 9.067.089.114.13.259.113.403l-.363 3.101a.548.548 0 0 1-.073.216l-24.334 41.214a.545.545 0 0 1-.47.269"
              fill="#080717"
            />
            <path
              d="M105.708 74.056c-1.044 8.341 4.858 15.95 13.184 16.997 8.325 1.045 15.92-4.869 16.963-13.21 1.044-8.34-4.859-15.95-13.183-16.996-8.326-1.046-15.92 4.868-16.964 13.209"
              fill="#080717"
            />
            <path
              d="M99.153 67.12c-.67 5.363 3.124 10.255 8.476 10.927 5.352.673 10.234-3.13 10.905-8.491.671-5.363-3.124-10.255-8.475-10.927-5.352-.672-10.235 3.13-10.906 8.492"
              fill="#080717"
            />
            <path
              d="M96.949 65.487a5.434 5.434 0 0 0 4.708 6.07 5.428 5.428 0 0 0 6.059-4.717 5.434 5.434 0 0 0-4.709-6.07 5.427 5.427 0 0 0-6.058 4.717M140.234 66.388c-.692 5.96-5.589 10.206-10.938 9.481-5.35-.725-9.125-6.144-8.433-12.105.692-5.96 5.59-10.205 10.938-9.48 5.35.724 9.125 6.143 8.433 12.104"
              fill="#080717"
            />
            <path
              d="M136.044 75.95a.584.584 0 0 1-.53-.326.54.54 0 0 1 .292-.728c2.905-1.236 4.986-4.096 5.432-7.463.655-4.959-2.451-9.426-6.925-9.958a.558.558 0 0 1-.504-.615.574.574 0 0 1 .649-.478c5.109.608 8.667 5.627 7.932 11.188-.495 3.746-2.836 6.938-6.109 8.331a.603.603 0 0 1-.237.049M92.676 62.23c-.97 7.746 4.512 14.812 12.242 15.783 7.73.971 14.783-4.52 15.752-12.266.97-7.745-4.512-14.811-12.242-15.783-7.73-.97-14.783 4.521-15.752 12.266"
              fill="#080717"
            />
            <path
              d="M103.529 57.88c-.97 7.746 4.511 14.812 12.241 15.784 7.73.97 14.783-4.521 15.753-12.266.969-7.746-4.512-14.812-12.242-15.783-7.73-.971-14.783 4.52-15.752 12.266"
              fill="#080717"
            />
            <path
              d="M104.627 60.193c-.892 7.127 4.17 13.65 11.281 14.544 7.114.895 13.624-4.177 14.516-11.303.892-7.126-4.17-13.65-11.281-14.544-7.112-.892-13.623 4.178-14.516 11.303m12.909 15.757a14.11 14.11 0 0 1-1.766-.111c-7.718-.97-13.21-8.05-12.242-15.784.968-7.733 8.039-13.234 15.753-12.266 7.718.97 13.21 8.05 12.242 15.783-.893 7.14-6.985 12.378-13.987 12.378M116.123 82.474a.221.221 0 0 1-.04-.004c-.467-.084-.91-.348-1.28-.761a3.48 3.48 0 0 1-.466-.67c-.108-.2-.083-.489.056-.644.138-.155.337-.12.446.081.102.19.221.362.353.509.28.313.616.512.97.576.174.032.298.261.276.512-.02.231-.157.4-.315.4"
              fill="#080717"
            />
            <path
              d="m101.581 94.238-.323-7.65a7.016 7.016 0 0 1-.766-.209c-4.826-1.67-7.056-8.19-4.98-14.566 2.076-6.374 7.67-10.189 12.497-8.52 3.841 1.33 6.035 5.731 5.725 10.686a4.273 4.273 0 0 1 3.313-.944c2.228.3 3.897 2.303 3.722 4.47-.19 2.349-2.35 4.022-4.742 3.713a4.387 4.387 0 0 1-1.424-.442l.623 14.746-13.645-1.284Z"
              fill="#FFF"
            />
            <path
              d="M114.44 95.522a.57.57 0 0 1-.564-.552l-.647-15.209a.58.58 0 0 1 .255-.508.558.558 0 0 1 .557-.034c.416.209.854.344 1.303.401a3.952 3.952 0 0 0 3.012-.853 3.54 3.54 0 0 0 1.286-2.452c.158-1.93-1.36-3.721-3.38-3.991a3.93 3.93 0 0 0-3.02.85.555.555 0 0 1-.613.064.58.58 0 0 1-.303-.549c.314-4.992-1.924-9.186-5.57-10.437-4.714-1.619-10.226 2.161-12.282 8.424-1.004 3.059-1.042 6.24-.105 8.956.92 2.666 2.67 4.562 4.929 5.337.256.088.516.15.736.198.251.056.433.277.444.539l.336 7.89a.571.571 0 0 1-.539.6.572.572 0 0 1-.588-.55l-.317-7.454a6.355 6.355 0 0 1-.431-.13c-2.596-.89-4.597-3.038-5.634-6.047-1.02-2.958-.984-6.405.1-9.706 2.253-6.866 8.402-10.97 13.71-9.15 3.86 1.324 6.335 5.47 6.368 10.504a5.077 5.077 0 0 1 2.924-.483c2.61.349 4.566 2.695 4.36 5.231a4.688 4.688 0 0 1-1.7 3.25 5.052 5.052 0 0 1-3.864 1.104 5.078 5.078 0 0 1-.81-.173l.61 14.328a.572.572 0 0 1-.539.601h-.025"
              fill="#080717"
            />
            <path
              d="M114.199 73.68c-.089.013-.177.03-.266.04-4.98.608-9.678-3.912-10.492-10.094-.01-.07-.015-.139-.024-.208 1.692-.61 3.479-.703 5.17-.153 3.929 1.28 6.114 5.619 5.612 10.414M104.558 86.823c-.23 0-.46-.009-.692-.026-.265-.019-.465-.27-.447-.562.017-.292.244-.515.511-.492 2.745.199 5.425-.931 7.344-3.109a.45.45 0 0 1 .68-.011c.19.203.195.538.01.748-1.954 2.217-4.622 3.452-7.406 3.452M99.064 69.687c.09.391-.317 1.077-.91 1.533-.592.456-1.145.51-1.236.118-.09-.39.318-1.076.91-1.533.592-.455 1.146-.509 1.236-.118M105.58 73.443c-.076.432-.618.444-1.213.027-.594-.417-1.016-1.106-.941-1.538.075-.432.618-.444 1.212-.027.595.417 1.016 1.106.941 1.538M100.007 75.95a.644.644 0 0 1-.443-.168c-.464-.444-.61-1.064-.38-1.618l.926-2.239c.105-.25.436-.384.742-.3.304.086.468.36.364.61l-.928 2.239c-.098.237-.037.492.161.682.21.201.183.505-.061.678a.66.66 0 0 1-.38.116M100.265 81.11c.326.336.714.61 1.097.578.34-.032.647-.31.869-.59a27.64 27.64 0 0 0-1.966.013m1.028 1.363c-.727 0-1.443-.453-2.129-1.346a.393.393 0 0 1-.024-.462c.127-.198.3-.47 3.851-.327a.461.461 0 0 1 .355.179.35.35 0 0 1 .04.357c-.07.151-.71 1.477-1.929 1.591-.055.005-.11.008-.164.008M114.848 78.124h-.02a.567.567 0 0 1-.558-.577c.045-1.263.455-2.24 1.219-2.903 1.101-.957 2.526-.869 2.585-.866.319.023.558.29.535.598a.568.568 0 0 1-.615.517c-.023 0-1.017-.048-1.741.589-.516.453-.794 1.16-.827 2.103a.57.57 0 0 1-.578.54M99.045 72.832c-.142.595-.73 1.013-1.312.933-.582-.08-.938-.626-.796-1.222.143-.595.73-1.013 1.312-.933.582.08.939.627.796 1.222M104.41 75.007c-.142.595-.73 1.012-1.311.933-.582-.08-.939-.627-.796-1.222.143-.595.73-1.013 1.312-.933.581.08.938.626.796 1.222"
              fill="#080717"
            />
            <path
              d="M126.764 117.269a.503.503 0 0 1-.405-.218l-5.454-7.486a.669.669 0 0 1 .066-.854c.224-.215.558-.18.744.077l5.455 7.485a.67.67 0 0 1-.067.854.487.487 0 0 1-.339.142"
              fill="#FFF"
            />
            <path
              fill="#080717"
              d="m96.97 109.399-13.251 1.346-6.347-17.805 9.553-.893 12.151-.875z"
            />
            <path
              d="M85.107 107.399a12.615 12.615 0 0 1 3.266-6.118l4.67-4.804c1.81-1.862 4.156-3.101 6.732-3.581-1.845 4.195-1.471 7.68 1.396 8.792 4.157 1.614 11.98-2.366 17.472-8.89.456-.543.88-1.085 1.274-1.626 5.106.734 9.887 3.49 13.177 7.77l2.546 3.067 6.846 10.892-10.203 13.478-6.612-8.299-8.086 42.682H73.03l1.729-5.15a34.778 34.778 0 0 1 7.044-12.13l3.278-3.662.025-32.421Z"
              fill="#080717"
            />
            <path
              d="M86.622 110.745a.535.535 0 0 1-.54-.486c-.48-6.479 5.398-10.153 5.649-10.306a.555.555 0 0 1 .749.16.51.51 0 0 1-.165.72c-.057.035-5.582 3.503-5.15 9.353a.53.53 0 0 1-.543.559M100.033 144.3c2.457-1.408 5.221-.973 7.725.305.664.19 1.536 1.367 2.156 1.707l3.938.751 27.443-10.812-7.882-10.64 9.504-12.692 14.42 21.88-.102.07.69.97c4.082 5.723 1.375 13.787-5.32 15.844-13.316 4.09-31.924 9.797-34.372 10.51l-.064.06c-3.329 1.398-5.843 2.135-9.007.993l-4.598-1.896 1.593.992a1.84 1.84 0 0 1 .739 2.225.847.847 0 0 1-1.045.505l-4.036-1.274a5.153 5.153 0 0 1-3.36-4.09l-1.49-9.84a5.172 5.172 0 0 1 2.534-5.263l.534-.306Z"
              fill="#FFF"
            />
            <path
              d="m98.238 144.83-.543.312c-1.664.957-2.589 2.883-2.3 4.795l1.514 10.037a4.683 4.683 0 0 0 3.05 3.724l4.085 1.294a.306.306 0 0 0 .374-.182 1.313 1.313 0 0 0-.525-1.585l-1.62-1.012a.567.567 0 0 1-.203-.736.556.556 0 0 1 .71-.266l4.675 1.934c2.85 1.032 5.159.487 8.638-.973a.557.557 0 0 1 .216-.12c2.423-.709 20.336-6.22 34.936-10.72 3.07-.945 5.419-3.248 6.446-6.317 1.028-3.07.545-6.342-1.327-8.976l-.703-.988a.565.565 0 0 1-.102-.385l-14.029-21.359-8.935 11.972 7.763 10.514a.57.57 0 0 1-.244.863l-27.9 11.03a.553.553 0 0 1-.47-.03l-4.007-2.205a9.002 9.002 0 0 0-1.85-.762.54.54 0 0 1-.1-.039 7.886 7.886 0 0 0-7.55.18m5.895 21.304c-.14 0-.283-.02-.423-.065l-4.103-1.3a5.814 5.814 0 0 1-3.802-4.625l-1.515-10.037c-.357-2.37.79-4.76 2.854-5.945l.542-.312a8.993 8.993 0 0 1 8.558-.231c.708.207 1.39.49 2.03.841l3.777 2.079 26.974-10.664-7.575-10.26a.568.568 0 0 1 .001-.678l9.663-12.946a.556.556 0 0 1 .912.028l14.66 22.318c.07.107.1.23.091.348l.496.696c2.084 2.933 2.623 6.576 1.478 9.995-1.145 3.418-3.761 5.982-7.179 7.036-14.378 4.43-31.969 9.843-34.827 10.688a.557.557 0 0 1-.129.076c-3.132 1.32-6.013 2.306-9.56 1.022l-1.56-.644a2.453 2.453 0 0 1-.036 1.666 1.426 1.426 0 0 1-1.327.914"
              fill="#080717"
            />
            <path
              d="M95.35 149.889c-.214 0-.422-.058-.535-.16-.167-.153-.063-.346.232-.432l5.284-1.541c.295-.087.67-.033.836.12.167.151.064.344-.232.43l-5.283 1.542c-.096.028-.2.041-.302.041M97.334 154.238a.45.45 0 0 1-.378-.183c-.11-.168-.031-.377.178-.465l3.486-1.486c.209-.09.467-.025.577.143.11.168.03.377-.178.466l-3.486 1.485a.509.509 0 0 1-.2.04M97.35 156.413a.471.471 0 0 1-.391-.185c-.116-.17-.035-.384.18-.475l3.455-1.472c.216-.093.484-.029.6.142.116.171.035.384-.18.476l-3.455 1.472a.536.536 0 0 1-.21.042M53.107 73.775l-.271-.097c-2.41-.863-4.365-2.558-5.503-4.774l-1.01-1.968-4.72-2.384c-.77-.389-1.21-1.177-1.118-2.008.064-.586.39-1.115.893-1.452a2.176 2.176 0 0 1 1.727-.302l9.464 2.04-.244 1.046-9.48-2.043a1.04 1.04 0 0 0-.829.14.95.95 0 0 0-.42.683.943.943 0 0 0 .527.947l5.04 2.545 1.172 2.281c1.011 1.97 2.748 3.477 4.89 4.244l.271.097-.39 1.005Z"
              fill="#080717"
            />
            <path
              d="M37.697 65.076a9.576 9.576 0 0 1-1.565-.128l.248-.927c1.775.294 3.634-.02 5.1-.86l.453-.26.71.765-.452.26c-1.314.753-2.893 1.15-4.494 1.15M38.704 69.425l-.401-1.015.199-.12c1.318-.788 2.48-1.87 3.457-3.214l.685.752c-1.056 1.455-2.315 2.625-3.74 3.478l-.2.12ZM43.625 69.425l-.981-2.02 1.188-.154.982 2.021z"
              fill="#080717"
            />
          </g>
        </g>
      </g>
    </Box>
  );
};
