import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  DefaultProps,
  packSx,
  Pagination as MantinePagination,
  Text,
} from '@mantine/core';

import { LayoutMainBottomPaddingSpacingContext } from 'context';
import { MetaType } from 'types';

type Props = {
  meta: MetaType;
  currentPage: number;
  changePage: (page: number) => void;
  isStickyToBottom?: boolean;
  sx?: DefaultProps['sx'];
};

export const Pagination = ({
  meta,
  currentPage,
  changePage,
  isStickyToBottom,
  sx,
}: Props) => {
  const { t } = useTranslation('common');

  const [layoutMainBottomPaddingSpacing] = useContext(
    LayoutMainBottomPaddingSpacingContext,
  );

  const { lastPage, from, to, total } = meta;

  return (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          alignItems: 'center',
          ...(isStickyToBottom && {
            bottom: theme.other.spacing(-layoutMainBottomPaddingSpacing),
            margin: theme.other.spacing(0, -1, -1),
            padding: theme.other.spacing(0, 1, 3),
            backgroundColor: theme.other.layoutBodyColor,
            zIndex: 2,
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              position: 'sticky',
            },
          }),
        }),
        ...packSx(sx),
      ]}
    >
      <Text
        sx={theme => ({ marginRight: theme.other.spacing(1) })}
        size="sm"
        color="gray.7"
      >
        {from}-{to} {t('common:from')} {total}
      </Text>
      <MantinePagination
        page={currentPage}
        total={lastPage}
        onChange={changePage}
      />
    </Box>
  );
};
