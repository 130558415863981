import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker, useBeforeUnload } from 'react-router-dom';

import { Modal } from 'components/shared';

type Props = {
  hasUnsavedChanges: boolean;
};

export const NotSavedChangesModal = ({ hasUnsavedChanges }: Props) => {
  const { t } = useTranslation('common');

  const { state, reset, proceed } = unstable_useBlocker(hasUnsavedChanges);

  const isBlockedByReactRouter = state === 'blocked';

  useBeforeUnload(
    useCallback(
      e => {
        if (hasUnsavedChanges && !isBlockedByReactRouter) {
          e.preventDefault();
          e.returnValue = '';
        }
      },
      [hasUnsavedChanges, isBlockedByReactRouter],
    ),
  );

  return (
    <Modal
      isOpen={isBlockedByReactRouter}
      onClose={reset}
      size="lg"
      title={t('common:notSavedChanges')}
      defaultButtonText={t('common:back')}
      defaultButtonAction={reset}
      primaryButtonText={t('common:yesCancelChanges')}
      primaryButtonAction={proceed}
    >
      {t('notSavedChangesDescription')}
    </Modal>
  );
};
