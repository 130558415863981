import { useId } from 'react';
import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ManWithDocumentIcon = ({
  color,
  size,
  width = 148,
  height = 139,
  sx,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 148 139"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <mask
        id={`${id}_a`}
        width={65}
        height={85}
        x={2}
        y={2}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M2 2.13v84.74h65V16.06L52.72 2.13H2Z" />
      </mask>
      <g mask={`url(#${id}_a)`}>
        <path fill="#fff" d="M68 1.16H1v86.69h67V1.16Z" />
      </g>
      <mask
        id={`${id}_b`}
        width={66}
        height={88}
        x={1}
        y={1}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M2.27 2.39h49.61l13.85 13.73v70.75H2.27V2.39ZM1 1.13v87h66V15.6L52.41 1.13H1Z"
        />
      </mask>
      <g mask={`url(#${id}_b)`}>
        <path fill="#000" d="M68 .13H0v89h68v-89Z" />
      </g>
      <mask
        id={`${id}_c`}
        width={16}
        height={16}
        x={52}
        y={1}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m52.48 16.08.15-14.95 14.68 14.69s-14.66.42-14.83.25"
        />
      </mask>
      <g mask={`url(#${id}_c)`}>
        <path fill={iconColor} d="M68.31.13H51.48v17.11h16.83V.13Z" />
      </g>
      <mask
        id={`${id}_d`}
        width={37}
        height={34}
        x={105}
        y={40}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M125.67 40.19c-1.89 0-3.7.33-5.38.93a8.124 8.124 0 0 0-3.71-.89c-3.37 0-6.26 2.03-7.57 4.95a8.957 8.957 0 0 0-3.16 6.83c0 3.05 1.52 5.73 3.85 7.36 1.28 7.78 7.94 13.71 15.97 13.71 8.95 0 16.2-7.36 16.2-16.44 0-9.08-7.25-16.44-16.2-16.44"
        />
      </mask>
      <g mask={`url(#${id}_d)`}>
        <path fill="#0A091A" d="M142.87 39.19h-38.02v34.89h38.02V39.19Z" />
      </g>
      <mask
        id={`${id}_e`}
        width={5}
        height={5}
        x={133}
        y={40}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M133.96 43.99s1.72-2.01.94-2.96c-.77-.94 1.07.64.99 1.97 0 0 1.07-.17 2.06.3 0 0-2.83.56-3.13 1.54l-.86-.86v.01Z"
        />
      </mask>
      <g mask={`url(#${id}_e)`}>
        <path fill="#0A091A" d="M138.95 39.09h-5.99v6.75h5.99v-6.75Z" />
      </g>
      <mask
        id={`${id}_f`}
        width={31}
        height={42}
        x={104}
        y={49}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M108.54 53.69s.37 3.21-.8 4.67-5.26 2.11-1.61 4.81c0 0-5.33 15.6 10.81 16.4v11.08l17.3-4.01.29-16.18s-5.48 1.02-7.67-3.28c0 0 4.82-.58 4.89-3.79.07-3.21-3.14-3.5-4.67-3.57-1.53-.07-7.3-2.7-4.96-8.75 0 0-6.64-6.05-13.58 2.62Z"
        />
      </mask>
      <g mask={`url(#${id}_f)`}>
        <path fill="#fff" d="M135.53 44.02H99.8v47.63h35.73V44.02Z" />
      </g>
      <mask
        id={`${id}_g`}
        width={32}
        height={44}
        x={104}
        y={48}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M116.3 91.46V80.18c-4.59-.34-7.88-1.94-9.79-4.75-3-4.41-1.6-10.36-1.11-12.02-.97-.79-1.41-1.5-1.38-2.23.04-1.06 1.07-1.7 1.98-2.27.49-.31 1-.62 1.25-.94.82-1.03.76-3.38.67-4.19l-.03-.26.17-.21c2.42-3.02 5.08-4.67 7.92-4.89 3.81-.3 6.48 2.09 6.59 2.19l.32.29-.16.41c-.76 1.97-.67 3.71.29 5.17 1.21 1.85 3.4 2.67 4.11 2.71 3.56.17 5.34 1.59 5.28 4.22-.06 2.78-2.96 3.85-4.51 4.23 2.17 2.97 6.48 2.21 6.52 2.2l.77-.14-.32 17.46-18.57 4.3Zm-7.1-37.56c.07.88.16 3.46-.97 4.87-.39.49-.99.86-1.57 1.23-.61.38-1.36.85-1.38 1.24 0 .16.1.6 1.22 1.43l.38.28-.15.44c-.02.07-2.26 6.8.83 11.34 1.75 2.58 4.92 4 9.4 4.22l.61.03v10.88l16.03-3.72.27-14.96c-1.69.11-5.72-.04-7.58-3.7l-.41-.81.91-.11s4.27-.56 4.33-3.17c.04-1.88-1.21-2.78-4.06-2.92-1.07-.05-3.67-1.07-5.12-3.29-.78-1.19-1.48-3.16-.57-5.89-.76-.56-2.77-1.81-5.32-1.61-2.4.2-4.7 1.61-6.84 4.21l-.01.01Z"
        />
      </mask>
      <g mask={`url(#${id}_g)`}>
        <path fill="#000" d="M136.18 47.1H102.5v45.35h33.68V47.1Z" />
      </g>
      <mask
        id={`${id}_h`}
        width={9}
        height={14}
        x={55}
        y={34}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M58.5 34.39s-4.05 3.5-1.95 8c2.1 4.51 6.62 4.66 6.62 4.66s1.87-10.18-4.67-12.67"
        />
      </mask>
      <g mask={`url(#${id}_h)`}>
        <path fill="#fff" d="M66.05 33.39H53.46v14.67h12.59V33.39Z" />
      </g>
      <mask
        id={`${id}_i`}
        width={10}
        height={15}
        x={55}
        y={33}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m63.7 47.71-.55-.02c-.2 0-4.93-.22-7.17-5.03-2.28-4.89 2.06-8.72 2.11-8.76l.29-.25.36.14c6.96 2.65 5.15 12.94 5.07 13.38l-.1.54h-.01Zm-5.06-12.57c-.83.85-3.08 3.6-1.51 6.99 1.5 3.21 4.29 4.02 5.49 4.23.25-2.08.72-9.07-3.98-11.21v-.01Z"
        />
      </mask>
      <g mask={`url(#${id}_i)`}>
        <path fill="#000" d="M66.69 32.66H52.7v16.06h13.99V32.66Z" />
      </g>
      <mask
        id={`${id}_j`}
        width={7}
        height={4}
        x={109}
        y={55}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M114.6 58.72c-.22 0-.44-.12-.56-.32-1.43-2.52-3.43-2.11-3.51-2.1-.34.08-.62.11-.7-.23-.08-.34.13-.54.47-.63.12-.03 3.35-1.27 5.27 2.11.17.31-.35.91-.66 1.08-.1.06-.21.08-.31.08"
        />
      </mask>
      <g mask={`url(#${id}_j)`}>
        <path fill="#000" d="M116.74 53.18h-7.99v6.54h7.99v-6.54Z" />
      </g>
      <mask
        id={`${id}_k`}
        width={7}
        height={4}
        x={107}
        y={63}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M109.14 66.06c-.34 0-.65-.05-.88-.11a.641.641 0 0 1 .33-1.24c.16.04 1.58.37 2.32-.94l.16-.28 1.56-.21a.641.641 0 0 1 .17 1.27l-.94.13c-.74 1.07-1.85 1.37-2.72 1.37"
        />
      </mask>
      <g mask={`url(#${id}_k)`}>
        <path fill="#000" d="M114.4 62.24h-7.68v4.82h7.68v-4.82Z" />
      </g>
      <mask
        id={`${id}_l`}
        width={6}
        height={5}
        x={125}
        y={61}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M126.48 65.45a.64.64 0 0 1-.56-.95l.71-1.28c.03-.34.16-1.02.75-1.45.56-.4 1.31-.44 2.25-.1.33.12.51.49.39.82-.12.33-.49.5-.82.39-.49-.18-.88-.2-1.06-.07-.19.13-.23.48-.23.58v.17l-.87 1.56a.64.64 0 0 1-.56.33Z"
        />
      </mask>
      <g mask={`url(#${id}_l)`}>
        <path fill="#000" d="M131.14 60.34h-6.39v6.11h6.39v-6.11Z" />
      </g>
      <mask
        id={`${id}_m`}
        width={11}
        height={11}
        x={116}
        y={70}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M116.93 80.21c-.34 0-.63-.27-.64-.61-.01-.35.26-.65.62-.66 8.37-.31 8.39-7.7 8.38-8.02 0-.35.28-.64.63-.65.35 0 .63.28.64.63 0 .09.02 8.95-9.61 9.31h-.02Z"
        />
      </mask>
      <g mask={`url(#${id}_m)`}>
        <path fill="#000" d="M127.59 69.27h-12.31v11.94h12.31V69.27Z" />
      </g>
      <mask
        id={`${id}_n`}
        width={122}
        height={109}
        x={25}
        y={28}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M51.97 29.05c-1.3 1.96 8.98 7.23 9.69 8.97.71 1.73 1.43 7.34-2.55 7.74-3.98.41-7.86-2.65-8.26.1-.41 2.75 6.12 5.91 15.41 5.6 0 0 .51 46.97 32.35 56.03l-5.92 2.14-36.73-13.45S43.92 85.69 44.23 83.44c.31-2.24-2.96-.71-1.73 2.55 1.22 3.26 2.14 5.4 1.12 5.91-1.02.51-17.86-3.57-17.86-3.57s-.92 1.94 2.55 3.46c3.47 1.53 15.41 6.32 18.78 8.76 3.37 2.45 37.14 27.71 44.49 29.34 0 0 .82 5.09-1.43 5.91-2.24.82 54.32.66 54.32.66s8.4-34.16-10.13-47.85l.05-4.91-17.37 4.31-.17-3.54-28.52-10.61S71.99 38.05 69.75 36.23C67.5 34.42 53.17 27.25 52 29.02"
        />
      </mask>
      <g mask={`url(#${id}_n)`}>
        <path fill="#fff" d="M148 26.27H23.83v111.36H148V26.27Z" />
      </g>
      <mask
        id={`${id}_o`}
        width={123}
        height={110}
        x={24}
        y={28}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M134.34 137.13c-4.87 0-10.97 0-17.03-.03-7.89-.03-14.18-.09-18.7-.18-9.03-.17-9.06-.28-9.17-.94-.05-.28.08-.62.48-.76 1.28-.47 1.23-3.37 1.07-4.8-4.97-1.47-19.33-10.94-42.69-28.16-.75-.55-1.29-.95-1.6-1.17-2.64-1.92-10.82-5.37-15.71-7.44-1.23-.52-2.26-.95-2.95-1.26-1.53-.67-2.5-1.53-2.88-2.54-.36-.96-.03-1.7 0-1.78l.22-.47.51.12c7.63 1.85 16.25 3.7 17.45 3.59.33-.33-.57-2.71-1.17-4.31l-.3-.78c-.72-1.91-.09-3.35.66-4.01.54-.47 1.18-.59 1.68-.33.24.13.78.55.63 1.65-.09 1.22 5.84 7.21 11.43 12.09l36.4 13.33 3.92-1.42c-12.32-4.23-21.54-15-26.73-31.27-3.54-11.11-4.15-21.51-4.25-24.16-7.48.13-12.69-1.89-14.57-3.99-.85-.95-.94-1.81-.85-2.36.1-.64.36-1.11.8-1.4.86-.57 2.07-.25 3.59.15 1.36.36 2.9.76 4.45.61.88-.09 1.52-.48 1.94-1.19 1-1.68.54-4.57.09-5.68-.21-.51-2.3-1.96-3.82-3.02-3.73-2.6-6.94-4.84-5.82-6.54.19-.29.51-.47.93-.54 3.18-.49 15.65 5.91 17.75 7.6 2.26 1.82 16.53 32.96 18.65 37.62l28.67 10.67.15 3.18 17.42-4.33-.05 5.41c7.58 5.76 11.73 15.9 12.02 29.37.22 10.22-1.88 18.87-1.9 18.96l-.12.49h-.5c-.07 0-4.2.01-10.12.01m-42.78-1.7c6.23.38 33.52.46 52.38.41.91-4.19 6.79-34.29-10-46.7l-.26-.19.04-4.41-17.33 4.3-.19-3.89L87.8 74.38l-.11-.24c-6.54-14.34-16.84-36.13-18.4-37.39-.97-.78-4.7-2.85-8.71-4.67-5.47-2.49-7.68-2.82-8.12-2.65-.27.73 4.06 3.75 5.5 4.75 2.5 1.74 3.93 2.77 4.27 3.59.5 1.21 1.15 4.61-.17 6.82-.63 1.05-1.63 1.67-2.91 1.8-1.77.18-3.51-.27-4.9-.64-1.07-.28-2.18-.57-2.56-.32-.12.08-.2.25-.23.52-.06.41.12.85.54 1.32 1.73 1.94 7.17 3.78 14.21 3.55l.65-.02v.65c.01.46.88 46.6 31.89 55.43l1.9.54-8 2.89-37.06-13.57-.09-.08c-2.89-2.51-12.27-10.91-11.95-13.3.02-.15.02-.26 0-.32-.09.04-.25.14-.4.35-.2.28-.6 1.06-.11 2.38l.29.78c1.23 3.24 1.97 5.2.52 5.93-.99.49-9.96-1.51-17.6-3.35.08.51.53 1.32 2.26 2.09.69.3 1.71.73 2.93 1.25 4.94 2.09 13.2 5.58 15.96 7.58.3.22.85.62 1.6 1.18 29.83 21.99 39.66 27.39 42.65 28.06l.42.09.07.43c.07.46.56 3.82-.63 5.62"
        />
      </mask>
      <g mask={`url(#${id}_o)`}>
        <mask
          id={`${id}_p`}
          width={122}
          height={110}
          x={25}
          y={28}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M147 28.13H25v109h122v-109Z" />
        </mask>
        <g mask={`url(#${id}_p)`}>
          <path fill="#000" d="M148 27.13H24v111h124v-111Z" />
        </g>
      </g>
      <mask
        id={`${id}_q`}
        width={3}
        height={4}
        x={110}
        y={57}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M112.31 59.02c0 .57-.46 1.03-1.03 1.03-.57 0-1.03-.46-1.03-1.03 0-.57.46-1.03 1.03-1.03.57 0 1.03.46 1.03 1.03Z"
        />
      </mask>
      <g mask={`url(#${id}_q)`}>
        <path fill="#000" d="M113.31 56.98h-4.07v4.07h4.07v-4.07Z" />
      </g>
      <mask
        id={`${id}_r`}
        width={14}
        height={28}
        x={90}
        y={78}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M91.56 105.11c-.09 0-.17-.02-.26-.05a.642.642 0 0 1-.33-.84l11-24.95c.14-.32.52-.47.84-.33.32.14.47.52.33.84l-11 24.95c-.11.24-.34.38-.59.38"
        />
      </mask>
      <g mask={`url(#${id}_r)`}>
        <path fill="#000" d="M104.28 77.8H89.83v28.31h14.45V77.8Z" />
      </g>
      <mask
        id={`${id}_s`}
        width={17}
        height={3}
        x={90}
        y={129}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M99.19 131.72c-2.67 0-5.67-.27-7.88-1.22a.639.639 0 0 1 .51-1.17c4.58 1.98 13.37.71 13.46.7.35-.05.67.19.73.54.05.35-.19.67-.54.72-.21.03-2.99.43-6.27.43"
        />
      </mask>
      <g mask={`url(#${id}_s)`}>
        <path fill="#000" d="M107.06 128.18H89.84v4.53h17.22v-4.53Z" />
      </g>
      <mask
        id={`${id}_t`}
        width={31}
        height={37}
        x={97}
        y={95}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m104.95 131.51-7.11-24.36 23.42-11.1c.32-.15.7-.02.85.3.15.32.02.7-.3.85l-22.43 10.64 6.42 21.96c3.49-1.39 17.11-6.65 20.75-5.83.34.08.56.42.48.76-.08.34-.42.56-.77.48-3.01-.69-15.93 4.12-20.65 6.03l-.66.27Z"
        />
      </mask>
      <g mask={`url(#${id}_t)`}>
        <path fill="#000" d="M128.1 94.9H96.84v37.61h31.26V94.9Z" />
      </g>
      <mask
        id={`${id}_u`}
        width={17}
        height={10}
        x={118}
        y={86}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m118.43 95.13 1.98-7.89c.09-.34.43-.55.78-.46.34.09.55.43.46.77l-1.39 5.55 13.85-5.08c.33-.12.7.05.82.38s-.05.7-.38.82l-16.12 5.91Z"
        />
      </mask>
      <g mask={`url(#${id}_u)`}>
        <path fill="#000" d="M136.05 85.69h-18.62v10.44h18.62V85.69Z" />
      </g>
      <mask
        id={`${id}_v`}
        width={8}
        height={16}
        x={110}
        y={87}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M110.73 102.19a.64.64 0 0 1-.58-.91l6.27-13.52a.64.64 0 1 1 1.16.54l-6.27 13.52c-.11.23-.34.37-.58.37Z"
        />
      </mask>
      <g mask={`url(#${id}_v)`}>
        <path fill="#000" d="M118.74 86.31h-9.73v16.88h9.73V86.31Z" />
      </g>
      <mask
        id={`${id}_w`}
        width={16}
        height={21}
        x={102}
        y={85}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M103.36 105.61a.641.641 0 0 1-.52-1.01l13.59-18.87c.21-.29.61-.35.89-.15.29.21.35.6.15.89l-13.59 18.87c-.12.17-.32.27-.52.27Z"
        />
      </mask>
      <g mask={`url(#${id}_w)`}>
        <path fill="#000" d="M118.67 84.38h-17.04v22.23h17.04V84.38Z" />
      </g>
      <mask
        id={`${id}_x`}
        width={7}
        height={9}
        x={8}
        y={8}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="m9.33 15.36-.61-.6a.494.494 0 0 1-.13-.34v-1.61h1v2.4c0 .11-.06.16-.08.18-.01 0-.05.02-.08.02s-.07-.01-.1-.04v-.01Zm.85-2.55h.66c.5 0 .98-.24 1.33-.68.33-.4.52-.93.55-1.49.11.07.22.17.31.28.24.3.38.71.38 1.14 0 .89-.59 1.6-1.31 1.6h-1.92v-.85Zm0-1.55c0-.45.3-.82.66-.82h1.3v.03c0 .43-.13.83-.38 1.14-.24.3-.57.47-.92.47h-.66v-.82ZM8.59 9.68c0-.45.3-.82.66-.82h1.58c.5 0 .93.34 1.16.85h-1.14c-.69 0-1.25.69-1.25 1.55v.82h-1V9.69l-.01-.01Zm.66-1.55C8.56 8.13 8 8.82 8 9.68v4.74c0 .36.13.69.35.91l.61.6c.14.14.3.2.47.2.11 0 .22-.03.33-.09.26-.16.43-.48.43-.84v-.82h1.92c1.05 0 1.9-1.05 1.9-2.33 0-.62-.2-1.21-.55-1.65-.23-.28-.5-.48-.8-.59-.23-.97-.96-1.67-1.82-1.67H9.26l-.01-.01Z"
        />
      </mask>
      <g mask={`url(#${id}_x)`}>
        <mask
          id={`${id}_y`}
          width={8}
          height={9}
          x={7}
          y={8}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M14.05 8.06H7.94v8.14h6.11V8.06Z" />
        </mask>
        <g mask={`url(#${id}_y)`}>
          <path fill="#000" d="M15 7.13H7v10h8v-10Z" />
        </g>
      </g>
      <mask
        id={`${id}_z`}
        width={27}
        height={6}
        x={16}
        y={10}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M38.6 14.05h.71v-2.78h-.71v2.78Zm-2.9-2.35c-.21-.29-.58-.48-1-.48-.33 0-.63.12-.84.31v-.26h-.68v2.78h.71v-1.67c0-.3.29-.56.65-.56.36 0 .64.25.64.56v1.67h.71v-1.67c0-.3.29-.56.65-.56.36 0 .64.25.64.56v1.67h.71v-1.8c0-.57-.53-1.03-1.19-1.03-.42 0-.79.19-1 .48Zm-19.02.96c0-.46.43-.84.96-.84s.96.37.96.84-.43.83-.96.83-.96-.37-.96-.83Zm0-1.01v-.38H16v3.81h.71V13.7c.24.24.58.4 1.02.4.92 0 1.61-.64 1.61-1.44 0-.8-.68-1.44-1.61-1.44-.46 0-.81.17-1.05.43Zm4.71.15c.45 0 .79.22.92.55h-1.84c.13-.33.48-.55.92-.55Zm-1.69.86c0 .8.74 1.44 1.69 1.44.73 0 1.33-.37 1.58-.9h-.79c-.18.19-.42.31-.78.31-.48 0-.84-.26-.94-.62h2.59c.02-.09.04-.19.04-.28 0-.8-.73-1.39-1.68-1.39s-1.69.64-1.69 1.44h-.02Zm4.59-1.03v-.37h-.68v2.78h.71v-1.56c0-.4.37-.62.8-.59v-.69c-.36 0-.64.16-.83.42v.01Zm1.68 1.03c0-.46.43-.84.96-.84s.96.37.96.84-.43.83-.96.83-.96-.37-.96-.83Zm-.73 0c0 .8.68 1.44 1.61 1.44.46 0 .81-.17 1.05-.43v.02c0 .47-.41.85-.98.85-.36 0-.61-.12-.78-.31h-.79c.25.53.85.9 1.57.9.95 0 1.66-.64 1.66-1.44v-2.42h-.68v.38c-.24-.26-.59-.43-1.05-.43-.92 0-1.61.64-1.61 1.44Zm4.61 0c0-.46.43-.84.96-.84s.96.37.96.84-.43.83-.96.83-.96-.37-.96-.83Zm-.73 0c0 .8.68 1.44 1.6 1.44.46 0 .81-.17 1.05-.43v.38h.68v-2.78h-.68v.38c-.24-.26-.59-.43-1.05-.43-.92 0-1.6.64-1.6 1.44Zm11.6-1.04v-.35h-.68v2.78h.71v-1.56c0-.37.35-.67.77-.67.42 0 .77.3.77.67v1.56H43v-1.61c0-.71-.52-1.23-1.34-1.23-.43 0-.74.15-.95.4l.01.01Zm-2.23-1.07c0 .23.21.41.48.41s.48-.18.48-.41c0-.23-.21-.41-.48-.41s-.48.18-.48.41Z"
        />
      </mask>
      <g mask={`url(#${id}_z)`}>
        <mask
          id={`${id}_A`}
          width={29}
          height={6}
          x={15}
          y={10}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path fill="#fff" d="M43.07 10.08H15.94v5.11h27.13v-5.11Z" />
        </mask>
        <g mask={`url(#${id}_A)`}>
          <path fill="#000" d="M44 9.13H15v7h29v-7Z" />
        </g>
      </g>
      <mask
        id={`${id}_B`}
        width={43}
        height={7}
        x={8}
        y={23}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M49.39 23.77c.22 0 .39.17.39.39l.04 4.23c0 .22-.17.39-.39.39l-40.35.35a.39.39 0 0 1-.39-.39l-.04-4.23c0-.22.17-.39.39-.39l40.35-.35Zm0-.63-40.35.35c-.57 0-1.02.47-1.01 1.03l.04 4.23c0 .56.47 1.02 1.03 1.01l40.35-.35c.57 0 1.02-.47 1.01-1.03l-.04-4.23c0-.56-.47-1.02-1.03-1.01Z"
        />
      </mask>
      <g mask={`url(#${id}_B)`}>
        <path
          fill="#000"
          d="m51.448 21.76-44.498.388.079 9.01 44.498-.389-.079-9.01Z"
        />
      </g>
      <mask
        id={`${id}_C`}
        width={43}
        height={7}
        x={8}
        y={32}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M49.46 32.8c.22 0 .39.17.39.39l.04 4.23c0 .22-.17.39-.39.39l-40.35.35a.39.39 0 0 1-.39-.39l-.04-4.23c0-.22.17-.39.39-.39l40.35-.35Zm0-.63-40.35.35c-.57 0-1.02.47-1.01 1.03l.04 4.23c0 .56.47 1.02 1.03 1.01l40.35-.35c.57 0 1.02-.47 1.01-1.03l-.04-4.23c0-.56-.47-1.02-1.03-1.01Z"
        />
      </mask>
      <g mask={`url(#${id}_C)`}>
        <path
          fill="#000"
          d="m51.517 30.779-44.498.388.078 9.01 44.499-.388-.079-9.01Z"
        />
      </g>
      <mask
        id={`${id}_D`}
        width={27}
        height={7}
        x={8}
        y={41}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M33.23 41.97c.22 0 .39.17.39.39l.04 4.23c0 .22-.17.39-.39.39l-24.04.21a.39.39 0 0 1-.39-.39l-.04-4.23c0-.22.17-.39.39-.39l24.04-.21Zm0-.63-24.04.21c-.57 0-1.02.47-1.01 1.03l.04 4.23c0 .56.47 1.02 1.03 1.01l24.04-.21c.57 0 1.02-.47 1.01-1.03l-.04-4.23c0-.56-.47-1.02-1.03-1.01Z"
        />
      </mask>
      <g mask={`url(#${id}_D)`}>
        <path
          fill="#000"
          d="m35.279 40.1-28.19.246.077 8.72 28.189-.246-.076-8.72Z"
        />
      </g>
      <mask
        id={`${id}_E`}
        width={27}
        height={8}
        x={8}
        y={50}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M33.51 51.11c.21 0 .39.17.39.39l.04 4.28c0 .22-.17.39-.38.4l-23.95.21a.39.39 0 0 1-.39-.39l-.04-4.28c0-.22.17-.39.38-.4l23.95-.21Zm0-.63-23.95.21c-.56 0-1.02.47-1.01 1.04L8.59 56c0 .57.47 1.03 1.03 1.02l23.95-.21c.56 0 1.02-.47 1.01-1.04l-.04-4.27c0-.57-.47-1.03-1.03-1.02Z"
        />
      </mask>
      <g mask={`url(#${id}_E)`}>
        <path
          fill="#000"
          d="m35.558 49.237-28.089.246.077 8.77 28.089-.246-.077-8.77Z"
        />
      </g>
      <mask
        id={`${id}_F`}
        width={25}
        height={13}
        x={37}
        y={68}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path
          fill="#fff"
          d="M37.84 80.13c-.08 0-.16-.02-.23-.06a.468.468 0 0 1-.19-.64c.3-.55.66-1.14 1.04-1.77.97-1.6 2.1-3.47 2.68-5.34-.38-.28-.66-.73-.75-1.24-.16-.88.28-1.73 1.19-2.34.17-.11.37-.13.54-.04.33.17.35.57.36.9.02.45 0 .89-.06 1.34.07-.21.19-.41.48-.42h.37l.13.39c.16.55.15 1.13.11 1.66.19.11.4.27.65.47.54.42 1.56 1.21 1.98.91.95-.68 1.26-1.05 1.41-1.22.32-.37.51-.37.95-.29.48.09 1.59.29 4.82.26 1.81-.02 2.75-.41 3.5-.72 1.34-.55 2.24-.79 4.92.69.23.13.31.42.19.64-.13.23-.42.31-.65.19-2.42-1.33-3.01-1.09-4.1-.64-.79.32-1.86.76-3.85.79-3.32.04-4.5-.18-5.01-.27-.03 0-.05 0-.07-.01-.18.21-.55.63-1.57 1.37-.99.71-2.27-.28-3.11-.93-.06-.05-.13-.1-.2-.16-.06.3-.14.63-.22.96.02.45.01.92-.22 1.33-.24.43-.52.43-.71.36-.44-.18-.37-.46-.06-1.67 0-.04.02-.07.03-.11v-.09c-.03-.56-.08-1.24.36-1.69 0-.08.01-.15.02-.23-.16.08-.34.13-.53.13-.62 1.97-1.77 3.88-2.76 5.51-.37.62-.73 1.2-1.02 1.74a.48.48 0 0 1-.42.25m3.71-10.07c-.19.27-.27.56-.22.85.02.1.05.2.1.29.07-.38.11-.77.12-1.14Z"
        />
      </mask>
      <g mask={`url(#${id}_F)`}>
        <path fill="#000" d="M63.07 67.62H36.3v13.51h26.77V67.62Z" />
      </g>
    </Box>
  );
};
