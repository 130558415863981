import { useTranslation } from 'react-i18next';
import { DropzoneProps } from '@mantine/dropzone';

import { getUploadFileErrorMessage } from 'helpers';

export const useGetUploadFileErrorMessage = ({
  maxSizeInKB,
}: {
  maxSizeInKB: number;
}) => {
  const { t } = useTranslation('common');

  const errorsMap = {
    'file-invalid-type': t('common:formErrors.invalidFileExtension'),
    'file-too-large': t('common:formErrors.errorTooLarge', {
      sizeInKB: maxSizeInKB,
    }),
  };

  return ([{ errors }]: Parameters<
    NonNullable<DropzoneProps['onReject']>
  >[0]) => getUploadFileErrorMessage(errors, errorsMap);
};
