import { ChangeEvent, forwardRef, Ref, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Progress, Sx, useMantineTheme } from '@mantine/core';

import { TextInputPassword } from 'components/shared';
import { Props as TextInputProps } from 'components/shared/Inputs/TextInput';

import { TextConditions } from './TextConditions';

export type InstanceRefObjectType = {
  isValid: boolean;
};

type Props = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  instanceRef?: Ref<InstanceRefObjectType>;
  sx?: Sx;
} & TextInputProps;

export const TextInputPasswordWithStrength = forwardRef<
  HTMLInputElement,
  Props
>(({ value, onChange, instanceRef, sx, ...textInputPasswordProps }, ref) => {
  const { t } = useTranslation('user');
  const mantineTheme = useMantineTheme();

  const hasMinLength = value.length >= 8;
  const hasLowercaseLetter = /[a-z]/.test(value);
  const hasUppercaseLetter = /[A-Z]/.test(value);
  const hasNumber = /\d/.test(value);

  const numberOfValidChecks =
    Number(hasMinLength) +
    Number(hasLowercaseLetter) +
    Number(hasUppercaseLetter) +
    Number(hasNumber);

  const getProgressColor = (minValidNumber: number) =>
    numberOfValidChecks >= minValidNumber
      ? mantineTheme.colors.green[0]
      : mantineTheme.colors.gray[1];

  const getProgressSections = () =>
    Array.from(Array(4)).map((_, index) => ({
      value: 24.25,
      color: getProgressColor(index + 1),
    }));

  useImperativeHandle(instanceRef, () => ({
    isValid: numberOfValidChecks === 4,
  }));

  return (
    <Box sx={sx}>
      <TextInputPassword
        sx={theme => ({ marginBottom: theme.other.spacing(1) })}
        ref={ref}
        value={value}
        onChange={onChange}
        label={t('user:password')}
        placeholder={t('user:password')}
        withErrorWrapperAlwaysEnabled={false}
        {...textInputPasswordProps}
      />
      <Progress
        styles={theme => ({
          root: {
            maxWidth: 328,
            marginBottom: theme.other.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
          },
          bar: {
            position: 'static',
            '&, &:first-of-type, &:last-of-type': {
              borderRadius: theme.radius.xs,
            },
          },
        })}
        size={4}
        sections={getProgressSections()}
      />
      <TextConditions
        hasMinLength={hasMinLength}
        hasLowercaseLetter={hasLowercaseLetter}
        hasUppercaseLetter={hasUppercaseLetter}
        hasNumber={hasNumber}
      />
    </Box>
  );
});
