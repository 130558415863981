import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useListState } from '@mantine/hooks';
import { z } from 'zod';

import { useGetDataStream, usePatchDataStream } from 'api/dataStreams';
import { getHasHateoasAccess, getZodSchemaErrorMessages } from 'helpers';
import { URLS } from 'consts';
import {
  DataStreamTechnicalUserType,
  DataStreamTemplateType,
  DataTypeType,
  ValidationErrors,
} from 'types';
import {
  useServerErrors,
  useShowNotification,
  useStateWithCursorPosition,
  useTransactionsFeature,
} from 'hooks';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';
import { StreamDataForm, StreamTemplates } from 'components/Transactions';

import { Header } from './Header';

export const DataStreamEdit = () => {
  const { t } = useTranslation(['common', 'transactions', 'dataStreams']);
  const { dataStreamId } = useParams() as {
    dataStreamId: string;
  };

  const showNotification = useShowNotification();
  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const {
    data: transactionsFeature,
    isError: isTransactionFeatureError,
    isLoading: isLoadingTransactionsFeature,
  } = useTransactionsFeature();

  const {
    data: dataStream,
    isError: isDataStreamError,
    isInitialLoading: isDataStreamInitialLoading,
  } = useGetDataStream(
    { pathParams: { dataStreamId } },
    {
      enabled: !isLoadingTransactionsFeature && transactionsFeature,
      keepPreviousData: true,
    },
  );

  const { mutateAsync: updateDataStream, isLoading: isUpdatingDataStream } =
    usePatchDataStream({ pathParams: { dataStreamId } });

  const [dataStreamNameField, setDataStreamNameField, dataStreamNameInputRef] =
    useStateWithCursorPosition();
  const [selectedDataTypeName, setSelectedDataTypeName] =
    useState<DataTypeType['name']>('');
  const [selectedTechnicalUser, setSelectedTechnicalUser] = useState<{
    id: DataStreamTechnicalUserType['id'] | string;
    name: DataStreamTechnicalUserType['name'];
  }>({ id: '', name: '' });
  const [templates, templatesHandler] = useListState<DataStreamTemplateType>(
    null as unknown as undefined,
  );
  const [dataFormErrors, setDataFormErrors] = useState<ValidationErrors | null>(
    null,
  );
  const [serverErrors, setServerErrors] = useState<ValidationErrors | null>(
    null,
  );
  const [hasNoTemplatesError, setHasNoTemplatesError] = useState(false);

  const clearServerError = (errorKey: string) => {
    if (serverErrors?.[errorKey]) {
      setServerErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }
  };
  const clearDataFormError = (errorKey: string) => {
    if (dataFormErrors?.[errorKey]) {
      setDataFormErrors(errors => {
        const errorsToUpdate = { ...errors };

        delete errorsToUpdate[errorKey];

        return errorsToUpdate;
      });
    }

    clearServerError(errorKey);
  };
  const clearTemplatesError = () => {
    clearServerError('templates');
    setHasNoTemplatesError(false);
  };

  const dataFormSchema = z.object({
    name: z.string().min(1, { message: t('common:formErrors.required') }),
    dataType: z.string().min(1, { message: t('common:formErrors.required') }),
    technicalUserId: z.number({
      invalid_type_error: t('common:formErrors.required'),
    }),
  });

  const handleDataStreamUpdate = async () => {
    const errors = getZodSchemaErrorMessages({
      schema: dataFormSchema,
      fields: {
        name: dataStreamNameField.value,
        dataType: selectedDataTypeName,
        technicalUserId: selectedTechnicalUser.id,
      },
    });

    if (templates.length === 0) {
      setHasNoTemplatesError(true);
    }

    if (errors) {
      setDataFormErrors(errors);
    } else if (templates.length > 0) {
      try {
        await updateDataStream({
          name: dataStreamNameField.value,
          dataType: selectedDataTypeName,
          technicalUserId: Number(selectedTechnicalUser.id),
          templates,
        });

        showNotification({
          message: t('dataStreams:dataStreamUpdated'),
        });
      } catch (error) {
        setServerErrors(getServerErrorStatus(error) || {});
        addServerErrorActions(error);
      }
    }
  };

  useEffect(() => {
    if (dataStream) {
      setDataStreamNameField({
        value: dataStream.name,
        cursorPosition: null,
      });
      setSelectedDataTypeName(dataStream.dataType);
      setSelectedTechnicalUser({
        id: dataStream.technicalUser.id || '',
        name: dataStream.technicalUser.name || '',
      });
      templatesHandler.setState(dataStream.templates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStream]);

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={dataStream}
        isError={isDataStreamError || isTransactionFeatureError}
        isLoading={
          isDataStreamInitialLoading ||
          isLoadingTransactionsFeature ||
          templates === null
        }
        isNoAccess={!transactionsFeature && !isLoadingTransactionsFeature}
        errorMargin="xl"
      >
        {({ __links }) => {
          const hasUpdateDataStreamAccess = getHasHateoasAccess(
            'update',
            __links,
          );

          return (
            <>
              <Breadcrumbs
                items={[
                  {
                    name: t('transactions:transactions'),
                    url: URLS.transactions,
                  },
                  { name: t('dataStreams:dataStreams'), url: URLS.dataStreams },
                  {
                    name: t('dataStreams:dataStreamEditBreadcrumb'),
                    url: '#',
                  },
                ]}
              />
              <Header
                sx={theme => ({ marginBottom: theme.other.spacing(4.5) })}
                hasUpdateDataStreamAccess={hasUpdateDataStreamAccess}
                onUpdate={handleDataStreamUpdate}
                isUpdatingDataStream={isUpdatingDataStream}
              />
              <StreamDataForm
                sx={theme => ({ marginBottom: theme.other.spacing(3.5) })}
                dataStreamNameField={dataStreamNameField}
                setDataStreamNameField={setDataStreamNameField}
                dataStreamNameInputRef={dataStreamNameInputRef}
                selectedDataType={selectedDataTypeName}
                setSelectedDataTypeName={setSelectedDataTypeName}
                selectedTechnicalUser={selectedTechnicalUser}
                setSelectedTechnicalUser={setSelectedTechnicalUser}
                hasAccess={hasUpdateDataStreamAccess}
                errors={{ ...serverErrors, ...dataFormErrors }}
                clearError={clearDataFormError}
              />
              <StreamTemplates
                templates={templates}
                templatesHandler={templatesHandler}
                hasAccess={hasUpdateDataStreamAccess}
                serverErrors={serverErrors}
                clearServerError={clearServerError}
                hasNoTemplatesError={hasNoTemplatesError}
                clearTemplatesError={clearTemplatesError}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
