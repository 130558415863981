import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Menu, Text, UnstyledButton } from '@mantine/core';

import { useGetUser } from 'api/account';
import { GuideTourContext } from 'context';
import { useGetV1Url, useIsMdBreakpoint, useLogout } from 'hooks';
import { ChevronDownIcon, TourIcon } from 'icons';
import { GUIDE_TOUR_DATA, URLS } from 'consts';
import { UserAvatar } from 'components/shared';

import { GuideTourOnDemand } from './GuideTourOnDemand';

export const UserMenu = () => {
  const { t } = useTranslation(['common', 'navigation']);

  const isMdBreakpoint = useIsMdBreakpoint();

  const [{ isUserMenuOpenForGuideTour }] = useContext(GuideTourContext);

  const logout = useLogout();
  const getV1Url = useGetV1Url();
  const { data: { email, name, surname, avatar } = {} } = useGetUser();

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isGuideTourOpen, setIsGuideTourOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <>
      <Menu
        data-guide-tour-step={GUIDE_TOUR_DATA.userMenu.selector}
        opened={isUserMenuOpen || isUserMenuOpenForGuideTour}
        position="bottom-end"
        offset={4}
        onChange={setIsUserMenuOpen}
        withinPortal={isUserMenuOpenForGuideTour}
      >
        <Menu.Target>
          <UnstyledButton
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={toggleIsOpen}
          >
            <UserAvatar
              sx={theme => ({ marginRight: theme.other.spacing(1) })}
              name={name}
              surname={surname}
              avatar={avatar}
              size={30}
            />
            {isMdBreakpoint && (
              <Text
                sx={theme => ({
                  color: theme.colors[theme.primaryColor][1],
                })}
                size="sm"
                mr="sm"
              >
                {email}
              </Text>
            )}
            <Box
              sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                borderRadius: theme.radius.sm,
              })}
            >
              <Box
                sx={theme => ({
                  display: 'flex',
                  transition: `transform ${theme.other.animationsTime} ease`,
                  transform: `rotate(${isUserMenuOpen ? '180' : '0'}deg)`,
                })}
              >
                <ChevronDownIcon color="primary.1" />
              </Box>
            </Box>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            sx={theme => ({
              marginBottom: theme.other.spacing(0.5),
              fontWeight: 600,
            })}
            component={Link}
            to={URLS.account}
          >
            {t('navigation:userMenu.accountSettings')}
          </Menu.Item>
          <Menu.Item
            sx={{
              fontWeight: 600,
            }}
            component="a"
            href={getV1Url('organisations')}
          >
            {t('navigation:userMenu.organisationSettings')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            sx={{
              '.mantine-Menu-itemLabel': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onClick={() => {
              setIsGuideTourOpen(true);
            }}
          >
            <TourIcon
              sx={theme => ({
                marginRight: theme.other.spacing(1),
              })}
            />
            {t('navigation:userMenu.guideTour')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            sx={{
              fontWeight: 600,
            }}
            color="red"
            onClick={logout}
          >
            {t('navigation:userMenu.logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {isGuideTourOpen && (
        <GuideTourOnDemand
          onClose={() => {
            setIsGuideTourOpen(false);
          }}
        />
      )}
    </>
  );
};
