import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';
import { StarIcon } from 'icons';
import { UserBoardType } from 'types';
import { TextInputSearch } from 'components/shared';

import { Boards } from '../Boards';
import { filterOrganisationsAndBoardsByNames } from './helpers';

type Props = {
  boards: UserBoardType[];
  changeBoard: (boardId: number) => Promise<void>;
  favoriteBoards?: UserBoardType[];
};

export const BoardsPanel = ({ boards, changeBoard, favoriteBoards }: Props) => {
  const { t } = useTranslation('navigation');

  const [searchValue, setSearchValue] = useState('');

  const filteredBoards = filterOrganisationsAndBoardsByNames(
    boards,
    searchValue,
  );
  const filteredFavoriteBoards = favoriteBoards
    ? filterOrganisationsAndBoardsByNames(favoriteBoards, searchValue)
    : null;

  return (
    <div>
      <TextInputSearch
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
          padding: theme.other.spacing(0, 2),
        })}
        placeholder={t('navigation:organisations.searchPlaceholder')}
        value={searchValue}
        onChange={e => {
          setSearchValue(e.target.value);
        }}
        autoFocus
      />
      <Tabs
        styles={theme => ({
          tabsList: {
            marginBottom: theme.other.spacing(1),
            padding: theme.other.spacing(0, 2),
          },
          panel: {
            maxHeight: 345,
            overflow: 'auto',
          },
        })}
        defaultValue="all"
      >
        <Tabs.List>
          <Tabs.Tab value="all">
            {t('navigation:organisations.all')} ({boards.length})
          </Tabs.Tab>
          {filteredFavoriteBoards && (
            <Tabs.Tab value="favorites" icon={<StarIcon />}>
              {t('navigation:organisations.favorites')}
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="all">
          <Boards boards={filteredBoards} changeBoard={changeBoard} />
        </Tabs.Panel>
        {filteredFavoriteBoards && (
          <Tabs.Panel value="favorites">
            <Boards boards={filteredFavoriteBoards} changeBoard={changeBoard} />
          </Tabs.Panel>
        )}
      </Tabs>
    </div>
  );
};
