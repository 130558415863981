import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';

import { useRedirectToV1IfNeeded } from 'hooks';

export const Home = () => {
  const location = useLocation();

  const redirectToV1IfNeeded = useRedirectToV1IfNeeded();

  const isAccountDeleted = Boolean(location.state?.isAccountDeleted);

  useEffect(() => {
    if (!isAccountDeleted) {
      redirectToV1IfNeeded();
    }
  }, [isAccountDeleted, redirectToV1IfNeeded]);

  return <LoadingOverlay visible />;
};
