import { Box } from '@mantine/core';

import { TickIcon, CancelIcon } from 'icons';

type Text = {
  children: string;
  isValid: boolean;
};

export const TextCondition = ({ children, isValid }: Text) => (
  <Box
    component="li"
    sx={theme => ({
      ...theme.other.getFontSizeStyles('md'),
      display: 'flex',
      marginBottom: theme.other.spacing(1),
      color: isValid
        ? theme.colors.green[0]
        : theme.colors.red[theme.fn.primaryShade()],
      fontWeight: 500,
    })}
  >
    {isValid ? (
      <TickIcon
        size={16}
        color="green"
        sx={theme => ({ marginRight: theme.other.spacing(1) })}
      />
    ) : (
      <CancelIcon
        size={16}
        color="error"
        sx={theme => ({ marginRight: theme.other.spacing(1) })}
      />
    )}
    {children}
  </Box>
);
