import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

export const EmailSentTooltipLabel = () => {
  const { t } = useTranslation('user');

  return (
    <Box
      sx={theme => ({
        textAlign: 'left',
        li: {
          ':before': {
            content: "'•'",
            marginRight: theme.other.spacing(0.5),
          },
        },
      })}
      component="ul"
    >
      <li>{t('user:emailNotSendTips.checkSpam')}</li>
      <li>{t('user:emailNotSendTips.checkEmail')}</li>
      <li>{t('user:emailNotSendTips.wait')}</li>
    </Box>
  );
};
