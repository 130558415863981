import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mantine/core';

import { FileTypeWithoutExtensionIcon } from 'icons';
import { TemplateType } from 'types';

const ATTACHMENTS_INTERVAL = 3;

type Props = {
  attachmentTemplates: TemplateType['attachmentTemplates'];
};

export const AttachmentTemplates = ({ attachmentTemplates }: Props) => {
  const { t } = useTranslation('common');

  const [attachmentNumberToShow, setAttachmentNumberToShow] =
    useState(ATTACHMENTS_INTERVAL);

  return (
    <div>
      <Box
        sx={theme => ({
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.other.spacing(1.5),
        })}
        component="ul"
      >
        {attachmentTemplates
          .slice(0, attachmentNumberToShow)
          .map(({ type }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              key={type}
              component="li"
            >
              <FileTypeWithoutExtensionIcon
                sx={theme => ({
                  marginRight: theme.other.spacing(0.5),
                })}
              />
              {type}
            </Box>
          ))}
      </Box>
      {attachmentTemplates.length > attachmentNumberToShow && (
        <Button
          sx={theme => ({ marginTop: theme.other.spacing(0.5), padding: 0 })}
          variant="subtle"
          onClick={() => {
            setAttachmentNumberToShow(
              numberToShow => numberToShow + ATTACHMENTS_INTERVAL,
            );
          }}
        >
          {t('common:showMore')}
        </Button>
      )}
    </div>
  );
};
