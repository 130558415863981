import { IMAGE_SQUARE_SIZE } from '../../consts';
import { ZOOM_LIMIT, ZOOM_RATIO } from '../consts';

const ratioMultiplier = 1 + ZOOM_RATIO;

type CanvasData = {
  canvasDataWidth: number;
  canvasDataNaturalWidth: number;
  canvasDataHeight: number;
};

const getZoomMaxAvailableValue = ({
  canvasDataWidth,
  canvasDataNaturalWidth,
  canvasDataHeight,
}: CanvasData) => {
  let maxZoomValue = canvasDataWidth / canvasDataNaturalWidth;
  let zoomStep = 0;

  while (
    !(
      canvasDataWidth / ratioMultiplier ** zoomStep <= IMAGE_SQUARE_SIZE ||
      canvasDataHeight / ratioMultiplier ** zoomStep <= IMAGE_SQUARE_SIZE
    )
  ) {
    maxZoomValue =
      canvasDataWidth /
      ratioMultiplier ** zoomStep /
      ratioMultiplier /
      canvasDataNaturalWidth;
    zoomStep += 1;
  }

  return maxZoomValue;
};

export const getZoomRange = ({
  canvasDataWidth,
  canvasDataNaturalWidth,
  canvasDataHeight,
}: CanvasData) => {
  const maxZoomAvailableValue = getZoomMaxAvailableValue({
    canvasDataWidth,
    canvasDataNaturalWidth,
    canvasDataHeight,
  });
  const range: number[] = [];

  for (let i = 0; i < ZOOM_LIMIT; i += 1) {
    range.push(maxZoomAvailableValue * ratioMultiplier ** i);
  }

  return range;
};
