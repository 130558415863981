import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetNotificationsResponseType,
  GetNotificationsRequestType,
} from '../types';

export const getNotifications = async ({
  queryParams: {
    category,
    isRead,
    page,
    perPage,
    priority,
    sortBy,
    sortDir,
    type,
  },
}: GetNotificationsRequestType) => {
  const query = stringifyUrlQuery({
    category,
    isRead,
    page,
    perPage,
    priority,
    sortBy,
    sortDir,
    type,
  });

  const url = `/api/v2/notifications${query}`;

  const { data } = await apiAuthClient.get<GetNotificationsResponseType>(url);

  return data;
};
