import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@mantine/hooks';

import { setRefreshToken } from 'helpers';
import { useShowNotification, useRedirectToV1IfNeeded } from 'hooks';
import { STORAGE_KEYS } from 'consts';

export const useSuccessLogin = () => {
  const { t } = useTranslation('user');
  const [, setToken] = useLocalStorage({
    key: STORAGE_KEYS.token,
    serialize: value => value,
    getInitialValueInEffect: false,
  });

  const showNotification = useShowNotification();
  const redirectToV1IfNeeded = useRedirectToV1IfNeeded();

  return (accessToken: string) => {
    setToken(accessToken);
    setRefreshToken(accessToken);
    showNotification({
      message: t('user:signedInSuccessfully'),
    });
    redirectToV1IfNeeded();
  };
};
