import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ActionIcon, Box, Textarea, UnstyledButton } from '@mantine/core';

import { DeleteIcon } from 'icons';
import {
  AddButtonSubtle,
  DescriptionField,
  TextInput,
} from 'components/shared';

export const Options = ({
  options,
  optionsFieldArray: { fields, append, remove, replace },
  register,
  error,
  clearOptionsError,
}) => {
  const { t } = useTranslation('templates');

  const [isTextareaModeEnabled, setIsTextareaModeEnabled] = useState(false);

  return (
    <DescriptionField
      label={t('templates:templateCreatorVariables.options')}
      description={
        <Trans
          i18nKey={
            isTextareaModeEnabled
              ? 'templates:templateCreatorVariables.optionsSwitchToAddingOptions'
              : 'templates:templateCreatorVariables.optionsSwitchToTextarea'
          }
          components={{
            button: (
              <UnstyledButton
                sx={theme => ({
                  fontWeight: 500,
                  color: theme.colors[theme.primaryColor][theme.primaryShade],
                })}
                onClick={() => {
                  setIsTextareaModeEnabled(enabled => !enabled);
                }}
              />
            ),
          }}
        />
      }
      error={error}
    >
      {isTextareaModeEnabled ? (
        <Textarea
          placeholder={t('templates:templateCreatorVariables.options')}
          minRows={5}
          value={options.reduce(
            (accumulator, { value }, index) =>
              `${accumulator}${value}${
                index === fields.length - 1 ? '' : '\n'
              }`,
            '',
          )}
          onChange={e => {
            clearOptionsError();
            replace(
              e.target.value.split('\n').map(option => ({
                value: option,
              })),
            );
          }}
        />
      ) : (
        <>
          <Box sx={{ maxHeight: 400, overflowY: 'auto' }} component="ul">
            {fields.map(({ id, value }, index) => {
              const inputRegisteredProps = {
                ...register(`options.${index}.value`),
              };

              return (
                <Box
                  sx={theme => ({
                    display: 'flex',
                    marginBottom: theme.other.spacing(2),
                    columnGap: theme.other.spacing(1),
                  })}
                  component="li"
                  key={id}
                >
                  <TextInput
                    sx={{ width: '100%' }}
                    {...inputRegisteredProps}
                    rightIcon={
                      fields.length > 1 ? (
                        <ActionIcon
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <DeleteIcon color="red" />
                        </ActionIcon>
                      ) : undefined
                    }
                    placeholder={`${t(
                      'templates:templateCreatorVariables.option',
                    )} ${index + 1}`}
                    withErrorWrapperAlwaysEnabled={false}
                    onChange={e => {
                      clearOptionsError();
                      inputRegisteredProps.onChange(e);
                    }}
                  />
                </Box>
              );
            })}
          </Box>
          <AddButtonSubtle
            sx={theme => ({
              fontSize: theme.fontSizes.lg,
              paddingRight: theme.other.spacing(1),
              paddingLeft: theme.other.spacing(1),
            })}
            onClick={() => {
              append({ value: '' });
            }}
          >
            {t('templates:templateCreatorVariables.addNext')}
          </AddButtonSubtle>
        </>
      )}
    </DescriptionField>
  );
};
