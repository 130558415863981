import { useEffect, useState } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Box } from '@mantine/core';

import { useGetExternalLetterheadFile } from 'api/organisations';
import { getAreObjectsEqualByJsonRepresentation } from 'helpers';
import { OrganisationBrandingConfigType } from 'types';
import { NotSavedChangesModal, PdfPreview } from 'components/shared';

import { MarginType } from './types';
import { DocumentMarginForm } from './DocumentMarginForm';
import { SamplePdfDocument } from './SamplePdfDocument';

import { getDocumentWithLetterhead, getPdfMarginInPx } from './helpers';

type Props = {
  headedPaperUrl: OrganisationBrandingConfigType['headedPaperUrl'];
  margin: MarginType;
  isDeletingLetterhead: boolean;
  isUpdatingBrandingConfig: boolean;
  urlToRedirectAfterCancel: string;
  deleteLetterhead: () => void;
  updateBrandingConfig: (margin: MarginType) => void;
};

export const BrandingPreview = ({
  headedPaperUrl,
  margin,
  isDeletingLetterhead,
  isUpdatingBrandingConfig,
  urlToRedirectAfterCancel,
  deleteLetterhead,
  updateBrandingConfig,
}: Props) => {
  const [updatedMargin, setUpdatedMargin] = useState(margin);

  const [documentWithLetterheadObjectUrl, setDocumentWithLetterheadObjectUrl] =
    useState<string | null>(null);

  const [
    {
      blob: samplePdfDocumentBlob,
      loading: isSamplePdfLoading,
      error: samplePdfError,
    },
    updatePdf,
  ] = usePDF({
    document: <SamplePdfDocument padding={getPdfMarginInPx(margin)} />,
  });

  const {
    data: letterheadPdfArrayBuffer,
    isLoading: isLetterheadPdfArrayBufferLoading,
    isError: isLetterheadPdfArrayBufferError,
  } = useGetExternalLetterheadFile(
    { url: headedPaperUrl || '' },
    {
      enabled: Boolean(headedPaperUrl),
    },
  );

  const handleUpdateMargin = (newMargin: MarginType) => {
    setUpdatedMargin(newMargin);
    updatePdf(<SamplePdfDocument padding={getPdfMarginInPx(newMargin)} />);
  };

  const isPreviewLoading =
    isSamplePdfLoading || isLetterheadPdfArrayBufferLoading;

  useEffect(() => {
    if (letterheadPdfArrayBuffer && samplePdfDocumentBlob) {
      (async () => {
        const documentWithLetterheadUint8Array =
          await getDocumentWithLetterhead({
            samplePdfDocumentBlob,
            letterheadPdfArrayBuffer,
          });

        setDocumentWithLetterheadObjectUrl(
          URL.createObjectURL(new Blob([documentWithLetterheadUint8Array])),
        );
      })();
    }
  }, [letterheadPdfArrayBuffer, samplePdfDocumentBlob]);

  useEffect(() => {
    handleUpdateMargin(margin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [margin]);

  useEffect(
    () => () => {
      if (documentWithLetterheadObjectUrl) {
        URL.revokeObjectURL(documentWithLetterheadObjectUrl);
      }
    },
    [documentWithLetterheadObjectUrl],
  );

  return (
    <>
      <Box
        sx={theme => ({
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.other.spacing(3),
        })}
      >
        <PdfPreview
          file={documentWithLetterheadObjectUrl}
          isError={Boolean(samplePdfError) || isLetterheadPdfArrayBufferError}
          isLoading={isPreviewLoading}
        />
        <DocumentMarginForm
          margin={updatedMargin}
          isDeletingLetterhead={isDeletingLetterhead}
          isUpdatingBrandingConfig={isUpdatingBrandingConfig}
          urlToRedirectAfterCancel={urlToRedirectAfterCancel}
          refreshPreview={newMargin => {
            if (
              !isPreviewLoading &&
              !getAreObjectsEqualByJsonRepresentation(newMargin, updatedMargin)
            ) {
              handleUpdateMargin(newMargin);
            }
          }}
          deleteLetterhead={deleteLetterhead}
          update={newMargin => {
            if (!getAreObjectsEqualByJsonRepresentation(margin, newMargin)) {
              updateBrandingConfig(newMargin);
            }
          }}
        />
      </Box>
      <NotSavedChangesModal
        hasUnsavedChanges={
          !getAreObjectsEqualByJsonRepresentation(margin, updatedMargin)
        }
      />
    </>
  );
};
