import { ReactNode } from 'react';
import {
  QueryClient,
  QueryClientProvider as QueryClientProviderReactQuery,
} from '@tanstack/react-query';

type Props = {
  children: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

export const QueryClientProvider = ({ children }: Props) => (
  <QueryClientProviderReactQuery client={queryClient}>
    {children}
  </QueryClientProviderReactQuery>
);
