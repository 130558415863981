import { PDFDocument } from 'pdf-lib';

export const getDocumentWithLetterhead = async ({
  samplePdfDocumentBlob,
  letterheadPdfArrayBuffer,
}: {
  samplePdfDocumentBlob: Blob;
  letterheadPdfArrayBuffer: ArrayBuffer;
}) => {
  const samplePdfArrayBuffer = await samplePdfDocumentBlob.arrayBuffer();

  const [samplePdfDocument, newDocument] = await Promise.all([
    PDFDocument.load(samplePdfArrayBuffer),
    PDFDocument.create(),
  ]);

  const [sampleDocumentPage] = samplePdfDocument.getPages();
  const [[layoutEmbeddedPage], [embeddedPage]] = await Promise.all([
    newDocument.embedPdf(letterheadPdfArrayBuffer),
    newDocument.embedPdf(samplePdfArrayBuffer, [0]),
  ]);

  const { width, height } = sampleDocumentPage.getSize();
  const newPage = newDocument.addPage([width, height]);

  newPage.drawPage(layoutEmbeddedPage, {
    width,
    height,
  });

  newPage.drawPage(embeddedPage, {
    width,
    height,
  });

  return newDocument.save();
};
