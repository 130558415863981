import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  DefaultProps,
  packSx,
  Text,
  UnstyledButton,
} from '@mantine/core';

import { ErrorTwoToneIcon } from 'icons';
import { Modal } from 'components/shared';
import { TransactionNotificationAccordionItem } from 'components/Transactions/TransactionNotificationAccordionItem';

type Props = {
  errors: { [key: string]: string[] };

  sx?: DefaultProps['sx'];
};

export const Errors = ({ errors, sx }: Props) => {
  const { t } = useTranslation('transactions');

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <UnstyledButton
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
          },
          ...packSx(sx),
        ]}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <ErrorTwoToneIcon
          sx={theme => ({ marginRight: theme.other.spacing(1) })}
          color="red"
        />
        <Text>
          {t('transactions:errors')} ({Object.keys(errors).length})
        </Text>
      </UnstyledButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('transactions:errorsModalTitle')}
        defaultButtonAction={onClose}
        defaultButtonText={t('common:back')}
      >
        <Accordion multiple defaultValue={Object.keys(errors)}>
          {Object.entries(errors).map(([errorName, errorValues]) => (
            <TransactionNotificationAccordionItem
              key={errorName}
              value={errorName}
              title={errorName}
              iconType="error"
              items={errorValues}
            />
          ))}
        </Accordion>
      </Modal>
    </>
  );
};
