import { ThemeComponentType } from '../types';

export const Button: ThemeComponentType = {
  defaultProps: {
    size: 'sm',
  },
  styles: (theme, { color, variant }) => {
    const getVariantRootStyles = () => {
      switch (variant) {
        case 'default':
          return {
            backgroundColor: theme.white,
            color: theme.black,
            border: `1px solid ${theme.colors.gray[1]}`,
            '&:hover,&:focus': {
              boxShadow: theme.shadows.xs,
            },
            '&:hover': {
              backgroundColor: theme.white,
            },
          };
        case 'filled':
          return {
            color: theme.colors[theme.primaryColor][1],
            '&:hover,&:focus': {
              boxShadow: theme.shadows.xs,
            },
            '&:hover': {
              backgroundColor: theme.fn.themeColor(color, 6),
            },
          };
        case 'light':
          return {
            backgroundColor: theme.fn.themeColor(color, 1),
            fontWeight: 600,
            '&:hover,&:focus': {
              boxShadow: theme.shadows.xs,
            },
            '&:hover': {
              backgroundColor: theme.fn.themeColor(color, 1),
            },
          };
        case 'subtle':
          return {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          };
        default:
          return {};
      }
    };

    return {
      root: {
        paddingRight: theme.other.spacing(2),
        paddingLeft: theme.other.spacing(2),
        fontWeight: 500,
        lineHeight: 'normal',
        '&:disabled': {
          color: theme.colors.gray[3],
          backgroundColor: theme.colors.gray[1],
        },
        ...getVariantRootStyles(),
      },
      leftIcon: {
        marginRight: theme.other.spacing(1),
        marginLeft: theme.other.spacing(-1),
      },
      rightIcon: {
        marginLeft: theme.other.spacing(1),
        marginRight: theme.other.spacing(-1),
      },
    };
  },
};
