import { useMutation } from '@tanstack/react-query';
import { useGetCurrentOrganisationId } from 'hooks';
import { patchWebhook } from '../requests';
import { PatchWebhookRequestType } from '../types';

export const usePatchWebhook = ({
  options,
}: {
  options?: {
    onSuccess?: () => void;
  };
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      pathParams: { webhookId },
      bodyParams,
    }: {
      pathParams: Omit<PatchWebhookRequestType['pathParams'], 'organisationId'>;
      bodyParams: PatchWebhookRequestType['bodyParams'];
    }) =>
      patchWebhook({
        pathParams: {
          organisationId,
          webhookId,
        },
        bodyParams,
      }),
    ...options,
  });
};
