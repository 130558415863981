import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTransaction } from '../requests';
import { GetTransactionRequestType } from '../types';

export const useGetTransaction = (
  {
    pathParams: { dataStreamId, transactionId },
  }: {
    pathParams: Omit<GetTransactionRequestType['pathParams'], 'organisationId'>;
  },
  options?: {
    enabled: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.transaction,
      { organisationId, dataStreamId, transactionId },
    ],
    queryFn: () =>
      getTransaction({
        pathParams: { organisationId, dataStreamId, transactionId },
      }),
    ...options,
  });
};
