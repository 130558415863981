import { MantineThemeOverride } from '@mantine/core';

import 'react-phone-input-2/lib/style.css';

import {
  activeStyles,
  black,
  colors,
  colorScheme,
  fontFamily,
  fontSizes,
  headings,
  lineHeight,
  primaryColor,
  primaryShade,
  shadows,
  spacing,
  white,
} from './consts';
import { components } from './components';
import { other } from './other';

export const theme: MantineThemeOverride = {
  activeStyles,
  black,
  colors,
  colorScheme,
  components,
  fontFamily,
  fontSizes,
  headings,
  lineHeight,
  other,
  primaryColor,
  primaryShade,
  shadows,
  spacing,
  white,
};
