import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { useGetSsoAuth } from 'api/singleSignOn';
import { setRefreshToken } from 'helpers';
import { useShowGeneralErrorNotification, useShowNotification } from 'hooks';
import { HTTP_HEADERS, SSO_LOGIN_METHODS, STORAGE_KEYS, URLS } from 'consts';

type Props = {
  method: keyof typeof SSO_LOGIN_METHODS;
};

export const Oauth = ({ method }: Props) => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const [, setToken] = useLocalStorage({
    key: STORAGE_KEYS.token,
    serialize: value => value,
    getInitialValueInEffect: false,
  });

  const showNotification = useShowNotification();

  const searchParams = new URLSearchParams(window.location.search);

  const codeSearchParam = searchParams.get('code') || '';
  const stateSearchParam = searchParams.get('state') || '';

  const hasRequiredSearchParams = Boolean(codeSearchParam && stateSearchParam);

  const showGeneralErrorNotification = useShowGeneralErrorNotification();

  useGetSsoAuth(
    {
      pathParams: {
        ssoDriver: method,
      },
      queryParams: {
        code: codeSearchParam,
        state: stateSearchParam,
      },
    },
    {
      enabled: hasRequiredSearchParams,
      onError: () => {
        navigate(URLS.home, {
          replace: true,
        });
        showGeneralErrorNotification();
      },
      onSuccess: ({ data: { accessToken }, headers }) => {
        setToken(accessToken);
        setRefreshToken(accessToken);
        showNotification({
          message: t('user:signedInSuccessfully'),
        });

        window.location.href = decodeURIComponent(
          headers[HTTP_HEADERS.xRedirectTo] as string,
        );
      },
    },
  );

  return hasRequiredSearchParams ? (
    <LoadingOverlay visible />
  ) : (
    <Navigate to={URLS.home} replace />
  );
};
