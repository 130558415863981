import { useLocalStorage } from '@mantine/hooks';

export const useLocalDocumentsCount = () => {
  const [localContractsCount] = useLocalStorage({
    key: 'persist:contracts',
    defaultValue: 0,
    deserialize: localStorageValue => {
      const parsedValue = JSON.parse(localStorageValue);

      return JSON.parse(parsedValue?.pendingContracts ?? `[]`).length;
    },
  });

  return localContractsCount;
};
