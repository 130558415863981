import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { usePatchUserNotifications } from 'api/account';
import { useServerErrors } from 'hooks';
import { NOTIFICATION_TYPES } from 'consts';
import { MappedUserNotificationListItemType } from 'types';
import { Modal } from 'components/shared';

import { NotificationForm } from './NotificationsForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notifications: MappedUserNotificationListItemType;
};

export const NotificationModal = ({
  isOpen,
  onClose,
  notifications,
}: Props) => {
  const { t } = useTranslation(['common', 'notifications']);

  const { addServerErrorActions } = useServerErrors();

  const formRef = useRef<HTMLFormElement>(null);

  const {
    mutateAsync: updateUserNotifications,
    isLoading: isUpdatingUserNotifications,
  } = usePatchUserNotifications();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('notifications:notificationsManagement')}
      defaultButtonDisabled={isUpdatingUserNotifications}
      defaultButtonAction={onClose}
      primaryButtonIsLoading={isUpdatingUserNotifications}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      <NotificationForm
        formRef={formRef}
        onSubmit={async values => {
          try {
            const notificationsToSend = Object.entries(values).reduce(
              (accumulator, [type, { mail, push }]) => {
                const unmappedType =
                  NOTIFICATION_TYPES[type as keyof typeof NOTIFICATION_TYPES];

                return [
                  ...accumulator,
                  {
                    type: unmappedType,
                    channel: 'mail' as const,
                    enable: mail,
                  },
                  {
                    type: unmappedType,
                    channel: 'push' as const,
                    enable: push,
                  },
                ];
              },
              [] as {
                channel: 'mail' | 'push';
                enable: boolean;
                type: number;
              }[],
            );

            await updateUserNotifications({
              notifications: notificationsToSend,
            });

            onClose();
          } catch (error) {
            addServerErrorActions(error, {
              422: {
                message: t('common:errorTryAgain'),
              },
            });
          }
        }}
        notifications={notifications}
      />
    </Modal>
  );
};
