import { NOTIFICATION_TABS } from '../consts';

export const getNotificationsCategoryByActiveTab = (
  activeTab: (typeof NOTIFICATION_TABS)[keyof typeof NOTIFICATION_TABS],
): 'document' | 'organisation' | undefined => {
  switch (activeTab) {
    case NOTIFICATION_TABS.documents:
      return 'document';
    case NOTIFICATION_TABS.organisationsAndBoards:
      return 'organisation';
    default:
      return undefined;
  }
};
