import { useState } from 'react';
import { Box } from '@mantine/core';

import { Providers } from './Providers';
import { TopSection } from './TopSection';
import { BottomSection } from './BottomSection';
import { EditorArea } from './EditorArea';
import { Errors } from './Errors';

export const Root = ({ editor, errors, textEditor }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Providers editor={editor} errors={errors}>
      <Box
        sx={theme => ({
          maxWidth: 1146,
          fontSize: theme.fontSizes.lg,
        })}
      >
        <Box
          sx={theme => ({
            position: 'sticky',
            top: theme.other.spacing(-2),
            zIndex: 2,
            backgroundColor: theme.colors.blue[1],
            border: `1px solid ${theme.colors.blue[2]}`,
          })}
        >
          <TopSection />
          <BottomSection
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </Box>
        <Errors />
        <EditorArea textEditor={textEditor} isSidebarOpen={isSidebarOpen} />
      </Box>
    </Providers>
  );
};
