import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const SignatureSmsIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M10.286 16.571h3.428M7.43 20h9.142c.632 0 1.143-.512 1.143-1.143V5.143c0-.631-.511-1.143-1.143-1.143H7.43c-.632 0-1.143.512-1.143 1.143v13.714c0 .631.511 1.143 1.143 1.143Z"
      />
    </Box>
  );
};
