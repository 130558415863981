import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetDataStreamsResponseType,
  GetDataStreamsRequestType,
} from '../types';

export const getDataStreams = async ({
  pathParams: { organisationId },
  queryParams: { dataType, name, page, perPage, sortDir },
}: GetDataStreamsRequestType) => {
  const query = stringifyUrlQuery({
    dataType,
    name,
    page,
    perPage,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/data-streams${query}`;

  const { data } = await apiAuthClient.get<GetDataStreamsResponseType>(url);

  return data;
};
