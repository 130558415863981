import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getAuthOtp } from '../requests';
import { GetAuthOtpRequestType } from '../types';

export const useGetAuthOtp = (
  { pathParams: { invitationId } }: GetAuthOtpRequestType,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.invitationAuthOtp, { invitationId }],
    queryFn: () => getAuthOtp({ pathParams: { invitationId } }),
    ...options,
  });
