import { Dispatch, SetStateAction } from 'react';
import { ActionIcon, Box, Slider } from '@mantine/core';

import { ZoomInIcon, ZoomOutIcon } from 'icons';
import { FieldErrorMessage } from 'components/shared';

import { ZOOM_LIMIT } from '../consts';

type Props = {
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  avatarError: string;
};

export const ZoomControl = ({ zoom, setZoom, avatarError }: Props) => (
  <div>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <ActionIcon
        onClick={() => {
          setZoom(z => (z > 1 ? z - 1 : 1));
        }}
      >
        <ZoomOutIcon />
      </ActionIcon>
      <Slider
        sx={{
          width: 320,
        }}
        step={1}
        min={1}
        max={ZOOM_LIMIT}
        label={null}
        radius="xs"
        thumbSize={20}
        value={zoom}
        onChange={step => {
          setZoom(step);
        }}
      />
      <ActionIcon
        onClick={() => {
          setZoom(z => (z < ZOOM_LIMIT ? z + 1 : ZOOM_LIMIT));
        }}
      >
        <ZoomInIcon />
      </ActionIcon>
    </Box>
    {avatarError && <FieldErrorMessage>{avatarError}</FieldErrorMessage>}
  </div>
);
