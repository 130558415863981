import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';

import { useGetUser, usePatchUser, usePostUserOtpUpdate } from 'api/account';
import { setHookFormServerErrors } from 'helpers';
import { useGetOtpCodeValidationError, useServerErrors } from 'hooks';
import { AuthOtpType, UserType } from 'types';
import { Modal, OtpFlow } from 'components/shared';

import { PersonalInformationForm } from './PersonalInformationForm';
import { FormValuesType } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditPersonalInformationModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const { addServerErrorActions, getServerErrorStatus, getServerFieldError } =
    useServerErrors();
  const getOtpCodeValidationError = useGetOtpCodeValidationError();

  const [otpCode, setOtpCode] = useState('');
  const [otpCodeError, setOtpCodeError] = useState('');
  const [otpData, setOtpData] = useState<AuthOtpType | null>(null);

  const { data: userData } = useGetUser();
  const user = userData as UserType;
  const { mutateAsync: updateUserData, isLoading: isUpdatingUserData } =
    usePatchUser();
  const {
    mutateAsync: getOtpData,
    isLoading: isGettingOtpData,
    variables: otpGetDataRequestVariables,
  } = usePostUserOtpUpdate();

  const formRef = useRef<HTMLFormElement>(null);
  const formInstanceRef = useRef<{
    setFormError: UseFormSetError<{}>;
  }>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleServerErrors = (error: unknown) => {
    setHookFormServerErrors(
      getServerErrorStatus(error),
      formInstanceRef.current!.setFormError,
    );
    setOtpCodeError(getServerFieldError(error, 'code'));
    addServerErrorActions(error);
  };

  const handleGetOtpCode = async (email: FormValuesType['email']) => {
    try {
      const data = await getOtpData({
        email,
        setupPassword: false,
      });

      setOtpData(data);
    } catch (error) {
      handleServerErrors(error);
    }
  };

  const handleUpdateUserData = async ({
    name,
    surname,
    phone,
    email,
  }: {
    name: FormValuesType['name'];
    surname: FormValuesType['surname'];
    phone: FormValuesType['phone'];
    email?: FormValuesType['email'];
  }) => {
    try {
      await updateUserData({
        email,
        name,
        surname,
        phone,
        ...(otpData && { otpId: otpData.id, code: otpCode }),
      });

      handleClose();
    } catch (error) {
      handleServerErrors(error);
    }
  };

  const resetState = () => {
    setOtpCode('');
    setOtpCodeError('');
    setOtpData(null);
  };

  useEffect(() => {
    if (otpData && otpCodeError) {
      codeInputRef.current?.focus();
    }
  }, [otpData, otpCodeError]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      title={t('user:personalDataEdit')}
      defaultButtonDisabled={isUpdatingUserData}
      defaultButtonAction={handleClose}
      primaryButtonIsLoading={isUpdatingUserData || isGettingOtpData}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      {otpData && (
        <OtpFlow
          codeNumber={otpData.order}
          validUntil={otpData.validUntil}
          isGettingOtpData={isGettingOtpData}
          resendCode={() => {
            handleGetOtpCode(otpGetDataRequestVariables!.email);
          }}
          textInputProps={{
            ref: codeInputRef,
            error: otpCodeError,
            value: otpCode,
            onChange: e => {
              setOtpCodeError('');
              setOtpCode(e.target.value);
            },
          }}
        />
      )}
      <PersonalInformationForm
        sx={{ display: otpData ? 'none' : undefined }}
        formRef={formRef}
        formInstanceRef={formInstanceRef}
        defaultValues={{
          email: user.email,
          name: user.name,
          surname: user.surname,
          phone: user.phone || '',
        }}
        onSubmit={({ email, name, surname, phone }) => {
          if (email !== user.email) {
            if (otpData) {
              const otpCodeValidationError = getOtpCodeValidationError(otpCode);

              if (otpCodeValidationError) {
                setOtpCodeError(otpCodeValidationError);
              } else {
                handleUpdateUserData({
                  email,
                  name,
                  surname,
                  phone,
                });
              }
            } else {
              handleGetOtpCode(email);
            }
          } else {
            handleUpdateUserData({ name, surname, phone });
          }
        }}
      />
    </Modal>
  );
};
