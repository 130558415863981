import { ThemeComponentType } from '../types';

export const Navbar: ThemeComponentType = {
  styles: {
    root: {
      height: '100%',
      borderRightWidth: 0,
    },
  },
};
