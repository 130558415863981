import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs, Document, Page } from 'react-pdf';
import { File } from 'react-pdf/src/shared/types';
import { Box, DefaultProps, Loader, packSx } from '@mantine/core';

import { Error, NotFound } from 'components/shared';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDF_WIDTH = 500;
const PDF_HEIGHT = 707;

type Props = {
  file: File;
  isError: boolean;
  isLoading: boolean;
  sx?: DefaultProps['sx'];
};

export const PdfPreview = ({ file, isError, isLoading, sx }: Props) => {
  const { t } = useTranslation('common');

  const [isPasswordProtected, setIsPasswordProtected] = useState(false);

  const getContent = () => {
    if (isError) {
      return <Error />;
    }

    if (isLoading || file === null) {
      return <Loader />;
    }

    return (
      <Document
        file={file}
        error={<Error />}
        loading={
          isPasswordProtected ? (
            <Error
              description={t('common:formErrors.fileIsEncrypted')}
              withPageReload={false}
            />
          ) : (
            <Loader />
          )
        }
        noData={<NotFound />}
        onPassword={() => {
          setIsPasswordProtected(true);
        }}
      >
        <Page
          width={PDF_WIDTH}
          height={PDF_HEIGHT}
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    );
  };

  return (
    <Box sx={[{ overflow: 'auto' }, ...packSx(sx)]}>
      <Box
        sx={theme => ({
          width: PDF_WIDTH,
          height: PDF_HEIGHT,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.white,
        })}
      >
        {getContent()}
      </Box>
    </Box>
  );
};
