import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { patchDataType } from '../requests';
import { PatchDataTypeRequestType } from '../types';

export const usePatchDataType = ({
  pathParams: { dataTypeName },
}: {
  pathParams: Omit<PatchDataTypeRequestType['pathParams'], 'organisationId'>;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (bodyParams: PatchDataTypeRequestType['bodyParams']) =>
      patchDataType({
        pathParams: { organisationId, dataTypeName },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.dataTypes],
      });
    },
  });
};
