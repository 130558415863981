import { useInfiniteQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getOrganisationUsers } from '../requests';
import {
  GetOrganisationUsersRequestType,
  GetOrganisationUsersResponseType,
} from '../types';

export const useGetCurrentOrganisationUsersInfinite = (
  {
    queryParams: { email, isAdmin, isDeleted, nameAndSurname, page, perPage },
  }: Omit<GetOrganisationUsersRequestType, 'pathParams'>,
  options?: {
    keepPreviousData?: boolean;
    getNextPageParam?: (
      data: GetOrganisationUsersResponseType,
    ) => string | undefined;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useInfiniteQuery({
    queryKey: [
      API_QUERY_KEYS.organisationUsers,
      {
        organisationId: Number(organisationId),
        email,
        isAdmin,
        isDeleted,
        nameAndSurname,
        page,
        perPage,
      },
    ],
    queryFn: ({ pageParam }) =>
      getOrganisationUsers({
        pathParams: { organisationId },
        queryParams: {
          email,
          isAdmin,
          isDeleted,
          nameAndSurname,
          page: pageParam,
          perPage,
        },
      }),
    ...options,
  });
};
