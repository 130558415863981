import { ComponentProps, useState } from 'react';
import { DefaultProps, packSx, Popover, Select } from '@mantine/core';

import { List } from './List';
import { SharedPropsType } from './types';

type Props = {
  selectProps: ComponentProps<typeof Select>;
  sx?: DefaultProps['sx'];
} & SharedPropsType;

export const SelectFromDataList = ({
  selectProps,
  searchInputProps,
  isError,
  isLoading,
  selectedId,
  itemsData,
  onChange,
  fetchNextPage,
  notFoundProps,
  sx,
  uniqueKey,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      opened={isOpen}
      onChange={setIsOpen}
      width={370}
      position="bottom-start"
    >
      <Popover.Target>
        <Select
          sx={[
            isOpen
              ? {
                  '>[role="combobox"]': {
                    '&[aria-expanded="false"]': {
                      '.rotate': {
                        transform: 'rotate(180deg)',
                      },
                    },
                  },
                }
              : undefined,
            ...packSx(sx),
          ]}
          readOnly
          onClick={() => {
            setIsOpen(open => !open);
          }}
          onKeyUp={e => {
            if (e.key === ' ') {
              setIsOpen(open => !open);
            }
          }}
          {...selectProps}
        />
      </Popover.Target>
      <Popover.Dropdown
        sx={theme => ({ padding: theme.other.spacing(2, 0, 0.5) })}
      >
        <List
          isError={isError}
          isLoading={isLoading}
          selectedId={selectedId}
          itemsData={itemsData}
          onChange={item => {
            onChange(item);
            setIsOpen(false);
          }}
          fetchNextPage={fetchNextPage}
          searchInputProps={searchInputProps}
          notFoundProps={notFoundProps}
          uniqueKey={uniqueKey}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
