import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormSetError } from 'react-hook-form';
import { Box, Radio, Text, UnstyledButton } from '@mantine/core';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { format } from 'date-fns';

import { useGetUser } from 'api/account';
import { PatchUserRequestType } from 'api/account/types';
import { ACCOUNT_DATE_FORMATS, LANGUAGE_LIST } from 'consts';
import { UserType } from 'types';
import { FieldErrorMessage } from 'components/shared';

import { useGetZodSchema } from './hooks';

const TWELVE_HOUR_FORMATS = [
  ACCOUNT_DATE_FORMATS.variant1,
  ACCOUNT_DATE_FORMATS.variant2,
  ACCOUNT_DATE_FORMATS.variant3,
] as const;
const TWENTY_FOUR_HOUR_FORMATS = [
  ACCOUNT_DATE_FORMATS.variant4,
  ACCOUNT_DATE_FORMATS.variant5,
  ACCOUNT_DATE_FORMATS.variant6,
] as const;

type LocaleType = NonNullable<PatchUserRequestType['bodyParams']['locale']>;
type DateFormatType = NonNullable<
  PatchUserRequestType['bodyParams']['dateFormat']
>;

type Props = {
  formRef: RefObject<HTMLFormElement>;
  onSubmit: (
    formValues: {
      locale: LocaleType;
      dateFormat: DateFormatType;
    },
    setFormError: UseFormSetError<{}>,
  ) => void;
};

export const AppSettingsForm = ({ formRef, onSubmit }: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const { data: userData } = useGetUser();
  const user = userData as UserType;

  const [isTwelveHourFormatChosen, setIsTwelveHourFormatChosen] = useState(
    TWELVE_HOUR_FORMATS.includes(
      user.dateFormat as (typeof TWELVE_HOUR_FORMATS)[number],
    ),
  );

  const schema = useGetZodSchema();

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      locale: user.locale,
      dateFormat: user.dateFormat,
    },
  });

  const dateFormatsToShow = isTwelveHourFormatChosen
    ? TWELVE_HOUR_FORMATS
    : TWENTY_FOUR_HOUR_FORMATS;

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(values => onSubmit(values, setError))}
    >
      <div>
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          weight={600}
        >
          {t('user:appLanguage')}
        </Text>
        <div>
          {LANGUAGE_LIST.map(({ value, labelTranslation }) => (
            <Radio
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              key={value}
              {...register('locale')}
              label={t(labelTranslation)}
              value={value}
            />
          ))}
          <FieldErrorMessage>{formErrors.locale?.message}</FieldErrorMessage>
        </div>
      </div>
      <div>
        <Box
          sx={theme => ({
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.other.spacing(2),
          })}
        >
          <Text weight={600}>{t('user:dateFormat')}</Text>
          <UnstyledButton
            sx={theme => ({
              fontSize: theme.fontSizes.lg,
              fontWeight: 500,
              color: theme.fn.primaryColor(),
            })}
            onClick={() => {
              setIsTwelveHourFormatChosen(
                twelveHoursFormatChosen => !twelveHoursFormatChosen,
              );
            }}
          >
            {isTwelveHourFormatChosen
              ? t('user:changeTo12HourFormat')
              : t('user:changeTo24HourFormat')}
          </UnstyledButton>
        </Box>
        <div>
          {dateFormatsToShow.map(dateFormat => (
            <Radio
              sx={theme => ({
                marginBottom: theme.other.spacing(2),
              })}
              key={dateFormat}
              {...register('dateFormat')}
              label={format(
                new Date(`${new Date().getFullYear()}-01-31T15:00:00`),
                dateFormat,
              )}
              value={dateFormat}
            />
          ))}
          <FieldErrorMessage>
            {formErrors.dateFormat?.message}
          </FieldErrorMessage>
        </div>
      </div>
    </form>
  );
};
