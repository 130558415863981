import { Box } from '@mantine/core';

import { SectionDivider } from '../SectionDivider';
import { InsertSection } from './InsertSection';
import { PrimarySection } from './PrimarySection';
import { SecondarySection } from './SecondarySection';

export const BottomSection = ({ isSidebarOpen, setIsSidebarOpen }) => (
  <Box
    sx={theme => ({
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      gap: theme.other.spacing(2),
      padding: theme.other.spacing(1, 2),
    })}
  >
    <InsertSection />
    <SectionDivider />
    <PrimarySection />
    <SectionDivider />
    <SecondarySection
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
    />
  </Box>
);
