import { ThemeComponentType } from '../types';

export const Accordion: ThemeComponentType = {
  defaultProps: {
    chevronPosition: 'left',
    variant: 'filled',
  },
  styles: (theme, { variant }) => ({
    item: {
      borderColor: variant === 'contained' ? theme.colors.gray[1] : undefined,
      '&[data-active]': {
        backgroundColor: 'transparent',
      },
    },
    control: {
      ':hover': {
        backgroundColor: 'transparent',
      },
    },
    chevron: {
      marginRight: theme.other.spacing(0.5),
      svg: {
        width: 32,
        height: 32,
      },
    },
    label: {
      fontSize: theme.fontSizes.xl,
      fontWeight: 600,
    },
  }),
};
