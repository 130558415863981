import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ArrowUpIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m12 4 5.12 5.12M12 4 6.88 9.12M12 4v16"
      />
    </Box>
  );
};
