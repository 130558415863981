import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { NumberInput } from 'components/shared';
import { Props as NumberInputProps } from 'components/shared/Inputs/NumberInput';

import { MARGIN_RANGE } from '../consts';

type Props = {
  nestedLabel: string;
} & NumberInputProps;

export const MarginInput = forwardRef<HTMLInputElement, Props>(
  ({ nestedLabel, ...numberInputProps }, ref) => {
    const { t } = useTranslation('organisations');

    return (
      <NumberInput
        styles={{
          wrapper: {
            maxWidth: 81,
          },
        }}
        {...numberInputProps}
        ref={ref}
        min={MARGIN_RANGE.min}
        max={MARGIN_RANGE.max}
        step={0.1}
        precision={1}
        removeTrailingZeros
        hideControls={false}
        label={
          <>
            {nestedLabel}
            <Text
              sx={theme => ({
                display: 'block',
                marginTop: theme.other.spacing(0.25),
              })}
              span
              size="md"
              weight={400}
            >
              {t('organisations:brandingMarginRange', {
                min: MARGIN_RANGE.min,
                max: MARGIN_RANGE.max,
              })}
            </Text>
          </>
        }
      />
    );
  },
);
