import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE } from 'consts';

type LayoutMainBottomPaddingSpacingContextType = number;

export const LayoutMainBottomPaddingSpacingContext = createContext(
  [] as unknown as [
    LayoutMainBottomPaddingSpacingContextType,
    Dispatch<SetStateAction<LayoutMainBottomPaddingSpacingContextType>>,
  ],
);

type Props = {
  children: ReactNode;
};

export const LayoutMainBottomPaddingSpacingContextProvider = ({
  children,
}: Props) => {
  const layoutMainBottomPaddingSpacingState =
    useState<LayoutMainBottomPaddingSpacingContextType>(
      DEFAULT_LAYOUT_BOTTOM_PADDING_VALUE,
    );

  return (
    <LayoutMainBottomPaddingSpacingContext.Provider
      value={layoutMainBottomPaddingSpacingState}
    >
      {children}
    </LayoutMainBottomPaddingSpacingContext.Provider>
  );
};
