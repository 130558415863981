import { useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { getNumberInputValue } from '../helpers';
import { InitialNumberValue } from '../InitialNumberValue';
import { Placeholder } from '../Placeholder';
import { Label } from '../Label';
import { DescriptionForApi } from '../DescriptionForApi';
import { Hint } from '../Hint';
import { Required } from '../Required';
import { MinMaxNumberValue } from '../MinMaxNumberValue';
import { useGetZodIntegerVariableSchema } from './hooks';

export const IntegerVariable = ({ fieldsInstanceRef, initialValues }) => {
  const schema = useGetZodIntegerVariableSchema();

  const {
    formState: { errors: formErrors },
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
      ? {
          ...initialValues,
          initialValue: initialValues.initialValue
            ? Number(initialValues.initialValue)
            : undefined,
          minValue: initialValues.minValue
            ? Number(initialValues.minValue)
            : undefined,
          maxValue: initialValues.maxValue
            ? Number(initialValues.maxValue)
            : undefined,
        }
      : {
          initialValue: undefined,
          placeholder: '',
          label: '',
          hint: '',
          apiDescription: '',
          minValue: undefined,
          maxValue: undefined,
          required: false,
          requiredWhen: null,
        },
  });

  useImperativeHandle(fieldsInstanceRef, () => ({
    getValues: () => {
      const { initialValue, minValue, maxValue, ...values } = getValues();

      return {
        ...values,
        initialValue: getNumberInputValue(initialValue),
        minValue: getNumberInputValue(minValue),
        maxValue: getNumberInputValue(maxValue),
      };
    },
    schema,
    clearErrors,
    setError,
  }));

  const { initialValue, minValue, maxValue } = getValues();
  const handleNumberValueChange = (value, field) => {
    clearErrors(field);
    setValue(field, value);
  };

  return (
    <div>
      <InitialNumberValue
        inputProps={{
          defaultValue: initialValue,
          onChange: value => {
            handleNumberValueChange(value, 'initialValue');
          },
        }}
        error={formErrors?.initialValue?.message || ''}
      />
      <Placeholder
        inputProps={{ ...register('placeholder') }}
        error={formErrors?.placeholder?.message || ''}
      />
      <Label
        inputProps={{ ...register('label') }}
        error={formErrors?.label?.message || ''}
      />
      <Hint
        inputProps={{ ...register('hint') }}
        error={formErrors?.hint?.message || ''}
      />
      <DescriptionForApi
        inputProps={{ ...register('apiDescription') }}
        error={formErrors?.apiDescription?.message || ''}
      />
      <MinMaxNumberValue
        inputMinValueProps={{
          defaultValue: minValue,
          onChange: value => {
            handleNumberValueChange(value, 'minValue');
          },
        }}
        inputMaxValueProps={{
          defaultValue: maxValue,
          onChange: value => {
            handleNumberValueChange(value, 'maxValue');
          },
        }}
        error={
          formErrors?.minValue?.message || formErrors?.maxValue?.message || ''
        }
      />
      <Required checkboxProps={{ ...register('required') }} />
    </div>
  );
};
