import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { patchTransactionDataStream } from '../requests';
import { PatchTransactionDataStreamRequestType } from '../types';

export const usePatchTransactionDataStream = ({
  pathParams: { dataStreamId, transactionId },
}: {
  pathParams: Omit<
    PatchTransactionDataStreamRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const queryClient = useQueryClient();

  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (
      bodyParams: PatchTransactionDataStreamRequestType['bodyParams'],
    ) =>
      patchTransactionDataStream({
        pathParams: { organisationId, dataStreamId, transactionId },
        bodyParams,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.transactionDataStream,
          { organisationId, dataStreamId, transactionId },
        ],
      });
    },
  });
};
