import { Document } from '@react-pdf/renderer';

import { MarginType } from '../types';
import { SampleDocumentContent } from './DocumentContent';
import { DocumentPage } from './DocumentPage';

type Props = {
  padding: MarginType;
};

export const SamplePdfDocument = ({ padding }: Props) => (
  <Document>
    <DocumentPage padding={padding}>
      <SampleDocumentContent />
    </DocumentPage>
  </Document>
);
