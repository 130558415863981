import { useTranslation } from 'react-i18next';

import { CERTIFICATE_LANGUAGES } from 'consts';

import { TemplateDescriptionField } from '../TemplateDescriptionField';
import { CertificateLanguageCheckboxes } from './CertificateLanguageCheckboxes';

type CertificateLanguagesType =
  (typeof CERTIFICATE_LANGUAGES)[keyof typeof CERTIFICATE_LANGUAGES][];

type Props = {
  error: string | undefined;
  certificateLanguages: CertificateLanguagesType;
  onCertificateLanguagesChange: (
    certificateLanguages: CertificateLanguagesType,
  ) => void;
};

export const CertificateLanguages = ({
  error,
  certificateLanguages,
  onCertificateLanguagesChange,
}: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:certificateLangs')}
      description={t('templates:certificateLangsDescription')}
      error={error}
    >
      <CertificateLanguageCheckboxes
        certificateLanguages={certificateLanguages}
        onCertificateLanguagesChange={onCertificateLanguagesChange}
      />
    </TemplateDescriptionField>
  );
};
