import { apiAuthClient } from 'api/helpers';

import {
  PostCustomizationsDocumentPaperRequestType,
  PostCustomizationsDocumentPaperResponseType,
} from '../types';

export const postCustomizationsDocumentPaper = async ({
  pathParams: { organisationId, boardId, templateId },
  formData,
}: PostCustomizationsDocumentPaperRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/boards/${boardId}/templates/${templateId}/customizations/document/paper`;

  const { data } =
    await apiAuthClient.post<PostCustomizationsDocumentPaperResponseType>(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

  return data.data;
};
