import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

import { usePostTemplate } from 'api/templates';
import { DYNAMIC_URLS, SIGN_TYPES, URLS } from 'consts';
import { useServerErrors, useShowNotification } from 'hooks';
import { EyeIcon, SaveIcon } from 'icons';
import { TemplateCreatorEditor } from 'legacy/TemplateCreatorEditor';
import { ActionsBar } from 'components/shared';
import {
  AutoNumeration,
  CertificateLanguages,
  FormAccordion,
  Name,
  Permissions,
  Properties,
  SignType,
  TemplateType,
} from 'components/Templates';

import { handleErrorFocus } from './helpers';
import { useGetZodSchema } from './hooks';
import { FormValuesType } from './types';

export const TemplateAddForm = () => {
  const { t } = useTranslation(['common', 'templates']);

  const navigate = useNavigate();

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();

  const { mutateAsync: createTemplate, isLoading: isCreatingTemplate } =
    usePostTemplate();

  const schema = useGetZodSchema();

  const formRef = useRef<HTMLFormElement>(null);

  const editorRef = useRef<{
    editorValueJson: object;
    setGeneralError: Dispatch<SetStateAction<string>>;
    triggerValidationAndGetErrors: () => string[];
  }>(null);

  const {
    formState: { errors: formErrors },
    handleSubmit,
    register,
    clearErrors,
    setError,
    setValue,
    watch,
  } = useForm<FormValuesType>({
    resolver: zodResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      name: '',
      signType: '',
      certificateLanguages: ['pl'],
      enableNumeric: false,
      templateNumeric: '',
      templateType: 'base',
      oneWay: false,
      canEditByNegotiator: false,
    },
  });

  const onSubmit = async ({
    name,
    signType,
    certificateLanguages,
    enableNumeric,
    templateNumeric,
    templateType,
    oneWay,
    canEditByNegotiator,
  }: FormValuesType) => {
    const hasContractTemplateErrors =
      (editorRef.current?.triggerValidationAndGetErrors() || []).length > 0;

    if (!hasContractTemplateErrors) {
      try {
        const { id } = await createTemplate({
          name,
          signType: signType as Exclude<typeof signType, ''>,
          certificatesLanguages: certificateLanguages,
          enableNumeric,
          templateNumeric,
          oneWay,
          canEditByNegotiator,
          base: templateType === 'base',
          isOffer: templateType === 'offer',
          contractTemplate: editorRef.current?.editorValueJson,
        });

        showNotification({
          message: t('templates:templateAdded'),
        });

        navigate(DYNAMIC_URLS.templateEdit({ templateId: String(id) }), {
          replace: true,
        });
      } catch (error) {
        const { contractTemplate = '', ...validationServerErrors } =
          getServerErrorStatus(error);

        Object.entries(validationServerErrors).forEach(([field, message]) => {
          setError(field as keyof FormValuesType, {
            type: 'server',
            message,
          });
        });

        handleErrorFocus(validationServerErrors, formRef);

        editorRef.current?.setGeneralError(contractTemplate);

        addServerErrorActions(error);
      }
    }
  };

  const onError = () => {
    editorRef.current?.triggerValidationAndGetErrors();
  };

  const templateType = watch('templateType');

  useEffect(() => {
    if (templateType === 'offer') {
      setValue('signType', SIGN_TYPES.any);
    }
  }, [setValue, templateType]);

  useEffect(() => {
    handleErrorFocus(formErrors, formRef);
  }, [formErrors]);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
        <FormAccordion
          sx={theme => ({
            marginBottom: theme.other.spacing(3),
          })}
          isError={Object.keys(formErrors).length > 0}
        >
          <Name
            error={formErrors.name?.message}
            nameInputProps={{ ...register('name') }}
          />
          <TemplateType
            error={formErrors.templateType?.message}
            templateTypeRadioProps={{ ...register('templateType') }}
          />
          <SignType
            error={formErrors.signType?.message}
            signType={watch('signType')}
            signTypeRadioProps={{ ...register('signType') }}
            allowedItems={templateType === 'offer' ? [SIGN_TYPES.any] : 'all'}
          />
          <CertificateLanguages
            error={formErrors.certificateLanguages?.message}
            certificateLanguages={watch('certificateLanguages')}
            onCertificateLanguagesChange={certificateLanguages => {
              clearErrors('certificateLanguages');
              setValue('certificateLanguages', certificateLanguages);
            }}
          />
          <AutoNumeration
            error={formErrors.templateNumeric?.message}
            isTemplateNumericEnabled={watch('enableNumeric')}
            onTemplateNumericEnabledChange={enabled => {
              setValue('enableNumeric', enabled);
            }}
            templateNumeric={watch('templateNumeric')}
            onTemplateNumericChange={templateNumeric => {
              clearErrors('templateNumeric');
              setValue('templateNumeric', templateNumeric);
            }}
          />
          <Properties
            error={formErrors.oneWay?.message}
            oneWayCheckboxProps={{ ...register('oneWay') }}
          />
          <Permissions
            sx={{
              paddingBottom: 0,
            }}
            error={formErrors.canEditByNegotiator?.message}
            canEditByNegotiatorCheckboxProps={{
              ...register('canEditByNegotiator'),
            }}
          />
        </FormAccordion>
        {/* @ts-ignore */}
        <TemplateCreatorEditor editorRef={editorRef} />
      </form>
      <ActionsBar
        items={[
          {
            name: t('common:cancel'),
            to: URLS.templates,
            disabled: isCreatingTemplate,
          },
          {
            name: t('templates:documentPreview'),
            action: () => {
              // eslint-disable-next-line no-alert
              alert('COMING SOON');
            },
            icon: <EyeIcon />,
          },
          {
            name: t('common:save'),
            action: () => {
              formRef.current?.requestSubmit();
            },
            icon: <SaveIcon />,
            color: 'blue',
            isLoading: isCreatingTemplate,
          },
        ]}
      />
    </>
  );
};
