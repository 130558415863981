import { useId } from 'react';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const WomanWithMagnifierRoundedIcon = ({
  color,
  size,
  width = 313,
  height = 313,
  ...props
}: IconPropsType) => {
  const id = useId();
  const iconColor = useGetColor(color);

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 313 313"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id={`${id}_a`} d="M0 0h262.458v149.337H0z" />
        <path id={`${id}_b`} d="M0 0h138.002v138.17H0z" />
        <circle id={`${id}_c`} cx={156.5} cy={156.5} r={156.5} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id={`${id}_d`} fill="#fff">
            <use xlinkHref={`#${id}_c`} />
          </mask>
          <g mask={`url(#${id}_d)`}>
            <g transform="translate(-21 46)">
              <path
                d="m194.654 146.306 8.847 3.006 7.02-17.083-9.45-2.61-6.417 16.687Zm10.055 5.667-12.838-4.363 7.907-20.56 13.626 3.765-8.695 21.158Z"
                fill="#0B0817"
              />
              <path
                fill="#FFF"
                d="m201.04 129.523 9.477 2.755-7.025 17.106-8.902-3.037z"
              />
              <g transform="translate(0 131.663)">
                <mask id={`${id}_e`} fill="#fff">
                  <use xlinkHref={`#${id}_a`} />
                </mask>
                <path
                  d="M88.666 7.574s-9.885 2.852-7.875 13.252c0 0-39.71-2.851-56.13 31.371C8.242 86.42-7.34 104.37 3.718 126.178c11.059 21.808 28.987 25.332 44.066 22.144 15.08-3.187 40.213-11.408 40.213-11.408l174.462-.22-.968-56.876-36.795-4.523-6.133 43.625s-8.714-44.272-11.942-64.308C203.392 34.576 190.482.968 145.618 9.37c0 0-3.82-8.836-11.016-9.37L88.666 7.574Z"
                  fill="#0B0817"
                  mask={`url(#${id}_e)`}
                />
              </g>
              <path
                d="m184.672 137.786 31.738 10.742-27.14 76.898c-3.182 9.016-12.953 13.86-22.042 10.925-9.6-3.098-14.655-13.612-11.086-23.058l28.53-75.507Z"
                fill={iconColor}
                fillRule="nonzero"
              />
              <path
                d="M157.05 173.269s13.882-23.441 14.607-30.184c.725-6.741 10.463-1.659 10.05 6.639-.416 8.298-3.005 15.559-3.005 15.559l-21.652 7.986Z"
                fill="#FFF"
              />
              <path
                d="M174.485 141.025c-.564 0-1.56.218-1.77 2.174-.604 5.608-9.609 21.714-13.253 28.045l18.393-6.785c.56-1.714 2.446-7.929 2.788-14.788.244-4.891-3.393-8.436-5.907-8.635-.066-.004-.152-.011-.251-.011Zm-19.877 34.28 1.527-2.58c.138-.232 13.771-23.317 14.464-29.753.346-3.218 2.45-4.215 4.305-4.06 3.873.306 8.152 5.119 7.864 10.865-.417 8.359-2.957 15.56-3.064 15.864l-.167.469-24.93 9.196Z"
                fill="#000"
              />
              <path
                d="m89.823 209.055 54.907-15.745s4.146-21.63 16.707-26.033c9.524-3.337 34.504-10.219 46.024-13.35 3.74-1.017 7.275 2.193 6.625 6.018-.423 2.488-1.651 5.035-4.542 6.703-7.41 4.276-20.199 9.413-20.199 9.413s19.057-4.025 23.42-2.25c5.713 2.324 6.074 11.576-1.965 14.217 0 0 7.16 11.193-2.261 14.337 0 0 5.722 3.072 2.478 8.879-1.534 2.744-4.366 4.514-7.484 4.896l-25.14 3.078s-4.522 9.557-18.715 15.594c-14.194 6.037-72.075 32.684-72.075 32.684s-17.518-16.64-13.448-33.128c4.07-16.487 15.668-25.313 15.668-25.313"
                fill="#FFF"
              />
              <path
                d="M90.319 210.021c-1.348 1.095-11.452 9.7-15.13 24.603-2.01 8.14 1.632 16.396 5.04 21.887 2.998 4.826 6.35 8.43 7.601 9.708 6.514-2.997 58.211-26.765 71.433-32.388 13.606-5.787 18.126-14.98 18.17-15.071l.252-.527.58-.074 25.138-3.078c2.856-.348 5.355-1.978 6.685-4.359.825-1.476 1.057-2.863.69-4.123-.614-2.103-2.72-3.284-2.742-3.295l-2.175-1.168 2.342-.781c2.019-.675 3.269-1.757 3.818-3.31 1.376-3.89-2.083-9.388-2.118-9.444l-.737-1.157 1.303-.428c3.29-1.082 5.317-3.515 5.42-6.508.089-2.562-1.295-4.803-3.525-5.71-2.98-1.213-15.267.715-22.8 2.305l-.615-2.031c.127-.051 12.803-5.158 20.064-9.347 2.215-1.278 3.569-3.282 4.023-5.96a4.231 4.231 0 0 0-1.331-3.855 4.22 4.22 0 0 0-3.965-.955c-10.617 2.886-36.35 9.963-45.953 13.328-11.832 4.146-15.971 25.018-16.012 25.228l-.123.642-.629.182L90.32 210.02Zm-2.926 58.744-.523-.496c-.733-.697-17.919-17.262-13.748-34.157 4.113-16.66 15.571-25.536 16.056-25.905l.16-.12.192-.056 54.29-15.57a61.827 61.827 0 0 1 3.578-10.944c3.583-8.19 8.187-13.318 13.687-15.246 9.668-3.387 35.458-10.48 46.098-13.372a6.335 6.335 0 0 1 5.953 1.433 6.352 6.352 0 0 1 1.998 5.792c-.565 3.332-2.268 5.837-5.058 7.448-3.327 1.919-7.752 4.02-11.625 5.755 5.65-.864 12.193-1.528 14.714-.504 3.067 1.248 4.97 4.293 4.851 7.76-.095 2.768-1.659 6.19-5.679 7.992 1.077 2.042 2.997 6.48 1.692 10.176-.584 1.654-1.72 2.947-3.385 3.863.822.761 1.74 1.89 2.177 3.387.532 1.83.24 3.768-.875 5.763-1.66 2.97-4.758 5.002-8.285 5.433l-24.585 3.01c-1.194 2.155-6.335 10.207-18.982 15.586-14.018 5.961-71.468 32.404-72.046 32.671l-.655.301Z"
                fill="#000"
              />
              <path
                d="m226.752 207.184 32.898 4.1 2.738-12.812 63.1-31.084a2.451 2.451 0 0 0 1.38-2.098 2.449 2.449 0 0 0-1.159-2.227 6.89 6.89 0 0 0-6.104-.587l-17.225 6.632 11.577-17.219c.65-.967.686-2.146.1-3.153a2.898 2.898 0 0 0-2.792-1.459 7.696 7.696 0 0 0-5.325 2.792l-15.322 18.766 5.09-15.627c.393-1.209.074-2.45-.854-3.318a3.115 3.115 0 0 0-3.364-.628 7.898 7.898 0 0 0-3.785 3.221l-11.074 18.311-21.502-7.58 14.252-21.935a4.781 4.781 0 0 0-1.197-6.476 4.752 4.752 0 0 0-6.17.479c-3.09 3.072-7.629 7.708-11.7 12.342-2.106 2.399-4.844 5.206-7.742 8.176-6.589 6.757-14.058 14.415-15.703 18.99-2.112 5.874-.489 27.774-.117 32.394Zm34.572 6.454-36.546-4.553-.075-.858c-.097-1.107-2.347-27.176.163-34.158 1.802-5.012 9.113-12.508 16.184-19.757 2.878-2.95 5.595-5.738 7.666-8.095 4.11-4.68 8.685-9.352 11.798-12.448a6.871 6.871 0 0 1 8.92-.69 6.911 6.911 0 0 1 1.73 9.362l-12.775 19.663 17.318 6.106 10.177-16.831a10.055 10.055 0 0 1 4.81-4.095 5.26 5.26 0 0 1 5.614 1.05 5.276 5.276 0 0 1 1.423 5.535l-1.565 4.804 8.126-9.953a9.828 9.828 0 0 1 6.804-3.567 5.056 5.056 0 0 1 4.798 2.509 5.066 5.066 0 0 1-.172 5.417l-7.809 11.617 10.928-4.21a9.035 9.035 0 0 1 7.995.77 4.555 4.555 0 0 1 2.158 4.141 4.552 4.552 0 0 1-2.565 3.903l-62.176 30.627-2.929 13.711ZM174.32 179.402l-.353-2.102 22.79-3.838.354 2.102zM174.777 192.998l-.232-2.119 36.88-4.044.232 2.118zM175.61 206.377l-.24-2.117 31.908-3.63.24 2.117z"
                fill="#0B0817"
              />
              <path
                d="M24.829 225.51a1.065 1.065 0 0 1-.215-2.109l67.317-13.977a1.065 1.065 0 0 1 .432 2.087l-67.317 13.977a1.084 1.084 0 0 1-.217.022"
                fill="#FFF"
              />
              <path
                d="M90.012 27.792S50.525 38.05 59.49 76.73l-1.067 6.625S40.03 77.359 33.118 96.193c-6.82 18.588 10.15 27.98 22.104 27.125 0 0 5.55 18.805 31.375 5.983l49.305-6.625s24.973-2.136 20.49-27.78c0 0 10.673-9.832 6.403-21.585-4.269-11.754-14.514-14.745-14.514-14.745l-9.674-4.844s-2.615-43.29-48.595-25.93"
                fill="#0B0817"
              />
              <path
                fill="#FFF"
                d="m78.613 102.162 14.632 45.42 41.851-7.607-5.212-43.852z"
              />
              <path
                d="m80.023 103.07 13.949 43.296 39.95-7.26-4.97-41.8-48.929 5.763Zm12.495 45.727-15.315-47.541 53.612-6.315 5.457 45.904-43.754 7.952Z"
                fill="#000"
              />
              <path
                d="M94.018 42.049s14.76 14.902 34.977 10.804c0 0 .744 4.594 4.713 3.725 3.969-.87 9.55-2.98 12.155 3.353 2.605 6.334-6.077 11.425-9.426 11.302 1.554.057.094 18.134-.255 19.939-4.262 22.002-32.66 34.86-51.287 21.476-5.427-3.9-9.322-9.607-11.637-15.83-.22-.593-3.07-9.28-2.31-9.566 0 0-13.51 5.085-15.008-6.085-1.24-9.252 11.659-12.046 15.008-8.817 0 0 22.077-13.599 23.07-30.301"
                fill="#FFF"
              />
              <path
                d="M71.885 87.623c-.116 1.088 1.957 7.71 2.37 8.823 2.407 6.47 6.3 11.772 11.26 15.336 8.06 5.79 18.925 6.838 29.063 2.803 10.684-4.253 18.561-13.302 20.56-23.616.412-2.13 1.482-17.302.622-19.012.053.105.278.327.638.34l.078-2.13h.002c1.723.068 5.673-1.742 7.67-4.611 1.2-1.725 1.446-3.481.73-5.22-1.854-4.51-5.186-4.092-9.694-3.008-.429.103-.847.203-1.249.292-1.376.3-2.596.1-3.621-.6-1.158-.788-1.775-2.044-2.087-2.939-16.262 2.84-29.045-6.248-33.377-9.865-2.544 16.056-22.462 28.498-23.345 29.042l-.702.432-.594-.572c-1.343-1.296-5.418-1.44-8.86.304-1.879.953-4.944 3.208-4.354 7.603.333 2.488 1.349 4.257 3.02 5.26 3.98 2.386 10.494-.006 10.559-.03l.748 1.994c.39-.147.548-.485.563-.626m30.331 31.518c-6.506 0-12.757-1.902-17.942-5.628-5.304-3.81-9.458-9.455-12.013-16.324-.117-.315-.9-2.68-1.549-4.98-.43-1.52-.713-2.652-.852-3.5-2.42.66-7.324 1.567-10.933-.593-2.257-1.352-3.618-3.641-4.042-6.807-.558-4.164 1.448-7.731 5.503-9.788 3.679-1.865 8.185-2.017 10.614-.473 1.58-1.04 5.976-4.075 10.397-8.379 5-4.868 11.065-12.409 11.556-20.683l.142-2.376 1.676 1.688c.126.128 11.33 11.193 27.214 11.195 2.183 0 4.453-.21 6.796-.685l1.085-.22.177 1.094c.003.014.318 1.8 1.472 2.58.53.359 1.172.448 1.963.276.388-.086.792-.183 1.207-.282 3.77-.907 9.467-2.278 12.16 4.27.994 2.417.665 4.923-.953 7.247-1.868 2.684-5.3 4.741-7.91 5.345.213 1.301.332 3.856.072 9.13-.225 4.55-.651 9.207-.83 10.127-2.133 11.02-10.51 20.672-21.862 25.19-4.334 1.725-8.798 2.576-13.148 2.576"
                fill="#000"
              />
              <path
                d="M118.52 62.667c0 .844-2.807 1.528-6.27 1.528-3.464 0-6.271-.684-6.271-1.528s2.807-1.528 6.27-1.528c3.464 0 6.271.684 6.271 1.528M93.666 64.534c.381.753-1.812 2.635-4.9 4.205-3.089 1.57-5.901 2.231-6.283 1.48-.382-.754 1.813-2.636 4.9-4.205 3.09-1.57 5.902-2.233 6.283-1.48M105.98 87.153c-.056 0-.112-.005-.17-.013-3.349-.538-5.466-1.7-6.475-3.554-1.515-2.79.006-6.245 1.229-9.023.42-.955.818-1.857 1.005-2.55.287-1.063.284-1.885-.01-2.258-.209-.265-.575-.282-.58-.282a1.074 1.074 0 0 1-1.097-1.032 1.066 1.066 0 0 1 1.032-1.098c.141.002 1.445-.012 2.315 1.09.748.949.88 2.34.394 4.137-.229.848-.658 1.821-1.111 2.852-1.035 2.35-2.323 5.276-1.307 7.145.662 1.218 2.325 2.048 4.943 2.468a1.067 1.067 0 0 1-.168 2.118M84.443 79.303a1.063 1.063 0 0 1-1-1.426c.053-.144 1.309-3.521 4.5-4.537 2.208-.702 4.705-.098 7.422 1.795a1.066 1.066 0 0 1-1.215 1.749c-2.14-1.49-4.006-2-5.548-1.517-2.191.688-3.15 3.214-3.16 3.24-.159.429-.565.696-.999.696M116.54 71.08c.1 1.983-.966 3.647-2.38 3.719-1.415.071-2.643-1.478-2.743-3.46-.1-1.982.967-3.647 2.381-3.718 1.415-.072 2.643 1.478 2.742 3.46M108.289 96.736a1.065 1.065 0 0 1-.266-2.098c.062-.016 4.523-1.225 6.141-4.681.946-2.02.755-4.476-.568-7.3a1.064 1.064 0 1 1 1.928-.904c1.606 3.431 1.794 6.5.56 9.123-2.056 4.372-7.307 5.77-7.53 5.826a1.064 1.064 0 0 1-.265.034"
                fill="#0B0817"
              />
              <path
                d="M84.445 69.722a1.067 1.067 0 0 1-.579-1.96c.044-.028 3.047-2.014 5.73-6.028 3.589-5.367 4.943-11.635 4.024-18.63a1.064 1.064 0 1 1 2.11-.278c1.237 9.42-1.722 16.173-4.422 20.18-2.935 4.353-6.151 6.459-6.287 6.546-.179.115-.379.17-.576.17M68.885 84.26c-.028 0-.055 0-.083-.004a1.065 1.065 0 0 1-.98-1.143c.152-1.97-.247-3.443-1.183-4.381-1.398-1.4-3.631-1.246-3.653-1.242a1.07 1.07 0 0 1-1.145-.976 1.064 1.064 0 0 1 .967-1.148c.132-.01 3.21-.25 5.319 1.841 1.408 1.398 2.02 3.44 1.818 6.069-.043.559-.51.984-1.06.984"
                fill="#0B0817"
              />
              <path
                d="M157.861 69.085c0-37.566 30.416-68.02 67.937-68.02 37.52 0 67.936 30.454 67.936 68.02 0 37.567-30.416 68.02-67.936 68.02-37.52 0-67.937-30.453-67.937-68.02"
                fill="#FFF"
              />
              <g transform="translate(156.797)">
                <mask id={`${id}_f`} fill="#fff">
                  <use xlinkHref={`#${id}_b`} />
                </mask>
                <path
                  d="M69.001 2.13c-36.874 0-66.873 30.036-66.873 66.956 0 36.918 29.999 66.953 66.873 66.953 36.874 0 66.873-30.035 66.873-66.953 0-36.92-29.999-66.955-66.873-66.955m0 136.04C30.954 138.17 0 107.178 0 69.085 0 30.992 30.954 0 69.001 0c38.047 0 69.001 30.992 69.001 69.086 0 38.093-30.954 69.084-69 69.084"
                  fill="#000"
                  mask={`url(#${id}_f)`}
                />
              </g>
              <path
                d="M168.238 69.085c0-31.828 25.77-57.63 57.56-57.63 31.79 0 57.56 25.802 57.56 57.63 0 31.829-25.77 57.631-57.56 57.631-31.79 0-57.56-25.802-57.56-57.63"
                fill="#FFF"
              />
              <path
                d="M225.798 12.52c-31.152 0-56.497 25.375-56.497 56.565 0 31.19 25.345 56.566 56.497 56.566s56.496-25.376 56.496-56.566S256.95 12.52 225.798 12.52m-58.624 56.565c0-32.365 26.298-58.696 58.624-58.696 32.326 0 58.624 26.331 58.624 58.696 0 32.366-26.298 58.697-58.624 58.697-32.326 0-58.624-26.331-58.624-58.697Z"
                fill="#000"
              />
              <circle
                fill={iconColor}
                fillRule="nonzero"
                cx={226}
                cy={68}
                r={50}
              />
            </g>
          </g>
        </g>
        <path
          d="m205.752 253.184 32.898 4.1 2.738-12.812 63.1-31.084a2.451 2.451 0 0 0 1.38-2.098 2.449 2.449 0 0 0-1.159-2.227 6.89 6.89 0 0 0-6.104-.587l-17.225 6.632 11.577-17.219c.65-.967.686-2.146.1-3.153a2.898 2.898 0 0 0-2.792-1.459 7.696 7.696 0 0 0-5.325 2.792l-15.322 18.766 5.09-15.627c.393-1.209.074-2.45-.854-3.318a3.115 3.115 0 0 0-3.364-.628 7.898 7.898 0 0 0-3.785 3.221l-11.074 18.311-21.502-7.58 14.252-21.935a4.781 4.781 0 0 0-1.197-6.476 4.752 4.752 0 0 0-6.17.479c-3.09 3.072-7.629 7.708-11.7 12.342-2.106 2.399-4.844 5.206-7.742 8.176-6.589 6.757-14.058 14.415-15.703 18.99-2.112 5.874-.489 27.774-.117 32.394Zm34.572 6.454-36.546-4.553-.075-.858c-.097-1.107-2.347-27.176.163-34.158 1.802-5.012 9.113-12.508 16.184-19.757 2.878-2.95 5.595-5.738 7.666-8.095 4.11-4.68 8.685-9.352 11.798-12.448a6.871 6.871 0 0 1 8.92-.69 6.911 6.911 0 0 1 1.73 9.362l-12.775 19.663 17.318 6.106 10.177-16.831a10.055 10.055 0 0 1 4.81-4.095 5.26 5.26 0 0 1 5.614 1.05 5.276 5.276 0 0 1 1.423 5.535l-1.565 4.804 8.126-9.953a9.828 9.828 0 0 1 6.804-3.567 5.056 5.056 0 0 1 4.798 2.509 5.066 5.066 0 0 1-.172 5.417l-7.809 11.617 10.928-4.21a9.035 9.035 0 0 1 7.995.77 4.555 4.555 0 0 1 2.158 4.141 4.552 4.552 0 0 1-2.565 3.903l-62.176 30.627-2.929 13.711Z"
          fill="#0B0817"
        />
      </g>
    </svg>
  );
};
