import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { NotificationListSummaryType } from 'types';

export const NotificationCountContext = createContext(
  [] as unknown as [
    NotificationListSummaryType,
    Dispatch<SetStateAction<NotificationListSummaryType>>,
  ],
);

type Props = {
  children: ReactNode;
};

export const NotificationCountContextProvider = ({ children }: Props) => {
  const notificationCountState = useState<NotificationListSummaryType>({
    unreadCount: null,
    documentUnreadCount: null,
    organisationUnreadCount: null,
  });

  return (
    <NotificationCountContext.Provider value={notificationCountState}>
      {children}
    </NotificationCountContext.Provider>
  );
};
