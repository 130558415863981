import { useEffect } from 'react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';
import {
  BackendFetch,
  DevTools,
  I18nextPlugin,
  Tolgee,
  withTolgee,
} from '@tolgee/i18next';

import { useGetUser } from 'api/account';
import { env } from 'env';
import { getNavigatorLanguage } from 'helpers';
import { useChangeTranslations } from 'hooks';
import { BASENAME, DEFAULT_LANGUAGE, STORAGE_KEYS } from 'consts';
import { useLocalStorage } from '@mantine/hooks';

const customNavigatorDetector = {
  name: 'customNavigatorDetector',
  lookup: getNavigatorLanguage,
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customNavigatorDetector);

const tolgee = Tolgee()
  .use(I18nextPlugin())
  .use(DevTools())
  .use(
    BackendFetch({
      prefix: `/${BASENAME}/i18n`,
    }),
  )
  .init({
    apiUrl: env.REACT_APP_TOLGEE_API_URL,
    apiKey: env.REACT_APP_TOLGEE_API_KEY,
    language: DEFAULT_LANGUAGE,
  });

withTolgee(i18next, tolgee)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    fallbackLng: DEFAULT_LANGUAGE,
    ns: 'common',
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['customNavigatorDetector'],
    },
    returnNull: false,
  });

type Props = {
  children: JSX.Element;
};

export const I18NextProvider = ({ children }: Props) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [token] = useLocalStorage({
    key: STORAGE_KEYS.token,
    serialize: value => value,
    getInitialValueInEffect: false,
  });

  const { data: userData } = useGetUser({
    enabled: Boolean(token) && token !== 'null',
  });

  const changeTranslations = useChangeTranslations();

  useEffect(() => {
    if (userData?.locale && userData.locale !== language) {
      changeTranslations(userData.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.locale]);

  return children;
};
