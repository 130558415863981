import { useMutation } from '@tanstack/react-query';
import { useGetCurrentOrganisationId } from 'hooks';
import { deleteWebhook } from '../requests';
import { DeleteWebhookRequestType } from '../types';

export const useDeleteWebhook = ({
  options,
}: {
  options?: {
    onSuccess?: () => void;
  };
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: ({
      pathParams: { webhookId },
    }: {
      pathParams: Omit<
        DeleteWebhookRequestType['pathParams'],
        'organisationId'
      >;
    }) =>
      deleteWebhook({
        pathParams: {
          organisationId,
          webhookId,
        },
      }),
    ...options,
  });
};
