import { useSearchParams } from 'hooks';
import { CursorPagination } from 'components/Transactions';

type Props = {
  prevPage: string | null | undefined;
  nextPage: string | null | undefined;
  isStickyToBottom?: boolean;
};
export const CursorPaginationReactRouter = ({
  prevPage,
  nextPage,
  isStickyToBottom,
}: Props) => {
  const { changeSearchParam } = useSearchParams(['search', 'page']);

  const goToPrevPage = prevPage
    ? () => {
        changeSearchParam('page', prevPage);
      }
    : undefined;
  const goToNextPage = nextPage
    ? () => {
        changeSearchParam('page', nextPage);
      }
    : undefined;

  const isPaginationShown = goToPrevPage || goToNextPage;

  return isPaginationShown ? (
    <CursorPagination
      sx={theme => ({
        paddingTop: theme.other.spacing(2),
        justifyContent: 'flex-end',
      })}
      goToPrevPage={goToPrevPage}
      goToNextPage={goToNextPage}
      isStickyToBottom={isStickyToBottom}
    />
  ) : null;
};
