import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const EyeIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m4 12-.696-.278a.75.75 0 0 0 0 .556L4 12Zm16 0 .696.279a.75.75 0 0 0 0-.557L20 12Zm-8 4.964c-2.578 0-4.39-1.28-5.585-2.609a10.527 10.527 0 0 1-1.627-2.43 5.639 5.639 0 0 1-.089-.197l-.003-.007s0-.001 0 0L4 12c-.696.278-.696.279-.696.28l.001.002.002.005a1.382 1.382 0 0 0 .032.075 10.562 10.562 0 0 0 .455.9c.323.571.818 1.333 1.506 2.097 1.376 1.53 3.564 3.105 6.7 3.105v-1.5ZM4 12c.696.278.696.279.696.28a10.527 10.527 0 0 1 1.718-2.635C7.61 8.317 9.423 7.035 12 7.035v-1.5c-3.136 0-5.324 1.577-6.7 3.106a12.028 12.028 0 0 0-1.869 2.794 7.032 7.032 0 0 0-.124.278l-.002.005v.002h-.001c0 .001 0 .002.696.28Zm8-4.964c2.578 0 4.39 1.28 5.585 2.609a10.53 10.53 0 0 1 1.627 2.43 5.71 5.71 0 0 1 .089.197l.003.007s0 .001 0 0L20 12c.696-.278.696-.279.696-.28l-.001-.002-.002-.005-.007-.018-.025-.057a10.537 10.537 0 0 0-.455-.9A12.03 12.03 0 0 0 18.7 8.64c-1.376-1.53-3.564-3.105-6.7-3.105v1.5ZM20 12c-.696-.278-.696-.279-.696-.28l-.003.008-.016.038a9.049 9.049 0 0 1-.384.757 10.53 10.53 0 0 1-1.316 1.832c-1.195 1.328-3.007 2.61-5.585 2.61v1.5c3.136 0 5.324-1.577 6.7-3.106a12.032 12.032 0 0 0 1.869-2.794c.04-.085.071-.154.092-.203l.025-.057.007-.018.002-.005v-.002h.001L20 12Zm-8 1.536A1.536 1.536 0 0 1 10.464 12h-1.5A3.036 3.036 0 0 0 12 15.036v-1.5ZM13.536 12c0 .848-.688 1.536-1.536 1.536v1.5A3.036 3.036 0 0 0 15.036 12h-1.5ZM12 10.464c.848 0 1.536.688 1.536 1.536h1.5A3.036 3.036 0 0 0 12 8.964v1.5Zm0-1.5A3.036 3.036 0 0 0 8.964 12h1.5c0-.848.688-1.536 1.536-1.536v-1.5Z"
        fill={iconColor}
      />
    </Box>
  );
};
