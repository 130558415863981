import { Dispatch, SetStateAction } from 'react';
import { Box, DefaultProps, Loader, Text } from '@mantine/core';
import { Dropzone, DropzoneProps, FileWithPath } from '@mantine/dropzone';

import { useGetUploadFileErrorMessage } from './hooks';
import { Description } from './Description';

type Props = {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  onFileDrop: (file: FileWithPath) => void;
  isLoading: boolean;
  accept: DropzoneProps['accept'];
  acceptText: string;
  additionalText?: string;
  maxSizeInKB?: number;
  sx?: DefaultProps['sx'];
};

export const FileDropzone = ({
  error,
  setError,
  onFileDrop,
  isLoading,
  accept,
  acceptText,
  additionalText = '',
  maxSizeInKB = 10000,
  sx,
}: Props) => {
  const getUploadFileErrorMessage = useGetUploadFileErrorMessage({
    maxSizeInKB,
  });

  return (
    <Box sx={sx}>
      <Dropzone
        sx={theme => ({
          padding: theme.other.spacing(2),
          textAlign: 'center',
        })}
        accept={accept}
        multiple={false}
        useFsAccessApi={false}
        onDrop={([uploadedFile]) => {
          onFileDrop(uploadedFile);
        }}
        onReject={files => {
          setError(getUploadFileErrorMessage(files));
        }}
        maxSize={maxSizeInKB * 1000}
      >
        <Description
          isLoading={isLoading}
          acceptText={acceptText}
          additionalText={additionalText}
        />
        {isLoading && (
          <Loader sx={{ position: 'absolute', inset: 0, margin: 'auto' }} />
        )}
      </Dropzone>
      {error && (
        <Text color="red" mt="lg">
          {error}
        </Text>
      )}
    </Box>
  );
};
