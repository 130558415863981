import { forwardRef } from 'react';
import { Loader, useMantineTheme } from '@mantine/core';

import { SearchIcon } from 'icons';
import { TextInput } from 'components/shared';
import { Props as TextInputProps } from 'components/shared/Inputs/TextInput';

export type Props = {
  value: TextInputProps['value'];
  onChange: TextInputProps['onChange'];
  isLoading?: boolean;
} & TextInputProps;

export const TextInputSearch = forwardRef<HTMLInputElement, Props>(
  ({ isLoading = false, value, onChange, ...textInputMantineProps }, ref) => {
    const mantineTheme = useMantineTheme();

    return (
      <TextInput
        ref={ref}
        withErrorWrapperAlwaysEnabled={false}
        icon={
          <SearchIcon
            color={value ? mantineTheme.fn.primaryColor() : 'dark.3'}
          />
        }
        rightIcon={isLoading ? <Loader size="xs" /> : undefined}
        value={value}
        onChange={onChange}
        {...textInputMantineProps}
      />
    );
  },
);
