import { Box, useMantineTheme } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const ErrorTwoToneIcon = ({
  color,
  size = 24,
  sx,
  backgroundColor: backgroundColorProp,
  ...props
}: IconPropsType & { backgroundColor?: string }) => {
  const mantineTheme = useMantineTheme();
  const iconColor = useGetColor(color);
  const backgroundColorMantine = useGetColor(backgroundColorProp);
  const backgroundColor = backgroundColorProp
    ? backgroundColorMantine
    : mantineTheme.fn.rgba(iconColor, 0.07);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} rx={12} fill={backgroundColor} />
      <path
        d="m7 7 10 10M7 17 17 7"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
