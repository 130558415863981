import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Title } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';

import { atriumImg, hrmAcademyImg, volvoTrucksImg } from './assets';

const gradient =
  'linear-gradient(0deg, #32375C 0%, rgba(50,55,92,0.04) 69.12%, rgba(104,94,244,0) 100%)';

const delayInMs = 20000;

export const PromotionalCarousel = () => {
  const { t } = useTranslation('testimonials');

  const autoplay = useRef(Autoplay({ delay: delayInMs }));

  const slides = [
    {
      img: volvoTrucksImg,
      opinion: t('testimonials:volvoTrucks.opinion'),
      author: t('testimonials:volvoTrucks.author'),
    },
    {
      img: atriumImg,
      opinion: t('testimonials:atrium.opinion'),
      author: t('testimonials:atrium.author'),
    },
    {
      img: hrmAcademyImg,
      opinion: t('testimonials:hrmAcademy.opinion'),
      author: t('testimonials:hrmAcademy.author'),
    },
  ];

  return (
    <Carousel
      styles={theme => ({
        indicators: {
          justifyContent: 'flex-start',
          bottom: theme.other.spacing(4),
          left: theme.other.spacing(4),
        },
        indicator: {
          width: 7,
          height: 7,
          borderRadius: '50%',
        },
      })}
      draggable={false}
      withControls={false}
      withIndicators
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      {slides.map(({ img, opinion, author }, index) => (
        <Carousel.Slide
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          sx={theme => ({
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: theme.other.spacing(4),
            background: `${gradient}, url(${img}) 0 0/cover`,
          })}
        >
          <Box sx={theme => ({ marginBottom: theme.other.spacing(4) })}>
            <Title
              sx={theme => ({
                marginBottom: theme.other.spacing(2.5),
                color: theme.colors[theme.primaryColor][1],
              })}
              order={6}
            >
              {opinion}
            </Title>
            <Text
              sx={theme => ({
                color: theme.colors[theme.primaryColor][1],
              })}
              weight={600}
            >
              {author}
            </Text>
          </Box>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};
