import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';
import { Text } from '@mantine/core';

import { useGetUser, usePatchUser, usePostUserOtpUpdate } from 'api/account';
import { PatchUserRequestType } from 'api/account/types';
import { setHookFormServerErrors } from 'helpers';
import {
  useGetOtpCodeValidationError,
  useServerErrors,
  useShowNotification,
} from 'hooks';
import { OTP_DIGITS_NUMBER } from 'consts';
import { AuthOtpType, UserType, ValidationErrors } from 'types';
import { Modal, OtpFlow } from 'components/shared';

import { SetPasswordForm } from './SetPasswordForm';
import { SetNewPasswordForm } from './SetNewPasswordForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SetPasswordModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('user');

  const { addServerErrorActions, getServerErrorStatus, getServerFieldError } =
    useServerErrors();
  const showNotification = useShowNotification();
  const getOtpCodeValidationError = useGetOtpCodeValidationError();

  const [validationServerErrors, setValidationServerErrors] =
    useState<ValidationErrors>({});

  const [otpCode, setOtpCode] = useState('');
  const [otpCodeError, setOtpCodeError] = useState('');
  const [otpData, setOtpData] = useState<AuthOtpType | null>(null);

  const { data: userData } = useGetUser();
  const user = userData as UserType;
  const { mutateAsync: updateUserData, isLoading: isUpdatingUserData } =
    usePatchUser();
  const { mutateAsync: getOtpData, isLoading: isGettingOtpData } =
    usePostUserOtpUpdate();

  const formRef = useRef<HTMLFormElement>(null);
  const formInstanceRef = useRef<{
    setFormError: UseFormSetError<{}>;
  }>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleUpdatePasswordSuccessCallback = () => {
    showNotification({
      message: t('user:passwordUpdated'),
    });
    handleClose();
  };

  const handleUpdatePasswordServerErrors = (error: unknown) => {
    setHookFormServerErrors(
      getServerErrorStatus(error),
      formInstanceRef.current!.setFormError,
    );
    setOtpCodeError(getServerFieldError(error, 'code'));
    addServerErrorActions(error, {
      422: {
        message: t('user:passwordNotUpdated'),
      },
    });
  };

  const handleGetOtpCode = async () => {
    try {
      const data = await getOtpData({
        email: user.email,
        setupPassword: true,
      });

      setOtpData(data);
    } catch (error) {
      setHookFormServerErrors(
        getServerErrorStatus(error),
        formInstanceRef.current!.setFormError,
      );
      addServerErrorActions(error);
    }
  };

  const handleSetPassword = async ({
    currentPassword,
    password,
  }: {
    currentPassword: PatchUserRequestType['bodyParams']['password'];
    password: PatchUserRequestType['bodyParams']['password'];
  }) => {
    try {
      await updateUserData({
        currentPassword,
        password,
        passwordConfirmation: password,
      });

      handleUpdatePasswordSuccessCallback();
    } catch (error) {
      handleUpdatePasswordServerErrors(error);
    }
  };

  const handleSetNewPassword = async ({
    password,
  }: {
    password: PatchUserRequestType['bodyParams']['password'];
  }) => {
    try {
      await updateUserData({
        password,
        passwordConfirmation: password,
        otpId: otpData!.id,
        code: otpCode,
      });

      handleUpdatePasswordSuccessCallback();
    } catch (error) {
      handleUpdatePasswordServerErrors(error);
    }
  };

  const resetState = () => {
    setValidationServerErrors({});
    setOtpCode('');
    setOtpCodeError('');
    setOtpData(null);
  };

  const isUserPasswordSet = user.loginMethods.includes('password');

  useEffect(() => {
    if (otpData && (otpCodeError || validationServerErrors?.code)) {
      codeInputRef.current?.focus();
    }
  }, [otpData, otpCodeError, validationServerErrors?.code]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      title={t('user:passwordChange')}
      defaultButtonDisabled={isUpdatingUserData}
      defaultButtonAction={handleClose}
      primaryButtonIsLoading={isUpdatingUserData || isGettingOtpData}
      primaryButtonText={t('user:changePassword')}
      primaryButtonAction={() => {
        formRef.current?.requestSubmit();
      }}
    >
      {!otpData && (
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          size="md"
          weight={400}
        >
          {t('user:passwordChangeDescription', {
            buttonText: t('user:changePassword'),
            digits: OTP_DIGITS_NUMBER,
          })}
        </Text>
      )}
      {otpData && (
        <OtpFlow
          codeNumber={otpData.order}
          validUntil={otpData.validUntil}
          isGettingOtpData={isGettingOtpData}
          resendCode={handleGetOtpCode}
          textInputProps={{
            ref: codeInputRef,
            error: otpCodeError,
            value: otpCode,
            onChange: e => {
              setOtpCodeError('');
              setOtpCode(e.target.value);
            },
          }}
        />
      )}
      {isUserPasswordSet ? (
        <SetPasswordForm
          sx={{ display: otpData ? 'none' : undefined }}
          formRef={formRef}
          formInstanceRef={formInstanceRef}
          onSubmit={handleSetPassword}
        />
      ) : (
        <SetNewPasswordForm
          sx={{ display: otpData ? 'none' : undefined }}
          formRef={formRef}
          formInstanceRef={formInstanceRef}
          onSubmit={({ password }) => {
            if (otpData) {
              const otpCodeValidationError = getOtpCodeValidationError(otpCode);

              if (otpCodeValidationError) {
                setOtpCodeError(otpCodeValidationError);
              } else {
                handleSetNewPassword({ password });
              }
            } else {
              handleGetOtpCode();
            }
          }}
        />
      )}
    </Modal>
  );
};
