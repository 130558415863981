import { apiAuthClient } from 'api/helpers';

import { GetAuthResponseType, GetAuthRequestType } from '../types';

export const getAuth = async ({
  pathParams: { invitationId },
}: GetAuthRequestType) => {
  const url = `/api/v2/invitations/${invitationId}/auth`;

  const { data } = await apiAuthClient.get<GetAuthResponseType>(url);

  return data.data;
};
