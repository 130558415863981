import { ReactNode } from 'react';

import {
  GuideTourContextProvider,
  LayoutMainBottomPaddingSpacingContextProvider,
  NotificationCountContextProvider,
  InvitationFlowModalContextProvider,
} from 'context';

type Props = {
  children: ReactNode;
};

export const LoggedUserProviders = ({ children }: Props) => (
  /* TODO: remove notification count context when websockets are ready */
  <NotificationCountContextProvider>
    <GuideTourContextProvider>
      <LayoutMainBottomPaddingSpacingContextProvider>
        <InvitationFlowModalContextProvider>
          {children}
        </InvitationFlowModalContextProvider>
      </LayoutMainBottomPaddingSpacingContextProvider>
    </GuideTourContextProvider>
  </NotificationCountContextProvider>
);
