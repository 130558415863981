import React, { useContext } from 'react';
import { Alert, Box } from '@mantine/core';

import { ErrorTwoToneIcon } from 'icons';
import { ErrorsContext } from 'legacy/TemplateCreatorEditor/context';

export const Errors = () => {
  const errors = useContext(ErrorsContext);

  return errors.length > 0 ? (
    <Alert
      sx={theme => ({
        marginBottom: -1,
        backgroundColor: theme.colors.red[1],
        borderColor: theme.colors.red[2],
      })}
      radius={0}
      icon={<ErrorTwoToneIcon color="red" backgroundColor="red.2" />}
    >
      <ul>
        {errors.map(error => (
          <Box component="li" key={error}>
            {error}
          </Box>
        ))}
      </ul>
    </Alert>
  ) : null;
};
