import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const TEXT_LIMIT = 255;

export const useGetZodBooleanVariableSchema = () => {
  const { t } = useTranslation('common');

  return z.object({
    initialValue: z.boolean(),
    label: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
    apiDescription: z
      .string()
      .trim()
      .max(TEXT_LIMIT, {
        message: t('common:formErrors.maxNumberOfSigns', {
          max: TEXT_LIMIT,
        }),
      }),
  });
};
