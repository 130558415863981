import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { ManWithDocumentIcon } from 'icons';
import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeletedAccountModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('user');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <ManWithDocumentIcon
          sx={theme => ({
            marginBottom: theme.other.spacing(1.5),
          })}
          color="primary"
        />
        <Text
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
          })}
          weight={600}
        >
          {t('user:yourAccountHasBeenDeleted')}
        </Text>
        <Text size="md" weight={400}>
          <Trans
            i18nKey="user:accountDeletedAcknowledgements"
            components={{
              br: <br />,
            }}
          />
        </Text>
      </Box>
    </Modal>
  );
};
