import { ThemeComponentType } from '../types';

export const Avatar: ThemeComponentType = {
  defaultProps: {
    radius: 'xl',
  },
  styles: theme => {
    const placeholderColor = theme.colors.blue[theme.fn.primaryShade()];

    return {
      placeholder: {
        backgroundColor: theme.colors.blue[1],
        fontSize: theme.fontSizes.md,
        fontWeight: 600,
        color: placeholderColor,
      },
      placeholderIcon: {
        color: placeholderColor,
      },
    };
  },
};
