import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getAuth } from '../requests';
import { GetAuthRequestType } from '../types';

export const useGetAuth = (
  { pathParams: { invitationId } }: GetAuthRequestType,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery({
    queryKey: [API_QUERY_KEYS.invitationAuth, { invitationId }],
    queryFn: () => getAuth({ pathParams: { invitationId } }),
    ...options,
  });
