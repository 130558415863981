import { ReactNode } from 'react';
import { Accordion, Box, Loader } from '@mantine/core';

import { Error } from 'components/shared/Error';

const ACCORDION_ITEM_VALUE = 'accordionItemValue';

type Props<T = unknown | undefined> = {
  children: ReactNode | ((data: Exclude<T, undefined>) => JSX.Element);
  isExpanded: boolean;
  data?: T;
  isError?: boolean;
  isLoading?: boolean;
  withFirstCell?: boolean;
};

export const TableRowAccordion = <T extends unknown>({
  data,
  children,
  isExpanded,
  isError = false,
  isLoading = false,
  withFirstCell = false,
}: Props<T>) => {
  const tdStyle = {
    '&&': {
      padding: 0,
      borderBottom: isExpanded ? undefined : 'none',
    },
  };

  const getContent = () => {
    if (isError) {
      return (
        <Error
          sx={theme => ({
            padding: theme.other.spacing(2),
          })}
        />
      );
    }

    if (isLoading) {
      return <Loader sx={{ display: 'block', margin: 'auto' }} />;
    }

    if (data && typeof children === 'function') {
      return children(data as Exclude<T, undefined>);
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  };

  return (
    <Box
      sx={{
        td: {
          minWidth: 'initial',
          maxWidth: 'initial',
        },
        '.mantine-Text-root': {
          overflow: 'initial',
          whiteSpace: 'initial',
          textOverflow: 'initial',
        },
      }}
      component="tr"
    >
      {withFirstCell && <Box sx={tdStyle} component="td" />}
      <Box sx={tdStyle} component="td" colSpan={1000}>
        <Accordion
          styles={theme => ({
            content: {
              padding: 0,
              margin: theme.other.spacing(2, 1),
              border: `1px solid ${
                isLoading ? 'transparent' : theme.colors.gray[1]
              }`,
              borderRadius: theme.radius.sm,
            },
          })}
          value={isExpanded ? ACCORDION_ITEM_VALUE : null}
        >
          <Accordion.Item value={ACCORDION_ITEM_VALUE}>
            <Accordion.Panel>
              <Box sx={{ minHeight: 38 }}>{getContent()}</Box>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    </Box>
  );
};
