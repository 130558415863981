import { Box } from '@mantine/core';

import { Sidebar } from './Sidebar';

const SIDEBAR_WIDTH = 300;

export const EditorArea = ({ textEditor, isSidebarOpen }) => {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        backgroundColor: theme.white,
        border: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Box
        sx={theme => ({
          overflow: 'auto',
          width: isSidebarOpen ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%',
          padding: theme.other.spacing(3),
          s: {
            textDecoration: 'none',
            '[data-slate-content="true"]': {
              textDecoration: 'line-through',
            },
            u: {
              '[data-slate-content="true"]': {
                textDecoration: 'line-through underline',
              },
            },
          },
          u: {
            textDecoration: 'none',
            '[data-slate-content="true"]': {
              textDecoration: 'underline',
            },
            s: {
              '[data-slate-content="true"]': {
                textDecoration: 'line-through underline',
              },
            },
          },
        })}
      >
        {textEditor}
      </Box>
      {isSidebarOpen && (
        <Box
          sx={theme => ({
            flexShrink: 0,
            width: SIDEBAR_WIDTH,
            padding: theme.other.spacing(2),
            borderLeft: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          <Sidebar />
        </Box>
      )}
    </Box>
  );
};
