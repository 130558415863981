import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const FilterIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 7.733h-9.067m0 0a2.133 2.133 0 1 0-4.266 0m4.266 0a2.133 2.133 0 1 1-4.266 0m0 0H4m16 8.534h-2.667m0 0a2.133 2.133 0 1 0-4.266 0m4.266 0a2.133 2.133 0 1 1-4.266 0m0 0H4"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
