import { apiClient } from 'api/helpers';
import { HTTP_HEADERS } from 'consts';

import { GetSsoRequestType } from '../types';

export const getSso = async ({
  headers,
  pathParams: { ssoDriver },
}: GetSsoRequestType) => {
  const url = `/api/v2/sso/${ssoDriver}`;

  const data = await apiClient.get<{ data: '' }>(url, {
    headers,
  });

  return data.headers[HTTP_HEADERS.xRedirectTo];
};
