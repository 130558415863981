import { useContext } from 'react';

import { NotificationType } from 'types';
import { NOTIFICATION_TYPES } from 'consts';
import { InvitationFlowModalContext } from 'context/InvitationFlowModal';
import { NotificationListItem } from './NotificationListItem';

type Props = {
  notifications: NotificationType[];
  lastNotificationRef: (element: any) => void;
  hasUpdateAccess: boolean;
  markNotificationAsRead: (
    notificationId: NotificationType['id'],
    callback?: () => void,
  ) => void;
  deleteNotification: (id: NotificationType['id']) => void;
  handleClosePopover: () => void;
};

export const NotificationList = ({
  notifications,
  lastNotificationRef,
  hasUpdateAccess,
  markNotificationAsRead,
  deleteNotification,
  handleClosePopover,
}: Props) => {
  const { openModal } = useContext(InvitationFlowModalContext);

  return (
    <ul>
      {notifications.map(
        ({ id, message, createdAt, readAt, url, sender, type }, index) => (
          <li
            key={id}
            ref={
              index === notifications.length - 1
                ? lastNotificationRef
                : undefined
            }
          >
            <NotificationListItem
              message={message}
              createdAt={createdAt}
              readAt={readAt}
              url={url}
              hasUpdateAccess={hasUpdateAccess}
              markAsRead={callback => {
                markNotificationAsRead(id, callback);
              }}
              deleteNotification={() => {
                deleteNotification(id);
              }}
              sender={sender}
              type={type}
              handleOpen={
                type === NOTIFICATION_TYPES.documentSignInvitation
                  ? () => {
                      const match = url?.match(/invitation\/([^/]*)/);
                      const invitationId = match && match[1];
                      if (invitationId) {
                        openModal({ invitationId });
                        handleClosePopover();
                      }
                    }
                  : undefined
              }
            />
          </li>
        ),
      )}
    </ul>
  );
};
