import { apiAuthClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import {
  GetTechnicalUsersResponseType,
  GetTechnicalUsersRequestType,
} from '../types';

export const getTechnicalUsers = async ({
  pathParams: { organisationId },
  queryParams: { name, page, perPage, sortBy, sortDir },
}: GetTechnicalUsersRequestType) => {
  const query = stringifyUrlQuery({
    name,
    page,
    perPage,
    sortBy,
    sortDir,
  });

  const url = `/api/v2/organisations/${organisationId}/technical-users${query}`;

  const { data } = await apiAuthClient.get<GetTechnicalUsersResponseType>(url);

  return data;
};
