import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';

import { IconPropsType } from './types';

export const LockIcon = ({ color, size = 24, sx, ...props }: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.571 10.857V7.43a3.429 3.429 0 0 1 6.858 0v3.428m-9.143 0h11.428c.632 0 1.143.512 1.143 1.143v6.857c0 .631-.511 1.143-1.143 1.143H6.286a1.143 1.143 0 0 1-1.143-1.143V12c0-.631.512-1.143 1.143-1.143Z"
        stroke={iconColor}
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Box>
  );
};
