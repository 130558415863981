import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTransactionEntries } from '../requests';
import { GetTransactionEntriesRequestType } from '../types';

export const useGetTransactionEntries = (
  {
    pathParams: { dataStream, transactionId },
  }: {
    pathParams: Omit<
      GetTransactionEntriesRequestType['pathParams'],
      'organisationId'
    >;
  },
  options?: {
    enabled?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.transactionEntries,
      {
        organisationId,
        dataStream,
        transactionId,
      },
    ],
    queryFn: () =>
      getTransactionEntries({
        pathParams: { organisationId, dataStream, transactionId },
      }),
    ...options,
  });
};
