import { PaginatedListType } from 'types';

export const getInfinityQueryNextPageParam = ({
  links,
}: PaginatedListType<unknown>) => {
  if (links.next) {
    const nextPageSearchParams = new URL(links.next).searchParams;

    return nextPageSearchParams.get('page') ?? undefined;
  }

  return undefined;
};
