import { Box, LoadingOverlay } from '@mantine/core';

import { SettingsType, UpdateCurrentSettingType } from '../types';
import { MailColorTheme } from './MailColorTheme';
import { MailFooter } from './MailFooter';

type Props = {
  currentSettings: SettingsType;
  updateCurrentSetting: UpdateCurrentSettingType;
  previewHtml: string;
  isPreviewHtmlLoading: boolean;
};

export const Customization = ({
  currentSettings,
  updateCurrentSetting,
  previewHtml,
  isPreviewHtmlLoading,
}: Props) => (
  <Box
    sx={theme => ({
      [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
        display: 'flex',
      },
    })}
  >
    <Box
      sx={theme => ({
        width: '100%',
        maxWidth: 670,
        height: 1000,
        position: 'relative',
        marginBottom: theme.other.spacing(3),
        border: `24px solid ${theme.colors.gray[1]}`,
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
          margin: theme.other.spacing(0, 3, 0, 0),
        },
      })}
    >
      <LoadingOverlay visible={isPreviewHtmlLoading} />
      <Box
        sx={{ width: '100%', height: '100%', border: 'none' }}
        component="iframe"
        srcDoc={
          previewHtml
            ? previewHtml.replace(/<script.*?<\/script>/gi, '')
            : undefined
        }
      />
    </Box>
    <Box
      sx={theme => ({
        width: '100%',
        maxWidth: 670,
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
          maxWidth: 460,
        },
      })}
    >
      <MailColorTheme
        color={currentSettings.color}
        updateColor={color => {
          updateCurrentSetting('color', color);
        }}
      />
      <MailFooter
        withCustomFooter={currentSettings.withCustomFooter}
        updateWithCustomFooter={withCustomFooter => {
          updateCurrentSetting('withCustomFooter', withCustomFooter);
        }}
      />
    </Box>
  </Box>
);
