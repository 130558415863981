import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { useGetCurrentOrganisationId } from 'hooks';

import { getTransactionEntry } from '../requests';
import { GetTransactionEntryRequestType } from '../types';

export const useGetTransactionEntry = (
  {
    pathParams: { dataStream, transactionId, transactionEntry },
  }: {
    pathParams: Omit<
      GetTransactionEntryRequestType['pathParams'],
      'organisationId'
    >;
  },
  options?: {
    enabled?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();

  return useQuery({
    queryKey: [
      API_QUERY_KEYS.transactionEntry,
      {
        organisationId,
        dataStream,
        transactionId,
        transactionEntry,
      },
    ],
    queryFn: () =>
      getTransactionEntry({
        pathParams: {
          organisationId,
          dataStream,
          transactionId,
          transactionEntry,
        },
      }),
    ...options,
  });
};
