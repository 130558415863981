import { Box } from '@mantine/core';

import { PaperCard } from 'components/shared';

import { AppSettings } from './AppSettings';
import { PersonalInformation } from './PersonalInformation';
import { Password } from './Password';
import { DeletingAccount } from './DeletingAccount';
import { getPanelWrapperWidthStyles } from '../helpers';

export const AccountManagement = () => (
  <Box
    sx={theme => ({
      [theme.fn.largerThan('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    })}
  >
    <Box
      sx={theme => ({
        ...getPanelWrapperWidthStyles(theme),
        [theme.fn.largerThan('md')]: {
          marginRight: theme.other.spacing(2),
        },
      })}
    >
      <PaperCard
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
      >
        <PersonalInformation />
      </PaperCard>
      <PaperCard
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
      >
        <AppSettings />
      </PaperCard>
      <PaperCard
        sx={theme => ({
          marginBottom: theme.other.spacing(2),
        })}
      >
        <Password />
      </PaperCard>
    </Box>
    <Box sx={getPanelWrapperWidthStyles}>
      <PaperCard>
        <DeletingAccount />
      </PaperCard>
    </Box>
  </Box>
);
