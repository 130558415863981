import {
  Box,
  DefaultProps,
  MantineColor,
  packSx,
  useMantineTheme,
} from '@mantine/core';

import { getPalette, getDotSize } from './helpers';

type Props = {
  color?: MantineColor;
  sx?: DefaultProps['sx'];
  dotSize?: number;
  label?: string;
};

export const Status = ({ sx, color = 'gray', dotSize = 24, label }: Props) => {
  const mantineTheme = useMantineTheme();

  const { background, content } = getPalette(mantineTheme, color);
  const size = getDotSize(dotSize);

  return (
    <Box
      sx={[
        theme => ({
          display: 'flex',
          justifyContent: label ? undefined : 'center',
          alignItems: 'center',
          borderRadius: mantineTheme.radius.sm,
          backgroundColor: background,
          width: label ? 'fit-content' : dotSize,
          height: label ? undefined : dotSize,
          padding: label ? theme.other.spacing(0.75, 1.5) : undefined,
          whiteSpace: 'pre-wrap',
        }),
        ...packSx(sx),
      ]}
    >
      {dotSize !== 0 && (
        <Box
          sx={{
            width: size,
            height: size,
            borderRadius: '50%',
            backgroundColor: content,
            flexShrink: 0,
          }}
        />
      )}
      {label && (
        <Box
          sx={theme => ({
            fontSize: theme.fontSizes.sm,
            fontWeight: 400,
            lineHeight: '15px',
            color: content,
            marginLeft: dotSize === 0 ? undefined : theme.other.spacing(0.75),
          })}
          component="span"
        >
          {label}
        </Box>
      )}
    </Box>
  );
};
