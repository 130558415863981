import { apiAuthClient } from 'api/helpers';

import { PostOtpLoginRequestType, PostOtpLoginResponseType } from '../types';

export const postOtpLogin = async ({ bodyParams }: PostOtpLoginRequestType) => {
  const url = '/api/v2/auth/otp/login';

  const { data } = await apiAuthClient.post<PostOtpLoginResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
