import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box } from '@mantine/core';

import { URLS } from 'consts';
import { useIsLgBreakpoint } from 'hooks';
import { ArrowLeftIcon, LogoExtendedIcon } from 'icons';

import { LanguageSelect } from '../LanguageSelect';

type Props = {
  backButtonAction?: () => void;
  backButtonActionText?: string;
};

export const Header = ({ backButtonAction, backButtonActionText }: Props) => {
  const { t } = useTranslation('common');
  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        position: isLgBreakpoint ? 'absolute' : undefined,
        width: '100%',
        marginBottom: isLgBreakpoint ? undefined : theme.other.spacing(3),
        padding: isLgBreakpoint ? theme.other.spacing(4) : undefined,
        zIndex: 1,
      })}
    >
      <Anchor
        sx={theme => ({
          marginTop: isLgBreakpoint ? undefined : theme.other.spacing(1),
        })}
        component={Link}
        to={URLS.home}
      >
        <LogoExtendedIcon color={isLgBreakpoint ? 'white' : 'black'} />
      </Anchor>
      <Box
        sx={theme => ({
          width: backButtonAction && isLgBreakpoint ? '50%' : undefined,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: theme.other.spacing(4),
        })}
      >
        {backButtonAction && isLgBreakpoint && (
          <Anchor
            component="button"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              if (backButtonAction) {
                backButtonAction();
              }
            }}
          >
            <ArrowLeftIcon
              sx={theme => ({ marginRight: theme.other.spacing(0.5) })}
            />
            {backButtonActionText || t('common:back')}
          </Anchor>
        )}
        <LanguageSelect />
      </Box>
    </Box>
  );
};
