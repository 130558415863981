import { useSearchParams } from 'hooks';
import { DataTypeListItemType } from 'types';
import { DataList, PercentageContainer } from 'components/shared';
import { CursorPaginationReactRouter } from 'components/Transactions';

import { DataTypePanel } from '../DataTypePanel';

type Props = {
  dataTypes: DataTypeListItemType[];
  isRefetchingDataTypes: boolean;
  prevPage: string | null | undefined;
  nextPage: string | null | undefined;
};

export const DataTypeList = ({
  dataTypes,
  isRefetchingDataTypes,
  prevPage,
  nextPage,
}: Props) => {
  const { getSearchParam } = useSearchParams(['search', 'page']);

  const scrollTopTriggerKey = `${getSearchParam('page')}${getSearchParam(
    'search',
  )}`;

  return (
    <PercentageContainer>
      <DataList
        data={dataTypes}
        isLoading={isRefetchingDataTypes}
        scrollTopTriggerKey={scrollTopTriggerKey}
      >
        {({ updatedAt, name }) => (
          <DataTypePanel name={name} updatedAt={updatedAt} />
        )}
      </DataList>
      <CursorPaginationReactRouter
        prevPage={prevPage}
        nextPage={nextPage}
        isStickyToBottom
      />
    </PercentageContainer>
  );
};
