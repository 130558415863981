import { ReactNode } from 'react';
import { ActionIcon, Box, Text, Title } from '@mantine/core';

import { useIsLgBreakpoint } from 'hooks';
import { ChevronLeftIcon } from 'icons';

type Props = {
  title: string;
  description: ReactNode;
  preTitle?: string;
  errorLabel?: ReactNode;
  backButtonAction?: () => void;
};

export const ContentHeader = ({
  title,
  preTitle,
  description,
  errorLabel,
  backButtonAction,
}: Props) => {
  const isLgBreakpoint = useIsLgBreakpoint();

  return (
    <Box sx={theme => ({ marginBottom: theme.other.spacing(2) })}>
      <Text
        size="md"
        color="red"
        sx={{ minHeight: isLgBreakpoint ? 65 : undefined }}
      >
        {errorLabel}
      </Text>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {backButtonAction && !isLgBreakpoint && (
          <ActionIcon
            sx={theme => ({
              marginRight: theme.other.spacing(1),
              marginLeft: theme.other.spacing(-1),
            })}
            onClick={backButtonAction}
          >
            <ChevronLeftIcon color="dark.0" />
          </ActionIcon>
        )}
        <div>
          {preTitle && <Text>{preTitle}</Text>}
          <Title
            order={3}
            sx={{
              width: isLgBreakpoint ? 350 : undefined,
              marginBottom: 0,
            }}
          >
            {title}
          </Title>
          <Text>{description}</Text>
        </div>
      </Box>
    </Box>
  );
};
