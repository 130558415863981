import { FunctionComponent, MouseEvent } from 'react';
import { Box, MantineColor, UnstyledButton } from '@mantine/core';

import { useGetColor } from 'hooks';

type Props = {
  color: MantineColor;
  colorHover: MantineColor;
  width: number;
  Icon: FunctionComponent;
  text: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};
export const ActionBlock = ({
  color,
  colorHover,
  width,
  Icon,
  text,
  onClick,
}: Props) => {
  const backgroundColor = useGetColor(color);
  const backgroundColorHover = useGetColor(colorHover);

  return (
    <UnstyledButton
      sx={theme => ({
        width,
        backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.white,
        fontWeight: 500,
        '&:hover': {
          backgroundColor: backgroundColorHover,
        },
      })}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        component="span"
      >
        <Icon />
        {text}
      </Box>
    </UnstyledButton>
  );
};
