import { ValidationErrors } from 'types';

export const getDataStreamTemplatesItemErrors = (
  serverErrors: ValidationErrors | null,
  index: number,
) =>
  serverErrors
    ? Object.entries(serverErrors).reduce(
        (accumulator, [errorKey, errorMessage]) => {
          if (errorKey.startsWith(`templates[${index}].`)) {
            return {
              ...accumulator,
              [errorKey.replace(/templates\[\d]\./, '')]: errorMessage,
            };
          }

          return accumulator;
        },
        {},
      )
    : {};
