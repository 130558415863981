import { Box } from '@mantine/core';

import { DetailsContent } from './DetailsContent';
import { DetailsHeader } from './DetailsHeader';

type Props = {
  hasTechnicalUsersFeature: boolean;
};

export const Details = ({ hasTechnicalUsersFeature }: Props) => (
  <div>
    <Box sx={theme => ({ marginBottom: theme.other.spacing(4.5) })}>
      <DetailsHeader />
    </Box>
    <DetailsContent hasTechnicalUsersFeature={hasTechnicalUsersFeature} />
  </div>
);
