import { apiAuthClient } from '../../helpers';

export const getBatches = async ({
  pathParams: { userBatchId },
}: {
  pathParams: { userBatchId: string };
}) => {
  const url = `/api/v2/batches/${userBatchId}`;

  const { data } = await apiAuthClient.get(url);

  return data.data;
};
