import { apiClient } from 'api/helpers';
import { stringifyUrlQuery } from 'helpers';

import { GetSsoAuthRequestType, GetSsoAuthResponseType } from '../types';

export const getSsoAuth = async ({
  pathParams: { ssoDriver },
  queryParams: { code, state },
}: GetSsoAuthRequestType) => {
  const query = stringifyUrlQuery({
    code,
    state,
  });
  const url = `/api/v2/sso/${ssoDriver}/auth${query}`;
  const { data, headers } = await apiClient.get<GetSsoAuthResponseType>(url);

  return { data: data.data, headers };
};
