import { DocumentListItemParticipantType } from 'types';

export const getDocumentRoleTranslationKey = (
  role: DocumentListItemParticipantType['role'],
) => {
  switch (role) {
    case 'acceptor':
      return 'documents:documentRole.acceptor';
    case 'signer':
      return 'documents:documentRole.signer';
    case 'owner':
      return 'documents:documentRole.owner';
    case 'manager':
      return 'documents:documentRole.manager';
    default:
      return '';
  }
};
