import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDeleteUser, usePostUserOtpDelete } from 'api/account';
import {
  useClearLoginData,
  useGetOtpCodeValidationError,
  useServerErrors,
} from 'hooks';
import { ArrowRightIcon } from 'icons';
import { URLS } from 'consts';
import { AuthOtpType, ValidationErrors } from 'types';
import { Modal, OtpFlow } from 'components/shared';

import { ChooseReason } from './ChooseReason';
import { DeleteAccountWarning } from './DeleteAccountWarning';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteAccountModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation(['common', 'user']);
  const navigate = useNavigate();

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const getOtpCodeValidationError = useGetOtpCodeValidationError();
  const clearLoginData = useClearLoginData();

  const [validationServerErrors, setValidationServerErrors] =
    useState<ValidationErrors>({});

  const [isReasonChosen, setIsReasonChosen] = useState(false);

  const [reason, setReason] = useState('');

  const [otpCode, setOtpCode] = useState('');
  const [otpCodeError, setOtpCodeError] = useState('');
  const [otpData, setOtpData] = useState<AuthOtpType | null>(null);

  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();
  const { mutateAsync: getOtpData, isLoading: isGettingOtpData } =
    usePostUserOtpDelete();

  const codeInputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleServerErrors = (error: unknown) => {
    setValidationServerErrors(getServerErrorStatus(error));
    addServerErrorActions(error);
  };

  const handleGetOtpCode = async () => {
    try {
      const data = await getOtpData();

      setOtpData(data);
    } catch (error) {
      handleServerErrors(error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser({
        bodyParams: {
          code: otpCode,
          otpId: otpData!.id,
        },
      });

      handleClose();
      clearLoginData();
      navigate(URLS.home, {
        replace: true,
        state: {
          isAccountDeleted: true,
        },
      });
    } catch (error) {
      handleServerErrors(error);
    }
  };

  const resetState = () => {
    setIsReasonChosen(false);
    setReason('');
    setValidationServerErrors({});
    setOtpCode('');
    setOtpCodeError('');
    setOtpData(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="md"
      title={t('user:deletingAccount')}
      defaultButtonDisabled={isDeletingUser}
      defaultButtonAction={handleClose}
      primaryButtonIsLoading={isGettingOtpData}
      primaryButtonText={t('common:next')}
      primaryButtonRightIcon={<ArrowRightIcon />}
      primaryButtonDisabled={!reason}
      primaryButtonAction={
        otpData
          ? undefined
          : () => {
              if (isReasonChosen) {
                handleGetOtpCode();
              } else {
                setIsReasonChosen(true);
              }
            }
      }
      redButtonIsLoading={isDeletingUser}
      redButtonText={t('user:yesIWantDeleteAccount')}
      redButtonAction={
        otpData
          ? async () => {
              const otpCodeValidationError = getOtpCodeValidationError(otpCode);

              if (otpCodeValidationError) {
                setOtpCodeError(otpCodeValidationError);
              } else {
                await handleDeleteUser();
              }
            }
          : undefined
      }
    >
      {otpData ? (
        <OtpFlow
          codeNumber={otpData.order}
          validUntil={otpData.validUntil}
          isGettingOtpData={isGettingOtpData}
          resendCode={handleGetOtpCode}
          textInputProps={{
            ref: codeInputRef,
            error: otpCodeError || validationServerErrors?.code,
            value: otpCode,
            onChange: e => {
              setOtpCodeError('');
              setValidationServerErrors({});
              setOtpCode(e.target.value);
            },
          }}
        />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isReasonChosen ? (
            <DeleteAccountWarning />
          ) : (
            <ChooseReason reason={reason} setReason={setReason} />
          )}
        </>
      )}
    </Modal>
  );
};
