import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { usePatchTransactionDataType } from 'api/transactions';
import {
  decodeYamlDataType,
  encodeYamlDataType,
  getNestedErrorMessage,
} from 'helpers';
import {
  useGetCurrentOrganisationId,
  useServerErrors,
  useShowNotification,
} from 'hooks';
import { ValidationErrors } from 'types';
import { Modal } from 'components/shared';
import { YamlEditor } from 'components/Transactions';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  yaml: string;
};

export const DataTypeEditModal = ({ isOpen, onClose, yaml }: Props) => {
  const { t } = useTranslation('dataTypes');
  const queryClient = useQueryClient();
  const { transactionId, transactionDataStream } = useParams() as {
    transactionDataStream: string;
    transactionId: string;
  };

  const { addServerErrorActions, getServerErrorStatus } = useServerErrors();
  const showNotification = useShowNotification();
  const organisationId = useGetCurrentOrganisationId();

  const [yamlValue, setYamlValue] = useState(decodeYamlDataType(yaml));
  const [errorStatus, setErrorStatus] = useState<ValidationErrors>({});
  const [isDataTypeUpdatingOrRefetching, setIsDataTypeUpdatingOrRefetching] =
    useState(false);

  const { mutateAsync: transactionDataTypeUpdateMutateAsync } =
    usePatchTransactionDataType({
      pathParams: {
        transactionId,
        dataStreamId: transactionDataStream,
      },
    });

  const updateDataType = async (yamlRequestData: string) => {
    if (!yamlValue) {
      setErrorStatus({
        ...(!yamlValue && { yaml: t('common:formErrors.required') }),
      });

      return;
    }

    setIsDataTypeUpdatingOrRefetching(true);

    try {
      await transactionDataTypeUpdateMutateAsync({
        yaml: encodeYamlDataType(yamlRequestData),
      });
      await queryClient.invalidateQueries([
        'transactionDataType',
        organisationId,
        transactionDataStream,
        transactionId,
      ]);

      showNotification({
        message: t('dataTypes:dataTypeUpdated'),
      });

      onClose();
    } catch (error) {
      setErrorStatus(getServerErrorStatus(error) || {});
      addServerErrorActions(error);
    } finally {
      setIsDataTypeUpdatingOrRefetching(false);
    }
  };

  const yamlErrorsAsString = errorStatus
    ? getNestedErrorMessage(errorStatus)
    : '';

  useEffect(() => {
    if (isOpen) {
      setYamlValue(decodeYamlDataType(yaml));
    }
  }, [isOpen, yaml]);

  /* clear errorStatus when request is done after closing modal and has validation errors */
  useEffect(() => {
    if (!isOpen && Object.keys(errorStatus).length > 0) {
      setErrorStatus({});
    }
  }, [errorStatus, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('dataTypes:editDataType')}
      defaultButtonAction={onClose}
      defaultButtonDisabled={isDataTypeUpdatingOrRefetching}
      primaryButtonAction={async () => {
        await updateDataType(yamlValue);
      }}
      primaryButtonDisabled={Object.keys(errorStatus).length > 0}
      primaryButtonIsLoading={isDataTypeUpdatingOrRefetching}
    >
      <YamlEditor
        sx={theme => ({
          marginBottom: theme.other.spacing(-3),
        })}
        value={yamlValue}
        onChange={value => {
          setErrorStatus({});
          setYamlValue(value);
        }}
        error={yamlErrorsAsString}
      />
    </Modal>
  );
};
