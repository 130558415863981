import { useTranslation } from 'react-i18next';
import { Text } from '@mantine/core';

import { useFormatUserDate } from 'hooks';
import { TransactionEntryType } from 'types';
import { PaperCard, PercentageContainer } from 'components/shared';

import { DataTypeActions } from './DataTypeActions';

type Props = {
  dataType: TransactionEntryType['dataType'];
};

export const DataTypePanel = ({ dataType }: Props) => {
  const { t } = useTranslation(['common', 'dataTypes']);

  const formatUserDate = useFormatUserDate();

  if (dataType) {
    const { name, yaml, updatedAt } = dataType;

    return (
      <PercentageContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        component={PaperCard}
      >
        <div>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(2.25),
            })}
            size="xl"
            weight={600}
          >
            {name}
          </Text>
          <Text size="md" weight={600}>
            {formatUserDate(updatedAt)}
          </Text>
          <Text color="gray.7" size="sm">
            {t('dataTypes:lastUpdate')}
          </Text>
        </div>
        <DataTypeActions yaml={yaml} />
      </PercentageContainer>
    );
  }

  return null;
};
