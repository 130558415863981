import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Anchor,
  Box,
  Checkbox,
  DefaultProps,
  MantineTheme,
} from '@mantine/core';

import { STATIC_URLS } from 'consts';
import { MarketingContactModal } from 'components/shared/Agreements/MarketingContactModal';

import { NewsletterModal } from './NewsletterModal';
import { AgreementsType } from './types';

type Props = {
  agreements: AgreementsType;
  onAgreementsChange: (
    agreements: AgreementsType,
    sourceChanged: keyof AgreementsType | 'all',
  ) => void;
  errors: {
    [key in keyof AgreementsType]?: string;
  };
  sx?: DefaultProps['sx'];
};

export const Agreements = ({
  agreements,
  onAgreementsChange,
  errors: {
    terms: termsError = '',
    newsletter: newsletterError = '',
    phoneCallMarketing: phoneCallMarketingError = '',
    phoneMessageMarketing: phoneMessageMarketingError = '',
  },
  sx,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isMarketingContactModalOpen, setIsMarketingContactModalOpen] =
    useState(false);

  const openNewsletterModal = () => {
    setIsNewsletterModalOpen(true);
  };
  const openMarketingContactModal = () => {
    setIsMarketingContactModalOpen(true);
  };

  const closeNewsletterModal = () => {
    setIsNewsletterModalOpen(false);
  };
  const closeMarketingContactModal = () => {
    setIsMarketingContactModalOpen(false);
  };

  const areAllStepFieldsChecked = Object.values(agreements).every(Boolean);

  const changeAgreement = (checked: boolean, field: keyof AgreementsType) => {
    onAgreementsChange(
      {
        ...agreements,
        [field]: checked,
      },
      field,
    );
  };

  const checkboxSx = (theme: MantineTheme) => ({
    label: {
      fontSize: theme.fontSizes.md,
    },
  });

  return (
    <>
      <Box sx={sx}>
        <Checkbox
          sx={checkboxSx}
          label={<b>{t('common:selectAll')}</b>}
          checked={areAllStepFieldsChecked}
          onChange={e => {
            onAgreementsChange(
              Object.keys(agreements).reduce(
                (accumulator, field) => ({
                  ...accumulator,
                  [field]: e.target.checked,
                }),
                {} as AgreementsType,
              ),
              'all',
            );
          }}
        />
        <Checkbox
          sx={checkboxSx}
          label={
            <>
              *{' '}
              <Trans
                i18nKey="user:acceptTerms"
                components={{
                  a: (
                    <Anchor
                      href={STATIC_URLS.termsAndConditions}
                      target="_blank"
                    />
                  ),
                }}
              />
            </>
          }
          checked={agreements.terms}
          onChange={e => {
            changeAgreement(e.target.checked, 'terms');
          }}
          error={termsError}
        />
        <Checkbox
          sx={checkboxSx}
          label={
            <>
              {t('user:newsletterWish')}{' '}
              <Anchor component="button" onClick={openNewsletterModal}>
                {t('common:more')}
              </Anchor>
            </>
          }
          checked={agreements.newsletter}
          onChange={e => {
            changeAgreement(e.target.checked, 'newsletter');
          }}
          error={newsletterError}
        />
        <Checkbox
          sx={checkboxSx}
          label={
            <>
              {t('user:marketingContact')}{' '}
              <Anchor component="button" onClick={openMarketingContactModal}>
                {t('common:more')}
              </Anchor>
            </>
          }
          onChange={openMarketingContactModal}
          checked={
            agreements.phoneCallMarketing || agreements.phoneMessageMarketing
          }
          error={phoneCallMarketingError || phoneMessageMarketingError}
        />
      </Box>
      <NewsletterModal
        isOpen={isNewsletterModalOpen}
        onClose={closeNewsletterModal}
        onSubmit={() => {
          changeAgreement(true, 'newsletter');
          closeNewsletterModal();
        }}
      />
      <MarketingContactModal
        isOpen={isMarketingContactModalOpen}
        onClose={closeMarketingContactModal}
        onSubmit={closeMarketingContactModal}
        errors={{
          phoneCallMarketing: phoneCallMarketingError,
          phoneMessageMarketing: phoneMessageMarketingError,
        }}
        agreements={agreements}
        changeAgreement={changeAgreement}
      />
    </>
  );
};
