export const GUIDE_TOUR_DATA = {
  createNewDocument: {
    selector: 'createNewDocument',
    titleTranslation: 'user:guideTour.createNewDocumentTitle',
    contentTranslation: 'user:guideTour.createNewDocumentContent',
  },
  changeOrganisationAndBoard: {
    selector: 'changeOrganisationAndBoard',
    titleTranslation: 'user:guideTour.changeOrganisationAndBoardTitle',
    contentTranslation: 'user:guideTour.changeOrganisationAndBoardContent',
  },
  userMenu: {
    selector: 'userMenu',
    titleTranslation: 'user:guideTour.userMenuTitle',
    contentTranslation: 'user:guideTour.userMenuContent',
  },
} as const;
