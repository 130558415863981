import { useId } from 'react';
import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const FlagUkIcon = ({ size = 24, sx, ...props }: IconPropsType) => {
  const id = useId();

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path d="M0 0h24v24H0V0Z" fill="#012169" />
        <path
          d="m2.813 0 9.15 9.05L21.075 0H24v3.1l-9 8.95 9 8.9V24h-3l-9-8.95L3.037 24H0v-3l8.963-8.9L0 3.2V0h2.813Z"
          fill="#fff"
        />
        <path
          d="M15.9 14.05 24 22v2l-10.162-9.95H15.9Zm-6.9 1 .225 1.75-7.2 7.2H0l9-8.95ZM24 0v.15l-9.338 9.4.075-2.2L22.126 0H24ZM0 0l8.963 8.8h-2.25L0 2.1V0Z"
          fill="#C8102E"
        />
        <path d="M9.037 0v24h6V0h-6ZM0 8v8h24V8H0Z" fill="#fff" />
        <path
          d="M0 9.65v4.8h24v-4.8H0ZM10.238 0v24h3.6V0h-3.6Z"
          fill="#C8102E"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={24} height={24} rx={12} fill="#fff" />
        </clipPath>
      </defs>
    </Box>
  );
};
