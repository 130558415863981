import { apiAuthClient } from 'api/helpers';

import { GetUserNotificationsResponseType } from '../types';

export const getUserNotifications = async () => {
  const url = '/api/v2/auth/user/notifications';

  const { data } = await apiAuthClient.get<GetUserNotificationsResponseType>(
    url,
  );

  return data;
};
