import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Checkbox, Text } from '@mantine/core';

import { TemplateDescriptionField } from '../TemplateDescriptionField';

type Props = {
  error: string | undefined;
  oneWayCheckboxProps: UseFormRegisterReturn;
};

export const Properties = ({ error, oneWayCheckboxProps }: Props) => {
  const { t } = useTranslation('templates');

  return (
    <TemplateDescriptionField
      label={t('templates:properties')}
      description={t('templates:propertiesDescription')}
      error={error}
    >
      <Checkbox
        {...oneWayCheckboxProps}
        sx={{
          marginBottom: 0,
        }}
        label={
          <>
            <Text>{t('templates:templateTypes.oneWay')}</Text>
            <Text size="md" weight={400}>
              {t('templates:oneWayDescription')}
            </Text>
          </>
        }
      />
    </TemplateDescriptionField>
  );
};
