import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetCurrentBoard } from 'api/boards';
import { useGetTemplate } from 'api/templates';
import { DYNAMIC_URLS, URLS } from 'consts';
import { getHasHateoasAccess } from 'helpers';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { TemplateAttachmentFormHandler } from './TemplateAttachmentFormHandler';

export const TemplateAttachmentAddFromTemplate = () => {
  const { t } = useTranslation('templates');

  const { templateId, baseTemplateId } = useParams() as {
    templateId: string;
    baseTemplateId: string;
  };

  const { board, isCurrentBoardError, isCurrentBoardLoading } =
    useGetCurrentBoard();

  const hasBoardTemplateFeature = board?.canAccessTemplateCreator || false;

  const {
    data: templateData,
    isError: isTemplateError,
    isLoading: isTemplateLoading,
  } = useGetTemplate(
    { pathParams: { templateId } },
    { enabled: hasBoardTemplateFeature },
  );

  const {
    data: baseTemplateData,
    isError: isBaseTemplateError,
    isLoading: isBaseTemplateLoading,
  } = useGetTemplate(
    { pathParams: { templateId: baseTemplateId } },
    { enabled: hasBoardTemplateFeature },
  );

  const hasTemplateUpdateAccess = getHasHateoasAccess(
    'update',
    templateData?.__links,
  );

  return (
    <>
      <MetaTitle value={t('templates:templates')} />
      <DataWrapper
        data={templateData}
        isError={isCurrentBoardError || isTemplateError || isBaseTemplateError}
        isLoading={
          isCurrentBoardLoading || isTemplateLoading || isBaseTemplateLoading
        }
        isNoAccess={
          !hasBoardTemplateFeature &&
          hasTemplateUpdateAccess &&
          !isCurrentBoardLoading
        }
        errorMargin="xl"
      >
        {({ name, attachmentTemplates }) => {
          const { contractTemplate, name: baseTemplateName } =
            baseTemplateData as Exclude<typeof baseTemplateData, undefined>;

          return (
            <>
              <MetaTitle value={`${name} - ${t('templates:templates')}`} />
              <Breadcrumbs
                sx={theme => ({
                  marginBottom: theme.other.spacing(3),
                })}
                items={[
                  { name: t('templates:templates'), url: URLS.templates },
                  { name, url: DYNAMIC_URLS.templateEdit({ templateId }) },
                  {
                    name: `${t(
                      'templates:addNewAttachmentFromTemplate',
                    )} ${baseTemplateName}`,
                    url: '#',
                  },
                ]}
              />
              <TemplateAttachmentFormHandler
                attachmentDefaultValue={contractTemplate}
                attachmentTemplates={attachmentTemplates}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
