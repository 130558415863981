import { Dispatch, SetStateAction } from 'react';
import { UnstyledButton } from '@mantine/core';
import { ArrowUpIcon } from 'icons';
import { SortStateType } from 'types';

type Props<T> = {
  text: string;
  sort: SortStateType<T>;
  value: T;
  setSort: Dispatch<SetStateAction<SortStateType<T>>>;
};

export const TableSortButton = <T extends string | null>({
  text,
  value,
  sort,
  setSort,
}: Props<T>) => (
  <UnstyledButton
    sx={{
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
    }}
    onClick={() => {
      setSort(currentSort => ({
        sortBy: value,
        sortDir: currentSort.sortDir === 'asc' ? 'desc' : 'asc',
      }));
    }}
  >
    {text}
    <ArrowUpIcon
      sx={theme => ({
        transform: sort.sortDir === 'asc' ? undefined : 'rotate(180deg)',
        transitionDuration: theme.other.animationsTime,
      })}
    />
  </UnstyledButton>
);
