import { useQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { getUserBoards } from '../requests';

export const useGetUserBoards = () =>
  useQuery({
    queryKey: [API_QUERY_KEYS.userBoards],
    queryFn: getUserBoards,
  });
