import { apiAuthClient } from 'api/helpers';
import { GetDocumentInvitationRequestType } from '../types';

export const getDocumentInvitation = async ({
  pathParams: { invitationId },
}: GetDocumentInvitationRequestType) => {
  const url = `/api/v2/document-invitations/${invitationId}`;
  const { data } = await apiAuthClient.get(url);

  return data.data;
};
