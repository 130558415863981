import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDataTypes } from 'api/dataTypes';
import { getCursorFromUrl, getHasHateoasAccess } from 'helpers';
import { useGetOrganisationFeatureAccess, useSearchParams } from 'hooks';
import { URLS } from 'consts';
import { Breadcrumbs, DataWrapper, MetaTitle } from 'components/shared';

import { Header } from './Header';
import { DataTypeList } from './DataTypeList';

export const DataTypes = () => {
  const { t } = useTranslation(['transactions', 'dataTypes']);

  const {
    data: hasDataTypesFeature,
    isError: isDataTypesFeatureError,
    isLoading: isLoadingDataTypesFeature,
  } = useGetOrganisationFeatureAccess('dataTypes');

  const { getSearchParam, deleteSearchParam } = useSearchParams([
    'page',
    'search',
  ]);

  const page = getSearchParam('page');
  const name = getSearchParam('search');

  const {
    data: dataTypesData,
    isError: isDataTypesError,
    isInitialLoading: isDataTypesInitialLoading,
    isRefetching: isRefetchingDataTypes,
  } = useGetDataTypes(
    { queryParams: { page, name } },
    {
      enabled: !isLoadingDataTypesFeature && hasDataTypesFeature,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (dataTypesData?.data.length === 0 && page !== null) {
      deleteSearchParam('page');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTypesData?.data, page]);

  return (
    <>
      <MetaTitle value={t('transactions:transactions')} />
      <DataWrapper
        data={dataTypesData}
        isError={isDataTypesError || isDataTypesFeatureError}
        isLoading={isDataTypesInitialLoading || isLoadingDataTypesFeature}
        isNoAccess={!hasDataTypesFeature && !isLoadingDataTypesFeature}
        errorMargin="xl"
      >
        {({ data: dataTypes, links, __links }) => {
          const hasDataTypeCreateAccess = getHasHateoasAccess(
            'create',
            __links,
          );

          const prevPage = getCursorFromUrl(links.prev);
          const nextPage = getCursorFromUrl(links.next);

          return (
            <>
              <Breadcrumbs
                items={[
                  {
                    name: t('transactions:transactions'),
                    url: URLS.transactions,
                  },
                  { name: t('dataTypes:dataTypes'), url: '#' },
                ]}
              />
              <Header hasDataTypeCreateAccess={hasDataTypeCreateAccess} />
              <DataTypeList
                dataTypes={dataTypes}
                isRefetchingDataTypes={isRefetchingDataTypes}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </>
          );
        }}
      </DataWrapper>
    </>
  );
};
