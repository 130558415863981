import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';

import { Modal } from 'components/shared';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isDeleting: boolean;
  redButtonAction: () => {};
  hasMultipleItemsToDelete?: boolean;
  forbiddenItems?: { id: string | number; name: ReactNode }[];
  forbiddenItemIcon?: ReactNode;
};

export const ListDeleteModal = ({
  isOpen,
  onClose,
  isDeleting,
  redButtonAction,
  hasMultipleItemsToDelete = false,
  forbiddenItems = [],
  forbiddenItemIcon,
}: Props) => {
  const { t } = useTranslation('common');

  const hasForbiddenItems = forbiddenItems.length > 0;

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      title={
        hasForbiddenItems
          ? t('common:cantDeleteAllElements')
          : t('common:deleteConfirmation')
      }
      defaultButtonAction={onClose}
      defaultButtonDisabled={isDeleting}
      redButtonText={t('common:yesDelete')}
      redButtonIsLoading={isDeleting}
      redButtonAction={redButtonAction}
    >
      {hasForbiddenItems && (
        <>
          <Text
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
            size="md"
            weight={400}
          >
            {t('common:cantDeleteAllElementsDescription')}
          </Text>
          <Box
            sx={theme => ({
              marginBottom: theme.other.spacing(2),
            })}
            component="ul"
          >
            {forbiddenItems.map(({ id, name }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 600,
                }}
                component="li"
                key={id}
              >
                <Box
                  sx={theme => ({
                    marginRight: theme.other.spacing(0.5),
                    position: 'relative',
                    top: 2,
                  })}
                  component="span"
                >
                  {forbiddenItemIcon}
                </Box>
                <span>{name}</span>
              </Box>
            ))}
          </Box>
        </>
      )}
      <Text size="md" weight={400}>
        {hasMultipleItemsToDelete
          ? t('common:deleteMultipleConfirmationDescription')
          : t('common:deleteConfirmationDescription')}
      </Text>
    </Modal>
  );
};
