import { Box } from '@mantine/core';

import { IconPropsType } from './types';

export const FileTypeWithoutExtensionIcon = ({
  sx,
  ...props
}: IconPropsType) => (
  <Box
    component="svg"
    sx={sx}
    width={24}
    height={28}
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.662 1.47-.22-.22H4A3.15 3.15 0 0 0 .85 4.4v19.2A3.15 3.15 0 0 0 4 26.75h16a3.15 3.15 0 0 0 3.15-3.15V6.958l-.22-.22-5.268-5.268ZM7.15 9.246v-.1l6.5.004v.1l-6.5-.004Zm0 4.797v-.1h9.7v.1h-9.7Zm0 4.697 9.7.01v.1l-9.7-.01v-.1Z"
      fill="#E6EDF9"
      stroke="#004FC4"
      strokeWidth={1.5}
    />
  </Box>
);
