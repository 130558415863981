import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Anchor, Box, Button } from '@mantine/core';
import { z } from 'zod';

import { usePostAuthLogin } from 'api/auth';
import { getZodSchemaErrorMessages } from 'helpers';
import { useServerErrors } from 'hooks';
import { URLS } from 'consts';
import { ValidationErrors } from 'types';
import { TextInput, TextInputPassword } from 'components/shared';

import { AuthContainerLoginHeader } from '../../AuthContainerLoginHeader';
import { SsoLoginMethods } from '../SsoLoginMethods';
import { LoginOptionsDivider } from '../LoginOptionsDivider';

type Props = {
  email: string;
  setEmail: (email: string) => void;
  hasLoginMethodEmail: boolean;
  switchLoginMethod: () => void;
  onSuccess: (accessToken: string) => void;
  backButtonAction?: () => void;
};

export const LoginPassword = ({
  email,
  setEmail,
  hasLoginMethodEmail,
  switchLoginMethod,
  onSuccess,
  backButtonAction,
}: Props) => {
  const { t } = useTranslation(['common', 'user']);

  const schema = z.object({
    email: z
      .string()
      .trim()
      .min(1, { message: t('common:formErrors.required') })
      .email({ message: t('common:formErrors.incorrectEmail') }),
    password: z.string().min(1, { message: t('common:formErrors.required') }),
  });

  const { getGeneralServerError, getServerErrorStatus } = useServerErrors();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState('');
  const [errorStatus, setErrorStatus] = useState<ValidationErrors | null>(null);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const { mutateAsync: login, isLoading } = usePostAuthLogin();

  const clearError = (field: string) => {
    setErrorStatus(status => ({
      ...status,
      [field]: '',
    }));
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    clearError('email');
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    clearError('password');
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = getZodSchemaErrorMessages({
      schema,
      fields: { email, password },
    });
    const emailErrorField = errors?.email;
    const passwordErrorField = errors?.password;

    if (errors) {
      setErrorStatus(status => ({
        ...status,
        email: errors.email,
        password: errors.password,
      }));
      if (emailErrorField) {
        emailInputRef.current?.focus();
      } else if (passwordErrorField) {
        passwordInputRef.current?.focus();
      }
    } else {
      try {
        const { accessToken } = await login({
          email,
          password,
        });

        onSuccess(accessToken);
      } catch (error) {
        const validationServerErrors = getServerErrorStatus(error);

        if (validationServerErrors) {
          setErrorStatus(validationServerErrors);
        } else {
          setServerErrorMessage(getGeneralServerError(error));
        }

        passwordInputRef.current?.focus();
      }
    }
  };

  return (
    <>
      <AuthContainerLoginHeader
        errorLabel={serverErrorMessage}
        backButtonAction={backButtonAction}
      />
      <form onSubmit={handleSubmit}>
        <Box
          component={TextInput}
          id="email"
          autoComplete="username"
          label={t('common:email')}
          placeholder={t('common:emailPlaceholder')}
          disabled
          value={email}
          error={errorStatus?.email}
          ref={emailInputRef}
          onChange={handleChangeEmail}
        />
        <TextInputPassword
          id="password"
          type="password"
          autoComplete="current-password"
          label={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t('user:password')}{' '}
              <Anchor
                component={Link}
                to={URLS.requestPasswordReset}
                state={{ email }}
              >
                {t('user:forgotPassword')}
              </Anchor>
            </Box>
          }
          placeholder={t('user:passwordPlaceholder')}
          autoFocus
          disabled={isLoading}
          value={password}
          error={errorStatus?.password}
          ref={passwordInputRef}
          onChange={handleChangePassword}
        />
        <Button
          sx={theme => ({
            marginBottom: theme.other.spacing(2),
            fontWeight: 600,
          })}
          type="submit"
          fullWidth
          size="lg"
          loading={isLoading}
        >
          {t('user:signIn')}
        </Button>
      </form>
      {hasLoginMethodEmail && (
        <Anchor
          component="button"
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
          onClick={switchLoginMethod}
        >
          {t('user:wishOneTimePassword')}
        </Anchor>
      )}
      <LoginOptionsDivider />
      <SsoLoginMethods />
    </>
  );
};
