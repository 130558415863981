import { useTranslation } from 'react-i18next';
import { Box, Center, Text, Title, UnstyledButton } from '@mantine/core';

import { LockIcon, WarningIcon } from 'icons';

import { RegistrationStepType, ValidStepsType } from '../types';

type Props = {
  availableSteps: number;
  validSteps: ValidStepsType;
  onChangeStep: (step: RegistrationStepType) => void;
  isFinished: boolean;
};

export const Steps = ({
  availableSteps,
  validSteps,
  onChangeStep,
  isFinished,
}: Props) => {
  const { t } = useTranslation('user');

  return (
    <Box
      sx={theme => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isFinished ? 'center' : 'space-between',
        height: '100%',
        backgroundColor: theme.colors[theme.primaryColor][9],
        color: theme.colors[theme.primaryColor][1],
        padding: theme.other.spacing(4),
      })}
    >
      {isFinished ? (
        <Title
          sx={theme => ({
            color: theme.colors[theme.primaryColor][1],
          })}
          order={4}
        >
          {t('user:registrationFinished')}
        </Title>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Title
              sx={theme => ({
                color: theme.colors[theme.primaryColor][1],
              })}
              order={4}
            >
              {t('user:registrationStepHeader')}
            </Title>
            <Text
              sx={theme => ({
                marginBottom: theme.other.spacing(4),
                color: theme.colors[theme.primaryColor][1],
              })}
            >
              {t('user:registrationStepSubheader')}
            </Text>
            <ol>
              {Array.from(Array(3)).map((_, index) => {
                const step = (index + 1) as RegistrationStepType;
                const isAvailable = step <= availableSteps;
                const isValid = validSteps[step];

                return (
                  <Box
                    sx={theme => ({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: theme.other.spacing(3),
                      opacity: isAvailable ? 1 : 0.5,
                    })}
                    key={step}
                    component="li"
                  >
                    <UnstyledButton
                      sx={theme => ({
                        display: 'flex',
                        alignItems: 'center',
                        cursor: isAvailable ? undefined : 'not-allowed',
                        fontSize: theme.fontSizes.xl,
                        fontWeight: isAvailable ? 700 : undefined,
                        color: isValid
                          ? theme.colors[theme.primaryColor][1]
                          : theme.colors.yellow[0],
                        '&:disabled': {
                          backgroundColor: 'transparent',
                        },
                        ':before': {
                          content: `"${step}"`,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 50,
                          height: 50,
                          marginRight: theme.other.spacing(2),
                          border: `1px solid ${
                            isValid
                              ? theme.colors[theme.primaryColor][1]
                              : theme.colors.yellow[0]
                          }`,
                          borderRadius: 35,
                          color: isValid ? undefined : theme.colors.yellow[0],
                          fontWeight: 400,
                        },
                      })}
                      disabled={!isAvailable}
                      onClick={() => {
                        onChangeStep(step);
                      }}
                    >
                      {t(`user:registrationSteps.${step}`)}
                    </UnstyledButton>
                    {!isValid && <WarningIcon color="yellow" />}
                  </Box>
                );
              })}
            </ol>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Center
              sx={theme => ({
                width: 'fit-content',
                marginRight: theme.other.spacing(2),
              })}
            >
              <LockIcon />
            </Center>
            <Text
              sx={theme => ({
                color: theme.colors[theme.primaryColor][1],
              })}
            >
              {t('user:registrationStepFooter')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
