import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { putCustomizationsEmail } from '../requests';
import { PutCustomizationsEmailRequestType } from '../types';

export const usePutCustomizationsEmail = ({
  pathParams: { organisationId },
}: Pick<PutCustomizationsEmailRequestType, 'pathParams'>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      color,
      withCustomFooter,
    }: PutCustomizationsEmailRequestType['bodyParams']) =>
      putCustomizationsEmail({
        pathParams: { organisationId },
        bodyParams: {
          color,
          withCustomFooter,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          API_QUERY_KEYS.organisationCustomizationsEmail,
          { organisationId },
        ],
      });
    },
  });
};
