import { useMutation, useQueryClient } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';

import { postAvatar } from '../requests';
import { PostAvatarRequestType } from '../types';

export const usePostAvatar = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      formData,
    }: {
      formData: PostAvatarRequestType['formData'];
    }) =>
      postAvatar({
        formData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEYS.user],
      });
    },
  });
};
