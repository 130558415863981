export const getUserFullName = (
  name: string | null | undefined,
  surname: string | null | undefined,
) => {
  const nameString = typeof name === 'string' ? name : '';
  const surnameString = typeof surname === 'string' ? surname : '';
  const separator = nameString && surnameString ? ' ' : '';

  return `${nameString}${separator}${surnameString}`;
};
