import { useMantineTheme } from '@mantine/core';

const rightSectionWithTwoIconsWidth = 52;
const rightSectionWithOneIconWidth = 24;
export const useGetClearableInputRightSectionData = (
  hasTwoRightSectionIcons: boolean,
) => {
  const mantineTheme = useMantineTheme();

  return {
    inputPaddingRight:
      (hasTwoRightSectionIcons
        ? rightSectionWithTwoIconsWidth
        : rightSectionWithOneIconWidth) + mantineTheme.other.spacing(4),
    rightSectionWidth: hasTwoRightSectionIcons
      ? rightSectionWithTwoIconsWidth
      : rightSectionWithOneIconWidth,
  };
};
