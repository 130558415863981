import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

import { Modal } from 'components/shared';

import { GuideTourStepType } from '../types';

type Props = {
  step: GuideTourStepType;
} & TooltipRenderProps;

export const TooltipComponent = ({
  backProps,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step: initialStep,
  tooltipProps,
}: Props) => {
  const { t } = useTranslation('user');

  const isFirstStep = index === 0;
  const step = {
    ...initialStep,
    isBackButtonEnabled:
      initialStep.isBackButtonEnabled === undefined
        ? true
        : initialStep.isBackButtonEnabled,
  };

  return (
    <div ref={tooltipProps.ref}>
      <Modal
        isOpen
        onClose={() => {
          skipProps.onClick(new MouseEvent('click') as any); // prevents error from e.preventDefault()
        }}
        overlayOpacity={0}
        title={step.title}
        defaultButtonText={t('user:previous')}
        defaultButtonAction={
          step.isBackButtonEnabled
            ? e => {
                if (isFirstStep) {
                  skipProps.onClick(e);
                } else {
                  backProps.onClick(e);
                }

                if (step.backButtonCallback) {
                  step.backButtonCallback();
                }
              }
            : undefined
        }
        primaryButtonText={isLastStep ? t('user:goToApp') : t('user:next')}
        primaryButtonAction={e => {
          primaryProps.onClick(e);

          if (step.nextButtonCallback) {
            step.nextButtonCallback();
          }
        }}
      >
        {step.content}
      </Modal>
    </div>
  );
};
