import { Accordion, Box, Text } from '@mantine/core';

import { ErrorTwoToneIcon, WarningTwoToneIcon } from 'icons';

type Props = {
  value: string;
  title: string;
  iconType: 'error' | 'warning';
  items: string[];
};

export const TransactionNotificationAccordionItem = ({
  value,
  title,
  iconType,
  items,
}: Props) => {
  const isErrorType = iconType === 'error';

  return (
    <Accordion.Item value={value}>
      <Accordion.Control sx={{ paddingBottom: 0 }}>
        {title} ({items.length})
      </Accordion.Control>
      <Accordion.Panel>
        <ul>
          {items.map(item => (
            <Text
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                padding: theme.other.spacing(1, 0),
                borderBottom: `1px solid ${theme.colors.gray[1]}`,
              })}
              key={item}
              component="li"
              size="md"
            >
              <Box
                sx={theme => ({
                  marginRight: theme.other.spacing(1),
                  flexShrink: 0,
                })}
                component={isErrorType ? ErrorTwoToneIcon : WarningTwoToneIcon}
                color={isErrorType ? 'error' : 'yellow'}
              />

              {item}
            </Text>
          ))}
        </ul>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
