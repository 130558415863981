import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';
import {
  AlignCenterIcon,
  AlignJustifiedIcon,
  AlignLeftIcon,
  AlignRightIcon,
} from 'legacy/TemplateCreatorEditor/icons';

import { SectionWrapper } from '../../SectionWrapper';
import { TextModifierButton } from '../TextModifierButton';

const ALIGNMENTS = [
  {
    value: 'left',
    tooltipLabelTranslation: 'templates:toolbar.leftAlignment',
    icon: <AlignLeftIcon />,
  },
  {
    value: 'right',
    tooltipLabelTranslation: 'templates:toolbar.rightAlignment',
    icon: <AlignRightIcon />,
  },
  {
    value: 'center',
    tooltipLabelTranslation: 'templates:toolbar.centerAlignment',
    icon: <AlignCenterIcon />,
  },
  {
    value: 'justify',
    tooltipLabelTranslation: 'templates:toolbar.justification',
    icon: <AlignJustifiedIcon />,
  },
];

export const TextAlignmentSection = () => {
  const { t } = useTranslation('templates');

  const editor = useContext(EditorContext);

  const { isFocused } = editor.value.selection;
  const activeAlignment = editor.activeAlignment();
  const hasParagraph = editor.hasParagraph();

  return (
    <SectionWrapper>
      {ALIGNMENTS.map(({ value, tooltipLabelTranslation, icon }) => (
        <TextModifierButton
          key={value}
          tooltipLabel={t(tooltipLabelTranslation)}
          icon={icon}
          disabled={!isFocused || !hasParagraph}
          isActive={isFocused && value === activeAlignment}
          onClick={() => {
            editor.setParagraphAlignment(value);
          }}
        />
      ))}
    </SectionWrapper>
  );
};
