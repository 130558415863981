import { isBefore } from 'date-fns';
import { MantineColor } from '@mantine/core';

import { TechnicalUserType } from 'types';

export const getCertificateColorStatus = (
  checkFor: TechnicalUserType['certificate']['type'],
  certificate: TechnicalUserType['certificate'],
): MantineColor => {
  const hasPergaminCertificate = certificate.type === 'pergamin';
  const hasCustomCertificate = certificate.type === 'custom';
  const hasCertificate = hasPergaminCertificate || hasCustomCertificate;
  const hasExpired = certificate.expiresAt
    ? isBefore(new Date(certificate.expiresAt), new Date())
    : false;

  switch (true) {
    case hasExpired:
      return 'orange';
    case (checkFor === 'pergamin' && hasPergaminCertificate) ||
      (checkFor === 'custom' && hasCustomCertificate):
      return 'green';
    case !hasCertificate:
      return 'red';
    default:
      return 'gray';
  }
};
