import { Box } from '@mantine/core';

import { useGetColor } from 'hooks';
import { IconPropsType } from 'icons/types';

export const SelectionListIcon = ({
  color,
  size = 24,
  sx,
  ...props
}: IconPropsType) => {
  const iconColor = useGetColor(color);

  return (
    <Box
      component="svg"
      sx={sx}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={iconColor}
        strokeWidth={1.5}
        d="M11.172 7.586H20m-8.828 8.828H20M5.103 5.379H7.31c.61 0 1.104.494 1.104 1.104V8.69c0 .61-.494 1.103-1.104 1.103H5.103C4.494 9.793 4 9.3 4 8.69V6.483c0-.61.494-1.104 1.103-1.104Zm0 8.828H7.31c.61 0 1.104.494 1.104 1.103v2.207c0 .61-.494 1.104-1.104 1.104H5.103C4.494 18.62 4 18.127 4 17.517V15.31c0-.609.494-1.103 1.103-1.103Z"
      />
    </Box>
  );
};
