import { useMutation } from '@tanstack/react-query';

import { useGetCurrentOrganisationId } from 'hooks';

import { patchTransactionDataType } from '../requests';
import { PatchTransactionDataTypeRequestType } from '../types';

export const usePatchTransactionDataType = ({
  pathParams: { dataStreamId, transactionId },
}: {
  pathParams: Omit<
    PatchTransactionDataTypeRequestType['pathParams'],
    'organisationId'
  >;
}) => {
  const organisationId = useGetCurrentOrganisationId();

  return useMutation({
    mutationFn: (
      bodyParams: PatchTransactionDataTypeRequestType['bodyParams'],
    ) =>
      patchTransactionDataType({
        pathParams: { organisationId, dataStreamId, transactionId },
        bodyParams,
      }),
  });
};
