import { apiAuthClient } from 'api/helpers';

import {
  PostTechnicalUserRequestType,
  PostTechnicalUserResponseType,
} from '../types';

export const postTechnicalUser = async ({
  pathParams: { organisationId },
  bodyParams,
}: PostTechnicalUserRequestType) => {
  const url = `/api/v2/organisations/${organisationId}/technical-users`;

  const { data } = await apiAuthClient.post<PostTechnicalUserResponseType>(
    url,
    bodyParams,
  );

  return data.data;
};
