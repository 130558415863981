import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorContext } from 'legacy/TemplateCreatorEditor/context';

const NAME_LIMIT = 255;

export const useGetVariableNameError = () => {
  const { t } = useTranslation(['common', 'templates']);

  const editor = useContext(EditorContext);

  return (variableName, variableInitialName) => {
    let nameError = '';

    if (variableName.length === 0) {
      nameError = t('common:formErrors.required');
    } else if (variableName.length > NAME_LIMIT) {
      nameError = t('common:formErrors.maxNumberOfSigns', {
        max: NAME_LIMIT,
      });
    } else if (
      editor
        .getVariables()
        .some(
          ({ name }) => name !== variableInitialName && name === variableName,
        )
    ) {
      nameError = t('templates:templateCreatorVariables.variableAlreadyExists');
    }

    return nameError;
  };
};
