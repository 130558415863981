import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';

import { Modal } from 'components/shared';
import { Props as ModalProps } from 'components/shared/Modals/Modal';

import { env } from 'env';
import { useGetCurrentOrganisationId } from 'hooks';
import { TechnicalUserType } from 'types';

import { CodeBlock } from '../CodeBlock';
import { Step } from './Step';

const productionExternalApiUrl = 'https://ext-api.pergam.in';
const apiToExternalApiUrlMap: { [key: string]: string } = {
  'https://api.pergam.in': productionExternalApiUrl,
  'https://api-dev.pergam.in': 'https://api-dev.pergam.in',
  'https://api-staging.pergam.in': 'https://ext-api-tst.pergam.in',
  'https://api-new-infra.pergam.in': 'https://ext-api-dev.pergam.in',
  'https://api-pred.pergam.in': 'https://ext-api-pred.pergam.in',
};
const externalApiUrl =
  apiToExternalApiUrlMap[env.REACT_APP_API_URL as string] ??
  productionExternalApiUrl;

type Props = {
  technicalUserId: TechnicalUserType['id'];
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const CertificateInstructionModal = ({
  technicalUserId,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation(['api', 'common']);

  const organisationId = useGetCurrentOrganisationId();

  const rsaCommand = '$openssl genrsa -out pkey.pem 4096';
  const csrCommand = `$openssl req -new -key pkey.pem -out ut.csr -subj „/CN=${technicalUserId}/O=${organisationId}"`;
  const csrEndpoint = `${externalApiUrl}/ext-api/v2/certificates/sign`;
  const requestPayload = `{
    „csr": „string",
    „secret": „string"
}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={t('api:instruction.header')}
      primaryButtonText={t('common:understand')}
      primaryButtonAction={onClose}
    >
      <ol>
        <Step>
          1. {t('api:instruction.generateKey')} <CodeBlock code={rsaCommand} />
        </Step>
        <Step>
          2. {t('api:instruction.generateCSR')} <CodeBlock code={csrCommand} />
        </Step>
        <Step>
          3. {t('api:instruction.processCSR')}
          <Box
            sx={theme => ({
              display: 'block',
              margin: theme.other.spacing(1.5, 0),
              fontWeight: 500,
            })}
            component="span"
          >
            {csrEndpoint}
          </Box>
          {t('api:instruction.requestExample')}
          <CodeBlock code={requestPayload} />
        </Step>
      </ol>
    </Modal>
  );
};
