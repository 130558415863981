const languagesOrder = ['pl', 'en', 'de'];
export const getNextLanguageValue = currentLanguage => {
  const currentLanguageIndex = languagesOrder.findIndex(
    lang => lang === currentLanguage,
  );

  return currentLanguageIndex === languagesOrder.length - 1
    ? languagesOrder[0]
    : languagesOrder[currentLanguageIndex + 1];
};
