import { useInfiniteQuery } from '@tanstack/react-query';

import { API_QUERY_KEYS } from 'consts';
import { getInfinityQueryNextPageParam } from 'helpers';
import { useGetCurrentBoardId, useGetCurrentOrganisationId } from 'hooks';

import { getBoardTemplates } from '../requests';
import { GetBoardTemplatesRequestType } from '../types';

export const useGetBoardTemplatesInfinite = (
  {
    queryParams: {
      base,
      from,
      id,
      name,
      page,
      perPage,
      public: publicParam,
      sortBy,
      sortDir,
      to,
    },
  }: Omit<GetBoardTemplatesRequestType, 'pathParams'>,
  options?: {
    enabled?: boolean;
  },
) => {
  const organisationId = useGetCurrentOrganisationId();
  const boardId = useGetCurrentBoardId();

  return useInfiniteQuery({
    queryKey: [
      API_QUERY_KEYS.boardTemplates,
      {
        organisationId,
        boardId,
        base,
        from,
        id,
        name,
        page,
        perPage,
        public: publicParam,
        sortBy,
        sortDir,
        to,
      },
    ],
    queryFn: ({ pageParam }) =>
      getBoardTemplates({
        pathParams: { organisationId, boardId },
        queryParams: {
          base,
          from,
          id,
          name,
          page: pageParam,
          perPage,
          public: publicParam,
          sortBy,
          sortDir,
          to,
        },
      }),
    getNextPageParam: getInfinityQueryNextPageParam,
    ...options,
  });
};
