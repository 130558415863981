import { ZodObject, ZodRawShape } from 'zod';

export const getZodSchemaErrorMessages = <T extends ZodRawShape>({
  schema,
  fields,
  getAllFieldErrors = false,
}: {
  schema: ZodObject<T>;
  fields: unknown;
  getAllFieldErrors?: boolean;
}) => {
  const parsedSchema = schema.safeParse(fields);

  if (parsedSchema.success) {
    return null;
  }

  const errors = parsedSchema.error.formErrors.fieldErrors;

  return (
    getAllFieldErrors
      ? errors
      : Object.entries(errors).reduce(
          (accumulator, [field, fieldErrors]) => ({
            ...accumulator,
            [field]: (fieldErrors as string[])[0],
          }),
          {},
        )
  ) as { [key: string]: string };
};
