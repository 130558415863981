import { useTranslation } from 'react-i18next';
import { Box, Button, DefaultProps, packSx, Text } from '@mantine/core';

import { WomanWithExclamationMarkIcon } from 'icons';

type Props = {
  sx?: DefaultProps['sx'];
  description?: string;
  withPageReload?: boolean;
};

export const Error = ({ sx, description, withPageReload = true }: Props) => {
  const { t } = useTranslation('error');

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          textAlign: 'center',
        },
        ...packSx(sx),
      ]}
    >
      <WomanWithExclamationMarkIcon
        sx={theme => ({
          marginBottom: theme.other.spacing(1),
        })}
        color="red.6"
      />
      <Text
        sx={theme => ({
          marginBottom: theme.other.spacing(0.5),
        })}
        weight={600}
      >
        {t('error:errorOccurred')}
      </Text>
      <Text
        sx={theme => ({
          maxWidth: 360,
          marginBottom: theme.other.spacing(2),
        })}
        size="sm"
      >
        {description || t('error:description')}
      </Text>
      {withPageReload && (
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('error:refreshPage')}
        </Button>
      )}
    </Box>
  );
};
