import { Box, DefaultProps, packSx } from '@mantine/core';

type Props = {
  sx: DefaultProps['sx'];
  valueLength: number;
  limit: number;
};

export const InputLimit = ({ sx, valueLength, limit }: Props) => (
  <Box
    sx={[
      theme => ({
        width: 'fit-content',
        fontSize: theme.fontSizes.md,
        fontWeight: 500,
        color:
          valueLength > limit
            ? theme.colors.red[theme.fn.primaryShade()]
            : theme.colors.gray[5],
      }),
      ...packSx(sx),
    ]}
  >
    {valueLength}/{limit}
  </Box>
);
